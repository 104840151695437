import { useCallback } from 'react';

import { useVideoPlayerActions, useVideoPlayerId } from 'shared/components/video-player/hooks';
import { useSetCurrentTime } from 'shared/components/video-player/state/atoms/hooks';
import { PlaylistItemType } from 'shared/components/video-player/types';

export const useHandleSetPlaylistItem = (playlistItem: PlaylistItemType) => {
  const playerId = useVideoPlayerId();
  const actions = useVideoPlayerActions();
  const setCurrentTime = useSetCurrentTime(playerId);

  return useCallback(
    (autoPlay = true) => {
      setCurrentTime(0);
      actions.setPlaylistItem(playlistItem.id, autoPlay);
    },
    [setCurrentTime, actions, playlistItem.id],
  );
};
