import { Button, ClickAwayListener, Fade, Menu, PopoverActions, Stack } from '@mui/material';
import { IconClose } from 'kognia-ui/icons/IconClose';
import flatten from 'lodash/flatten';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getPlayerName } from 'pages/tactical-analysis/components/tactical-analysis/utils/get-team-type';
import { ButtonDropdown } from 'shared/components/button-dropdown';
import { FilterPlayerTabPanel } from 'shared/components/filter-player/filter-player-tab-panel';
import { PlayersList } from 'shared/components/filter-player/filter-players-list';
import styles from 'shared/components/filter-player/FilterPlayer.module.scss';
import { FilterPlayerTab, FilterPlayerTabs } from 'shared/components/filter-player-tab';
import { NotificationCircle } from 'shared/components/notification-circle';
import { MatchTeam, MatchTeamPlayer } from 'shared/types';

export type FilterProps = {
  disabled?: boolean;
  idx: number;
  displayName: string;
  filteredPlayers?: Set<string>;
  onRemove?: () => void;
  onUpdate: (values: string[]) => void;
  teamsWithPlayers: MatchTeam[];
  selectedValues?: string[];
  showRemoveButton?: boolean;
};

const getSelectedValues = (selectedValues: string[], players?: MatchTeamPlayer[]) => {
  return selectedValues.filter((playerId) => players?.some((teamPlayer) => teamPlayer.id === playerId)) ?? [];
};

export const FilterPlayer = ({
  disabled,
  idx,
  displayName,
  filteredPlayers,
  onRemove,
  onUpdate,
  teamsWithPlayers,
  selectedValues = [],
  showRemoveButton,
}: FilterProps) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('0');
  const [values, setValues] = useState(teamsWithPlayers.map((team) => getSelectedValues(selectedValues, team.players)));
  const [isOpen, setIsOpen] = useState(false);
  const actionRef = useRef<PopoverActions | null>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement>();

  useEffect(() => {
    setValues((values) => {
      const newValues = teamsWithPlayers.map((team) => getSelectedValues(selectedValues, team.players));
      return !isEqual(values, newValues) ? newValues : values;
    });
  }, [selectedValues, teamsWithPlayers]);

  const hasInvalidPlayersSelected = useMemo(
    () =>
      selectedValues.some((player) => {
        if (!filteredPlayers) return false;
        return player !== '' && !filteredPlayers.has(player);
      }),
    [selectedValues, filteredPlayers],
  );

  const handleButtonClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      setIsOpen(true);
    },
    [setAnchorEl, setIsOpen],
  );

  const handleChangeTab = useCallback(
    (event: React.ChangeEvent<any>, newValue: string) => {
      setSelectedTab(newValue);
    },
    [setSelectedTab],
  );

  const handleClose = useCallback(() => {
    if (!anchorEl) return;

    setAnchorEl(undefined);
    setIsOpen(false);
  }, [setAnchorEl, setIsOpen, anchorEl]);

  const handleChangeValues = (
    event: React.ChangeEvent<HTMLInputElement>,
    team: MatchTeam,
    checked: boolean,
    idx: number,
  ) => {
    setValues((values) => {
      let newValues: string[];
      const currentValues = flatten(values);

      if (event.currentTarget.id.startsWith('all-') && checked) {
        newValues = currentValues.filter((player) => !team.players.some((teamPlayer) => teamPlayer.id === player));
      } else {
        newValues = checked
          ? [...currentValues, event.currentTarget.id]
          : currentValues.filter((playerId) => event.currentTarget.id !== playerId);
      }

      onUpdate(newValues);
      return Object.assign([], values, { [idx]: newValues });
    });
  };

  const handleResetAll = useCallback(() => {
    setValues([]);
    onUpdate([]);
  }, [setValues, onUpdate]);

  const generateDisplayName = useCallback((): string => {
    const playerValues = values.reduce((acc, value) => {
      value.map((player) => acc.push(player));
      return acc;
    }, []);
    if (playerValues.length === 1) return getPlayerName(playerValues[0], teamsWithPlayers) || displayName;

    return `${playerValues.length} ${displayName}`;
  }, [values, displayName, teamsWithPlayers]);

  return (
    <span className={styles.container} title={displayName}>
      <NotificationCircle variant='error' visible={hasInvalidPlayersSelected} />
      <ButtonDropdown
        title={generateDisplayName()}
        onClick={handleButtonClick}
        isSelected={values[idx] && (values[idx].length > 0 || values[idx].some((value) => value.length > 0))}
        disabled={Boolean(disabled)}
        isOpen={isOpen}
      />
      {showRemoveButton && (
        <div className={styles.closeButtonContainer} onClick={onRemove}>
          <div className={styles.closeButton}>
            <IconClose size='xsmall' color='tertiary' />
          </div>
        </div>
      )}
      {isOpen && anchorEl && (
        <ClickAwayListener onClickAway={handleClose}>
          <Menu
            action={actionRef}
            anchorEl={anchorEl}
            open
            onClose={handleClose}
            TransitionComponent={Fade}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            className={styles.menu}
          >
            <div className={styles.contentContainer}>
              <>
                <Stack direction='row' justifyContent='flex-end'>
                  <Button color='secondary' onClick={handleResetAll}>
                    {t('common:actions.reset-all').toUpperCase()}
                  </Button>
                </Stack>
                <FilterPlayerTabs value={selectedTab} onChange={handleChangeTab} aria-label='Team tabs'>
                  {teamsWithPlayers.map((team, idx) => (
                    <FilterPlayerTab key={team.id} label={team.name.toUpperCase()} value={`${idx}`} />
                  ))}
                </FilterPlayerTabs>
                {teamsWithPlayers.map((team, idx) => (
                  <FilterPlayerTabPanel key={team.id} value={selectedTab} index={`${idx}`}>
                    <PlayersList
                      filteredPlayers={filteredPlayers}
                      team={team}
                      values={values[idx] ?? []}
                      handleChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                        handleChangeValues(event, team, checked, idx)
                      }
                    />
                  </FilterPlayerTabPanel>
                ))}
              </>
              <div className={styles.message}>
                <span>{t('recordings:filters:players.message')}</span>
              </div>
              <div className={styles.buttonContainer}>
                <Button variant='outlined' color='secondary' onClick={handleClose}>
                  <span>{t('recordings:filters.players.ok')}</span>
                </Button>
              </div>
            </div>
          </Menu>
        </ClickAwayListener>
      )}
    </span>
  );
};
