import { zodResolver } from '@hookform/resolvers/zod';
import { AccordionActions, AccordionDetails } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useRerunEventsPreprocess } from 'pages/backoffice/api/operations/use-rerun-events-preprocess';
import { AnnotationFormActions } from 'pages/backoffice/pages/annotation/components/annotation-form-actions';
import { RecordingIdField } from 'pages/backoffice/pages/annotation/components/form-fields/recording-id-field';
import { rerunEventsPreprocessFormSchema } from 'pages/backoffice/pages/annotation/utils/form-fields-validation';
import {
  RerunEventsPreprocessForm,
  RerunEventsPreprocessFormSchema,
} from 'pages/backoffice/pages/annotation/utils/form-fields-validation/types';

export const RerunEventsPreprocess = () => {
  const { rerunEventsPreprocess } = useRerunEventsPreprocess();

  const {
    register,
    formState: { errors },
    reset,
    handleSubmit: handleFormSubmit,
  } = useForm<RerunEventsPreprocessFormSchema>({
    resolver: zodResolver(rerunEventsPreprocessFormSchema),
  });

  const handleSubmit = useCallback(
    (data: RerunEventsPreprocessForm) => {
      rerunEventsPreprocess({ data });
    },
    [rerunEventsPreprocess],
  );

  return (
    <form onSubmit={handleFormSubmit(handleSubmit)}>
      <AccordionDetails>
        <RecordingIdField errors={errors} register={register} />
      </AccordionDetails>
      <AccordionActions>
        <AnnotationFormActions reset={() => reset()} />
      </AccordionActions>
    </form>
  );
};
