import isEqual from 'lodash/isEqual';

import {
  defaultRecordingFiltersScenariosOrTacticsInside,
  defaultRecordingsFiltersEvents,
} from 'api/recording/useEpisodesWithFilters/useTacticalAnalysesPlaylistItems';
import { RecordingsFilters } from 'shared/types/recording/types';

const isTacticsApplied = (
  tactics: Required<RecordingsFilters>['scenariosOrTacticsInside']['tactics']['offensive' | 'defensive'],
) => {
  return tactics.some(
    (tactic) => tactic.playerIds.length || tactic.tacticalFundamentalType.length || tactic.teamIds.length,
  );
};

const isAppliedInsideFilters = (filters: RecordingsFilters['scenariosOrTacticsInside']) => {
  if (!filters) {
    return false;
  }

  if (filters.teams.length || filters.zones.length || filters.scenario.length) {
    return true;
  }

  return isTacticsApplied(filters.tactics.defensive) || isTacticsApplied(filters.tactics.offensive);
};

export const getFilterStatus = (filters: RecordingsFilters) => {
  const areFiltersStartingApplied =
    Boolean(filters.eventsStarting) && !isEqual(filters.eventsStarting, defaultRecordingsFiltersEvents);
  const areFiltersEndingApplied =
    Boolean(filters.eventsEnding) && !isEqual(filters.eventsEnding, defaultRecordingsFiltersEvents);
  const areFiltersInsideApplied =
    Boolean(filters.scenariosOrTacticsInside) &&
    isAppliedInsideFilters(filters?.scenariosOrTacticsInside) &&
    !isEqual(filters.scenariosOrTacticsInside, defaultRecordingFiltersScenariosOrTacticsInside);

  return {
    areFiltersEndingApplied,
    areFiltersStartingApplied,
    areFiltersInsideApplied,
  };
};
