import { useAtom } from 'jotai';
import { debounce } from 'lodash';
import { useEffect, useRef } from 'react';

import { useFiltersSearchParams } from 'shared/hooks/use-filters-search-params/useFiltersSearchParams';
import { getUrlFiltersState } from 'shared/hooks/use-url-filters/store/filtersStore';
import { State } from 'shared/hooks/use-url-filters/types/state';
import { convertSearchParamsToFilters } from 'shared/hooks/use-url-filters/utils/convertSearchParamsToFilters';
import { createInitialState } from 'shared/hooks/use-url-filters/utils/createInitialState';
import { generateAppliedFilters } from 'shared/hooks/use-url-filters/utils/generateAppliedFilters';
import { useValueRef } from 'shared/hooks/use-value-ref/useValueRef';
import { FiltersList } from 'shared/types';

interface Args<T> {
  initialFilters: T;
  possibleFilters: T;
  sendFilters: (data: T) => void;
  stateId: string;
}

const DEBOUNCE_TIME = 200;
const INITIAL_RENDERS_AMOUNT = 3;

export const useUrlFilters = <T extends FiltersList>({
  initialFilters,
  possibleFilters,
  stateId,
  sendFilters,
}: Args<T>) => {
  const [filters, setFilters] = useAtom<State<T>>(getUrlFiltersState(stateId));
  const initialFiltersRef = useValueRef<T>(initialFilters);
  const initialRendersAmountRef = useRef<number>(0);
  const { getUrlString, convertUrlStringToObject } = useFiltersSearchParams(possibleFilters);

  const searchParamsString = getUrlString();

  useEffect(() => {
    setFilters(createInitialState(initialFiltersRef.current));
  }, [initialFiltersRef, setFilters]);

  useEffect(() => {
    if (initialRendersAmountRef.current < INITIAL_RENDERS_AMOUNT) {
      initialRendersAmountRef.current = initialRendersAmountRef.current + 1;
      return;
    }

    sendFilters(generateAppliedFilters(filters, initialFiltersRef.current));
  }, [filters, sendFilters, initialFiltersRef]);

  useEffect(() => {
    const debouncedUpdateFilters = debounce(() => {
      setFilters(convertSearchParamsToFilters(convertUrlStringToObject(searchParamsString), possibleFilters));
    }, DEBOUNCE_TIME);

    debouncedUpdateFilters();

    return () => {
      debouncedUpdateFilters.cancel();
    };
  }, [possibleFilters, searchParamsString, convertUrlStringToObject, setFilters]);
};
