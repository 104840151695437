import {
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  styled,
  TextField as MuiTextField,
} from '@mui/material';
import Alert from '@mui/material/Alert';
import { Colors, fontSizes } from 'kognia-ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Locales } from 'kognia/i18n/types';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import { User } from 'shared/types';

// Temporary styled components to reflect actual implementation.
// TODO: To be removed when form elements will be defined in design system (styled in theme).

const Label = styled(InputLabel)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: theme.typography.pxToRem(14),
  marginBottom: theme.spacing(0.5),
  position: 'static',
  transform: 'none',
}));

const FieldContainer = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  width: '100%',
}));

const TextField = styled(MuiTextField)({
  '& .MuiInputBase-root': {
    backgroundColor: Colors.athens,
    color: Colors.storm,
    fontSize: fontSizes.default,
  },

  '& .MuiInputBase-input.Mui-disabled': {
    color: Colors.storm,
    opacity: 1,
    WebkitTextFillColor: Colors.storm,
  },
});

const Select = styled(MuiSelect)({
  '& .MuiSelect-select': {
    backgroundColor: Colors.athens,
    fontSize: fontSizes.default,
  },

  '& .MuiSelect-select.Mui-disabled': {
    color: Colors.storm,
    opacity: 1,
    WebkitTextFillColor: Colors.storm,
  },
});

type AccountFormProps = {
  user: User;
};

const AccountForm = ({ user }: AccountFormProps): JSX.Element => {
  const { t } = useTranslation();
  const branding = useBranding();
  const languageOptions = useMemo(
    () => [
      { label: t('languages:english'), value: Locales['en-US'] },
      { label: t('languages:spanish'), value: Locales['es-ES'] },
      { label: t('languages:german'), value: Locales['de-DE'] },
    ],
    [t],
  );

  const languageOptionsSelect = languageOptions.map((option) => {
    return (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    );
  });

  const getSelectedLocale = (locale: string, options: { value: string; label: string }[]) => {
    return options.find((option) => option.value === locale) || options[0];
  };

  const selectedLocale = useMemo(() => getSelectedLocale(user.locale, languageOptions), [languageOptions, user.locale]);

  return (
    <Container maxWidth={'sm'}>
      <FieldContainer>
        <Label htmlFor={'firstName'}>{t('account:first-name')}</Label>
        <TextField variant={'outlined'} size={'small'} id={'firstName'} value={user.firstName} disabled fullWidth />
      </FieldContainer>

      <FieldContainer>
        <Label htmlFor={'lastName'}>{t('account:last-name')}</Label>
        <TextField size={'small'} id={'lastName'} value={user.lastName} disabled fullWidth />
      </FieldContainer>

      <FieldContainer>
        <Label htmlFor={'email'}>{t('account:email')}</Label>
        <TextField size={'small'} id={'email'} value={user.email} disabled fullWidth />
      </FieldContainer>

      <FieldContainer>
        <Label htmlFor={'language'}>{t('account:language')}</Label>
        <Select
          labelId='language'
          id='language'
          value={selectedLocale.value}
          size={'small'}
          onChange={() => {}}
          disabled
          fullWidth
        >
          {languageOptionsSelect}
        </Select>
      </FieldContainer>

      <Divider sx={{ mt: 1, mb: 5 }} />

      <Alert variant='filled' severity='info' sx={{ alignItems: 'center' }}>
        {t('account:info-alert', {
          clientDisplayName: branding.displayName,
        })}
      </Alert>
    </Container>
  );
};

export default AccountForm;
