import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { transformPlaylist } from 'api/playlist/transformers';
import { PlaylistApiResponse } from 'api/playlist/types';
import { playlistItemsBaseUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { useThrottle } from 'shared/hooks/use-throttle/useThrottle';
import { Playlist } from 'shared/types';

enum BatchUpdatePlaylistItemsActions {
  SELECT_FUNDAMENTALS = 'selectFundamentals',
  TRIM_TIME = 'trimTime',
}

export type SelectFundamentalsInItems = {
  action: BatchUpdatePlaylistItemsActions.SELECT_FUNDAMENTALS;
  playlistItemIds: string[];
  fundamentalsSelected: string[];
};

export type TrimItems = {
  action: BatchUpdatePlaylistItemsActions.TRIM_TIME;
  playlistItemIds: string[];
  timeToTrim: {
    before: number;
    after: number;
  };
};

export type BatchUpdatePlaylistItems = SelectFundamentalsInItems | TrimItems;

export const createSelectFundamentalsPayload = (
  playlistItemIds: string[],
  fundamentalsSelected: string[],
): SelectFundamentalsInItems => ({
  action: BatchUpdatePlaylistItemsActions.SELECT_FUNDAMENTALS,
  playlistItemIds,
  fundamentalsSelected,
});

export const createTrimItemsPayload = ({
  playlistItemIds,
  before,
  after,
}: {
  playlistItemIds: string[];
  before?: number;
  after?: number;
}): TrimItems => ({
  action: BatchUpdatePlaylistItemsActions.TRIM_TIME,
  playlistItemIds,
  timeToTrim: {
    before: before ?? 0,
    after: after ?? 0,
  },
});

const THROTTLE_TIME = 1000;

export const useBatchUpdatePlaylistItems = (playlistId: string, onSuccess?: (playlist: Playlist) => void) => {
  const { t } = useTranslation();

  const { mutate, isPending, isError, isSuccess } = useMutationRequest<PlaylistApiResponse, Playlist>({
    transformer: transformPlaylist,
    type: HTTPMethod.PATCH,
    errorMessage: t('api:use-update-playlist-item.error'),
    onSuccess: async (playlist: Playlist) => {
      onSuccess && onSuccess(playlist);
    },
  });

  const handleUpdateBatchPlaylistItems = useCallback(
    (data: BatchUpdatePlaylistItems, onSuccess?: (playlist: Playlist) => void) => {
      mutate(
        {
          url: playlistItemsBaseUrl(playlistId),
          data,
        },
        { onSuccess },
      );
    },
    [mutate, playlistId],
  );

  const updateBatchPlaylistItems = useThrottle(handleUpdateBatchPlaylistItems, {
    wait: THROTTLE_TIME,
    options: { leading: true },
  });

  return { updateBatchPlaylistItems, isPending, isError, isSuccess };
};
