import { USERS_QUERY_KEY } from 'api/backoffice/user/use-kognia-users';
import { CreateKogniaUser, KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { queryClient } from 'api/config';
import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { userAuth0Url } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { BackendApiError, backendApiErrorParser } from 'api/utils/backend-api-error-parser';

export const useCreateUser = () => {
  const { mutate, isPending, isError, isSuccess } = useMutationRequest<KogniaUser, KogniaUser, BackendApiError>({
    type: HTTPMethod.POST,
    errorMessage: (response) => backendApiErrorParser(response, 'Error creating user(s)'),
    successMessage: 'User created successfully',
  });

  const createUser = ({ data, onSuccess }: { data: CreateKogniaUser; onSuccess?: () => void }) => {
    mutate(
      {
        url: userAuth0Url,
        data,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] });
          onSuccess && onSuccess();
        },
      },
    );
  };

  return { createUser, isPending, isError, isSuccess };
};
