import { styled } from '@mui/material';

interface PlayerTabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const TabPanel = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 0),
  width: '100%',
}));

export const FilterPlayerTabPanel = (props: PlayerTabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <TabPanel
      role='tabpanel'
      hidden={value !== index}
      id={`filters-tabpanel-player-${index}`}
      aria-labelledby={`filters-tab-player-${index}`}
      {...other}
    >
      {children}
    </TabPanel>
  );
};
