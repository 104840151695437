import {
  RecordingFiltersScenariosOrTacticsInside,
  RecordingsFilters,
  RecordingsFiltersEvents,
} from 'shared/types/recording/types';
import { TacticsVariants } from 'shared/types/tactics/types';

export const emptyZones = [];

export const defaultEventsStartingAnEpisode: RecordingsFiltersEvents = {
  teams: [],
  event: '',
  zones: emptyZones,
  players: [],
};

export const defaultEventsEndingAnEpisode: RecordingsFiltersEvents = {
  teams: [],
  event: '',
  zones: emptyZones,
  players: [],
};

export const defaultFundamentalWithPlayers = {
  id: '',
  players: [''],
};

export const defaultFundamentalsFilters = (category: 'offensive' | 'defensive') => [
  {
    teamIds: [],
    tacticalFundamentalType: '',
    playerIds: [],
    category: category === 'offensive' ? TacticsVariants.OFFENSIVE : TacticsVariants.DEFENSIVE,
  },
];

export const defaultScenarioInsideAnEpisode: RecordingFiltersScenariosOrTacticsInside = {
  teams: [],
  scenario: '',
  zones: emptyZones,
  tactics: {
    offensive: defaultFundamentalsFilters('offensive'),
    defensive: defaultFundamentalsFilters('defensive'),
  },
};

export const defaultFilters: RecordingsFilters = {
  recordingIds: [],
  eventsStarting: defaultEventsStartingAnEpisode,
  eventsEnding: defaultEventsEndingAnEpisode,
  scenariosOrTacticsInside: defaultScenarioInsideAnEpisode,
};
