import { Typography } from '@mui/material';
import { Breakpoint, fontSizes, generateContainerQueries } from 'kognia-ui';

export const StatsTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography
      fontSize={fontSizes.small}
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...generateContainerQueries({
          [Breakpoint.MD]: {
            whiteSpace: 'nowrap',
          },
        }),
      }}
    >
      {children}
    </Typography>
  );
};
