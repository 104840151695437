import { useTranslation } from 'react-i18next';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { invalidateTacticalAnalysisUrl } from 'api/routes';
import { HTTPMethod, TacticalAnalysisApiResponse } from 'api/types';

export const useInvalidateTacticalAnalysis = (onSuccess?: (response: TacticalAnalysisApiResponse) => void) => {
  const { t } = useTranslation();

  const { mutate, isPending, isError, isSuccess } = useMutationRequest({
    type: HTTPMethod.POST,
    errorMessage: t('api:use-invalidate-tactical-analysis.error'),
    successMessage: t('api:use-invalidate-tactical-analysis.success'),
    onSuccess: async (response: TacticalAnalysisApiResponse) => {
      onSuccess && onSuccess(response);
    },
  });

  const invalidateTacticalAnalysis = (tacticalAnalysisId: string) => {
    mutate({ url: invalidateTacticalAnalysisUrl(tacticalAnalysisId) });
  };

  return { invalidateTacticalAnalysis, isPending, isError, isSuccess };
};
