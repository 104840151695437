import { Box } from '@mui/material';
import classNames from 'classnames';
import { IconDownload } from 'kognia-ui/icons/IconDownload';
import React, { useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import KebabMenuRecordings from 'pages/recordings-list/components/kebab-menu-recordings';
import { RecordingsListItemDetails } from 'pages/recordings-list/components/recordings-list-item-details';
import styles from 'pages/recordings-list/components/vod-recording-card/RecordingCard.module.scss';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import { ListItem } from 'shared/components/list/list-item';
import useIsTaggingTool from 'shared/hooks/is-tagging-tool';
import { Recording } from 'shared/types/recording/types';

interface Props {
  children: React.ReactNode;
  hasVideoSource: boolean;
  hideActions?: boolean;
  menuOptions: MenuListOption[];
  onClick: () => void;
  recording: Recording;
  title: React.ReactNode;
}

const RecordingCard = ({ children, hasVideoSource, hideActions, menuOptions, onClick, recording, title }: Props) => {
  const { t } = useTranslation();
  const kebabRef = useRef<HTMLDivElement | null>(null);

  const isTaggingTool = useIsTaggingTool();

  const defaultMenuOptions = useMemo(() => {
    const options: MenuListOption[] = [];

    if (recording.matchReportDownloadUrl) {
      options.push({
        displayText: (
          <a href={recording.matchReportDownloadUrl} target='_blank' rel='noreferrer' className={styles.downloadOption}>
            <IconDownload size='small' color='secondary' />
            {t('recordings-list:recording-card.download-report')}
          </a>
        ),
      });
    }
    return options;
  }, [t, recording.matchReportDownloadUrl]);

  return (
    <Box className={styles.container}>
      <ListItem
        className={classNames(styles.recordingCard, {
          [styles.processing]: !hasVideoSource,
        })}
        onClick={onClick}
      >
        {title}
        <RecordingsListItemDetails>
          {children}
          <div className={styles.recordingActions}>
            {!hideActions &&
              (isTaggingTool ? (
                <KebabMenu ref={kebabRef} options={[...defaultMenuOptions, ...menuOptions]} id={recording.id} />
              ) : (
                <KebabMenuRecordings
                  ref={kebabRef}
                  options={defaultMenuOptions}
                  extraOptions={menuOptions}
                  recording={recording}
                />
              ))}
          </div>
        </RecordingsListItemDetails>
      </ListItem>
    </Box>
  );
};

export default RecordingCard;
