import { Tooltip } from '@mui/material';
import { useState } from 'react';
import { ImageSizes } from '../../shared/types/images';
import { getIconSizeFromImageSize } from '../../shared/utils/getIconSizeFromImageSize';
import { AvatarBase } from './AvatarBase';
import { IconShield } from '../../icons/IconShield';

type Props = {
  src: string | null | undefined;
  name?: string;
  size?: ImageSizes;
};

export const TeamAvatar = ({ src, name, size = 'xsmall' }: Props) => {
  const [hasSrcError, setHasSrcError] = useState(!src);

  return (
    <Tooltip title={name ?? ''}>
      <AvatarBase
        size={size}
        alt={name}
        src={src ?? ''}
        withBackground={hasSrcError}
        onError={() => setHasSrcError(true)}
        variant={hasSrcError ? 'circular' : 'square'}
      >
        <IconShield size={getIconSizeFromImageSize(size)} color='secondary' />
      </AvatarBase>
    </Tooltip>
  );
};
