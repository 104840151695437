import {
  RecordingFiltersScenariosOrTacticsInside,
  RecordingsFilters,
  RecordingsFiltersEvents,
} from 'shared/types/recording/types';

export enum ActionType {
  SET_EVENTS_STARTING_FILTER,
  SET_EVENTS_ENDING_FILTER,
  SET_RECORDING_IDS,
  SET_SCENARIOS_OR_TACTICS_INSIDE,
  RESET,
}

export type Action =
  | { type: ActionType.SET_EVENTS_STARTING_FILTER; payload: RecordingsFiltersEvents | undefined }
  | { type: ActionType.SET_EVENTS_ENDING_FILTER; payload: RecordingsFiltersEvents | undefined }
  | { type: ActionType.SET_RECORDING_IDS; payload: string[] }
  | { type: ActionType.SET_SCENARIOS_OR_TACTICS_INSIDE; payload: RecordingFiltersScenariosOrTacticsInside | undefined }
  | { type: ActionType.RESET };

export const reducer = (state: RecordingsFilters, action: Action): RecordingsFilters => {
  switch (action.type) {
    case ActionType.RESET:
      return { recordingIds: state.recordingIds };

    case ActionType.SET_EVENTS_STARTING_FILTER: {
      return {
        ...state,
        eventsStarting: action.payload,
      };
    }

    case ActionType.SET_EVENTS_ENDING_FILTER: {
      return {
        ...state,
        eventsEnding: action.payload,
      };
    }

    case ActionType.SET_SCENARIOS_OR_TACTICS_INSIDE: {
      return {
        ...state,
        scenariosOrTacticsInside: action.payload,
      };
    }
    default:
      return state;
  }
};
