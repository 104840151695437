import { IconClose } from 'kognia-ui/icons/IconClose';
import { IconDone } from 'kognia-ui/icons/IconDone';
import { useTranslation } from 'react-i18next';

import { queryClient } from 'api/config';
import { generateFetchRecordingsQueryRef, useRecordingsListFilters } from 'api/recording/useFetchRecordings';
import { useInvalidateTacticalAnalysis } from 'api/tactical-analysis/useInvalidateTacticalAnalysis';
import { useValidateTacticalAnalysis } from 'api/tactical-analysis/useValidateTacticalAnalysis';
import { TacticalAnalysisApiResponse } from 'api/types';
import { MenuListOption } from 'shared/components/kebab-menu';
import { useClientIdValue } from 'shared/contexts/app-state/hooks/useClientIdValue';
import { useUser } from 'shared/contexts/app-state/hooks/useUser';
import { UserAuthority } from 'shared/types';
import { TacticalAnalysis, TacticalAnalysisStates } from 'shared/types/recording/types';

export const useRecordingValidationOption = (tacticalAnalysis: TacticalAnalysis | null) => {
  const { t } = useTranslation();
  const user = useUser();

  const clientId = useClientIdValue();
  const filters = useRecordingsListFilters();
  const fetchQueryRef = generateFetchRecordingsQueryRef(clientId);
  const onSuccess = (tacticalAnalysis: TacticalAnalysisApiResponse) =>
    queryClient.setQueryData([fetchQueryRef, filters], (data: any) => ({
      pages: data.pages.map((page: any) => ({
        ...page,
        data: {
          ...page.data,
          recordings: page.data.recordings.map((aRecording: any) => {
            if (aRecording.id !== tacticalAnalysis.recordingId) return aRecording;
            return { ...aRecording, tacticalAnalysis: tacticalAnalysis };
          }),
        },
      })),
      pageParams: data.pageParams,
    }));

  const { validateTacticalAnalysis } = useValidateTacticalAnalysis(onSuccess);
  const { invalidateTacticalAnalysis } = useInvalidateTacticalAnalysis(onSuccess);

  const validationOption: MenuListOption[] =
    tacticalAnalysis && user.authorities.includes(UserAuthority.ROLE_ADMIN)
      ? [
          tacticalAnalysis.state !== TacticalAnalysisStates.VALIDATED
            ? {
                icon: <IconDone size='small' color='secondary' />,
                displayText: t('recordings-list:recording-card.validate-tactical-analysis'),
                onClick: (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                  event && event.stopPropagation();
                  validateTacticalAnalysis(tacticalAnalysis.id);
                },
              }
            : {
                icon: <IconClose size='small' color='secondary' />,
                displayText: t('recordings-list:recording-card.invalidate-tactical-analysis'),
                onClick: (event?: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
                  event && event.stopPropagation();
                  invalidateTacticalAnalysis(tacticalAnalysis.id);
                },
              },
        ]
      : [];

  return validationOption;
};
