import { PlayerStateMachineContext } from 'shared/components/video-player/types';
import { getVideoSourceByIndex } from 'shared/components/video-player/util';

export const jumpToVideoSource = (videoSourceIndex: number, context: PlayerStateMachineContext) => {
  const videoSource = getVideoSourceByIndex(
    context.playlist.playingItem.playlistItem,
    context.playlist.currentSelectedPlayingMode,
    videoSourceIndex,
  );

  return {
    currentTime: videoSource.startTime,
    playlist: {
      ...context.playlist,
      playingItem: {
        ...context.playlist.playingItem,
        videoSourceIndex: videoSourceIndex,
      },
    },
  };
};
