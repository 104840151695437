import { useCallback } from 'react';

import { PostNewPlaylistItem } from 'api/playlist/types';
import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { useGeneratePlaylistItemName } from 'pages/tactical-analysis/components/timeline/timeline-table/hooks/use-generate-playlist-item-name';
import { FundamentalsSelection } from 'shared/types/playlist/types';

interface Params {
  recordingId: string;
}

export const useMapClipsToPlaylistItems = ({ recordingId }: Params) => {
  const generatePlaylistItemName = useGeneratePlaylistItemName(recordingId);

  return useCallback(
    ({
      items,
      playlistId,
      fundamentalsSelected,
    }: {
      items: Clip[];
      playlistId: string;
      fundamentalsSelected: FundamentalsSelection;
    }): PostNewPlaylistItem[] =>
      items.map(
        (item) =>
          <PostNewPlaylistItem>{
            endTime: item.endTime,
            startTime: item.startTime,
            recordingId: recordingId,
            playlistId: playlistId,
            fundamentalsSelected: fundamentalsSelected,
            name: generatePlaylistItemName(item),
          },
      ),
    [generatePlaylistItemName, recordingId],
  );
};
