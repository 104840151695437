import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useDates } from 'shared/hooks/use-dates';

interface FullMatchParams {
  homeTeam: string;
  awayTeam: string;
  date: Date;
}

interface EffectiveTimeParams extends FullMatchParams {}

export const useDownloadRequestName = () => {
  const { t } = useTranslation('download-manager');
  const { dateToString } = useDates();

  const createFullPlaylistName = useCallback(
    (playlistTitle: string) => {
      return `${playlistTitle} (${t('download-manager:download-request-name.full-playlist')})`;
    },
    [t],
  );

  const createClipsName = useCallback(
    (playlistTitle: string, clipsCount: number) => {
      return `${playlistTitle} (${t('download-manager:download-request-name.clips', {
        count: clipsCount,
      })})`;
    },
    [t],
  );

  const createFullMatchName = useCallback(
    ({ awayTeam, homeTeam, date }: FullMatchParams) => {
      return `${homeTeam} vs ${awayTeam} ${dateToString(date)} (${t(
        'download-manager:download-request-name.full-match',
      )})`;
    },
    [t, dateToString],
  );

  const createEffectiveTimeName = useCallback(
    ({ awayTeam, homeTeam, date }: EffectiveTimeParams) => {
      return `${homeTeam} vs ${awayTeam} ${dateToString(date)} (${t(
        'download-manager:download-request-name.effective-time',
      )})`;
    },
    [t, dateToString],
  );

  return { createFullPlaylistName, createClipsName, createFullMatchName, createEffectiveTimeName };
};
