import { OverlayGeneratorChunkData } from '../index';

export const preloadData = (
  data: OverlayGeneratorChunkData,
  currentChunkNumber: number,
  currentFrame: number,
  chunkSize: number,
) => {
  const percentageOfChunkToStartPreload = 0.5;
  const isNextChunkLoaded = data[currentChunkNumber + 1];
  const shouldPreloadNextChunk = currentFrame % chunkSize > chunkSize * percentageOfChunkToStartPreload;

  return !isNextChunkLoaded && shouldPreloadNextChunk;
};
