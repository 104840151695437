import { Tooltip } from '@mui/material';
import classNames from 'classnames';
import { IconShield } from 'kognia-ui/icons/IconShield';

import styles from 'pages/recordings-list/components/vod-recording-card/RecordingCard.module.scss';
import { RecordingTeam } from 'pages/recordings-list/types/recording';

type ScoresInfoProps = {
  teams: RecordingTeam[];
};

const ScoresInfo = ({ teams }: ScoresInfoProps) => {
  return (
    <>
      <div className={styles.shield}>
        <Tooltip title={teams[0]?.name ?? ''}>
          {!!teams[0].logo ? (
            <img
              className={classNames(styles['recording-card__score-logo'], styles['recording-card__score-logo--left'])}
              src={teams[0].logo}
              alt={teams[0].name}
            />
          ) : (
            <div className={styles.icon}>
              <IconShield size='small' color='secondary' />
            </div>
          )}
        </Tooltip>
      </div>
      <span className={styles['recording-card__score']}>
        <span
          className={classNames(styles['recording-card__score-number'], styles['recording-card__score-number--left'])}
        >
          {teams[0].score}
        </span>
        <span
          className={classNames(styles['recording-card__score-number'], styles['recording-card__score-number--right'])}
        >
          {teams[1].score}
        </span>
      </span>
      <div className={styles.shield}>
        <Tooltip title={teams[1]?.name ?? ''}>
          {!!teams[1].logo ? (
            <img
              className={classNames(styles['recording-card__score-logo'], styles['recording-card__score-logo--right'])}
              src={teams[1].logo}
              alt={teams[1].name}
            />
          ) : (
            <div className={styles.icon}>
              <IconShield size='small' />
            </div>
          )}
        </Tooltip>
      </div>
    </>
  );
};

export default ScoresInfo;
