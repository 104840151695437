import { atom, useAtomValue, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';

import { RecordingsFilters } from 'shared/types/recording/types';

const playlistMultimatchAppliedFiltersAtom = atomFamily(() =>
  atom<RecordingsFilters>({
    recordingIds: [],
  }),
);

export const useSetPlaylistMultimatchAppliedFilters = (id: string) => {
  return useSetAtom(playlistMultimatchAppliedFiltersAtom(id));
};

export const usePlaylistMultimatchAppliedFilters = (id: string) => {
  return useAtomValue(playlistMultimatchAppliedFiltersAtom(id));
};
