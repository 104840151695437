import React, { CSSProperties, useMemo, useRef } from 'react';
import { useLongPress } from 'use-long-press';

export type ButtonProps = {
  submit?: boolean;
  disabled?: boolean;
  className?: string;
  style?: CSSProperties;
  children?: React.ReactNode;
  onPress?: () => void;
  onPressStart?: () => void;
  onPressEnd?: () => void;
  onLongPress?: () => void;
  onAnimationEnd?: () => void;
  onClick?: () => void;
};

export const Button = (props: ButtonProps) => {
  const { onPress, onPressStart, onPressEnd, onLongPress, onAnimationEnd, submit, ...buttonProps } = props;

  const pressTriggeredRef = useRef<boolean>(false);
  const longPressTriggeredRef = useRef<boolean>(false);

  const opts = useMemo(() => {
    return {
      onStart: () => {
        longPressTriggeredRef.current = false;
        pressTriggeredRef.current = false;
        onPressStart?.();
      },
      onCancel: () => {
        pressTriggeredRef.current = true;
        onPressEnd?.();
        if (!longPressTriggeredRef.current) {
          onPress?.();
        }
      },
      onFinish: () => {
        pressTriggeredRef.current = true;
        onPressEnd?.();
        if (!longPressTriggeredRef.current) {
          onPress?.();
        }
      },
      onLongPress: () => {
        longPressTriggeredRef.current = true;
        onLongPress?.();
      },
      onClick: (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        ev.preventDefault();
        if (pressTriggeredRef.current || longPressTriggeredRef.current) {
          return;
        }
        onPress?.();
      },
    };
  }, [onLongPress, onPress, onPressStart, onPressEnd]);

  const bind = useLongPress(opts.onLongPress, opts);

  return (
    <button
      {...buttonProps}
      onAnimationEnd={onAnimationEnd}
      type={submit === true ? 'submit' : 'button'}
      onClick={props.onClick ?? opts.onClick}
      {...bind}
    />
  );
};
