import memoize from 'lodash/memoize';

import { TeamTypes } from 'pages/tactical-analysis/api/types';
import { MatchTeam, MatchTeams } from 'shared/types/match';

export const getTeamType = memoize((teamId: string, teams: MatchTeams): TeamTypes => {
  if (teamId === teams.homeTeam.id) return TeamTypes.HOME;
  return TeamTypes.OPPONENT;
});

export const getPlayerName = (playerId: string, teams: MatchTeam[]): string | undefined =>
  teams.flatMap((team) => team.players).find((teamPlayer) => teamPlayer.id === playerId)?.name;
