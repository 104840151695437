import { Box, Stack } from '@mui/material';
import { useEffect, useState } from 'react';

import { useTeamWithDefaults } from 'entities/dashboard/api/useTeamWithDefaults';
import { TeamProfileHeaderFeature } from 'features/dashboard/team-profile-header-feature/TeamProfileHeader.feature';
import { TeamCompetitionsFeature } from 'features/dashboard/TeamCompetitions.feature';
import { Dialog } from 'shared/components/dialog';
import { useGenerateTemporaryPlaylist } from 'shared/hooks/useGenerateTemporaryPlaylist';
import { Playlist } from 'shared/types';
import { TeamProfileDashboardWidget } from 'widgets/dashboard/TeamProfileDashboard.widget';
import { TemporaryPlaylistWidget } from 'widgets/playlist/temporary-playlist/TemporaryPlaylist.widget';

type Props = {
  teamId: string;
};

export const TeamProfile = ({ teamId }: Props) => {
  const { data: team } = useTeamWithDefaults(teamId);

  const [selectedSeasonYear, setSelectedSeasonYear] = useState<number | undefined>();
  const [selectedCompetition, setSelectedCompetition] = useState<string | undefined>();

  const [temporalPlaylist, setTemporalPlaylist] = useState<Playlist | null>(null);
  const generateTemporaryPlaylist = useGenerateTemporaryPlaylist({ onSuccess: setTemporalPlaylist });

  useEffect(() => {
    if (!team) return;

    setSelectedSeasonYear(team.teamDefaults?.season?.year);
    setSelectedCompetition(team.teamDefaults?.season?.competition?.id);
  }, [team]);

  if (!team) return null;

  return (
    <>
      <Stack gap={2} padding={(theme) => theme.spacing(1.5, 4, 2, 4)}>
        <TeamProfileHeaderFeature />

        <TeamCompetitionsFeature
          selectedSeasonYear={selectedSeasonYear}
          selectedCompetition={selectedCompetition}
          onChangeSelectedCompetition={setSelectedCompetition}
          onChangeSelectedSeasonYear={setSelectedSeasonYear}
        />
      </Stack>

      {selectedCompetition && selectedSeasonYear && (
        <>
          <TeamProfileDashboardWidget
            competitionId={selectedCompetition}
            key={`${selectedCompetition}-${selectedSeasonYear}`}
            onEvent={generateTemporaryPlaylist}
            seasonYear={selectedSeasonYear}
            team={team}
          />
          <Dialog onClose={() => setTemporalPlaylist(null)} open={!!temporalPlaylist} fullWidth maxWidth={false}>
            <Box
              height={(theme) => `calc(100vh - ${theme.spacing(8)})`}
              width={(theme) => `calc(100vw - ${theme.spacing(8)})`}
            >
              {temporalPlaylist && <TemporaryPlaylistWidget playlist={temporalPlaylist} />}
            </Box>
          </Dialog>
        </>
      )}
    </>
  );
};
