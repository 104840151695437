import { Colors } from 'kognia-ui';

import { TypeOfPlay } from 'shared/types/type-of-play/types';

export const getIconPlayColor = ({ disabled, typeOfPlay }: { disabled: boolean; typeOfPlay: TypeOfPlay }): string => {
  if (disabled) {
    return Colors.iron;
  }

  switch (typeOfPlay) {
    case TypeOfPlay.Offense:
      return Colors.primary;
    case TypeOfPlay.Defense:
      return Colors.green;
    case TypeOfPlay.Transition:
      return Colors.peach;
    case TypeOfPlay.GameEvent:
      return Colors.shark;
    default:
      return Colors.night;
  }
};
