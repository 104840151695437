import { Stack, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { IconTime } from 'kognia-ui/icons/IconTime';
import { useTranslation } from 'react-i18next';

import { BadgeText } from 'entities/playlist/ui/BadgetText';
import { Playlist } from 'shared/types';
import { secondsAsDuration } from 'shared/utils/seconds-as-duration';

type Props = {
  playlist: Playlist;
};

export const PlaylistDetails = ({ playlist }: Props) => {
  const { t } = useTranslation();

  return (
    <Stack direction='row' spacing={1} alignItems={'center'}>
      <Typography fontSize={fontSizes.small}>{t('playlist-detail:all-playlist')}</Typography>
      <BadgeText>{t('playlist-detail:clips', { count: playlist.playlistItems.length })}</BadgeText>
      <BadgeText sx={{ paddingLeft: 0 }}>
        <IconTime size='small' color='secondary' /> {secondsAsDuration(playlist.duration, false)}
      </BadgeText>
    </Stack>
  );
};
