import { IconUser } from 'kognia-ui/icons/IconUser';

import { CoachPhotoSkeletonContainer } from 'pages/backoffice/pages/coaches/components/coach-photo-skeleton/styled';

interface Props {
  small?: boolean;
}

export const CoachPhotoSkeleton = ({ small }: Props) => {
  return (
    <CoachPhotoSkeletonContainer small={small}>
      <IconUser color='secondary' size={small ? 'xsmall' : 'medium'} />
    </CoachPhotoSkeletonContainer>
  );
};
