import { Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';

import { PlaylistMenuItem } from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/add-to-playlist-menu-state/atoms';
import { useRemovePlaylistMenuItem } from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/add-to-playlist-menu-state/hooks';
import { CheckboxWithCustomColor } from 'shared/components/CheckboxWithCustomColor/CheckboxWithCustomColor';

interface Props {
  item: PlaylistMenuItem;
}

const SelectedPlaylistItem = ({ item }: Props) => {
  const removePlaylistMenuItem = useRemovePlaylistMenuItem(item.id);

  return (
    <Typography
      key={item.id}
      py={0}
      px={2}
      color='common.white'
      fontSize={fontSizes.small}
      fontWeight='fontWeightRegular'
    >
      <CheckboxWithCustomColor customColor='white' checked={true} onChange={removePlaylistMenuItem} />
      {item.name}
    </Typography>
  );
};

export default SelectedPlaylistItem;
