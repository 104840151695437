import { useLocation, useParams } from 'react-router-dom';

import { useMetadataFixture } from 'pages/backoffice/api/fixtures/use-metadata-fixture';
import { FixtureInfoForm } from 'pages/backoffice/pages/fixtures/fixture-form/components/fixture-info-form';
import { getCurrentStepFromPathName, useCheckValidateStep } from 'pages/backoffice/pages/fixtures/fixture-form/config';

export const FixtureFormInfo = () => {
  const location = useLocation();
  const { id } = useParams();
  const { data: fixture, isLoading } = useMetadataFixture({ fixtureId: id });
  const step = getCurrentStepFromPathName(location.pathname);

  useCheckValidateStep(step);

  return <>{isLoading && id ? null : <FixtureInfoForm fixture={fixture} step={step} />}</>;
};
