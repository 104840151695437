import map from 'lodash/map';
import React from 'react';
import { useTranslation } from 'react-i18next';

import FundamentalsSummary from 'pages/tactical-analysis/components/tactical-analysis/filters/components/filters-summary/scenarios-inside-episode-summary/fundamentals-summary';
import FiltersSummaryItem from 'pages/tactical-analysis/components/tactical-analysis/filters/components/filters-summary-item';
import {
  defaultFundamentalsFilters,
  defaultFundamentalWithPlayers,
  defaultScenarioInsideAnEpisode,
} from 'pages/tactical-analysis/components/tactical-analysis/filters/default';
import { FundamentalWithPlayers, Zones } from 'pages/tactical-analysis/components/tactical-analysis/filters/types';
import { findTeamById } from 'pages/tactical-analysis/components/tactical-analysis/utils/find-team-by-id';
import { getTeamType } from 'pages/tactical-analysis/components/tactical-analysis/utils/get-team-type';
import { useTacticalAnalysisTeams } from 'pages/tactical-analysis/hooks/use-tactical-analysis-teams';
import ActiveFilter, { DefaultVariant } from 'shared/components/active-filter';
import ActiveFilterTeam from 'shared/components/active-filter-team';
import ActiveFilters from 'shared/components/active-filters';
import { MatchTeams } from 'shared/types/match';
import { RecordingFiltersScenariosOrTacticsInside } from 'shared/types/recording/types';

interface Props {
  contained?: boolean;
  filters: RecordingFiltersScenariosOrTacticsInside;
  showCloseButton?: boolean;
  teams: MatchTeams;
  recordingId: string;
  onUpdate?: (filters: RecordingFiltersScenariosOrTacticsInside) => void;
  hasTeamFocus?: boolean;
}

export const ScenariosInsideEpisodeSummary = ({
  teams,
  filters,
  contained,
  showCloseButton,
  onUpdate,
  recordingId,
  hasTeamFocus,
}: Props) => {
  const { homeTeam, opponentTeam } = useTacticalAnalysisTeams(recordingId);
  const { t } = useTranslation();

  if (JSON.stringify(filters) === JSON.stringify(defaultScenarioInsideAnEpisode)) return null;

  const offensiveTeamId = filters.teams.includes(homeTeam?.id ?? '') ? homeTeam?.id : '';
  const defensiveTeamId = filters.teams.includes(opponentTeam?.id ?? '') ? opponentTeam?.id : '';

  const offensiveTeamType = offensiveTeamId ? getTeamType(offensiveTeamId, teams) : DefaultVariant.DEFAULT;
  const defensiveTeamType = defensiveTeamId ? getTeamType(defensiveTeamId, teams) : DefaultVariant.DEFAULT;

  const clearFundamentalTeamFilter = (variant: 'offensive' | 'defensive') => {
    onUpdate &&
      onUpdate({
        ...filters,
        tactics: {
          ...filters.tactics,
          [variant]: {
            ...filters.tactics[variant],
            teams: [],
          },
        },
      });
  };

  const clearTeamFilter = () => {
    filters.teams ? onUpdate && onUpdate({ ...filters, teams: [] }) : clearFundamentalTeamFilter('offensive');
  };

  const clearTacticalFilter = () => {
    onUpdate && onUpdate({ ...filters, tactics: { offensive: [], defensive: [] } });
  };

  const updateZoneFilter = (zone: Zones) => {
    onUpdate && onUpdate({ ...filters, zones: [...filters.zones, zone] });
  };

  const clearAllFilters = () => {
    onUpdate && onUpdate(defaultScenarioInsideAnEpisode);
  };

  const clearFundamental = (variant: 'offensive' | 'defensive', fundamentalId: string) => {
    const filteredFundamentals = filters.tactics[variant].map((fundamental) => {
      if (fundamental.tacticalFundamentalType !== fundamentalId) return fundamental;

      if (
        fundamental.tacticalFundamentalType === fundamentalId &&
        fundamental.playerIds.some((player) => Boolean(player))
      )
        return {
          id: '',
          players: fundamental.playerIds,
        } as FundamentalWithPlayers;

      if (
        fundamental.tacticalFundamentalType === fundamentalId &&
        !fundamental.playerIds.some((player) => Boolean(player))
      )
        return defaultFundamentalWithPlayers;
    });

    onUpdate &&
      onUpdate({
        ...filters,
        tactics: {
          ...filters.tactics,
          [variant]: {
            ...filters.tactics[variant],
            fundamentals: filteredFundamentals.length > 0 ? filteredFundamentals : [defaultFundamentalWithPlayers],
          },
        },
      });
  };

  const clearFundamentalPlayer = (variant: 'offensive' | 'defensive', playerId: string, fundamentalId: string) => {
    const filteredFundamentalPlayers = filters.tactics[variant].map((fundamental) => {
      return fundamental.tacticalFundamentalType === fundamentalId
        ? ({
            id: fundamental.tacticalFundamentalType,
            players: fundamental.playerIds.filter((player) => player !== playerId),
          } as FundamentalWithPlayers)
        : fundamental;
    });

    onUpdate &&
      onUpdate({
        ...filters,
        tactics: {
          ...filters.tactics,
          [variant]: {
            ...filters.tactics[variant],
            fundamentals:
              filteredFundamentalPlayers.length > 0 ? filteredFundamentalPlayers : [defaultFundamentalWithPlayers],
          },
        },
      });
  };

  const offensiveTeams = [
    ...new Set(
      filters.tactics.offensive
        .map((o) => o.teamIds)
        .flat()
        .concat(filters.teams),
    ),
  ];
  const defensiveTeams = [...new Set(filters.tactics.defensive.map((o) => o.teamIds).flat())];

  return (
    <FiltersSummaryItem title={t('recordings:filters.tabs.scenarios-inside-episode')}>
      <ActiveFilters>
        {filters.scenario && (
          <ActiveFilter
            onClose={clearTacticalFilter}
            closeButton={showCloseButton}
            contained={contained}
            title={t(`fundamentals:tactical-scenario-types.${filters.scenario}`)}
            variant={offensiveTeamType}
          />
        )}
        {JSON.stringify(filters.tactics.offensive) !== JSON.stringify(defaultFundamentalsFilters) && (
          <>
            {offensiveTeams.map((teamId) => (
              <ActiveFilterTeam
                key={teamId}
                hasTeamFocus={hasTeamFocus}
                onClose={clearTeamFilter}
                action={'OFFENSIVE'}
                contained={contained}
                showCloseButton={showCloseButton}
                team={findTeamById(teams, teamId)}
                teamType={getTeamType(teamId, teams)}
              />
            ))}

            <FundamentalsSummary
              contained={contained}
              fundamentals={filters.tactics.offensive}
              onClose={(fundamental: string) => clearFundamental('offensive', fundamental)}
              onClosePlayer={(player: string, fundamental: string) =>
                clearFundamentalPlayer('offensive', player, fundamental)
              }
              showCloseButton={showCloseButton}
              teamId={offensiveTeamId}
              teams={teams}
            />
          </>
        )}
        {map(filters.zones, (value, idx) =>
          value ? (
            <ActiveFilter
              onClose={() => {
                const zone = Number(idx) as Zones;
                updateZoneFilter(zone);
              }}
              closeButton={showCloseButton}
              contained={contained}
              key={idx}
              title={`${t('recordings:filters.zone')} ${idx}`}
              variant={offensiveTeamType}
            />
          ) : null,
        )}
        {showCloseButton && <ActiveFilter onClose={clearAllFilters} closeButton variant={offensiveTeamType} />}
      </ActiveFilters>
      {JSON.stringify(filters.tactics.defensive) !== JSON.stringify(defaultFundamentalsFilters) && (
        <ActiveFilters>
          {defensiveTeams.map((teamId) => (
            <ActiveFilterTeam
              key={teamId}
              hasTeamFocus={hasTeamFocus}
              onClose={() => clearFundamentalTeamFilter('defensive')}
              action={'DEFENSIVE'}
              contained={contained}
              showCloseButton={showCloseButton}
              team={findTeamById(teams, teamId)}
              teamType={getTeamType(teamId, teams)}
            />
          ))}

          <FundamentalsSummary
            contained={contained}
            fundamentals={filters.tactics.defensive}
            onClose={(fundamental) => clearFundamental('defensive', fundamental)}
            onClosePlayer={(player: string, fundamental: string) =>
              clearFundamentalPlayer('defensive', player, fundamental)
            }
            showCloseButton={showCloseButton}
            teamId={defensiveTeamId}
            teams={teams}
          />
          {showCloseButton && <ActiveFilter onClose={clearAllFilters} closeButton variant={defensiveTeamType} />}
        </ActiveFilters>
      )}
    </FiltersSummaryItem>
  );
};
