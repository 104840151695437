import { Box, Paper, Stack, Tab, Tabs, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { ReactNode, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PlayerFavoritesList } from 'features/dashboard/PlayerFavoritesList';
import { TeamFavoritesList } from 'features/dashboard/TeamFavoritesList';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`favorite-tabpanel-${index}`}
      aria-labelledby={`favorite-tab-${index}`}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `favorite-tab-${index}`,
    'aria-controls': `favorite-tabpanel-${index}`,
  };
}

export const FavoritesCardWidget = () => {
  const [value, setValue] = useState(0);
  const { t } = useTranslation(['common']);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Paper elevation={0} sx={{ borderRadius: 2 }}>
      <Stack padding={3}>
        <Typography variant='h3' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'} mb={3}>
          {t('favorites.title')}
        </Typography>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label='favorites tabs'>
              <Tab label={t('players')} {...a11yProps(0)} />
              <Tab label={t('teams')} {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <PlayerFavoritesList />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TeamFavoritesList />
          </TabPanel>
        </Box>
      </Stack>
    </Paper>
  );
};
