import { Button, ButtonGroup, Stack } from '@mui/material';

import { ProcessFixturePreset } from 'pages/backoffice/entities/process-fixture/types/processFixture.types';
import { FormField } from 'shared/components/form/form-field';

type Props = {
  onChange: (preset: ProcessFixturePreset) => void;
  selectedPreset: ProcessFixturePreset | undefined;
};

export const PresetSelector = ({ onChange, selectedPreset }: Props) => {
  const handleSelectPreset = (preset: ProcessFixturePreset) => {
    onChange(preset);
  };

  return (
    <Stack direction={'row'} gap={2} alignItems={'center'} justifyContent={'left'}>
      <FormField id={'preset'} label={'Preset'}>
        <ButtonGroup color='primary' aria-label='outlined primary button group'>
          {Object.values(ProcessFixturePreset).map((preset) => (
            <Button
              size={'large'}
              variant={selectedPreset === preset ? 'contained' : 'outlined'}
              color={'primary'}
              key={preset}
              onClick={() => handleSelectPreset(preset)}
              sx={{ textTransform: 'capitalize' }}
            >
              {preset}
            </Button>
          ))}
        </ButtonGroup>
      </FormField>
    </Stack>
  );
};
