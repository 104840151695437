import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

const MENU_WIDTH = '400px';

export const AddToPlaylistMenuContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isClosed',
})<{ isClosed: boolean }>(({ theme, isClosed }) => ({
  // TODO add to theme
  background: Colors.shark,
  width: MENU_WIDTH,
  height: '100%',
  transition: theme.transitions.create('width', {
    duration: '0.5s',
  }),
  overflowX: 'hidden',

  ...(isClosed && {
    minWidth: 0,
    width: 0,
  }),
}));

export const AddToPlaylistMenuContent = styled(Box)({
  display: 'flex',
  flexFlow: 'column',
  width: MENU_WIDTH,
  height: '100%',
});
