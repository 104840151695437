import { useCallback } from 'react';

import { useSaveUserPreset } from 'api/user-presets/use-save-user-preset';
import { PlaylistMultimatchAppliedFiltersPreset } from 'shared/constants/user-presets/userPresetsClasses';
import { RecordingsFilters } from 'shared/types/recording/types';

export const useSavePlaylistMultimatchFiltersAppliedPreset = () => {
  const { savePreset } = useSaveUserPreset();

  return useCallback(
    (playlistId: string, filters: RecordingsFilters) => {
      const playingModePreset = new PlaylistMultimatchAppliedFiltersPreset(filters, playlistId);
      savePreset({ data: playingModePreset });
    },
    [savePreset],
  );
};
