import { Button, styled } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';

export const SelectButton = styled(Button)({
  color: Colors.white,
  fontSize: fontSizes.xSmall,
  fontWeight: fontWeight['500'],

  '&.Mui-disabled': {
    color: Colors.storm,
  },
});

export const sxSelectPaperStyles = {
  backgroundColor: Colors.night,
  color: Colors.white,
  minWidth: '327px',
  border: `1px solid ${Colors.storm}`,
  marginTop: 2,
  padding: 2,
};
