import { Box, IconButton } from '@mui/material';
import { IconClose } from 'kognia-ui/icons/IconClose';
import { IconSearch } from 'kognia-ui/icons/IconSearch';

import Spinner from 'shared/components/spinner';

type Props = {
  searchValue: string;
  isLoading: boolean;
  handleReset: () => void;
};

export const DashboardSearchIcon = ({ searchValue, isLoading, handleReset }: Props) => {
  if (isLoading)
    return (
      <Box paddingRight={0.5}>
        <Spinner size={'small'} />
      </Box>
    );
  if (searchValue)
    return (
      <IconButton size={'small'} onClick={handleReset}>
        <IconClose size={'small'} />
      </IconButton>
    );

  return (
    <Box padding={'5px'}>
      <IconSearch size='small' color='secondary' />
    </Box>
  );
};
