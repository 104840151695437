import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';

interface Dictionary<T> {
  [key: string]: T;
}

export const getQueryParams = <T extends Dictionary<any>>(queryParams: T) => {
  return new URLSearchParams({ ...omitBy(queryParams, (value) => isUndefined(value) || value === '') }).toString();
};
