import { MutateOptions } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { transformPlaylist } from 'api/playlist/transformers';
import { PlaylistApiResponse } from 'api/playlist/types';
import { duplicatePlaylistItemsUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { Playlist } from 'shared/types';

export const useDuplicatePlaylistItems = (playlistId: string) => {
  const { t } = useTranslation();

  const { mutate, isPending, isError, isSuccess } = useMutationRequest<PlaylistApiResponse, Playlist>({
    type: HTTPMethod.POST,
    transformer: transformPlaylist,
    errorMessage: t('api:use-duplicate-playlist-items.error'),
    successMessage: t('api:use-duplicate-playlist-items.success'),
  });

  const duplicatePlaylistItems = useCallback(
    ({
      playlistItemIds,
      options,
    }: {
      playlistItemIds: string[];
      options?: MutateOptions<Playlist, unknown, unknown>;
    }) => {
      const url = duplicatePlaylistItemsUrl(playlistId);

      return mutate({ url, data: playlistItemIds }, options);
    },
    [mutate, playlistId],
  );

  return { duplicatePlaylistItems, isPending, isError, isSuccess };
};
