import { Typography, styled } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export const PlaylistSelectorMenuTitle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
  color: theme.palette.common.white,
  fontSize: theme.typography.fontSize,
  // TODO add in theme
  lineHeight: 1.77,
  fontWeight: theme.typography.fontWeightMedium,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  cursor: 'pointer',
}));

export const PlaylistSelectorMenuLink = styled(RouterLink)({
  marginLeft: 'auto',
  textDecoration: 'none',
  cursor: 'pointer',

  '&:after': {
    display: 'none',
  },
});
