import makeStyles from '@mui/styles/makeStyles';
import { Colors } from 'kognia-ui';

export const useAutocompleteStyles = makeStyles(() => ({
  root: {
    minHeight: '40px',
  },
  inputRoot: {
    paddingRight: `8px !important`,
  },
  endAdornment: {
    position: 'absolute',
    top: `calc(50% - 16px})`,
  },
  option: {
    padding: '0 !important',
    transition: 'all 0.2s ease-out',
    // Hover
    '&[data-focus="true"]': {
      backgroundColor: Colors.athens,
    },
    // Selected
    '&[aria-selected="true"]': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: Colors.athens,
      },
    },
  },
}));
