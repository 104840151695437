import axios, { AxiosError } from 'axios';

import { nextServiceError } from 'tagging-tool/container/serviceError';
import { getStorageExtraRequestHeaders } from 'tagging-tool/service/storage';
import { normalizeAxiosResponseHeaders } from 'tagging-tool/utility/normalizeAxiosResponseHeaders';

export type ServiceResponse<Response> =
  | { error: false; data: Response; headers: { [header: string]: string } }
  | ({ error: true } & ServiceError);

/**
 * The SDK *sanitizes* all possible network related errors adopting `ServiceError` object spec.
 */
export type ServiceError = {
  /** HTTP response status or `0` if no network error trigger the error. */
  status: number;
  /** Message from the endpoint response. */
  message?: string;
  /** Message with error from the endpoint response. */
  errors: { [key: string]: Array<string> };
};

export type FetchRequestOptions = {
  url: string;
  method?:
    | 'get'
    | 'GET'
    | 'post'
    | 'POST'
    | 'put'
    | 'PUT'
    | 'patch'
    | 'PATCH'
    | 'delete'
    | 'DELETE'
    | 'head'
    | 'HEAD'
    | 'options'
    | 'OPTIONS'
    | 'purge'
    | 'PURGE'
    | 'link'
    | 'LINK'
    | 'unlink'
    | 'UNLINK';
  body?: unknown;
  headers?: { [key: string]: string };
  emitServiceError?: boolean;
};

// fetch

export const fetchRequest = <Response>(
  options: FetchRequestOptions & { mock?: Response },
): Promise<ServiceResponse<Response>> => {
  const extraHeaders = getStorageExtraRequestHeaders();

  const url = `${options.url}`;
  const method = options.method ?? (options.body !== undefined ? 'post' : 'get');
  return new Promise((resolve) => {
    if (options.mock) {
      const data = options.mock;
      return setTimeout(() => {
        resolve({ error: false, data, headers: {} });
      }, 126);
    }
    axios(url, {
      withCredentials: true,
      method,
      headers: {
        ...extraHeaders,
        ...options.headers,
      },
      data: options.body,
    })
      .then(({ data, headers }) => {
        const normalizedHeaders = normalizeAxiosResponseHeaders(headers);

        resolve({ error: false, data, headers: normalizedHeaders });
      })
      .catch((err: AxiosError<any>) => {
        // Axios changed the typing of AxiosError from AxiosError<T = any, D = any> in version 0.26.0
        // to AxiosError<T = unknown, D = any> in 0.27.0
        let message = '';
        const _message: { [k: string]: any } | string | undefined = err.response?.data?.message ?? err.response?.data;

        if (typeof _message === 'string' && _message.indexOf('<html') > -1) {
          message = 'IGNORED_HTML_MESSAGE';
        } else if (typeof _message === 'string') {
          message = _message;
        } else if (typeof _message === 'object') {
          message = JSON.stringify(_message);
        }

        const serviceError = {
          status: err.response?.status ?? 0,
          message,
          errors: err.response?.data?.errors ?? {},
        };

        if (options.emitServiceError !== false) {
          nextServiceError(serviceError);
        }

        resolve({
          error: true,
          ...serviceError,
        });
      });
  });
};
