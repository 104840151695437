import {
  EndPlayTacticalScenarioType,
  ListedTacticalScenarioType,
  StartPlayTacticalScenarioType,
} from 'shared/types/episodes/types';

export const endPlayFundamentals: { [key in EndPlayTacticalScenarioType]: string } = {
  'out_of_play|out_of_bounds|touch_line': 'Throw-in',
  'out_of_play|out_of_bounds|attacking_goal_line': 'Corner Kick',
  'out_of_play|out_of_bounds|defending_goal_line': 'Goal Kick',
  'out_of_play|goal': 'Goal',
  'out_of_play|stopped_by_referee|foul|offside': 'Offside',
  'out_of_play|stopped_by_referee|foul': 'Foul (Free Kick)',
  'out_of_play|stopped_by_referee|foul|in_penalty_area': 'Foul (Penalty Kick)',
  'out_of_play|stopped_by_referee|other_stop': 'Other',
  'out_of_play|end_of_play': 'End of time',
};

export const startPlayFundamentals: { [key in StartPlayTacticalScenarioType]: string } = {
  'in_play|pass|kickoff': 'Kick-off',
  'in_play|pass|goal_kick': 'Goal Kick',
  'in_play|pass|throw_in': 'Throw-in',
  'in_play|pass|corner_kick': 'Corner Kick',
  'in_play|pass|free_kick': 'Free Kick (Pass)',
  'in_play|pass|drop_ball': 'Dropped ball',
  'in_play|shot|free_kick': 'Free Kick (Shot)',
  'in_play|shot|penalty_kick': 'Penalty Kick',
  'in_play|shot|corner_kick': 'Corner Kick (Shot)',
};

export const isEndingAPlayEvent = (event: string): boolean => {
  return Boolean(endPlayFundamentals[event as EndPlayTacticalScenarioType]);
};

export const isStartingAPlayEvent = (event: string): boolean => {
  return Boolean(startPlayFundamentals[event as StartPlayTacticalScenarioType]);
};

export const scenariosInsideAndEpisode: { [key in ListedTacticalScenarioType]: number } = {
  'build-up': 1,
  'build-up-ab': 2,
  'build-up-cd': 3,
  'possession-a': 4,
  'possession-bc': 5,
  'possession-d': 6,
  finishing: 7,
  'possession-a-no-possession': 8,
  'possession-bc-no-possession': 9,
  'possession-d-no-possession': 10,
  'recovery-ball': 11,
  'change-of-possession': 12,
  'change-of-possession-no-possession': 13,
  'long-game': 14,
};
