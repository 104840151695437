import { useRecoilValue, useSetRecoilState } from 'recoil';

import { timelineMatchAtoms } from 'pages/tactical-analysis/atoms';

export const useSetPresetPlayingMode = (recordingId: string) => {
  return useSetRecoilState(timelineMatchAtoms.playingMode(recordingId));
};

export const usePresetPlayingMode = (recordingId: string) => {
  return useRecoilValue(timelineMatchAtoms.playingMode(recordingId));
};
