import { Homography } from '../types';

const MATRIX_SIZE = 3;

const multiply3x3Matrix = (A: number[][], B: number[][]): number[][] => {
  const result: number[][] = Array.from({ length: MATRIX_SIZE }, () => Array(MATRIX_SIZE).fill(0));

  for (let i = 0; i < MATRIX_SIZE; i++) {
    for (let j = 0; j < MATRIX_SIZE; j++) {
      for (let k = 0; k < MATRIX_SIZE; k++) {
        result[i][j] += A[i][k] * B[k][j];
      }
    }
  }

  return result;
};

// Function that given a 3x3 homography matrix it returns the matrix upscale by a value
export const scaleMatrix = (matrix: number[][], matrixScale: number, videoScale: number): Homography => {
  const upscaleMatrix = [
    [1 / matrixScale, 0, 0],
    [0, 1 / matrixScale, 0],
    [0, 0, 1],
  ];

  const videoScaleMatrix = [
    [videoScale, 0, 0],
    [0, videoScale, 0],
    [0, 0, 1],
  ];

  const adjustedByVideoScale = multiply3x3Matrix(videoScaleMatrix, matrix);

  return multiply3x3Matrix(adjustedByVideoScale, upscaleMatrix) as Homography;
};
