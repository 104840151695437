import Hotjar from '@hotjar/browser';

import { User } from 'shared/types/user/types';
import { Environments, getEnvironment } from 'shared/utils/environment';

const siteId = 5206020;
const hotjarVersion = 6;

const excludedEmails = ['@kogniasports.com', 'narcis@goupplayers.com', 'javier.rguezdelvalle@gmail.com'];

export const initHotjar = (user: User, clientId: string) => {
  if (getEnvironment() !== Environments.Production) return;
  excludedEmails.forEach((email) => {
    if (user.email.includes(email)) return;
  });

  Hotjar.init(siteId, hotjarVersion);
  Hotjar.identify(user.id, {
    email: user.email,
    selectedClientId: clientId,
  });
};
