import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

export const useDates = () => {
  const { i18n } = useTranslation();

  const parseBackendDateString = useCallback((dateString: string): Date => {
    return new Date(dateString);
  }, []);

  const dateToString = useCallback(
    (date: Date | string): string => {
      const options = { year: 'numeric', month: 'short', day: 'numeric' } as Intl.DateTimeFormatOptions;

      if (date instanceof Date) {
        return date.toLocaleDateString(i18n.language, options);
      }

      return parseBackendDateString(date).toLocaleDateString(i18n.language, options);
    },
    [i18n.language, parseBackendDateString],
  );

  const dateAndTimeToString = useCallback(
    (date: Date | string): string => {
      const options = {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      } as Intl.DateTimeFormatOptions;

      if (date instanceof Date) {
        return date.toLocaleDateString(i18n.language, options);
      }

      return parseBackendDateString(date).toLocaleDateString(i18n.language, options);
    },
    [i18n.language, parseBackendDateString],
  );

  const dateToTime = useCallback(
    (date: Date | string): string => {
      const options = { hour: 'numeric', minute: 'numeric', second: 'numeric' } as Intl.DateTimeFormatOptions;

      if (date instanceof Date) {
        return date.toLocaleTimeString(i18n.language, options);
      }

      return parseBackendDateString(date).toLocaleTimeString(i18n.language, options);
    },
    [i18n.language, parseBackendDateString],
  );

  const parseDateForApi = useCallback((date: Date): string => {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date
      .getDate()
      .toString()
      .padStart(2, '0')}`;
  }, []);

  return { dateToString, dateAndTimeToString, dateToTime, parseBackendDateString, parseDateForApi };
};
