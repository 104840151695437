import { Typography } from '@mui/material';
import { fontSizes, fontWeight } from 'kognia-ui';

type PlainTextProps = {
  originalText: string;
};

export const PlainText = ({ originalText }: PlainTextProps) => (
  <Typography fontSize={fontSizes.default} fontWeight={fontWeight['500']}>
    {originalText}
  </Typography>
);
