import { useRecoilValue, useSetRecoilState } from 'recoil';

import { timelineAtoms } from 'pages/tactical-analysis/atoms';

export const useSetTimelineHeadersWidth = () => {
  return useSetRecoilState(timelineAtoms.headersWidth);
};

export const useTimelineHeadersWidth = () => {
  return useRecoilValue(timelineAtoms.headersWidth);
};
