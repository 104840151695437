import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { TaggingEvent } from 'api/tagging-tool/types';
import { routes } from 'kognia/router/routes';
import {
  AlignRecordingActionsContainer,
  AlignRecordingBottomActions,
  AlignRecordingClipContainer,
  AlignRecordingClipListContainer,
  AlignRecordingContainer,
  AlignRecordingContent,
  AlignRecordingName,
  AlignRecordingVideoPlayerContainer,
} from 'pages/tagging-tool-align-recording/components/align-recording/styled';
import { RecordingAlignmentClip } from 'pages/tagging-tool-align-recording/components/align-recording-clip';
import { Meta } from 'pages/tagging-tool-align-recording/types';
import { Message } from 'shared/components/message';
import Spinner from 'shared/components/spinner';
import { useCurrentTime, useDuration, useVideoPlayerActions } from 'shared/components/video-player';
import VideoPlayerComponent from 'shared/components/video-player/video-player-component';
import {
  fetchUpdateTaggingRecordingAlignment,
  fetchUpdateTaggingRecordingAlignmentWithMerge,
} from 'tagging-tool/service/taggingRecording.service';
import { dateToPrintableDateString } from 'tagging-tool/utility/date';
import { makeRecordingDetailRoute } from 'tagging-tool/utility/navigation';

interface Props {
  aligning: boolean;
  alignmentDiff: number;
  canAlign: boolean;
  canFinish: boolean;
  meta: Meta | undefined;
  recordingId: string;
  events: TaggingEvent[] | undefined;
  selectedAlignmentEvent: undefined | TaggingEvent;
  setAligning: (value: boolean) => void;
  setAlignmentDiff: (value: number) => void;
  setCanAlign: (value: boolean) => void;
  setCanFinish: (value: boolean) => void;
  setEvents: (events: TaggingEvent[] | undefined) => void;
  setMeta: (meta: Meta | undefined) => void;
  setSelectedAlignmentEvent: (event: undefined | TaggingEvent) => void;
  sourceRecordingId: undefined | string;
}

export const AlignRecording = ({
  meta,
  aligning,
  events,
  recordingId,
  setAligning,
  canAlign,
  setMeta,
  setEvents,
  sourceRecordingId,
  setCanAlign,
  canFinish,
  setCanFinish,
  alignmentDiff,
  setAlignmentDiff,
  selectedAlignmentEvent,
  setSelectedAlignmentEvent,
}: Props) => {
  const actions = useVideoPlayerActions();
  const currentTime = useCurrentTime();
  const duration = useDuration();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [fetching, setFetching] = useState<boolean>(false);

  const handleAlign = useCallback(() => {
    if (!selectedAlignmentEvent) return;

    const startTime =
      selectedAlignmentEvent.time - selectedAlignmentEvent.timeBefore > 0
        ? selectedAlignmentEvent.time - selectedAlignmentEvent.timeBefore
        : 0;

    setAlignmentDiff(Math.round(Math.floor(currentTime) - startTime));

    setAligning(false);
    setCanFinish(true);
  }, [selectedAlignmentEvent, setAlignmentDiff, currentTime, setAligning, setCanFinish]);

  const handleEditAlignment = useCallback(() => {
    setAlignmentDiff(0);
    setSelectedAlignmentEvent(undefined);
    setAligning(true);
    setCanAlign(false);
    setCanFinish(false);
  }, [setAligning, setAlignmentDiff, setCanAlign, setCanFinish, setSelectedAlignmentEvent]);

  const handleFinish = useCallback(() => {
    if (!meta) return;

    setFetching(true);

    const finalize = () => {
      setSelectedAlignmentEvent(undefined);
      setFetching(false);
      setCanAlign(false);
      setCanFinish(false);
    };

    if (sourceRecordingId !== undefined) {
      // merging
      fetchUpdateTaggingRecordingAlignmentWithMerge({
        recordingId: meta.recordingId,
        liveTaggingSessionId: recordingId,
        diff: alignmentDiff,
      }).then((res) => {
        finalize();

        if (res.error) {
          return;
        }

        setMeta(undefined);
        setEvents(undefined);

        navigate(makeRecordingDetailRoute({ recordingId: meta.recordingId }), { replace: true });
      });
    } else {
      fetchUpdateTaggingRecordingAlignment({
        recordingId: meta.recordingId,
        diff: alignmentDiff,
      }).then(() => {
        finalize();
      });
    }
  }, [
    meta,
    sourceRecordingId,
    setSelectedAlignmentEvent,
    setCanAlign,
    setCanFinish,
    recordingId,
    alignmentDiff,
    setMeta,
    setEvents,
    navigate,
  ]);

  const handleCancel = useCallback(() => {
    if (selectedAlignmentEvent) {
      setAlignmentDiff(0);
      setAligning(true);
      setSelectedAlignmentEvent(undefined);
      setCanAlign(true);
      setCanFinish(false);
    } else {
      navigate(-1);
    }
  }, [
    navigate,
    selectedAlignmentEvent,
    setAligning,
    setAlignmentDiff,
    setCanAlign,
    setCanFinish,
    setSelectedAlignmentEvent,
  ]);

  const handleBack = useCallback(() => {
    navigate(generatePath(routes.TAGGING_TOOL_TAG, { recordingId }));
  }, [navigate, recordingId]);

  const showFinish = canFinish || (events !== undefined && events.length === 0);
  return (
    <>
      <div>
        {(meta === undefined || events === undefined) && <Spinner isFullPage />}
        {meta !== undefined && events !== undefined && (
          <AlignRecordingContainer>
            <AlignRecordingContent>
              <AlignRecordingVideoPlayerContainer>
                <VideoPlayerComponent />
                <AlignRecordingName color={Colors.white} fontWeight={fontWeight['500']} fontSize={fontSizes.default}>
                  {`${meta.name} / ${dateToPrintableDateString(new Date(meta.date))}`}
                </AlignRecordingName>
              </AlignRecordingVideoPlayerContainer>
              <AlignRecordingActionsContainer>
                <Grid container spacing={1}>
                  <Grid item xs={aligning ? 12 : 8}>
                    <Message>
                      {aligning
                        ? t('tagging-tool:tagging-recording.seek-to-align')
                        : t('tagging-tool:tagging-recording.review-alignment')}
                    </Message>
                  </Grid>
                  {!aligning && (
                    <Grid item xs={4}>
                      <Button fullWidth variant='contained' color='secondary' onClick={handleEditAlignment}>
                        {t('tagging-tool:tagging-recording.reset-alignment')}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </AlignRecordingActionsContainer>
              <AlignRecordingClipListContainer>
                <AlignRecordingClipContainer>
                  {events.map((event, idx) => {
                    return (
                      <RecordingAlignmentClip
                        key={event.id}
                        event={event}
                        aligning={aligning}
                        alignmentDiff={alignmentDiff}
                        videoDuration={duration}
                        idx={idx}
                        checked={selectedAlignmentEvent?.id === event.id}
                        onCheckedChange={(value) => {
                          if (value) {
                            setSelectedAlignmentEvent(event);
                            setCanAlign(true);
                          }
                        }}
                        onPlay={(diffTime) => {
                          actions.jumpToTimePercent((diffTime * 100) / duration);
                          actions.play();
                        }}
                      />
                    );
                  })}
                </AlignRecordingClipContainer>
              </AlignRecordingClipListContainer>
            </AlignRecordingContent>
          </AlignRecordingContainer>
        )}
      </div>
      <AlignRecordingBottomActions>
        {(aligning || showFinish) && (
          <>
            <Button size='large' variant='contained' color='secondary' onClick={handleCancel}>
              {t('common:actions.cancel')}
            </Button>
            <LoadingButton
              size='large'
              onClick={showFinish ? handleFinish : handleAlign}
              disabled={(!canAlign && !canFinish) || fetching}
              loading={fetching}
              variant='contained'
            >
              {showFinish ? t('common:finish') : t('tagging-tool:tagging-recording.align-button')}
            </LoadingButton>
          </>
        )}
        {!aligning && !showFinish && (
          <Button size='large' variant='contained' color='secondary' onClick={handleBack}>
            {t('common:back')}
          </Button>
        )}
      </AlignRecordingBottomActions>
    </>
  );
};
