import { MenuItem } from '@mui/material';
import { forwardRef, MouseEvent, Ref, useCallback } from 'react';

import { useSelectResultHandler } from 'entities/dashboard/hooks/useSelectResultHandler';
import { SearchResult } from 'entities/dashboard/types/dashboardSearch';
import { DashboardSearchOptionType } from 'features/dashboard/dashboard-search-results-tabs/ui/DashboardSearchOptionType';

interface Props {
  option: SearchResult;
  selected?: boolean;
  onSelect?: (data: SearchResult) => void;
}

export const DashboardSearchOption = forwardRef(({ option, selected, onSelect }: Props, ref?: Ref<HTMLLIElement>) => {
  const selectResultHandler = useSelectResultHandler();
  const handleClick = useCallback(
    (event: MouseEvent) => {
      event.stopPropagation();
      selectResultHandler(option);
      onSelect?.(option);
    },
    [onSelect, selectResultHandler, option],
  );

  return (
    <MenuItem ref={ref} disableRipple onClick={handleClick} selected={selected} sx={{ display: 'block' }}>
      <DashboardSearchOptionType searchResult={option} />
    </MenuItem>
  );
});

DashboardSearchOption.displayName = 'SearchOption';
