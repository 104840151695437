import { Paper, PaperProps } from '@mui/material';
import { boxShadows, Colors } from 'kognia-ui';

interface Props extends PaperProps {
  width: number;
}

export const RESULTS_HEIGHT = 304;

export const SearchResultPaper = ({ children, width, ...props }: Props) => {
  return (
    <Paper
      sx={{
        border: `1px solid ${Colors.ghost}`,
        borderRadius: 1,
        boxShadow: boxShadows[3],
        display: 'flex',
        flexDirection: 'column',
        maxHeight: RESULTS_HEIGHT,
        mt: 0.5,
        padding: (theme) => theme.spacing(1, 2),
        width,
      }}
      {...props}
    >
      {children}
    </Paper>
  );
};
