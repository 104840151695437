import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

export const CustomButtonsBoxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: '1',
  flexDirection: 'column',
  background: Colors.white,
  padding: theme.spacing(1),
  minHeight: '100%',
}));

export const CustomButtonsBoxHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

export const CustomButtonsBoxList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));
