import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';
import React from 'react';

const RulesWrapper = styled(Box)(() => ({
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  display: 'flex',
}));

const Line = styled(Box)(() => ({
  background: Colors.aqua,
  zIndex: 1,
  opacity: 0.2,
}));

const LineVertical = styled(Line)(() => ({
  position: 'relative',
  left: '50%',
  justifyContent: 'center',
  height: '100%',
  width: ' 1px',
}));

const LineHorizontal = styled(Line)(() => ({
  height: '1px',
  width: '100%',
  alignSelf: 'center',
}));

export const Rules = () => {
  return (
    <RulesWrapper>
      <LineVertical />
      <LineHorizontal />
    </RulesWrapper>
  );
};
