import { Client } from 'shared/types/user/types';

export const findClientOrThrow = (clients: Client[], clientId: string) => {
  const client = clients.find((client) => client.id === clientId);

  if (!client) {
    throw new Error(`Client with id ${clientId} not found`);
  }

  return client;
};
