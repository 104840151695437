export type BackendApiError = {
  detail: string;
  instance: string;
  status: number;
  title: string;
  type: string;
};

export const backendApiErrorParser = (data: BackendApiError | undefined, fallbackErrorMessage: string) =>
  data && data.detail ? data.detail : fallbackErrorMessage;
