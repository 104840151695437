type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export const entriesFromObject = <T extends Record<string, any>>(object: T): Entries<T> => {
  return Object.entries(object) as Entries<T>;
};

export const keysFromObject = <T extends Record<string, any>>(object: T): Array<keyof T> => {
  return Object.keys(object);
};
