import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

export const useRedirectTo = () => {
  const navigate = useNavigate();

  return useCallback(
    (link: string) => {
      if (link.startsWith('http')) {
        return window.open(link);
      }

      return navigate(link);
    },
    [navigate],
  );
};
