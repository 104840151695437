import { useAtomValue } from 'jotai';

import { userAtom } from 'shared/contexts/app-state/atoms';
import { User } from 'shared/types/user/types';

export function useUser(): User {
  const user = useAtomValue(userAtom);

  if (!user) {
    throw new Error('User is not authenticated');
  }

  return user;
}
