import { PlayingMode } from 'shared/components/video-player/types';
import { playlistMultimatchAppliedFilters } from 'shared/constants/user-presets/userPresetsPlayList';
import {
  timelineAppliedFiltersPreset,
  timelineHeadersWidthPreset,
  timelineHeightPreset,
  timelinePinScenariosPreset,
  timelinePlayingModePreset,
  timelineSelectedTacticsPreset,
  timelineShowBallPossession,
  timelineShowNoBallPossession,
  timelineTeamIdFocus,
  timelineTimePreset,
  timelineZoomPreset,
} from 'shared/constants/user-presets/userPresetsTimeline';
import { videoPlayerSpeedPreset } from 'shared/constants/user-presets/userPresetsVideoPlayer';
import { TacticIdOrAll } from 'shared/types';
import { PlaybackRate } from 'shared/types/payback-rates/payback-rates';
import { RecordingsFilters } from 'shared/types/recording/types';
import { UserPreset, UserPresetScope, UserPresetKeysUnion } from 'shared/types/user-preset/types';
import { ZoomLevelsType } from 'shared/types/zoom-range/zoomLevels';

class PlaylistCommonUserPreset<T> implements UserPreset<T> {
  scope: UserPresetScope = UserPresetScope.playlist;
  key = '' as UserPresetKeysUnion;
  value = {} as T;
  ref?: string;
}

export class PlaylistMultimatchAppliedFiltersPreset extends PlaylistCommonUserPreset<RecordingsFilters> {
  key = playlistMultimatchAppliedFilters.key;
  scope = playlistMultimatchAppliedFilters.scope;
  value: RecordingsFilters;
  ref: string;

  constructor(value: RecordingsFilters, ref: string) {
    super();
    this.value = value;
    this.ref = ref;
  }
}

class CommonUserPreset<T> implements UserPreset<T> {
  scope: UserPresetScope = UserPresetScope.timeline;
  key = '' as UserPresetKeysUnion;
  value = {} as T;
  ref?: string;
}

export class TimelinePlayingModePreset extends CommonUserPreset<PlayingMode> {
  key = timelinePlayingModePreset.key;
  scope = timelinePlayingModePreset.scope;
  value: PlayingMode;
  ref: string;

  constructor(value: PlayingMode, ref: string) {
    super();
    this.value = value;
    this.ref = ref;
  }
}

export class TimelineZoomPreset extends CommonUserPreset<ZoomLevelsType> {
  key = timelineZoomPreset.key;
  scope = timelineZoomPreset.scope;
  value: ZoomLevelsType;

  constructor(value: ZoomLevelsType) {
    super();
    this.value = value;
  }
}

export class TimelineSelectedTacticsPreset extends CommonUserPreset<TacticIdOrAll[]> {
  key = timelineSelectedTacticsPreset.key;
  scope = timelineSelectedTacticsPreset.scope;
  value: TacticIdOrAll[];
  ref: string;

  constructor(value: TacticIdOrAll[], ref: string) {
    super();
    this.value = value;
    this.ref = ref;
  }
}

export class TimelineHeightPreset extends CommonUserPreset<number> {
  key = timelineHeightPreset.key;
  scope = timelineHeightPreset.scope;
  value: number;

  constructor(value: number) {
    super();
    this.value = value;
  }
}

export class TimelinePinScenariosPreset extends CommonUserPreset<boolean> {
  key = timelinePinScenariosPreset.key;
  scope = timelinePinScenariosPreset.scope;
  value: boolean;

  constructor(value: boolean) {
    super();
    this.value = value;
  }
}

export class TimelineAppliedFiltersPreset extends CommonUserPreset<RecordingsFilters> {
  key = timelineAppliedFiltersPreset.key;
  scope = timelineAppliedFiltersPreset.scope;
  value: RecordingsFilters;
  ref: string;

  constructor(value: RecordingsFilters, ref: string) {
    super();
    this.value = value;
    this.ref = ref;
  }
}

export class TimelineHeadersWidthPreset extends CommonUserPreset<number> {
  key = timelineHeadersWidthPreset.key;
  scope = timelineHeadersWidthPreset.scope;
  value: number;

  constructor(value: number) {
    super();
    this.value = value;
  }
}

export class TimelineTimePreset extends CommonUserPreset<number> {
  key = timelineTimePreset.key;
  scope = timelineTimePreset.scope;
  value: number;
  ref: string;

  constructor(value: number, ref: string) {
    super();
    this.value = value;
    this.ref = ref;
  }
}

export class TimelineTeamIdFocusPreset extends CommonUserPreset<string> {
  key = timelineTeamIdFocus.key;
  scope = timelineTeamIdFocus.scope;
  value: string;

  constructor(value: string, ref: string) {
    super();
    this.value = value;
    this.ref = ref;
  }
}

export class TimelineShowBallPossession extends CommonUserPreset<boolean> {
  key = timelineShowBallPossession.key;
  scope = timelineShowBallPossession.scope;
  value: boolean;

  constructor(value: boolean, ref: string) {
    super();
    this.value = value;
    this.ref = ref;
  }
}

export class TimelineShowNoBallPossession extends CommonUserPreset<boolean> {
  key = timelineShowNoBallPossession.key;
  scope = timelineShowNoBallPossession.scope;
  value: boolean;

  constructor(value: boolean, ref: string) {
    super();
    this.value = value;
    this.ref = ref;
  }
}

export class VideoPlayerSpeedPreset extends CommonUserPreset<number> {
  key = videoPlayerSpeedPreset.key;
  scope = videoPlayerSpeedPreset.scope;
  value: PlaybackRate;

  constructor(value: PlaybackRate) {
    super();
    this.value = value;
  }
}
