import { IconKeypad } from 'kognia-ui/icons/IconKeypad';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { KeypadTag } from 'api/tagging-tool/types';
import { DialogNew } from 'shared/components/dialog-new';
import { DialogContentText, DialogTextVariants } from 'shared/components/dialog-new/dialog-content-text';

export type RemoveConfirmModalProps = {
  open: boolean;
  data?: Partial<KeypadTag>;
  onDelete: (tag: KeypadTag) => void;
  onCancel: () => void;
  onClosed: () => void;
};

export const RemoveButtonModal = ({ open, data, onDelete, onCancel }: RemoveConfirmModalProps) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(() => {
    if (!data) {
      return;
    }
    onDelete(data as KeypadTag);
  }, [onDelete, data]);

  return (
    <DialogNew
      title={t('tagging-tool:keypad-tag.delete-modal-title')}
      icon={<IconKeypad size='small' color='error' />}
      onCancel={onCancel}
      open={open}
      fullWidth
      maxWidth='sm'
      onSubmit={handleSubmit}
      onClose={onCancel}
      buttonCancelText={t('common:actions.cancel')}
      buttonSubmitText={t('common:actions.remove')}
      error
    >
      <DialogContentText textVariant={DialogTextVariants.Primary}>
        {t('tagging-tool:keypad-tag.delete-modal-main-text', { name: data?.name ?? '' })}
      </DialogContentText>
      <DialogContentText>{t('tagging-tool:keypad-tag.delete-modal-text1')}</DialogContentText>
      <DialogContentText>{t('tagging-tool:keypad-tag.delete-modal-text2')}</DialogContentText>
    </DialogNew>
  );
};
