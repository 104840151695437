import { PlaylistsWithFiltersPage } from 'api/playlist/useFetchPlaylists/types';
import { Playlist } from 'shared/types';
import { FiltersList } from 'shared/types/filters/types';

export const getTotalElementsFromPage = (pages: PlaylistsWithFiltersPage[] | undefined): number => {
  if (!pages || pages.length === 0) return 0;

  const lastPage = pages[pages.length - 1];

  return lastPage.data.page.totalElements;
};

export const getFilters = (pages: PlaylistsWithFiltersPage[] | undefined): FiltersList => {
  if (!pages || pages.length === 0) return {};

  const lastPage = pages[pages.length - 1];

  return lastPage.data.filters;
};

export const getPlaylistItems = (pages: PlaylistsWithFiltersPage[] | undefined): Playlist[] => {
  return pages
    ? pages.reduce((acc: Playlist[], page: any) => {
        return acc.concat(page.data.playlists);
      }, [])
    : [];
};

const AUTOCOMPLETE_PAGE_SIZE_INITIAL = 8;
const AUTOCOMPLETE_PAGE_SIZE_SEARCH = 10;

export const getTimelinePlaylistPageSize = (nameParam: string) => {
  if (nameParam) {
    return AUTOCOMPLETE_PAGE_SIZE_SEARCH;
  }

  return AUTOCOMPLETE_PAGE_SIZE_INITIAL;
};
