import { offensiveTactics } from '@kognia/tactical-analysis-data';

import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { generateClipsWithOverlappingTime } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/generateClips';
import { TacticActionType } from 'pages/tactical-analysis/types';
import { TacticalFundamental } from 'shared/types/episodes/types';

interface Params {
  clips: TacticalFundamental[];
  recordingId: string;
}

const isOffensiveTactic = (tacticId: string) => offensiveTactics.includes(tacticId);

export const getClipsFromTacticalFundamental = ({ clips, recordingId }: Params): Clip[] => {
  const mappedClipsByTeamIdAndType = clips.reduce<Record<string, Record<string, Clip[]>>>((acc, clip) => {
    if (!acc[clip.teamId]) {
      acc[clip.teamId] = {};
    }

    if (!acc[clip.teamId][clip.tacticalFundamentalType]) {
      acc[clip.teamId][clip.tacticalFundamentalType] = [];
    }

    const rowId = `${recordingId}-${clip.tacticalFundamentalType}-${clip.teamId}`;

    acc[clip.teamId][clip.tacticalFundamentalType].push(<Clip>{
      id: `${rowId}-${clip.startTime}-${clip.endTime}`,
      startTime: clip.startTime,
      endTime: clip.endTime,
      type: 'tactic',
      rowId: rowId,
      teamId: clip.teamId,
      elementId: clip.tacticalFundamentalType,
      action: isOffensiveTactic(clip.tacticalFundamentalType) ? TacticActionType.OFFENSIVE : TacticActionType.DEFENSIVE,
      title: clip.name,
      titleForPlaylist: clip.name,
    });

    return acc;
  }, {});

  return Object.values(mappedClipsByTeamIdAndType).reduce<Clip[]>((acc, clipsByType) => {
    const clips = Object.values(clipsByType).reduce(
      (acc, clips) => [...acc, generateClipsWithOverlappingTime({ clips })].flat(),
      [],
    );
    return [...acc, ...clips];
  }, []);
};
