import { RowGroup } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/row';
import { RowsContentGroup } from 'pages/tactical-analysis/components/timeline/timeline-table/components/row-content-group';

type Props = {
  rowGroup?: RowGroup;
  recordingId: string;
};
export const ScenariosRowGroupContent = ({ rowGroup, recordingId }: Props) => {
  if (!rowGroup) return null;

  return <RowsContentGroup key={rowGroup.type} level={0} rowGroup={rowGroup} recordingId={recordingId} />;
};
