import { FixturesPlayer } from 'pages/backoffice/types/fixture';

export const getJerseyNumber = (player: FixturesPlayer): number => {
  if (player.jerseyNumber) return player.jerseyNumber;

  return player.fixtures && player.fixtures.length > 0 ? player.fixtures[0].jerseyNumber : 0;
};

export const getTeamName = (player: FixturesPlayer): string => {
  return player.fixtures && player.fixtures.length > 0 ? player.fixtures[0].team.name : '';
};

export const getTeamLastFixtureDate = (player: FixturesPlayer): string | undefined => {
  return player.fixtures && player.fixtures.length > 0 ? player.fixtures[0].date ?? undefined : undefined;
};
