import { Typography } from '@mui/material';
import { fontSizes, fontWeight } from 'kognia-ui';
import React, { ReactNode } from 'react';

import { KeypadTag } from 'api/tagging-tool/types';
import {
  CustomButtonsBoxContainer,
  CustomButtonsBoxHeader,
  CustomButtonsBoxList,
} from 'pages/tagging-tool-keypad-edit/components/custom-buttons/components/custom-buttons-box/styled';
import { CustomButtonsSkeleton } from 'pages/tagging-tool-keypad-edit/components/custom-buttons-skeleton';
import { EventButton } from 'pages/tagging-tool-keypad-edit/components/EventButton/EventButton';

interface Props {
  data: any;
  icon: ReactNode;
  onEdit: (payload: KeypadTag) => void;
  onRemove: (payload: KeypadTag) => void;
  onEnabledChange: (tag: KeypadTag, is_enabled: boolean) => void;
  title: string;
}

export const CustomButtonsBox = ({ data, icon, onEdit, onEnabledChange, onRemove, title }: Props) => {
  return (
    <CustomButtonsBoxContainer>
      <CustomButtonsBoxHeader>
        {icon}
        <Typography fontSize={fontSizes.small} fontWeight={fontWeight['500']} textTransform='uppercase'>
          {title}
        </Typography>
      </CustomButtonsBoxHeader>
      {(data === undefined || data.length === 0) && <CustomButtonsSkeleton />}
      {data !== undefined && data.length > 0 && (
        <CustomButtonsBoxList>
          {data.map((custom: any) => {
            return (
              <EventButton
                key={custom.keypadTagId}
                data={custom}
                onEdit={onEdit}
                onEnabledChange={onEnabledChange}
                onRemove={onRemove}
              />
            );
          })}
        </CustomButtonsBoxList>
      )}
    </CustomButtonsBoxContainer>
  );
};
