import { Card, CardContent, Container, Stack, Typography } from '@mui/material';
import { fontWeight } from 'kognia-ui';
import { IconChevronRight } from 'kognia-ui/icons/IconChevronRight';
import React, { useCallback } from 'react';
import { generatePath, useParams } from 'react-router-dom';

import { useClients } from 'api/backoffice/client/hooks/use-clients/useClients';
import { useEditClient } from 'api/backoffice/client/hooks/use-edit-client/useEditClient';
import { routes } from 'kognia/router/routes';
import { BackofficeContentContainer } from 'pages/backoffice/components/backoffice-content-container';
import { EnvironmentForm } from 'pages/backoffice/features/environment-form/EnvironmentForm.feature';
import { EnvironmentFormDefaultValues } from 'pages/backoffice/features/environment-form/types/environmentFormDefaultValues';
import { EnvironmentOnSubmit } from 'pages/backoffice/features/environment-form/types/environmentOnSubmit';
import { useRedirectTo } from 'shared/components/sidebar-layout/sidebar-menu/hooks/use-redirect-to';
import Spinner from 'shared/components/spinner';
import { invariant } from 'shared/utils/invariant';

export const EditEnvironment = () => {
  const { id } = useParams();
  invariant(id);
  const { editClient, isPending } = useEditClient();
  const redirectTo = useRedirectTo();
  const { items, isLoading: isLoadingClient } = useClients({ initialFilters: { clientIds: [id] } });

  const defaultValues: EnvironmentFormDefaultValues | undefined = items?.[0]
    ? {
        id: items[0].id,
        name: items[0].name,
        city: items[0].city,
        country: items[0].country,
      }
    : undefined;

  const handleEditClient = useCallback(
    ({ data }: EnvironmentOnSubmit) => {
      editClient({
        data: {
          name: data.name,
          city: data.city,
          country: data.country,
        },
        id,
        onSuccess: () => redirectTo(generatePath(routes.BACKOFFICE_ENVIRONMENT_DETAIL, { id })),
      });
    },
    [editClient, id, redirectTo],
  );

  return (
    <BackofficeContentContainer>
      <Container fixed maxWidth='sm'>
        <Stack direction={'column'} spacing={2}>
          <Typography
            variant={'h6'}
            sx={{ fontWeight: fontWeight['400'] }}
            display='flex'
            alignItems='center'
            gap={0.5}
          >
            <span>{items?.[0]?.name}</span>
            <IconChevronRight size='small' color='secondary' />
            <span>Edit environment</span>
          </Typography>
          <Card>
            <CardContent>
              {isLoadingClient ? (
                <Spinner isFullPage />
              ) : (
                <EnvironmentForm
                  isLoading={isPending}
                  onSubmit={handleEditClient}
                  defaultValues={defaultValues}
                  isEdit={true}
                />
              )}
            </CardContent>
          </Card>
        </Stack>
      </Container>
    </BackofficeContentContainer>
  );
};
