import { styled, Switch as MuiSwitch, SwitchProps } from '@mui/material';
import { forwardRef } from 'react';

interface Props extends SwitchProps {
  onColor?: string;
  offColor?: string;
}

const Switch = styled(MuiSwitch, {
  shouldForwardProp: (prop) => prop !== 'onColor' && prop !== 'offColor',
})<Props>(({ theme, offColor, onColor }) => ({
  minWidth: 40,
  width: 40,
  height: 24,
  padding: 0,
  lineHeight: 1,
  '& .MuiSwitch-switchBase': {
    padding: theme.spacing(0.5),
    transitionDuration: theme.transitions.duration.shorter,
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + .MuiSwitch-track': {
        backgroundColor: onColor ?? theme.palette.primary.main,
        opacity: 1,
      },
    },
    '&.Mui-disabled': {
      filter: 'grayscale(100%)',
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      border: `6px solid ${theme.palette.common.white}`,
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    background: theme.palette.common.white,
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'none',
  },
  '& .MuiSwitch-track': {
    borderRadius: 48,
    backgroundColor: offColor ?? theme.palette.secondary.main,
    opacity: 1,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

const Toggle = forwardRef(
  (
    props: Props,
    ref?: ((instance: HTMLButtonElement | null) => void) | React.RefObject<HTMLButtonElement> | null | undefined,
  ) => {
    return <Switch ref={ref} {...props} />;
  },
);

Toggle.displayName = 'Toggle';

export default Toggle;
