import { AxiosResponse } from 'axios';

export const normalizeAxiosResponseHeaders = (headers: AxiosResponse['headers']) => {
  return Object.entries(headers).reduce<Record<string, string>>((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = String(value);
    }
    return acc;
  }, {});
};
