import { styled, Tabs as MUITabs } from '@mui/material';
import { fontSizes } from 'kognia-ui';

export const Tabs = styled(MUITabs)(({ theme }) => ({
  '& .MuiTab-root': {
    fontWeight: 'normal',
    fontSize: fontSizes.default,
    textTransform: 'none',
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    color: theme.palette.secondary.main,
  },
  '& .Mui-selected': {
    '&.MuiTab-root': {
      color: theme.palette.text.primary,
    },
  },
  '& .MuiTabs-indicator': {
    height: theme.spacing(0.5),
    backgroundColor: theme.palette.text.primary,
  },
}));
