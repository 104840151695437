import { Box, CSSObject, styled, Theme } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { Colors } from 'kognia-ui';

export const openSidebarWidth = 256;
export const collapsedSidebarWidth = 73;
export const shiftSidebarWidth = (openSidebarWidth - collapsedSidebarWidth) / 2;

const openedMixin = (theme: Theme): CSSObject => ({
  width: openSidebarWidth,
  padding: `${theme.spacing(5)} ${theme.spacing(2)}`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  width: collapsedSidebarWidth,
  padding: `${theme.spacing(5)} ${theme.spacing(2)}`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
});

export const SidebarLayoutSidebar = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: openSidebarWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export const SidebarLayoutWrapper = styled(Box)({
  display: 'flex',
  width: '100%',
  minHeight: '100%',
});

export const SidebarLayoutContent = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })<{ open: boolean }>(
  ({ open }) => ({
    backgroundColor: Colors.background,
    display: 'flex',
    justifyContent: 'center',
    flexGrow: 1,
    minHeight: '100%',
    maxWidth: open ? `calc(100% - ${openSidebarWidth}px)` : `calc(100% - ${collapsedSidebarWidth}px)`,
  }),
);

export const UserAvatar = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '24px',
  minWidth: '24px',
  height: '24px',
  marginRight: theme.spacing(0.25),
  borderRadius: '50%',
  overflow: 'hidden',
  '& img': {
    maxWidth: '100%',
  },
}));

export const SidebarLogoWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'spacingBottom' })<{
  spacingBottom: boolean;
}>(({ theme, spacingBottom }) => ({
  width: '100%',

  ...(spacingBottom && {
    marginBottom: theme.spacing(2),
  }),
}));

export const SidebarHeader = styled(Box, { shouldForwardProp: (prop) => prop !== 'open' })<{
  open: boolean;
}>(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'column',
  marginBottom: theme.spacing(1),
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),

  ...(open && {
    flexDirection: 'row',
    marginBottom: theme.spacing(6),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }),
}));
