import z, { ZodIssue } from 'zod';

export const brandingFormRequiredSchema = z
  .object({
    displayName: z.string(),
    favicon: z.instanceof(FileList),
    emailLogo: z.instanceof(FileList),
    navbarLogo: z.instanceof(FileList),
    navbarCollapsedLogo: z.instanceof(FileList),
    homePageLogo: z.instanceof(FileList),
  })
  .refine((data) => {
    const fields = Object.entries(data);
    const anyFieldFilled = fields.some(([, value]) => value !== undefined && value !== null && value.length > 0);

    if (!anyFieldFilled) return true;

    const errors = fields.reduce<ZodIssue[]>((acc, [key, value]) => {
      if (value === undefined || value === null || value.length === 0) {
        acc.push({
          code: 'custom',
          path: [key],
          message: `${key} is required.`,
        });
      }
      return acc;
    }, []);

    if (errors.length > 0) {
      throw new z.ZodError(errors);
    }

    return true;
  });

export const brandingFormOptionalSchema = z.object({
  favicon: z.instanceof(FileList).optional(),
  emailLogo: z.instanceof(FileList).optional(),
  navbarLogo: z.instanceof(FileList).optional(),
  navbarCollapsedLogo: z.instanceof(FileList).optional(),
  homePageLogo: z.instanceof(FileList).optional(),
  displayName: z.string().optional(),
});
