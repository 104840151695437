import { IconButton, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

export const ClipPlayerDialogIconButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  color: Colors.white,
  zIndex: 100,
}));
