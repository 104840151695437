import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePlayerInfinityQuery } from 'entities/dashboard/api/use-player-infinity-query/usePlayerInfinityQuery';
import { useTeamsInfinityQuery } from 'entities/dashboard/api/use-teams-infinity-query/useTeamsInfinityQuery';
import { SearchResult, SearchType } from 'entities/dashboard/types/dashboardSearch';
import { PlayerApiFilters } from 'shared/types/player/player';

type Response = {
  fetchNextPage: () => void;
  isLoading: boolean;
  isPending: boolean;
  options: SearchResult[];
  setEnableSearch: (value: boolean) => void;
  setFilters: (value: { name: string }) => void;
  totals: {
    [SearchType.ALL]: number;
    [SearchType.PLAYER]: number;
    [SearchType.TEAM]: number;
  };
};

const SEARCH_PAGE_SIZE = 10;

export const useDashboardSearchResults = (): Response => {
  const { t } = useTranslation('countries');
  const playersResponse = usePlayerInfinityQuery({
    enabled: false,
    pageSize: SEARCH_PAGE_SIZE,
  });

  const teamsResponse = useTeamsInfinityQuery({
    enabled: false,
    pageSize: SEARCH_PAGE_SIZE,
  });

  const handleEnable = useCallback(
    (value: boolean) => {
      playersResponse.setEnabled(value);
      teamsResponse.setEnabled(value);
    },
    [playersResponse, teamsResponse],
  );

  const handleSetFilters = useCallback(
    (filters: PlayerApiFilters) => {
      playersResponse.setFilters(filters);
      teamsResponse.setFilters(filters);
    },
    [playersResponse, teamsResponse],
  );

  const handleFetchNextPage = useCallback(() => {
    playersResponse.fetchNextPage();
    teamsResponse.fetchNextPage();
  }, [playersResponse, teamsResponse]);

  const results = useMemo(() => {
    const paginate = (items: SearchResult[]) => {
      const pages = [];
      for (let i = 0; i < items.length; i += SEARCH_PAGE_SIZE) {
        pages.push(items.slice(i, i + SEARCH_PAGE_SIZE));
      }

      return pages;
    };

    const transformedPlayers = playersResponse.items.map((player) => ({
      value: player.id,
      label: player.name,
      photoUrl: player.photoUrl,
      type: SearchType.PLAYER,
      ...(player.fixtures.length > 0
        ? {
            extraInfo: {
              value: player.fixtures[0].team.id,
              label: player.fixtures[0].team.name,
              photoUrl: player.fixtures[0].team.logoUrl,
            },
          }
        : {}),
    }));

    const transformedTeams = teamsResponse.items.map((team) => ({
      value: team.id,
      label: team.name,
      photoUrl: team.logoUrl,
      type: SearchType.TEAM,
      ...(team.countryCode
        ? {
            extraInfo: {
              value: team.countryCode,
              label: t(team.countryCode.toUpperCase()),
            },
          }
        : {}),
    }));

    const playerPages = paginate(transformedPlayers);
    const teamPages = paginate(transformedTeams);

    const maxPages = Math.max(playerPages.length, teamPages.length);

    const combinedPages = Array.from({ length: maxPages }).map((_, index) => {
      const teamPage = teamPages[index] || [];
      const playerPage = playerPages[index] || [];
      return [...playerPage, ...teamPage].sort((a, b) => a.label.localeCompare(b.label));
    });

    return combinedPages.flat();
  }, [t, playersResponse.items, teamsResponse.items]);

  const totalPlayers = playersResponse.page?.totalElements ?? 0;
  const totalTeams = teamsResponse.page?.totalElements ?? 0;
  const totalResults = totalPlayers + totalTeams;

  return useMemo(
    () => ({
      setEnableSearch: handleEnable,
      options: results,
      totals: {
        [SearchType.ALL]: totalResults,
        [SearchType.PLAYER]: totalPlayers,
        [SearchType.TEAM]: totalTeams,
      },
      setFilters: handleSetFilters,
      fetchNextPage: handleFetchNextPage,
      isPending: playersResponse.isPending || teamsResponse.isPending,
      isLoading: playersResponse.isFetchingNextPage || teamsResponse.isFetchingNextPage,
    }),
    [
      totalResults,
      totalPlayers,
      totalTeams,
      handleEnable,
      handleFetchNextPage,
      handleSetFilters,
      results,
      playersResponse.isPending,
      teamsResponse.isPending,
      playersResponse.isFetchingNextPage,
      teamsResponse.isFetchingNextPage,
    ],
  );
};
