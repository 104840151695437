import { z } from 'zod';

import { VenueFormFieldsNames } from 'pages/backoffice/pages/venues/components/venue-form/types';

export const venueFormSchema = z.object({
  [VenueFormFieldsNames.id]: z.string().optional(),
  [VenueFormFieldsNames.name]: z.string().trim().min(2),
  [VenueFormFieldsNames.city]: z.string().trim().min(2),
  [VenueFormFieldsNames.countryCode]: z.string().min(2),
});
