import isEmpty from 'lodash/isEmpty';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RecordingsFiltersList, useRecordings } from 'api/recording/useFetchRecordings';
import { RecordingListPageSkeleton } from 'pages/recordings-list/components/recording-list-page-skeleton';
import { RecordingsListPage } from 'pages/recordings-list/components/recordings-list-page';
import { POSSIBLE_FILTERS } from 'pages/recordings-list/constants/possibleFilters';
import { useRecordingsListFilters } from 'pages/recordings-list/hooks/useRecordingsListFilters';
import { RecordingFilters } from 'pages/recordings-list/types/recording';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import { FiltersFromUrl } from 'shared/types';
import { entriesFromObject } from 'shared/utils/objectToArray';

export interface TabType {
  label: string;
  value: string[];
  type: RecordingFilters;
}

const COMPETITION_PRIORITY: Record<string, number> = {
  'LaLiga 2024-25': 0,
  'LaLiga 2023-24': 1,
  'UEFA Champions League 2023-24': 2,
  'UEFA Youth League 23-24': 3,
  'LaLiga 2022-23': 4,
  'LaLiga 2021-22': 5,
  'LaLiga 2 2022-23': 6,
  'LaLiga 2 2021-22': 7,
  'Serie A 2023-24': 8,
  'Allsvenskan 2023': 9,
  'Saudi Pro League 2023-24': 10,
  'Saudi Arabian Super Cup 2023-24': 11,
  'Poland Division 1 2024-25': 12,
  'Poland Division 1 2023-24': 13,
  'Liga MX Apertura 2024': 14,
  'Liga MX Apertura 2023': 15,
  'Liga MX': 16,
  'Division de Honor 2022-23': 17,
  'Division de Honor Juvenil': 18,
  'Primera RFEF Grupo 2': 19,
  'Primera RFEF Grupo 1 2023/24': 20,
  'Liga F 2023-24': 21,
  'Liga F': 22,
};

const DEFAULT_URL_REQUEST = 'annotationType=ALL';

const sortTabsTypesByPriority = (a: TabType, b: TabType) => {
  const first = a.label in COMPETITION_PRIORITY ? COMPETITION_PRIORITY[a.label] : Number.MAX_SAFE_INTEGER;
  const second = b.label in COMPETITION_PRIORITY ? COMPETITION_PRIORITY[b.label] : Number.MAX_SAFE_INTEGER;

  let result = 0;
  if (first < second) result = -1;
  else if (first > second) result = 1;
  return result;
};

const getInitialFilters = (filters: Partial<FiltersFromUrl<RecordingsFiltersList>>): Partial<RecordingsFiltersList> => {
  return entriesFromObject(filters).reduce<Partial<RecordingsFiltersList>>((acc, filter) => {
    const [key, value] = filter || [];

    if (key && value) {
      acc[key] = {
        title: POSSIBLE_FILTERS[key].title,
        options: {
          [value]: { title: value, isApplied: true },
        },
      };
    }

    return acc;
  }, {});
};

const RecordingsListPageContainer = (): JSX.Element | null => {
  const { t } = useTranslation();
  const { getUrlFilters, getUrlString } = useRecordingsListFilters();
  const appliedFiltersString = getUrlString();
  const [keyState, setKeyState] = useState(false);

  useEffect(() => {
    setKeyState(appliedFiltersString !== DEFAULT_URL_REQUEST);
  }, [appliedFiltersString]);

  const { filters, isSuccess, isPending, isFetching } = useRecordings({
    extraKey: 'only-filters',
    refetchInterval: false,
    initialFilters: getInitialFilters(getUrlFilters()),
  });
  const branding = useBranding();

  useEffect(() => {
    document.title = t('common:metas.title.recordings', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  const competitionsTabs: TabType[] = useMemo(
    () =>
      filters?.competition?.options
        ? Object.keys(filters?.competition?.options)
            .filter((item) => !isEmpty(item))
            .map((item) => ({
              label: item,
              value: [item],
              type: RecordingFilters.COMPETITION,
            }))
            .sort(sortTabsTypesByPriority)
        : [],
    [filters],
  );

  if (isPending || isFetching) {
    return <RecordingListPageSkeleton />;
  }

  if (isSuccess) {
    return <RecordingsListPage key={String(keyState)} competitionsTabs={competitionsTabs} defaultFilters={filters} />;
  }

  return null;
};

export default RecordingsListPageContainer;
