import { Box, ListItem, styled } from '@mui/material';
import { Colors, fontWeight } from 'kognia-ui';

export const FixtureListHeaderGrid = styled(Box)(({ theme }) => ({
  display: 'grid',
  flexDirection: 'row',
  gridTemplateColumns: '.5fr 1fr 1fr 32px',
  alignItems: 'center',
  gridGap: theme.spacing(),
}));

export const GridListItem = styled(ListItem)(({ theme }) => ({
  display: 'grid',
  flexDirection: 'row',
  borderBottom: `1px solid ${Colors.iron}`,
  '& *': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingRight: theme.spacing(0.5),
  },
  '& label': {
    fontWeight: fontWeight['500'],
  },
  '&:last-child': {
    borderBottom: 0,
  },
}));
