import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { CoachApi } from 'pages/backoffice/api/coaches/types';
import { CoachesApiResponse } from 'pages/backoffice/api/coaches/use-coaches/types';
import { Coach } from 'pages/backoffice/types/coaches';
import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';

const transformData = (items: CoachApi[]): Coach[] => {
  return items.map((item) => ({
    id: item.uuid,
    name: item.name,
    photoUrl: item.photo_url,
  }));
};

export const transformCoaches = (response: CoachesApiResponse): InfinityQueryDataResult<Coach> => {
  return {
    data: {
      items: transformData(response.data),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
