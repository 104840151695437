import { SvgIcon, type SvgIconProps } from '../components/SvgIcon';

export const IconSelectAll = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule='evenodd'
        d='M4.75 4.05h12a.7.7 0 0 1 .7.7v1h1.3v-1a2 2 0 0 0-2-2h-12a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h1v-1.3h-1a.7.7 0 0 1-.7-.7v-12a.7.7 0 0 1 .7-.7z'
      />
      <g stroke='#6f738c'>
        <rect x='5.9' y='5.9' width='14.7' height='14.7' rx='1.35' strokeWidth='1.3' fill='#fff' />
        <path d='M8.25 13.101l3.332 3.149 6.669-7' strokeWidth='1.5' fill={'transparent'} />
      </g>
    </SvgIcon>
  );
};
