import { Episode } from 'shared/types';
import { MatchSegmentTypes, SegmentConfig } from 'shared/types/segment/types';

export const enrichEpisodesWithName = (episodes: Episode[]): Episode[] => {
  return episodes
    .sort((a, b) => (a.startTime > b.startTime ? 1 : -1))
    .map((episode, idx) => ({
      ...episode,
      name: `${idx + 1}`,
    }));
};
const defaultMatchSegments = [
  {
    segment: {
      name: MatchSegmentTypes.FIRST,
      start: 0,
      length: 2700,
    },
    start: 0,
    length: 2700,
  },
  {
    segment: {
      name: MatchSegmentTypes.SECOND,
      start: 2700,
      length: 2700,
    },
    start: 2700,
    length: 2700,
  },
];
export const enrichSegmentMatchStarts = (episodes: Episode[]): SegmentConfig[] => {
  if (episodes.length === 0) {
    return [];
  }

  const segments: SegmentConfig[] = [];

  defaultMatchSegments.forEach((matchSegment) => {
    const filteredEpisodes = episodes.filter((episode) => episode.matchSegment === matchSegment.segment.name);
    if (!filteredEpisodes.length) return;

    const episodeStartTime = Math.min(
      ...filteredEpisodes.map((episode) => {
        return episode.startTime;
      }),
    );
    const episodeEndTime = Math.max(
      ...filteredEpisodes.map((episode) => {
        return episode.endTime;
      }),
    );

    segments.push({
      ...matchSegment,
      start: episodeStartTime,
      length: episodeEndTime - episodeStartTime,
    });
  });

  return segments;
};
export const generateMatchSegments = (episodes: Episode[]): SegmentConfig[] => {
  return enrichSegmentMatchStarts(episodes);
};
