import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useInvalidateStages } from 'pages/backoffice/api/stages/use-stages';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';

interface DeleteParams {
  stageId: string;
  seasonId: string;
  onSuccess?: () => void;
}

export const useDeleteStage = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateStages = useInvalidateStages();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.DELETE,
    successMessage: 'Stage deleted',
    errorMessage: (data) => metadataApiErrorParser(data, 'Stage delete error'),
    onSuccess: invalidateStages,
  });

  const deleteStage = ({ stageId, onSuccess }: DeleteParams) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.STAGES}/${stageId}`,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, deleteStage };
};
