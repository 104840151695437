import { Badge, styled } from '@mui/material';
import { fontSizes } from 'kognia-ui';

export const MenuItemBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: 1,
    top: 2,
    minWidth: '16px',
    height: '16px',
    padding: theme.spacing(0, 0.375),
    fontSize: fontSizes.xxxSmall,
    lineHeight: 1,
    fontWeight: 'fontWeightRegular',
  },
}));
