import { TimelineTableBlock } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/timeline';
import { MatchSegmentTypes } from 'shared/types/segment/types';

export const generateFullMatchTimelineBlockFromDuration = (duration: number): TimelineTableBlock => ({
  id: 'full-match',
  name: 'full-match-block',
  startTime: 0,
  endTime: duration,
  matchSegment: MatchSegmentTypes.FIRST,
});
