import { Box, Checkbox, Grid, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';

import { useDates } from 'shared/hooks/use-dates';
import { RecordingByName } from 'shared/types/recording/types';

interface ResultsProps {
  recording: RecordingByName;
  isChecked: boolean;
}

export const RecordingResult = ({ recording, isChecked }: ResultsProps) => {
  const { dateToString } = useDates();

  const isScoreAvailable = recording.homeTeamScore !== null && recording.awayTeamScore !== null;

  return (
    <Grid container justifyContent='space-between' alignItems='center' p={0.5} borderBottom={1} borderColor='grey.200'>
      <Grid item>
        <Grid container spacing={1} alignItems='center'>
          <Grid item>
            <Checkbox checked={isChecked} />
          </Grid>
          <Grid item>
            <Box display='flex' alignItems='center' gap={1} lineHeight={1}>
              <Typography
                component='span'
                // TODO define variant styles in theme
                fontSize={fontSizes.default}
                overflow='hidden'
                textOverflow='ellipsis'
                whiteSpace='nowrap'
                maxWidth='144px'
                title={recording.name}
              >
                {recording.name}
              </Typography>
              {isScoreAvailable && (
                <Typography
                  component='span'
                  width='64px'
                  // TODO define variant styles in theme
                  fontSize={fontSizes.small}
                  lineHeight={1.67}
                  color='secondary.main'
                >
                  ({recording.homeTeamScore}-{recording.awayTeamScore})
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {/* TODO define variant styles in theme */}
        <Typography component='span' fontSize={fontSizes.small} lineHeight={1.67} color='secondary.main'>
          {dateToString(recording.date)}
        </Typography>
      </Grid>
    </Grid>
  );
};
