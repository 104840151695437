import {
  Box,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  TextField,
} from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';

const VIDEO_SOURCE_LIST_ITEM_INDENT = '32px';

interface VideoSourceListItemTextProps {
  inset?: boolean;
  isChecked?: boolean;
}

export const VideoSourceList = styled(List)(() => ({
  padding: 0,
}));

export const VideSourceListItemContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingRight: theme.spacing(2),
}));

export const VideoSourceListItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'isChecked',
})<VideoSourceListItemTextProps>(({ inset, isChecked }) => ({
  fontSize: fontSizes.default,
  paddingLeft: inset ? VIDEO_SOURCE_LIST_ITEM_INDENT : 0,
  fontWeight: isChecked ? fontWeight['500'] : fontWeight['400'],
}));

export const VideoSourceListItemButton = styled(ListItemButton)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingRight: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  '&:hover, &:focus': {
    backgroundColor: Colors.shark,
  },
}));

export const VideoSourceListItemIconButton = styled(IconButton)(() => ({
  '& svg': {
    fill: Colors.storm,
  },
  '&:hover': {
    '& svg': {
      fill: Colors.white,
    },
  },
}));

export const VideoSourceListItemIcon = styled(ListItemIcon)(() => ({
  width: VIDEO_SOURCE_LIST_ITEM_INDENT,
  minWidth: 'auto',
}));

export const VideoSourceListDivider = styled(Divider)(({ theme }) => ({
  borderColor: Colors.storm,
  width: `calc(100% + ${theme.spacing(4)})`,
  transform: `translateX(-${theme.spacing(2)})`,
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

export const VideoSourceListItemTextField = styled(TextField)({
  '& fieldset': {
    borderColor: Colors.storm,
  },
  '& input': {
    color: Colors.white,
    fontSize: fontSizes.default,
  },
  '&:hover': {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: Colors.lavender,
    },
  },
  '& .MuiOutlinedInput-notchedOutline.Mui-focused, .MuiOutlinedInput-notchedOutline': {
    borderColor: `${Colors.lavender} !important`,
  },
});
