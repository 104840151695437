import { ZoneValues } from 'shared/types/recording/types';

export enum Zones {
  'zone1' = 1,
  'zone2' = 2,
  'zone3' = 3,
  'zone4' = 4,
}

export interface FiltersStateEventsStartingAnEpisode {
  team?: string;
  tacticalScenario?: string;
  zones: ZoneValues;
}

export interface FiltersStateScenariosInsideAnEpisode {
  team?: string;
  tacticalScenario?: string;
  zones: ZoneValues;
  fundamentals: {
    offensive: FiltersFundamentals;
    defensive: FiltersFundamentals;
  };
}

export interface FiltersStateEventsEndingAnEpisode {
  team?: string;
  tacticalScenario?: string;
  zones: ZoneValues;
}

export interface FundamentalWithPlayers {
  id: string;
  players: string[];
}

export interface FiltersFundamentals {
  team?: string;
  fundamentals: FundamentalWithPlayers[];
}

export interface FiltersState {
  eventsStartingAnEpisode: FiltersStateEventsStartingAnEpisode;
  eventsEndingAnEpisode: FiltersStateEventsEndingAnEpisode;
  scenariosInsideAnEpisode: FiltersStateScenariosInsideAnEpisode;
}
