import { useCallback, useEffect } from 'react';

import { useRecordingsByName } from 'api/recording/useRecordingsByName/useRecordingsByName';
import { RecordingByName } from 'shared/types/recording/types';

type Options = {
  setRecordings: (recordings: RecordingByName[]) => void;
};

export const useSyncRecordingsSelection = ({ setRecordings }: Options) => {
  const fetchRecordingsByName = useRecordingsByName({ enabled: false });

  useEffect(() => {
    if (fetchRecordingsByName.isEnabled && fetchRecordingsByName.data) {
      setRecordings(fetchRecordingsByName.data);
    }
  }, [fetchRecordingsByName.data, fetchRecordingsByName.isEnabled, setRecordings]);

  const handleSearchRecordings = useCallback(
    (recordingIds: string[]) => {
      fetchRecordingsByName.setPageSize(recordingIds.length);
      fetchRecordingsByName.setRecordingIds(recordingIds);
      fetchRecordingsByName.setEnabled(recordingIds.length > 0);
    },
    [fetchRecordingsByName],
  );

  return useCallback(
    (recordings: RecordingByName[], recordingIds: string[]) => {
      if (recordingIds.length === 0) return;

      recordings.length === 0 ? handleSearchRecordings(recordingIds) : fetchRecordingsByName.setEnabled(false);
    },
    [handleSearchRecordings, fetchRecordingsByName],
  );
};
