import { Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';

export const PageTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography fontSize={fontSizes.xLarge} data-testid='page-title' component={'h1'}>
      {children}
    </Typography>
  );
};
