import { styled } from '@mui/material';

interface LogoProps {
  isHidden?: boolean;
}

export const LogoImg = styled('img', { shouldForwardProp: (prop) => prop !== 'isHidden' })<LogoProps>(
  ({ isHidden }) => ({
    display: isHidden ? 'none' : 'block',
    width: '100%',
    maxWidth: '100%',
  }),
);
