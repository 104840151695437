import { styled, Box, alpha } from '@mui/material';
import { fontSizes } from 'kognia-ui';

const ARROW_SIZE = '10px';
const TIMER_WIDTH = '50px';

export const PlayHeadContent = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isShadow',
})<{ isShadow?: boolean }>(({ theme, isShadow }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  height: '100%',
  opacity: 0,
  transition: 'opacity 0.1s ease-out',
  userSelect: 'none',

  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '50%',
    width: '1px',
    height: '100%',
    background: theme.palette.warning.main,

    ...(isShadow && {
      background: alpha(theme.palette.secondary.light, 0.9),
    }),
  },
}));

export const PlayHeadArrowDown = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isShadow',
})<{ isShadow?: boolean }>(({ theme, isShadow }) => ({
  position: 'absolute',
  top: 0,
  left: `-${ARROW_SIZE}`,
  zIndex: 1,
  width: 0,
  height: 0,
  borderLeft: `${ARROW_SIZE} solid transparent`,
  borderRight: `${ARROW_SIZE} solid transparent`,
  borderTop: `${ARROW_SIZE} solid ${theme.palette.warning.main}`,

  ...(isShadow && {
    borderTopColor: alpha(theme.palette.secondary.light, 0.9),
  }),
}));

export const PlayHeadTime = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isShadow',
})<{ isShadow?: boolean }>(({ theme, isShadow }) => ({
  position: 'absolute',
  top: ARROW_SIZE,
  left: `calc(-${TIMER_WIDTH} / 2)`,
  zIndex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: TIMER_WIDTH,
  height: '16px',
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
  fontSize: fontSizes.xxSmall,
  lineHeight: 1,
  background: theme.palette.warning.main,
  borderRadius: '4px',

  ...(isShadow && {
    background: alpha(theme.palette.secondary.light, 0.9),
  }),
}));

export const PlayHeadHeader = styled(Box)({
  position: 'relative',
  userSelect: 'none',
});
