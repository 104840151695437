import { IconSizes } from 'kognia-ui/components/SvgIcon';
import { useCallback } from 'react';

import ActiveFilter from 'shared/components/active-filter';
import { RecordingByName } from 'shared/types/recording/types';

interface Props {
  onRemove: (recordingId: string) => void;
  recording: RecordingByName;
  size?: IconSizes;
}

export const ActiveRecordingFilter = ({ recording, onRemove, size = 'medium' }: Props) => {
  const isScoreAvailable = recording.homeTeamScore !== null && recording.awayTeamScore !== null;

  const handleOnRemove = useCallback(() => {
    onRemove(recording.id);
  }, [onRemove, recording]);

  return (
    <ActiveFilter
      size={size}
      onClose={handleOnRemove}
      closeButton
      key={recording.id}
      title={
        <>
          {recording.name}
          {isScoreAvailable && (
            <span>
              {' '}
              ({recording.homeTeamScore}-{recording.awayTeamScore})
            </span>
          )}
        </>
      }
    />
  );
};
