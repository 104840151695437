import { useMemo } from 'react';

import { useTacticalAnalysisMode } from 'pages/tactical-analysis/hooks/use-tactical-analysis-mode';
import {
  useTimelineShowBallPossession,
  useTimelineShowNoBallPossession,
} from 'pages/tactical-analysis/hooks/use-timeline-ball-possession';
import { useTimelineTeamIdFocus } from 'pages/tactical-analysis/hooks/use-timeline-team-id-focus';

export const useTimelineConfigs = (recordingId: string) => {
  const showNoBallPossession = useTimelineShowNoBallPossession(recordingId);
  const showBallPossession = useTimelineShowBallPossession(recordingId);
  const { isHomeTeamSelected, isOpponentTeamSelected } = useTimelineTeamIdFocus(recordingId);
  const tacticalAnalysisMode = useTacticalAnalysisMode(recordingId);

  return useMemo(
    () => ({
      showNoBallPossession,
      showBallPossession,
      isHomeTeamSelected,
      isOpponentTeamSelected,
      tacticalAnalysisMode,
    }),
    [showNoBallPossession, showBallPossession, isHomeTeamSelected, isOpponentTeamSelected, tacticalAnalysisMode],
  );
};
