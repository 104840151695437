import { List, ListItem, ListItemButton, ListItemIcon, Paper, styled } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';

import { openSidebarWidth } from 'shared/components/sidebar-layout/SideBarLayout.styled';

export const MenuItem = styled(ListItem)(({ theme }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  paddingBottom: theme.spacing(0.5),
  paddingTop: theme.spacing(0.5),
}));

export const MenuButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: 8,
  paddingLeft: theme.spacing(1),
  paddingRight: 0,
  fontSize: fontSizes.default,
  '&:hover': {
    backgroundColor: Colors.titanPurple,
  },
  '&.Mui-selected': {
    backgroundColor: Colors.lavender,
  },
}));

export const MenuIcon = styled(ListItemIcon)(({ theme }) => ({
  flexGrow: 0,
  minWidth: '24px',
  marginRight: theme.spacing(1),
}));

interface SubMenuItemProps {
  bottomDivider?: boolean;
}

export const SubMenuItem = styled(ListItem, {
  shouldForwardProp: (prop) => prop !== 'bottomDivider',
})<SubMenuItemProps>(({ bottomDivider }) => ({
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
  paddingBottom: 0,
  ...(bottomDivider && {
    borderBottom: `1px solid ${Colors.athens}`,
  }),
}));

export const SubMenuButton = styled(ListItemButton)(({ theme }) => ({
  borderRadius: 0,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  lineHeight: '22px',
  fontSize: fontSizes.default,
  '&:hover': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
  '&.Mui-selected': {
    backgroundColor: 'transparent',
    color: theme.palette.primary.main,
  },
}));

export const SubMenuPaper = styled(Paper)(({ theme }) => ({
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  width: `calc(${openSidebarWidth}px - ${theme.spacing(4)})`,
  marginBottom: theme.spacing(1),
}));

export const Menu = styled(List)(({ theme }) => ({
  paddingTop: 0,
  paddingBottom: 0,
  marginTop: theme.spacing(-0.5),
  marginBottom: theme.spacing(-0.5),
}));
