import { Button, ButtonProps } from '@mui/material';

interface Props extends ButtonProps {}

export const ButtonOutlined = ({ children, sx, color = 'secondary', ...restProps }: Props) => {
  return (
    <Button
      color={color}
      variant='outlined'
      sx={{ fontWeight: 'fontWeightRegular', borderRadius: 5, whiteSpace: 'nowrap', ...sx }}
      {...restProps}
    >
      {children}
    </Button>
  );
};
