import throttle from 'lodash/throttle';
import { useEffect, useMemo } from 'react';

import { useValueRef } from 'shared/hooks/use-value-ref/useValueRef';

interface Options {
  leading?: boolean;
  trailing?: boolean;
}

interface Settings {
  wait?: number;
  options?: Options;
}

const DEFAULT_THROTTLE_TIME = 200;

export const useThrottle = <T extends (...args: any[]) => ReturnType<T>>(callback: T, settings?: Settings) => {
  const callbackRef = useValueRef<T>(callback);

  const throttledCallback = useMemo(
    () =>
      throttle(
        (...args: Parameters<T>) => callbackRef.current(...args),
        settings?.wait ?? DEFAULT_THROTTLE_TIME,
        settings?.options,
      ),
    [callbackRef, settings?.options, settings?.wait],
  );

  useEffect(() => {
    return () => throttledCallback.cancel();
  }, [throttledCallback]);

  return throttledCallback;
};
