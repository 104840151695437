import { Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';

import { useDates } from 'shared/hooks/use-dates';

type DateInfoProps = {
  date: Date;
};

const DateInfo = ({ date }: DateInfoProps) => {
  const { dateToString } = useDates();
  return <Typography fontSize={fontSizes.small}>{dateToString(date)}</Typography>;
};

export default DateInfo;
