import { Box } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

export const RecordingsFiltersHeader = ({ children }: Props) => {
  return (
    <Box p={4} bgcolor='grey.200'>
      {children}
    </Box>
  );
};
