import {
  PlayerStateMachineContext,
  PlayerStateMachineEvent,
  UpdatePlaylistItemAction,
} from 'shared/components/video-player/types';
import { getVideoByVideoType } from 'shared/components/video-player/util';

export function isPaused(context: PlayerStateMachineContext) {
  return !context.isPlaying;
}

export function isPlaying(context: PlayerStateMachineContext) {
  return context.isPlaying;
}

export function isInStandBy(context: PlayerStateMachineContext) {
  return context.isInStandBy;
}

export function isLastVideoSource(context: PlayerStateMachineContext) {
  if (!context.playlist.playingItem.playlistItem) return false;

  const { videoSources } = getVideoByVideoType(
    context.playlist.playingItem.playlistItem,
    context.playlist.currentSelectedPlayingMode,
  );

  return videoSources.length - 1 === context.playlist.playingItem.videoSourceIndex;
}

export function isLastPlaylistItem(context: PlayerStateMachineContext) {
  if (!context.playlist.playingItem.playlistItem) return false;

  const currentPlaylistItemIndex = context.playlist.playlistItems.findIndex(
    (playlistItem) => playlistItem.id === context.playlist.currentPlaylistItemId,
  );

  return currentPlaylistItemIndex === context.playlist.playlistItems.length - 1;
}

export function isFirstPlaylistItem(context: PlayerStateMachineContext) {
  if (!context.playlist.playingItem.playlistItem) return false;

  const currentPlaylistItemIndex = context.playlist.playlistItems.findIndex(
    (playlistItem) => playlistItem.id === context.playlist.currentPlaylistItemId,
  );

  return currentPlaylistItemIndex === 0;
}

export function isPlaylistEmpty(context: PlayerStateMachineContext) {
  return !Boolean(context.playlist.playlistItems.length);
}

export function isPlayerNotReady(context: PlayerStateMachineContext) {
  return !Boolean(context.videoRef?.current);
}

export function isPlayerReady(context: PlayerStateMachineContext) {
  return Boolean(context.videoRef?.current);
}

export function isNotLastPlaylistItem(context: PlayerStateMachineContext) {
  return !isLastPlaylistItem(context);
}

export function shouldAutoplayNextPlaylistItem(context: PlayerStateMachineContext) {
  return context.autoPlayNextPlaylistItem && isNotLastPlaylistItem(context);
}

export function isCurrentPlaylistItem(context: PlayerStateMachineContext, _event: PlayerStateMachineEvent) {
  const event = _event as UpdatePlaylistItemAction;
  return context.playlist.currentPlaylistItemId === event.playlistItem.id;
}

export const hasPlaylistItemNoDuration = (context: PlayerStateMachineContext) => {
  return context.playlist.playingItem.playlistItem.duration === 0;
};
