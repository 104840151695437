import { Grid } from '@mui/material';

import styles from 'pages/recordings-list/components/vod-recording-card/RecordingCard.module.scss';

type CompetitionInfoProps = {
  competitionName?: string;
};

const CompetitionInfo = ({ competitionName }: CompetitionInfoProps) => {
  if (!competitionName) return null;

  return (
    <Grid item xs={12} sm={'auto'} className={styles.recordingCardTag}>
      {competitionName}
    </Grid>
  );
};

export default CompetitionInfo;
