import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

export const useIntersectionObserver = <T extends MutableRefObject<HTMLElement | null>>(
  observer: (entries: IntersectionObserverEntry[]) => void, //NOTE: wrap observer with useCallback
  elementRef: T,
) => {
  const observerRef = useRef<IntersectionObserver | null>(null);

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];
      if (!target.isIntersecting) {
        return;
      }

      observer(entries);
    },
    [observer],
  );

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }

    if (elementRef.current) {
      observerRef.current = new IntersectionObserver(handleObserver);
      observerRef.current.observe(elementRef.current);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [elementRef, handleObserver]);
};
