import { Colors } from 'kognia-ui';

import { TypeCounterBackgroundColorType, TypeCounterColorType } from 'shared/components/type-counter/styled';
import { TypeOfPlay } from 'shared/types/type-of-play/types';

export type TaggingEventClipNameBackgroundColorType = {
  [value in TypeOfPlay]: TypeCounterBackgroundColorType;
};

export type TaggingEventClipNameColorType = {
  [value in TypeOfPlay]: TypeCounterColorType;
};

export const TaggingEventClipTypeBackgroundColor: TaggingEventClipNameBackgroundColorType = {
  [TypeOfPlay.Offense]: Colors.melrose,
  [TypeOfPlay.Defense]: Colors.river,
  [TypeOfPlay.Transition]: Colors.dawn,
  [TypeOfPlay.GameEvent]: 'none',
  [TypeOfPlay.imported]: 'none',
};

export const TaggingEventClipTypeColor: TaggingEventClipNameColorType = {
  [TypeOfPlay.Offense]: Colors.brandPastel,
  [TypeOfPlay.Defense]: Colors.green,
  [TypeOfPlay.Transition]: Colors.peach,
  [TypeOfPlay.GameEvent]: 'initial',
  [TypeOfPlay.imported]: 'initial',
};
