import { useState } from 'react';
import { ImageSizes } from '../../shared/types/images';
import { AvatarBase } from './AvatarBase';
import { getIconSizeFromImageSize } from '../../shared/utils/getIconSizeFromImageSize';
import { IconPlayer } from '../../icons/IconPlayer';

type Props = {
  src: string | undefined | null;
  name?: string;
  size?: ImageSizes;
  withBorder?: boolean;
};

export const PlayerAvatar = ({ src, name, size = 'small', withBorder }: Props) => {
  const [hasSrcError, setHasSrcError] = useState(!src);

  return (
    <AvatarBase
      size={size}
      alt={name}
      src={src ?? ''}
      withBackground={hasSrcError}
      withBorder={withBorder}
      onError={() => setHasSrcError(true)}
    >
      <IconPlayer size={getIconSizeFromImageSize(size)} color='secondary' />
    </AvatarBase>
  );
};
