import { List, Stack, ListItemButton } from '@mui/material';
import { useCallback, useRef } from 'react';

import { useTeamsInfinityQuery } from 'api/teams/use-teams-infinity-query/useTeamsInfinityQuery';
import { useSetCompetitionsState, useCompetitionsState } from 'entities/competitions/store/steps';
import { TeamRow } from 'features/competitions/teams-step/ui/team-row/TeamRow';
import Spinner from 'shared/components/spinner';
import { useIntersectionObserver } from 'shared/hooks/use-intersection-observer/useIntersectionObserver';
import { Team } from 'shared/types/team/team';
import { invariant } from 'shared/utils/invariant';

export const TeamsStepFeature = () => {
  const { season } = useCompetitionsState();
  const setCompetitionsState = useSetCompetitionsState();
  invariant(season?.id, 'seasonId is undefined in TeamsStepFeature');
  const { items, isFetching, fetchNextPage, hasNextPage } = useTeamsInfinityQuery({
    initialFilters: { seasonId: season?.id },
  });
  const lastTeamRef = useRef<HTMLDivElement | null>(null);

  const handleObserver = useCallback(() => {
    if (!hasNextPage || isFetching) {
      return;
    }

    fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetching]);

  useIntersectionObserver(handleObserver, lastTeamRef);

  const handleGotToPlayers = (team: Team) => {
    setCompetitionsState((prev) => {
      return {
        ...prev,
        step: 'players',
        team,
      };
    });
  };

  return (
    <Stack>
      {items.length > 0 && (
        <List disablePadding>
          {items.map((team, index) => (
            <ListItemButton
              key={team.id}
              ref={index === items.length - 1 ? lastTeamRef : null}
              sx={{ p: 0, borderBottom: ({ palette }) => `1px solid ${palette.divider}` }}
              onClick={() => handleGotToPlayers(team)}
            >
              <TeamRow logoUrl={team.logoUrl} name={team.name} />
            </ListItemButton>
          ))}
        </List>
      )}

      {isFetching && (
        <Stack alignItems={'center'}>
          <Spinner />
        </Stack>
      )}
    </Stack>
  );
};
