import { Button, styled } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';

export const PaginationLoadingButton = styled(Button)(({ theme }) => ({
  '&.MuiButton-root': {
    backgroundColor: Colors.night,
    minWidth: 142,
    textTransform: 'uppercase',
    fontSize: fontSizes.xSmall,
    letterSpacing: '1.6px',
    color: Colors.white,
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.action.disabledBackground,
  },
  '&.Mui-disabled.MuiLoadingButton-loading': {
    color: 'transparent',
  },
}));
