import { useCallback } from 'react';

import { queryClient } from 'api/config';
import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { latestViewedDownloadRequestsUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';

export const useUpdateDownloadRequests = () => {
  const { mutate } = useMutationRequest({
    type: HTTPMethod.PUT,
  });

  const updateDownloadRequests = useCallback(
    async (queryKeys?: string[]) => {
      return mutate(
        { url: latestViewedDownloadRequestsUrl },
        {
          onSuccess: () => {
            if (queryKeys) {
              queryClient.invalidateQueries({
                predicate: (query) =>
                  query.queryKey.some((queryKey) =>
                    typeof queryKey === 'string' ? queryKeys.includes(queryKey) : false,
                  ),
              });
            }
          },
        },
      );
    },
    [mutate],
  );

  return { updateDownloadRequests };
};
