import { useAtomValue } from 'jotai';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { featureFlagsState } from 'shared/contexts/app-state/atoms';

export function useFeatureFlag(featureFlag?: FEATURE_FLAG) {
  const featureFlags = useAtomValue(featureFlagsState);

  return Boolean(featureFlags && !!featureFlag && featureFlags[featureFlag]);
}
