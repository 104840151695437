import { sort } from 'fast-sort';

const comparer = new Intl.Collator(undefined, {
  numeric: true,
  sensitivity: 'base',
}).compare;

export function sortArray<T>(params: { data: Array<T>; key: keyof T; asc?: boolean }): Array<T> {
  const { data, key, asc } = params;

  const f = (item: T) => item[key];
  const sortBy =
    asc === false
      ? {
          desc: f,
        }
      : {
          asc: f,
        };
  return sort(data).by({ ...sortBy, comparer });
}

export function sortStringArray(data: Array<string>, asc = false): Array<string> {
  const f = (item: string) => item;
  const sortBy = !asc
    ? {
        desc: f,
      }
    : {
        asc: f,
      };
  return sort(data).by({ ...sortBy, comparer });
}
