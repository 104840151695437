import { Suspense } from 'react';

import { ErrorContainerFeature } from 'features/error-container/ErrorContainer.feature';
import { SidebarLayout } from 'shared/components/sidebar-layout';

export const ErrorPage = () => {
  return (
    <SidebarLayout>
      <Suspense>
        <ErrorContainerFeature />
      </Suspense>
    </SidebarLayout>
  );
};
