import React, { useEffect } from 'react';

import { useCurrentPlaylistItem, useIsSeeking, useVideoPlayerState } from 'shared/components/video-player/hooks';
import {
  useAreOverlaysReady,
  useOverlaysController,
} from 'shared/components/video-player/hooks/use-overlays-controller';
import { VideoOverlaysHomographyContainer } from 'shared/components/video-player/overlays/styled';
import { READY_STATES } from 'shared/components/video-player/state/states';

type Props = {
  disabled: boolean;
};

export const VideoOverlays = ({ disabled }: Props) => {
  const { container, overlayGenerator, matrix3dTransformation } = useOverlaysController();
  const currentPlaylistItem = useCurrentPlaylistItem();
  const isSeeking = useIsSeeking();
  const areOverlaysReady = useAreOverlaysReady();
  const { readyState } = useVideoPlayerState();

  useEffect(() => {
    if (currentPlaylistItem && currentPlaylistItem.fundamentalsSelected.tacticalAnalysisId) {
      overlayGenerator.init({
        tacticalAnalysisId: currentPlaylistItem.fundamentalsSelected.tacticalAnalysisId,
        recordingId: currentPlaylistItem.recordingId,
      });
    }
  }, [currentPlaylistItem, overlayGenerator]);

  const areOverlaysVisible =
    !disabled &&
    !!matrix3dTransformation &&
    areOverlaysReady &&
    !isSeeking &&
    currentPlaylistItem.fundamentalsSelected.tacticalAnalysisId &&
    (readyState === READY_STATES.PLAYING || readyState === READY_STATES.PAUSED || readyState === READY_STATES.STAND_BY);

  return (
    <VideoOverlaysHomographyContainer
      key={currentPlaylistItem.recordingId}
      ref={container}
      aspectRatio={'16/9'}
      style={{
        display: areOverlaysVisible ? 'block' : 'none',
        transform: matrix3dTransformation ? `matrix3d(${matrix3dTransformation.join(',')})` : 'none',
      }}
    />
  );
};
