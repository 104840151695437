import { MenuItem, styled } from '@mui/material';
import { fontSizes } from 'kognia-ui';

export const ClipActionsMenuTitle = styled(MenuItem)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontSize: fontSizes.xSmall,
  fontWeight: theme.typography.fontWeightMedium,
  textTransform: 'uppercase',
  letterSpacing: '1.6px',
  opacity: '1 !important',
}));
