import { Paper } from '@mui/material';
import { Breakpoint, generateContainerQueries } from 'kognia-ui';

type Props = {
  children: React.ReactNode;
};

const HEADER_MAX_WIDTH = '440px';
const HEADER_MIN_WIDTH = '316px';

export const ProfileInfoWrapper = ({ children }: Props) => {
  return (
    <Paper
      elevation={0}
      sx={{
        position: 'relative',
        padding: 2,
        pr: 5,
        ...generateContainerQueries({
          [Breakpoint.MD]: {
            minWidth: HEADER_MIN_WIDTH,
            maxWidth: HEADER_MAX_WIDTH,
          },
        }),
      }}
    >
      {children}
    </Paper>
  );
};
