import { Box, styled } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';

export const BadgeText = styled(Box)(({ theme }) => ({
  background: Colors.background,
  display: 'flex',
  fontSize: fontSizes.xxSmall,
  fontWeight: theme.typography.fontWeightMedium,
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  borderRadius: '2px',
  height: '24px',
  lineHeight: fontSizes.xxSmall,
}));
