import React from 'react';

import { RowGroup } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/row';
import { RowsContentGroup } from 'pages/tactical-analysis/components/timeline/timeline-table/components/row-content-group';

type Props = {
  rowGroups: RowGroup[];
  recordingId: string;
};

export const RowGroups = React.memo(({ rowGroups, recordingId }: Props) => {
  return (
    <>
      {rowGroups
        .filter((rowGroup) => rowGroup.totalClips > 0)
        .map((rowGroup) => (
          <RowsContentGroup key={rowGroup.type} level={0} rowGroup={rowGroup} recordingId={recordingId} />
        ))}
    </>
  );
});

RowGroups.displayName = 'RowGroups';
