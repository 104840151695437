import { MetadataFixture, MetadataFixturePlayer } from 'pages/backoffice/types/fixture';
import { Player } from 'shared/types/player/player';
import { TeamWithPlayers } from 'shared/types/team/team';

export const transformFixturePlayer = (
  player: MetadataFixturePlayer,
  team: TeamWithPlayers,
  fixture: MetadataFixture,
): Player => {
  return {
    id: player.id,
    name: player.name,
    photoUrl: player.photoUrl,
    fixtures: [
      {
        jerseyNumber: player.jerseyNumber,
        side: player.side,
        name: player.name,
        date: fixture.date ?? '',
        position: player.position,
        id: player.id,
        homeTeamScore: fixture.homeTeamScore,
        awayTeamScore: fixture.awayTeamScore,
        awayPenaltyScore: fixture.awayPenaltyScore,
        homePenaltyScore: fixture.homePenaltyScore,
        matchEndType: fixture.matchEndType,
        team,
      },
    ],
    // TODO remove isFavorite when Player type is not shared by the app
    isFavorite: false,
    mappings: player.mappings || [],
  };
};
