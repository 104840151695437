import { UseSuspenseQueryResult } from '@tanstack/react-query';

import { transformCompetitions } from 'api/competitions/utils/transformCompetitions';
import { useFetchSuspenseRequest } from 'api/hooks/useFetchSuspenseRequest';
import { teamCompetitions } from 'api/routes';
import { Competition } from 'shared/types/competition/competition';

const TEAM_COMPETITIONS_QUERY_KEY = 'team-competitions';

export const useTeamCompetitions = (teamId: string): UseSuspenseQueryResult<Competition[]> => {
  return useFetchSuspenseRequest<Competition[]>({
    url: teamCompetitions(teamId),
    queryRef: [TEAM_COMPETITIONS_QUERY_KEY, teamId],
    transformer: transformCompetitions,
  });
};
