import { Grid } from '@mui/material';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const ListTitleContainer = ({ children }: Props) => {
  return (
    <Grid item container justifyContent={'space-between'} alignItems={'center'} gap={2}>
      {children}
    </Grid>
  );
};
