import { Stack } from '@mui/material';

import { PlainText } from 'shared/components/editable-text/plain-text';

type Props = {
  title: string;
};
export const PlaylistTitle = ({ title }: Props) => {
  return (
    <Stack justifyContent={'center'}>
      <PlainText originalText={title} />
    </Stack>
  );
};
