export enum MatchSegmentTypes {
  FIRST = 'first',
  SECOND = 'second',
  FIRST_EXTRA = 'firstextra',
  SECOND_EXTRA = 'secondextra',
  PENALTY = 'penalty',
}

export type SegmentConfig = {
  length: number;
  start: number;
  segment: {
    length: number;
    name: MatchSegmentTypes;
    start: number;
  };
};
