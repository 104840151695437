import { SearchType } from 'entities/dashboard/types/dashboardSearch';

type SearchResultsTabsKeys = {
  [key in SearchType]: string;
};

export const searchResultsTabsKeys: SearchResultsTabsKeys = {
  [SearchType.PLAYER]: 'tabs.player',
  [SearchType.ALL]: 'tabs.all',
  [SearchType.TEAM]: 'tabs.team',
};
