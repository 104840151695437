// NOTE: schema for getPreset function created based on this object
export const USER_PRESET_KEYS = {
  multiMatchAppliedFilters: 'multimatch-applied-filters',
  playingMode: 'playing-mode',
  selectedTactics: 'selected-tactics',
  zoomLevel: 'zoom-level',
  height: 'height',
  pinScenarios: 'pin-scenarios',
  headersWidth: 'headers-width',
  timeLineAppliedFilters: 'timeline-applied-filters',
  filters: 'filters',
  time: 'time',
  teamIdFocus: 'team-id-focus',
  showBallPossession: 'show-ball-possession',
  showNoBallPossession: 'show-no-ball-possession',
  speed: 'speed',
} as const;
