import classNames from 'classnames';

import styles from 'pages/tactical-analysis/components/timeline/timeline-table/components/team-row-highlight/TeamRowHighlight.module.scss';
import { Team } from 'pages/tactical-analysis/components/timeline/types';

export const TeamRowHighlight = ({ team }: { team: Team }) => {
  return (
    <div
      className={classNames(styles.teamHomeHighlight, {
        [styles.isHomeTeam]: team === 'home',
        [styles.isOpponentTeam]: team === 'opponent',
      })}
    />
  );
};
