import { Dispatch, useReducer } from 'react';

export type State = {
  enabled: boolean;
  more: boolean;
};

export type InitialState = Pick<State, 'enabled'>;

export enum ActionType {
  ENABLED,
  MORE,
}

export type Action = { type: ActionType.ENABLED; payload: boolean } | { type: ActionType.MORE; payload: boolean };

const reducer = (state: State, action: Action): State => {
  if (action.type === ActionType.ENABLED) {
    return {
      ...state,
      enabled: action.payload,
    };
  } else if (action.type === ActionType.MORE) {
    return {
      ...state,
      more: action.payload,
    };
  }
  return state;
};

export const useEventButtonState = (initialState: InitialState): [State, Dispatch<Action>] => {
  const [state, dispatch] = useReducer(reducer, {
    more: false,
    enabled: initialState.enabled,
  });

  return [state, dispatch];
};
