import { useTranslation } from 'react-i18next';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { validateTacticalAnalysisUrl } from 'api/routes';
import { HTTPMethod, TacticalAnalysisApiResponse } from 'api/types';

export const useValidateTacticalAnalysis = (onSuccess?: (response: TacticalAnalysisApiResponse) => void) => {
  const { t } = useTranslation();

  const { mutate, isPending, isError, isSuccess } = useMutationRequest({
    type: HTTPMethod.POST,
    errorMessage: t('api:use-validate-tactical-analysis.error'),
    successMessage: t('api:use-validate-tactical-analysis.success'),
    onSuccess: async (response: TacticalAnalysisApiResponse) => {
      onSuccess && onSuccess(response);
    },
  });

  const validateTacticalAnalysis = (tacticalAnalysisId: string) => {
    mutate({ url: validateTacticalAnalysisUrl(tacticalAnalysisId) });
  };

  return { validateTacticalAnalysis, isPending, isError, isSuccess };
};
