import classNames from 'classnames';
import React, { forwardRef, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import {
  ClipWithSelection,
  useGetClip,
} from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/atoms';
import { ClipActions } from 'pages/tactical-analysis/components/timeline/timeline-table/components/clip-actions';
import { RowActions } from 'pages/tactical-analysis/components/timeline/timeline-table/components/row-actions';
import { useTimelineEventsHandlers } from 'pages/tactical-analysis/components/timeline/timeline-table/hooks/use-timeline-events-handlers';
import styles from 'pages/tactical-analysis/components/timeline/timeline-table/TimelineTable.module.scss';
import { useTacticalAnalysisMode } from 'pages/tactical-analysis/hooks/use-tactical-analysis-mode';
import { useTimelineConfigs } from 'pages/tactical-analysis/hooks/useTimelineConfigs';
import { TacticalAnalysisPlayingMode } from 'pages/tactical-analysis/types/tactical-analysis-playing-mode';

export const TimelineTableWrapper = forwardRef(
  (
    {
      children,
      recordingId,
      ...rest
    }: PropsWithChildren<
      React.HTMLAttributes<HTMLDivElement> & {
        recordingId: string;
      }
    >,
    ref: any,
  ) => {
    const config = useTimelineConfigs(recordingId);
    const getClip = useGetClip();
    const tacticalAnalysisMode = useTacticalAnalysisMode(recordingId);

    const [clipAnchorEl, setClipAnchorEl] = useState<null | HTMLDivElement>(null);
    const [contextMenuClip, setContextMenuClip] = useState<ClipWithSelection | undefined>(undefined);

    const [rowAnchorEl, setRowAnchorEl] = useState<null | HTMLDivElement>(null);
    const [contextMenuRow, setContextMenuRow] = useState<string | undefined>(undefined);

    const [clickTimeout, setClickTimeout] = useState<NodeJS.Timeout | null>(null);

    const {
      handleClick,
      handleDoubleClick,
      handlePlayClip,
      handlePlayRow,
      handleSelectClip,
      handlePlaySelectedClips,
      handleDeselectClip,
      handleSelectRowClips,
      handlePlayHomeTeamRow,
      handlePlayOpponentTeamRow,
    } = useTimelineEventsHandlers(recordingId);

    const handleTimelineClick = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        const element = event.target instanceof HTMLDivElement ? (event.target as HTMLElement) : undefined;

        if (clickTimeout !== null && !event.shiftKey && !event.ctrlKey && !event.metaKey) {
          // A single click has happened recently, let's handle double click
          clearTimeout(clickTimeout);
          setClickTimeout(null);
        } else {
          const timeoutTime = element && (element.dataset.clipId || element.parentElement?.dataset.rowId) ? 300 : 0;
          // This might be the first click of a double click, or a single click. Let's wait a bit to see.
          const timeoutId = setTimeout(() => {
            handleClick(event);
            setClickTimeout(null);
          }, timeoutTime);
          setClickTimeout(timeoutId);
        }
      },
      [clickTimeout, handleClick],
    );

    const handleContextMenu = useCallback(
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        event.stopPropagation();
        if (event.target instanceof HTMLDivElement && event.target.dataset.isDisabled === 'true') return;

        if (event.target instanceof HTMLDivElement && event.target.dataset.clipId) {
          setClipAnchorEl(event.target);
          setContextMenuClip(getClip(event.target.dataset.clipId));
        }

        const rowElement: HTMLDivElement | null =
          event.target instanceof HTMLDivElement
            ? event.target.dataset.rowId
              ? event.target
              : event.target.closest('[data-row-id]')
            : null;

        if (tacticalAnalysisMode === TacticalAnalysisPlayingMode.selection) return;
        if (rowElement && rowElement?.dataset.rowId) {
          setRowAnchorEl(rowElement);
          setContextMenuRow(rowElement.dataset.rowId);
        }
      },
      [getClip, tacticalAnalysisMode],
    );

    const handleClose = useCallback(() => {
      setClipAnchorEl(null);
    }, []);

    const handleRowClose = useCallback(() => {
      setRowAnchorEl(null);
    }, []);

    const className = useMemo(() => {
      const showHomeTeamBallPossession =
        (config.isHomeTeamSelected && config.showBallPossession) ||
        (config.isOpponentTeamSelected && config.showNoBallPossession);
      const showOpponentTeamBallPossession =
        (config.isHomeTeamSelected && config.showNoBallPossession) ||
        (config.isOpponentTeamSelected && config.showBallPossession);

      return classNames(styles.timelineTableContentWrapper, {
        [styles.isPlayingSelection]: config.tacticalAnalysisMode === 'selection',
        [styles.hideHomeTeamBallPossession]: !showHomeTeamBallPossession,
        [styles.hideOpponentTeamBallPossession]: !showOpponentTeamBallPossession,
        [styles.hideHomeTeamTacticRows]: config.isOpponentTeamSelected,
        [styles.hideOpponentTeamTacticRows]: config.isHomeTeamSelected,
        [styles.showHomeTeamTactics]: config.isHomeTeamSelected,
        [styles.opponentHomeTeamTactics]: config.isOpponentTeamSelected,
      });
    }, [config]);

    return (
      <>
        <div
          {...rest}
          onClick={handleTimelineClick}
          onDoubleClick={handleDoubleClick}
          onContextMenu={handleContextMenu}
          ref={ref}
          className={className}
        >
          {children}
        </div>
        {Boolean(clipAnchorEl) && contextMenuClip ? (
          <ClipActions
            clip={contextMenuClip}
            deselectClip={handleDeselectClip}
            isSelected={contextMenuClip.isSelected}
            menuAnchorEl={clipAnchorEl}
            onClose={handleClose}
            playSelectedClips={handlePlaySelectedClips}
            playClip={handlePlayClip}
            recordingId={recordingId}
            selectClip={handleSelectClip}
            selectRow={handleSelectRowClips}
          />
        ) : null}
        {Boolean(rowAnchorEl) && contextMenuRow ? (
          <RowActions
            rowId={contextMenuRow}
            menuAnchorEl={rowAnchorEl}
            onClose={handleRowClose}
            playRow={handlePlayRow}
            playHomeTeamRow={handlePlayHomeTeamRow}
            playOpponentTeamRow={handlePlayOpponentTeamRow}
          />
        ) : null}
      </>
    );
  },
);

TimelineTableWrapper.displayName = 'TimelineTableWrapper';
