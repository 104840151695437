import { Trans } from 'react-i18next';

import EmptyCellGrid from 'pages/recording-playlists/components/empty-cell-grid';
import NotFoundImg from 'shared/assets/cameras.svg';
import styles from 'shared/components/not-found/NotFound.module.scss';

type NotFoundProps = {
  header: string;
  description?: string;
  showGridBackground?: boolean;
  showContactLink?: boolean;
};

const NotFound = ({
  header,
  description,
  showGridBackground = false,
  showContactLink = false,
}: NotFoundProps): JSX.Element => {
  const contentStyle = showGridBackground ? styles.contentWithBackground : styles.content;

  return (
    <div className={styles.container}>
      {showGridBackground && <EmptyCellGrid cells={12} />}
      <div className={contentStyle}>
        <div>
          <img src={NotFoundImg} alt='' />
          <h5 className={styles.title}>{header}</h5>
          {description && <p className='text-medium'>{description}</p>}
          {showContactLink && (
            <Trans
              i18nKey='common:try-again-contact'
              components={{ mailto: <a href='mailto:support@kogniasports.com' /> }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default NotFound;
