import { Stack, Typography } from '@mui/material';
import { PlayerAvatar } from 'kognia-ui/components/avatar/PlayerAvatar';

interface Props {
  name: string;
  photoUrl: string | null;
}

export const NameRow = ({ name, photoUrl }: Props) => {
  return (
    <Stack p={({ spacing }) => spacing(0.8, 3)} width='100%' direction='row' alignItems='center' gap={1}>
      <PlayerAvatar src={photoUrl} size='xsmall' withBorder />

      <Typography variant='body2' component='span'>
        {name}
      </Typography>
    </Stack>
  );
};
