import { zodResolver } from '@hookform/resolvers/zod';
import { AccordionActions, AccordionDetails } from '@mui/material';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { useCreateTask } from 'pages/backoffice/api/operations/use-create-task';
import { AnnotationFormActions } from 'pages/backoffice/pages/annotation/components/annotation-form-actions';
import { AnnotationToolField } from 'pages/backoffice/pages/annotation/components/form-fields/annotation-tool-field';
import { IndexTypeGroup } from 'pages/backoffice/pages/annotation/components/form-fields/index-type-group';
import { LoadAnnotationsField } from 'pages/backoffice/pages/annotation/components/form-fields/load-annotations-field';
import { PipelineTypeField } from 'pages/backoffice/pages/annotation/components/form-fields/pipeline-type-field';
import { RecordingIdField } from 'pages/backoffice/pages/annotation/components/form-fields/recording-id-field';
import { TaskTypeField } from 'pages/backoffice/pages/annotation/components/form-fields/task-type-field';
import { createTaskFormSchema } from 'pages/backoffice/pages/annotation/utils/form-fields-validation';
import {
  CreateTaskForm,
  CreateTaskFormSchema,
} from 'pages/backoffice/pages/annotation/utils/form-fields-validation/types';

export const CreateTask = () => {
  const { createTask } = useCreateTask();

  const {
    register,
    formState: { errors },
    reset,
    resetField,
    handleSubmit: handleFormSubmit,
  } = useForm<CreateTaskFormSchema>({
    resolver: zodResolver(createTaskFormSchema),
  });

  const handleCreateTask = useCallback(
    (data: CreateTaskForm) => {
      createTask({ data });
    },
    [createTask],
  );

  return (
    <form onSubmit={handleFormSubmit(handleCreateTask)}>
      <AccordionDetails>
        <RecordingIdField errors={errors} register={register} />
        <IndexTypeGroup errors={errors} register={register} resetField={resetField} />
        <TaskTypeField errors={errors} register={register} />
        <LoadAnnotationsField register={register} />
        <PipelineTypeField errors={errors} register={register} />
        <AnnotationToolField errors={errors} register={register} />
      </AccordionDetails>
      <AccordionActions>
        <AnnotationFormActions reset={() => reset()} />
      </AccordionActions>
    </form>
  );
};
