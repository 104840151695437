import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface FilterDropdownControlsProps {
  isResetDisabled?: boolean;
  onClickReset?: () => void;
  onClickApply: () => void;
  showApplyButton?: boolean;
  showResetButton?: boolean;
}

const FilterControls = (props: FilterDropdownControlsProps) => {
  const { t } = useTranslation();
  const { isResetDisabled = false, onClickReset, onClickApply, showApplyButton = true, showResetButton = true } = props;

  const handleResetClick = () => {
    onClickReset && onClickReset();
  };

  return (
    <Stack direction='row' justifyContent={showApplyButton && showResetButton ? 'space-between' : 'flex-end'}>
      {showResetButton && (
        <Button color='secondary' disabled={isResetDisabled} onClick={handleResetClick}>
          {t('common:actions.reset-filter')}
        </Button>
      )}
      {showApplyButton && <Button onClick={onClickApply}>{t('common:actions.apply')}</Button>}
    </Stack>
  );
};

export default FilterControls;
