import { TypeOf } from 'zod';

import { seasonCompetitionFormSchema } from 'pages/backoffice/pages/season-competitions/components/season-competition-form/form';

export enum SeasonCompetitionFormFieldsNames {
  id = 'id',
  year = 'year',
  crossesYear = 'crossesYear',
  competitionId = 'competitionId',
}

export type SeasonFormSchema = TypeOf<typeof seasonCompetitionFormSchema>;
