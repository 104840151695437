import { PlayingMode, PlayingModes, PlaylistItemType } from 'shared/components/video-player/types';
import { getVideoByVideoType } from 'shared/components/video-player/util';

const getVideoSourceIndexAndTimeFromPlaylistTime = (
  playingMode: PlayingMode,
  playlistItem: PlaylistItemType | undefined,
  time: number,
) => {
  if (!playlistItem) throw Error('Playlist is mandatory');

  const { videoSources } = getVideoByVideoType(playlistItem, playingMode);

  const result = videoSources.reduce(
    (result, videoSource, currentVideoIndex) => {
      const timeInVideoSource = videoSource.startTime + (time - result.startTime);

      const foundVideoIndex =
        timeInVideoSource <= videoSource.endTime && timeInVideoSource >= videoSource.startTime
          ? currentVideoIndex
          : result.videoIndex;

      const accumulatedStartTime = videoSource.endTime - videoSource.startTime + result.startTime;

      return {
        startTime: accumulatedStartTime,
        videoIndex: foundVideoIndex,
        timeInVideoSource: foundVideoIndex !== result.videoIndex ? timeInVideoSource : result.timeInVideoSource,
      };
    },
    { startTime: 0, videoIndex: -1, timeInVideoSource: 0 },
  );

  return { videoSourceIndex: result.videoIndex, currentSourceTime: result.timeInVideoSource };
};

export const getVideoSourceIndexAndTimeFromMatchTime = (
  playingMode: PlayingMode,
  playlistItem: PlaylistItemType,
  timeInMatch: number,
): { currentSourceTime: number; videoSourceIndex: number } => {
  const { videoSources } = getVideoByVideoType(playlistItem, playingMode);
  const isPlayingSelectionNotEffectiveTime =
    playingMode.mode === 'TACTICAL_CAMERA' && !playingMode.useEffectiveTime && videoSources.length > 1;

  for (let x = 0; x <= videoSources.length; x++) {
    const videoSource = videoSources[x];

    if (playingMode.mode !== PlayingModes.EPISODES) {
      if (timeInMatch >= videoSource.startTime && timeInMatch <= videoSource.endTime) {
        return { videoSourceIndex: x, currentSourceTime: timeInMatch };
      }

      if (!playingMode.useEffectiveTime && !isPlayingSelectionNotEffectiveTime) {
        return { videoSourceIndex: x, currentSourceTime: timeInMatch };
      }

      if (timeInMatch > videoSource.endTime && videoSources[x + 1] === undefined) {
        return { videoSourceIndex: x, currentSourceTime: videoSources[x].startTime };
      }

      if (timeInMatch > videoSource.endTime && timeInMatch < videoSources[x + 1].startTime) {
        return { videoSourceIndex: x + 1, currentSourceTime: videoSources[x + 1].startTime };
      }

      if (timeInMatch < videoSource.startTime) {
        return { videoSourceIndex: x, currentSourceTime: videoSource.startTime };
      }

      continue;
    }

    if (videoSource.startTimeInMatch === undefined || videoSource.endTimeInMatch === undefined) {
      throw Error('Action not valid in context without a game');
    }

    const diff = timeInMatch - videoSource.startTimeInMatch;

    if (
      timeInMatch >= videoSource.startTimeInMatch &&
      timeInMatch <= videoSource.endTimeInMatch &&
      diff >= videoSource.startTime &&
      diff <= videoSource.endTime
    ) {
      return { videoSourceIndex: x, currentSourceTime: diff };
    }

    if (
      timeInMatch >= videoSource.startTimeInMatch &&
      timeInMatch <= videoSource.endTimeInMatch &&
      diff < videoSource.startTime
    ) {
      return { videoSourceIndex: x, currentSourceTime: videoSource.startTime };
    }

    if (timeInMatch < videoSource.startTimeInMatch) {
      return { videoSourceIndex: x, currentSourceTime: videoSource.startTime };
    }
  }

  return { videoSourceIndex: 0, currentSourceTime: 0 };
};

export const getVideoSourceIndexAndTimeFromPercentTime = (
  playingMode: PlayingMode,
  playlistItem: PlaylistItemType,
  timeInPercentage: number,
): { currentSourceTime: number; videoSourceIndex: number } => {
  const secondInPercent = (timeInPercentage * playlistItem.duration) / 100;

  const isFullMatchNotEffectiveTime = playingMode.mode === 'TACTICAL_CAMERA' && !playingMode.useEffectiveTime;

  return isFullMatchNotEffectiveTime
    ? getVideoSourceIndexAndTimeFromMatchTime(playingMode, playlistItem, secondInPercent)
    : getVideoSourceIndexAndTimeFromPlaylistTime(playingMode, playlistItem, secondInPercent);
};
