import { useTacticalAnalysisData } from 'pages/tactical-analysis/api/use-tactical-analysis-data';
import { UseTimelineMatchTaggingData } from 'pages/tactical-analysis/api/use-tactical-analysis-data/types';
import { useClientIdValue } from 'shared/contexts/app-state/hooks/useClientIdValue';
import { DataFetching } from 'shared/hooks/use-page-state-machine';

export const useTacticalAnalysisPage = (tacticalAnalysisId: string): DataFetching<UseTimelineMatchTaggingData> => {
  const clientId = useClientIdValue();
  const tacticalAnalysisData = useTacticalAnalysisData(tacticalAnalysisId);

  const isInvalidClient = !tacticalAnalysisData?.data?.matchWithEpisodes?.match?.clientIds.includes(clientId);

  return {
    ...tacticalAnalysisData,
    isInvalidClient,
  };
};
