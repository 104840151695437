import { useAtom, atom } from 'jotai';
import { useCallback } from 'react';

const sidebarOpenState = atom(true);

export const useSidebar = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useAtom(sidebarOpenState);

  const toggleSidebar = useCallback(() => setIsSidebarOpen(!isSidebarOpen), [setIsSidebarOpen, isSidebarOpen]);

  return {
    isSidebarOpen,
    toggleSidebar,
  };
};
