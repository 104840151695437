import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

import { SolutionCardVariants } from 'pages/home/types/solutionCardVariants';

export const SolutionCardWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disabled' && prop !== 'variant',
})<{ disabled?: boolean; variant: SolutionCardVariants }>(({ theme, variant, disabled }) => ({
  flex: 1,
  width: '224px',
  height: '352px',
  borderRadius: '8px',
  borderWidth: '4px',
  borderStyle: 'solid',
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  userSelect: 'none',

  '&:hover': {
    div: {
      opacity: 1,
    },
  },

  '&:active': {
    // TODO use from theme
    borderColor: Colors.ghost,
  },

  a: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    flex: 1,
    height: '100%',
    padding: theme.spacing(3, 2, 2),
    borderRadius: '8px',
    textDecoration: 'none',
    cursor: 'pointer',
    transition: theme.transitions.create('all', {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),

    '&:active, &:hover, &:focus, &:focus-within': {
      zIndex: 'auto',
      color: 'inherit',
      // TODO use from theme
      boxShadow: `0 32px 40px -2px rgba(10, 22, 70, 0.12), 0 0 1px 0 rgba(10, 22, 70, 0.06)`,
    },

    '&:after': {
      content: 'none',
      border: 0,
    },

    '&:active:after': {
      content: 'none',
      border: 'none',
    },

    [theme.breakpoints.up('xl')]: {
      padding: theme.spacing(4, 3, 3),
    },

    h3: {
      textAlign: 'center',
    },
  },

  ...(variant === SolutionCardVariants.TAGGING_TOOL && {
    '& h3': {
      color: theme.palette.common.white,
    },
    // TODO use from theme
    backgroundColor: Colors.brandPastel,
    borderColor: Colors.brandPastel,
  }),

  ...(variant === SolutionCardVariants.LIVE_RECORDINGS && {
    '& h3': {
      color: theme.palette.common.white,
    },
    background: theme.palette.tertiary.main,
    borderColor: theme.palette.tertiary.main,
  }),

  ...(variant === SolutionCardVariants.PLAYLISTS && {
    // TODO use from theme
    backgroundColor: Colors.peach,
    borderColor: Colors.peach,
  }),

  ...(variant === SolutionCardVariants.RECORDINGS && {
    // TODO use from theme
    backgroundColor: Colors.aqua,
    borderColor: Colors.aqua,
  }),

  ...(disabled && {
    background: theme.palette.grey[200],
    borderColor: theme.palette.grey[200],

    pointerEvents: 'none',

    '& h3': {
      color: theme.palette.secondary.light,
    },

    '& a': {
      cursor: 'default',

      '&:hover, &:focus, &:focus-within': {
        boxShadow: 'none',
      },
    },
  }),
}));
