import { zodResolver } from '@hookform/resolvers/zod';
import { IconUser } from 'kognia-ui/icons/IconUser';
import { useCallback, useRef } from 'react';
import { useForm } from 'react-hook-form';

import { useCreateSeasonClients } from 'pages/backoffice/api/season-clients/use-create-season-clients';
import { useUpdateSeasonClients } from 'pages/backoffice/api/season-clients/use-update-season-clients';
import { SeasonClientsFormComponent } from 'pages/backoffice/pages/associate-season-with-clients/components/season-clients-form';
import {
  seasonClientsFormSchema,
  seasonClientsUpdateFormSchema,
} from 'pages/backoffice/pages/associate-season-with-clients/components/season-clients-form/form';
import {
  SeasonClientsForm,
  SeasonClientsFormFieldsNames,
  SeasonClientsFormSchema,
} from 'pages/backoffice/pages/associate-season-with-clients/components/season-clients-form/types';
import { DialogNew } from 'shared/components/dialog-new';

interface Props {
  open: boolean;
  onClose: () => void;
  defaultValues?: SeasonClientsForm;
}

const seasonClientsFormId = 'competition-clients-form';
const EMPTY_VALUES = {
  [SeasonClientsFormFieldsNames.id]: '',
  [SeasonClientsFormFieldsNames.originalClient]: undefined,
  [SeasonClientsFormFieldsNames.season]: undefined,
  [SeasonClientsFormFieldsNames.clientIds]: [],
};

export const SeasonClientsModal = ({ open, onClose, defaultValues }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);
  const { handleSubmit, reset, control } = useForm<SeasonClientsFormSchema>({
    resolver: zodResolver(defaultValues ? seasonClientsUpdateFormSchema : seasonClientsFormSchema),
    defaultValues: defaultValues || EMPTY_VALUES,
  });

  const { create } = useCreateSeasonClients();
  const { update } = useUpdateSeasonClients();

  const handleClose = useCallback(() => {
    onClose();
    reset();
  }, [onClose, reset]);

  const handleCreate = useCallback(
    (data: SeasonClientsForm) => {
      const { originalClient, season, clientIds } = data;
      if (!season) return;

      create({
        data: {
          originalClientId: originalClient.id,
          seasonId: season.id,
          clientIds,
        },
        onSuccess: () => {
          handleClose();
        },
      });
    },
    [create, handleClose],
  );

  const handleUpdate = useCallback(
    (data: SeasonClientsForm) => {
      if (!data?.id) return;
      const { id, clientIds } = data;
      update({
        data: { client_ids: clientIds },
        id,
        onSuccess: () => {
          handleClose();
        },
      });
    },
    [handleClose, update],
  );

  const handleCreateOrUpdate = useCallback(
    (data: SeasonClientsForm) => {
      if (defaultValues) {
        handleUpdate(data);
      } else {
        handleCreate(data);
      }
    },
    [defaultValues, handleCreate, handleUpdate],
  );

  return (
    <DialogNew
      maxWidth='sm'
      fullWidth
      title={defaultValues ? 'Edit association' : 'New association'}
      icon={<IconUser size='small' />}
      onCancel={handleClose}
      onClose={handleClose}
      buttonSubmitText={defaultValues ? 'Save' : 'Create'}
      open={open}
      buttonFormId={seasonClientsFormId}
    >
      <form id={seasonClientsFormId} ref={formRef} onSubmit={handleSubmit(handleCreateOrUpdate)}>
        <SeasonClientsFormComponent<SeasonClientsFormSchema>
          control={control}
          isEdit={!!defaultValues}
          seasonName={defaultValues?.seasonName}
        />
      </form>
    </DialogNew>
  );
};
