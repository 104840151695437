import { useAtomValue, useSetAtom } from 'jotai';

import { bulkMode } from 'entities/playlist/store/UserPlaylist.state';

export const useIsBulkModeActive = (id: string) => {
  return useAtomValue(bulkMode(id));
};

export const useSetIsBulkModeActive = (id: string) => {
  return useSetAtom(bulkMode(id));
};
