import { useCallback, useMemo } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { useSaveUserPreset } from 'api/user-presets/use-save-user-preset';
import { timelineMatchAtoms } from 'pages/tactical-analysis/atoms';
import { TimelineAppliedFiltersPreset } from 'shared/constants/user-presets/userPresetsClasses';
import { RecordingsFilters } from 'shared/types/recording/types';

export const useTacticalAnalysisAppliedFilters = (recordingId: string) => {
  const appliedFilters = useRecoilValue(timelineMatchAtoms.appliedFilters(recordingId));

  return useMemo(
    () => ({
      appliedFilters,
      areFiltersApplied:
        appliedFilters.eventsEnding || appliedFilters.eventsStarting || appliedFilters.scenariosOrTacticsInside,
    }),
    [appliedFilters],
  );
};

export const useSetTacticalAnalysisAppliedFilters = (recordingId: string) => {
  const { savePreset } = useSaveUserPreset();
  const setAppliedFilters = useSetRecoilState(timelineMatchAtoms.appliedFilters(recordingId));

  return useCallback(
    (appliedFilters: RecordingsFilters, updatePreset = true) => {
      updatePreset && savePreset({ data: new TimelineAppliedFiltersPreset(appliedFilters, recordingId) });
      setAppliedFilters(appliedFilters);
    },
    [recordingId, savePreset, setAppliedFilters],
  );
};
