import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { ClipTimeline } from 'pages/tactical-analysis/components/timeline/timeline-table/components/clip-timeline';

export const ClipsList = ({ recordingId, clips }: { recordingId: string; clips: Clip[] }) => {
  return (
    <>
      {clips.map((clip) => (
        <ClipTimeline recordingId={recordingId} key={clip.id} clip={clip} />
      ))}
    </>
  );
};
