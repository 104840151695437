import { Box, styled } from '@mui/material';

export const PlayerPhotoPreviewContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  marginTop: theme.spacing(2),
}));

export const PlayerPhotoContainer = styled(Box)({
  width: '120px',
  height: '120px',
});
