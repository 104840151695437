import Konva from 'konva';
import { IFrame } from 'konva/lib/types';
import { useCallback, useEffect, useMemo } from 'react';
import { create, StoreApi, UseBoundStore } from 'zustand';
import { OverlayGenerator, Quality } from '../main';
import { OverlayGeneratorStore } from '../store';
import { OverlayElementsMetaData, RecordingOverlayData } from '../utils/loaders';

interface UseOverlayGeneratorParams {
  id?: string;
  recordingData?: RecordingOverlayData;
  overlayElementsMetaData?: OverlayElementsMetaData;
  containerRef?: React.RefObject<HTMLDivElement>;
}

const cache: {
  [key in string]: {
    overlayGenerator: OverlayGenerator;
    useStore: UseBoundStore<StoreApi<OverlayGeneratorStore>>;
  };
} = {};

export const useOverlayGeneratorById = (
  id: string,
): [OverlayGenerator, UseBoundStore<StoreApi<OverlayGeneratorStore>>] => {
  return useMemo(() => {
    if (!cache[id]) {
      const generator = new OverlayGenerator({
        imageInterface: Image,
        fetchInterface: fetch,
      });
      const useStore = create(generator.store.store);

      cache[id] = { overlayGenerator: generator, useStore };
      return [generator, useStore];
    }

    return [cache[id].overlayGenerator, cache[id].useStore];
  }, [id]);
};

export const useOverlayGenerator = ({
  id = 'default-overlay-generator',
  recordingData,
  overlayElementsMetaData,
}: UseOverlayGeneratorParams = {}) => {
  const [overlayGenerator, useStore] = useOverlayGeneratorById(id);

  useEffect(() => {
    recordingData && overlayGenerator.setRecordingData(recordingData);
    overlayElementsMetaData && overlayGenerator.setOverlayElementsMetaData(overlayElementsMetaData);

    return () => {
      overlayGenerator.reset();
    };
  }, [overlayGenerator, recordingData, overlayElementsMetaData]);

  const frameRate = useStore((state) => state.metaData.video.frameRate);
  const isReady = useStore((state) => !state.status.isLoadingData);
  const frameInfo = useStore((state) => state.frameInfo);

  const createAnimation = useCallback(
    (callback: (frame?: IFrame) => void) => {
      return new Konva.Animation((frame) => {
        return callback(frame);
      }, overlayGenerator.overlayRendered.stage);
    },
    [overlayGenerator.overlayRendered.stage],
  );

  const changeQuality = useCallback(
    (quality: Quality) => {
      if (!quality) return;

      overlayGenerator.setQuality(quality);
    },
    [overlayGenerator],
  );

  return {
    overlayGenerator,
    isReady,
    frameRate,
    frameInfo,
    changeQuality,
    createAnimation,
  };
};
