import classNames from 'classnames';

import Spinner from 'shared/components/spinner';
import { useIsBuffering, useIsSeeking } from 'shared/components/video-player/hooks';
import styles from 'shared/components/video-player/video-player-component/components/video-spinner/VideoSpinner.module.scss';

const VideoSpinner = () => {
  const isSeeking = useIsSeeking();
  const isBuffering = useIsBuffering();

  const isLoading = isSeeking || isBuffering;

  return (
    <div className={classNames(styles.spinner, { [styles.isLoading]: isLoading })}>
      <Spinner size='xlarge' />
    </div>
  );
};

export default VideoSpinner;
