import { Box, styled } from '@mui/material';

import {
  CSS_CLIP_BORDER_COLOR,
  CSS_EPISODE_CLIP_COLOR,
  CSS_EPISODE_CLIP_COLOR_HOVER,
} from 'pages/tactical-analysis/components/timeline/timeline-table/components/timeline-css-variables';

export const EpisodeClip = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isSelectedForPlaying' && prop !== 'isSelectionMode',
})<{ isSelected: boolean; isSelectionMode: boolean; isSelectedForPlaying: boolean }>(
  ({ theme, isSelected, isSelectionMode, isSelectedForPlaying }) => ({
    position: 'relative',
    top: 0,
    zIndex: 0,
    width: '100%',
    height: '100%',
    padding: theme.spacing(0.25, 0),
    color: theme.palette.common.white,
    background: `var(${CSS_EPISODE_CLIP_COLOR})`,
    borderRadius: '2px',
    border: `2px solid var(${CSS_EPISODE_CLIP_COLOR})`,
    transition: theme.transitions.create(['opacity', 'background-color', 'border-color'], {
      easing: theme.transitions.easing.easeOut,
      duration: '0.1s',
    }),
    cursor: 'pointer',

    '&:hover': {
      borderColor: `var(${CSS_EPISODE_CLIP_COLOR_HOVER})`,
      background: `var(${CSS_EPISODE_CLIP_COLOR_HOVER})`,

      ...(isSelected && {
        borderColor: `var(${CSS_CLIP_BORDER_COLOR})`,
      }),
    },

    ...(isSelected && {
      zIndex: 1,
      borderColor: `var(${CSS_CLIP_BORDER_COLOR})`,
      background: `var(${CSS_EPISODE_CLIP_COLOR_HOVER})`,
    }),

    ...(isSelectionMode && {
      opacity: 0.2,
      pointerEvents: 'none',
      cursor: 'default',
    }),

    ...(isSelectedForPlaying && {
      opacity: 1,
      pointerEvents: 'auto',
      cursor: 'pointer',
    }),
  }),
);
