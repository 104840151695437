import { useCallback } from 'react';
import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  useCleanSelection,
  useClipsIdsSelection,
  useGetClipsList,
  useRowClips,
  useSelectPlayingSelectionClipsIds,
  useSelectRow,
  useUnselectPlayingSelectionClips,
  useUnselectRows,
} from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/atoms';
import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { useGenerateTimelinePlaylist } from 'pages/tactical-analysis/hooks/use-generate-timeline-playlist';
import {
  useSetTacticalAnalysisMode,
  useTacticalAnalysisMode,
} from 'pages/tactical-analysis/hooks/use-tactical-analysis-mode';
import { useTeamUtils } from 'pages/tactical-analysis/hooks/use-timeline-team-id-focus';
import { TacticalAnalysisPlayingMode } from 'pages/tactical-analysis/types/tactical-analysis-playing-mode';
import { createVideoSourcesFromClips } from 'pages/tactical-analysis/utils/create-video-sources-from-clips';
import { usePlaylistItems, useVideoPlayerActions, useVideoPlayerPlayingMode } from 'shared/components/video-player';
import { PlaylistItemType } from 'shared/components/video-player/types';

interface PlaySelection {
  rowId?: string;
  team?: 'home' | 'opponent';
}

const selectedPlayingRow = atomFamily<{ rowId: string; team?: 'home' | 'opponent' } | undefined, string>({
  key: 'selected-playing-row',
  default: undefined,
});

export const useSelectedPlayingRow = (recordingId: string) => {
  return useRecoilValue(selectedPlayingRow(recordingId));
};

export const useSetSelectedPlayingRow = (recordingId: string) => {
  return useSetRecoilState(selectedPlayingRow(recordingId));
};

export const useSelectionPlaying = (recordingId: string) => {
  const getRowClips = useRowClips();
  const getClipsList = useGetClipsList();
  const actions = useVideoPlayerActions();
  const playlistItems = usePlaylistItems();
  const cleanSelection = useCleanSelection();
  const selectRow = useSelectRow();
  const clipIdsSelection = useClipsIdsSelection();
  const playingMode = useVideoPlayerPlayingMode();
  const { isHomeTeam } = useTeamUtils(recordingId);
  const selectClipsForPlaying = useSelectPlayingSelectionClipsIds();
  const unselectRows = useUnselectRows();
  const unselectPlayingSelectionClips = useUnselectPlayingSelectionClips();
  const tacticalAnalysisMode = useTacticalAnalysisMode(recordingId);
  const setTacticalAnalysisMode = useSetTacticalAnalysisMode(recordingId);
  const generateTimelinePlaylist = useGenerateTimelinePlaylist(recordingId);
  const setSelectedPlayingRow = useSetSelectedPlayingRow(recordingId);

  const createPlaylistItemsFromClips = useCallback(
    (clipsList: Clip[]): PlaylistItemType[] => {
      const { videoSources, duration } = createVideoSourcesFromClips(
        clipsList,
        generateTimelinePlaylist(playingMode)[0].videoTypes[0].videoSources,
        playingMode,
      );

      return [
        {
          ...playlistItems[0],
          id: 'timeline-playlist-item-selection',
          duration,
          videoTypes: [
            {
              ...playlistItems[0].videoTypes[0],
              videoSources,
            },
          ],
        },
      ];
    },
    [generateTimelinePlaylist, playingMode, playlistItems],
  );

  const playAll = useCallback(() => {
    unselectPlayingSelectionClips();
    unselectRows();
    setTacticalAnalysisMode(TacticalAnalysisPlayingMode.default);
    actions.setPlaylist(generateTimelinePlaylist(playingMode), playingMode, true, true);
  }, [
    unselectPlayingSelectionClips,
    actions,
    playingMode,
    unselectRows,
    setTacticalAnalysisMode,
    generateTimelinePlaylist,
  ]);

  const playSelection = useCallback(
    ({ rowId, team }: PlaySelection = {}) => {
      const getClips = ({ rowId, team }: PlaySelection) => {
        if (!rowId) return [];

        setSelectedPlayingRow({ rowId, team });

        if (team === 'home') {
          return getRowClips(rowId).filter((clip) => clip.teamId && isHomeTeam(clip.teamId));
        }

        if (team === 'opponent') {
          return getRowClips(rowId).filter((clip) => clip.teamId && !isHomeTeam(clip.teamId));
        }

        return getRowClips(rowId);
      };

      const clipsList = rowId ? getClips({ rowId, team }) : getClipsList(clipIdsSelection);

      const newPlaylistItems: PlaylistItemType[] = createPlaylistItemsFromClips(clipsList);

      if (tacticalAnalysisMode === TacticalAnalysisPlayingMode.selection) {
        unselectPlayingSelectionClips(newPlaylistItems[0].videoTypes[0].videoSources.map(({ id }) => id));
      }

      const clipsToSelect = clipsList
        .map((clip) => {
          if (clip?.parentClipId) {
            return [clip.id, clip.parentClipId];
          }

          return clip.id;
        })
        .flat();
      const clipsToSelectUnique = Array.from(new Set(clipsToSelect));
      selectClipsForPlaying(clipsToSelectUnique);

      if (rowId) {
        cleanSelection();
        selectRow(rowId, team);
      } else {
        unselectRows();
        selectRow();
      }

      setTacticalAnalysisMode(TacticalAnalysisPlayingMode.selection);
      actions.setPlaylist(newPlaylistItems, playingMode, true);
      actions.play();
    },
    [
      getClipsList,
      clipIdsSelection,
      createPlaylistItemsFromClips,
      tacticalAnalysisMode,
      selectClipsForPlaying,
      setTacticalAnalysisMode,
      actions,
      playingMode,
      setSelectedPlayingRow,
      getRowClips,
      isHomeTeam,
      unselectPlayingSelectionClips,
      cleanSelection,
      selectRow,
      unselectRows,
    ],
  );

  return {
    playSelection,
    playAll,
  };
};
