import { FormControl, Input, TextField, Typography } from '@mui/material';
import { Colors } from 'kognia-ui';
import React from 'react';
import { FieldErrors, Path, UseFormRegister, UseFormTrigger } from 'react-hook-form';

import { PlayerFormFieldsNames } from 'pages/backoffice/pages/players/components/player-form/types';
import { FormFormLabel } from 'shared/components/form/form-form-label';

interface Props<TFormValues extends Record<string, any>> {
  register: UseFormRegister<TFormValues>;
  errors: FieldErrors<TFormValues>;
  trigger: UseFormTrigger<TFormValues>;
}

export function PlayerForm<TFormValues extends Record<string, any>>({ register, errors, trigger }: Props<TFormValues>) {
  return (
    <>
      <FormControl fullWidth margin='normal'>
        <FormFormLabel>Player Name</FormFormLabel>
        <TextField
          size='small'
          {...register(PlayerFormFieldsNames.name as Path<TFormValues>)}
          error={!!errors[PlayerFormFieldsNames.name]}
          helperText={errors[PlayerFormFieldsNames.name] && <>{errors[PlayerFormFieldsNames.name].message}</>}
        />
      </FormControl>
      <FormControl
        fullWidth
        margin='normal'
        onChange={() => trigger(PlayerFormFieldsNames.playerImage as Path<TFormValues>)}
      >
        <FormFormLabel>Image URL</FormFormLabel>
        <Input
          type='file'
          size='small'
          {...register(PlayerFormFieldsNames.playerImage as Path<TFormValues>)}
          error={!!errors[PlayerFormFieldsNames.playerImage]}
        />
        {errors[PlayerFormFieldsNames.playerImage] && (
          <Typography color={Colors.red}>
            <>{errors[PlayerFormFieldsNames.playerImage].message ?? ''}</>
          </Typography>
        )}
      </FormControl>
    </>
  );
}
