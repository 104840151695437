import { useQueryStringState } from 'shared/hooks/use-query-string-state';

export enum LiveSessionsFilterNames {
  from = 'from',
  to = 'to',
}

export interface LiveSessionsFilters {
  [LiveSessionsFilterNames.from]: string | null;
  [LiveSessionsFilterNames.to]: string | null;
}

const ALLOWED_LIVE_SESSIONS_QUERY_PARAMS = Object.keys(LiveSessionsFilterNames);

export const useLiveSessionsFilters = () => {
  const { value, updateValues } = useQueryStringState(ALLOWED_LIVE_SESSIONS_QUERY_PARAMS);

  const filters: LiveSessionsFilters = {
    [LiveSessionsFilterNames.from]: (value[LiveSessionsFilterNames.from] as string) || null,
    [LiveSessionsFilterNames.to]: (value[LiveSessionsFilterNames.to] as string) || null,
  };

  return { filters, updateFilters: updateValues };
};
