import { clientsQueryKey } from 'api/backoffice/client/hooks/use-clients/useClients';
import { USERS_QUERY_KEY } from 'api/backoffice/user/use-kognia-users';
import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { queryClient } from 'api/config';
import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { userBatchClientIdsUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';

export const useAddUsersToEnvironments = () => {
  const { mutate, isPending, isError, isSuccess } = useMutationRequest({
    type: HTTPMethod.PATCH,
    errorMessage: 'Error adding user(s) to environment(s)',
    successMessage: 'User(s) added to environment(s)',
  });

  const addUsersToEnvironments = ({
    kogniaUsers,
    clientIds,
    onSuccess,
  }: {
    kogniaUsers: KogniaUser[];
    clientIds: string[];
    onSuccess?: () => void;
  }) => {
    mutate(
      {
        url: userBatchClientIdsUrl,
        data: { clientIds, userIds: kogniaUsers.map((user) => user.id) },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === clientsQueryKey || query.queryKey[0] === USERS_QUERY_KEY,
          });
          onSuccess && onSuccess();
        },
      },
    );
  };

  return { addUsersToEnvironments, isPending, isError, isSuccess };
};
