import { Accordion, AccordionSummary } from '@mui/material';
import { IconChevronDown } from 'kognia-ui/icons/IconChevronDown';

import { BackofficeContentContainer } from 'pages/backoffice/components/backoffice-content-container';
import { AnnotationsTask } from 'pages/backoffice/pages/annotation/components/annotations-task';
import { CreateTask } from 'pages/backoffice/pages/annotation/components/create-task';
import { DeleteEpisodeOverlays } from 'pages/backoffice/pages/annotation/components/delete-episode-overlays';
import { DeleteTask } from 'pages/backoffice/pages/annotation/components/delete-task';
import { LabelsTask } from 'pages/backoffice/pages/annotation/components/labels-task';
import { ProcessEpisode } from 'pages/backoffice/pages/annotation/components/process-episode';
import { ReprocessSync } from 'pages/backoffice/pages/annotation/components/reprocess-sync';
import { ReprocessThirdPartyEvents } from 'pages/backoffice/pages/annotation/components/reprocess-third-party-events';
import { RerunEventsPreprocess } from 'pages/backoffice/pages/annotation/components/rerun-events-preprocess';
import { ValidateTask } from 'pages/backoffice/pages/annotation/components/validate-task';

export const Annotation = () => {
  return (
    <BackofficeContentContainer>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size='small' />}>Create task</AccordionSummary>
        <CreateTask />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size='small' />}>Delete task</AccordionSummary>
        <DeleteTask />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size='small' />}>Validate Recording</AccordionSummary>
        <ValidateTask />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size='small' />}>Load annotations</AccordionSummary>
        <AnnotationsTask />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size='small' />}>Update Labels</AccordionSummary>
        <LabelsTask />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size='small' />}>Process Episode</AccordionSummary>
        <ProcessEpisode />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size='small' />}>Delete Episode Overlays</AccordionSummary>
        <DeleteEpisodeOverlays />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size='small' />}>
          Reprocess Third Party Tracking Sync
        </AccordionSummary>
        <ReprocessSync />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size='small' />}>Reprocess Third Party Events</AccordionSummary>
        <ReprocessThirdPartyEvents />
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<IconChevronDown size='small' />}>
          Rerun Events Preprocess (Data + Mapping)
        </AccordionSummary>
        <RerunEventsPreprocess />
      </Accordion>
    </BackofficeContentContainer>
  );
};
