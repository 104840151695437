import { Avatar, styled } from '@mui/material';
import { ImageSizes } from '../../shared/types/images';
import { IMAGE_BORDER_SIZES, IMAGE_SIZES } from '../../shared/constants/images';

type Props = {
  size: ImageSizes;
  withBorder?: boolean;
  withBackground?: boolean;
};

export const AvatarBase = styled(Avatar, {
  shouldForwardProp: (prop) => prop !== 'size' && prop !== 'withBorder' && prop !== 'withBackground',
})<Props>(({ theme, size, withBorder = false, withBackground = false }) => ({
  width: IMAGE_SIZES[size],
  height: IMAGE_SIZES[size],
  minWidth: IMAGE_SIZES[size],
  minHeight: IMAGE_SIZES[size],
  img: {
    maxWidth: '100%',
    maxHeight: '100%',
    cover: 'contain',
    width: 'auto',
    height: 'auto',
  },
  background: withBackground ? theme.palette.grey[200] : 'transparent',
  border: withBorder ? `${IMAGE_BORDER_SIZES[size]}px solid ${theme.palette.info.main}` : 'none',
}));
