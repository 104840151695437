import { usePlayerContext } from '@vime/react';
import get from 'lodash/get';
import React, { useMemo } from 'react';
import { useMatch } from 'react-router-dom';
import { atomFamily, useRecoilValue } from 'recoil';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { routes } from 'kognia/router/routes';
import { useAreOverlaysReady } from 'shared/components/video-player/hooks/use-overlays-controller';
import { VideoPlayerActions, VideoPlayerStateFixedContext } from 'shared/components/video-player/index';
import { videoPlayerStateAtoms } from 'shared/components/video-player/state/atoms';
import { useGetCurrentTime } from 'shared/components/video-player/state/atoms/hooks';
import { PLAYER_STATES, READY_STATES } from 'shared/components/video-player/state/states';
import { getCurrentPlaylistItemTime } from 'shared/components/video-player/state/utils/get-current-playlist-item-time';
import { PlayingMode, PlayingModes, VideoSourceType, VideoSourceWithTimes } from 'shared/components/video-player/types';
import { PlaylistItemType } from 'shared/components/video-player/types';
import {
  checkIsFinishedPlayingCurrentPlaylistItem,
  getVideoByVideoType,
  getVideoSourceByIndex,
} from 'shared/components/video-player/util';
import { useFeatureFlag } from 'shared/contexts/app-state/hooks/useFeatureFlag';

const getVideoPlayerFixedStateContext = (hookName: string) => {
  const context = React.useContext(VideoPlayerStateFixedContext);

  if (context === undefined) {
    throw new Error(`${hookName} must be used within a VideoPlayerStateFixedContext`);
  }

  return context;
};

export const useVideoPlayerRef = () => {
  const context = getVideoPlayerFixedStateContext('useVideoPlayerRef');

  return context.state.videoPlayerRef;
};

export const useVideoPlayerContainerRef = () => {
  const context = getVideoPlayerFixedStateContext('useVideoPlayerContainerRef');

  return context.state.videoPlayerContainerRef;
};

export const useVideoPlayerId = (): string => {
  const context = getVideoPlayerFixedStateContext('useVideoPlayerId');

  return context.state.videoPlayerId;
};

export const useVideoPlayerRefreshData = (): (() => void) | (() => Promise<void>) | undefined => {
  const context = getVideoPlayerFixedStateContext('useVideoPlayerRefreshData');

  return context.state.refreshData;
};

export const useVideoPlayerActions = (): VideoPlayerActions => {
  const context = getVideoPlayerFixedStateContext('useVideoPlayerActions');

  return context.state.actions;
};

export const usePlayerCurrentSource = () => {
  const playerId = useVideoPlayerId();
  const playlist = useRecoilValue(videoPlayerStateAtoms.playlist(playerId));

  return useMemo(
    () =>
      getVideoSourceByIndex(
        playlist.playingItem.playlistItem,
        playlist.currentSelectedPlayingMode,
        playlist.playingItem.videoSourceIndex,
      ),
    [playlist.playingItem.playlistItem, playlist.currentSelectedPlayingMode, playlist.playingItem.videoSourceIndex],
  );
};

export const useCurrentVideoSourceDuration = () => {
  const videoSource = usePlayerCurrentSource();

  const startTime = videoSource?.startTime ?? 0;
  const endTime = videoSource?.endTime ?? 0;

  return endTime - startTime;
};

export const useVideoPlayerPlayingMode = (): PlayingMode => {
  const playerId = useVideoPlayerId();
  const playlist = useRecoilValue(videoPlayerStateAtoms.playlist(playerId));

  return playlist.currentSelectedPlayingMode;
};

export const useCurrentPlaylistItemId = (): string => {
  const playerId = useVideoPlayerId();
  const playlist = useRecoilValue(videoPlayerStateAtoms.playlist(playerId));

  return playlist.currentPlaylistItemId;
};

export const useVideoPlayerPlaylistItem = (id: string): PlaylistItemType => {
  const playerId = useVideoPlayerId();
  const playlist = useRecoilValue(videoPlayerStateAtoms.playlist(playerId));

  const playlistItem = playlist.playlistItems.find((playlistItem) => playlistItem.id === id);

  if (!playlistItem) return playlist.playlistItems[0];

  return playlistItem;
};

export const useCurrentPlaylistItem = (): PlaylistItemType => {
  const playerId = useVideoPlayerId();
  const playlist = useRecoilValue(videoPlayerStateAtoms.playlist(playerId));

  return useMemo(() => {
    const currentPlaylistItem = playlist.playlistItems.find(
      (playlistItem) => playlistItem.id === playlist.currentPlaylistItemId,
    );
    if (!currentPlaylistItem) return playlist.playingItem.playlistItem;
    return currentPlaylistItem;
  }, [playlist.playlistItems, playlist.currentPlaylistItemId, playlist.playingItem.playlistItem]);
};

export const useDuration = () => {
  const playerId = useVideoPlayerId();
  const playlist = useRecoilValue(videoPlayerStateAtoms.playlist(playerId));

  return playlist.playingItem.playlistItem.duration ?? 0;
};

export const useIsSeeking = (): boolean => {
  const context = getVideoPlayerFixedStateContext('useIsSeeking');
  const [seeking] = usePlayerContext(context.state.videoPlayerRef, 'seeking', false);

  return seeking;
};

export const useIsBuffering = () => {
  const showCustomOverlaysFlag = useFeatureFlag(FEATURE_FLAG.CUSTOM_OVERLAYS);
  const matchPath = useMatch(routes.RECORDING_PLAYLIST_DETAIL);
  const areOverlaysReady = useAreOverlaysReady();
  const { showOverlays } = useVideoPlayerPlayingMode();
  const playlistItem = useCurrentPlaylistItem();
  const context = getVideoPlayerFixedStateContext('useIsBuffering');
  const [buffering] = usePlayerContext(context.state.videoPlayerRef, 'buffering', false);

  return matchPath &&
    showOverlays &&
    playlistItem.showOverlays &&
    playlistItem.hasHomographies &&
    showCustomOverlaysFlag
    ? buffering || !areOverlaysReady
    : buffering;
};

export const useVideoPlayerIsPlaying = () => {
  const playerId = useVideoPlayerId();

  return useRecoilValue(videoPlayerStateAtoms.isPlaying(playerId));
};

export const useVideoPlayerIsInStandBy = () => {
  const playerId = useVideoPlayerId();

  return useRecoilValue(videoPlayerStateAtoms.isInStandBy(playerId));
};

export const useVideoPlayerState = () => {
  const playerId = useVideoPlayerId();
  const state = useRecoilValue(videoPlayerStateAtoms.playerStatus(playerId));

  const readyState = get(state, 'READY');

  return {
    state: readyState ? PLAYER_STATES.READY : state,
    isEnded: state === READY_STATES.ENDED,
    readyState: readyState,
    isPlaylistEmpty: state === PLAYER_STATES.EMPTY_PLAYLIST && readyState,
  };
};

export const usePlaylistCurrentPlaylistItemId = (): string => {
  const playerId = useVideoPlayerId();
  const playlist = useRecoilValue(videoPlayerStateAtoms.playlist(playerId));

  return playlist.currentPlaylistItemId;
};

export const useIsCurrentPlaylistItem = (id: string): boolean => {
  const playerId = useVideoPlayerId();
  const playlist = useRecoilValue(videoPlayerStateAtoms.playlist(playerId));

  return playlist.currentPlaylistItemId === id;
};

export const usePlaylistItems = (): PlaylistItemType[] => {
  const playerId = useVideoPlayerId();
  const playlist = useRecoilValue(videoPlayerStateAtoms.playlist(playerId));

  return playlist.playlistItems;
};

export const useCurrentPlaylistItemVideoSources = (): VideoSourceWithTimes[] => {
  const playlistItem = useCurrentPlaylistItem();
  const playingMode = useVideoPlayerPlayingMode();
  const { videoSources } = getVideoByVideoType(playlistItem, playingMode);

  return videoSources;
};

export const useCurrentPlaylistItemVideo = (): ((playingMode: PlayingMode) => VideoSourceType) => {
  const playlistItem = useCurrentPlaylistItem();

  return (playingMode: PlayingMode) => getVideoByVideoType(playlistItem, playingMode);
};

export const useCurrentVideoSourceIndex = (): number => {
  const playerId = useVideoPlayerId();
  const playlist = useRecoilValue(videoPlayerStateAtoms.playlist(playerId));

  return playlist.playingItem.videoSourceIndex;
};

export const useCurrentVideoSource = (): VideoSourceWithTimes => {
  const index = useCurrentVideoSourceIndex();
  const videoSources = useCurrentPlaylistItemVideoSources();

  return videoSources[index];
};

export const useCurrentVideoSourceTime = () => {
  const playerId = useVideoPlayerId();
  return useGetCurrentTime(playerId);
};

const nonReactiveCurrentMatchTimeAtomFamily = atomFamily<{ time: number }, string>({
  key: 'non-reactive-current-match-time',
  default: { time: 0 },
  dangerouslyAllowMutability: true,
});

export const useNonReactiveCurrentTime = () => {
  return useRecoilValue(nonReactiveCurrentMatchTimeAtomFamily(useVideoPlayerId()));
};

const getCurrentTimeByVideoSource = (
  currentTime: number,
  currentVideoSource: VideoSourceWithTimes,
  hasMultipleVideoSources: boolean,
  playingMode: PlayingMode,
): number => {
  if (playingMode.mode !== PlayingModes.EPISODES) return currentTime;
  if (!hasMultipleVideoSources) return currentTime;

  return (currentVideoSource.startTimeInMatch ?? 0) + currentTime;
};

export const useCurrentMatchTime = () => {
  const mutableMatchTime = useNonReactiveCurrentTime();
  const playlistItem = useCurrentPlaylistItem();
  const currentTime = useCurrentVideoSourceTime();
  const videoSourceIndex = useCurrentVideoSourceIndex();
  const playingMode = useVideoPlayerPlayingMode();
  const { videoSources } = getVideoByVideoType(playlistItem, playingMode);

  const time = getCurrentTimeByVideoSource(
    currentTime,
    videoSources[videoSourceIndex],
    videoSources.length > 1,
    playingMode,
  );

  mutableMatchTime.time = time;

  return time;
};

export const useCurrentTime = () => {
  const playlistItem = useCurrentPlaylistItem();
  const currentTime = useCurrentVideoSourceTime();
  const videoSourceIndex = useCurrentVideoSourceIndex();
  const playingMode = useVideoPlayerPlayingMode();

  return getCurrentPlaylistItemTime(playingMode, currentTime, videoSourceIndex, playlistItem);
};

export const useIsFinishedPlayingCurrentPlaylistItem = (playlistItemId: string) => {
  const isCurrentPlaylistItem = useIsCurrentPlaylistItem(playlistItemId);
  const videoPlayerRef = useVideoPlayerRef();
  const playerId = useVideoPlayerId();
  const playlist = useRecoilValue(videoPlayerStateAtoms.playlist(playerId));

  return !isCurrentPlaylistItem ? false : checkIsFinishedPlayingCurrentPlaylistItem(playlist, videoPlayerRef);
};
