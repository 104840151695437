import axios, { AxiosError, AxiosProgressEvent } from 'axios';

import {
  editAllAlignmentsUrl,
  alignAllWithRecordingUrl,
  recordingsBaseUrl,
  specificRecordingUrl,
  initiateMultipartUploadUrl,
  getMultipartUploadUrl,
  completeMultipartUploadUrl,
  abortMultipartUploadUrl,
  liveTaggingSessionsBaseUrl,
  recordingPlaylistsUrl,
} from 'api/routes';
import { fetchRequest, ServiceResponse } from 'tagging-tool/service/base';
import {
  FetchAbortMultipartUpload,
  FetchCompleteMultipartUpload,
  FetchCreateLiveRecordingResponse,
  FetchCreateLiveTaggingRecordingBody,
  FetchCreateUploadRecordingBody,
  FetchCreateUploadRecordingResponse,
  FetchInitiateMultipartUpload,
  FetchMultipartUploadUrl,
  FetchPlaylistsParams,
  FetchPlaylistsResponse,
  FetchUpdateTaggingRecordingAlignmentBody,
  FetchUpdateTaggingRecordingAlignmentResponse,
  FetchUpdateTaggingRecordingAlignmentWithMergeMergeBody,
  FetchUploadMultipartRecordingVideoParams,
  FetchUploadRecordingVideoParams,
  PartETag,
} from 'tagging-tool/service/taggingRecording';
import { normalizeAxiosResponseHeaders } from 'tagging-tool/utility/normalizeAxiosResponseHeaders';

export const fetchCreateLiveTaggingRecording = (body: FetchCreateLiveTaggingRecordingBody) => {
  return fetchRequest<FetchCreateLiveRecordingResponse>({
    url: liveTaggingSessionsBaseUrl,
    body: {
      name: body.name,
      date: body.date,
      startTime: body.startTime,
    },
  });
};

export const fetchUpdateTaggingRecordingAlignment = (body: FetchUpdateTaggingRecordingAlignmentBody) => {
  return fetchRequest<FetchUpdateTaggingRecordingAlignmentResponse>({
    url: editAllAlignmentsUrl(body.recordingId),
    body,
  });
};

export const fetchUpdateTaggingRecordingAlignmentWithMerge = (
  body: FetchUpdateTaggingRecordingAlignmentWithMergeMergeBody,
) => {
  return fetchRequest<FetchUpdateTaggingRecordingAlignmentResponse>({
    url: alignAllWithRecordingUrl,
    body,
  });
};

export const fetchTaggingRecording = (params: { id: string }) => {
  return fetchRequest<void>({
    url: specificRecordingUrl(params.id),
  });
};

export const fetchCreateUploadRecording = (body: FetchCreateUploadRecordingBody) => {
  return fetchRequest<FetchCreateUploadRecordingResponse>({
    url: recordingsBaseUrl,
    body: { ...body, origin: 'upload' },
  });
};

// playlists
export const fetchPlaylists = (params: FetchPlaylistsParams) => {
  return fetchRequest<FetchPlaylistsResponse>({
    url: recordingPlaylistsUrl(params.recordingId),
  });
};

const fetchUploadVideo = (params: FetchUploadRecordingVideoParams) => {
  const { url, blob, onProgress, calculateProgress, abortController } = params;

  return new Promise<ServiceResponse<void>>((resolve) => {
    axios
      .put(url, blob, {
        onUploadProgress: (event: AxiosProgressEvent) => {
          onProgress(calculateProgress(event.loaded));
        },
        signal: abortController.signal,
        withCredentials: false,
      })
      .then(({ data, headers }) => {
        const normalizedHeaders = normalizeAxiosResponseHeaders(headers);

        resolve({ error: false, data, headers: normalizedHeaders });
      })
      .catch((err: AxiosError) => {
        resolve({
          error: true,
          status: err.response?.status ?? 0,
          errors: {},
        });
      });
  });
};

const fetchInitiateMultipartUpload = (params: FetchInitiateMultipartUpload) => {
  return fetchRequest<string>({
    url: initiateMultipartUploadUrl(params.recordingId),
  });
};

const fetchMultipartUploadUrl = (params: FetchMultipartUploadUrl) => {
  const { recordingId, uploadId, partNumber, partLength } = params;
  return fetchRequest<string>({
    url: getMultipartUploadUrl(recordingId, uploadId, partNumber, partLength),
  });
};

const fetchCompleteMultipartUpload = (params: FetchCompleteMultipartUpload) => {
  const { recordingId, ...body } = params;
  return fetchRequest<void>({
    url: completeMultipartUploadUrl(recordingId),
    body,
  });
};

export const fetchAbortMultipartUpload = (params: FetchAbortMultipartUpload) => {
  const { recordingId, uploadId } = params;
  return fetchRequest<void>({
    url: abortMultipartUploadUrl(recordingId, uploadId),
    body: {},
  });
};

export const fetchUploadMultipartVideo = async (params: FetchUploadMultipartRecordingVideoParams) => {
  const { recordingId, file, onInit, onProgress, abortController } = params;
  const initResponse = await fetchInitiateMultipartUpload({ recordingId });

  if (initResponse.error) {
    return initResponse;
  }

  const uploadId = initResponse.data;
  onInit(uploadId);

  const FILE_CHUNK_SIZE = 100 * 1024 * 1024; // 100MB
  const NUM_CHUNKS = Math.floor(file.size / FILE_CHUNK_SIZE) + 1;

  let start: number, end: number, blob: Blob;
  const partETags: PartETag[] = [];

  for (let partNumber = 1; partNumber < NUM_CHUNKS + 1; partNumber++) {
    start = (partNumber - 1) * FILE_CHUNK_SIZE;
    end = partNumber * FILE_CHUNK_SIZE;
    blob = partNumber < NUM_CHUNKS ? file.slice(start, end) : file.slice(start);

    const presignedUrlResponse = await fetchMultipartUploadUrl({
      recordingId,
      uploadId,
      partNumber,
      partLength: blob.size,
    });

    if (presignedUrlResponse.error) {
      return presignedUrlResponse;
    }

    const uploadPartResponse = await fetchUploadVideo({
      url: presignedUrlResponse.data,
      blob,
      onProgress,
      calculateProgress: (loaded: number) => {
        return ((partNumber - 1) * FILE_CHUNK_SIZE + loaded) / file.size;
      },
      abortController,
    });

    if (uploadPartResponse.error) {
      return uploadPartResponse;
    }

    partETags.push({
      eTag: uploadPartResponse.headers['etag'],
      partNumber,
    });
  }

  return fetchCompleteMultipartUpload({ recordingId, uploadId, partETags });
};
