import { Paper, Popper, styled } from '@mui/material';
import { themeZIndexes } from 'kognia-ui';

export const AutocompletePopper = styled(Popper)(() => ({
  zIndex: themeZIndexes.popper,
}));

export const AutocompletePopperContent = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  position: 'relative',
}));
