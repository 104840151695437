import { AutocompleteChangeReason, AutocompleteCloseReason } from '@mui/base';
import {
  Autocomplete as AutocompleteMUI,
  AutocompleteRenderInputParams,
  PopperProps,
  Stack,
  TextField,
} from '@mui/material';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import { AutocompleteGetTagProps, AutocompleteValue } from '@mui/material/useAutocomplete';
import { IconClose } from 'kognia-ui/icons/IconClose';
import { IconSearch } from 'kognia-ui/icons/IconSearch';
import React, { KeyboardEventHandler, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAutocompleteStyles } from 'shared/components/autocomplete/styles';
import Spinner from 'shared/components/spinner';
import { useDebounce } from 'shared/hooks/use-debounce/useDebounce';
import { guid } from 'shared/utils/guid';

export type UpdateAutocompleteValue<T> = (item: T) => void;

interface Props<TItem, Multiple extends boolean | undefined> {
  autoFocus?: boolean;
  PaperComponent?: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>>;
  PopperComponent?: React.JSXElementConstructor<PopperProps>;
  fetchNextPage: () => void;
  getItemLabel: (item: TItem) => string;
  getOptionDisabled?: (item: TItem) => boolean;
  isOptionEqualToValue?: (option: TItem, value: TItem) => boolean;
  inputWidth?: number | string;
  isLoading: boolean;
  className?: string;
  listWidth?: number;
  inputValue?: string;
  useInputChange?: boolean;
  multiple?: Multiple;
  onChange: (searchTerm: string) => void;
  open?: boolean | undefined;
  options: TItem[];
  onClose?: (event: React.SyntheticEvent, reason: AutocompleteCloseReason) => void;
  onSearchTermChange?: (name: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onSubmit?: () => void;
  onKeyUp?: (key: string) => void;
  placeholder?: string;
  autoHighlight?: boolean;
  renderOption: (
    props: React.HTMLAttributes<HTMLLIElement>,
    item: TItem,
    state: AutocompleteRenderOptionState,
  ) => JSX.Element;
  renderTags?: (value: TItem[], getTagProps: AutocompleteGetTagProps) => React.ReactNode;
  resultsHeight?: number | string;
  debounceTime?: number;
  resultsNoMatches?: string | React.ReactNode;
  updateValue: (
    item: AutocompleteValue<TItem, Multiple, undefined, undefined>,
    reason: AutocompleteChangeReason,
  ) => void;
  value?: AutocompleteValue<TItem, Multiple, undefined, undefined> | undefined;
}

const specialKeysCodes = [
  'Alt',
  'ArrowDown',
  'ArrowLeft',
  'ArrowRight',
  'ArrowUp',
  'CapsLock',
  'Control',
  'End',
  'Enter',
  'Escape',
  'Home',
  'Insert',
  'PageDown',
  'PageUp',
  'Pause',
  'PrintScreen',
  'Shift',
  'Tab',
];

const DEFAULT_RESULTS_HEIGHT = 100;
const DEFAULT_INPUT_WIDTH = 400;
const DEFAULT_LIST_WIDTH = 400;
const CONTAINER_PADDING = 4;

export const Autocomplete = <TItem, Multiple extends boolean | undefined>({
  autoFocus = false,
  getItemLabel,
  getOptionDisabled,
  inputWidth = DEFAULT_INPUT_WIDTH,
  listWidth = DEFAULT_LIST_WIDTH,
  multiple,
  className = '',
  placeholder = '',
  renderOption,
  renderTags,
  resultsHeight = DEFAULT_RESULTS_HEIGHT,
  resultsNoMatches,
  updateValue,
  inputValue,
  open = undefined,
  PopperComponent,
  autoHighlight = true,
  PaperComponent,
  isOptionEqualToValue,
  useInputChange,
  options,
  value,
  onBlur,
  onChange,
  onClose,
  onKeyUp,
  onSubmit,
  onSearchTermChange,
  onFocus,
  isLoading,
  fetchNextPage,
}: Props<TItem, Multiple>) => {
  const [autocompleteKey, setAutocompleteKey] = React.useState(guid());
  const autocompleteClasses = useAutocompleteStyles();
  const { t } = useTranslation();

  const loadNextPageOnScrollEnd = useCallback(
    async (event: React.UIEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
      const target = event.currentTarget;
      const isScrollBottom = target.offsetHeight + target.scrollTop + CONTAINER_PADDING >= target.scrollHeight;

      if (isScrollBottom) {
        await fetchNextPage();
      }
    },
    [fetchNextPage],
  );

  const handleOnChange = useDebounce((searchTerm: string) => {
    onChange(searchTerm);
  });

  const handleOnKeyUp: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (specialKeysCodes.includes(event.key)) return;

      onKeyUp && onKeyUp(event.key);
      const target = event.target as HTMLInputElement;
      if (!(target.value.length === 1)) {
        handleOnChange(target.value);
      }
    },
    [onKeyUp, handleOnChange],
  );

  const handleOnKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (event.key !== 'Enter' || !onSubmit) return;

      event.preventDefault();
      onBlur && onBlur();
      onSubmit();
    },
    [onBlur, onSubmit],
  );

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => {
      return (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            autoFocus,
            endAdornment: inputValue ? (
              <IconClose
                onClick={(event: React.MouseEvent<SVGSVGElement>) => {
                  onChange && onChange('');
                  onSearchTermChange && onSearchTermChange('');
                  setAutocompleteKey(guid());
                  event.preventDefault();
                  event.stopPropagation();
                }}
                isButton
                size='small'
                color='secondary'
              />
            ) : (
              <IconSearch size='small' color='secondary' />
            ),
          }}
          placeholder={inputValue ? inputValue : placeholder}
          size='small'
          sx={{ paddingTop: 0.25, paddingBottom: 0.25, marginBottom: 0 }}
          variant='outlined'
          onClick={(event) => event.stopPropagation()}
          onMouseEnter={(event) => event.stopPropagation()}
          onMouseDown={(event) => event.stopPropagation()}
          onFocus={(event) => {
            event.stopPropagation();
            onFocus && onFocus();
          }}
        />
      );
    },
    [autoFocus, onChange, inputValue, onSearchTermChange, onFocus, placeholder],
  );

  const style = useMemo(() => ({ width: inputWidth }), [inputWidth]);

  const listProps = useMemo(
    () => ({
      style: {
        maxHeight: resultsHeight,
        padding: 0,
        maxWidth: listWidth,
      },
      onScroll: loadNextPageOnScrollEnd,
      role: 'list-box',
    }),
    [listWidth, resultsHeight, loadNextPageOnScrollEnd],
  );

  const handleAutocompleteOnChange = useCallback(
    (
      event: React.SyntheticEvent,
      newValue: AutocompleteValue<TItem, Multiple, undefined, undefined>,
      reason: AutocompleteChangeReason,
    ) => {
      event.stopPropagation();
      updateValue(newValue, reason);
    },
    [updateValue],
  );

  const handleInputChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      event.stopPropagation();
      onChange(newValue);
    },
    [onChange],
  );

  return (
    <AutocompleteMUI
      key={autocompleteKey}
      value={value}
      classes={autocompleteClasses}
      disableCloseOnSelect={multiple}
      clearOnBlur={false}
      multiple={multiple}
      autoHighlight={autoHighlight}
      options={options}
      onKeyUp={handleOnKeyUp}
      onKeyDown={handleOnKeyDown}
      isOptionEqualToValue={isOptionEqualToValue}
      filterOptions={(options) => options}
      noOptionsText={resultsNoMatches !== undefined ? resultsNoMatches : t('common:no-results')}
      onInputChange={useInputChange ? handleInputChange : undefined}
      onChange={handleAutocompleteOnChange}
      className={className}
      autoSelect={false}
      renderTags={renderTags}
      ListboxProps={listProps}
      getOptionLabel={getItemLabel}
      getOptionDisabled={getOptionDisabled}
      loading={isLoading}
      onClose={onClose}
      onFocus={onFocus}
      onBlur={onBlur}
      loadingText={
        <Stack flex={1} alignItems={'center'}>
          <Spinner />
        </Stack>
      }
      renderInput={renderInput}
      renderOption={renderOption}
      style={style}
      open={open}
      PopperComponent={PopperComponent}
      PaperComponent={PaperComponent}
    />
  );
};
