import { useQueryClient } from '@tanstack/react-query';

import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { transformMetadataFixturesFromApiResponse } from 'pages/backoffice/api/fixtures/transformers';
import { FixtureFilters } from 'pages/backoffice/api/fixtures/types';
import { MetadataFixturesApiResponse } from 'pages/backoffice/api/fixtures/use-metadata-fixtures/types';
import { getFixturesUrl } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { MetadataFixture } from 'pages/backoffice/types/fixture';
import { SortDirection } from 'shared/types/filters/types';

const QUERY_KEY = 'metadata-fixtures';

export const useMetadataFixtures = (): InfinityQueryResult<MetadataFixture, FixtureFilters> => {
  const { isAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();

  return useInfinityQuery<MetadataFixture, FixtureFilters, MetadataFixturesApiResponse>({
    generateUrl: getFixturesUrl,
    key: QUERY_KEY,
    options: {
      size: 10,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    transformer: transformMetadataFixturesFromApiResponse,
    queryOptions: {
      enabled: isAllowedBackofficeOrigin,
    },
  });
};

export const useInvalidateMetadataFixtures = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
};
