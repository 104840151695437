import { useRecoilValue, useSetRecoilState } from 'recoil';

import { timelineMatchAtoms } from 'pages/tactical-analysis/atoms';

export const useSetIsTimelineReadyToRender = (recordingId: string) => {
  return useSetRecoilState(timelineMatchAtoms.isTimelineReadyToRender(recordingId));
};

export const useIsTimelineReadyToRender = (recordingId: string) => {
  return useRecoilValue(timelineMatchAtoms.isTimelineReadyToRender(recordingId));
};
