import React, { useEffect, useRef } from 'react';

import { PlayHeadShadow } from 'pages/tactical-analysis/components/timeline/play-head/play-head-shadow';
import {
  PlayHeadArrowDown,
  PlayHeadContent,
  PlayHeadHeader,
  PlayHeadTime,
} from 'pages/tactical-analysis/components/timeline/play-head/PlayHead.styled';
import { usePlayHeadPositionInTimeline } from 'pages/tactical-analysis/components/timeline/timeline-table/hooks/use-play-head-position-in-timeline';
import { useTacticalAnalysisEpisodes } from 'pages/tactical-analysis/hooks/use-tactical-analysis-episodes';
import { useTacticalAnalysisMatchSegments } from 'pages/tactical-analysis/hooks/use-tactical-analysis-match-segments';
import { useTimelineCurrentVideoSourceTime } from 'pages/tactical-analysis/hooks/use-tactical-analysis-timeline-time';
import { useTimelineZoomLevel } from 'pages/tactical-analysis/hooks/use-timeline-zoom-level';
import { formattedTime } from 'pages/tactical-analysis/utils/formatted-time';
import { getPlayHeadPositionByTime } from 'pages/tactical-analysis/utils/get-play-head-position-by-time';
import { useCurrentPlaylistItem, usePlayerCurrentSource, useVideoPlayerRef } from 'shared/components/video-player';
import { useVideoPlayerPlayingMode } from 'shared/components/video-player/hooks';
import { PlayingModes } from 'shared/components/video-player/types';

interface Props {
  recordingId: string;
  visibleContentRef: React.RefObject<HTMLDivElement>;
  headerContentRef: React.RefObject<HTMLDivElement>;
}

const isPlayHeadVisible = (scrollLeft: number, clientWidth: number, playHeadPosition: number) => {
  const playHeadPositionByWithScroll = playHeadPosition - scrollLeft;

  return playHeadPositionByWithScroll > 0 && playHeadPositionByWithScroll < clientWidth;
};

const getPlayHeadThreshold = (scrollLeft: number, clientWidth: number, playHeadPosition: number) => {
  if (!isPlayHeadVisible(scrollLeft, clientWidth, playHeadPosition)) return 0;
  return clientWidth - (playHeadPosition - scrollLeft);
};

export const PlayHead = ({ recordingId, visibleContentRef, headerContentRef }: Props) => {
  const playHeadPositioningActions = usePlayHeadPositionInTimeline({
    recordingId,
    visibleContentRef,
    headerContentRef,
  });
  const episodes = useTacticalAnalysisEpisodes(recordingId);
  const { mode, useEffectiveTime } = useVideoPlayerPlayingMode();
  const lastPlayHeadPosition = useRef(0);
  const { zoomLevel } = useTimelineZoomLevel();
  const currentSource = usePlayerCurrentSource();
  const videoPlayerRef = useVideoPlayerRef();
  const { currentTime, visibleTime } = useTimelineCurrentVideoSourceTime(recordingId);
  const matchSegments = useTacticalAnalysisMatchSegments(recordingId);
  const playHeadContentDiv = React.useRef<HTMLDivElement>(null);
  const playlistItem = useCurrentPlaylistItem();

  useEffect(() => {
    if (!visibleContentRef.current || !playHeadContentDiv.current || !videoPlayerRef?.current?.ready) return;

    const isPositionByEpisode =
      mode === PlayingModes.EPISODES || (mode === PlayingModes.TACTICAL_CAMERA && useEffectiveTime);

    const leftPosition = getPlayHeadPositionByTime(currentTime, isPositionByEpisode, episodes, zoomLevel);

    const playHeadThreshold = getPlayHeadThreshold(
      visibleContentRef.current.scrollLeft,
      visibleContentRef.current.clientWidth,
      leftPosition,
    );

    if (playHeadThreshold === 0 && Math.abs(lastPlayHeadPosition.current - leftPosition) > 10) {
      playHeadPositioningActions.center();
    }

    lastPlayHeadPosition.current = leftPosition;

    requestAnimationFrame(() => {
      if (!playHeadContentDiv.current || !visibleContentRef.current) return;

      if (playHeadThreshold <= 120 && playHeadThreshold > 16) {
        visibleContentRef.current.scrollLeft = visibleContentRef.current.scrollLeft + 1;
      }

      playHeadContentDiv.current.style.opacity = '1';
      playHeadContentDiv.current.style.transform = `translateX(${leftPosition}px)`;
    });
  }, [
    videoPlayerRef,
    playlistItem.hasHomographies,
    visibleContentRef,
    matchSegments,
    lastPlayHeadPosition,
    playHeadPositioningActions,
    currentTime,
    zoomLevel,
    currentSource,
    episodes,
    mode,
    useEffectiveTime,
  ]);

  return (
    <>
      <PlayHeadShadow recordingId={recordingId} />
      <PlayHeadContent ref={playHeadContentDiv}>
        <PlayHeadHeader>
          <PlayHeadArrowDown />
          <PlayHeadTime>{formattedTime(visibleTime, matchSegments)}</PlayHeadTime>
        </PlayHeadHeader>
      </PlayHeadContent>
    </>
  );
};
