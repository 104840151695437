import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

export const BackofficeUnauthorizedContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  padding: theme.spacing(3, 2),
  background: Colors.white,
  borderRadius: theme.shape.borderRadius,
}));

export const BackofficeLoadingContainer = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));
