import { ErrorBoundary } from '@sentry/react';
import { ReactNode, Suspense } from 'react';

import { ErrorContainerFeature } from 'features/error-container/ErrorContainer.feature';
import { ContainerWrapper, ContainerWrapperProps, Content } from 'shared/components/container/index.styled';

interface Props extends ContainerWrapperProps {
  children: ReactNode;
  fullScreen?: boolean;
}

const Container = ({ children, fullScreen = false, backgroundImage, backgroundSize, backgroundColor }: Props) => {
  return (
    <ErrorBoundary
      fallback={
        <Suspense>
          <ErrorContainerFeature />
        </Suspense>
      }
    >
      <ContainerWrapper
        backgroundImage={backgroundImage}
        backgroundSize={backgroundSize}
        backgroundColor={backgroundColor}
      >
        <Content fullScreen={fullScreen}>{children}</Content>
      </ContainerWrapper>
    </ErrorBoundary>
  );
};

export default Container;
