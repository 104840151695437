import { Box, Button, Stack, TextField } from '@mui/material';
import { FormEvent, useState } from 'react';

import { useDashboardEvents } from 'entities/dashboard/hooks/useDashboardEvents';
import { EmbeddedDashboardFeature } from 'features/dashboard/EmbeddedDashboardFeature';
import Container from 'shared/components/container';
import { Dialog } from 'shared/components/dialog';
import { useQueryParams } from 'shared/hooks/use-query-params';
import { useGenerateTemporaryPlaylist } from 'shared/hooks/useGenerateTemporaryPlaylist';
import { Playlist } from 'shared/types';
import { TemporaryPlaylistWidget } from 'widgets/playlist/temporary-playlist/TemporaryPlaylist.widget';

export const DashboardPreviewPage = () => {
  const query = useQueryParams();
  const dashboardIdQueryParam = query.get('dashboardId');
  const [dashboardId, setDashboardId] = useState<string>(dashboardIdQueryParam || '');
  const [temporalPlaylist, setTemporalPlaylist] = useState<Playlist | null>(null);
  const generateTemporaryPlaylist = useGenerateTemporaryPlaylist({ onSuccess: setTemporalPlaylist });

  useDashboardEvents({ onEvent: generateTemporaryPlaylist });

  const handleFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setDashboardId(event.currentTarget.dashboardId.value);
  };

  return (
    <Container fullScreen>
      <form onSubmit={handleFormSubmit} style={{ height: '100%' }}>
        {!dashboardIdQueryParam && (
          <Stack direction={'row'} alignItems={'center'} gap={2} padding={2} height={'100px'}>
            <TextField id={'dashboardId'} size={'small'} sx={{ width: '380px' }} />
            <Button color={'primary'} variant={'contained'} size={'large'} type={'submit'}>
              Select Dashboard
            </Button>
          </Stack>
        )}
        {dashboardId && <EmbeddedDashboardFeature key={dashboardId} dashboardId={dashboardId} />}
        <Dialog onClose={() => setTemporalPlaylist(null)} open={!!temporalPlaylist} fullWidth maxWidth={false}>
          <Box
            height={(theme) => `calc(100vh - ${theme.spacing(8)})`}
            width={(theme) => `calc(100vw - ${theme.spacing(8)})`}
          >
            {temporalPlaylist && <TemporaryPlaylistWidget playlist={temporalPlaylist} />}
          </Box>
        </Dialog>
      </form>
    </Container>
  );
};
