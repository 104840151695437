import { literal, TypeOf, z } from 'zod';

import {
  FilesBundesligaFieldsNames,
  FilesBundesligaForm,
  filesBundesligaSchema,
  FilesChyronHegoSetOneFieldsNames,
  FilesChyronhegoSetOneForm,
  filesChyronHegoSetOneSchema,
  FilesChyronHegoSetTwoFieldsNames,
  FilesChyronHegoSetTwoForm,
  filesChyronHegoSetTwoSchema,
  FilesHawkeyeFieldsNames,
  FilesHawkeyeForm,
  filesHawkeyeSchema,
  FilesOptaFieldsNames,
  FilesOptaForm,
  filesOptaSchema,
  FilesSecondSpectrumFieldsNames,
  FilesSecondSpectrumSetOneForm,
  filesSecondSpectrumSetOneSchema,
  FilesSecondSpectrumSetTwoForm,
  filesSecondSpectrumSetTwoSchema,
  FilesSignalityFieldsNames,
  FilesSignalityForm,
  filesSignalitySchema,
  SportlogiqTrackingType,
} from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/types/processFixtureFormSubforms.types';

export enum ProcessFixtureEventsProvider {
  KOGNIA = 'kognia',
  OPTA = 'opta',
  BUNDESLIGA = 'bundesliga',
}

export enum ProcessFixturePipelineType {
  THIRD_PARTY_TACTICAL = 'third-party-tactical',
  THIRD_PARTY_TACTICAL_TRACKING = 'third-party-tactical-tracking',
}

export enum ProcessFixtureFieldsNames {
  recordingId = 'recording_id',
  videoPath = 'video_path',
  bucket = 'bucket',
  pipelineType = 'pipeline_type',
  trackingProvider = 'tracking_provider',
  eventsProvider = 'events_provider',
  runProcessing = 'run_processing',
  restartProcessing = 'restart_processing',
  showOverlays = 'show_overlays',
  autoRunTacticalAnalysis = 'auto_run_tactical_analysis',
  sportlogiqTrackingType = 'sportlogiq_tracking_type',
}

export enum ProcessFixtureTrackingProvider {
  KOGNIA = 'kognia',
  HAWKEYE = 'hawkeye',
  CHYRONHEGO = 'chyronhego',
  SIGNALITY = 'signality',
  SECOND_SPECTRUM = 'second-spectrum',
  SPORTLOGIQ = 'sportlogiq',
}

export interface ProcessFixtureForm
  extends FilesHawkeyeForm,
    FilesChyronhegoSetOneForm,
    FilesChyronHegoSetTwoForm,
    FilesSecondSpectrumSetOneForm,
    FilesSecondSpectrumSetTwoForm,
    FilesSignalityForm,
    FilesOptaForm,
    FilesBundesligaForm {
  [ProcessFixtureFieldsNames.recordingId]: string;
  [ProcessFixtureFieldsNames.videoPath]: string;
  [ProcessFixtureFieldsNames.bucket]: string;
  [ProcessFixtureFieldsNames.pipelineType]: ProcessFixturePipelineType;
  [ProcessFixtureFieldsNames.trackingProvider]?: ProcessFixtureTrackingProvider;
  [ProcessFixtureFieldsNames.eventsProvider]?: ProcessFixtureEventsProvider;
  [ProcessFixtureFieldsNames.runProcessing]: boolean;
  [ProcessFixtureFieldsNames.restartProcessing]: boolean;
  [ProcessFixtureFieldsNames.showOverlays]: boolean;
  [ProcessFixtureFieldsNames.autoRunTacticalAnalysis]: boolean;
  [ProcessFixtureFieldsNames.sportlogiqTrackingType]: SportlogiqTrackingType;
}

const filesSchema = z.object({
  ...filesHawkeyeSchema.shape,
  ...filesChyronHegoSetOneSchema.shape,
  ...filesChyronHegoSetTwoSchema.shape,
  ...filesSecondSpectrumSetOneSchema.shape,
  ...filesSecondSpectrumSetTwoSchema.shape,
  ...filesSignalitySchema.shape,
  ...filesOptaSchema.shape,
  ...filesBundesligaSchema.shape,
});

export const processFixtureFormSchema = z.object({
  [ProcessFixtureFieldsNames.recordingId]: z.string().min(2),
  [ProcessFixtureFieldsNames.videoPath]: z.string().min(2),
  [ProcessFixtureFieldsNames.bucket]: z.string(),
  [ProcessFixtureFieldsNames.pipelineType]: z.nativeEnum(ProcessFixturePipelineType),
  [ProcessFixtureFieldsNames.trackingProvider]: z
    .nativeEnum(ProcessFixtureTrackingProvider)
    .optional()
    .or(literal('').transform(() => undefined)),
  [ProcessFixtureFieldsNames.eventsProvider]: z
    .nativeEnum(ProcessFixtureEventsProvider)
    .optional()
    .or(literal('').transform(() => undefined)),
  [ProcessFixtureFieldsNames.runProcessing]: z.boolean(),
  [ProcessFixtureFieldsNames.restartProcessing]: z.boolean(),
  [ProcessFixtureFieldsNames.showOverlays]: z.boolean(),
  [ProcessFixtureFieldsNames.autoRunTacticalAnalysis]: z.boolean(),
  [ProcessFixtureFieldsNames.sportlogiqTrackingType]: z.nativeEnum(SportlogiqTrackingType),
  ...filesSchema.shape,
});

export type processFixtureFormSchema = TypeOf<typeof processFixtureFormSchema>;

export const eventsFilesFieldsNames = {
  ...FilesOptaFieldsNames,
  ...FilesBundesligaFieldsNames,
};

export const trackingFilesFieldsNames = {
  ...FilesChyronHegoSetTwoFieldsNames,
  ...FilesChyronHegoSetOneFieldsNames,
  ...FilesHawkeyeFieldsNames,
  ...FilesSecondSpectrumFieldsNames,
  ...FilesSignalityFieldsNames,
};
