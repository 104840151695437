export interface RecordingTeam {
  name: string;
  logo: string;
  score: number;
}

export enum RecordingAnnotationTypes {
  all = 'ALL',
}

export enum RecordingFilters {
  ANNOTATION_TYPE = 'annotationType',
  COMPETITION = 'competition',
  DATE = 'date',
  MATCHDAY = 'matchday',
  TEAM = 'team',
  TYPE = 'type',
}

export interface RecordingsFilters {
  annotationType: string;
  competition: string;
  date: string;
  matchday: string;
  team: string;
  type: string;
}
