import { Grid } from '@mui/material';

import StateAnalysis from 'pages/recordings-list/components/vod-recording-card/recording-states/state-analysis';
import StateCamera from 'pages/recordings-list/components/vod-recording-card/recording-states/state-camera';
import StateTags from 'pages/recordings-list/components/vod-recording-card/recording-states/state-tags';
import styles from 'pages/recordings-list/components/vod-recording-card/RecordingCard.module.scss';
import { Recording } from 'shared/types/recording/types';

interface Props {
  hasVideoSource: boolean;
  recording: Recording;
}

const gridItemStyles = {
  padding: '6px 12px !important',
};

const StateInfo = ({ recording, hasVideoSource }: Props) => {
  return (
    <Grid container spacing={3} className={styles.states} wrap={'nowrap'} sx={{ marginTop: '0 !important' }}>
      <Grid item sx={gridItemStyles}>
        <StateCamera isProcessingVideo={recording.isProcessingVideo} active={hasVideoSource} />
      </Grid>
      <Grid item sx={gridItemStyles}>
        <StateAnalysis tacticalAnalysis={recording.tacticalAnalysis} hidden={false} />
      </Grid>
      <Grid item sx={gridItemStyles}>
        <StateTags active={recording.hasTaggingEvents} />
      </Grid>
    </Grid>
  );
};

export default StateInfo;
