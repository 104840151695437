import { transformUsers } from 'api/backoffice/user/transformer';
import { KogniaUser, KogniaUsersFilters, UsersApiResponse } from 'api/backoffice/user/use-kognia-users/types';
import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { usersWithFiltersUrl } from 'api/routes';
import { SortDirection } from 'shared/types/filters/types';

const PAGE_SIZE = 10;
export const USERS_QUERY_KEY = 'users';

interface Options {
  initialFilters?: KogniaUsersFilters;
  pageSize?: number | null;
}

export const INITIAL_USERS_FILTERS: KogniaUsersFilters = {
  firstName: '',
  emails: [],
};

const DEFAULT_USERS_SORT_FIELD = 'updatedAt';

export const useKogniaUsers = (
  options: Options = {
    initialFilters: INITIAL_USERS_FILTERS,
  },
): InfinityQueryResult<KogniaUser, KogniaUsersFilters> => {
  return useInfinityQuery<KogniaUser, KogniaUsersFilters, UsersApiResponse>({
    generateUrl: usersWithFiltersUrl,
    initialFilters: options.initialFilters,
    key: USERS_QUERY_KEY,
    options: {
      size: options.pageSize ?? PAGE_SIZE,
      sort: `${DEFAULT_USERS_SORT_FIELD},${SortDirection.DESC}`,
      sortDirection: SortDirection.DESC,
    },
    transformer: transformUsers,
  });
};
