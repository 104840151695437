import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TacticalAnalysesFilters } from 'api/recording/useEpisodesWithFilters/types';
import { useMultipleRecordingsFilters } from 'features/multi-analysis-filters/hooks/use-multiple-recordings-filters/useMultipleRecordingsFilters';
import { FilterEvents, EventType } from 'features/multi-analysis-filters/ui/filter-events/FilterEvents';
import { RecordingsFiltersActions } from 'features/multi-analysis-filters/ui/recordings-filters-actions/RecordingsFiltersActions';
import { RecordingsFiltersInsideAnEpisode } from 'features/multi-analysis-filters/ui/recordings-filters-inside-an-episode/RecordingsFiltersInsideAnEpisode';
import { getFilterStatus } from 'features/multi-analysis-filters/utils/getFilterStatus';
import { FilterContainer } from 'shared/components/filter-container';
import { FilterTab, FilterTabPanel, FilterTabs } from 'shared/components/filter-tabs';
import { LoadingBlock } from 'shared/components/loading-block';
import { RecordingsFilters } from 'shared/types/recording/types';

interface Props {
  filtersData: TacticalAnalysesFilters;
  recordingIds: string[];
  isLoadingFilters: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  onApply: (filters: RecordingsFilters) => void;
  applyButtonLabel: string;
  applyButtonDefaultLabel: string;
  resetButtonLabel: string;
  onUpdate?: (filters: RecordingsFilters) => void;
  getFilteredItems?: (params: RecordingsFilters) => void;
  appliedFilters?: RecordingsFilters;
  clearFilters?: () => void;
}

const Tabs = {
  Starting: '0',
  Inside: '1',
  Ending: '2',
};

export const MultiAnalysisFilters = ({
  recordingIds,
  applyButtonLabel,
  applyButtonDefaultLabel,
  resetButtonLabel,
  isDisabled,
  filtersData,
  isLoading,
  isLoadingFilters,
  appliedFilters,
  onUpdate,
  getFilteredItems,
  onApply,
  clearFilters,
}: Props) => {
  const { filters, reset, setEventsEndingFilter, setEventsStartingFilter, setScenariosInsideAnEpisodeFilter } =
    useMultipleRecordingsFilters(appliedFilters ? { ...appliedFilters } : { recordingIds });

  const isPrevAppliedFilters = !!appliedFilters?.recordingIds?.length;
  const { areFiltersStartingApplied, areFiltersInsideApplied, areFiltersEndingApplied } = getFilterStatus(filters);
  const areFiltersApplied = areFiltersStartingApplied || areFiltersEndingApplied || areFiltersInsideApplied;

  useEffect(() => {
    onUpdate && onUpdate(filters);
  }, [areFiltersApplied, filters, onUpdate]);

  const handleOnApply = useCallback(() => {
    if (clearFilters && isPrevAppliedFilters && !areFiltersApplied) {
      clearFilters();
      return;
    }

    onApply(filters);
  }, [areFiltersApplied, clearFilters, filters, isPrevAppliedFilters, onApply]);

  const { t } = useTranslation();
  const [selectedTabIndex, setSelectedTabIndex] = useState(Tabs.Starting);

  const handleTabChange = useCallback((event: React.ChangeEvent<any>, newValue: string) => {
    setSelectedTabIndex(newValue);
  }, []);

  const areFiltersDisabled = recordingIds.length === 0;

  useEffect(() => {
    if (getFilteredItems && filters.recordingIds.length > 0 && areFiltersApplied) {
      getFilteredItems(filters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, getFilteredItems]);

  return (
    <LoadingBlock isLoading={isLoadingFilters}>
      <FilterContainer disabled={areFiltersDisabled}>
        <FilterTabs disabled={areFiltersDisabled} value={selectedTabIndex} onChange={handleTabChange}>
          <FilterTab
            disabled={areFiltersDisabled}
            active={areFiltersStartingApplied}
            label={t('recordings:filters.tabs.events-starting-episode')}
            value={'0'}
          />
          <FilterTab
            disabled={areFiltersDisabled}
            active={areFiltersInsideApplied}
            label={t('recordings:filters.tabs.scenarios-inside-episode')}
            value={'1'}
          />
          <FilterTab
            disabled={areFiltersDisabled}
            active={areFiltersEndingApplied}
            label={t('recordings:filters.tabs.events-ending-episode')}
            value={'2'}
          />
        </FilterTabs>
        <FilterTabPanel value={selectedTabIndex} index={Tabs.Starting}>
          <FilterEvents
            disabled={areFiltersDisabled}
            type={EventType.STARTING}
            onUpdate={setEventsStartingFilter}
            filters={filters.eventsStarting}
            teams={filtersData.teams}
            actionsStarting={filtersData.eventsStarting}
          />
        </FilterTabPanel>
        <FilterTabPanel value={selectedTabIndex} index={Tabs.Inside}>
          <RecordingsFiltersInsideAnEpisode
            filters={filters.scenariosOrTacticsInside}
            onUpdate={setScenariosInsideAnEpisodeFilter}
            scenarios={filtersData.scenarios}
            tactics={filtersData.tactics}
            teams={filtersData.teams}
          />
        </FilterTabPanel>
        <FilterTabPanel value={selectedTabIndex} index={Tabs.Ending}>
          <FilterEvents
            disabled={areFiltersDisabled}
            type={EventType.ENDING}
            onUpdate={setEventsEndingFilter}
            filters={filters.eventsEnding}
            teams={filtersData.teams}
            actionsStarting={filtersData.eventsEnding}
          />
        </FilterTabPanel>
        <RecordingsFiltersActions>
          <Grid item>
            <Button variant='outlined' color='secondary' onClick={reset} disabled={!areFiltersApplied}>
              {resetButtonLabel}
            </Button>
          </Grid>
          <Grid item>
            <LoadingButton
              variant='contained'
              color='primary'
              disabled={
                (clearFilters ? !areFiltersApplied && !isPrevAppliedFilters : !areFiltersApplied) ||
                isLoading ||
                isDisabled
              }
              onClick={handleOnApply}
              loading={areFiltersApplied && isLoading}
            >
              {areFiltersApplied ? applyButtonLabel : applyButtonDefaultLabel}
            </LoadingButton>
          </Grid>
        </RecordingsFiltersActions>
      </FilterContainer>
    </LoadingBlock>
  );
};
