import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { TacticalAnalysisWithFiltersResponse } from 'api/recording/use-tactical-analysis-filtered-items/types';
import { transformFiltersForRequest } from 'api/recording/utils';
import { tacticalAnalysisWithFilters } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { Episode } from 'shared/types';
import { RecordingsFilters } from 'shared/types/recording/types';

const placeHolderData = [] as Episode[];

export const useTacticalAnalysisFilteredItems = (): TacticalAnalysisWithFiltersResponse => {
  const { t } = useTranslation();
  const { mutate, isPending, isError, isSuccess, data } = useMutationRequest<Episode[], Episode[]>({
    type: HTTPMethod.POST,
    errorMessage: t('api:use-tactical-analysis-filter.error'),
    transformer: (data: Episode[]): Episode[] => data,
  });

  const getFilters = useCallback(
    (
      tacticalAnalysisId: string,
      appliedFilters: RecordingsFilters,
      onSuccess?: (episodes: Episode[], appliedFilters: RecordingsFilters) => void,
    ) => {
      mutate(
        {
          url: tacticalAnalysisWithFilters(tacticalAnalysisId),
          data: transformFiltersForRequest(appliedFilters),
        },
        { onSuccess: (data: Episode[]) => onSuccess && onSuccess(data, appliedFilters) },
      );
    },
    [mutate],
  );

  return { getFilters, isPending, isError, isSuccess, data: !data ? placeHolderData : data };
};
