import { ErrorMessage } from '@hookform/error-message';
import { zodResolver } from '@hookform/resolvers/zod';
import { LoadingButton } from '@mui/lab';
import { Box, Button, CardActions, Stack } from '@mui/material';
import React, { forwardRef, Ref, useCallback, useImperativeHandle } from 'react';
import { useForm } from 'react-hook-form';

import { EnvironmentFormDefaultValues } from 'pages/backoffice/features/environment-form/types/environmentFormDefaultValues';
import { EnvironmentFormField } from 'pages/backoffice/features/environment-form/types/environmentFormField';
import { EnvironmentFormRef } from 'pages/backoffice/features/environment-form/types/environmentFormRef';
import { EnvironmentFormValues } from 'pages/backoffice/features/environment-form/types/environmentFormValues';
import { EnvironmentOnSubmit } from 'pages/backoffice/features/environment-form/types/environmentOnSubmit';
import { EnvironmentFormLabels } from 'pages/backoffice/features/environment-form/utils/environmentFormLabels';
import { environmentFormSchema } from 'pages/backoffice/features/environment-form/utils/environmentFormSchema';
import { generateEnvironmentFormFields } from 'pages/backoffice/features/environment-form/utils/generateEnvironmentFormFields';
import { FormInputField } from 'shared/components/form/form-input-field';
import { FormSelectField } from 'shared/components/form/form-select-field';
import { ClientBranding } from 'shared/types/user/types';
import { getErrorByFieldName } from 'shared/utils/get-error-by-field-name/getErrorByFieldName';

interface EnvironmentFormProps {
  isLoading: boolean;
  onSubmit: (params: EnvironmentOnSubmit) => void;
  defaultValues?: EnvironmentFormDefaultValues;
  brandingAssetsUrls?: Partial<ClientBranding> | null;
  isEdit?: boolean;
}

export const EnvironmentForm = forwardRef(
  ({ isLoading, onSubmit, defaultValues, isEdit = false }: EnvironmentFormProps, ref?: Ref<EnvironmentFormRef>) => {
    const {
      reset,
      register,
      handleSubmit: handleFormSubmit,
      getValues,
      formState: { errors, isDirty },
    } = useForm<EnvironmentFormValues>({
      resolver: zodResolver(environmentFormSchema),
      defaultValues,
    });

    useImperativeHandle(
      ref,
      () => ({
        reset,
      }),
      [reset],
    );

    const handleOnFormSubmit = useCallback(
      (data: EnvironmentFormValues) => {
        onSubmit({
          data,
        });
      },
      [onSubmit],
    );

    const formFieldRender = (field: EnvironmentFormField) => {
      switch (field.component) {
        case 'select':
          return (
            <FormSelectField
              id={field.id}
              label={EnvironmentFormLabels[field.id]}
              error={!!getErrorByFieldName(errors, field.id)}
              disabled={field.disabled}
              fullWidth={field.fullWidth}
              options={field.options}
              helperText={<ErrorMessage errors={errors} name={field.id} />}
              defaultValue={getValues(field.id) || ''}
              {...register(field.id)}
            />
          );
        case 'input':
        default:
          return (
            <FormInputField
              id={field.id}
              label={EnvironmentFormLabels[field.id]}
              error={!!getErrorByFieldName(errors, field.id)}
              disabled={field.disabled}
              fullWidth={field.fullWidth}
              type={field.type}
              helperText={<ErrorMessage errors={errors} name={field.id} />}
              {...register(field.id)}
            />
          );
      }
    };

    const formFieldsRender = generateEnvironmentFormFields({ isEdit }).map((field) => {
      return (
        <Stack key={field.id} direction='row' alignItems='center' spacing={1} justifyContent='space-between'>
          <Box sx={{ flexGrow: 1 }}>{formFieldRender(field)}</Box>
        </Stack>
      );
    });

    return (
      <form onSubmit={handleFormSubmit(handleOnFormSubmit)}>
        <Stack direction='column'>
          {formFieldsRender}

          <CardActions sx={{ justifyContent: 'flex-end' }}>
            <Button onClick={() => reset()}>Reset</Button>
            <LoadingButton
              color={'primary'}
              loading={isLoading}
              size={'large'}
              type={'submit'}
              variant={'contained'}
              disabled={!isDirty}
            >
              Send
            </LoadingButton>
          </CardActions>
        </Stack>
      </form>
    );
  },
);

EnvironmentForm.displayName = 'EnvironmentForm';
