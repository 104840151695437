import { faro } from '@grafana/faro-web-sdk';
import { useCallback } from 'react';

import { User } from 'shared/types/user/types';

export const useSetUserAndClientIdForMetrics = () => {
  return useCallback((clientId: string, user: User) => {
    faro.api.setUser({
      id: user.id,
      email: user.email,
      attributes: {
        client_id: clientId,
      },
    });
  }, []);
};
