import { atom, useAtomValue, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';

const timelineInitialTimeAtom = atomFamily(() => atom<number>());

export const useSetTimelineInitialTime = (id: string) => {
  return useSetAtom(timelineInitialTimeAtom(id));
};

export const useTimelineInitialTime = (id: string) => {
  return useAtomValue(timelineInitialTimeAtom(id));
};
