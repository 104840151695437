import { Box, Grid } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

export const RecordingsFiltersActions = ({ children }: Props) => {
  return (
    <Box display='flex' mb={3} py={3} px={0} borderColor='grey.200' sx={{ borderStyle: 'solid', borderWidth: '1px 0' }}>
      <Grid container flex={1} spacing={2} justifyContent={'center'}>
        {children}
      </Grid>
    </Box>
  );
};
