import { Stack } from '@mui/material';
import { IconStrawberry } from 'kognia-ui/icons/IconStrawberry';
import countBy from 'lodash/countBy';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RecordingsFiltersList } from 'api/recording/useFetchRecordings';
import { useRecordingsListFilters } from 'pages/recordings-list/hooks/useRecordingsListFilters';
import { RecordingFilters } from 'pages/recordings-list/types/recording';
import { FilterLabel } from 'shared/components/filter-label';
import Filter, { FilterOption } from 'shared/components/filter-v2';
import CheckBoxMenu from 'shared/components/filter-v2-menu/checkbox';
import { ActionTypes } from 'shared/streams/actionTypes';
import { publishEvent } from 'shared/streams/eventEmitter';
import { FilterOptions, FiltersList } from 'shared/types';
import { MetricsNames } from 'shared/types/metrics';
import { entriesFromObject } from 'shared/utils/objectToArray';

type FilterDropdownsProps = {
  filtersList: FiltersList;
};

export const Filters = ({ filtersList }: FilterDropdownsProps): JSX.Element => {
  const { t } = useTranslation();
  const [openFilter, setOpenFilter] = useState<string>();
  const { setFilter } = useRecordingsListFilters();

  const mapFilterOptions = useCallback(
    (filterKey: string, options: FilterOptions): FilterOption[] => {
      const mappedOptions = entriesFromObject(options).reduce<FilterOption[]>((acc, [key, value]) => {
        acc.push({
          id: key,
          isSelected: typeof value.isApplied !== 'undefined' ? value.isApplied : false,
          title: filterKey === RecordingFilters.TYPE ? t(`filters:${key}`) : value.title,
        });

        return acc;
      }, []);

      if (filterKey === RecordingFilters.TEAM) {
        mappedOptions.sort((a: FilterOption, b: FilterOption) =>
          a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
        );
      }

      return mappedOptions;
    },
    [t],
  );

  const handleOpenFilter = useCallback((key: string) => {
    publishEvent({
      type: ActionTypes.OPEN_RECORDINGS_FILTER,
      payload: {
        name: MetricsNames.RECORDINGS_OPEN_FILTER,
        data: {
          filter_name: key,
        },
      },
    });

    return setOpenFilter(key);
  }, []);

  const handleSubmit = useCallback(
    (key: keyof RecordingsFiltersList, filterOptions: FilterOption[]) => {
      const selectedOptions = filter(filterOptions, (option) => option.isSelected).map((option) => option.id);

      publishEvent({
        type: ActionTypes.APPLY_RECORDINGS_FILTER,
        payload: {
          name: MetricsNames.RECORDINGS_APPLY_FILTER,
          data: {
            filter_name: key,
          },
        },
      });

      setOpenFilter(undefined);
      setFilter(key, selectedOptions);
    },
    [setFilter],
  );

  return (
    <Stack direction={'row'} gap={2} justifyContent={'flex-end'}>
      <FilterLabel>
        <IconStrawberry size='small' />
        {t('filters:title')}
      </FilterLabel>
      {map(filtersList, (filter, key) => {
        const mappedOptions = mapFilterOptions(key, filter.options);
        const hasSelectedOptions = countBy(mappedOptions, (option) => option.isSelected).true > 0;
        return (
          <Filter
            key={key}
            isOpen={key === openFilter}
            onClickOpen={() => handleOpenFilter(key)}
            onClose={() => setOpenFilter(undefined)}
            displayName={t(`filters:${key}`)}
            hasSelectedOptions={hasSelectedOptions}
          >
            <CheckBoxMenu
              initialOptions={mappedOptions}
              submitOptions={(options: FilterOption[]) => handleSubmit(key as keyof RecordingsFiltersList, options)}
            />
          </Filter>
        );
      })}
    </Stack>
  );
};
