import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';

export const createUserEmailsData = (users: KogniaUser[], notDeletedIds: string[]) => {
  return users
    .reduce<string[]>((acc, item) => {
      if (notDeletedIds.includes(item.id)) {
        acc.push(item.email);
      }
      return acc;
    }, [])
    .join(', ');
};
