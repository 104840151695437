import { css } from '@mui/system';

export const KogniaLogoAnimatedStyled = css`
  .kognia-logo-animated {
    pointer-events: all;

    &.kognia-logo-hover:hover * {
      animation: none !important;
    }

    #circleBottomInner {
      animation: circleBottomInnerAnimation 4000ms linear 1 normal forwards;
    }

    @keyframes circleBottomInnerAnimation {
      0% {
        transform: translate(150.149101px, 156.153049px);
      }
      20.666667% {
        transform: translate(150.149101px, 156.153049px);
        animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
      }
      27.333333% {
        transform: translate(101.200001px, 202.097999px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      }
      30.333333% {
        transform: translate(52.200001px, 202.097999px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      }
      33% {
        transform: translate(52.200001px, 151.097999px);
        animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
      }
      43% {
        transform: translate(99.200001px, 103.097999px);
      }
      65% {
        transform: translate(99.200001px, 103.097999px);
      }
      67% {
        transform: translate(150.149101px, 156.153049px);
      }
      100% {
        transform: translate(150.149101px, 156.153049px);
      }
    }

    #circleBottomInner2 {
      animation: circleBottomInner2Animation 4000ms linear 1 normal forwards;
    }

    @keyframes circleBottomInner2Animation {
      0% {
        transform: scale(0, 0);
      }
      4% {
        transform: scale(0, 0);
      }
      15.333333% {
        transform: scale(1.654649, 1.654649);
        animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
      }
      18.333333% {
        transform: scale(1, 1);
      }
      39.333333% {
        transform: scale(1, 1);
      }
      39.666667% {
        transform: scale(0, 0);
      }
      70% {
        transform: scale(0, 0);
      }
      72% {
        transform: scale(1, 1);
      }
      100% {
        transform: scale(1, 1);
      }
    }

    #circleBottomOuter {
      animation: circleBottomOuterAnimation 4000ms linear 1 normal forwards;
    }

    @keyframes circleBottomOuterAnimation {
      0% {
        transform: translate(150.149101px, 156.153049px) scale(-0.026269, -0.026269);
      }
      4% {
        transform: translate(150.149101px, 156.153049px) scale(-0.026269, -0.026269);
        animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
      }
      15.333333% {
        transform: translate(150.149101px, 156.153049px) scale(2.742465, 2.742465);
      }
      100% {
        transform: translate(150.149101px, 156.153049px) scale(2.742465, 2.742465);
      }
    }

    #circleBottomOuterPath {
      animation: circleBottomOuterPathAnimation 4000ms linear 1 normal forwards;
    }

    @keyframes circleBottomOuterPathAnimation {
      0% {
        opacity: 1;
      }
      12% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
      }
      15.333333% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    #circleRightInner {
      animation: circleRightInnerAnimation 4000ms linear 1 normal forwards;
    }

    @keyframes circleRightInnerAnimation {
      0% {
        transform: translate(194.348px, 108.9px);
      }
      26.333333% {
        transform: translate(194.348px, 108.9px);
        animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
      }
      35% {
        transform: translate(101.348px, 201.9px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      }
      38.666667% {
        transform: translate(51.348px, 201.9px);
        animation-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
      }
      43% {
        transform: translate(51.348px, 151.9px);
        animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
      }
      52.333333% {
        transform: translate(97.348px, 104.9px);
      }
      65% {
        transform: translate(97.348px, 104.9px);
      }
      67% {
        transform: translate(194.348px, 108.9px);
      }
      100% {
        transform: translate(194.348px, 108.9px);
      }
    }

    #circleRightInner2 {
      animation: circleRightInner2Animation 4000ms linear 1 normal forwards;
    }

    @keyframes circleRightInner2Animation {
      0% {
        transform: scale(0.030924, 0.030924);
      }
      3.333333% {
        transform: scale(0.030924, 0.030924);
        animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
      }
      13.666667% {
        transform: scale(1.548077, 1.548077);
        animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
      }
      17.666667% {
        transform: scale(1, 1);
      }
      50% {
        transform: scale(1, 1);
      }
      50.666667% {
        transform: scale(0, 0);
      }
      70% {
        transform: scale(0, 0);
      }
      72% {
        transform: scale(1, 1);
      }
      100% {
        transform: scale(1, 1);
      }
    }

    #circleRightOuter {
      animation: circleRightOuterAnimation 4000ms linear 1 normal forwards;
    }

    @keyframes circleRightOuterAnimation {
      0% {
        transform: translate(194.348px, 108.9px) scale(0.030916, 0.030916);
      }
      3.333333% {
        transform: translate(194.348px, 108.9px) scale(0.030916, 0.030916);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      }
      13.666667% {
        transform: translate(194.348px, 108.9px) scale(3.148678, 3.148678);
      }
      100% {
        transform: translate(194.348px, 108.9px) scale(3.148678, 3.148678);
      }
    }

    #circleRightOuterPath {
      animation: circleRightOuterPathAnimation 4000ms linear 1 normal forwards;
    }

    @keyframes circleRightOuterPathAnimation {
      0% {
        opacity: 1;
      }
      10.333333% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
      }
      13.666667% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }

    #circleLeftInner {
      animation: circleLeftInnerAnimation 4000ms linear 1 normal forwards;
    }

    @keyframes circleLeftInnerAnimation {
      0% {
        transform: translate(96.9508px, 103.946898px) scale(0, 0);
      }
      4.666667% {
        transform: translate(96.9508px, 103.946898px) scale(0, 0);
        animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
      }
      16% {
        transform: translate(96.9508px, 103.946898px) scale(1.441659, 1.441659);
        animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
      }
      19% {
        transform: translate(96.9508px, 103.946898px) scale(1, 1);
      }
      38.666667% {
        transform: translate(96.9508px, 103.946898px) scale(1, 1);
      }
      41.333333% {
        transform: translate(96.9508px, 103.946898px) scale(1.267653, 1.267653);
        animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
      }
      44% {
        transform: translate(96.9508px, 103.946898px) scale(1, 1);
      }
      48.666667% {
        transform: translate(96.9508px, 103.946898px) scale(1, 1);
      }
      51.333333% {
        transform: translate(96.9508px, 103.946898px) scale(1.441658, 1.441658);
        animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
      }
      55.666667% {
        transform: translate(96.9508px, 103.946898px) scale(0, 0);
      }
      70% {
        transform: translate(96.9508px, 103.946898px) scale(0, 0);
      }
      72% {
        transform: translate(96.9508px, 103.946898px) scale(1, 1);
      }
      100% {
        transform: translate(96.9508px, 103.946898px) scale(1, 1);
      }
    }

    #circleLeftOuter {
      animation: circleLeftOuterAnimation 4000ms linear 1 normal forwards;
    }

    @keyframes circleLeftOuterAnimation {
      0% {
        transform: translate(96.9508px, 103.946898px) scale(-0.001604, -0.001604);
      }
      4.666667% {
        transform: translate(96.9508px, 103.946898px) scale(-0.001604, -0.001604);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
      }
      16% {
        transform: translate(96.9508px, 103.946898px) scale(1.99611, 1.99611);
      }
      100% {
        transform: translate(96.9508px, 103.946898px) scale(1.99611, 1.99611);
      }
    }

    #circleLeftOuterPath {
      animation: circleLeftOuterPathAnimation 4000ms linear 1 normal forwards;
    }

    @keyframes circleLeftOuterPathAnimation {
      0% {
        opacity: 1;
      }
      12.666667% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 1, 1);
      }
      16% {
        opacity: 0;
      }
      100% {
        opacity: 0;
      }
    }
  }
`;
