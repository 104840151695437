import {
  Clip,
  ClipType,
} from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { TimelineTableBlock } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/timeline';
import { MatchSegmentTypes } from 'shared/types/segment/types';

const generateNoEffectiveTimeClip = (
  id: string,
  rowId: string,
  startTime: number,
  endTime: number,
  title = '',
  idx = 0,
): Clip => {
  return {
    id: `${id}-not-effective-time-${idx}`,
    type: 'not-effective-time',
    startTime,
    endTime,
    rowId,
    title,
    elementId: '',
    clips: [],
  };
};

type Options = {
  timelineTableBlocks: TimelineTableBlock[];
  clipType: ClipType;
  rowId: string;
  clipIdPrefix?: string;
};

export const generateEpisodeClips = ({ timelineTableBlocks, clipType, clipIdPrefix = '', rowId }: Options): Clip[] => {
  return timelineTableBlocks.reduce<Clip[]>((acc, episode, idx) => {
    const nextEpisodeIndex = idx < timelineTableBlocks.length - 1 ? idx + 1 : null;

    if (idx === 0) {
      acc.push(generateNoEffectiveTimeClip(episode.id, rowId, 0, episode.startTime));
    }

    acc.push({
      id: clipIdPrefix ? `${clipIdPrefix}-${episode.id}` : episode.id,
      type: clipType,
      startTime: episode.startTime,
      endTime: episode.endTime,
      rowId: rowId,
      // TODO add translation or use a translation key
      title: `timeline:episode-name`,
      elementId: episode.id,
      name: episode.name,
      clips: [],
    });

    if (nextEpisodeIndex) {
      const nextEpisode = timelineTableBlocks[nextEpisodeIndex];

      const isHalfTime =
        episode &&
        nextEpisode &&
        nextEpisode.matchSegment === MatchSegmentTypes.SECOND &&
        episode.matchSegment === MatchSegmentTypes.FIRST;

      acc.push(
        generateNoEffectiveTimeClip(
          episode.id,
          episode.id,
          episode.endTime,
          nextEpisode.startTime,
          isHalfTime ? 'HT' : '',
          acc.length,
        ),
      );
    }

    return acc;
  }, []);
};
