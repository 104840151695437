import { SvgIcon, type SvgIconProps } from '../components/SvgIcon';

export const IconShieldChess = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        d='M12 22c-2.317-.583-4.229-1.913-5.737-3.988S4 13.633 4 11.1V5l8-3 8 3v6.1c0 2.533-.754 4.838-2.262 6.913S14.317 21.417 12 22zm0-2.1c1.617-.5 2.967-1.487 4.05-2.962A9.87 9.87 0 0 0 17.95 12H12V4.125l-6 2.25v5.175a2.62 2.62 0 0 0 .05.45H12v7.9z'
        fill='currentColor'
      />
    </SvgIcon>
  );
};
