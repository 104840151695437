import { IconShield } from 'kognia-ui/icons/IconShield';
import React from 'react';

import ActiveFilter, { ActiveFilterTypes } from 'shared/components/active-filter';
import styles from 'shared/components/active-filter-team/ActiveFilter.module.scss';
import { MatchTeam } from 'shared/types/match';

interface Props {
  team?: MatchTeam;
  teamType: ActiveFilterTypes;
  action?: string;
  contained?: boolean;
  showCloseButton?: boolean;
  onClose?: () => void;
  hasTeamFocus?: boolean;
}

const ActiveFilterTeam = ({
  contained = false,
  team,
  teamType,
  action = '',
  showCloseButton,
  onClose,
  hasTeamFocus,
}: Props) => {
  if (!team) return null;

  return (
    <ActiveFilter
      hasTeamFocus={hasTeamFocus}
      onClose={onClose}
      closeButton={showCloseButton}
      contained={contained}
      logo={
        team.logo ? (
          <img alt={'logo'} className={styles.logo} src={team.logo} />
        ) : (
          <IconShield size='small' color='secondary' className={styles.icon} />
        )
      }
      variant={teamType}
      title={team.name}
      action={action}
    />
  );
};

export default ActiveFilterTeam;
