import React, { useCallback } from 'react';

import { PostNewPlaylistItem } from 'api/playlist/types';
import { useAddManyToPlaylist } from 'api/playlist/useAddManyToPlaylist';
import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { generateClipsWithOverlappingTime } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/generateClips';
import AddToPlaylistMenu from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu';
import {
  usePlaylistMenuItems,
  useRemoveMultiplePlaylistMenuItem,
} from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/add-to-playlist-menu-state/hooks';
import { OverlappingClipsModal } from 'pages/tactical-analysis/components/timeline/overlapping-clips-modal/OverlappingClipsModal';
import { useAddToPlaylistValidator } from 'pages/tactical-analysis/hooks/use-add-to-playlist-validator/useAddToPlaylistValidator';
import { useTimelineIsMultiselectModeActive } from 'pages/tactical-analysis/hooks/use-timeline-is-multiselect-mode-active';
import { useMapClipsToPlaylistItems } from 'shared/hooks/use-map-clips-to-post-playlist-items/useMapClipsToPlaylistItems';
import { FundamentalsSelection } from 'shared/types/playlist/types';

interface Props {
  recordingId: string;
}

const AddToPlaylistMenuTimeline = ({ recordingId }: Props) => {
  const { isMultiselectModeActive, setIsMultiselectModeActive } = useTimelineIsMultiselectModeActive(recordingId);
  const reset = useRemoveMultiplePlaylistMenuItem();
  const { addManyToPlaylist } = useAddManyToPlaylist();
  const selectedPlaylistItems = usePlaylistMenuItems();
  const mapClipsToPlaylistItems = useMapClipsToPlaylistItems({ recordingId });
  const {
    isValidationModalOpen,
    onModalOpen,
    onModalClose,
    checkForParentClips,
    resetData,
    setData,
    data: { clipsToSave, playlistId, fundamentalsSelected },
  } = useAddToPlaylistValidator();

  const handleSubmitRequest = useCallback(
    ({ items }: { items: PostNewPlaylistItem[] }) => {
      addManyToPlaylist({
        items,
        options: {
          onSuccess: () => {
            reset(selectedPlaylistItems.map((clip) => clip.id));
            resetData();
          },
        },
      });
    },
    [addManyToPlaylist, reset, resetData, selectedPlaylistItems],
  );

  const handleSubmit = useCallback(
    ({
      items,
      merge,
      callbackData,
    }: {
      items: Clip[];
      merge: boolean;
      callbackData?: { playlistId: string; fundamentalsSelected: FundamentalsSelection };
    }) => {
      if (!callbackData && !fundamentalsSelected) return;
      const fundamentals: FundamentalsSelection | undefined =
        callbackData?.fundamentalsSelected ?? fundamentalsSelected;
      if (!fundamentals) return;
      const playlistItems: Clip[] = merge ? generateClipsWithOverlappingTime({ clips: items }) : items;
      const playlistItemsToSave: PostNewPlaylistItem[] = mapClipsToPlaylistItems({
        items: playlistItems,
        playlistId: callbackData?.playlistId ?? playlistId,
        fundamentalsSelected: fundamentals,
      });

      handleSubmitRequest({
        items: playlistItemsToSave,
      });
    },
    [fundamentalsSelected, handleSubmitRequest, mapClipsToPlaylistItems, playlistId],
  );

  const handleSave = useCallback(
    ({
      items,
      playlistId,
      fundamentalsSelected,
    }: {
      items: Clip[];
      playlistId: string;
      fundamentalsSelected: FundamentalsSelection;
    }) => {
      setData({ clips: items, playlistId, fundamentalsSelected });
      checkForParentClips({
        playlistItems: items,
        callback: (items) => handleSubmit({ items, merge: false, callbackData: { playlistId, fundamentalsSelected } }),
      });
    },
    [checkForParentClips, handleSubmit, setData],
  );

  return (
    <>
      <AddToPlaylistMenu
        isOpen={isMultiselectModeActive}
        onClose={() => setIsMultiselectModeActive(false)}
        recordingId={recordingId}
        onSave={handleSave}
      />
      <OverlappingClipsModal
        isOpen={isValidationModalOpen}
        onClose={onModalClose}
        onOpen={onModalOpen}
        onSubmit={(merge) => handleSubmit({ items: clipsToSave, merge })}
      />
    </>
  );
};

export default React.memo(AddToPlaylistMenuTimeline);
