import { KeypadTag } from 'api/tagging-tool/types';
import { makeUUID } from 'tagging-tool/utility/uuid';

export enum KeypadGenericTagKey {
  // offense
  OFFENSE_L_CORNER,
  OFFENSE_DIRECT_FAULT,
  OFFENSE_INDIRECT_FAULT,
  OFFENSE_PENALTY,
  OFFENSE_R_CORNER,

  OFFENSE_L_CROSS,
  OFFENSE_GOAL_FAVOR,
  OFFENSE_GOAL_CHANCE,
  OFFENSE_R_CROSS,
  OFFENSE_FINALIZATION,
  OFFENSE_OFFENSE,
  OFFENSE_GK_SHORT,
  OFFENSE_GK_LONG,

  OFFENSE_THROW_IN_L,
  OFFENSE_THROW_IN_R,

  // transition
  TRANSITION_LOST_BALL,
  TRANSITION_BALL_RECOVERY,

  // defense

  DEFENSE_GK_LONG,
  DEFENSE_HIGH_PRESS,
  DEFENSE_DEFENSE,
  DEFENSE_FINALIZATION,
  DEFENSE_L_CROSS,
  DEFENSE_GOAL_CHANCE,
  DEFENSE_GOAL_AGAINST,
  DEFENSE_R_CROSS,

  DEFENSE_L_CORNER,
  DEFENSE_DIRECT_FAULT,
  DEFENSE_INDIRECT_FAULT,
  DEFENSE_PENALTY,
  DEFENSE_R_CORNER,

  DEFENSE_THROW_IN_L,
  DEFENSE_THROW_IN_R,

  // events

  EVENT_KICK_OFF,
  EVENT_SECOND_HALF,
}

export type KeypadGenericTagId = keyof typeof KeypadGenericTagKey;

export type KeypadIndex = {
  [key in KeypadGenericTagId]: KeypadTag;
} & {
  [key in string]: KeypadTag;
};

export const makeCustomKeypadTag = (params: {
  keypadId: string;
}): Pick<KeypadTag, 'keypadId' | 'keypadTagId' | 'isEnabled' | 'isCustom' | 'timeAfter' | 'timeBefore'> => {
  const { keypadId } = params;
  const keypadTagId = makeUUID();
  return {
    keypadId,
    keypadTagId,
    isEnabled: true,
    isCustom: true,
    timeAfter: 5,
    timeBefore: 5,
  };
};
