import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { getOauth2LoginUrl } from 'api/routes';
import { ProtectedRouteInner } from 'shared/components/protected-route/ui/protected-route-inner/ProtectedRouteInner';
import { CONNECTION_QUERY_PARAM_NAME } from 'shared/constants/queryParams';
import { useIsUserAuthorized } from 'shared/contexts/app-state/hooks/useIsUserAuthorized';

type ProtectedRouteProps = {
  children: ReactNode;
};

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const location = useLocation();

  const isUserAuthorized = useIsUserAuthorized();
  const queryParams = new URLSearchParams(location.search);
  const connection = queryParams.get(CONNECTION_QUERY_PARAM_NAME);

  useEffect(() => {
    if (isUserAuthorized === false) {
      window.location.href = getOauth2LoginUrl(location.pathname, connection);
    }
  }, [connection, isUserAuthorized, location.pathname]);
  if (!isUserAuthorized) return null;

  return <ProtectedRouteInner>{children}</ProtectedRouteInner>;
};
