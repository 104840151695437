import { Box, ClickAwayListener, ListItem, Stack } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSeason } from 'pages/backoffice/api/seasons/use-season';
import { useSeasons } from 'pages/backoffice/api/seasons/use-seasons';
import { RenderSelectOption } from 'pages/backoffice/components/select-components/render-option';
import { useOnChangeSeasonSelection } from 'pages/backoffice/pages/stages/components/select-season/use-on-change-season-selection';
import { Season } from 'pages/backoffice/types/seasons';
import { printSeasonName } from 'pages/backoffice/utils/print-season-name';
import { Autocomplete } from 'shared/components/autocomplete';
import { AutocompleteDropdownButton } from 'shared/components/autocomplete-multi-select/components/autocomplete-dropdown-button';
import {
  AutocompletePopper,
  AutocompletePopperContent,
} from 'shared/components/autocomplete-multi-select/components/autocomplete-popper';
import { AutocompletePopperWrapper } from 'shared/components/autocomplete-multi-select/components/autocomplete-popper-wrapper';
import { AutocompleteResultsWrapper } from 'shared/components/autocomplete-multi-select/components/autocomplete-results-wrapper';

export type SeasonsFilters = {
  name: string;
};

const INITIAL_RECORDING_SEASONS_FILTERS: SeasonsFilters = {
  name: '',
};

interface Props {
  setSeasonOnChange: (season: Season | undefined) => void;
  selectedSeasonId?: string;
}

const AUTOCOMPLETE_WIDTH = 400;

export const SelectSeason = ({ setSeasonOnChange, selectedSeasonId }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [autocompleteValue, setAutocompleteValue] = useState<Season | undefined>();
  const { items: results, isLoading, setFilters, fetchNextPage } = useSeasons();
  const { data: defaultSelectedSeason } = useSeason({ seasonId: selectedSeasonId });
  const handleOnChangeSelection = useOnChangeSeasonSelection(setSeasonOnChange, setAutocompleteValue);
  const { t } = useTranslation();

  useEffect(() => {
    handleOnChangeSelection(results, autocompleteValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results, autocompleteValue]);

  const isOpen = Boolean(anchorEl);

  const handleOpen = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAutocompleteValue(defaultSelectedSeason);
      setAnchorEl(event.currentTarget);
    },
    [defaultSelectedSeason],
  );

  const handleClose = useCallback(() => {
    setFilters(INITIAL_RECORDING_SEASONS_FILTERS);
    setAnchorEl(null);
  }, [setFilters]);

  const handleDropdownClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      isOpen ? handleClose() : handleOpen(event);
    },
    [isOpen, handleClose, handleOpen],
  );

  const handleSelectSeason = useCallback(() => {
    setAutocompleteValue((autocompleteValue) => {
      return autocompleteValue;
    });
    handleClose();
  }, [setAutocompleteValue, handleClose]);

  const handleSetName = useCallback(
    (name: string) => {
      setFilters({ name: name });
    },
    [setFilters],
  );

  const renderOption = useCallback((props: React.ComponentProps<typeof ListItem>, option: Season) => {
    return <RenderSelectOption {...props} key={option.id} name={printSeasonName(option)} />;
  }, []);

  const handleApply = useCallback(() => {
    setAutocompleteValue((autocompleteValue) => {
      return autocompleteValue;
    });
    handleClose();
  }, [setAutocompleteValue, handleClose]);

  const handleReset = useCallback(() => {
    setAutocompleteValue(undefined);
  }, [setAutocompleteValue]);

  const paperComponent: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>> = useCallback(
    (props) => (
      <AutocompleteResultsWrapper {...props} onReset={handleReset} onApply={handleApply}>
        {props.children}
      </AutocompleteResultsWrapper>
    ),
    [handleReset, handleApply],
  );

  const handleUpdateValue = useCallback(
    (item: Season | null) => {
      if (item === null) return;
      setAutocompleteValue(item);
      setSeasonOnChange(item);
      handleClose();
    },
    [setAutocompleteValue, setSeasonOnChange, handleClose],
  );

  return (
    <Stack direction={'column'} spacing={1}>
      <AutocompleteDropdownButton
        fullWidth
        isOpen={isOpen}
        onClick={handleDropdownClick}
        isSelected={Boolean(defaultSelectedSeason)}
        label={defaultSelectedSeason ? printSeasonName(defaultSelectedSeason) : 'Select season'}
      />
      <Box>
        {anchorEl && (
          <ClickAwayListener onClickAway={handleSelectSeason}>
            <AutocompletePopper anchorEl={anchorEl} open={isOpen} placement='bottom-start'>
              <AutocompletePopperContent elevation={8}>
                <Autocomplete
                  autoFocus
                  PaperComponent={paperComponent}
                  PopperComponent={AutocompletePopperWrapper}
                  fetchNextPage={fetchNextPage}
                  getItemLabel={(item) => printSeasonName(item)}
                  inputWidth={AUTOCOMPLETE_WIDTH}
                  isLoading={isLoading}
                  isOptionEqualToValue={(option: Season, value: Season) => option.id === value?.id}
                  listWidth={AUTOCOMPLETE_WIDTH}
                  multiple={false}
                  onChange={handleSetName}
                  open
                  options={results}
                  renderOption={renderOption}
                  placeholder={t('common:actions.search')}
                  resultsHeight={260}
                  updateValue={handleUpdateValue}
                  value={autocompleteValue}
                />
              </AutocompletePopperContent>
            </AutocompletePopper>
          </ClickAwayListener>
        )}
      </Box>
    </Stack>
  );
};
