import { BackofficeContentContainer } from 'pages/backoffice/components/backoffice-content-container';
import { UserActionsHeaderWidget } from 'pages/backoffice/widgets/users/user-actions-header/UserActionsHeader.widget';
import { UsersListWidget } from 'pages/backoffice/widgets/users/users-list/UsersList.widget';

export const UsersPage = () => {
  return (
    <BackofficeContentContainer>
      <UserActionsHeaderWidget />
      <UsersListWidget />
    </BackofficeContentContainer>
  );
};
