import { List } from '@mui/material';

import { CoachItem } from 'pages/backoffice/pages/coaches/components/coach-item';
import { Coach } from 'pages/backoffice/types/coaches';

interface Props {
  coaches: Coach[];
}

export const CoachesList = ({ coaches }: Props) => {
  const coachesItems = coaches.map((coache) => {
    return <CoachItem key={coache.id} coach={coache} />;
  });

  return <List>{coachesItems}</List>;
};
