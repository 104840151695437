import classNames from 'classnames';
import { ReactNode } from 'react';

import { RowContentContainerWrapper } from 'pages/tactical-analysis/components/timeline/timeline-table/components/row-content-container/RowContentContainer.styled';
import timelineStyles from 'pages/tactical-analysis/components/timeline/timeline-table/TimelineTable.module.scss';
import { Team } from 'pages/tactical-analysis/components/timeline/types';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  isHighlighted?: boolean;
  children: ReactNode;
  team: Team;
  borderTop: boolean;
  isSelected?: boolean;
}

export const RowContentContainer = ({
  children,
  borderTop = false,
  team,
  isHighlighted,
  isSelected,
  ...rest
}: Props) => (
  <RowContentContainerWrapper
    {...rest}
    isHighlighted={isHighlighted}
    isBorderTop={borderTop}
    className={classNames(timelineStyles.rowContent, {
      [timelineStyles.isSelectedForPlaying]: isSelected,
      [timelineStyles.isHomeTeamRow]: team === 'home',
      [timelineStyles.isOpponentTeamRow]: team === 'opponent',
    })}
  >
    {children}
  </RowContentContainerWrapper>
);
