import { useAtomValue, useSetAtom } from 'jotai';
import { atomWithReset, useResetAtom } from 'jotai/utils';

import { Competition } from 'shared/types/competition/competition';
import { Team } from 'shared/types/team/team';

type Step = 'competitions' | 'teams' | 'players';

type CompetitionNavigationState = {
  step: Step;
  competition?: Competition;
  team?: Team;
  season?: Competition['seasons'][number];
};

const DEFAULT_COMPETITIONS_STATE: CompetitionNavigationState = {
  step: 'competitions',
};

const competitionsState = atomWithReset<CompetitionNavigationState>(DEFAULT_COMPETITIONS_STATE);

export const useCompetitionsState = () => {
  return useAtomValue(competitionsState);
};

export const useSetCompetitionsState = () => {
  return useSetAtom(competitionsState);
};

export const useResetCompetitionsState = () => {
  return useResetAtom(competitionsState);
};
