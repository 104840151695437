import { UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { keypadUrl } from 'api/routes';
import { transformKeypad } from 'api/tagging-tool/transformers/keypads';
import { Keypad } from 'api/tagging-tool/types';

export const generateFetchKeypadQueryRef = (keypadId: [string]) => [`fetchKeypad-${keypadId[0]}`];

interface useFetchTaggingToolEventsInterface {
  (keypadId: [string]): UseQueryResult<Keypad>;
}

export const useFetchKeypad: useFetchTaggingToolEventsInterface = (keypadId) => {
  const fetchQueryRef = generateFetchKeypadQueryRef(keypadId);
  return useFetchRequest<Keypad>({
    queryRef: fetchQueryRef,
    url: keypadUrl(keypadId[0] ? keypadId[0] : '-'),
    transformer: transformKeypad,
    options: {
      enabled: false,
    },
  });
};
