import { UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { keypadsUrl } from 'api/routes';
import { transformKeypads } from 'api/tagging-tool/transformers/keypads';
import { KeypadSimple } from 'api/tagging-tool/types';

export const generateFetchKeypadsQueryRef = (recordingId: string) => [`fetchKeypads-${recordingId}`];

interface useFetchTaggingToolEventsInterface {
  (recordingId: string): UseQueryResult<KeypadSimple[]>;
}

export const useFetchKeypads: useFetchTaggingToolEventsInterface = (recordingId) => {
  const fetchQueryRef = generateFetchKeypadsQueryRef(recordingId);
  const fetchRequest = useFetchRequest<KeypadSimple[]>({
    queryRef: fetchQueryRef,
    url: keypadsUrl(),
    transformer: transformKeypads,
  });

  return { ...fetchRequest };
};
