import isEmpty from 'lodash/isEmpty';
import reduce from 'lodash/reduce';
import queryString from 'query-string';
import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export type QueryStringState = { [key in string]: string | string[] | null | undefined };

const filterAllowedFields = (state: QueryStringState, allowedFields: string[]) => {
  return reduce(
    state,
    (acc, value, key) => {
      if (!isEmpty(value) && allowedFields.includes(key)) {
        return { ...acc, [key]: value };
      }

      return acc;
    },
    {},
  );
};

export const useQueryStringState = (listOfFieldsToUpdate: string[]) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateValues = useCallback(
    (state: QueryStringState) => {
      const allowedFields = filterAllowedFields(state, listOfFieldsToUpdate);
      setSearchParams(new URLSearchParams(allowedFields));
    },
    [listOfFieldsToUpdate, setSearchParams],
  );

  return { value: queryString.parse(searchParams.toString()), updateValues };
};
