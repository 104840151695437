import { ButtonBase, styled, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { IconSizes, SvgIconProps } from 'kognia-ui/components/SvgIcon';
import { ComponentType } from 'react';

const BulkButtonBase = styled(ButtonBase)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(0.5, 1),
  transition: theme.transitions.create(['color']),
  ':disabled': { opacity: 0.8 },
  '&:hover': {
    color: theme.palette.primary.light,

    '& svg': {
      fill: theme.palette.primary.light,
    },
  },
}));

interface Props {
  onClick: () => void;
  IconComponent: ComponentType<SvgIconProps>;
  iconColor?: SvgIconProps['color'];
  iconSize?: IconSizes;
  label?: string;
  disabled?: boolean;
}

export const BulkButton = ({
  onClick,
  IconComponent,
  label,
  iconColor,
  iconSize = 'small',
  disabled = false,
}: Props) => {
  return (
    <BulkButtonBase onClick={onClick} disabled={disabled}>
      <IconComponent color={iconColor} sx={{ color: 'common.white' }} size={iconSize} />
      {label ? (
        <Typography component='span' fontSize={fontSizes.xxSmall} fontWeight='fontWeightMedium'>
          {label}
        </Typography>
      ) : null}
    </BulkButtonBase>
  );
};
