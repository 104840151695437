import { zodResolver } from '@hookform/resolvers/zod';
import { Button, MenuItem, Select, Stack, TextField } from '@mui/material';
import { IconUser } from 'kognia-ui/icons/IconUser';
import map from 'lodash/map';
import { useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { TypeOf } from 'zod';

import { useInvalidateTeams } from 'pages/backoffice/api/teams/use-teams';
import { useUpdateTeam } from 'pages/backoffice/api/teams/use-update-team';
import { MappingProvider } from 'pages/backoffice/types/shared';
import ConfirmPopoverDialog from 'shared/components/confirm-popover-dialog';
import { DialogNew } from 'shared/components/dialog-new';
import { FormFormLabel } from 'shared/components/form/form-form-label';
import { Team } from 'shared/types/team/team';
import { teamMappingSchema } from 'shared/types/team/teamSchema';

interface Props {
  onClose: () => void;
  team: Team;
}

const defaultProvider = MappingProvider.OPTA;

const findTeamProviderMapping = (team: Team, mappingProvider: string) => {
  if (!team?.mappings) return null;

  const mapping = team.mappings.find((mapping) => mapping.provider === mappingProvider);
  return mapping ? mapping.team_id_provider : null;
};

export enum MappingFormSchemaNames {
  provider = 'provider',
  teamIdProvider = 'team_id_provider',
}

export type MappingFormSchema = TypeOf<typeof teamMappingSchema>;

export const TeamMappingsModal = ({ onClose, team }: Props) => {
  const { editTeam, isPending } = useUpdateTeam();
  const invalidateTeams = useInvalidateTeams();
  const formRef = useRef<HTMLFormElement>(null);
  const [isOverwriteModalOpen, setIsOverwriteModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);

  const {
    register,
    watch,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<MappingFormSchema>({
    resolver: zodResolver(teamMappingSchema),
    defaultValues: {
      [MappingFormSchemaNames.provider]: defaultProvider,
      [MappingFormSchemaNames.teamIdProvider]: findTeamProviderMapping(team, defaultProvider) || '',
    },
  });

  const teamIdProvider = watch(MappingFormSchemaNames.teamIdProvider);

  const editTeamAction = useCallback(
    (teamId: string, mapping: MappingFormSchema) => {
      editTeam({
        id: teamId,
        data: {
          mappings: [mapping],
        },
        onSuccess: () => {
          invalidateTeams().then(() => onClose());
        },
      });
    },
    [editTeam, invalidateTeams, onClose],
  );

  const handleEditMapping = useCallback(
    (mapping: MappingFormSchema) => {
      findTeamProviderMapping(team, mapping.provider) && !isOverwriteModalOpen
        ? setIsOverwriteModalOpen(true)
        : editTeamAction(team.id, mapping);
    },
    [editTeamAction, team, isOverwriteModalOpen],
  );

  const handleModalSubmit = useCallback(() => {
    formRef.current?.requestSubmit();
  }, []);

  return (
    <DialogNew
      isLoading={isPending}
      fullWidth
      maxWidth='md'
      title={'Team mappings'}
      icon={<IconUser />}
      onCancel={onClose}
      onClose={onClose}
      open={true}
      onSubmit={handleModalSubmit}
      buttonSubmitText={'Done'}
      submitDisabled={!isDirty}
    >
      <form ref={formRef} onSubmit={handleSubmit(handleEditMapping)}>
        <Stack gap={2}>
          <FormFormLabel>Provider</FormFormLabel>
          <Select size={'small'} variant={'outlined'} value={defaultProvider}>
            {map(Object.keys(MappingProvider), (providerKey: keyof typeof MappingProvider) => (
              <MenuItem key={providerKey} value={MappingProvider[providerKey]}>
                {MappingProvider[providerKey]}
              </MenuItem>
            ))}
          </Select>
          <FormFormLabel>Mapping</FormFormLabel>
          <Stack direction={'row'} spacing={1}>
            <TextField
              sx={{ flexGrow: 1 }}
              size='small'
              {...register(MappingFormSchemaNames.teamIdProvider)}
              error={!!errors[MappingFormSchemaNames.teamIdProvider]}
              helperText={
                errors[MappingFormSchemaNames.teamIdProvider] && (
                  <>{errors[MappingFormSchemaNames.teamIdProvider].message}</>
                )
              }
            />
            {Boolean(teamIdProvider) && Boolean(findTeamProviderMapping(team, defaultProvider)) && (
              <Button size={'small'} variant={'contained'} color={'error'} onClick={() => setIsRemoveModalOpen(true)}>
                Remove
              </Button>
            )}
          </Stack>
        </Stack>
        {isOverwriteModalOpen ? (
          <ConfirmPopoverDialog
            anchorEl={formRef.current}
            cancelLabel={'Cancel'}
            confirmLabel={'Overwrite'}
            description={'This action will overwrite the existing mapping'}
            isOpen={isOverwriteModalOpen}
            onConfirm={() => formRef.current?.requestSubmit()}
            setIsOpen={setIsOverwriteModalOpen}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          />
        ) : null}
        {isRemoveModalOpen ? (
          <ConfirmPopoverDialog
            anchorEl={formRef.current}
            cancelLabel={'Cancel'}
            confirmLabel={'Remove'}
            description={'This action will remove the existing mapping'}
            isOpen={isRemoveModalOpen}
            onConfirm={() => editTeamAction(team.id, { provider: defaultProvider, team_id_provider: '' })}
            setIsOpen={setIsRemoveModalOpen}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
          />
        ) : null}
      </form>
    </DialogNew>
  );
};
