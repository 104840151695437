import mapValues from 'lodash/mapValues';

import { State } from 'shared/hooks/use-url-filters/types/state';
import { FiltersList } from 'shared/types';

export const convertSearchParamsToFilters = <T extends FiltersList>(
  searchParams: Record<string, string>,
  possibleFilters: T,
): State<T> => {
  return mapValues(possibleFilters, (_, key) => {
    return typeof key === 'string' ? searchParams[key]?.split(',') ?? [] : [];
  });
};
