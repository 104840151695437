import {
  CSS_HOME_TEAM_COLOR,
  CSS_OPPONENT_TEAM_COLOR,
} from 'pages/tactical-analysis/components/timeline/timeline-table/components/timeline-css-variables';
import { getCssVariable } from 'shared/utils/get-css-variables';

export const useTeamColors = () => {
  return {
    home: getCssVariable(CSS_HOME_TEAM_COLOR),
    opponent: getCssVariable(CSS_OPPONENT_TEAM_COLOR),
  };
};
