import { Box, GlobalStyles, SvgIcon } from '@mui/material';
import { delay } from 'lodash';
import { useEffect, useState } from 'react';

import { KogniaLogoAnimatedStyled } from 'shared/components/kognia-logo-animated/KogniaLogoAnimated.styled';

const KOGNIA_LOGO_ANIMATED_CLASS_NAME = 'kognia-logo-animated';
const KOGNIA_LOGO_HOVER_CLASS_NAME = 'kognia-logo-hover';

export const KogniaLogoAnimated = () => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  useEffect(() => {
    if (isHovered) {
      delay(() => {
        setIsHovered(false);
      }, 200);
    }
  }, [isHovered]);

  return (
    <Box>
      <GlobalStyles styles={KogniaLogoAnimatedStyled} />
      <SvgIcon
        viewBox={'45 50 160 160'}
        sx={{ width: '120px', height: '92px' }}
        className={
          isHovered
            ? `${KOGNIA_LOGO_ANIMATED_CLASS_NAME} ${KOGNIA_LOGO_HOVER_CLASS_NAME}`
            : `${KOGNIA_LOGO_ANIMATED_CLASS_NAME}`
        }
        onMouseEnter={handleMouseEnter}
      >
        <g id='circleBottomInner' transform='translate(150.149101,156.153049)'>
          <g id='circleBottomInner2' transform='scale(0,0)'>
            <path
              d='M109.599,99.2031c0,5.6999-4.7,10.3999-10.3998,10.3999-5.8,0-10.5-4.7-10.5-10.3999c0-5.8,4.7-10.5,10.5-10.5c5.6998,0,10.3998,4.7,10.3998,10.5Z'
              transform='translate(-99.149101,-99.153049)'
              fill='#4fe0dd'
            />
          </g>
        </g>
        <g id='circleBottomOuter' transform='translate(150.149101,156.153049) scale(-0.026269,-0.026269)'>
          <path
            id='circleBottomOuterPath'
            d='M109.599,99.2031c0,5.6999-4.7,10.3999-10.3998,10.3999-5.8,0-10.5-4.7-10.5-10.3999c0-5.8,4.7-10.5,10.5-10.5c5.6998,0,10.3998,4.7,10.3998,10.5Z'
            transform='translate(-99.149101,-99.153049)'
            fill='none'
            stroke='#101136'
          />
        </g>
        <g id='circleRightInner' transform='translate(194.348,108.9)'>
          <g id='circleRightInner2' transform='scale(0.030924,0.030924)'>
            <path
              d='M154.798,51.9c0,5.8-4.7,10.4-10.4,10.4-5.8,0-10.5-4.6-10.5-10.4s4.7-10.4,10.5-10.4c5.7,0,10.4,4.6,10.4,10.4Z'
              transform='translate(-144.348,-51.9)'
              fill='#4e3de9'
            />
          </g>
        </g>
        <g id='circleRightOuter' transform='translate(194.348,108.9) scale(0.030916,0.030916)'>
          <path
            id='circleRightOuterPath'
            d='M154.798,51.9c0,5.8-4.7,10.4-10.4,10.4-5.8,0-10.5-4.6-10.5-10.4s4.7-10.4,10.5-10.4c5.7,0,10.4,4.6,10.4,10.4Z'
            transform='translate(-144.348,-51.9)'
            fill='none'
            stroke='#101136'
            strokeWidth='0.5'
          />
        </g>
        <g id='circleLeftInner' transform='translate(96.9508,103.946898) scale(0,0)'>
          <path
            d='M73.1008,46.8969c0,14.5-11.7,26.2-26.2,26.2-14.4,0-26.1-11.7-26.1-26.2c0-14.4,11.7-26.1,26.1-26.1c14.5,0,26.2,11.7,26.2,26.1Z'
            transform='translate(-46.9508,-46.946898)'
            fill='#085eff'
          />
        </g>
        <g id='circleLeftOuter' transform='translate(96.9508,103.946898) scale(-0.001604,-0.001604)'>
          <path
            id='circleLeftOuterPath'
            d='M73.1008,46.8969c0,14.5-11.7,26.2-26.2,26.2-14.4,0-26.1-11.7-26.1-26.2c0-14.4,11.7-26.1,26.1-26.1c14.5,0,26.2,11.7,26.2,26.1Z'
            transform='translate(-46.9508,-46.946898)'
            fill='none'
            stroke='#101136'
          />
        </g>
        <path
          d='M146.1,52.4L52.4,146.3h-52.4v-52.6L93.7,0h52.4v52.4ZM94.9,3L3,94.8v48.4h48.2l91.9-92.1v-48.1h-48.2Z'
          transform='translate(50 57)'
          clipRule='evenodd'
          fill='#101136'
          fillRule='evenodd'
        />
        <path
          d='M52.2,26.1L0,78.5L0,0h52.2v26.1ZM3,3v68.1L49.1,24.8v-21.8L3,3Z'
          transform='translate(50 57)'
          clipRule='evenodd'
          fill='#101136'
          fillRule='evenodd'
        />
        <path
          d='M146.098,93.8984v52.1996h-52.1996l-47-46.8996l52.3001-52.3l46.8995,47Zm-94.8995,5.3L95.0984,143.098h47.8996v-47.8996l-43.8996-43.9-47.8999,47.9Z'
          transform='translate(50 57)'
          clipRule='evenodd'
          fill='#101136'
          fillRule='evenodd'
        />
      </SvgIcon>
    </Box>
  );
};
