import { Stack, Typography } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';
import { useTranslation } from 'react-i18next';

import { PlaylistItemsListEmptyImg } from 'entities/playlist/ui/PlaylistItemsListEmptyImg';
import NotFoundImg from 'shared/assets/cameras.svg';

export const PlaylistItemsEmpty = () => {
  const { t } = useTranslation();

  return (
    <Stack justifyContent='center' direction='row' alignItems='center' spacing={2} height='100%'>
      <PlaylistItemsListEmptyImg src={NotFoundImg} alt='' />

      <Stack spacing={0.5}>
        <Typography fontWeight={fontWeight['500']}>{t('playlist-detail:not-found.header')}</Typography>
        <Typography fontWeight={fontWeight['500']} fontSize={fontSizes.default} color={Colors.storm}>
          {t('playlist-detail:not-found.description')}
        </Typography>
      </Stack>
    </Stack>
  );
};
