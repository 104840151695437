import { Row } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/row';
import { TimelineTableBlock } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/timeline';
import { generateEpisodeClips } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/generateEpisodeClips';

export const generateEpisodesRows = (timelineTableBlocks: TimelineTableBlock[], recordingId: string): Row => {
  return {
    id: `${recordingId}-episodes`,
    type: 'episodes',
    title: 'episode',
    isHidden: false,
    clips: generateEpisodeClips({ timelineTableBlocks, clipType: 'episode', rowId: 'episodes' }),
  };
};
