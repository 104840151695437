import { PlayingModes, PlaylistItemType } from 'shared/components/video-player/types';

interface GeneratePlaylistItemOptions {
  playlistItem: PlaylistItemType;
  duration: number;
  startTime: number;
  endTime: number;
}

export const generateTrimmedPlaylistItem = ({
  playlistItem,
  duration,
  startTime,
  endTime,
}: GeneratePlaylistItemOptions) => {
  return {
    ...playlistItem,
    duration,
    videoTypes: playlistItem.videoTypes.map((videoType) => {
      if (videoType.playingMode.mode !== PlayingModes.TACTICAL_CAMERA) {
        return videoType;
      }

      return {
        ...videoType,
        videoSources: [{ ...videoType.videoSources[0], startTime, endTime }],
      };
    }),
  };
};
