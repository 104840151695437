import { Typography } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';

type MatchDayInfoProps = {
  matchDay: string;
};

const MatchDayInfo = ({ matchDay }: MatchDayInfoProps) => {
  if (!matchDay) return null;

  return (
    <Typography fontSize={fontSizes.small} color={Colors.storm}>
      {matchDay}
    </Typography>
  );
};

export default MatchDayInfo;
