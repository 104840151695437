import { Box } from '@mui/material';
import React, { useMemo } from 'react';

import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import {
  EffectiveTimeSeparatorsWrapper,
  SeparatorContainer,
  SeparatorContent,
} from 'pages/tactical-analysis/components/timeline/effective-time-separators/EffectiveTimeSeparator.styled';
import { CSS_CLIP_ZOOM_WIDTH } from 'pages/tactical-analysis/components/timeline/timeline-table/components/timeline-css-variables';
import { useVideoPlayerPlayingMode } from 'shared/components/video-player';

type Props = {
  episodesClips: Clip[];
};

const Separator = ({ clip, isHidden }: { clip: Clip; isHidden: boolean }) => {
  return (
    <SeparatorContainer key={clip.id} isHalfTime={Boolean(clip.title)} isHidden={isHidden}>
      <SeparatorContent key={clip.id}>{clip.title}</SeparatorContent>
    </SeparatorContainer>
  );
};

const EmptySeparator = ({ clip }: { clip: Clip }) => {
  const emptyStyle = useMemo(
    () => ({ width: `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.endTime - clip.startTime})` }),
    [clip],
  );

  return <Box key={clip.id} sx={{ height: 0, background: 'transparent' }} style={emptyStyle} />;
};

export const EffectiveTimeSeparators = React.memo(({ episodesClips }: Props) => {
  const { useEffectiveTime: effectiveTime } = useVideoPlayerPlayingMode();

  return effectiveTime ? (
    <EffectiveTimeSeparatorsWrapper>
      {episodesClips.map((clip, idx) =>
        clip.type === 'not-effective-time' ? (
          <Separator key={clip.id} clip={clip} isHidden={idx === 0} />
        ) : (
          <EmptySeparator key={clip.id} clip={clip} />
        ),
      )}
    </EffectiveTimeSeparatorsWrapper>
  ) : null;
});

EffectiveTimeSeparators.displayName = 'EffectiveTimeSeparators';
