import { Season, SeasonSummary } from 'pages/backoffice/types/seasons';

export const printSeasonName = (season: Season) => {
  if (season.competition) {
    return `${season.competition.name} ${season.year.toString()}${
      season.crossesYear ? `-${(season.year + 1).toString().slice(2)}` : ''
    } (${season.competition.country})`;
  }

  return ` ${season.year.toString()}${season.crossesYear ? `-${(season.year + 1).toString().slice(2)}` : ''}`;
};

export const printSummarySeasonName = (season: SeasonSummary) => {
  return ` ${season.year.toString()}${season.crossesYear ? `-${(season.year + 1).toString().slice(2)}` : ''}`;
};
