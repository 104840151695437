import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import TacticsNotFoundImg from 'pages/recordings-list/components/recordings-list/not-found/assets/tactics-not-found.svg';
import styles from 'pages/recordings-list/components/recordings-list/not-found/NotFound.module.scss';
import RecordingCardLoading from 'pages/recordings-list/components/vod-recording-card/loading';

interface RecordingsListNotFoundProps {
  onNotFoundClick?: () => void;
}

const NotFound = ({ onNotFoundClick }: RecordingsListNotFoundProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className={styles['recordings-list--not-found']}>
      <RecordingCardLoading isAnimated={false} />
      <RecordingCardLoading isAnimated={false} />
      <RecordingCardLoading isAnimated={false} />
      <RecordingCardLoading isAnimated={false} />
      <div className={styles['recordings-list__not-found-text']}>
        <img src={TacticsNotFoundImg} alt='' />
        <h5 className={styles['recordings-list__not-found-title']}>{t('recordings-list:not-found.header')}</h5>
        <p>{t('recordings-list:not-found.description')}</p>
        <Button
          variant='contained'
          onClick={() => {
            onNotFoundClick && onNotFoundClick();
          }}
          sx={{ mt: 1 }}
        >
          {t('recordings-list:not-found.try-another-combination')}
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
