import { z } from 'zod';

const DashboardPlaylistItemSchema = z.object({
  start_frame: z.number(),
  end_frame: z.number(),
  recording_id: z.string(),
  name: z.string(),
});

export const DashboardPlaylistGenericPayloadSchema = z.object({
  name: z.string(),
  items: z.array(DashboardPlaylistItemSchema),
});

export type DashboardPlaylistGenericPayload = z.infer<typeof DashboardPlaylistGenericPayloadSchema>;
