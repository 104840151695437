import { z } from 'zod';

export enum FilesChyronHegoSetOneFieldsNames {
  srmlXml = 'srml_xml',
  metadataXml = 'metadata_xml',
  trackingDat = 'tracking_dat',
}

export enum FilesChyronHegoSetTwoFieldsNames {
  matchInfoXml = 'match_info_xml',
  positionsXml = 'positions_xml',
}

export enum FilesHawkeyeFieldsNames {
  centroidsFolder = 'centroids_folder',
  ballFolder = 'ball_folder',
}

export enum FilesSecondSpectrumFieldsNames {
  dataXml = 'data_xml',
  metadataXml = 'metadata_xml',
  physicalSummaryCsv = 'physical_summary_csv',
  dataJsonl = 'data_jsonl',
  metadataJson = 'metadata_json',
}

export enum FilesSignalityFieldsNames {
  tracking1stJson = 'tracking_1st_json',
  tracking2ndJson = 'tracking_2nd_json',
  metadataJson = 'metadata_json',
}

export enum FilesOptaFieldsNames {
  f7Xml = 'f7_xml',
  f24Xml = 'f24_xml',
}

export enum FilesBundesligaFieldsNames {
  eventsXml = 'events_xml',
}

export enum SportlogiqTrackingType {
  FIXTURE_TRACKING_DATA = 'gameTrackingData',
  RAW_FIXTURE_TRACKING_DATA = 'rawGameTrackingData',
}

export interface FilesHawkeyeForm {
  [FilesHawkeyeFieldsNames.centroidsFolder]?: string;
  [FilesHawkeyeFieldsNames.ballFolder]?: string;
}

export const filesHawkeyeSchema = z.object({
  [FilesHawkeyeFieldsNames.centroidsFolder]: z.string().optional(),
  [FilesHawkeyeFieldsNames.ballFolder]: z.string().optional(),
});

export interface FilesChyronhegoSetOneForm {
  [FilesChyronHegoSetOneFieldsNames.srmlXml]?: string;
  [FilesChyronHegoSetOneFieldsNames.metadataXml]?: string;
  [FilesChyronHegoSetOneFieldsNames.trackingDat]?: string;
}

export const filesChyronHegoSetOneSchema = z.object({
  [FilesChyronHegoSetOneFieldsNames.srmlXml]: z.string().optional(),
  [FilesChyronHegoSetOneFieldsNames.metadataXml]: z.string().optional(),
  [FilesChyronHegoSetOneFieldsNames.trackingDat]: z.string().optional(),
});

export interface FilesChyronHegoSetTwoForm {
  [FilesChyronHegoSetTwoFieldsNames.matchInfoXml]?: string;
  [FilesChyronHegoSetTwoFieldsNames.positionsXml]?: string;
}

export const filesChyronHegoSetTwoSchema = z.object({
  [FilesChyronHegoSetTwoFieldsNames.matchInfoXml]: z.string().optional(),
  [FilesChyronHegoSetTwoFieldsNames.positionsXml]: z.string().optional(),
});

export interface FilesSecondSpectrumSetOneForm {
  [FilesSecondSpectrumFieldsNames.dataXml]?: string;
  [FilesSecondSpectrumFieldsNames.metadataXml]?: string;
  [FilesSecondSpectrumFieldsNames.physicalSummaryCsv]?: string;
}

export const filesSecondSpectrumSetOneSchema = z.object({
  [FilesSecondSpectrumFieldsNames.dataXml]: z.string().optional(),
  [FilesSecondSpectrumFieldsNames.metadataXml]: z.string().optional(),
  [FilesSecondSpectrumFieldsNames.physicalSummaryCsv]: z.string().optional(),
});

export interface FilesSecondSpectrumSetTwoForm {
  [FilesSecondSpectrumFieldsNames.dataXml]?: string;
  [FilesSecondSpectrumFieldsNames.metadataXml]?: string;
  [FilesSecondSpectrumFieldsNames.physicalSummaryCsv]?: string;
}

export const filesSecondSpectrumSetTwoSchema = z.object({
  [FilesSecondSpectrumFieldsNames.dataJsonl]: z.string().optional(),
  [FilesSecondSpectrumFieldsNames.metadataJson]: z.string().optional(),
});

export interface FilesSignalityForm {
  [FilesSignalityFieldsNames.tracking1stJson]?: string;
  [FilesSignalityFieldsNames.tracking2ndJson]?: string;
  [FilesSignalityFieldsNames.metadataJson]?: string;
}

export const filesSignalitySchema = z.object({
  [FilesSignalityFieldsNames.tracking1stJson]: z.string().optional(),
  [FilesSignalityFieldsNames.tracking2ndJson]: z.string().optional(),
  [FilesSignalityFieldsNames.metadataJson]: z.string().optional(),
});

export interface FilesOptaForm {
  [FilesOptaFieldsNames.f7Xml]?: string;
  [FilesOptaFieldsNames.f24Xml]?: string;
}

export const filesOptaSchema = z.object({
  [FilesOptaFieldsNames.f7Xml]: z.string().optional(),
  [FilesOptaFieldsNames.f24Xml]: z.string().optional(),
});

export interface FilesBundesligaForm {
  [FilesBundesligaFieldsNames.eventsXml]?: string;
}

export const filesBundesligaSchema = z.object({
  [FilesBundesligaFieldsNames.eventsXml]: z.string().optional(),
});
