import { RefObject, useCallback, useEffect } from 'react';

interface Props {
  recordingId: string;
  visibleContentRef: RefObject<HTMLDivElement>;
  headerRef: RefObject<HTMLDivElement>;
}

export const getCenterToPlayHeadLeftPosition = (playHeadPosition: number, clientWidth: number) => {
  const centeredPosition = playHeadPosition - clientWidth / 2;
  if (centeredPosition < 0) return 0;

  return centeredPosition;
};

export const useSyncRowContent = ({ visibleContentRef, headerRef }: Props) => {
  // Synchronize scroll position of timeline with scroll controller
  const handleSyncScroll = useCallback(() => {
    if (!visibleContentRef.current || !headerRef.current) return;

    headerRef.current.scrollTop = visibleContentRef.current.scrollTop;
  }, [headerRef, visibleContentRef]);

  const handleWheelScroll = useCallback(
    (event: WheelEvent) => {
      if (!visibleContentRef.current || !headerRef.current) return;
      const visibleContent = visibleContentRef.current;
      const header = headerRef.current;

      const maxVisibleContentScroll = visibleContent.scrollHeight - visibleContent.offsetHeight;

      visibleContent.scrollTop =
        maxVisibleContentScroll < visibleContent.scrollTop + event.deltaY && event.deltaY < 0
          ? maxVisibleContentScroll
          : visibleContent.scrollTop + event.deltaY;

      header.scrollTop = visibleContent.scrollTop;
    },
    [visibleContentRef, headerRef],
  );

  useEffect(() => {
    if (!visibleContentRef.current || !headerRef.current) return;
    const visibleContent = visibleContentRef.current;
    const header = headerRef.current;

    visibleContent.addEventListener('scroll', handleSyncScroll);
    header.addEventListener('wheel', handleWheelScroll);
    return () => {
      visibleContent.removeEventListener('scroll', handleSyncScroll);
      header.removeEventListener('wheel', handleWheelScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerRef.current, visibleContentRef.current, handleSyncScroll]);

  // Add event listeners for resizing and scrolling
  useEffect(() => {
    if (!visibleContentRef.current || !visibleContentRef.current) return;

    const container = visibleContentRef.current;

    container.addEventListener('scroll', handleSyncScroll);

    // Clean up function
    return () => {
      container.removeEventListener('scroll', handleSyncScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSyncScroll, visibleContentRef.current]);

  return null;
};
