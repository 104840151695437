import { QueryCache, QueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';

export const apiHost = '/api';

export const DEFAULT_VIDEO_TYPE = 'application/dash+xml';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      if (query.meta?.onError && typeof query.meta.onError === 'function') {
        query.meta?.onError(error);
      }
      if (query.meta?.errorMessage && typeof query.meta?.errorMessage === 'string') {
        enqueueSnackbar(query.meta.errorMessage, { variant: 'error' });
      }
    },
    onSuccess: (data, query) => {
      if (query.meta?.onSuccess && typeof query.meta.onSuccess === 'function') {
        query.meta?.onSuccess(data);
      }
      if (query.meta?.successMessage && typeof query.meta.successMessage === 'string') {
        enqueueSnackbar(query.meta.successMessage, { variant: 'success' });
      }
    },
    onSettled: (data, error, query) => {
      if (query.meta?.onSettled && typeof query.meta.onSettled === 'function') {
        query.meta?.onSettled();
      }
      if (query.meta?.settledMessage && typeof query.meta.settledMessage === 'string') {
        enqueueSnackbar(query.meta.settledMessage);
      }
    },
  }),
});
