import { useCallback, useEffect, useMemo } from 'react';

import { createActions } from 'shared/components/video-player/state/hooks/utils';
import { Send } from 'shared/components/video-player/state/types';
import { PlayerStateMachineContext } from 'shared/components/video-player/types';
import { checkIsFinishedPlayingCurrentPlaylistItem } from 'shared/components/video-player/util';
import { useThrottle } from 'shared/hooks/use-throttle/useThrottle';
import { getIsFormTag } from 'shared/utils/is-form-tag';

const CONTROLS_THROTTLE_TIME = 10;

export const useGenerateVideoPlayerActions = (context: PlayerStateMachineContext, send: Send) => {
  const actions = useMemo(() => createActions(send), [send]);

  const throttlePlay = useThrottle(actions.play, { wait: CONTROLS_THROTTLE_TIME });

  const throttlePause = useThrottle(actions.pause, { wait: CONTROLS_THROTTLE_TIME });

  const throttlePreviousPlaylistItem = useThrottle(actions.previousPlaylistItem, { wait: CONTROLS_THROTTLE_TIME });

  const throttleNextPlaylistItem = useThrottle(actions.nextPlaylistItem, { wait: CONTROLS_THROTTLE_TIME });

  const throttlePreviousVideoSource = useThrottle(actions.previousVideoSource, { wait: CONTROLS_THROTTLE_TIME });

  const throttleNextVideoSource = useThrottle(actions.nextVideoSource, { wait: CONTROLS_THROTTLE_TIME });

  const throttleRemovePlaylistItem = useThrottle(
    (id: string) => {
      actions.removePlaylistItem(id);
    },
    { wait: CONTROLS_THROTTLE_TIME },
  );

  const throttleTogglePlaying = useThrottle(
    () => {
      checkIsFinishedPlayingCurrentPlaylistItem(context.playlist, context.videoRef)
        ? actions.setPlaylistItem(context.playlist.currentPlaylistItemId, true)
        : actions.togglePlaying();
    },
    { wait: CONTROLS_THROTTLE_TIME },
  );

  const throttleSkipForward5s = useThrottle(actions.skipForward5s, { wait: CONTROLS_THROTTLE_TIME });

  const throttleSkipBackward5s = useThrottle(actions.skipBackward5s, { wait: CONTROLS_THROTTLE_TIME });

  const throttleJumpToTimeInMatch = useThrottle(
    (time: number) => {
      actions.jumpToMatchTime(time);
    },
    { wait: CONTROLS_THROTTLE_TIME },
  );

  const throttleJumpToTimePercentage = useThrottle(
    (percent: number) => {
      actions.jumpToTimePercent(percent);
    },
    { wait: CONTROLS_THROTTLE_TIME },
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const isFormTag = getIsFormTag((event.target as HTMLElement).tagName);
      if (isFormTag) return;

      if (event.key === ' ') throttleTogglePlaying();
      if (event.key === 'ArrowRight') {
        event.ctrlKey ? throttleNextVideoSource() : throttleSkipForward5s();
      }
      if (event.key === 'ArrowLeft') {
        event.ctrlKey ? throttlePreviousVideoSource() : throttleSkipBackward5s();
      }
    },
    [
      throttlePreviousVideoSource,
      throttleNextVideoSource,
      throttleSkipBackward5s,
      throttleSkipForward5s,
      throttleTogglePlaying,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return useMemo(
    () => ({
      ...actions,
      jumpToTimeInMatch: throttleJumpToTimeInMatch,
      jumpToTimePercent: throttleJumpToTimePercentage,
      nextPlaylistItem: throttleNextPlaylistItem,
      nextVideoSource: throttleNextVideoSource,
      pause: throttlePause,
      play: throttlePlay,
      previousPlaylistItem: throttlePreviousPlaylistItem,
      previousVideoSource: throttlePreviousVideoSource,
      removePlaylistItem: throttleRemovePlaylistItem,
    }),
    [
      actions,
      throttleJumpToTimeInMatch,
      throttleJumpToTimePercentage,
      throttleNextPlaylistItem,
      throttleNextVideoSource,
      throttlePause,
      throttlePlay,
      throttlePreviousPlaylistItem,
      throttlePreviousVideoSource,
      throttleRemovePlaylistItem,
    ],
  );
};
