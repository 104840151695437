import { Fade } from '@mui/material';
import React, { useRef } from 'react';

import {
  useCurrentVideoSourceDuration,
  useCurrentVideoSourceTime,
  usePlayerCurrentSource,
} from 'shared/components/video-player/hooks';
import { useIsBuffering, useIsSeeking, useVideoPlayerActions } from 'shared/components/video-player/index';
import styles from 'shared/components/video-player/video-player-component/components/progress-bar-video-source/ProgressBar.module.scss';

const ProgressBarVideoSource = () => {
  const progressBarRef = useRef<HTMLDivElement>(null);
  const duration = useCurrentVideoSourceDuration();
  const currentTime = useCurrentVideoSourceTime();
  const isBuffering = useIsBuffering();
  const isSeeking = useIsSeeking();
  const actions = useVideoPlayerActions();
  const videoSource = usePlayerCurrentSource();

  const handleJumpToPercent = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    if (!progressBarRef.current) return;

    const clientRect = progressBarRef.current.getBoundingClientRect();
    const pageX = event.pageX;
    const clickPosition = pageX - clientRect.x;
    const percentPositionClick = (100 * clickPosition) / progressBarRef.current.clientWidth;

    const endTimeInMatch = videoSource?.endTimeInMatch ?? 0;
    const startTimeInMatch = videoSource?.startTimeInMatch ?? 0;

    const timeInMatch = ((endTimeInMatch - startTimeInMatch) * percentPositionClick) / 100 + startTimeInMatch;
    actions.jumpToTimeInMatch(timeInMatch);
  };

  const showBuffering = isBuffering || isSeeking;

  return (
    <div className={styles.container} ref={progressBarRef} onClick={handleJumpToPercent}>
      <div className={styles.content}>
        <Fade unmountOnExit in={showBuffering} exit={!showBuffering}>
          <div className={styles.buffering} />
        </Fade>
        <div className={styles.bar} style={{ width: `${(100 * (currentTime - videoSource.startTime)) / duration}%` }} />
      </div>
    </div>
  );
};

export default ProgressBarVideoSource;
