import { StyledEngineProvider, ThemeProvider } from '@mui/material';
import { theme } from 'kognia-ui';

interface Props {
  children: React.ReactNode;
}

export const AppTheme = ({ children }: Props) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};
