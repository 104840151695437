import { useEffect, useRef, useState } from 'react';

enum ActionType {
  SUBSCRIBE,
  UNSUBSCRIBE,
  CURRENT,
  NEXT,
  STOP,
}

type Observer<T> = (value: T) => void;
type Setter<T> = (oldValue: T) => T;

type Action<T> =
  | { type: ActionType.SUBSCRIBE; observer: Observer<T> }
  | { type: ActionType.UNSUBSCRIBE; observer: Observer<T> }
  | { type: ActionType.CURRENT }
  | { type: ActionType.NEXT; setter: Setter<T> }
  | { type: ActionType.STOP };

// eslint-disable-next-line @typescript-eslint/ban-types
function* gen<T extends object>(initialValue: T): Generator<T, T, Action<T>> {
  let run = true;

  let observers: Array<Observer<T>> = [];
  let value: T = initialValue;

  while (run) {
    const res = yield value;

    if (res.type === ActionType.SUBSCRIBE) {
      observers.push(res.observer);
    } else if (res.type === ActionType.UNSUBSCRIBE) {
      observers = observers.filter((o) => o !== res.observer);
    } else if (res.type === ActionType.NEXT) {
      value = { ...res.setter(value) };
      setTimeout(() => {
        observers.forEach((o) => {
          o(value);
        });
      }, 0);
    } else if (res.type === ActionType.CURRENT) {
      // no-op
    } else if (res.type === ActionType.STOP) {
      run = false;
    }
  }

  return value;
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function makeContainer<T extends object>(initialValue: T) {
  const gen$ = gen<T>(initialValue);

  const getValue = (): T => {
    return gen$.next({ type: ActionType.CURRENT }).value;
  };

  const nextValue = (setter: Setter<T>) => {
    gen$.next({ type: ActionType.NEXT, setter });
  };

  const useContainer = (): [T, (setter: Setter<T>) => void] => {
    const mountedRef = useRef<boolean>(false);
    const [state, setState] = useState<T>(getValue);

    useEffect(() => {
      mountedRef.current = true;

      const observer: Observer<T> = (next: T) => {
        if (!mountedRef.current) {
          return;
        }
        setState(next);
      };

      gen$.next({
        type: ActionType.SUBSCRIBE,
        observer,
      });

      return () => {
        mountedRef.current = false;

        gen$.next({
          type: ActionType.UNSUBSCRIBE,
          observer,
        });
      };
    }, []);

    return [state, nextValue];
  };

  return { getValue, nextValue, useContainer };
}
