import mapValues from 'lodash/mapValues';

import { State } from 'shared/hooks/use-url-filters/types/state';
import { FiltersList } from 'shared/types';
import { entriesFromObject } from 'shared/utils/objectToArray';

export const generateAppliedFilters = <T extends FiltersList>(appliedFiltersState: State<T>, filters: T) => {
  return entriesFromObject(filters).reduce<T>((acc, [key, value]) => {
    acc[key] = {
      ...value,
      options: mapValues(value.options, (option, optionKey) => ({
        ...option,
        isApplied: appliedFiltersState[key].includes(optionKey),
      })),
    };

    return acc;
  }, {} as T);
};
