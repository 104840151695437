import { zodResolver } from '@hookform/resolvers/zod';
import { Stack, FormHelperText } from '@mui/material';
import { forwardRef, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { useRemoveUsersFromEnvironments } from 'api/backoffice/user/use-remove-users-from-environments/useRemoveUsersFromEnvironments';
import { SelectClients } from 'pages/backoffice/components/select-clients';
import { SelectUsers } from 'pages/backoffice/components/select-users';
import { createPluralValue } from 'pages/backoffice/utils/createPluralValue';
import {
  UsersToEnvironmentsForm,
  UsersToEnvironmentsFormFieldsNames,
  usersToEnvironmentsFormSchema,
  UsersToEnvironmentsFormSchema,
} from 'pages/backoffice/utils/users-to-environments-form-schema/usersToEnvironmentsFormSchema';
import ConfirmDialog from 'shared/components/confirm-dialog';

interface Props {
  onSuccess: () => void;
  selectedUsers: KogniaUser[];
}

export const RemoveUsersFromEnvironmentsFormWithConfirm = forwardRef(
  ({ onSuccess, selectedUsers }: Props, ref: React.LegacyRef<HTMLFormElement> | undefined) => {
    const { removeUsersFromEnvironments } = useRemoveUsersFromEnvironments();
    const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState<boolean>(false);
    const {
      getValues,
      control,
      handleSubmit: handleFormSubmit,
      watch,
    } = useForm<UsersToEnvironmentsFormSchema>({
      defaultValues: {
        [UsersToEnvironmentsFormFieldsNames.ENVIRONMENT_IDS]: [],
        [UsersToEnvironmentsFormFieldsNames.KOGNIA_USERS]: selectedUsers,
      },
      resolver: zodResolver(usersToEnvironmentsFormSchema),
    });

    const { environmentIds: selectedEnvironments, kogniaUsers: selectedKogniaUsers } = watch();

    const handleRemoveUsersFromEnvironments = () => {
      setIsOpenConfirmDialog(true);
    };

    const handleConfirm = useCallback(() => {
      const data: UsersToEnvironmentsForm = getValues();
      removeUsersFromEnvironments({
        clientIds: data[UsersToEnvironmentsFormFieldsNames.ENVIRONMENT_IDS],
        kogniaUsers: data[UsersToEnvironmentsFormFieldsNames.KOGNIA_USERS],
        onSuccess,
      });
    }, [removeUsersFromEnvironments, getValues, onSuccess]);

    return (
      <>
        <form ref={ref} onSubmit={handleFormSubmit(handleRemoveUsersFromEnvironments)}>
          <Stack direction='column' spacing={2}>
            <Controller
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <>
                  <SelectUsers kogniaUsers={value} setKogniaUsersOnChange={onChange} />
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </>
              )}
              name={UsersToEnvironmentsFormFieldsNames.KOGNIA_USERS}
              control={control}
            />

            <Controller
              render={({ field: { value, onChange }, fieldState: { error } }) => (
                <>
                  <SelectClients clientIds={value} users={selectedKogniaUsers} onChange={onChange} />
                  {error && <FormHelperText error>{error.message}</FormHelperText>}
                </>
              )}
              name={UsersToEnvironmentsFormFieldsNames.ENVIRONMENT_IDS}
              control={control}
            />
          </Stack>
        </form>

        <ConfirmDialog
          type={'primary'}
          title={`Are you sure you want to remove ${createPluralValue(selectedKogniaUsers, 'user')} (${
            selectedKogniaUsers.length
          }) from ${createPluralValue(selectedEnvironments, 'environment')} (${selectedEnvironments.length})?`}
          cancelLabel={'Cancel'}
          confirmLabel={'Confirm'}
          onConfirm={handleConfirm}
          isOpen={isOpenConfirmDialog}
          setIsOpen={setIsOpenConfirmDialog}
        />
      </>
    );
  },
);

RemoveUsersFromEnvironmentsFormWithConfirm.displayName = 'RemoveUsersFromEnvironmentsFormWithConfirm';
