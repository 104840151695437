export enum TypeOfPlaySource {
  Individual = 'INDIVIDUAL',
  Collective = 'COLLECTIVE',
}

export enum TypeOfPlay {
  Offense = 'OFFENSE',
  Defense = 'DEFENSE',
  Transition = 'TRANSITION',
  GameEvent = 'GAME_EVENT',
  imported = 'imported',
}
