import { USER_PRESET_KEYS } from 'shared/constants/user-presets/userPresetsKeys';
import { UserPresetScope } from 'shared/types/user-preset/types';

export const timelinePlayingModePreset = {
  scope: UserPresetScope.timeline,
  key: USER_PRESET_KEYS.playingMode,
} as const;

export const timelineSelectedTacticsPreset = {
  scope: UserPresetScope.timeline,
  key: USER_PRESET_KEYS.selectedTactics,
} as const;

export const timelineZoomPreset = {
  scope: UserPresetScope.timeline,
  key: USER_PRESET_KEYS.zoomLevel,
} as const;

export const timelineHeightPreset = {
  scope: UserPresetScope.timeline,
  key: USER_PRESET_KEYS.height,
} as const;

export const timelinePinScenariosPreset = {
  scope: UserPresetScope.timeline,
  key: USER_PRESET_KEYS.pinScenarios,
} as const;

export const timelineHeadersWidthPreset = {
  scope: UserPresetScope.timeline,
  key: USER_PRESET_KEYS.headersWidth,
} as const;

export const timelineAppliedFiltersPreset = {
  scope: UserPresetScope.timeline,
  key: USER_PRESET_KEYS.timeLineAppliedFilters,
} as const;

// NOTE: not used in the app
export const timelineFiltersPreset = {
  scope: UserPresetScope.timeline,
  key: USER_PRESET_KEYS.filters,
};

export const timelineTimePreset = {
  scope: UserPresetScope.timeline,
  key: USER_PRESET_KEYS.time,
} as const;

export const timelineTeamIdFocus = {
  scope: UserPresetScope.timeline,
  key: USER_PRESET_KEYS.teamIdFocus,
} as const;

export const timelineShowBallPossession = {
  scope: UserPresetScope.timeline,
  key: USER_PRESET_KEYS.showBallPossession,
} as const;

export const timelineShowNoBallPossession = {
  scope: UserPresetScope.timeline,
  key: USER_PRESET_KEYS.showNoBallPossession,
} as const;
