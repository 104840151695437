import { Stack, Typography } from '@mui/material';
import { PlayerAvatar } from 'kognia-ui/components/avatar/PlayerAvatar';
import { TeamAvatar } from 'kognia-ui/components/avatar/TeamAvatar';

import { SearchResult } from 'entities/dashboard/types/dashboardSearch';
import { CountryFlag } from 'shared/components/CountryFlag';
import { CountryCode } from 'shared/utils/countries';

type Props = {
  searchResult: SearchResult;
};

export const DashboardSearchOptionType = ({ searchResult }: Props) => {
  if (searchResult.type === 'player') {
    return (
      <Stack id='top-stack' direction='row' alignItems='center' justifyContent={'space-between'} flex={1}>
        <Stack direction='row' alignItems='center' justifyContent={'left'} minWidth={'66%'} maxWidth={'100%'} gap={1}>
          <PlayerAvatar withBorder src={searchResult.photoUrl} size={'xsmall'} />
          <Typography
            variant='body2'
            component='div'
            whiteSpace='nowrap'
            overflow='hidden'
            textOverflow='ellipsis'
            title={searchResult.label}
          >
            {searchResult.label}
          </Typography>
        </Stack>

        {searchResult.extraInfo && (
          <Stack direction={'row'} alignItems={'center'} justifyContent={'left'} gap={1} width={'33%'}>
            <TeamAvatar src={searchResult.extraInfo.photoUrl} name={searchResult.extraInfo.label} size={'xxsmall'} />

            <Typography
              variant='caption'
              whiteSpace='nowrap'
              overflow='hidden'
              textOverflow='ellipsis'
              color='secondary'
              title={searchResult.extraInfo.label}
            >
              {searchResult.extraInfo.label}
            </Typography>
          </Stack>
        )}
      </Stack>
    );
  }

  if (searchResult.type === 'team') {
    return (
      <Stack id='top-stack' direction='row' alignItems='center' justifyContent={'space-between'} flex={1}>
        <Stack direction='row' alignItems='center' justifyContent={'left'} minWidth={'66%'} maxWidth={'100%'} gap={1}>
          <TeamAvatar src={searchResult.photoUrl} name={searchResult.label} size={'xsmall'} />
          <Typography variant='body2' whiteSpace='nowrap' overflow='hidden' textOverflow='ellipsis'>
            {searchResult.label}
          </Typography>
        </Stack>

        {searchResult.extraInfo && (
          <Stack direction={'row'} alignItems={'center'} justifyContent={'left'} gap={1} width={'33%'}>
            <CountryFlag countryCode={searchResult.extraInfo.value as CountryCode} />
            <Typography
              variant='caption'
              whiteSpace='nowrap'
              overflow='hidden'
              textOverflow='ellipsis'
              color='secondary'
              title={searchResult.extraInfo.label}
            >
              {searchResult.extraInfo.label}
            </Typography>
          </Stack>
        )}
      </Stack>
    );
  }

  return null;
};
