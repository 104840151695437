import { SvgIcon, type SvgIconProps } from '../components/SvgIcon';

export const IconDoubleArrowLeft = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        fillRule='evenodd'
        d='M12.952 5.535c.257.257.257.673 0 .929L7.417 12l5.535 5.535c.257.257.257.673 0 .929s-.673.257-.929 0l-5.999-5.999c-.257-.257-.257-.673 0-.929l5.999-5.999c.257-.257.673-.257.929 0zm5 0c.257.257.257.673 0 .929L12.417 12l5.535 5.535c.257.257.257.673 0 .929s-.673.257-.929 0l-5.999-5.999c-.123-.123-.192-.29-.192-.465s.069-.341.192-.465l5.999-5.999c.257-.257.673-.257.929 0z'
      />
    </SvgIcon>
  );
};
