import { Box, Button, Grid, Typography } from '@mui/material';
import classNames from 'classnames';
import { Colors } from 'kognia-ui';
import { IconDelete } from 'kognia-ui/icons/IconDelete';
import { IconEdit } from 'kognia-ui/icons/IconEdit';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { KeypadTag } from 'api/tagging-tool/types';
import styles from 'pages/tagging-tool-keypad-edit/components/EventButton/EventButton.module.scss';
import { ActionType, useEventButtonState } from 'pages/tagging-tool-keypad-edit/components/EventButton/state';
import { Dot } from 'shared/components/dot';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import Toggle from 'shared/components/toggle';
import { TypeOfPlay } from 'shared/types/type-of-play/types';

export type EventButtonProps = {
  data?: KeypadTag;
  className?: string;
  // color
  green?: boolean;
  purple?: boolean;
  orange?: boolean;
  // size
  mLong?: boolean;
  xlLong?: boolean;
  fullLong?: boolean;
  fullWidth?: boolean;
  flexLong?: boolean;
  flip?: boolean;
  // handlers
  onEdit?: (tag: KeypadTag) => void;
  onRemove?: (tag: KeypadTag) => void;
  onEnabledChange?: (tag: KeypadTag, enabled: boolean) => void;
};

export const EventButton = (props: EventButtonProps) => {
  const kebabRef = useRef<HTMLDivElement | null>(null);
  const { t } = useTranslation();
  const [state, dispatch] = useEventButtonState({
    enabled: props.data?.isEnabled ?? false,
  });

  // effects

  useEffect(() => {
    dispatch({
      type: ActionType.ENABLED,
      payload: props.data?.isEnabled ?? false,
    });
  }, [dispatch, props.data?.isEnabled]);

  // forward handlers
  const handleEnabledChange = useCallback(() => {
    if (!props.data) {
      return;
    }
    const isEnabled = !props.data?.isEnabled;
    props.onEnabledChange?.(props.data, isEnabled);
  }, [props]);

  const handleEdit = useCallback(() => {
    if (!props.data) {
      return;
    }
    dispatch({ type: ActionType.MORE, payload: false });
    props.onEdit?.(props.data);
  }, [props, dispatch]);

  const handleRemove = useCallback(() => {
    if (!props.data) {
      return;
    }
    dispatch({ type: ActionType.MORE, payload: false });
    props.onRemove?.(props.data);
  }, [props, dispatch]);

  const isGrey = !state.enabled;
  const isPurple =
    !isGrey &&
    props.green !== true &&
    props.orange !== true &&
    (props.purple === true || props.data?.typeOfPlay === TypeOfPlay.Offense);
  const isOrange =
    !isGrey &&
    props.green !== true &&
    props.purple !== true &&
    (props.orange === true || props.data?.typeOfPlay === TypeOfPlay.Transition);
  const isGreen =
    !isGrey &&
    props.purple !== true &&
    props.orange !== true &&
    (props.green === true || props.data?.typeOfPlay === TypeOfPlay.Defense);

  const menuOptions: MenuListOption[] = useMemo(
    () => [
      {
        displayText: t('tagging-tool:keypad.edit'),
        icon: <IconEdit size='small' color='secondary' />,
        onClick: handleEdit,
      },
      {
        avoidCloseOnClick: true,
        displayText: (
          <Box className={styles.toggleContainer}>
            {t('tagging-tool:keypad.enabled-in-real-time')}{' '}
            <Toggle checked={props.data?.isEnabled === true} onClick={() => {}} />
          </Box>
        ),
        onClick: handleEnabledChange,
      },
      {
        isHidden: !props.data?.isCustom,
        displayText: t('common:actions.remove'),
        icon: <IconDelete size='small' color='secondary' />,
        onClick: handleRemove,
      },
    ],
    [handleEdit, handleEnabledChange, handleRemove, props.data?.isCustom, props.data?.isEnabled, t],
  );

  return (
    <Grid item sx={{ position: 'relative' }} className={props.className}>
      <div className={styles.buttonContainer}>
        <Button
          disableRipple
          onClick={handleEdit}
          className={classNames(props.className, styles.eventButton, {
            [styles.default]:
              props.mLong !== true && props.xlLong !== true && props.flip !== true && props.flexLong !== true,
            [styles.mLong]: props.mLong,
            [styles.xlLong]: props.xlLong,
            [styles.fullLong]: props.fullLong,
            [styles.fullWidth]: props.fullWidth,
            [styles.flip]: props.flip,
            [styles.flexLong]: props.flexLong,
            [styles.purple]: isPurple,
            [styles.green]: isGreen,
            [styles.orange]: isOrange,
            [styles.grey]: isGrey,
          })}
        >
          <>
            {props.flip !== true && (
              <Box alignItems={'center'} sx={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
                <Dot color={state.enabled ? Colors.green : Colors.night} />
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: Colors.night,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {props.data?.name ?? ''}
                </Typography>
              </Box>
            )}
            {props.flip === true && (
              <div className={styles.eventTextContainer}>
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: Colors.night,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                  className={styles.eventText}
                >
                  {props.data?.name ?? ''}
                </Typography>
                <Dot color={state.enabled ? Colors.green : Colors.night} />
              </div>
            )}
          </>
        </Button>
        <Box className={styles.verticalMenu}>
          <KebabMenu ref={kebabRef} options={menuOptions} id={'tagging-tool-clip'} />
        </Box>
      </div>
    </Grid>
  );
};
