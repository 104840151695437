import { makeContainer } from 'tagging-tool/container/base';
import { ServiceError } from 'tagging-tool/service/base';

export type ServiceErrorState = {
  lastServiceError?: ServiceError | undefined;
};

// state

const { nextValue } = makeContainer<ServiceErrorState>({});

export const nextServiceError = (lastServiceError?: ServiceError) => {
  nextValue((state) => {
    return {
      ...state,
      lastServiceError,
    };
  });
};
