import * as Sentry from '@sentry/react';
import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import { SENTRY_DSN_URL } from 'kognia/env';
import { Environments, getEnvironment, getReleaseVersion } from 'shared/utils/environment';

export const initErrorTracker = () => {
  const environment = getEnvironment();

  Sentry.init({
    dsn: SENTRY_DSN_URL,
    release: getReleaseVersion(),
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    environment,
    enabled: environment !== Environments.Unknown,
    tracesSampleRate: 1.0,
  });
};

export const sentryCreateBrowserRouter: typeof createBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);
