export const RBChecked = (props) => {
  return (
    <svg
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 24 24'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        d='M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z M12,17 C14.7614237,17 17,14.7614237 17,12 C17,9.23857625 14.7614237,7 12,7 C9.23857625,7 7,9.23857625 7,12 C7,14.7614237 9.23857625,17 12,17 Z'
      />
    </svg>
  );
};

export const RBUnchecked = (props) => {
  return (
    <svg
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 24 24'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path d='M12,20 C16.418278,20 20,16.418278 20,12 C20,7.581722 16.418278,4 12,4 C7.581722,4 4,7.581722 4,12 C4,16.418278 7.581722,20 12,20 Z M12,22 C6.4771525,22 2,17.5228475 2,12 C2,6.4771525 6.4771525,2 12,2 C17.5228475,2 22,6.4771525 22,12 C22,17.5228475 17.5228475,22 12,22 Z' />
    </svg>
  );
};

export const RBDisabled = (props) => {
  return (
    <svg
      stroke='currentColor'
      fill='currentColor'
      strokeWidth='0'
      viewBox='0 0 32 32'
      height='1em'
      width='1em'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fill='currentColor'
        fillRule='evenodd'
        d='M14 24.667c5.891 0 10.667-4.776 10.667-10.667S19.89 3.333 14 3.333 3.333 8.11 3.333 14 8.11 24.667 14 24.667zm0 2.666C6.636 27.333.667 21.363.667 14 .667 6.636 6.637.667 14 .667c7.364 0 13.333 5.97 13.333 13.333 0 7.364-5.97 13.333-13.333 13.333zm-5.333-12a1.333 1.333 0 1 1 0-2.666h10.666a1.333 1.333 0 1 1 0 2.666H8.667z'
      />
    </svg>
  );
};

export const CrossedEye = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h20v20H0z' />
        <path
          fill='currentColor'
          d='M10 6.103a3.638 3.638 0 0 1 3.63 3.63c0 .129 0 .275-.018.404l2.621 2.621a15.522 15.522 0 0 0 2.842-3.025C16.582 6.323 13.428 4.288 10 4.288c-.678 0-1.338.092-1.998.239l1.595 1.595c.128-.019.275-.019.403-.019zM2.098 3.115l2.769 2.768c-1.43.935-2.769 2.237-3.942 3.85 2.493 3.41 5.647 5.445 9.075 5.445 1.192 0 2.347-.256 3.447-.715l3.171 3.172 1.284-1.283-14.52-14.52-1.284 1.283zM6.37 9.733c0-.678.183-1.301.513-1.833l1.375 1.375a1.99 1.99 0 0 0-.073.458c0 1.009.807 1.815 1.815 1.815.165 0 .312-.036.458-.073l.715.715.66.66a3.671 3.671 0 0 1-1.833.513 3.638 3.638 0 0 1-3.63-3.63z'
        />
      </g>
    </svg>
  );
};

export const Eye = (props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' {...props}>
      <g fill='none' fillRule='evenodd'>
        <path d='M0 0h20v20H0z' />
        <path
          fill='currentColor'
          d='M10 3.75C5.833 3.75 2.275 6.342.833 10c1.442 3.658 5 6.25 9.167 6.25s7.725-2.592 9.167-6.25c-1.442-3.658-5-6.25-9.167-6.25zm0 10.417A4.168 4.168 0 0 1 5.833 10C5.833 7.7 7.7 5.833 10 5.833S14.167 7.7 14.167 10 12.3 14.167 10 14.167zM10 7.5A2.497 2.497 0 0 0 7.5 10c0 1.383 1.117 2.5 2.5 2.5s2.5-1.117 2.5-2.5-1.117-2.5-2.5-2.5z'
        />
      </g>
    </svg>
  );
};
