import { Box, Tab, Tabs } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { ChangeEvent, ReactNode } from 'react';

interface FilterTabsProps {
  value: string;
  disabled?: boolean;
  onChange: (event: ChangeEvent<any>, newValue: string) => void;
  children: ReactNode;
}

export const FilterTabs = (props: FilterTabsProps) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <div /> }}
    sx={{
      width: '100%',

      '& .MuiTabs-indicator': {
        height: 6,
      },
    }}
  />
);

interface FilterTabProps {
  active?: boolean;
  disabled?: boolean;
  label: string;
  value: string;
}

const TabSelectedBadge = () => {
  return (
    <Box
      width={8}
      height={8}
      borderRadius={'50%'}
      position={'absolute'}
      top={16}
      right={8}
      sx={{ backgroundColor: 'primary.main' }}
    />
  );
};

export const FilterTab = (props: FilterTabProps) => {
  const { active, ...tabProps } = props;
  return (
    <Tab
      icon={active ? <TabSelectedBadge /> : undefined}
      disableRipple
      {...tabProps}
      sx={(theme) => ({
        textTransform: 'none',
        maxWidth: '100%',
        paddingLeft: 0.5,
        paddingRight: 0.5,
        flex: 1,
        fontWeight: 'fontWeightMedium',
        fontSize: fontSizes.default,
        [theme.breakpoints.up('sm')]: {
          fontSize: fontSizes.medium,
        },
        [theme.breakpoints.up('lg')]: {
          fontSize: fontSizes.large,
        },
      })}
    />
  );
};

interface TabPanelProps {
  children?: ReactNode;
  index: any;
  value: any;
}

export const FilterTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Box
      px={0}
      py={3}
      role='tabpanel'
      hidden={value !== index}
      id={`filters-tabpanel-${index}`}
      aria-labelledby={`filters-tab-${index}`}
      {...other}
    >
      {children}
    </Box>
  );
};
