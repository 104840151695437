import { Box } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

export const RecordingsFiltersContent = ({ children }: Props) => {
  return (
    <Box p={4} bgcolor='common.white'>
      {children}
    </Box>
  );
};
