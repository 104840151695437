import { useRecoilValue, useSetRecoilState } from 'recoil';

import { timelineMatchAtoms } from 'pages/tactical-analysis/atoms';

export const useTacticalAnalysisEpisodes = (recordingId: string) => {
  return useRecoilValue(timelineMatchAtoms.episodes(recordingId));
};

export const useSetTacticalAnalysisEpisodes = (recordingId: string) => {
  return useSetRecoilState(timelineMatchAtoms.episodes(recordingId));
};
