import { z } from 'zod';

import { PRESET_SCHEMA } from 'shared/constants/user-presets/userPresetsSchema';

export type UserPresetValues = {
  [K in keyof typeof PRESET_SCHEMA]: z.TypeOf<(typeof PRESET_SCHEMA)[K]>;
};

export type UserPresetKeysUnion = keyof UserPresetValues;

export enum UserPresetScope {
  timeline = 'timeline',
  playlist = 'playlist',
  videoPlayer = 'video-player',
}

export interface UserPreset<T> {
  scope: UserPresetScope;
  key: UserPresetKeysUnion;
  value: T;
  ref?: string;
}
