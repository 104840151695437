import { Box, ClickAwayListener, Grid, IconButton, ListItem, Popper, Tooltip } from '@mui/material';
import { PopperProps } from '@mui/material/Popper';
import { IconInfo } from 'kognia-ui/icons/IconInfo';
import isEmpty from 'lodash/isEmpty';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useRecordingsByName } from 'api/recording/useRecordingsByName/useRecordingsByName';
import { ActiveRecordingFilter } from 'pages/playlist-detail/components/add-multiple-clips/components/select-recordings/components/active-recording-filter/ActiveRecordingFilter';
import { HeaderAddClipsContainer } from 'pages/playlist-detail/components/add-multiple-clips/components/select-recordings/components/header-add-clips-container/HeaderAddClipsContainer';
import { RecordingResult } from 'pages/playlist-detail/components/add-multiple-clips/components/select-recordings/components/recording-result/RecordingResult';
import { ResultsWrapper } from 'pages/playlist-detail/components/add-multiple-clips/components/select-recordings/components/results-wrapper/ResultsWrapper';
import { useSyncRecordingsSelection } from 'pages/playlist-detail/components/add-multiple-clips/components/select-recordings/hooks/useSyncRecordingsSelection';
import styles from 'pages/playlist-detail/components/add-multiple-clips/components/select-recordings/SelectRecordings.module.scss';
import ActiveFilter from 'shared/components/active-filter';
import ActiveFilters from 'shared/components/active-filters';
import { Autocomplete } from 'shared/components/autocomplete';
import { ButtonDropdown } from 'shared/components/button-dropdown';
import { RecordingByName } from 'shared/types/recording/types';

const PopperWrapper: React.JSXElementConstructor<PopperProps> = (props) => {
  return <div>{props.children as React.ReactNode}</div>;
};

interface Props {
  onChange: (oldRecordings: RecordingByName[], recordings: RecordingByName[]) => void;
  recordingIds: string[];
}

export const SelectRecordings = ({ recordingIds, onChange }: Props) => {
  const [recordings, setRecordings] = useState<RecordingByName[]>([]);
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [autocompleteValues, setAutocompleteValues] = useState<RecordingByName[]>([]);
  const syncRecordings = useSyncRecordingsSelection({ setRecordings });

  useEffect(() => {
    syncRecordings(recordings, recordingIds);
  }, [syncRecordings, recordings, recordingIds]);

  const { data: recordingsByNameData, isPending, setName, fetchNextPage, removeQueries } = useRecordingsByName();

  const isOpen = Boolean(anchorEl);

  const close = useCallback(() => {
    setName('');
    removeQueries();
    setAnchorEl(null);
  }, [setAnchorEl, setName, removeQueries]);

  const open = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAutocompleteValues(recordings);
      setAnchorEl(event.currentTarget);
    },
    [setAutocompleteValues, recordings],
  );

  const handleAddClipsClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      isOpen ? close() : open(event);
    },
    [isOpen, close, open],
  );

  const handleUpdateValue = useCallback(
    (newValues: RecordingByName[] | null) => {
      if (newValues === null) return;

      setAutocompleteValues(newValues);
    },
    [setAutocompleteValues],
  );

  const handleApply = useCallback(() => {
    setAutocompleteValues((autocompleteValues) => {
      setRecordings(autocompleteValues);
      onChange(recordings, autocompleteValues);
      return autocompleteValues;
    });
    close();
  }, [recordings, setAutocompleteValues, onChange, close]);

  const handleReset = useCallback(() => {
    setAutocompleteValues([]);
  }, [setAutocompleteValues]);

  const handleRemoveRecording = useCallback(
    (recordingId: string) => {
      const updatedRecordings = recordings.filter((value) => value.id !== recordingId);
      setRecordings(updatedRecordings);
      onChange(recordings, updatedRecordings);
    },
    [onChange, recordings],
  );

  const handleRemoveRecordings = useCallback(() => {
    setRecordings([]);
    onChange(recordings, []);
  }, [onChange, recordings]);

  const handleRemoveAutocompleteRecording = useCallback(
    (recordingId: string) => {
      setAutocompleteValues(autocompleteValues.filter((value) => value.id !== recordingId));
    },
    [setAutocompleteValues, autocompleteValues],
  );

  const handleRenderItem = useCallback(
    (props: React.ComponentProps<typeof ListItem>, option: RecordingByName, { selected }: { selected: boolean }) => {
      return (
        <ListItem {...props} disablePadding>
          <RecordingResult isChecked={selected} key={option.id} recording={option} />
        </ListItem>
      );
    },
    [],
  );

  const renderTags = useCallback(
    (recordings: RecordingByName[]): React.ReactNode => {
      return (
        <div className={styles.tags}>
          {recordings.map((recording) => {
            return (
              <ActiveRecordingFilter
                size='small'
                key={recording.id}
                onRemove={handleRemoveAutocompleteRecording}
                recording={recording}
              />
            );
          })}
        </div>
      );
    },
    [handleRemoveAutocompleteRecording],
  );

  const paperComponent: React.JSXElementConstructor<React.HTMLAttributes<HTMLElement>> = useCallback(
    (props) => (
      <ResultsWrapper {...props} onResetRecordings={handleReset} onApply={handleApply}>
        {props.children}
      </ResultsWrapper>
    ),
    [handleReset, handleApply],
  );

  return (
    <Grid container direction={'column'} spacing={1}>
      <Grid item>
        <Grid container alignContent={'center'}>
          <Grid item>
            <strong className={styles.title}>{t('playlists:multimatch-analysis.selected-recordings-title')}</strong>{' '}
          </Grid>
          <Grid item>
            <Tooltip title={`${t('playlists:multimatch-analysis.recordings-tooltip')}`}>
              <IconButton size={'small'} sx={{ padding: 0.5 }}>
                <IconInfo sx={{ color: 'text.secondary' }} size='small' />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <ActiveFilters>
          <ButtonDropdown
            disabled={false}
            isOpen={isOpen}
            isSelected={recordings.length > 0}
            onClick={handleAddClipsClick}
            size='large'
            title={
              recordings.length > 0
                ? t('playlists:multimatch-analysis.selected-recordings', { count: recordings.length })
                : t('playlists:multimatch-analysis.recordings')
            }
          />
          {anchorEl && (
            <ClickAwayListener onClickAway={handleApply}>
              <Popper
                className={styles.headerAddClipsPopper}
                anchorEl={anchorEl}
                open={isOpen}
                placement='bottom-start'
              >
                <HeaderAddClipsContainer>
                  <Autocomplete
                    autoFocus
                    PaperComponent={paperComponent}
                    PopperComponent={PopperWrapper}
                    fetchNextPage={fetchNextPage}
                    getItemLabel={(option) => option.name}
                    getOptionDisabled={(option) => isEmpty(option.videoSources) || !option.hasTacticalAnalysis}
                    inputWidth={360}
                    isLoading={isPending}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    listWidth={360}
                    multiple={true}
                    onChange={setName}
                    open
                    options={recordingsByNameData}
                    renderOption={handleRenderItem}
                    renderTags={renderTags}
                    resultsHeight={310}
                    resultsNoMatches={t('playlists:multimatch-analysis.no-recordings-found')}
                    updateValue={handleUpdateValue}
                    value={autocompleteValues}
                  />
                </HeaderAddClipsContainer>
              </Popper>
            </ClickAwayListener>
          )}
          {recordings && recordings.length > 0 && (
            <>
              {recordings.map((value) => (
                <ActiveRecordingFilter key={value.id} onRemove={handleRemoveRecording} recording={value} />
              ))}
              <Tooltip title={t('recordings:filters.clear-filters')} placement='top'>
                <Box>
                  <ActiveFilter onClose={handleRemoveRecordings} closeButton />
                </Box>
              </Tooltip>
            </>
          )}
        </ActiveFilters>
      </Grid>
    </Grid>
  );
};
