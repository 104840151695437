import { useState } from 'react';

import ConfirmPopoverDialog from 'shared/components/confirm-popover-dialog';

const useConfirmPopoverDialog = () => {
  const [isOpen, setIsOpen] = useState(false);

  return {
    isOpen,
    setIsOpen,
    ConfirmPopoverDialog,
  };
};

export default useConfirmPopoverDialog;
