export const VIDEO_PLAYER_MACHINE_ID = 'video-player-machine';

export enum PLAYER_STATES {
  CHECKING_NEXT_PLAYLIST_ITEM = 'CHECKING_NEXT_PLAYLIST_ITEM',
  ENDED = 'ENDED',
  ERROR = 'ERROR',
  IDLE = 'IDLE',
  EMPTY_PLAYLIST = 'EMPTY_PLAYLIST',
  JUMP_TO_TIME_PERCENT = 'JUMP_TO_TIME_PERCENT',
  LOADING = 'LOADING',
  LOADING_PLAYLIST_ITEM = 'LOADING_PLAYLIST_ITEM',
  LOADING_VIDEO_SOURCE = 'LOADING_VIDEO_SOURCE',
  READY = 'READY',
}

export enum READY_STATES {
  ENDED = 'ENDED',
  IDLE = 'IDLE',
  PAUSED = 'PAUSED',
  PLAYING = 'PLAYING',
  SEEKING_NEW_TIME = 'SEEKING_NEW_TIME',
  STAND_BY = 'STAND_BY',
  TIMING = 'TIMING',
}

export enum PLAYER_ACTIONS {
  ENDED = 'READY.ENDED',
  ERROR = 'ERROR',
  REFRESH = 'REFRESH',
  RESTART = 'RESTART',
  TOGGLE_FULL_SCREEN = 'READY.TOGGLE_FULL_SCREEN',
  CHANGE_PLAYING_MODE = 'CHANGE_PLAYING_MODE',
  JUMP_TO_TIME_PERCENT = 'JUMP_TO_TIME_PERCENT',
  JUMP_TO_MATCH_TIME = 'JUMP_TO_MATCH_TIME',
  LOAD_PLAYLIST = 'LOAD_PLAYLIST',
  REPLACE_PLAYLIST = 'READY.REPLACE_PLAYLIST',
  CHANGE_AUTOPLAY_NEXT_PLAYLIST_ITEM = 'READY.CHANGE_AUTOPLAY_NEXT_PLAYLIST_ITEM',
  REPLACE_PLAYLIST_ITEMS = 'READY.REPLACE_PLAYLIST_ITEMS',
  LOAD_PLAYLIST_ITEM = 'READY.LOAD_PLAYLIST_ITEM',
  NEXT_PLAYLIST_ITEM = 'READY.NEXT_PLAYLIST_ITEM',
  AUTOPLAY_NEXT_PLAYLIST_ITEM = 'READY.AUTOPLAY_NEXT_PLAYLIST_ITEM',
  NEXT_VIDEO_SOURCE = 'READY.NEXT_VIDEO_SOURCE',
  PREVIOUS_VIDEO_SOURCE = 'READY.PREVIOUS_VIDEO_SOURCE',
  PAUSE = 'READY.PAUSE',
  PLAY = 'READY.PLAY',
  PREVIOUS_PLAYLIST_ITEM = 'READY.PREVIOUS_PLAYLIST_ITEM',
  READY = 'READY',
  REMOVE_PLAYLIST_ITEM = 'READY.REMOVE_PLAYLIST_ITEM',
  REMOVE_PLAYLIST_ITEMS = 'READY.REMOVE_PLAYLIST_ITEMS',
  REORDER_PLAYLIST_ITEM = 'REORDER_PLAYLIST_ITEM',
  REPLAY = 'REPLAY',
  RESUME_STAND_BY = 'READY.RESUME_STAND_BY',
  SKIP_BACKWARD = 'READY.SKIP_BACKWARD',
  SKIP_FORWARD = 'READY.SKIP_FORWARD',
  STAND_BY = 'READY.STAND_BY',
  TIMING = 'READY.TIMING',
  TOGGLE_PLAYING = 'READY.TOGGLE_PLAYING',
  UPDATE_PLAYLIST_ITEM = 'READY.UPDATE_PLAYLIST_ITEM',
  UPDATE_PLAYLIST_ITEMS = 'READY.UPDATE_PLAYLIST_ITEMS',
  UPDATE_PLAYLIST_ITEM_CUSTOM_VIEW = 'READY.UPDATE_PLAYLIST_ITEM_CUSTOM_VIEW',
  ADD_PLAYLIST_ITEM_CUSTOM_VIEW = 'READY.ADD_PLAYLIST_ITEM_CUSTOM_VIEW',
  REMOVE_PLAYLIST_ITEM_CUSTOM_VIEW = 'READY.REMOVE_PLAYLIST_ITEM_CUSTOM_VIEW',
}
