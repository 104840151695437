export type MetadataApiErrorResponse =
  | {
      detail: string;
    }
  | string;

export const metadataApiErrorParser = (data: MetadataApiErrorResponse | undefined, fallbackErrorMessage: string) => {
  if (typeof data === 'string') {
    return data;
  }

  return data ? data.detail : fallbackErrorMessage;
};
