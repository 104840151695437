import { Button, Grid } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  children: React.ReactNode;
  onApply: () => void;
  onReset: () => void;
  resetText?: string;
}

export const AutocompleteResultsWrapper = ({ children, onApply, onReset, resetText = '' }: Props) => {
  const { t } = useTranslation();

  const handleOnSubmit = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault();
      event.stopPropagation();
      onApply();
    },
    [onApply],
  );

  return (
    <form onSubmit={handleOnSubmit}>
      <Grid container direction={'column'} spacing={1}>
        <Grid item container justifyContent={'flex-end'}>
          <Grid item>
            <Button variant={'text'} color={'secondary'} onClick={onReset}>
              {(resetText ? resetText : t('common:actions.reset-filter')).toUpperCase()}
            </Button>
          </Grid>
        </Grid>
        <Grid item>{children}</Grid>
        <Grid item container justifyContent={'flex-end'} spacing={1}>
          <Grid item>
            <Button type={'submit'} variant={'contained'} color={'primary'}>
              {t('common:actions.apply')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
};
