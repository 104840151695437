import React from 'react';

type AddToPlaylistMenuStateProviderProps = { children: React.ReactNode; id: string };

export interface AddToPlaylistMenuState {
  id: string;
}

const AddToPlaylistMenuStateContext = React.createContext<{ state: AddToPlaylistMenuState } | undefined>(undefined);

export function AddToPlaylistMenuStateProvider({ children, id }: AddToPlaylistMenuStateProviderProps) {
  return (
    <AddToPlaylistMenuStateContext.Provider value={{ state: { id } }}>
      {children}
    </AddToPlaylistMenuStateContext.Provider>
  );
}

export function usePlaylistMenuId(): string {
  const context = React.useContext(AddToPlaylistMenuStateContext);

  if (context === undefined) {
    throw new Error('usePlaylistMenuId must be used within a AddToPlaylistMenuStateProvider');
  }

  return context.state.id;
}
