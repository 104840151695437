import { Box } from '@mui/material';
import { Colors } from 'kognia-ui';
import React, { forwardRef, PropsWithChildren } from 'react';

export const RowsContent = forwardRef(
  ({ children, ...rest }: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>, ref: any) => (
    <Box
      {...rest}
      ref={ref}
      position='relative'
      flex={1}
      pb={3}
      // TODO change on color from theme
      bgcolor={Colors.background}
      overflow='auto'
    >
      {children}
    </Box>
  ),
);

RowsContent.displayName = 'RowsContent';
