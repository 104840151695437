import { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import useIsLanguageDetectionReady from 'kognia/i18n/hooks/use-is-language-detection-ready';
import { useClientIdValue } from 'shared/contexts/app-state/hooks/useClientIdValue';
import { useIsAppSettled } from 'shared/contexts/app-state/hooks/useIsAppSettled';
import { useMatchedRoutePattern } from 'shared/hooks/useMatchedRoutePattern';
import { ActionTypes } from 'shared/streams/actionTypes';
import { publishEvent } from 'shared/streams/eventEmitter';
import { MetricsNames } from 'shared/types/metrics';

export const ProtectedRouteInner = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const routePattern = useMatchedRoutePattern();
  const clientId = useClientIdValue();
  const isLanguageReady = useIsLanguageDetectionReady();
  const isAppSettled = useIsAppSettled();

  useEffect(() => {
    publishEvent({
      type: ActionTypes.VIEW_PAGE,
      payload: {
        name: MetricsNames.PAGE_VIEW,
        data: { generic_url: routePattern ?? '', url: location.pathname },
      },
    });
  }, [clientId, location.pathname, routePattern]);

  if (!isAppSettled || !isLanguageReady) return null;

  return <>{children}</>;
};
