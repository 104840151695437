import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { ReprocessSyncApiParams } from 'pages/backoffice/api/types';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';

interface ReprocessSyncParams {
  data: ReprocessSyncApiParams;
}

export const useReprocessSync = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Reprocess sync success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Reprocess sync error'),
  });

  const reprocessSync = ({ data }: ReprocessSyncParams) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate({ url: `${annotationApiUrls.THIRD_PARTY_SYNC}`, data });
  };

  return { ...mutationRequest, reprocessSync };
};
