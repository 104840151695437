import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { transformMetadataTeam } from 'pages/backoffice/api/fixtures/transformers';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { TeamApi } from 'pages/backoffice/api/teams/types';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';
import { MetadataTeam } from 'pages/backoffice/types/fixture';

type CreateTeamParams = Pick<TeamApi, 'name' | 'abbreviation' | 'country_code' | 'team_type'> & { image?: File };

interface CreateTeamData {
  data: CreateTeamParams;
  onSuccess?: (team: MetadataTeam) => void;
}

export const useCreateBackofficeTeam = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<TeamApi, MetadataTeam, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Team created',
    transformer: transformMetadataTeam,
    errorMessage: (data) => metadataApiErrorParser(data, 'Team creation error'),
  });

  const createTeam = ({ data, onSuccess }: CreateTeamData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    const formData = new FormData();
    formData.append(
      'team',
      JSON.stringify({
        name: data.name,
        abbreviation: data.abbreviation,
        country_code: data.country_code,
        team_type: data.team_type,
      }),
    );
    data.image && formData.append('team_image', data.image);

    mutationRequest.mutate(
      {
        url: annotationApiUrls.TEAMS,
        data: formData,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, createTeam };
};
