import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { CompetitionAvatar } from 'kognia-ui/components/avatar/CompetitionAvatar';
import { IconWorld } from 'kognia-ui/icons/IconWorld';

import { CountryFlag } from 'shared/components/CountryFlag';
import { Countries } from 'shared/utils/countries';

interface Props {
  name: string;
  country: string;
  logoUrl?: string | null;
}

export const CompetitionRow = ({ country, name, logoUrl }: Props) => {
  const countryData = Countries.find((item) => item.code.toLocaleLowerCase() === country.toLocaleLowerCase());

  return (
    <Stack
      p={({ spacing }) => spacing(0.8, 3)}
      flex={1}
      direction='row'
      justifyContent='space-between'
      alignItems='center'
      gap={1}
    >
      <Stack direction='row' alignItems='center' gap={1}>
        <CompetitionAvatar src={logoUrl} />

        <Typography variant='body2' component='span'>
          {name}
        </Typography>
      </Stack>

      {countryData ? (
        <Tooltip title={countryData.name}>
          <Box>
            <CountryFlag countryCode={countryData.code} />
          </Box>
        </Tooltip>
      ) : (
        <IconWorld size='small' color='info' />
      )}
    </Stack>
  );
};
