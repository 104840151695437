import { TypeOfPlay, TypeOfPlaySource } from 'shared/types/type-of-play/types';

export enum KeypadCustomButtonFieldsNames {
  NAME = 'name',
  TYPE_OF_PLAY = 'type_of_play',
  TYPE_OF_PLAY_SOURCE = 'type_of_play_source',
  TIMEFRAME_BEFORE = 'timeframe_before',
  TIMEFRAME_AFTER = 'timeframe_after',
  KEYBOARD_SHORTCUT = 'keyboard_shortcut',
  DESCRIPTION = 'description',
}

export interface KeypadCustomButtonFormInputs {
  [KeypadCustomButtonFieldsNames.NAME]: string;
  [KeypadCustomButtonFieldsNames.TYPE_OF_PLAY]: TypeOfPlay;
  [KeypadCustomButtonFieldsNames.TYPE_OF_PLAY_SOURCE]: TypeOfPlaySource | null;
  [KeypadCustomButtonFieldsNames.TIMEFRAME_BEFORE]: number;
  [KeypadCustomButtonFieldsNames.TIMEFRAME_AFTER]: number;
  [KeypadCustomButtonFieldsNames.KEYBOARD_SHORTCUT]: string | null;
  [KeypadCustomButtonFieldsNames.DESCRIPTION]: string | null;
}
