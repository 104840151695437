import { EditUserApiRequestData } from 'api/backoffice/user/use-edit-user/types';
import { USERS_QUERY_KEY } from 'api/backoffice/user/use-kognia-users';
import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { queryClient } from 'api/config';
import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { userIdUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { BackendApiError, backendApiErrorParser } from 'api/utils/backend-api-error-parser';

export const useEditUser = () => {
  const { mutate, isPending, isError, isSuccess } = useMutationRequest<KogniaUser, KogniaUser, BackendApiError>({
    type: HTTPMethod.PATCH,
    errorMessage: (response) => backendApiErrorParser(response, 'Error editing user'),
    successMessage: 'User edited successfully',
  });

  const editUser = ({
    data,
    onSuccess,
    userId,
  }: {
    data: EditUserApiRequestData;
    onSuccess?: () => void;
    userId: string;
  }) => {
    mutate(
      {
        url: userIdUrl(userId),
        data,
      },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] });
          onSuccess && onSuccess();
        },
      },
    );
  };

  return { editUser, isPending, isError, isSuccess };
};
