import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { KeypadEdit } from 'pages/tagging-tool-keypad-edit/components/keypad-edit';
import Container from 'shared/components/container';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import { invariant } from 'shared/utils/invariant';

export const KeypadEditPage = () => {
  const { id } = useParams();
  invariant(id);
  const { t } = useTranslation();
  const branding = useBranding();

  useEffect(() => {
    document.title = t('common:metas.title.keypads', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  return (
    <Container>
      <KeypadEdit id={id} key={id} />
    </Container>
  );
};
