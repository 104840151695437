import classNames from 'classnames';
import { animations } from 'kognia-ui';
import { SvgIconProps } from 'kognia-ui/components/SvgIcon';
import { IconLoading } from 'kognia-ui/icons/IconLoading';

import styles from 'shared/components/spinner/Spinner.module.scss';

interface SpinnerProps extends SvgIconProps {
  isFullPage?: boolean;
}

const Spinner = ({
  className,
  size = 'medium',
  color = 'primary',
  isFullPage = false,
  sx,
  ...restProps
}: SpinnerProps) => (
  <span className={classNames({ [styles.spinnerFull]: isFullPage, className: !!className })}>
    <IconLoading
      size={size}
      color={color}
      className={className}
      sx={{
        animation: `${animations.spin} 1.2s linear infinite`,
        zIndex: 1,
        ...sx,
      }}
      {...restProps}
    />
  </span>
);

export default Spinner;
