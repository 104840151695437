import { Avatar, Stack } from '@mui/material';

import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { UserDetailsModalGridRow } from 'pages/backoffice/widgets/users/users-list/ui/user-details-modal/ui/UserDetailsModalGridRow';
import { DialogNew } from 'shared/components/dialog-new';
import { useDates } from 'shared/hooks/use-dates';

interface Props {
  open: boolean;
  user: KogniaUser;
  onClose: () => void;
}

export const UserDetailsModal = ({ open, user, onClose }: Props) => {
  const { dateAndTimeToString } = useDates();

  return (
    <DialogNew
      maxWidth='lg'
      fullWidth
      title='User info'
      icon={<Avatar src={user.avatarUrl || ''} />}
      onClose={onClose}
      open={open}
    >
      <Stack direction='column' gap={1}>
        <UserDetailsModalGridRow label='First Name'>{user.firstName}</UserDetailsModalGridRow>
        <UserDetailsModalGridRow label='Last Name'>{user.lastName}</UserDetailsModalGridRow>
        <UserDetailsModalGridRow label='Email'>{user.email}</UserDetailsModalGridRow>
        <UserDetailsModalGridRow label='Locale'>{user.locale}</UserDetailsModalGridRow>
        <UserDetailsModalGridRow label='Created'>{dateAndTimeToString(user.createdAt)}</UserDetailsModalGridRow>
        <UserDetailsModalGridRow label='Last modified'>{dateAndTimeToString(user.updatedAt)}</UserDetailsModalGridRow>
        <UserDetailsModalGridRow label='Client Ids'>{user.clientIds.join(', ')}</UserDetailsModalGridRow>
      </Stack>
    </DialogNew>
  );
};
