import { CSSProperties, forwardRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

import { PLAYLIST_ITEM_FULL_WIDTH, PLAYLIST_ITEM_HEIGHT } from 'entities/playlist/config/Playlist.config';
import { PlaylistItemType } from 'shared/components/video-player/types';

export type RowProps = {
  data: PlaylistItemType[];
  index: number;
  style: CSSProperties;
};

type Props = {
  playlistItems: PlaylistItemType[];
  Row: React.FC<RowProps>;
};

export const PlaylistItemsList = forwardRef(({ playlistItems, Row }: Props, ref: any) => {
  return (
    <AutoSizer defaultHeight={49} defaultWidth={500}>
      {({ height, width }) => (
        <List
          outerRef={ref}
          height={height}
          itemCount={playlistItems.length}
          width={width}
          itemSize={PLAYLIST_ITEM_FULL_WIDTH}
          itemData={playlistItems}
          layout='horizontal'
          style={{ paddingBottom: height - PLAYLIST_ITEM_HEIGHT }}
          itemKey={(index, data) => data[index].id}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  );
});

PlaylistItemsList.displayName = 'PlaylistItemsList';
