import { alpha, Box, styled, Typography } from '@mui/material';
import { Colors } from 'kognia-ui';

import { defaultVideoAspectRatio } from 'shared/components/video-player/video-player-component/hooks/use-video-aspect-ratio';

export const AlignRecordingContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export const AlignRecordingContent = styled(Box)({
  width: '66.666667%',
});

export const AlignRecordingVideoPlayerContainer = styled(Box)({
  position: 'relative',
  aspectRatio: defaultVideoAspectRatio,
});

export const AlignRecordingActionsContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

export const AlignRecordingClipListContainer = styled(Box)({
  position: 'relative',
});

export const AlignRecordingClipContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export const AlignRecordingName = styled(Typography)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  padding: theme.spacing(1, 2),
  background: alpha(Colors.storm, 0.8),
}));

export const AlignRecordingBottomActions = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(3),
}));
