import { Dispatch, SetStateAction, useState } from 'react';

import { queryClient } from 'api/config';
import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { generateTeamsUrl } from 'api/routes';
import { transformTeams } from 'entities/dashboard/api/use-teams-infinity-query/utils/transformTeams';
import { SortDirection } from 'shared/types/filters/types';
import { Team, TeamApiResponse, TeamsFilters } from 'shared/types/team/team';

const TEAMS_QUERY_KEY = 'teams';

type Params = {
  enabled?: boolean;
  pageSize?: number;
  initialFilters?: TeamsFilters;
};

export type TeamsReturnType = InfinityQueryResult<Team, TeamsFilters> & {
  setEnabled: Dispatch<SetStateAction<boolean>>;
};

export const invalidateTeamsInfinityQuery = () => {
  return queryClient.invalidateQueries({ queryKey: [TEAMS_QUERY_KEY] });
};

export const useTeamsInfinityQuery = ({ enabled = true, pageSize = 10, initialFilters }: Params): TeamsReturnType => {
  const [isQueryEnabled, setIsQueryEnabled] = useState<boolean>(enabled);

  return {
    ...useInfinityQuery<Team, TeamsFilters, TeamApiResponse>({
      generateUrl: generateTeamsUrl,
      key: TEAMS_QUERY_KEY,
      options: {
        size: pageSize,
        sort: 'name',
        sortDirection: SortDirection.DESC,
      },
      initialFilters: {
        isFavorite: initialFilters?.isFavorite,
      },
      transformer: transformTeams,
      queryOptions: {
        enabled: isQueryEnabled,
      },
    }),
    setEnabled: setIsQueryEnabled,
  };
};
