import { IconTime } from 'kognia-ui/icons/IconTime';

import styles from 'pages/playlists/components/playlist-duration/PlaylistsDuration.module.scss';
import { secondsAsDuration } from 'shared/utils/seconds-as-duration';

interface Props {
  duration: number;
}

const PlayListDuration = ({ duration }: Props) => {
  return (
    <div className={styles.duration}>
      <IconTime /> {secondsAsDuration(duration, false)}
    </div>
  );
};

export default PlayListDuration;
