import { Grid, styled, Theme } from '@mui/material';
import { Colors } from 'kognia-ui';

const scrollbarStyles = (theme: Theme) => ({
  bordeRadius: theme.shape.borderRadius,
  '&::-webkit-scrollbar': {
    WebkitAppearance: 'none',

    '&:vertical': {
      width: theme.spacing(1),
    },
    '&:horizontal': {
      height: theme.spacing(1),
    },
  },

  '&::-webkit-scrollbar-corner': {
    background: Colors.background,
  },

  '&::-webkit-scrollbar-track': {
    '&:horizontal': {
      background: Colors.athens,

      '&:hover': {
        background: Colors.ghost,
      },
    },
  },

  '&::-webkit-scrollbar-thumb': {
    borderRadius: '8px',
    backgroundColor: Colors.iron,

    '&:hover': {
      borderRadius: '8px',
      backgroundColor: Colors.storm,
    },
  },
});

export const TagFilterBar = styled(Grid)(({ theme }) => ({
  ...scrollbarStyles(theme),
  padding: theme.spacing(2, 0),
}));

export const TagsList = styled(Grid)(({ theme }) => ({
  ...scrollbarStyles(theme),
  overflowY: 'auto',
  maxHeight: '732px',
  marginTop: theme.spacing(1),
}));
