import { Typography, Stack, List, Box, Collapse } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { memo } from 'react';

import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { UsersBulkBar } from 'pages/backoffice/features/users/users-list/ui/users-bulk-bar/UsersBulkBar';
import { UsersListItem } from 'pages/backoffice/features/users/users-list/ui/users-list-item/UsersListItem';
import { ListHeader } from 'shared/components/list/list-header';

interface Props {
  users: KogniaUser[];
  selectedUsers: string[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
  openDetailsModal: (user: KogniaUser) => void;
  openDeleteModal: () => void;
  openAddUsersToEnvironmentsModal: () => void;
  openRemoveUsersFromEnvironmentsModal: () => void;
}

export const UsersListFeature = memo(
  ({
    users,
    selectedUsers,
    setSelectedUsers,
    openDeleteModal,
    openDetailsModal,
    openAddUsersToEnvironmentsModal,
    openRemoveUsersFromEnvironmentsModal,
  }: Props) => {
    return (
      <Stack direction='column'>
        <Stack position='sticky' top={0} zIndex={(theme) => theme.zIndex.appBar} direction='column'>
          <ListHeader>
            <Box
              display='grid'
              gridTemplateColumns='42px repeat(4, minmax(100px, 1fr)) 32px'
              alignItems='center'
              ml='-8px'
            >
              <Box width={42} />

              <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
                First Name
              </Typography>

              <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
                Last Name
              </Typography>

              <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
                Last Modified
              </Typography>

              <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
                Email
              </Typography>

              <Box width={32} />
            </Box>
          </ListHeader>

          <Collapse in={Boolean(selectedUsers.length)}>
            <UsersBulkBar
              users={users}
              openDeleteModal={openDeleteModal}
              checkedUsers={selectedUsers}
              setCheckedUsers={setSelectedUsers}
              openAddUsersToEnvironmentsModal={openAddUsersToEnvironmentsModal}
              openRemoveUsersFromEnvironmentsModal={openRemoveUsersFromEnvironmentsModal}
            />
          </Collapse>
        </Stack>

        <List>
          {users.map((user) => (
            <UsersListItem
              key={user.id}
              user={user}
              selectedUsers={selectedUsers}
              openDetailsModal={openDetailsModal}
              setSelectedUsers={setSelectedUsers}
            />
          ))}
        </List>
      </Stack>
    );
  },
);

UsersListFeature.displayName = 'UsersListFeature';
