import { Stack } from '@mui/material';
import React from 'react';

export const PlaylistActions = ({ children }: React.PropsWithChildren) => {
  return (
    <Stack direction='row' spacing={1}>
      {children}
    </Stack>
  );
};
