import { useMatchWithEpisodes } from 'api/match/useMatchWithEpisodes';
import { KeypadSimple } from 'api/tagging-tool/types';
import { useFetchKeypads } from 'api/tagging-tool/use-fetch-keypads';
import { useLiveTaggingSessionAsMatch } from 'api/tagging-tool/use-fetch-live-session-as-match';
import { useFetchTaggingEvents } from 'api/tagging-tool/use-fetch-tagging-events';
import { fetchQueryResponse } from 'api/types';
import { TaggingEvents } from 'shared/types';
import { MatchWithEpisodes } from 'shared/types/match';

export type UseTaggingToolTagRecordingDataType = {
  keyPads: KeypadSimple[] | undefined;
  matchWithEpisodes: MatchWithEpisodes | undefined;
  taggingEvents: TaggingEvents | undefined;
};

interface UseTaggingToolTagRecordingDataInterface {
  (recordingId: string, isLive?: boolean): fetchQueryResponse<UseTaggingToolTagRecordingDataType>;
}

export const useTaggingToolTagRecordingData: UseTaggingToolTagRecordingDataInterface = (
  recordingId,
  isLive = false,
) => {
  const {
    data: matchWithEpisodes,
    isError: isErrorMatch,
    isFetching: isFetchingMatch,
    isSuccess: isSuccessMatch,
    isPending: isPendingMatch,
  } = isLive ? useLiveTaggingSessionAsMatch(recordingId) : useMatchWithEpisodes({ recordingId });

  const {
    data: taggingEvents,
    isError: isErrorTaggingEvents,
    isFetching: isFetchingTaggingEvents,
    isSuccess: isSuccessTaggingEvents,
    isPending: isPendingTaggingEvents,
  } = useFetchTaggingEvents(recordingId, { isLive });

  const {
    data: keyPads,
    isError: isErrorKeypads,
    isFetching: isFetchingKeypads,
    isSuccess: isSuccessKeypads,
    isPending: isPendingKeypads,
  } = useFetchKeypads(recordingId);

  return {
    data: {
      keyPads,
      matchWithEpisodes,
      taggingEvents,
    },
    isError: isErrorKeypads || isErrorMatch || isErrorTaggingEvents,
    isSuccess: isSuccessKeypads && isSuccessMatch && isSuccessTaggingEvents,
    isFetching: isFetchingKeypads || isFetchingMatch || isFetchingTaggingEvents,
    isPending: isPendingKeypads || isPendingMatch || isPendingTaggingEvents,
  };
};
