import { Box, Stack } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { TIMELINE_CONFIG } from 'pages/tactical-analysis/components/timeline/config';
import { useTimelinePlayingMode } from 'pages/tactical-analysis/hooks/use-timeline-playing-mode';
import Toggle from 'shared/components/toggle';
import { useCurrentPlaylistItem, useVideoPlayerPlayingMode } from 'shared/components/video-player';
import { getPlayingMode } from 'shared/components/video-player/defaultPlayingModes';

export const PlayingModeToggle = (): JSX.Element => {
  const { t } = useTranslation();
  const currentPlaylistItem = useCurrentPlaylistItem();
  const playingMode = useVideoPlayerPlayingMode();
  const { setPlayingMode } = useTimelinePlayingMode(currentPlaylistItem.recordingId);
  const handleToggle = useCallback(() => {
    return setPlayingMode(
      getPlayingMode({
        playingMode,
        useEffectiveTime: !playingMode.useEffectiveTime,
      }),
    );
  }, [setPlayingMode, playingMode]);

  return (
    <Box
      sx={({ palette, spacing }) => ({
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        height: `${TIMELINE_CONFIG.STICKY_HEADER_HEIGHT / 2}px`,
        padding: spacing(0, 1),
        color: 'text.primary',
        borderBottom: `1px solid ${palette.secondary.main}`,
        fontSize: fontSizes.small,
        // TODO get from theme
        lineHeight: 1.67,
      })}
    >
      <Stack direction={'row'} spacing={1} alignItems={'center'}>
        <span>{t('timeline:playing-mode-toggle.title')}</span>
        <Toggle checked={playingMode.useEffectiveTime} onClick={handleToggle} />
      </Stack>
    </Box>
  );
};
