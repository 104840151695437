import { ClientsFilters } from 'api/backoffice/client/hooks/use-clients/types';
import { transformClients } from 'api/backoffice/client/transformer';
import { ClientsApiResponse } from 'api/backoffice/client/types';
import { queryClient } from 'api/config';
import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { clientsWithFiltersUrl } from 'api/routes';
import { Client, SortDirection } from 'shared/types';

const PAGE_SIZE = 10;

interface Options {
  initialFilters?: ClientsFilters;
  enabled?: boolean;
  pageSize?: number;
}

export const INITIAL_CLIENTS_FILTERS: ClientsFilters = {
  name: '',
  clientIds: [],
};

export const clientsQueryKey = 'clients';

export const useClients = (
  data: Options = {
    initialFilters: INITIAL_CLIENTS_FILTERS,
    enabled: true,
    pageSize: PAGE_SIZE,
  },
): InfinityQueryResult<Client, ClientsFilters> => {
  return useInfinityQuery<Client, ClientsFilters, ClientsApiResponse>({
    generateUrl: clientsWithFiltersUrl,
    initialFilters: data.initialFilters,
    key: clientsQueryKey,
    options: {
      size: data.pageSize || PAGE_SIZE,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    transformer: transformClients,
    queryOptions: {
      enabled: data.enabled,
    },
  });
};

export const invalidateClients = async () => {
  await queryClient.invalidateQueries({ queryKey: [clientsQueryKey] });
};
