import { FormControl, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useTeamWithDefaults } from 'entities/dashboard/api/useTeamWithDefaults';
import { useTeamCompetitions } from 'features/dashboard/team-profile-header-feature/hooks/useTeamCompetitions';
import { SeasonSimple } from 'shared/types/season/seasonSchema';
import { invariant } from 'shared/utils/invariant';
import { printSeasonYear } from 'shared/utils/printSeasonYear';

const SELECT_WIDTH = '152px';

interface Props {
  selectedSeasonYear: number | undefined;
  selectedCompetition: string | undefined;
  onChangeSelectedSeasonYear: (value: number | undefined) => void;
  onChangeSelectedCompetition: (value: string | undefined) => void;
}

export const TeamCompetitionsFeature = ({
  selectedCompetition,
  selectedSeasonYear,
  onChangeSelectedCompetition,
  onChangeSelectedSeasonYear,
}: Props) => {
  const { teamId } = useParams();
  invariant(teamId);
  const { data: team } = useTeamWithDefaults(teamId);

  const { data: competitions } = useTeamCompetitions(team.id);
  const defaultCompetitionId = team.teamDefaults?.season?.competition?.id;
  const defaultCompetition = useMemo(
    () => competitions.find((comp) => comp.id === defaultCompetitionId),
    [competitions, defaultCompetitionId],
  );

  const handleCompetitionChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      onChangeSelectedCompetition(event.target.value);
      const competition = competitions.find((comp) => comp.id === event.target.value);
      const hasCompetitionCurrentSeasonYear = competition?.seasons?.find((season) => season.year === selectedSeasonYear)
        ?.year;
      const firstSeasonYear = competition?.seasons?.[0].year;

      const seasonYear = hasCompetitionCurrentSeasonYear ? selectedSeasonYear : firstSeasonYear;
      onChangeSelectedSeasonYear(seasonYear);
    },
    [competitions, onChangeSelectedCompetition, onChangeSelectedSeasonYear, selectedSeasonYear],
  );

  const handleSeasonChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      onChangeSelectedSeasonYear(Number(event.target.value));
    },
    [onChangeSelectedSeasonYear],
  );

  const selectedCompetitionSeasons = useMemo(() => defaultCompetition?.seasons || [], [defaultCompetition]);

  return (
    <Stack direction={'row'} gap={1}>
      <FormControl variant='filled' size='small'>
        <Select
          variant={'outlined'}
          value={selectedCompetition || ''}
          onChange={handleCompetitionChange}
          sx={{ width: SELECT_WIDTH, background: (theme) => theme.palette.common.white }}
        >
          {competitions.map((comp) => (
            <MenuItem key={comp.id} value={comp.id}>
              {comp.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant='filled' size='small'>
        <Select
          variant={'outlined'}
          value={selectedSeasonYear?.toString() || ''}
          onChange={handleSeasonChange}
          sx={{ width: SELECT_WIDTH, background: (theme) => theme.palette.common.white }}
        >
          {selectedCompetitionSeasons.map((season: SeasonSimple) => (
            <MenuItem key={season.id} value={season.year?.toString()}>
              {printSeasonYear(season)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};
