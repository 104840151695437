import { atom, useAtomValue, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';

const playHeadShadowTime = atomFamily(() => atom<number>(0));

const playHeadShadowVisibility = atomFamily(() => atom<boolean>(false));

export const usePlayHeadShadowTime = (recordingId: string) => {
  return useAtomValue(playHeadShadowTime(recordingId));
};

export const useSetPlayHeadShadowTime = (recordingId: string) => {
  return useSetAtom(playHeadShadowTime(recordingId));
};

export const usePlayHeadShadowVisibility = (recordingId: string) => {
  return useAtomValue(playHeadShadowVisibility(recordingId));
};

export const useSetPlayHeadShadowVisibility = (recordingId: string) => {
  return useSetAtom(playHeadShadowVisibility(recordingId));
};
