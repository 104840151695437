import find from 'lodash/find';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

import {
  RowGroup,
  RowList,
} from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/row';
import { TimelineTableBlock } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/timeline';
import { generateBlockContainerClip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/generateBlockContainerClip';
import { getClipsFromTaggingEvent } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/getClipsFromTaggingEvent';
import { GroupedTags } from 'shared/types/tagging-events/types';

export const generateManualTagsRows = (
  timelineTableBlocks: TimelineTableBlock[],
  groupedTags: GroupedTags,
  recordingId: string,
): RowGroup => {
  const rows: RowList = {};

  forEach(groupedTags, (tags, tagGroup) => {
    const rowId = `${recordingId}-${tagGroup}`;

    const modifiedTags = getClipsFromTaggingEvent(tags, rowId);
    modifiedTags.forEach((tag) => {
      if (!rows[rowId]) {
        rows[rowId] = generateBlockContainerClip({
          id: rowId,
          rowType: 'manual-tags',
          timelineTableBlocks: timelineTableBlocks,
          title: `timeline:${tagGroup}`,
          rowId: rowId,
          clipIdPrefix: tagGroup,
        });
      }

      const episodeClip = find(
        rows[rowId].clips,
        (row) =>
          (tag.startTime >= row.startTime && tag.startTime <= row.endTime) ||
          (tag.endTime >= row.startTime && tag.endTime <= row.endTime),
      );

      if (episodeClip) {
        episodeClip?.clips?.push(tag);
      }
    });
  });

  return {
    id: 'manual-tags',
    isOpen: true,
    isSelected: false,
    title: 'timeline:manual-tags',
    rows: map(rows, (row) => row),
    totalClips: reduce(rows, (acc, row) => acc + row.clips.length, 0),
    type: 'manual-tags',
  };
};
