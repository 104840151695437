import { UseQueryResult } from '@tanstack/react-query';
import { useMemo } from 'react';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { transformRecording } from 'api/recording/transformers';
import { specificRecordingUrl } from 'api/routes';
import { Recording } from 'shared/types/recording/types';

interface Parameters {
  recordingId: string;
  onSuccess?: (data: Recording) => void;
}

const recordingQueryRef = 'recording';

export const useRecordingQuery = ({ recordingId, onSuccess }: Parameters): UseQueryResult<Recording> => {
  const url = specificRecordingUrl(recordingId);
  const queryRef = useMemo(() => [recordingQueryRef, url], [url]);

  return useFetchRequest<Recording>({
    queryRef,
    url,
    transformer: transformRecording,
    options: {
      enabled: true,
    },
    onSuccess,
  });
};
