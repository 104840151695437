import account from 'kognia/i18n/locales/de-DE/account.json';
import api from 'kognia/i18n/locales/de-DE/api.json';
import common from 'kognia/i18n/locales/de-DE/common.json';
import createTeam from 'kognia/i18n/locales/de-DE/create-team.json';
import descriptions from 'kognia/i18n/locales/de-DE/descriptions.json';
import downloadManager from 'kognia/i18n/locales/de-DE/download-manager.json';
import explore from 'kognia/i18n/locales/de-DE/explore.json';
import faqs from 'kognia/i18n/locales/de-DE/faqs.json';
import filters from 'kognia/i18n/locales/de-DE/filters.json';
import forms from 'kognia/i18n/locales/de-DE/forms.json';
import fundamentals from 'kognia/i18n/locales/de-DE/fundamentals.json';
import home from 'kognia/i18n/locales/de-DE/home.json';
import languages from 'kognia/i18n/locales/de-DE/languages.json';
import playlistDetail from 'kognia/i18n/locales/de-DE/playlist-detail.json';
import playlists from 'kognia/i18n/locales/de-DE/playlists.json';
import recordingPlaylists from 'kognia/i18n/locales/de-DE/recording-playlists.json';
import recording from 'kognia/i18n/locales/de-DE/recording.json';
import recordingsList from 'kognia/i18n/locales/de-DE/recordings-list.json';
import recordings from 'kognia/i18n/locales/de-DE/recordings.json';
import sharePlaylistModal from 'kognia/i18n/locales/de-DE/share-playlist-modal.json';
import switchEnvironment from 'kognia/i18n/locales/de-DE/switch-environment.json';
import taggingTool from 'kognia/i18n/locales/de-DE/tagging-tool.json';
import timeline from 'kognia/i18n/locales/de-DE/timeline.json';
import tutorials from 'kognia/i18n/locales/de-DE/tutorials.json';
import videoPlayer from 'kognia/i18n/locales/de-DE/video-player.json';
import { Namespaces } from 'kognia/i18n/types';

const translations = {
  [Namespaces['ACCOUNT']]: account,
  [Namespaces['API']]: api,
  [Namespaces['COMMON']]: common,
  [Namespaces['CREATE-TEAM']]: createTeam,
  [Namespaces['DESCRIPTIONS']]: descriptions,
  [Namespaces['FORMS']]: forms,
  [Namespaces['FUNDAMENTALS']]: fundamentals,
  [Namespaces['HOME']]: home,
  [Namespaces['LANGUAGES']]: languages,
  [Namespaces['PLAYLIST-DETAIL']]: playlistDetail,
  [Namespaces['PLAYLISTS']]: playlists,
  [Namespaces['RECORDING']]: recording,
  [Namespaces['RECORDING-LIST']]: recordingsList,
  [Namespaces['RECORDING-PLAYLISTS']]: recordingPlaylists,
  [Namespaces['RECORDINGS']]: recordings,
  [Namespaces['SHARE-PLAYLIST-MODAL']]: sharePlaylistModal,
  [Namespaces['SWITCH_ENVIRONMENT']]: switchEnvironment,
  [Namespaces['TAGGING-TOOL']]: taggingTool,
  [Namespaces['TIMELINE']]: timeline,
  [Namespaces['TUTORIALS']]: tutorials,
  [Namespaces['VIDEO-PLAYER']]: videoPlayer,
  [Namespaces['FAQS']]: faqs,
  [Namespaces['FILTERS']]: filters,
  [Namespaces['DOWNLOAD_MANAGER']]: downloadManager,
  [Namespaces['EXPLORE']]: explore,
};

export default translations;
