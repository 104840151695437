import { TypeOf } from 'zod';

import { competitionFormSchema } from 'pages/backoffice/pages/competitions/components/competition-form/form';

export enum CompetitionFormFieldsNames {
  id = 'id',
  name = 'name',
  country = 'country',
  competitionImage = 'competitionImage',
}

export type CompetitionFormSchema = TypeOf<typeof competitionFormSchema>;
