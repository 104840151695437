import { Row, RowType } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/row';
import { TimelineTableBlock } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/timeline';
import { generateEpisodeClips } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/generateEpisodeClips';

type GenerateEpisodeContainerClipOptions = {
  id: string;
  title: string;
  timelineTableBlocks: TimelineTableBlock[];
  rowId: string;
  rowType: RowType;
  clipIdPrefix: string;
  teamId?: string;
  entityId?: string;
};
export const generateBlockContainerClip = ({
  id,
  title,
  timelineTableBlocks,
  rowId,
  rowType,
  clipIdPrefix,
  teamId,
  entityId,
}: GenerateEpisodeContainerClipOptions): Row => {
  return {
    id: id,
    type: rowType,
    title,
    entityId,
    teamId,
    isHidden: false,
    clips: generateEpisodeClips({
      timelineTableBlocks,
      clipType: 'block-container',
      rowId: rowId,
      clipIdPrefix: clipIdPrefix,
    }),
  };
};
