import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';
import {
  ProcessFixtureEventsProvider,
  ProcessFixturePipelineType,
  ProcessFixtureTrackingProvider,
} from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/types/processFixtureForm.types';

export type ProcessFixtureParamsDataSettings = {
  providers: {
    video: 'third-party-tactical';
    tracking?: ProcessFixtureTrackingProvider;
    events?: ProcessFixtureEventsProvider;
    options?: {
      sportlogiq_tracking_type?: string;
    };
  };
  run_processing: boolean;
  postprocess: {
    show_overlays: boolean;
    auto_run_tactical_analysis: boolean;
  };
};

export interface ProcessFixtureParamsData {
  recording_id: string;
  video_path: string;
  bucket: string;
  pipeline_type: ProcessFixturePipelineType;
  settings: ProcessFixtureParamsDataSettings;
  restart_processing: boolean;
  files?: unknown;
}

interface ProcessFixtureParams {
  data: ProcessFixtureParamsData;
}

export const useProcessFixture = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Process fixture success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Process fixture error'),
  });

  const processFixture = ({ data }: ProcessFixtureParams) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate({ url: `${annotationApiUrls.PROCESS_FIXTURE}`, data });
  };

  return { ...mutationRequest, processFixture };
};
