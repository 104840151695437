import { ImageSizes } from '../types/images';

export const IMAGE_SIZES: Record<ImageSizes, string> = {
  xxsmall: '20px',
  xsmall: '24px',
  small: '36px',
  medium: '48px',
  large: '64px',
  xlarge: '88px',
} as const;

export const IMAGE_BORDER_SIZES: Record<ImageSizes, number> = {
  xxsmall: 1,
  xsmall: 1,
  small: 2,
  medium: 2,
  large: 3,
  xlarge: 4,
} as const;
