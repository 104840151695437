import { Box, styled } from '@mui/material';

export const ResizeBarWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isDragging' && prop !== 'isHorizontal',
})<{ isDragging: boolean; isHorizontal: boolean }>(({ theme, isDragging, isHorizontal }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(0.5),
  height: '12px',
  width: '100%',
  background: theme.palette.common.white,
  borderStyle: 'solid',
  borderWidth: '1px 0',
  borderColor: theme.palette.secondary.light,
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.shorter,
  }),
  userSelect: 'none',
  cursor: 'row-resize',

  '&:hover, &:active': {
    borderColor: theme.palette.secondary.main,
    '& > div': {
      background: theme.palette.secondary.main,
    },
  },

  '& > div': {
    height: '4px',
    width: '4px',
    minWidth: '4px',
    background: theme.palette.secondary.light,
    borderRadius: '50%',
  },

  ...(isHorizontal && {
    height: '100%',
    width: '12px',
    minWidth: '12px',
    borderWidth: '0 1px',
    flexDirection: 'column',
    cursor: 'col-resize',
  }),

  ...(isDragging && {
    borderColor: theme.palette.secondary.main,
    '&:hover, &:active': {
      '& > div': {
        background: theme.palette.text.primary,
      },
    },
  }),
}));
