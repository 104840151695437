import { Stack } from '@mui/material';

export const StatWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack
      gap={1}
      flex={1}
      textAlign={'center'}
      alignItems={'center'}
      px={2}
      borderRight={(theme) => `1px solid ${theme.palette.divider}`}
      sx={{
        ['&:last-child']: { borderRight: 0 },
      }}
    >
      {children}
    </Stack>
  );
};
