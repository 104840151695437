import { TacticalAnalysesFilters } from 'api/recording/useEpisodesWithFilters/types';
import { Episode } from 'shared/types/episodes/types';
import { VideoSource, VideoSourceAPI } from 'shared/types/recording/types';
import { SegmentConfig } from 'shared/types/segment/types';

export interface MatchTeams {
  homeTeam: MatchTeam;
  opponentTeam: MatchTeam;
}

export interface MatchTeam {
  id: string;
  name: string;
  logo: string;
  players: MatchTeamPlayer[];
}

export interface MatchTeamPlayer {
  id: string;
  positionLine?: number;
  positionWing?: number;
  photo?: string;
  substitutedBy?: number;
  name: string;
  number?: number;
}

export enum MatchTeamTypes {
  HOME = 'homeTeam',
  OPPONENT = 'opponentTeam',
}

export interface MatchBase {
  id: string;
  clientIds: string[];
  segments: any[];
  teams: MatchTeams;
  title: string;
  hasHomographies: boolean;
  showOverlays: boolean;
}

export interface Match extends MatchBase {
  defaultVideoSource: VideoSource;
  videoSources: VideoSource[];
}

export interface MatchApi extends MatchBase {
  defaultVideoSource: VideoSourceAPI;
  videoSources: VideoSourceAPI[];
}

export interface MatchWithEpisodes {
  episodes: Episode[];
  match: Match;
  matchSegments: SegmentConfig[];
  filters?: TacticalAnalysesFilters;
}
