import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { generateClipsWithOverlappingTime } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/generateClips';
import { TacticalFundamental } from 'shared/types/episodes/types';

interface Params {
  clips: TacticalFundamental[];
  title: string;
  rowId: string;
}

export const getClipsForFilters = ({ clips, title, rowId }: Params) => {
  const filterClips = clips.map(
    (clip) =>
      <Clip>{
        id: `tactic-${clip.startTime}-${clip.endTime}`,
        startTime: clip.startTime,
        endTime: clip.endTime,
        type: 'filter',
        elementId: '',
        rowId: rowId,
        title,
      },
  );

  const filterClipsWithOverlappingTime = generateClipsWithOverlappingTime({ clips: filterClips });

  return filterClipsWithOverlappingTime.map(
    (clip) =>
      <Clip>{
        id: `tactic-${clip.startTime}-${clip.endTime}`,
        startTime: clip.startTime,
        endTime: clip.endTime,
        type: 'filter',
        elementId: '',
        rowId: rowId,
        title,
      },
  );
};
