import { BrandingFormFieldsNames } from 'pages/backoffice/features/branding-form/utils/brandingFormFieldsNames';

export const BrandingFormLabels = {
  [BrandingFormFieldsNames.FAVICON]: 'Favicon',
  [BrandingFormFieldsNames.EMAIL_LOGO]: 'Email Logo',
  [BrandingFormFieldsNames.NAVBAR_LOGO]: 'Navbar Logo',
  [BrandingFormFieldsNames.NAVBAR_COLLAPSED_LOGO]: 'Navbar Collapsed Logo',
  [BrandingFormFieldsNames.HOME_PAGE_LOGO]: 'Home Page Logo',
  [BrandingFormFieldsNames.DISPLAY_NAME]: 'Display Name',
};
