import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { ExplorePage } from 'pages/explore/Explore.page';
import styles from 'pages/home/HomeContainer.module.scss';
import { Solutions } from 'pages/home/ui/solutions/Solutions';
import Container from 'shared/components/container';
import { useFeatureFlag } from 'shared/contexts/app-state/hooks/useFeatureFlag';
import { useUser } from 'shared/contexts/app-state/hooks/useUser';
import { useBranding } from 'shared/hooks/use-branding/useBranding';

export const HomeContainer = () => {
  const user = useUser();
  const { t } = useTranslation();
  const branding = useBranding();
  const showExploreHomePage = useFeatureFlag(FEATURE_FLAG.APP_EXPLORE_HOME_PAGE);
  const showExplore = useFeatureFlag(FEATURE_FLAG.APP_EXPLORE);

  useEffect(() => {
    document.title = t('common:metas.title.home', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  if (showExploreHomePage && showExplore)
    return (
      <Suspense>
        <ExplorePage />
      </Suspense>
    );

  return (
    <Container>
      <div className={styles.homePage}>
        <h1 data-testid='page-title'>{t('home:welcome', { firstName: user.firstName })}</h1>
        <p>{t('home:what-would-you-like-to-do')}</p>
        <Solutions />
      </div>
    </Container>
  );
};

export default HomeContainer;
