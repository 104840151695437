import { TypeOf } from 'zod';

import { trimFormSchema } from 'features/playlist/user-playlist-items-list/ui/trim-modal/trimFormSchema';

export const trimFormId = 'trim-form';

export enum TrimFormFieldsNames {
  startTime = 'startTime',
  endTime = 'endTime',
}

export type TrimFormSchema = TypeOf<typeof trimFormSchema>;
