import classNames from 'classnames';

import EmptyCellGrid from 'pages/recording-playlists/components/empty-cell-grid';
import pageStyles from 'pages/recording-playlists/components/recording-playlists-page/RercordingPlaylistsPage.module.scss';
import styles from 'pages/recording-playlists/components/recording-playlists-skeleton/RecordingPlaylistsSkeleton.module.scss';

const RecordingPlaylistsSkeleton = () => {
  return (
    <>
      <div className={pageStyles.heading}>
        <h1 className={classNames(pageStyles.title, styles.title)} />
        <div className={styles.button} />
      </div>
      <EmptyCellGrid cells={9} size={4} isLoading />
    </>
  );
};

export default RecordingPlaylistsSkeleton;
