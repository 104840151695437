import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { Player, PlayerBackendApiResponse } from 'shared/types/player/player';
import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';
import { transformPlayersApiResponseWithValidation } from 'shared/utils/transformPlayer';

export const transformPlayers = (response: PlayerBackendApiResponse): InfinityQueryDataResult<Player> => {
  const items = transformPlayersApiResponseWithValidation(response.content);

  return {
    data: {
      items,
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
