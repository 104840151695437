import { useQueryClient } from '@tanstack/react-query';

import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { transformCoaches } from 'pages/backoffice/api/coaches/use-coaches/transformer';
import { CoachesApiResponse, CoachesFilters } from 'pages/backoffice/api/coaches/use-coaches/types';
import { getCoachesUrl } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { Coach } from 'pages/backoffice/types/coaches';
import { SortDirection } from 'shared/types/filters/types';

const QUERY_KEY = 'coaches';

export const useCoaches = (): InfinityQueryResult<Coach, CoachesFilters> => {
  const { isAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();

  return useInfinityQuery<Coach, CoachesFilters, CoachesApiResponse>({
    generateUrl: getCoachesUrl,
    key: QUERY_KEY,
    options: {
      size: 10,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    transformer: transformCoaches,
    queryOptions: {
      enabled: isAllowedBackofficeOrigin,
    },
  });
};

export const useInvalidateCoaches = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
};
