import { UseQueryResult } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { queryClient } from 'api/config';
import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { FeatureFlagsUrl } from 'api/routes';
import { FeatureFlags } from 'shared/types';

type UseFetchAccountResponse = UseQueryResult<FeatureFlags> & { setQueryData: (user: FeatureFlags) => void };

interface UseFetchAccountInterface {
  (): UseFetchAccountResponse;
}

export const transformFeatureFlags = (featureFlags: FeatureFlags): FeatureFlags => {
  return featureFlags;
};

export enum FEATURE_FLAG {
  APP_DASHBOARDS = 'app-dashboards',
  APP_DASHBOARD_PREVIEW = 'app-dashboard-preview',
  APP_EXPLORE = 'app-explore',
  APP_EXPLORE_HOME_PAGE = 'app-explore-home-page',
  APP_FAVORITES = 'app-favorites',
  CUSTOM_OVERLAYS = 'app-custom-overlays',
  CUSTOM_OVERLAYS_QUALITY_DROPDOWN = 'app-custom-overlays-quality-dropdown',
  DOWNLOAD_PLAYLIST = 'app-download-playlist',
}

const FEATURE_FLAGS_VALUES = {
  [FEATURE_FLAG.APP_DASHBOARDS]: true,
  [FEATURE_FLAG.APP_DASHBOARD_PREVIEW]: true,
  [FEATURE_FLAG.APP_EXPLORE]: true,
  [FEATURE_FLAG.APP_EXPLORE_HOME_PAGE]: true,
  [FEATURE_FLAG.APP_FAVORITES]: true,
  [FEATURE_FLAG.CUSTOM_OVERLAYS]: true,
  [FEATURE_FLAG.CUSTOM_OVERLAYS_QUALITY_DROPDOWN]: true,
  [FEATURE_FLAG.DOWNLOAD_PLAYLIST]: true,
};

const LOCALHOST_HOSTNAME = 'localhost';

export const useFetchFeatureFlags: UseFetchAccountInterface = () => {
  const fetchQueryRef = useMemo(() => ['fetchFeatureFlags'], []);
  const fetchRequest = useFetchRequest<FeatureFlags>({
    queryRef: fetchQueryRef,
    url: FeatureFlagsUrl,
    transformer: transformFeatureFlags,
    options: { enabled: false },
  });

  const includeLocalhostFeatureFlags = window.location.hostname === LOCALHOST_HOSTNAME;

  const featureFlags =
    includeLocalhostFeatureFlags && fetchRequest.data
      ? { ...FEATURE_FLAGS_VALUES, ...fetchRequest.data }
      : fetchRequest.data;

  const setQueryData = useCallback(
    (data: FeatureFlags) => queryClient.setQueryData(fetchQueryRef, data),
    [fetchQueryRef],
  );

  return { ...fetchRequest, data: featureFlags, setQueryData } as UseFetchAccountResponse;
};
