import { useTranslation } from 'react-i18next';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { invalidatePlaylistsQuery } from 'api/playlist/useFetchPlaylists';
import { invalidatePlaylistIdQuery } from 'api/playlist/usePlaylist';
import { deletePlaylistItemsUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';

export const useDeletePlaylistItems = (playlistId: string) => {
  const { t } = useTranslation();
  const { mutate, isPending, isError } = useMutationRequest({
    type: HTTPMethod.DELETE,
    errorMessage: t('api:use-delete-playlist-items.error'),
    successMessage: t('api:use-delete-playlist-items.success'),
    onSuccess: async () => {
      await invalidatePlaylistsQuery();
      await invalidatePlaylistIdQuery(playlistId);
    },
  });

  const deletePlaylistItems = (playlistItemsIds: string[], onSuccess?: (data: unknown) => void) => {
    mutate({ url: deletePlaylistItemsUrl(playlistId), data: { data: playlistItemsIds } }, { onSuccess });
  };

  return { deletePlaylistItems, isPending, isError };
};
