import { MenuItem } from '@mui/material';
import React from 'react';

interface SelectTypeOptionsProps {
  options: { value: string | number | any; label?: string }[];
}

export const getTypeOptions = ({ options }: SelectTypeOptionsProps) => {
  return options.map((option) => {
    return (
      <MenuItem key={option.value} value={option.value}>
        {option.label ? option.label : option.value}
      </MenuItem>
    );
  });
};
