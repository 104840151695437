import { useRecoilValue, useSetRecoilState } from 'recoil';

import { timelineMatchAtoms } from 'pages/tactical-analysis/atoms';

export const useTacticalAnalysisMatch = (recordingId: string) => {
  return useRecoilValue(timelineMatchAtoms.match(recordingId));
};

export const useSetTacticalAnalysisMatch = (recordingId: string) => {
  return useSetRecoilState(timelineMatchAtoms.match(recordingId));
};
