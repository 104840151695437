import { useCallback } from 'react';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { invalidatePlayerWithDefaults } from 'api/player/usePlayerWithDefaults';
import { favoritesUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { invalidatePlayers } from 'entities/dashboard/api/use-player-infinity-query/usePlayerInfinityQuery';
import { MetadataApiErrorResponse } from 'pages/backoffice/api/utils';

interface UpdatePlayerFavoritesParams {
  playerId: string;
  isFavorite: boolean;
  onSuccess?: () => void;
  onMutate?: (playerId: string, isFavorite: boolean) => void;
}

export const useUpdatePlayerFavorites = () => {
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
  });

  const updatePlayerFavorites = useCallback(
    ({ playerId, isFavorite, onSuccess, onMutate }: UpdatePlayerFavoritesParams) => {
      onMutate && onMutate(playerId, isFavorite);

      mutationRequest.mutate(
        { url: favoritesUrl('players', playerId), data: { isFavorite } },
        {
          onSuccess: () => {
            onSuccess?.();
          },
          onSettled: async () => {
            await Promise.allSettled([invalidatePlayers(), invalidatePlayerWithDefaults(playerId)]);
          },
        },
      );
    },
    [mutationRequest],
  );

  return { ...mutationRequest, updatePlayerFavorites };
};
