import { USERS_QUERY_KEY } from 'api/backoffice/user/use-kognia-users';
import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { createUserEmailsData } from 'api/backoffice/user/utils/createUserEmailsData';
import { queryClient } from 'api/config';
import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { userUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';

type Response = string[];

export const useDeleteUsers = () => {
  const triggerNotification = useNotifications();
  const { mutate, isPending, isError, isSuccess } = useMutationRequest<Response, Response>({
    type: HTTPMethod.DELETE,
    errorMessage: 'Error deleting user(s)',
  });

  const deleteUsers = ({ users, onSuccess }: { users: KogniaUser[]; onSuccess?: () => void }) => {
    mutate(
      {
        url: userUrl,
        data: { data: users.map((user) => user.id) },
      },
      {
        onSuccess: (notDeletedUsers) => {
          if (notDeletedUsers.length) {
            triggerNotification({
              type: NotificationType.ERROR,
              message: `${notDeletedUsers.length} of ${users.length} users were not deleted (${createUserEmailsData(
                users,
                notDeletedUsers,
              )})`,
            });
          } else {
            triggerNotification({
              type: NotificationType.SUCCESS,
              message: 'User(s) deleted',
            });
          }

          queryClient.invalidateQueries({ queryKey: [USERS_QUERY_KEY] });
          onSuccess && onSuccess();
        },
      },
    );
  };

  return { deleteUsers, isPending, isError, isSuccess };
};
