import { useAtomValue } from 'jotai';

import { currentClientIdAtom } from 'shared/contexts/app-state/atoms';

export const useClientIdValue = () => {
  const clientId = useAtomValue(currentClientIdAtom);

  if (!clientId) {
    throw new Error('Client ID is not set');
  }

  return clientId;
};
