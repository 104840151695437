import { Box, Button, GlobalStyles, Stack, Typography } from '@mui/material';
import de from 'date-fns/locale/de';
import en from 'date-fns/locale/en-US';
import es from 'date-fns/locale/es';
import { Colors, defaultFontFamily, fontSizes, fontWeight, themeZIndexes } from 'kognia-ui';
import { useCallback, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';

import { useUser } from 'shared/contexts/app-state/hooks/useUser';
import { useDates } from 'shared/hooks/use-dates';
import { guid } from 'shared/utils/guid';

registerLocale('es-ES', es);
registerLocale('en-US', en);
registerLocale('de-DE', de);

interface Props {
  from: string | null;
  to: string | null;
  isOpen: boolean;
  onClick: () => void;
  hasSelectedOptions: boolean;
  onClose: (start: Date | null, end: Date | null) => void;
}

export const DateRange = ({ isOpen, onClick, onClose, hasSelectedOptions, from = null, to = null }: Props) => {
  const { t } = useTranslation();
  const [dateRangeKey, setDateRangeKey] = useState<string>(guid());
  const { locale } = useUser();
  const { parseBackendDateString, dateToString } = useDates();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  useEffect(() => {
    setStartDate(from ? parseBackendDateString(from) : null);
    setEndDate(to ? parseBackendDateString(to) : null);
  }, [from, parseBackendDateString, to]);

  const onChange = useCallback((dates: [Date, Date]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  }, []);

  const handleOnClose = useCallback(() => {
    if (startDate && endDate) {
      onClose(startDate, endDate);
    }
  }, [startDate, endDate, onClose]);

  const handleClear = useCallback(() => {
    setDateRangeKey(guid());
    setStartDate(null);
    setEndDate(null);
    onClose(null, null);
  }, [onClose]);

  return (
    <div key={dateRangeKey}>
      <DatePicker
        selected={startDate}
        onChange={onChange}
        disabledKeyboardNavigation
        startDate={startDate}
        endDate={endDate}
        locale={locale}
        onCalendarOpen={onClick}
        isClearable={false}
        monthsShown={2}
        onCalendarClose={handleOnClose}
        selectsRange
        enableTabLoop={false}
        customInput={
          <Button
            aria-controls='date-range'
            variant='underlined'
            color={isOpen || hasSelectedOptions ? 'primary' : 'inherit'}
          >
            {t(`filters:date`)}
          </Button>
        }
      >
        {startDate && endDate ? (
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            padding={2}
            sx={{ clear: 'both', borderTop: `1px solid ${Colors.iron}` }}
          >
            <Box>
              <Typography variant={'body2'} component={'span'} sx={{ paddingRight: 2 }}>
                <strong>{t('filters:from')}</strong> {dateToString(startDate)}
              </Typography>
              <Typography variant={'body2'} component={'span'}>
                <strong>{t('filters:to')}</strong> {dateToString(endDate)}
              </Typography>
            </Box>
            <Box>
              <Button variant='outlined' color='secondary' onClick={handleClear}>
                {t('common:actions.reset')}
              </Button>
            </Box>
          </Stack>
        ) : null}
      </DatePicker>
      <GlobalStyles
        styles={{
          'div.react-datepicker__current-month, div.react-datepicker-time__header, .react-datepicker-year-header': {
            color: Colors.shark,
            fontSize: fontSizes.medium,
            fontWeight: fontWeight['500'],
          },
          'div.react-datepicker-wrapper': {
            width: 'auto',
          },
          'div.react-datepicker-popper': {
            zIndex: themeZIndexes.tooltip,
          },
          'div.react-datepicker': {
            color: Colors.storm,
            fontFamily: defaultFontFamily,
          },
          'div.react-datepicker__header': {
            background: Colors.melrose,
            borderColor: Colors.iron,
          },
          'button.react-datepicker__navigation': {
            top: '16px',
          },
          'div.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--in-range)':
            {
              background: Colors.lavender,
              color: Colors.melrose,
              '&:hover': {
                background: Colors.primary,
                color: Colors.melrose,
              },
            },
          '.react-datepicker__day-name, .react-datepicker__time-name': {
            color: Colors.shark,
          },
          'div.react-datepicker__day': {
            color: Colors.storm,
          },
          'div.react-datepicker__day--in-range': {
            color: Colors.white,
            background: Colors.lavender,
            '&:hover': {
              background: Colors.primary,
            },
          },
          'div.react-datepicker__day--selected': {
            background: Colors.lavender,
            color: Colors.melrose,

            '&:hover': {
              background: Colors.brandPastel,
              color: Colors.melrose,
            },
          },
          'span.react-datepicker__navigation-icon:hover::before': {
            borderColor: Colors.brandPastel,
          },

          'span.react-datepicker__navigation-icon::before': {
            borderColor: Colors.lavender,
          },
        }}
      />
    </div>
  );
};
