import { IconUser } from 'kognia-ui/icons/IconUser';
import { useRef } from 'react';

import { AddUsersToEnvironmentsFormFeature } from 'pages/backoffice/features/users/add-users-to-environments-form/AddUsersToEnvironmentsForm.feature';
import { DialogNew } from 'shared/components/dialog-new';

interface Props {
  open: boolean;
  onClose: () => void;
}

export const AddUsersToEnvironmentsModal = ({ open, onClose }: Props) => {
  const formRef = useRef<HTMLFormElement>(null);

  const handleSubmit = () => {
    formRef.current?.requestSubmit();
  };

  return (
    <DialogNew
      maxWidth='md'
      fullWidth
      title='Add users to environments'
      icon={<IconUser size='small' />}
      onCancel={onClose}
      onClose={onClose}
      onSubmit={handleSubmit}
      buttonSubmitText={'Send'}
      open={open}
    >
      <AddUsersToEnvironmentsFormFeature ref={formRef} onSuccess={onClose} />
    </DialogNew>
  );
};
