import { Card, CardContent, CardHeader, Divider, List, Stack, styled, Typography } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';
import { TeamAvatar } from 'kognia-ui/components/avatar/TeamAvatar';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { useMetadataFixture } from 'pages/backoffice/api/fixtures/use-metadata-fixture';
import { usePlayersMappings } from 'pages/backoffice/api/use-players-mappings';
import { BackofficeContentContainer } from 'pages/backoffice/components/backoffice-content-container';
import { FixtureRecordingsList } from 'pages/backoffice/pages/fixtures/components/fixture-recordings-list';
import { useResetFixtureFormData } from 'pages/backoffice/pages/fixtures/fixture-form/config';
import { GridListItem } from 'pages/backoffice/pages/fixtures/styled';
import { FixturesPlayer, MetadataTeam } from 'pages/backoffice/types/fixture';
import { printSeasonName } from 'pages/backoffice/utils/print-season-name';
import Spinner from 'shared/components/spinner';
import { useDates } from 'shared/hooks/use-dates';
import { invariant } from 'shared/utils/invariant';

type Props = {
  team: MetadataTeam;
  players: FixturesPlayer[];
};

const GridListItemTeamPlayer = styled(GridListItem)(() => ({
  gridTemplateColumns: '1fr 1fr 1fr 1fr',
}));

const TeamCard = ({ team, players }: Props) => {
  const { data: mappings } = usePlayersMappings();

  return (
    <Card sx={{ flex: 1 }}>
      <CardContent>
        <Stack gap={2}>
          <Stack spacing={2}>
            <Stack direction={'row'} spacing={1} alignItems={'center'}>
              <TeamAvatar src={team.logoUrl} name={team.name} />
              <Typography sx={{ color: Colors.night, fontSize: fontSizes.large }}>{team.name}</Typography>
              <Typography sx={{ color: Colors.storm, fontSize: fontSizes.small }}>({team.abbreviation})</Typography>
              <Divider />
            </Stack>
            <Stack gap={1}>
              <List>
                <GridListItemTeamPlayer sx={{ fontWeight: fontWeight['500'], background: Colors.background }}>
                  <Typography sx={{ fontWeight: fontWeight['500'] }}>Number</Typography>
                  <Typography sx={{ fontWeight: fontWeight['500'] }}>Player name</Typography>
                  <Typography sx={{ fontWeight: fontWeight['500'] }}>Position</Typography>
                  <Typography sx={{ fontWeight: fontWeight['500'] }}>Side</Typography>
                </GridListItemTeamPlayer>
                {players.map((player) => (
                  <GridListItemTeamPlayer key={player.id}>
                    <Typography>{player.jerseyNumber}</Typography>
                    <Typography>{player.name}</Typography>
                    <Typography>
                      {mappings && player.position !== undefined && player.position >= 0
                        ? `${mappings.positions[player.position]} (${player.position})`
                        : ''}
                    </Typography>
                    <Typography>
                      {mappings && player.side !== undefined && player.side >= 0
                        ? `${mappings.sides[player.side]} (${player.side})`
                        : ''}
                    </Typography>
                  </GridListItemTeamPlayer>
                ))}
              </List>
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

const GridListItemPlayer = styled(GridListItem)(() => ({
  gridTemplateColumns: '1fr 1fr 1fr auto',
}));

export const FixtureSummary = () => {
  const { dateAndTimeToString } = useDates();
  const { id } = useParams();
  invariant(id);
  const { data, isLoading } = useMetadataFixture({ fixtureId: id });
  const resetFixtureFormData = useResetFixtureFormData();

  useEffect(() => {
    resetFixtureFormData();
  }, [resetFixtureFormData]);

  if (isLoading || !data) {
    return (
      <Stack justifyContent={'center'} alignItems={'center'} sx={{ padding: 4 }}>
        <Spinner />
      </Stack>
    );
  }
  return (
    <BackofficeContentContainer>
      <Stack gap={2}>
        <Typography variant={'h5'}>{data.name}</Typography>
        <Stack direction={'row'} gap={2}>
          <TeamCard team={data.homeTeam} players={data.players.filter((p) => p.teamId === data.homeTeam.id)} />
          <TeamCard team={data.awayTeam} players={data.players.filter((p) => p.teamId === data.awayTeam.id)} />
        </Stack>
        <Card>
          <CardHeader title={'Fixture details'} />
          <CardContent>
            <List>
              <GridListItemPlayer>
                <label>Name</label>
                <Typography>{data.name}</Typography>
              </GridListItemPlayer>
              <GridListItemPlayer>
                <label>Date</label>
                <Typography>{data.date ? dateAndTimeToString(data.date) : '-'}</Typography>
              </GridListItemPlayer>
              <GridListItemPlayer>
                <label>Season</label>
                <Typography>{printSeasonName(data.season)}</Typography>
              </GridListItemPlayer>
              <GridListItemPlayer>
                <label>Stage</label>
                <Typography>{data.stage.name}</Typography>
              </GridListItemPlayer>
              <GridListItemPlayer>
                <label>Score</label>
                <Typography>
                  {data.homeTeamScore} : {data.awayTeamScore}
                </Typography>
              </GridListItemPlayer>
              <GridListItemPlayer>
                <label>Penalty score</label>
                <Typography>
                  {data.homePenaltyScore} : {data.awayPenaltyScore}
                </Typography>
              </GridListItemPlayer>
              <GridListItemPlayer>
                <label>Duration</label>
                <Typography>{data.duration}</Typography>
              </GridListItemPlayer>
              <GridListItemPlayer>
                <label>Home team coach</label>
                <Typography>{data.homeCoach ? data.homeCoach.name : '-'}</Typography>
              </GridListItemPlayer>
              <GridListItemPlayer>
                <label>Away team coach</label>
                <Typography>{data.awayCoach ? data.awayCoach.name : '-'}</Typography>
              </GridListItemPlayer>
              <GridListItemPlayer>
                <label>Pitch Size</label>
                <Typography>
                  {data.pitchLength} x {data.pitchWidth}
                </Typography>
              </GridListItemPlayer>
              <GridListItemPlayer>
                <label>Match end type</label>
                <Typography>{data.matchEndType}</Typography>
              </GridListItemPlayer>
              {data.venue ? (
                <GridListItemPlayer>
                  <label>Venue</label>
                  <Typography>{data.venue.name}</Typography>
                </GridListItemPlayer>
              ) : null}
            </List>
          </CardContent>
        </Card>
        {data && data.recordings.length > 0 ? (
          <FixtureRecordingsList fixtureId={data.id} recordings={data.recordings} />
        ) : null}
      </Stack>
    </BackofficeContentContainer>
  );
};
