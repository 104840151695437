export enum KeyboardKeyOptions {
  SpaceBar,
  ArrowRight,
  ArrowLeft,
  Control,
  Command,
  Alt,
  Plus,
  Minus,
  ScrollUp,
  ScrollDown,
  o,
  c,
  QuestionMark,
  f,
}
