import { Box, styled } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';

import { CSS_EFFECTIVE_TIME_SEPARATOR_HALF_TIME } from 'pages/tactical-analysis/components/timeline/timeline-table/components/timeline-css-variables';

export const SeparatorContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isHalfTime' && prop !== 'isHidden',
})<{ isHalfTime: boolean; isHidden: boolean }>(({ theme, isHalfTime, isHidden }) => ({
  height: '100%',
  padding: theme.spacing(0, 0.25),
  background: theme.palette.common.white,
  overflow: 'hidden',

  ...(isHalfTime && {
    minWidth: `var(${CSS_EFFECTIVE_TIME_SEPARATOR_HALF_TIME})`,
    maxWidth: `var(${CSS_EFFECTIVE_TIME_SEPARATOR_HALF_TIME})`,
  }),

  ...(isHidden && {
    visibility: 'hidden',
  }),
}));

export const SeparatorContent = styled(Box)(({ theme }) => ({
  height: '100%',
  paddingTop: theme.spacing(1),
  paddingLeft: theme.spacing(1),
  color: theme.palette.text.secondary,
  textShadow: `1px 1px 1px ${theme.palette.common.white}`,
  // TODO add color to theme
  backgroundImage: `linear-gradient(135deg, ${theme.palette.common.white} 5.56%, ${Colors.athens} 5.56%, ${Colors.athens} 50%, ${theme.palette.common.white} 50%, ${theme.palette.common.white} 55.56%, ${Colors.athens} 55.56%, ${Colors.athens} 100%)`,
  backgroundSize: '12.73px 12.73px',
  // TODO add to theme
  fontSize: fontSizes.small,
  lineHeight: 1.67,
  fontWeight: theme.typography.fontWeightMedium,
}));

export const EffectiveTimeSeparatorsWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  display: 'flex',
  width: '100%',
  height: '100%',
  padding: theme.spacing(0, 3),
  userSelect: 'none',
}));
