import { CircularProgress, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { IconTag } from 'kognia-ui/icons/IconTag';
import { useTranslation } from 'react-i18next';

import styles from 'pages/recordings-list/components/live-recording-card/live-recording-info/LiveRecordingInfo.module.scss';

const useCircularLoaderStyles = makeStyles(() => ({
  circle: {
    color: '#205CFA',
    strokeLinecap: 'round',
  },
}));

const LiveRecordingInfo = () => {
  const { t } = useTranslation();
  const classes = useCircularLoaderStyles();

  return (
    <Grid item xs={12} sm={'auto'} className={styles.container}>
      <div className={styles.liveTagIcon}>
        <div className={styles.spinnerContainer}>
          <CircularProgress classes={{ circle: classes.circle }} thickness={4} size={28} className={styles.spinner} />
        </div>
        <div className={styles.tagIcon}>
          <IconTag size='small' />
        </div>
      </div>
      <span className={styles.title}>{t('recordings-list:live-recording-card.live-recording')}</span>
    </Grid>
  );
};

export default LiveRecordingInfo;
