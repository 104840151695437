import { Box, Popover } from '@mui/material';
import { IconChevronDown } from 'kognia-ui/icons/IconChevronDown';
import React, { useState } from 'react';

import {
  SelectButton,
  sxSelectPaperStyles,
} from 'shared/components/video-player/video-player-bar/video-source-select/index.styled';
import {
  VideoSourceSelectList,
  VideoSourceSelectListItem,
  VideoSourceSelectListItemView,
} from 'shared/components/video-player/video-player-bar/video-source-select-list';

interface Props {
  options: VideoSourceSelectListItem[];
  selected: VideoSourceSelectListItemView;
  disabled: boolean;
}

export const VideoSourceSelect = ({ options, selected, disabled }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'video-source-select' : undefined;

  return (
    <Box>
      <SelectButton
        aria-describedby={id}
        variant='text'
        onClick={handleClick}
        endIcon={disabled ? null : <IconChevronDown size='small' sx={{ color: 'common white' }} />}
        disabled={disabled}
      >
        {selected.label}
      </SelectButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        PaperProps={{ sx: sxSelectPaperStyles }}
      >
        <VideoSourceSelectList options={options} selected={selected} onClose={handleClose} />
      </Popover>
    </Box>
  );
};
