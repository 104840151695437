import { UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { playerStats } from 'api/routes';
import { PlayerStats } from 'shared/types/player/player';

const PLAYER_STATS_KEY = 'player-stats';

type Options = {
  playerId: string;
  competitionId: string;
  seasonYear: number;
};

const STALE_TIME_1H = 1000 * 60 * 60;

export const usePlayerStats = (options: Options): UseQueryResult<PlayerStats> => {
  return useFetchRequest<PlayerStats>({
    url: playerStats(options.playerId, options.competitionId, options.seasonYear),
    queryRef: [PLAYER_STATS_KEY, options],
    options: {
      enabled: !!options.playerId && !!options.competitionId && !!options.seasonYear,
      staleTime: STALE_TIME_1H,
      retry: false,
    },
    transformer: (response: PlayerStats): PlayerStats => response,
  });
};
