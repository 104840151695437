import React, { ReactElement, ReactNode } from 'react';
import {
  DragDropContext,
  DraggableChildrenFn,
  Droppable,
  DroppableMode,
  DroppableProvided,
  DropResult,
} from 'react-beautiful-dnd';

export type SortableItem = {
  id: string;
};

export const reorder = <T extends SortableItem>(list: T[], startIndex: number, endIndex: number) => {
  if (startIndex < 0 || endIndex < 0) return [...list];
  if (startIndex > list.length - 1 || endIndex > list.length - 1) return [...list];

  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

interface Props<T> {
  children?: ReactNode;
  id: string;
  items: T[];
  onDragEnd?: () => void;
  onDragStart?: () => void;
  onOrderChange: (playlistItemId: string, currentIndex: number, nextIndex: number) => void;
  mode?: DroppableMode | undefined;
  renderClone?: DraggableChildrenFn | undefined;
  renderChildren?: (droppableProvided: DroppableProvided) => ReactElement;
  isDropDisabled?: boolean;
  direction?: 'vertical' | 'horizontal';
}

const SortableItemList = <T extends SortableItem>({
  id = 'sortable-list',
  items,
  onOrderChange,
  onDragStart,
  onDragEnd,
  mode = 'standard',
  renderClone,
  renderChildren,
  children,
  isDropDisabled = false,
  direction = 'vertical',
}: Props<T>) => {
  const handleDragStart = () => {
    if (onDragStart) onDragStart();
  };

  const handleDragEnd = (result: DropResult) => {
    if (onDragEnd) onDragEnd();

    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    onOrderChange(items[result.source.index].id, result.source.index, result.destination.index);
  };

  return (
    <DragDropContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
      <Droppable
        droppableId={id}
        mode={mode}
        renderClone={renderClone}
        isDropDisabled={isDropDisabled}
        direction={direction}
      >
        {mode === 'virtual' && renderChildren && !children
          ? (droppableProvided: DroppableProvided) => renderChildren(droppableProvided)
          : (provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {children}
                {provided.placeholder}
              </div>
            )}
      </Droppable>
    </DragDropContext>
  );
};

export default SortableItemList;
