import { Popper } from '@mui/material';

import FiltersSummary from 'pages/tactical-analysis/components/tactical-analysis/filters/components/filters-summary';
import EventsEndingEpisodeSummary from 'pages/tactical-analysis/components/tactical-analysis/filters/components/filters-summary/events-ending-episode-summary';
import EventsStartingEpisodeSummary from 'pages/tactical-analysis/components/tactical-analysis/filters/components/filters-summary/events-starting-episode-summary';
import { ScenariosInsideEpisodeSummary } from 'pages/tactical-analysis/components/tactical-analysis/filters/components/filters-summary/scenarios-inside-episode-summary';
import { MatchTeams } from 'shared/types';
import { RecordingsFilters } from 'shared/types/recording/types';

interface Props {
  filters: RecordingsFilters;
  teams: MatchTeams;
  recordingId: string;
  hasTeamFocus?: boolean;
  summaryAnchor: HTMLElement | null;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}

const FiltersBarSummary = ({
  filters,
  teams,
  recordingId,
  hasTeamFocus,
  summaryAnchor,
  onMouseEnter,
  onMouseLeave,
}: Props) => {
  return (
    <Popper
      placement='top-start'
      anchorEl={summaryAnchor}
      open={Boolean(summaryAnchor)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      sx={{ zIndex: (theme) => theme.zIndex.drawer }}
    >
      <FiltersSummary>
        {filters.eventsStarting ? (
          <EventsStartingEpisodeSummary
            hasTeamFocus={hasTeamFocus}
            contained
            teams={teams}
            filters={filters.eventsStarting}
          />
        ) : null}
        {filters.scenariosOrTacticsInside ? (
          <ScenariosInsideEpisodeSummary
            contained
            filters={filters.scenariosOrTacticsInside}
            hasTeamFocus={hasTeamFocus}
            recordingId={recordingId}
            teams={teams}
          />
        ) : null}
        {filters.eventsEnding ? (
          <EventsEndingEpisodeSummary
            hasTeamFocus={hasTeamFocus}
            contained
            teams={teams}
            filters={filters.eventsEnding}
          />
        ) : null}
      </FiltersSummary>
    </Popper>
  );
};

export default FiltersBarSummary;
