import { Box, Card, CardContent, List, Stack, Typography } from '@mui/material';
import { Colors, fontWeight } from 'kognia-ui';

import { FixtureRecording } from 'pages/backoffice/pages/fixtures/components/fixture-recording';
import { GridListItemRecording } from 'pages/backoffice/pages/fixtures/components/grid-list-item-recording';
import { MetadataRecordingSummary } from 'pages/backoffice/types/recordings';

type Props = {
  recordings: MetadataRecordingSummary[];
  fixtureId: string;
};

export const FixtureRecordingsList = ({ recordings, fixtureId }: Props) => {
  return (
    <Card>
      <CardContent>
        <Stack gap={1}>
          <Typography variant='h6'>Recordings</Typography>
          <List>
            <GridListItemRecording sx={{ fontWeight: fontWeight['500'], background: Colors.background }}>
              <Box>Id</Box>
              <Box>client Id</Box>
            </GridListItemRecording>
            {recordings.map((recording) => (
              <FixtureRecording fixtureId={fixtureId} key={recording.id} recording={recording} />
            ))}
          </List>
        </Stack>
      </CardContent>
    </Card>
  );
};
