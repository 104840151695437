import { clientsQueryKey } from 'api/backoffice/client/hooks/use-clients/useClients';
import { USERS_QUERY_KEY } from 'api/backoffice/user/use-kognia-users';
import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { queryClient } from 'api/config';
import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { userBatchClientIdsUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';

interface Response {
  failedUpdates: KogniaUser[];
  successfulUpdates: KogniaUser[];
}

export const useRemoveUsersFromEnvironments = () => {
  const triggerNotification = useNotifications();
  const { mutate, isPending, isError, isSuccess } = useMutationRequest<Response, Response>({
    type: HTTPMethod.DELETE,
    errorMessage: 'Error removing client(s) from environment(s)',
  });

  const removeUsersFromEnvironments = ({
    kogniaUsers,
    clientIds,
    onSuccess,
  }: {
    kogniaUsers: KogniaUser[];
    clientIds: string[];
    onSuccess?: () => void;
  }) => {
    mutate(
      {
        url: userBatchClientIdsUrl,
        data: { data: { clientIds, userIds: kogniaUsers.map((user) => user.id) } },
      },
      {
        onSuccess: (response) => {
          const failedUsersEmails = response.failedUpdates.map((user) => user.email);

          if (failedUsersEmails.length) {
            triggerNotification({
              type: NotificationType.ERROR,
              message: `${failedUsersEmails.length} of ${
                kogniaUsers.length
              } user(s) were not removed from environment(s) (${failedUsersEmails.join(', ')})`,
            });
          } else {
            triggerNotification({
              type: NotificationType.SUCCESS,
              message: 'Client(s) removed from environment(s)',
            });
          }

          queryClient.invalidateQueries({
            predicate: (query) => query.queryKey[0] === clientsQueryKey || query.queryKey[0] === USERS_QUERY_KEY,
          });
          onSuccess && onSuccess();
        },
      },
    );
  };

  return { removeUsersFromEnvironments, isPending, isError, isSuccess };
};
