import queryString from 'query-string';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { logoutUrl, returnToQueryParam } from 'api/routes';
import { routes } from 'kognia/router/routes';
import { RETURN_TO_QUERY_PARAM_NAME } from 'shared/constants/queryParams';

const logoutRedirect = (logoutUrl: string | undefined) => {
  if (logoutUrl) window.location.href = logoutUrl;
};

const fetchQueryRef = ['logoutAccount'];

export const useLogOutAccount = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParamValues = queryString.parse(search);
  const externalReturnToQueryParam = queryParamValues[RETURN_TO_QUERY_PARAM_NAME];
  const queryParam =
    typeof externalReturnToQueryParam === 'string' && externalReturnToQueryParam
      ? externalReturnToQueryParam
      : returnToQueryParam;
  const url = `${logoutUrl}?${RETURN_TO_QUERY_PARAM_NAME}=${queryParam}`;

  const fetchResponse = useFetchRequest({
    queryRef: fetchQueryRef,
    url,
    errorMessage: t('api:use-logout-account.error'),
    onSuccess: (response) => {
      logoutRedirect(response?.logoutUrl);
    },
    onError: () => {
      navigate(routes.HOME_PAGE, { replace: true });
    },
    options: {
      enabled: false,
      retry: false,
    },
  });

  const redirect = useCallback(() => logoutRedirect(queryParam), [queryParam]);

  return { ...fetchResponse, redirect };
};
