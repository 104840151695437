import { useAnimation } from 'framer-motion';
import React, { memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDeletePlaylistItem } from 'api/playlist/useDeletePlaylistItem';
import { useUpdatePlaylistItem } from 'api/playlist/useUpdatePlaylistItem';
import { useMapVideos } from 'entities/playlist/hooks/use-map-videos/useMapVideos';
import { useHandlePlaylistItemClick } from 'entities/playlist/hooks/useHandlePlaylistItemClick';
import { useHandleSetPlaylistItem } from 'entities/playlist/hooks/useHandleSetPlaylistItem';
import { PlaylistItemWrapper } from 'entities/playlist/ui/PlaylistItemWrapper';
import { generateCopyToPlaylistItem } from 'entities/playlist/util/generateCopyToPlaylistItem';
import { CopyToDialog } from 'features/playlist/CopyToDialog';
import { DownloadPlaylistItemsModal } from 'features/playlist/download-playlist-items-modal/DownloadPlaylistItemsModal';
import { PlaylistItemContent } from 'features/playlist/playlist-item-content/PlaylistItemContent';
import { useUserPlaylistItemOptions } from 'features/playlist/user-playlist-items-list/ui/user-playlist-item/hooks/useUserPlaylistItemOptions';
import RenameDialog from 'features/playlist/user-playlist-items-list/ui/user-playlist-item/ui/rename-dialog/RenameDialog';
import ConfirmPopoverDialog from 'shared/components/confirm-popover-dialog';
import { useIsCurrentPlaylistItem, useVideoPlayerActions } from 'shared/components/video-player';
import { PlaylistItemType } from 'shared/components/video-player/types';
import { Playlist } from 'shared/types/index';

interface Props {
  isDisabled?: boolean;
  isEditing?: boolean;
  playlist: Playlist;
  playlistItem: PlaylistItemType;
  setTrimmingPlaylistItem: (id: string) => void;
  videoIndex: number;
  enabledBulkMode?: boolean;
  onSelect?: (value: string) => void;
  isChecked?: boolean;
  onPlaylistItemDelete?: (id: string) => void;
  onPlaylistItemDuplicate?: () => void;
}

export const UserPlaylistItem = memo(
  ({
    isDisabled = false,
    isEditing = false,
    playlist,
    playlistItem,
    setTrimmingPlaylistItem,
    videoIndex,
    isChecked = false,
    onPlaylistItemDelete,
    onPlaylistItemDuplicate,
  }: Props) => {
    const { t } = useTranslation();
    const mapVideos = useMapVideos();
    const isCurrentPlaylistItem = useIsCurrentPlaylistItem(playlistItem.id);
    const actions = useVideoPlayerActions();
    const [copyToAnchor, setCopyToAnchor] = useState<HTMLElement | null>(null);

    const [isDeleted, setIsDeleted] = useState(false);
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
    const [isOpenRenameDialog, setIsOpenRenameDialog] = useState(false);
    const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
    const { deletePlaylistItem, isPending, isError } = useDeletePlaylistItem(playlist.id);
    const kebabRef = useRef<HTMLDivElement | null>(null);
    const isEditingMode = Boolean(isOpenDeleteDialog || isOpenRenameDialog || isEditing);

    const { updatePlaylistItem } = useUpdatePlaylistItem(playlist.id);
    const handleSetPlaylistItem = useHandleSetPlaylistItem(playlistItem);
    const handleItemClick = useHandlePlaylistItemClick(playlistItem, playlist.id);

    const getMenuOptions = useUserPlaylistItemOptions(
      playlist,
      playlistItem,
      setTrimmingPlaylistItem,
      setCopyToAnchor,
      setIsOpenRenameDialog,
      setIsOpenDeleteDialog,
      setIsDownloadModalOpen,
      onPlaylistItemDuplicate,
    );

    const handleUpdatePlaylistItem = useCallback(
      (playlistItemId: string, data: { [key: string]: any }) => {
        updatePlaylistItem(playlistItemId, data, (playlist: Playlist) => {
          actions.updatePlaylistItems(mapVideos(playlist));
        });
      },
      [actions, mapVideos, updatePlaylistItem],
    );

    const handleRenameDialogClose = useCallback(() => {
      actions.resumeStandBy();
    }, [actions]);

    const handleCloseCopyToDialog = useCallback(() => {
      actions.resumeStandBy();
      setCopyToAnchor(null);
    }, [actions]);

    const handleCloseDownloadModal = useCallback(() => {
      setIsDownloadModalOpen(false);
      actions.resumeStandBy();
    }, [actions]);

    const controls = useAnimation();

    const handleConfirmDelete = useCallback(async () => {
      setIsDeleted(true);
      await controls.start({ opacity: 0 });
      deletePlaylistItem(playlistItem.id, () => {
        actions.resumeStandBy();
        actions.removePlaylistItem(playlistItem.id);

        if (onPlaylistItemDelete) {
          onPlaylistItemDelete(playlistItem.id);
        }
      });

      if (isError) {
        setIsDeleted(false);
      }
    }, [actions, controls, deletePlaylistItem, isError, onPlaylistItemDelete, playlistItem.id]);

    const showProgressBar = isCurrentPlaylistItem && !isDisabled && !isDeleted;

    return (
      <>
        <PlaylistItemWrapper
          isDraggable
          isCurrent={isCurrentPlaylistItem && !isPending}
          isDeleting={isDeleted}
          isEditing={isEditingMode}
          isDisabled={isDisabled}
          onClick={handleItemClick}
          direction='row'
          justifyContent='space-between'
          title={`${playlistItem.name} | ${playlistItem.recordingName} (${playlistItem.recordingMatchday})`}
          data-testid={'playlist-item'}
        >
          <PlaylistItemContent
            isChecked={isChecked}
            isEditingMode={isEditingMode}
            getMenuOptions={getMenuOptions}
            onClick={handleSetPlaylistItem}
            playlistId={playlist.id}
            playlistItem={playlistItem}
            showProgressBar={showProgressBar}
            videoIndex={videoIndex}
            kebabRef={kebabRef}
          />
        </PlaylistItemWrapper>

        <ConfirmPopoverDialog
          anchorEl={kebabRef.current}
          cancelLabel={t('common:actions.cancel')}
          confirmLabel={t('common:actions.remove')}
          description={t('playlist-detail:confirm-permanently-remove-clip')}
          isOpen={isOpenDeleteDialog}
          onConfirm={handleConfirmDelete}
          setIsOpen={setIsOpenDeleteDialog}
        />

        <RenameDialog
          onClose={handleRenameDialogClose}
          videoIndex={videoIndex + 1}
          updatePlaylistItem={handleUpdatePlaylistItem}
          setIsOpen={setIsOpenRenameDialog}
          playlistItem={playlistItem}
          isOpen={isOpenRenameDialog}
        />

        {isDownloadModalOpen ? (
          <DownloadPlaylistItemsModal
            downloadType={'playlistItems'}
            playlistData={{
              ...playlist,
              playlistItems: playlist.playlistItems.filter((item) => item.id === playlistItem.id),
            }}
            onClose={handleCloseDownloadModal}
          />
        ) : null}

        <CopyToDialog
          playlistItems={[generateCopyToPlaylistItem(playlistItem)]}
          onClose={handleCloseCopyToDialog}
          anchorEl={copyToAnchor}
        />
      </>
    );
  },
);

UserPlaylistItem.displayName = 'EditablePlaylistItem';
