import { Box, styled } from '@mui/material';

export interface ContainerWrapperProps {
  backgroundImage?: string;
  backgroundSize?: string;
  backgroundColor?: string;
}

export const ContainerWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'backgroundImage' && prop !== 'backgroundSize' && prop !== 'backgroundColor',
})<ContainerWrapperProps>(({ backgroundImage, backgroundSize, backgroundColor }) => ({
  display: 'flex',
  maxWidth: '100%',
  flexGrow: 1,
  justifyContent: 'center',
  ...(backgroundImage && {
    backgroundImage: `url("${backgroundImage}")`,
  }),
  ...(backgroundSize && {
    backgroundSize: backgroundSize,
  }),
  ...(backgroundColor && {
    backgroundColor: backgroundColor,
  }),
}));

interface ContentProps {
  fullScreen: boolean;
}

export const Content = styled(Box, { shouldForwardProp: (prop) => prop !== 'fullScreen' })<ContentProps>(
  ({ theme, fullScreen }) => ({
    [theme.breakpoints.up('md')]: {
      maxWidth: '992px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '1200px',
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
    width: '100%',
    ...(fullScreen && {
      height: '100vh',
      maxWidth: '100% !important',
    }),
    ...(!fullScreen && {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingRight: theme.spacing(6),
      paddingLeft: theme.spacing(6),
    }),
  }),
);
