import { Dispatch, SetStateAction, useState } from 'react';

import { queryClient } from 'api/config';
import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { generatePlayersUrl } from 'api/routes';
import { transformPlayers } from 'entities/dashboard/api/use-player-infinity-query/utils/playerTransformer';
import { SortDirection } from 'shared/types/filters/types';
import { Player, PlayerApiFilters, PlayerBackendApiResponse } from 'shared/types/player/player';

const FETCH_PLAYERS_QUERY_KEY = 'fetchPlayers';

interface Params {
  enabled?: boolean;
  pageSize?: number;
  initialFilters?: PlayerApiFilters;
}

export type PlayersReturnType = InfinityQueryResult<Player, PlayerApiFilters> & {
  setEnabled: Dispatch<SetStateAction<boolean>>;
};

export const invalidatePlayers = () => {
  return queryClient.invalidateQueries({ queryKey: [FETCH_PLAYERS_QUERY_KEY] });
};

export const usePlayerInfinityQuery = ({
  enabled = true,
  pageSize = 10,
  initialFilters,
}: Params): PlayersReturnType => {
  const [isQueryEnabled, setIsQueryEnabled] = useState<boolean>(enabled);

  return {
    ...useInfinityQuery<Player, PlayerApiFilters, PlayerBackendApiResponse>({
      generateUrl: generatePlayersUrl,
      key: FETCH_PLAYERS_QUERY_KEY,
      options: {
        size: pageSize,
        sort: 'name',
        sortDirection: SortDirection.DESC,
      },
      initialFilters: {
        name: '',
        isFavorite: initialFilters?.isFavorite,
      },
      transformer: transformPlayers,
      queryOptions: {
        enabled: isQueryEnabled,
      },
    }),
    setEnabled: setIsQueryEnabled,
  };
};
