import { PlaylistItemType } from 'shared/components/video-player/types';

export const getPreviousPlaylistItem = (playlistItemId: string, playlistItems: PlaylistItemType[]) => {
  const currentPlaylistItemIndex = playlistItems.findIndex(
    (playlistItem: PlaylistItemType) => playlistItem.id === playlistItemId,
  );
  const previousVideoIndex = currentPlaylistItemIndex - 1;

  return playlistItems[previousVideoIndex < 0 ? 0 : previousVideoIndex];
};
