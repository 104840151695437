import { Box, alpha, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

export const HeaderAddClipsContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.common.white,
  // TODO get from theme
  border: `1px solid ${Colors.ghost}`,
  borderRadius: '4px',
  boxShadow: `0 4px 8px ${alpha(theme.palette.text.primary, 0.2)}`,
}));
