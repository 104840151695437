import { useCallback } from 'react';

import { useAddPlayersToFixture } from 'pages/backoffice/api/fixtures/use-add-players-to-fixture';
import { useCreateMetadataFixture } from 'pages/backoffice/api/fixtures/use-create-metadata-fixture';
import { useDeleteMetadataFixture } from 'pages/backoffice/api/fixtures/use-delete-metadata-fixture';
import { FixtureInfoSchema } from 'pages/backoffice/pages/fixtures/fixture-form/forms';
import { NewFixtureTeam } from 'pages/backoffice/pages/fixtures/fixture-form/types';
import { MetadataFixture } from 'pages/backoffice/types/fixture';
import { FixtureMatchEndType } from 'shared/types/fixtures/fixtures';

export const useCreateNewFixtureWithPlayers = (onSuccess: (fixture: MetadataFixture) => void) => {
  const { create } = useCreateMetadataFixture();
  const { deleteMetadataFixture } = useDeleteMetadataFixture({ successMessage: undefined });
  const { create: addPlayersToFixture } = useAddPlayersToFixture({ successMessage: 'Fixture created' });

  return useCallback(
    (fixtureInfo: FixtureInfoSchema, homeTeam: NewFixtureTeam, awayTeam: NewFixtureTeam) => {
      create({
        data: {
          name: fixtureInfo.name,
          duration: fixtureInfo.duration,
          home_team_score: fixtureInfo.scoreHomeTeam,
          away_team_score: fixtureInfo.scoreOpponentTeam,
          pitch_length: fixtureInfo.pitchLength,
          pitch_width: fixtureInfo.pitchWidth,
          home_penalty_score: 0,
          away_penalty_score: 0,
          match_end_type: FixtureMatchEndType.NORMAL_TIME,
          home_team_uuid: homeTeam?.team.id ?? '',
          away_team_uuid: awayTeam?.team.id ?? '',
          stage_uuid: fixtureInfo.stageId,
          ...(fixtureInfo?.homeCoach?.id ? { home_coach_uuid: fixtureInfo.homeCoach.id } : {}),
          ...(fixtureInfo?.awayCoach?.id ? { away_coach_uuid: fixtureInfo.awayCoach.id } : {}),
          ...(fixtureInfo.venue ? { venue_uuid: fixtureInfo.venue.id } : {}),
          ...(fixtureInfo.date ? { date: fixtureInfo.date } : {}),
        },
        onSuccess: (fixture: MetadataFixture) => {
          addPlayersToFixture({
            onSuccess: () => {
              onSuccess(fixture);
            },
            onError: () => {
              deleteMetadataFixture({ id: fixture.id });
            },
            fixtureId: fixture.id,
            players: [
              ...homeTeam.players.map((p) => ({
                jersey_number: p.jerseyNumber ?? 0,
                side: p.side,
                position: p.position,
                player_uuid: p.id,
                team_uuid: homeTeam.team.id,
              })),
              ...awayTeam.players.map((p) => ({
                jersey_number: p.jerseyNumber ?? 0,
                side: p.side,
                position: p.position,
                player_uuid: p.id,
                team_uuid: awayTeam.team.id,
              })),
            ],
          });
        },
      });
    },
    [deleteMetadataFixture, addPlayersToFixture, create, onSuccess],
  );
};
