import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback, useEffect } from 'react';
import { generatePath, matchPath, useNavigate, useParams } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { newFixtureData } from 'pages/backoffice/pages/fixtures/fixture-form/atoms';
import { FixtureInfoSchema } from 'pages/backoffice/pages/fixtures/fixture-form/forms';
import { NewFixtureTeam } from 'pages/backoffice/pages/fixtures/fixture-form/types';
import { MatchTeamTypes } from 'shared/types/match';

export const NEW_FIXTURES_STEPS = ['home-team', 'away-team', 'fixture-info'] as const;

export const NEW_FIXTURE_FORM_STEPS = [
  routes.BACKOFFICE_FIXTURE_FORM_STEP_1,
  routes.BACKOFFICE_FIXTURE_FORM_STEP_2,
  routes.BACKOFFICE_FIXTURE_FORM_STEP_3,
  routes.BACKOFFICE_NEW_FIXTURE_SUMMARY,
];

export const FORM_IDS = ['home-team-form', 'opponent-team-form', 'fixture-info-form'];

export const getCurrentStepFromPathName = (pathname: string) => {
  if (matchPath({ path: NEW_FIXTURE_FORM_STEPS[0] }, pathname)) return 0;
  if (matchPath({ path: NEW_FIXTURE_FORM_STEPS[1] }, pathname)) return 1;
  if (matchPath({ path: NEW_FIXTURE_FORM_STEPS[2] }, pathname)) return 2;
  if (matchPath({ path: NEW_FIXTURE_FORM_STEPS[3] }, pathname)) return 3;

  return 0;
};
export const useIsCreateFixtureStepValid = () => {
  const fixtureData = useAtomValue(newFixtureData);

  return useCallback(
    (step: number) => {
      if (step === 0) return true;
      if (fixtureData && fixtureData[MatchTeamTypes.HOME] && step === 1) return true;
      if (fixtureData && fixtureData[MatchTeamTypes.HOME] && fixtureData[MatchTeamTypes.OPPONENT] && step === 2)
        return true;

      return false;
    },
    [fixtureData],
  );
};

export const useIsUpdateCreateFixtureStepValid = () => {
  const fixtureData = useAtomValue(newFixtureData);

  return useCallback(() => {
    return Boolean(
      fixtureData &&
        fixtureData[MatchTeamTypes.HOME] &&
        fixtureData[MatchTeamTypes.OPPONENT] &&
        fixtureData.fixtureInfo,
    );
  }, [fixtureData]);
};

export const useCheckValidateStep = (step: number) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const checkIsCreateFixtureStepValid = useIsCreateFixtureStepValid();
  const checkIsUpdateFixtureStepValid = useIsUpdateCreateFixtureStepValid();

  useEffect(() => {
    !id && !checkIsCreateFixtureStepValid(step) && navigate(generatePath(routes.BACKOFFICE_FIXTURE_FORM_STEP_1));
    id && !checkIsUpdateFixtureStepValid() && navigate(generatePath(routes.BACKOFFICE_EDIT_FIXTURE_FORM, { id }));
  }, [id, checkIsUpdateFixtureStepValid, checkIsCreateFixtureStepValid, step, navigate]);
};

export function useTeamData(teamType: MatchTeamTypes): NewFixtureTeam | undefined {
  const fixtureData = useAtomValue(newFixtureData);

  return fixtureData ? fixtureData[teamType] : undefined;
}

export function useFixtureInfo(): FixtureInfoSchema | undefined {
  const fixtureData = useAtomValue(newFixtureData);

  return fixtureData ? fixtureData.fixtureInfo : undefined;
}

export function useSetTeamData(teamType: MatchTeamTypes) {
  const setNewFixtureData = useSetAtom(newFixtureData);

  return useCallback(
    (teamData: NewFixtureTeam) => {
      setNewFixtureData((fixtureData) => {
        return { ...fixtureData, [teamType]: teamData };
      });
    },
    [teamType, setNewFixtureData],
  );
}

export function useResetFixtureFormData() {
  return useResetAtom(newFixtureData);
}

export function useSetFixtureInfo() {
  useResetAtom(newFixtureData);
  const [fixtureData, setNewFixtureData] = useAtom(newFixtureData);

  return useCallback(
    (fixtureInfo: FixtureInfoSchema) => setNewFixtureData({ ...fixtureData, fixtureInfo }),
    [fixtureData, setNewFixtureData],
  );
}

export function useSetFixtureData() {
  return useSetAtom(newFixtureData);
}
