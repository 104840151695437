import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { useFetchRecordingPlaylists } from 'api/playlist/useFetchRecordingPlaylists';
import RecordingPlaylistsPage from 'pages/recording-playlists/components/recording-playlists-page';
import RecordingPlaylistsSkeleton from 'pages/recording-playlists/components/recording-playlists-skeleton';
import Container from 'shared/components/container';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import { invariant } from 'shared/utils/invariant';

export const RecordingPlaylistsContainer = () => {
  const { id } = useParams();
  invariant(id);
  const { t } = useTranslation();
  const branding = useBranding();
  const { isLoading, data } = useFetchRecordingPlaylists(id, false);

  useEffect(() => {
    document.title = t('common:metas.title.recording-playlist', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  return (
    <Container>
      {isLoading && <RecordingPlaylistsSkeleton />}
      {!isLoading && data && <RecordingPlaylistsPage playlists={data} matchId={id} />}
    </Container>
  );
};
