import React from 'react';

import { FormFieldProps } from 'pages/backoffice/pages/annotation/components/form-fields/types';
import { AnnotationFormLabels } from 'pages/backoffice/pages/annotation/utils/form-fields-validation';
import { AnnotationFormFieldsNames } from 'pages/backoffice/pages/annotation/utils/form-fields-validation/types';
import { FormInputField } from 'shared/components/form/form-input-field';

export const EndIxField = ({ errors = {}, register }: FormFieldProps) => {
  return (
    <FormInputField
      id={AnnotationFormFieldsNames.END_IX}
      label={AnnotationFormLabels[AnnotationFormFieldsNames.END_IX]}
      error={!!errors[AnnotationFormFieldsNames.END_IX]}
      fullWidth
      helperText={errors[AnnotationFormFieldsNames.END_IX] && <>{errors[AnnotationFormFieldsNames.END_IX]?.message}</>}
      {...register(AnnotationFormFieldsNames.END_IX)}
    />
  );
};
