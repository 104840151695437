import { styled, TableRow } from '@mui/material';

export const BodyTableRow = styled(TableRow)(({ theme }) => ({
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:focus': {
    outline: 'none',
    backgroundColor: theme.palette.action.selected,
  },
  '& td': { padding: 0, borderBottom: `1px solid ${theme.palette.divider}` },
}));
