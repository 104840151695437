import { Stack } from '@mui/material';
import { Suspense, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { PlayerProfile } from 'pages/player-profile/ui/PlayerProfile';
import Container from 'shared/components/container';
import { ProfileSearchBarWrapper } from 'shared/components/profile-search-bar/ProfileSearchBar';
import Spinner from 'shared/components/spinner';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import { invariant } from 'shared/utils/invariant';
import { DashboardSearchWidget } from 'widgets/dashboard/dashboard-search/DashboardSearch.widget';

export function PlayerProfilePage() {
  const { playerId } = useParams();
  invariant(playerId);
  const { t } = useTranslation('common');
  const branding = useBranding();

  useEffect(() => {
    document.title = t('common:metas.title.explore', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  return (
    <Container fullScreen>
      <Stack height={'100%'}>
        <ProfileSearchBarWrapper>
          <DashboardSearchWidget width={362} size={'small'} />
        </ProfileSearchBarWrapper>
        <Suspense fallback={<Spinner size={'large'} isFullPage />}>
          <PlayerProfile playerId={playerId} />
        </Suspense>
      </Stack>
    </Container>
  );
}
