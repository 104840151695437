import { Stack, Typography } from '@mui/material';
import { TeamAvatar } from 'kognia-ui/components/avatar/TeamAvatar';

interface Props {
  name: string;
  logoUrl: string | null;
}

export const TeamRow = ({ logoUrl, name }: Props) => {
  return (
    <Stack p={({ spacing }) => spacing(0.8, 3)} width='100%' direction='row' alignItems='center' gap={1}>
      <TeamAvatar src={logoUrl} />

      <Typography variant='body2' component='span'>
        {name}
      </Typography>
    </Stack>
  );
};
