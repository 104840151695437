import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

type LinkWithExternalProps = {
  to: string;
  onClick: ((event: any) => any) | undefined;
  children: ReactNode;
  className?: string;
};

const LinkWithExternal = ({ to, children, ...props }: LinkWithExternalProps) => {
  // It is intended to be an external link
  if (/^https?:\/\//.test(to))
    return (
      <a href={to} {...props} target='_blank' rel='noopener noreferrer'>
        {children}
      </a>
    );

  // Finally, it is an internal link
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

export default LinkWithExternal;
