import classNames from 'classnames';

import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import styles from 'pages/tactical-analysis/components/timeline/timeline-table/components/clip-not-effective-time/ClipNotEffectiveTime.module.scss';
import { NotEffectiveTimeClipsList } from 'pages/tactical-analysis/components/timeline/timeline-table/components/no-effective-time-clips-list';
import {
  CSS_CLIP_ZOOM_WIDTH,
  CSS_EFFECTIVE_TIME_SEPARATOR,
  CSS_EFFECTIVE_TIME_SEPARATOR_HALF_TIME,
} from 'pages/tactical-analysis/components/timeline/timeline-table/components/timeline-css-variables';

type Props = {
  clip: Clip;
  recordingId: string;
};

export const ClipNotEffectiveTime = ({ clip, recordingId }: Props) => {
  return (
    <div
      id={`${clip.id} ${clip.type}`}
      className={classNames(styles.container, { [styles.isHalfTime]: clip.title })}
      style={{
        width: `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.endTime - clip.startTime})`,
        minWidth: `var(${clip.title ? CSS_EFFECTIVE_TIME_SEPARATOR_HALF_TIME : CSS_EFFECTIVE_TIME_SEPARATOR})`,
        maxWidth: `var(${clip.title ? CSS_EFFECTIVE_TIME_SEPARATOR_HALF_TIME : CSS_EFFECTIVE_TIME_SEPARATOR})`,
      }}
    >
      {clip.clips && clip.clips.length > 0 ? <NotEffectiveTimeClipsList recordingId={recordingId} clip={clip} /> : null}
    </div>
  );
};
