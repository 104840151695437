import { Skeleton } from '@mui/material';

import { StatsTitle } from 'features/dashboard/player-profile-stats/ui/StatTitle';
import { StatValue } from 'features/dashboard/player-profile-stats/ui/StatValue';
import { StatWrapper } from 'features/dashboard/player-profile-stats/ui/StatWrapper';

type Props = {
  name: string;
};

export const StatSkeleton = ({ name }: Props) => {
  return (
    <StatWrapper>
      <Skeleton sx={{ transform: 'none' }}>
        <StatsTitle>{name}</StatsTitle>
      </Skeleton>
      <Skeleton sx={{ minWidth: '32px' }}>
        <StatValue>0</StatValue>
      </Skeleton>
    </StatWrapper>
  );
};
