import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

import { BASIC_ROW_HEIGHT } from 'pages/tactical-analysis/components/timeline/timeline-table/components/TimelineTable.styled';

export const RowHeaderContainerWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isHighlighted' && prop !== 'isBorderTop',
})<{ isHighlighted?: boolean; isBorderTop: boolean }>(({ theme, isHighlighted, isBorderTop }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  height: BASIC_ROW_HEIGHT,
  paddingRight: theme.spacing(1),
  background: theme.palette.common.white,
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  userSelect: 'none',
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.easeOut,
    duration: theme.transitions.duration.shorter,
  }),

  ...(isHighlighted && {
    // TODO use from theme
    background: Colors.background,
  }),

  ...(isBorderTop && {
    borderBottom: 0,
    borderTop: `1px solid ${theme.palette.secondary.main}`,
  }),
}));
