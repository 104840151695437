import isEmpty from 'lodash/isEmpty';

import {
  EPISODES_PLAYING_MODE,
  PANORAMIC_PLAYING_MODE,
  TACTICAL_CAMERA_WITH_OVERLAYS_PLAYING_MODE,
  TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE,
} from 'shared/components/video-player/defaultPlayingModes';
import { PlayingModes, VideoSourceType, VideoSourceWithTimes } from 'shared/components/video-player/types';
import { EpisodeClip } from 'shared/types';
import { VideoSource } from 'shared/types/recording/types';
import { GetVideoSourcesResponse } from 'shared/utils/get-video-sources';

interface GenerateVideoSourcesFromVideoTypes {
  startTime: number;
  endTime: number;
  episodesVideos: EpisodeClip[];
  videoSources?: GetVideoSourcesResponse;
  useCustomOverlays: boolean;
}

const findEpisodeForPlaylistItem = (startTime: number, endTime: number, episodeClips: EpisodeClip[]) => {
  return episodeClips.find((episodeClip) => startTime >= episodeClip.startTime && endTime <= episodeClip.endTime);
};

const generateFullMatchVideoSource = (
  startTime: number,
  endTime: number,
  fullMatchVideo: VideoSource,
): VideoSourceWithTimes => {
  return {
    startTime,
    endTime,
    src: fullMatchVideo.src,
    srcDownload: fullMatchVideo.srcDownload,
    id: fullMatchVideo.id,
  };
};

const generatePlaylistVideoSource = (
  startTime: number,
  endTime: number,
  episodeClip: EpisodeClip,
): VideoSourceWithTimes => {
  return {
    startTime: startTime - episodeClip.startTime,
    startTimeInMatch: episodeClip.startTime,
    endTimeInMatch: endTime,
    endTime: endTime - episodeClip.startTime,
    src: episodeClip.videoSrc.src,
    srcDownload: episodeClip.videoSrc.srcDownload,
    id: episodeClip.videoSrc.id,
  };
};

export const generateVideoSourcesFromVideoTypes = ({
  startTime,
  endTime,
  episodesVideos,
  videoSources,
  useCustomOverlays = false,
}: GenerateVideoSourcesFromVideoTypes): VideoSourceType[] => {
  const episodeClip = findEpisodeForPlaylistItem(startTime, endTime, episodesVideos);
  const hasEpisodes = !isEmpty(episodesVideos) && !!episodeClip;
  const hasTacticalCameraVideo = videoSources?.tacticalCameraVideo;
  const hasUploadedVideo = videoSources?.uploadedVideo;
  const hasPanoramaVideo = videoSources?.panoramicVideo;

  return [
    ...(hasEpisodes && !useCustomOverlays
      ? [
          {
            playingMode: EPISODES_PLAYING_MODE,
            videoSources: [generatePlaylistVideoSource(startTime, endTime, episodeClip)],
          },
        ]
      : []),
    ...(useCustomOverlays && hasTacticalCameraVideo
      ? [
          {
            playingMode: TACTICAL_CAMERA_WITH_OVERLAYS_PLAYING_MODE,
            videoSources: [generateFullMatchVideoSource(startTime, endTime, videoSources.tacticalCameraVideo)],
          },
        ]
      : []),
    ...(hasTacticalCameraVideo && (!useCustomOverlays || !hasEpisodes)
      ? [
          {
            playingMode: { mode: PlayingModes.TACTICAL_CAMERA, showOverlays: false, useEffectiveTime: true },
            videoSources: [generateFullMatchVideoSource(startTime, endTime, videoSources.tacticalCameraVideo)],
          },
        ]
      : []),
    ...(hasUploadedVideo
      ? [
          {
            playingMode: TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE,
            videoSources: [generateFullMatchVideoSource(startTime, endTime, videoSources.uploadedVideo)],
          },
        ]
      : []),
    ...(hasPanoramaVideo
      ? [
          {
            playingMode: PANORAMIC_PLAYING_MODE,
            videoSources: [generateFullMatchVideoSource(startTime, endTime, videoSources.panoramicVideo)],
          },
        ]
      : []),
  ];
};
