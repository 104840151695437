import z from 'zod';

const isValidId = (id: string): boolean => {
  const regex = /^[a-z0-9]+(?:-[a-z0-9]+)*$/;
  return regex.test(id);
};

export const environmentFormSchema = z.object({
  id: z
    .string()
    .trim()
    .min(3)
    .transform((val) => val.toLowerCase())
    .refine((val) => isValidId(val), {
      message: "Invalid ID format (e.g. 'my-client-id')",
    }),
  name: z.string().trim().min(3),
  city: z.string().trim().min(3),
  country: z.string().min(2),
});
