import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { useDates } from 'shared/hooks/use-dates';
import { TacticalAnalysis } from 'shared/types/recording/types';

const TABLE_WIDTH = 650;

const createData = (name: string, value: string | number) => {
  return { name, value };
};

interface Props {
  data: TacticalAnalysis;
  setTacticalAnalysis: (data: TacticalAnalysis) => void;
}

export const TacticalAnalysesTable = ({ data, setTacticalAnalysis }: Props) => {
  const { dateToString } = useDates();

  const handleChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setTacticalAnalysis({ ...data, showOverlays: checked });
  };

  const rows = [
    createData('Id', data.id),
    createData('Recording id', data.recordingId),
    createData('Tactical analysis Git commit', data.tacticalAnalysisGitCommit ?? '-'),
    createData('Number of episodes', data.numberOfEpisodes),
    createData('Start time', dateToString(data.startTime)),
    createData('State', data.state),
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: TABLE_WIDTH }} aria-label='team details table'>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align='right'>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name}>
              <TableCell component='th' scope='row'>
                {row.name}
              </TableCell>
              <TableCell align='right'>{row.value}</TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell component='th' scope='row'>
              Show overlays
            </TableCell>
            <TableCell align='right'>
              <Checkbox checked={data.showOverlays} onChange={handleChange} />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};
