import { z } from 'zod';

import { CompetitionFormFieldsNames } from 'pages/backoffice/pages/competitions/components/competition-form/types';

export const competitionFormSchema = z.object({
  [CompetitionFormFieldsNames.id]: z.string().optional(),
  [CompetitionFormFieldsNames.name]: z.string().trim().min(2),
  [CompetitionFormFieldsNames.country]: z.string(),
  [CompetitionFormFieldsNames.competitionImage]: z.instanceof(FileList).optional(),
});
