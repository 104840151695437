import React from 'react';

import { RowHeader } from 'pages/tactical-analysis/components/timeline/timeline-table/components/row-header';
import { useTimelineTableData } from 'pages/tactical-analysis/hooks/use-timeline-table-data';
import { hasFiltersResults } from 'pages/tactical-analysis/utils/has-filters-results';

export const FiltersHeaderRow = ({ recordingId }: { recordingId: string }) => {
  const timelineTableData = useTimelineTableData(recordingId);

  if (!timelineTableData.filtersRow || !hasFiltersResults(timelineTableData.filtersRow)) return null;

  return <RowHeader recordingId={recordingId} row={timelineTableData.filtersRow} />;
};
