import { styled, Link, LinkProps } from '@mui/material';
import { Colors } from 'kognia-ui';

const NotificationLinkStyled = styled(Link)(({ theme }) => ({
  marginLeft: theme.spacing(0.5),
  marginRight: theme.spacing(0.5),
  color: theme.palette.common.white,
  textDecoration: 'none',

  '&::after': {
    content: '""',
    height: 1,
    bottom: 0,
    left: 0,
    width: '100%',
    position: 'absolute',
    backgroundColor: theme.palette.common.white,
  },

  '&:hover::after': {
    backgroundColor: Colors.ghost,
  },

  '&:focus::after': {
    backgroundColor: Colors.ghost,
  },

  '&:focus': {
    //TODO use theme color
    color: Colors.ghost,
  },
  '&:hover': {
    //TODO use theme color
    color: Colors.ghost,
  },
}));

export const NotificationLink = ({ children, ...rest }: LinkProps) => {
  return (
    <NotificationLinkStyled component={'button'} {...rest}>
      {children}
    </NotificationLinkStyled>
  );
};
