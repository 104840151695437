import { UseQueryResult } from '@tanstack/react-query';
import forEach from 'lodash/forEach';

import { getJerseyNumber } from 'pages/backoffice/pages/fixtures/fixture-form/components/selected-players';
import { transformFixturePlayer } from 'pages/backoffice/pages/fixtures/fixture-form/utils';
import { MetadataFixture } from 'pages/backoffice/types/fixture';
import { Player } from 'shared/types/player/player';
import { TeamWithPlayers } from 'shared/types/team/team';

interface getLatestPlayersFromFixtures {
  team?: TeamWithPlayers;
  fixtures?: UseQueryResult<MetadataFixture>[];
}

export const getLatestPlayersFromFixtures = ({
  team,
  fixtures,
}: getLatestPlayersFromFixtures): Player[] | undefined => {
  if (!team || !fixtures) return;

  const latestUniquePlayersFromFixtures = fixtures.reduce<Record<string, Player>>((acc, fixture) => {
    if (!fixture.data) return acc;

    const fixturePlayers: Player[] = fixture.data.players
      .filter((player) => player.teamId === team.id)
      .map((player) => transformFixturePlayer(player, team, fixture.data));

    forEach(fixturePlayers, (player) => {
      const playerFixturesDateInAcc = acc[player.id]?.fixtures?.find((item) => item.date);
      const playerFixturesDateInPlayer = player.fixtures?.find((item) => item.date);
      if (
        !acc[player.id] ||
        (acc[player.id] &&
          playerFixturesDateInAcc &&
          playerFixturesDateInPlayer &&
          playerFixturesDateInAcc.date !== null &&
          playerFixturesDateInPlayer.date !== null &&
          Date.parse(playerFixturesDateInAcc.date) < Date.parse(playerFixturesDateInPlayer.date))
      ) {
        acc[player.id] = player;
      }
    });

    return acc;
  }, {});

  const sortByJerseyNumber = (a: Player, b: Player) => getJerseyNumber(a) - getJerseyNumber(b);
  return Object.values(latestUniquePlayersFromFixtures).sort(sortByJerseyNumber);
};
