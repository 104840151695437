import { Grid } from '@mui/material';

interface Props {
  children: React.ReactNode;
}

export const ListTitleActions = ({ children }: Props) => {
  return (
    <Grid item container justifyContent={'flex-end'} alignItems={'center'} sx={{ marginBottom: 2 }}>
      {children}
    </Grid>
  );
};
