import { useCallback } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { useDashboardRecentSearches } from 'entities/dashboard/api/use-dashboard-recent-searches/useDashboardRecentSearches';
import { SearchResult } from 'entities/dashboard/types/dashboardSearch';
import { routes } from 'kognia/router/routes';

export const useSelectResultHandler = () => {
  const navigate = useNavigate();
  const { setRecentSearch } = useDashboardRecentSearches();

  return useCallback(
    (searchResult: SearchResult) => {
      if (searchResult.type === 'player') {
        navigate(generatePath(routes.PLAYER_PROFILE, { playerId: searchResult.value }));
      }

      if (searchResult.type === 'team') {
        navigate(generatePath(routes.TEAM_PROFILE, { teamId: searchResult.value }));
      }

      setRecentSearch(searchResult);
    },
    [setRecentSearch, navigate],
  );
};
