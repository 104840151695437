import { FormControl, Grid, TextField, Typography } from '@mui/material';
import { Colors } from 'kognia-ui';
import range from 'lodash/range';
import React from 'react';
import { Control, Controller, UseFormRegister, UseFormWatch, FieldErrors } from 'react-hook-form';

import { useSeason } from 'pages/backoffice/api/seasons/use-season';
import { SelectSeason } from 'pages/backoffice/pages/stages/components/select-season';
import { StageFormFieldsNames, StageFormSchema } from 'pages/backoffice/pages/stages/components/stage-form/types';
import { adjustOrderIndexForDisplay } from 'pages/backoffice/pages/stages/utils';
import { FormFormLabel } from 'shared/components/form/form-form-label';
import { FormSelectField } from 'shared/components/form/form-select-field';
import { getTypeOptions } from 'shared/components/form/get-type-options';

interface Props {
  register: UseFormRegister<StageFormSchema>;
  errors: FieldErrors<StageFormSchema>;
  watch: UseFormWatch<StageFormSchema>;
  control: Control<StageFormSchema>;
}

const DEFAULT_ORDER_INDEX = 0;

export function StagesForm({ control, register, errors, watch }: Props) {
  const { seasonId, parentStageId, id } = watch();
  const { data: season } = useSeason({ seasonId: seasonId ?? undefined });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={9}>
        <FormControl fullWidth margin='normal'>
          <FormFormLabel>Name</FormFormLabel>
          <TextField
            size='small'
            {...register(StageFormFieldsNames.name)}
            error={!!errors[StageFormFieldsNames.name]}
            helperText={errors[StageFormFieldsNames.name] && <>{errors[StageFormFieldsNames.name].message}</>}
          />
        </FormControl>
        <Controller
          name={StageFormFieldsNames.seasonId}
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <FormControl fullWidth>
              <FormFormLabel>Season</FormFormLabel>
              <SelectSeason setSeasonOnChange={(season) => onChange(season?.id)} selectedSeasonId={value} />
              {error && <Typography color={Colors.red}>{error.message}</Typography>}
            </FormControl>
          )}
        />
        {season?.stages && season.stages.filter((stage) => stage.id !== id).length > 0 ? (
          <Grid item container spacing={2}>
            <Grid item>
              <FormFormLabel>Order</FormFormLabel>
              <Controller
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <>
                    <FormSelectField
                      id={StageFormFieldsNames.orderIndex}
                      options={getTypeOptions({
                        options: range(0, 50, 1).map((val) => ({
                          value: val,
                          label: adjustOrderIndexForDisplay(val).toString(),
                        })),
                      })}
                      onChange={onChange}
                      defaultValue={value ?? DEFAULT_ORDER_INDEX}
                      fullWidth={true}
                    />
                    {error && <Typography color={Colors.red}>{error.message}</Typography>}
                  </>
                )}
                name={StageFormFieldsNames.orderIndex}
                control={control}
              />
            </Grid>
            <Grid item>
              <FormFormLabel optional>Parent Stage</FormFormLabel>
              <FormSelectField
                id={StageFormFieldsNames.parentStageId}
                key={season.id}
                showNoneOption
                options={getTypeOptions({
                  options: season.stages
                    .filter((stage) => stage.id !== id)
                    .map((stage) => ({
                      value: stage.id,
                      label: stage.name,
                    })),
                })}
                error={!!errors[StageFormFieldsNames.parentStageId]}
                helperText={
                  errors[StageFormFieldsNames.parentStageId] && (
                    <>{errors[StageFormFieldsNames.parentStageId]?.message}</>
                  )
                }
                defaultValue={season.stages.find((stage) => stage.id === parentStageId) ? parentStageId : ''}
                fullWidth={true}
                {...register(StageFormFieldsNames.parentStageId)}
              />
            </Grid>
          </Grid>
        ) : (
          <input type={'hidden'} value={DEFAULT_ORDER_INDEX} {...register(StageFormFieldsNames.orderIndex)} />
        )}{' '}
      </Grid>
    </Grid>
  );
}
