import { object, string, z } from 'zod';

export enum SearchType {
  ALL = 'all',
  PLAYER = 'player',
  TEAM = 'team',
}

export const searchResultSchema = object({
  label: string(),
  photoUrl: string().optional().nullable(),
  type: z.nativeEnum(SearchType),
  value: string(),
  extraInfo: object({
    label: string(),
    photoUrl: string().optional().nullable(),
    value: string(),
  }).optional(),
});

export type SearchResult = z.infer<typeof searchResultSchema>;
