import { useMemo } from 'react';

import { useTeamUtils } from 'pages/tactical-analysis/hooks/use-timeline-team-id-focus';

export const useRowTeam = (recordingId: string, teamId?: string) => {
  const { isHomeTeam } = useTeamUtils(recordingId);
  return useMemo(() => {
    if (!teamId) return 'none';

    return isHomeTeam(teamId) ? 'home' : 'opponent';
  }, [teamId, isHomeTeam]);
};
