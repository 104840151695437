import get from 'lodash/get';
import { FieldErrors } from 'react-hook-form';

export const getErrorByFieldName = (errors: FieldErrors, fieldName: string) => {
  if (!errors) {
    return null;
  }

  return get(errors, fieldName);
};
