import { useEffect } from 'react';

import { useFetchAccount } from 'api/user/use-fetch-account';
import { useLogOutAccount } from 'api/user/use-logout-account/useLogoutAccount';

type ApiError = {
  response?: {
    status?: number;
  };
};

export const useLogoutAccountWithRedirect = () => {
  const { refetch: logOut, redirect } = useLogOutAccount();

  const handleLogOut = () => logOut();

  const handleRedirect = (error: ApiError) => {
    if (error.response?.status === 401) {
      redirect();
    }
  };

  const { refetch: fetchAccount } = useFetchAccount({
    onError: handleRedirect,
    onSuccess: handleLogOut,
    queryRef: ['getAccountForLogout'],
    options: {
      staleTime: Infinity,
      enabled: false,
      refetchOnWindowFocus: false,
      refetchInterval: undefined,
    },
  });

  useEffect(() => {
    fetchAccount();
  }, [fetchAccount]);
};
