import * as Sentry from '@sentry/react';
import { useCallback, useMemo, useState } from 'react';

import {
  dashboardRecentSearchesSchema,
  RecentSearch,
} from 'entities/dashboard/api/use-dashboard-recent-searches/dashboardRecentSearchesSchema';
import { SearchType } from 'entities/dashboard/types/dashboardSearch';
import { useClientId } from 'shared/contexts/app-state/hooks/useClientId';

export const MAX_SEARCHES_LENGTH = 10;

export const EXPIRATION_TIME = 30 * 24 * 60 * 60 * 1000; // 30d

const checkIsDateExpired = (date: string): boolean => {
  const currentDate = new Date();
  const inputDate = new Date(date);

  const timeDifference = currentDate.getTime() - inputDate.getTime();

  return timeDifference < EXPIRATION_TIME;
};

export const getRecentSearchesKey = (clientId = '') => `recentSearches-${clientId}`;

const getRecentSearches = (clientId = '') => {
  const localStorageKey = getRecentSearchesKey(clientId);

  try {
    const storageData = localStorage.getItem(localStorageKey);
    if (!storageData) {
      return [];
    }

    const validationResult = dashboardRecentSearchesSchema.array().safeParse(JSON.parse(storageData));
    if (validationResult.error) {
      throw new Error('Validation error in recent searches:', { cause: validationResult.error });
    }

    const recentSearches = validationResult.data
      .filter((item) => checkIsDateExpired(item.date))
      .slice(0, MAX_SEARCHES_LENGTH);

    localStorage.setItem(localStorageKey, JSON.stringify(recentSearches));

    return recentSearches;
  } catch (error) {
    Sentry.captureException(error);

    localStorage.removeItem(localStorageKey);
    return [];
  }
};

export const useDashboardRecentSearches = () => {
  const { clientId } = useClientId();

  const [searches, setSearches] = useState<RecentSearch[]>(() => getRecentSearches(clientId));

  const recentSearches = useMemo(() => searches.map(({ data }) => data), [searches]);

  const setRecentSearch = useCallback(
    (data: RecentSearch['data']) => {
      const newSearch = { data, date: new Date().toISOString() };
      const updatedSearches = [
        newSearch,
        ...searches.filter((item) => item.data.value !== newSearch.data.value).slice(0, MAX_SEARCHES_LENGTH - 1),
      ];

      localStorage.setItem(getRecentSearchesKey(clientId), JSON.stringify(updatedSearches));

      setSearches(updatedSearches);
    },
    [clientId, searches],
  );

  const totals = useMemo(
    () => ({
      all: recentSearches.length,
      player: recentSearches.filter((search) => search.type === SearchType.PLAYER).length,
      team: recentSearches.filter((search) => search.type === SearchType.TEAM).length,
    }),
    [recentSearches],
  );

  return { recentSearches, setRecentSearch, hasRecentSearches: recentSearches.length > 0, totals };
};
