import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { transformMetadataFixture } from 'pages/backoffice/api/fixtures/transformers';
import { FixtureApi } from 'pages/backoffice/api/fixtures/types';
import { CreateFixtureApiParams } from 'pages/backoffice/api/fixtures/use-create-metadata-fixture/types';
import { useInvalidateMetadataFixtures } from 'pages/backoffice/api/fixtures/use-metadata-fixtures';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';
import { MetadataFixture } from 'pages/backoffice/types/fixture';

interface RequestData {
  data: CreateFixtureApiParams;
  onSuccess?: (fixture: MetadataFixture) => void;
}

export const useCreateMetadataFixture = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateFixtures = useInvalidateMetadataFixtures();
  const mutationRequest = useMutationRequest<FixtureApi, MetadataFixture, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    errorMessage: (data) => metadataApiErrorParser(data, 'Fixture creation error'),
    onSuccess: invalidateFixtures,
    transformer: transformMetadataFixture,
  });

  const create = ({ data, onSuccess }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: annotationApiUrls.FIXTURES,
        data,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, create };
};
