import { Grid, Step, StepLabel, Stepper } from '@mui/material';

import { NEW_FIXTURES_STEPS } from 'pages/backoffice/pages/fixtures/fixture-form/config';

type Props = {
  step: number;
};

const LABELS = {
  ['home-team']: 'Home Team',
  ['away-team']: 'Away Team',
  ['fixture-info']: 'Fixture Info',
};

export default function FixtureFormStepper({ step }: Props) {
  return (
    <Grid container justifyContent={'center'}>
      <Grid item lg={10} xl={8}>
        <Stepper activeStep={step} alternativeLabel>
          {NEW_FIXTURES_STEPS.map((label, idx) => {
            return (
              <Step key={label} completed={idx < step}>
                <StepLabel>{LABELS[label]}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Grid>
    </Grid>
  );
}
