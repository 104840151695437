import { Box, styled } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { Colors, fontSizes, fontWeight, letterSpacing } from 'kognia-ui';
import React from 'react';

interface Props {
  children: React.ReactNode;
  icon?: React.ReactNode;
}

const DialogHeaderWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  flexDirection: 'column',
  padding: theme.spacing(3),
}));

const DialogTitle = styled(MuiDialogTitle)({
  fontSize: fontSizes.modalTitle,
  letterSpacing: letterSpacing.modalTitle,
  padding: 0,
  fontWeight: fontWeight.modalTitle,
  color: Colors.night,
});

const DialogIcon = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5),
  backgroundColor: Colors.background,
  borderRadius: '50%',
  marginBottom: theme.spacing(3),
}));

export const DialogHeader = ({ children, icon }: Props) => {
  return (
    <DialogHeaderWrapper>
      {icon && <DialogIcon>{icon}</DialogIcon>}
      <DialogTitle>{children}</DialogTitle>
    </DialogHeaderWrapper>
  );
};
