import { TypeOf } from 'zod';

import { venueFormSchema } from 'pages/backoffice/pages/venues/components/venue-form/form';

export enum VenueFormFieldsNames {
  id = 'id',
  name = 'name',
  city = 'city',
  countryCode = 'country_code',
}

export interface VenueForm {
  [VenueFormFieldsNames.id]?: string;
  [VenueFormFieldsNames.name]: string;
  [VenueFormFieldsNames.city]: string;
  [VenueFormFieldsNames.countryCode]: string;
}

export type VenueFormSchema = TypeOf<typeof venueFormSchema>;
