import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';
import React from 'react';

export enum GoalVariant {
  NORTH = 'north',
  SOUTH = 'south',
}

interface Props {
  variant: GoalVariant;
}

const goalHeight = '16px';
const goalWidth = '96px';

const KeypadGoalWrapper = styled(Box, { shouldForwardProp: (prop) => prop !== 'variant' })<Props>(({ variant }) => ({
  width: goalWidth,
  height: goalHeight,
  background: Colors.iron,
  border: `1px solid ${Colors.storm}`,
  position: 'absolute',
  left: `calc(50% - (${goalWidth} / 2))`,
  ...(variant === GoalVariant.NORTH && {
    top: `-${goalHeight}`,
    borderBottom: 0,
  }),
  ...(variant === GoalVariant.SOUTH && {
    bottom: `-${goalHeight}`,
    borderTop: 0,
  }),
}));

export const KeypadGoal = ({ variant }: Props) => {
  return <KeypadGoalWrapper variant={variant} aria-hidden={true} />;
};
