import { Button } from '@mui/material';
import React from 'react';

interface Props {
  reset: () => void;
}

export const AnnotationFormActions = ({ reset }: Props) => {
  return (
    <>
      <Button onClick={reset}>Reset</Button>
      <Button variant='contained' type='submit'>
        Submit
      </Button>
    </>
  );
};
