import { IconCopyTo } from 'kognia-ui/icons/IconCopyTo';
import { IconDownload } from 'kognia-ui/icons/IconDownload';
import { IconOverlays } from 'kognia-ui/icons/IconOverlays';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { useHandleSetPlaylistItem } from 'entities/playlist/hooks/useHandleSetPlaylistItem';
import { MenuListOption } from 'shared/components/kebab-menu';
import { useVideoPlayerActions } from 'shared/components/video-player';
import { useVideoPlayerId } from 'shared/components/video-player/hooks';
import { PlaylistItemType } from 'shared/components/video-player/types';
import { useSetIsOverlayPanelOpen } from 'shared/components/video-player/video-player-component/hooks/overlay-panel';
import { useFeatureFlag } from 'shared/contexts/app-state/hooks/useFeatureFlag';

export const useTemporaryPlaylistItemOptions = ({
  playlistItem,
  setIsDownloadModalOpen,
  setCopyToAnchor,
}: {
  playlistItem: PlaylistItemType;
  setCopyToAnchor: (value: HTMLElement | null) => void;
  setIsDownloadModalOpen: (value: boolean) => void;
}): ((hasHomographies: boolean) => MenuListOption[]) => {
  const { t } = useTranslation();
  const playerId = useVideoPlayerId();
  const handleSetPlaylistItem = useHandleSetPlaylistItem(playlistItem);
  const actions = useVideoPlayerActions();
  const setIsOverlayPanelOpenState = useSetIsOverlayPanelOpen(playerId);
  const customOverlaysFeatureFlag = useFeatureFlag(FEATURE_FLAG.CUSTOM_OVERLAYS);
  const downloadPlaylistFeatureFlag = useFeatureFlag(FEATURE_FLAG.DOWNLOAD_PLAYLIST);

  const handleOpenCopyToDialog = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setCopyToAnchor(event.currentTarget);
    },
    [setCopyToAnchor],
  );

  return useCallback(
    (hasHomographies: boolean) => {
      const overlayOption = {
        displayText: t('video-player:overlays.edit-overlays'),
        icon: <IconOverlays size={'small'} />,
        onClick: () => {
          handleSetPlaylistItem(false);
          setIsOverlayPanelOpenState(true);
        },
      };
      const options = [
        {
          displayText: t('playlist-detail:send-to-playlist'),
          icon: <IconCopyTo size={'small'} />,
          onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
            handleOpenCopyToDialog(event);
            actions.handleStandBy();
          },
          avoidCloseOnClick: true,
        },
        {
          displayText: t('common:actions.download'),
          icon: <IconDownload size={'small'} />,
          onClick: () => {
            setIsDownloadModalOpen(true);
            actions.handleStandBy();
          },
          isHidden: !downloadPlaylistFeatureFlag,
        },
      ];
      return hasHomographies && customOverlaysFeatureFlag && playlistItem.showOverlays
        ? [overlayOption, ...options]
        : options;
    },
    [
      t,
      downloadPlaylistFeatureFlag,
      customOverlaysFeatureFlag,
      playlistItem.showOverlays,
      handleSetPlaylistItem,
      setIsOverlayPanelOpenState,
      handleOpenCopyToDialog,
      actions,
      setIsDownloadModalOpen,
    ],
  );
};
