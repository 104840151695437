import { MenuItem } from '@mui/material';
import Menu from '@mui/material/Menu';
import classNames from 'classnames';
import { IconClose } from 'kognia-ui/icons/IconClose';
import { IconFolder } from 'kognia-ui/icons/IconFolder';
import { IconPlay } from 'kognia-ui/icons/IconPlay';
import { IconSelect } from 'kognia-ui/icons/IconSelect';
import { IconSelectAll } from 'kognia-ui/icons/IconSelectAll';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAddManyToPlaylist } from 'api/playlist/useAddManyToPlaylist';
import {
  useCleanSelection,
  useClipsIdsSelection,
  useGetClipsList,
} from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/atoms';
import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { generateClipsWithOverlappingTime } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/generateClips';
import { OverlappingClipsModal } from 'pages/tactical-analysis/components/timeline/overlapping-clips-modal/OverlappingClipsModal';
import styles from 'pages/tactical-analysis/components/timeline/timeline-table/components/clip-actions/ClipActions.module.scss';
import { ClipActionsMenuTitle } from 'pages/tactical-analysis/components/timeline/timeline-table/components/clip-actions/ClipActions.styled';
import { UseTimelineEventsHandlers } from 'pages/tactical-analysis/components/timeline/timeline-table/hooks/use-timeline-events-handlers/types';
import { useAddToPlaylistValidator } from 'pages/tactical-analysis/hooks/use-add-to-playlist-validator/useAddToPlaylistValidator';
import { SelectPlaylistDialog } from 'shared/components/select-playlist-dialog/SelectPlaylistDialog';
import { useCurrentPlaylistItem } from 'shared/components/video-player';
import { useMapClipsToPlaylistItems } from 'shared/hooks/use-map-clips-to-post-playlist-items/useMapClipsToPlaylistItems';
import { FundamentalsSelection, Playlist } from 'shared/types/index';

type Props = {
  clip: Clip;
  menuAnchorEl: HTMLDivElement | null;
  onClose: () => void;
  isSelected: boolean;
  recordingId: string;
  playClip: UseTimelineEventsHandlers['handlePlayClip'];
  playSelectedClips: UseTimelineEventsHandlers['handlePlaySelectedClips'];
  selectClip: UseTimelineEventsHandlers['handleSelectClip'];
  deselectClip: UseTimelineEventsHandlers['handleDeselectClip'];
  selectRow: UseTimelineEventsHandlers['handleSelectRowClips'];
};

interface HandleSelectPlaylist {
  (playlist: Playlist | null, isMultipleClipsMode?: boolean): void;
}

export const ClipActions = ({
  menuAnchorEl,
  clip,
  onClose,
  recordingId,
  selectClip,
  deselectClip,
  selectRow,
  playClip,
  playSelectedClips,
  isSelected,
}: Props) => {
  const { t } = useTranslation();
  const { addManyToPlaylist } = useAddManyToPlaylist();
  const currentPlaylistItem = useCurrentPlaylistItem();
  const getClips = useGetClipsList();
  const clipIdsSelection = useClipsIdsSelection();
  const [anchorSingleClipSubmenu, setAnchorSingleClipSubmenu] = useState<HTMLElement | null>(null);
  const [anchorMultipleClipsSubmenu, setAnchorMultipleClipsSubmenu] = useState<HTMLElement | null>(null);
  const mapClipsToPlaylistItems = useMapClipsToPlaylistItems({ recordingId });
  const clearSelection = useCleanSelection();
  const {
    isValidationModalOpen,
    onModalOpen,
    onModalClose,
    checkForParentClips,
    setData,
    resetData,
    data: { clipsToSave, fundamentalsSelected, playlistId },
  } = useAddToPlaylistValidator();

  const areMultipleClipsSelectedWithCurrentClip = clipIdsSelection.length > 1 && clipIdsSelection.includes(clip.id);
  const selectedClipsCount = clipIdsSelection.length;
  const showPlaySelectedOption = areMultipleClipsSelectedWithCurrentClip;

  const handleOpenSingleClipSubmenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorSingleClipSubmenu(event.currentTarget);
  }, []);

  const handleOpenMultipleClipSubmenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorMultipleClipsSubmenu(event.currentTarget);
  }, []);

  const closeAllMenus = useCallback(() => {
    onClose();
    setAnchorMultipleClipsSubmenu(null);
    setAnchorSingleClipSubmenu(null);
  }, [onClose]);

  const handleClickAway = useCallback(
    (event: MouseEvent | TouchEvent) => {
      event.stopPropagation();
      closeAllMenus();
    },
    [closeAllMenus],
  );

  const handleClose = useCallback(
    (event?: React.MouseEvent) => {
      event?.stopPropagation();
      closeAllMenus();
    },
    [closeAllMenus],
  );

  const handleSubmit = useCallback(
    ({
      items,
      merge,
      callbackData,
    }: {
      items: Clip[];
      merge: boolean;
      callbackData?: { playlistId: string; fundamentalsSelected: FundamentalsSelection };
    }) => {
      if (!callbackData && !fundamentalsSelected) return;
      const fundamentals: FundamentalsSelection | undefined =
        callbackData?.fundamentalsSelected ?? fundamentalsSelected;
      if (!fundamentals) return;
      const playlistItems: Clip[] = merge ? generateClipsWithOverlappingTime({ clips: items }) : items;

      addManyToPlaylist({
        items: mapClipsToPlaylistItems({
          playlistId: callbackData?.playlistId ?? playlistId,
          items: playlistItems,
          fundamentalsSelected: fundamentals,
        }),
        options: {
          onSuccess: async () => {
            resetData();
          },
          onSettled: closeAllMenus,
        },
      });
    },
    [addManyToPlaylist, closeAllMenus, fundamentalsSelected, mapClipsToPlaylistItems, playlistId, resetData],
  );

  const handleSelectPlaylist: HandleSelectPlaylist = useCallback(
    (playlist, isMultipleClipsMode) => {
      if (playlist === null || Array.isArray(playlist)) return;
      const fundamentalsSelected: FundamentalsSelection = currentPlaylistItem.hasHomographies
        ? currentPlaylistItem.fundamentalsSelected
        : {
            tacticalAnalysisId: currentPlaylistItem.fundamentalsSelected.tacticalAnalysisId,
            fundamentalsSelected: ['all'],
          };
      const clips = clipIdsSelection.includes(clip.id) && isMultipleClipsMode ? getClips(clipIdsSelection) : [clip];

      setData({ clips, playlistId: playlist.id, fundamentalsSelected });

      return checkForParentClips({
        playlistItems: clips,
        callback: (items) => {
          handleSubmit({ items, merge: false, callbackData: { playlistId: playlist.id, fundamentalsSelected } });
        },
      });
    },
    [
      currentPlaylistItem.hasHomographies,
      currentPlaylistItem.fundamentalsSelected,
      clipIdsSelection,
      clip,
      getClips,
      setData,
      checkForParentClips,
      handleSubmit,
    ],
  );

  const handlePlayClip = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      playClip(clip.startTime);
      handleClose(event);
    },
    [playClip, clip.startTime, handleClose],
  );

  const handlePlaySelectedClips = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      playSelectedClips();
      handleClose(event);
    },
    [playSelectedClips, handleClose],
  );

  const handleSelectClip = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      selectClip({ clipId: clip.id, ctrlKey: event.ctrlKey, shiftKey: event.shiftKey, metaKey: event.metaKey });
      handleClose(event);
    },
    [clip.id, handleClose, selectClip],
  );

  const handleDeselectClip = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      deselectClip(clip.id);
      handleClose(event);
    },
    [deselectClip, clip.id, handleClose],
  );

  const handleClearSelection = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      clearSelection();
      handleClose(event);
    },
    [clearSelection, handleClose],
  );

  const handleSelectRow = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      selectRow({ rowId: clip.rowId, ctrlKey: event.ctrlKey });
      handleClose(event);
    },
    [selectRow, clip.rowId, handleClose],
  );

  const handleOverlappingModalSubmit = useCallback(
    (merge: boolean) => {
      handleSubmit({ items: clipsToSave, merge });
      closeAllMenus();
    },
    [closeAllMenus, handleSubmit, clipsToSave],
  );

  const multipleClipsMenuItems = [
    <ClipActionsMenuTitle
      key={`${clip.id}-selected-clips`}
      sx={{
        paddingTop: ({ spacing }) => spacing(2),
        borderTop: ({ palette }) => `1px solid ${palette.secondary.light}`,
      }}
      disabled
    >
      {t('timeline:clip-actions.selected-clips')}
    </ClipActionsMenuTitle>,
    <MenuItem
      key={`${clip.id}-add-all-selected-to-playlist`}
      className={styles.menuItem}
      onClick={handleOpenMultipleClipSubmenu}
    >
      <IconFolder color='secondary' size='small' />
      {t('timeline:actions.add-all-selected-to-playlist', { selectedClipsCount })}
    </MenuItem>,
    showPlaySelectedOption ? (
      <MenuItem key={`${clip.id}-play-all-selected`} className={styles.menuItem} onClick={handlePlaySelectedClips}>
        <IconPlay color='secondary' size='small' />
        {t('timeline:actions.play-all-selected', { selectedClipsCount })}
      </MenuItem>
    ) : null,
    <MenuItem key={`${clip.id}-deselect-all-clips`} className={styles.menuItem} onClick={handleClearSelection}>
      <IconClose color='secondary' size='small' /> {t('timeline:actions.deselect-all-clips', { selectedClipsCount })}
    </MenuItem>,
  ];

  const multipleClipsMenuItemsList = multipleClipsMenuItems.map((item) => item);

  return (
    <>
      <Menu
        open
        anchorEl={menuAnchorEl}
        onClose={handleClickAway}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
        onClick={(event) => event.stopPropagation()}
        data-element-type='clip-actions-menu'
      >
        <ClipActionsMenuTitle disabled>{t('timeline:clip-actions.single-clip')}</ClipActionsMenuTitle>
        <MenuItem className={styles.menuItem} onClick={handleOpenSingleClipSubmenu}>
          <IconFolder color='secondary' size='small' /> {t('timeline:actions.add-to-playlist')}
        </MenuItem>
        <MenuItem className={styles.menuItem} onClick={handlePlayClip}>
          <IconPlay color='secondary' size='small' /> {t('timeline:actions.play-clip')}
        </MenuItem>
        {!isSelected ? (
          <MenuItem className={styles.menuItem} onClick={handleSelectClip}>
            <IconSelect color='secondary' size='small' /> {t('timeline:actions.select-clip')}
          </MenuItem>
        ) : null}
        {isSelected ? (
          <MenuItem className={styles.menuItem} onClick={handleDeselectClip}>
            <IconClose color='secondary' size='small' /> {t('timeline:actions.deselect-clip')}
          </MenuItem>
        ) : null}
        {areMultipleClipsSelectedWithCurrentClip ? multipleClipsMenuItemsList : null}
        {clip.type !== 'episode' ? (
          <MenuItem
            className={classNames(styles.menuItem)}
            sx={{
              borderTop: ({ palette }) => `1px solid ${palette.secondary.light}`,
            }}
            onClick={handleSelectRow}
          >
            <IconSelectAll color='secondary' size='small' />
            {t('timeline:actions.select-clips-in-row')}
          </MenuItem>
        ) : null}
      </Menu>
      {anchorSingleClipSubmenu ? (
        <SelectPlaylistDialog
          anchorEl={anchorSingleClipSubmenu}
          onClose={handleClose}
          onClickItem={(playlist) => handleSelectPlaylist(playlist)}
        />
      ) : null}
      {anchorMultipleClipsSubmenu ? (
        <SelectPlaylistDialog
          anchorEl={anchorMultipleClipsSubmenu}
          onClose={handleClose}
          onClickItem={(playlist) => handleSelectPlaylist(playlist, true)}
        />
      ) : null}
      <OverlappingClipsModal
        isOpen={isValidationModalOpen}
        onClose={onModalClose}
        onOpen={onModalOpen}
        onSubmit={handleOverlappingModalSubmit}
      />
    </>
  );
};
