import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useUser } from 'shared/contexts/app-state/hooks/useUser';

const useIsLanguageDetectionReady = (): boolean => {
  const user = useUser();
  const { i18n } = useTranslation();
  const [isLanguageReady, setIsLanguageReady] = useState(false);

  const changeLanguage = useCallback(
    async (locale: string) => {
      await i18n.changeLanguage(locale);
      setIsLanguageReady(true);
    },
    [i18n],
  );

  useEffect(() => {
    if (!user.id) {
      setIsLanguageReady(true);
    } else if (i18n.language !== user.locale || !isLanguageReady) {
      changeLanguage(user.locale);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return isLanguageReady;
};

export default useIsLanguageDetectionReady;
