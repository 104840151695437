import { Box, styled } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';

export const KeypadListItemContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: '8px',
  background: Colors.white,
  width: '100%',
  padding: '0 8px',
  borderRadius: '4px',
  height: '96px',
  cursor: 'pointer',
  transition: theme.transitions.create('background'),
  '&:hover, &:focus, &:active': {
    background: Colors.athens,
  },
}));

export const KeypadNameContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const KeypadIconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1),
}));

export const KeypadMenuWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
}));

export const KeypadDefaultBadge = styled(Box)(({ theme }) => ({
  background: Colors.athens,
  display: 'flex',
  alignItems: 'center',
  height: '20px',
  padding: theme.spacing(0.25, 0.5),
  borderRadius: theme.shape.borderRadius,
  fontSize: fontSizes.keypadBadge,
  fontWeight: fontWeight['500'],
  color: Colors.storm,
}));
