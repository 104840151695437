import { Divider, styled } from '@mui/material';
import { fontSizes } from 'kognia-ui';

export const DateLineDivider = styled(Divider)(({ theme }) => ({
  '&.MuiDivider-root': {
    '&::before': {
      width: '0%',
    },

    '&::after': {
      width: '100%',
      borderColor: theme.palette.secondary.light,
    },
  },

  '& .MuiDivider-wrapper': {
    padding: theme.spacing(0, 1, 0, 0),
    color: theme.palette.secondary.main,
    fontSize: fontSizes.small,
    // TODO: replace from theme
    lineHeight: '133.333%',
  },
}));
