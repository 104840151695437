import { Grid, Skeleton } from '@mui/material';
import { Colors } from 'kognia-ui';

export interface RecordingCardLoading {
  isAnimated?: boolean;
}

const RecordingCardLoading = (props: RecordingCardLoading) => {
  const { isAnimated = true } = props;
  return (
    <Grid item xs={12}>
      <Skeleton
        width='100%'
        height={104}
        sx={{ backgroundColor: Colors.ghost, transform: 'none', marginBottom: 1 }}
        animation={isAnimated ? 'pulse' : false}
      />
    </Grid>
  );
};

export default RecordingCardLoading;
