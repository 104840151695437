import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

import styles from 'pages/recordings-list/components/live-recording-card/LiveRecordingCard.module.scss';
import { useDates } from 'shared/hooks/use-dates';

type MatchDayInfoProps = {
  date: Date;
};

const StartTime = ({ date }: MatchDayInfoProps) => {
  const { t } = useTranslation();
  const { dateToTime } = useDates();

  return (
    <Grid item xs={12} sm={'auto'} className={styles.recordingCardTag}>
      <span className={styles.recordingInfoTime}>{t('recordings-list:live-recording-card:start-time')}</span>{' '}
      {dateToTime(date)}
    </Grid>
  );
};

export default StartTime;
