import { useCallback } from 'react';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { userPresetsBaseUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { transformUserPreset } from 'api/user-presets/transformers';
import { PostUserPreset } from 'api/user-presets/types';
import { UserPreset } from 'shared/types/user-preset/types';

export const useSaveUserPreset = () => {
  const { mutate, isPending, isError, isSuccess } = useMutationRequest<UserPreset<any>[], UserPreset<unknown>>({
    type: HTTPMethod.POST,
    transformer: transformUserPreset,
  });

  const savePreset = useCallback(
    ({
      data,
      onSuccess = () => {},
    }: {
      data: PostUserPreset<unknown>;
      onSuccess?: (res: UserPreset<typeof data.value>) => void;
    }) => {
      mutate(
        { url: userPresetsBaseUrl, data },
        { onSuccess: (res: UserPreset<typeof data.value>) => onSuccess && onSuccess(res) },
      );
    },
    [mutate],
  );

  return { savePreset, isPending, isError, isSuccess };
};
