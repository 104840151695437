const {
  MODE: ENVIRONMENT,
  VITE_REACT_APP_RELEASE_VERSION,
  VITE_SUPERSET_URL,
  VITE_SUPERSET_PLAYER_PROFILE_DASHBOARD_ID,
  VITE_SUPERSET_PLAYER_PROFILE_EXPLORE_DASHBOARD_ID,
  VITE_SUPERSET_TEAM_PROFILE_EXPLORE_DASHBOARD_ID,
  PUBLIC_URL,
  DEV,
  PROD,
  BASE_URL,
} = import.meta.env;

export {
  ENVIRONMENT,
  VITE_REACT_APP_RELEASE_VERSION,
  PUBLIC_URL,
  DEV,
  PROD,
  BASE_URL,
  VITE_SUPERSET_URL,
  VITE_SUPERSET_PLAYER_PROFILE_DASHBOARD_ID,
  VITE_SUPERSET_PLAYER_PROFILE_EXPLORE_DASHBOARD_ID,
  VITE_SUPERSET_TEAM_PROFILE_EXPLORE_DASHBOARD_ID,
};
