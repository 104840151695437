import React from 'react';

import { RowContent } from 'pages/tactical-analysis/components/timeline/timeline-table/components/row-content';
import { useTimelineTableData } from 'pages/tactical-analysis/hooks/use-timeline-table-data';
import { hasFiltersResults } from 'pages/tactical-analysis/utils/has-filters-results';

export const FiltersContentRow = ({ recordingId }: { recordingId: string }) => {
  const timelineTableData = useTimelineTableData(recordingId);

  if (!timelineTableData.filtersRow || !hasFiltersResults(timelineTableData.filtersRow)) return null;

  return (
    <RowContent
      rowId={timelineTableData.filtersRow.id}
      clips={timelineTableData.filtersRow.clips}
      recordingId={recordingId}
    />
  );
};
