import { useDeleteUsers } from 'api/backoffice/user/use-delete-users/useDeleteUsers';
import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { createPluralValue } from 'pages/backoffice/utils/createPluralValue';
import ConfirmDialog from 'shared/components/confirm-dialog';

interface Props {
  users: KogniaUser[];
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export const DeleteUsersConfirmModal = ({ users, isOpen, onClose }: Props) => {
  const { deleteUsers } = useDeleteUsers();

  const handleConfirm = () => {
    deleteUsers({ users });
  };

  return (
    <ConfirmDialog
      type={'error'}
      title={`Are you sure you want to delete ${createPluralValue(users, 'user')} (${users.length})?`}
      cancelLabel={'Cancel'}
      confirmLabel={'Delete'}
      onConfirm={handleConfirm}
      isOpen={isOpen}
      setIsOpen={onClose}
    />
  );
};
