import { UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { transformSeason } from 'pages/backoffice/api/seasons/transformers';
import { Season } from 'pages/backoffice/types/seasons';

type Params = {
  seasonId?: string;
  onSuccess?: (season: Season) => void;
};

export const useSeason = ({ seasonId, onSuccess }: Params): UseQueryResult<Season> => {
  return useFetchRequest<Season>({
    queryRef: ['metadata-season', seasonId ?? ''],
    url: `${annotationApiUrls.SEASONS}/${seasonId ?? ''}`,
    transformer: transformSeason,
    options: {
      enabled: seasonId !== undefined,
    },
    onSuccess,
  });
};
