import { Grid, GridProps, styled } from '@mui/material';
import { ReactNode } from 'react';

const EnvironmentItemContainer = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

interface Props extends GridProps {
  children: ReactNode;
}

export const EnvironmentItemColumn = ({ children, ...rest }: Props) => {
  return (
    <EnvironmentItemContainer item xs={3} {...rest}>
      {children}
    </EnvironmentItemContainer>
  );
};
