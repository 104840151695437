import React from 'react';

import styles from 'shared/components/filter-block/FilterBlock.module.scss';

interface Props {
  children: React.ReactNode;
}

const FilterBlock = ({ children }: Props) => {
  return <section className={styles.container}>{children}</section>;
};

export default FilterBlock;
