import { Box } from '@mui/material';
import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDashboardEvents } from 'entities/dashboard/hooks/useDashboardEvents';
import { EmbeddedDashboardFeature } from 'features/dashboard/EmbeddedDashboardFeature';
import { SUPERSET_PLAYER_PROFILE_DASHBOARD_ID } from 'kognia/env';
import Container from 'shared/components/container';
import { Dialog } from 'shared/components/dialog';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import { useGenerateTemporaryPlaylist } from 'shared/hooks/useGenerateTemporaryPlaylist';
import { Playlist } from 'shared/types';
import { TemporaryPlaylistWidget } from 'widgets/playlist/temporary-playlist/TemporaryPlaylist.widget';

export const PlayerProfileDashboardPage = () => {
  const triggerNotification = useNotifications();
  const { t } = useTranslation();
  const branding = useBranding();
  const [temporalPlaylist, setTemporalPlaylist] = useState<Playlist | null>(null);
  const generateTemporaryPlaylist = useGenerateTemporaryPlaylist({ onSuccess: setTemporalPlaylist });

  useEffect(() => {
    document.title = t('common:metas.title.explore', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  useDashboardEvents({ onEvent: generateTemporaryPlaylist });

  useEffect(() => {
    if (!SUPERSET_PLAYER_PROFILE_DASHBOARD_ID) {
      triggerNotification({
        type: NotificationType.ERROR,
        message: t('common:error.player-profile'),
      });

      throw new Error('Player profile not found due to local configuration issue');
    }
  }, [t, triggerNotification]);

  return (
    <Container fullScreen>
      {SUPERSET_PLAYER_PROFILE_DASHBOARD_ID ? (
        <>
          <Suspense>
            <EmbeddedDashboardFeature
              key={SUPERSET_PLAYER_PROFILE_DASHBOARD_ID}
              dashboardId={SUPERSET_PLAYER_PROFILE_DASHBOARD_ID}
            />
          </Suspense>
          <Dialog onClose={() => setTemporalPlaylist(null)} open={!!temporalPlaylist} fullWidth maxWidth={false}>
            <Box
              height={(theme) => `calc(100vh - ${theme.spacing(8)})`}
              width={(theme) => `calc(100vw - ${theme.spacing(8)})`}
            >
              {temporalPlaylist && <TemporaryPlaylistWidget playlist={temporalPlaylist} />}
            </Box>
          </Dialog>
        </>
      ) : null}
    </Container>
  );
};
