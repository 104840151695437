import memoize from 'lodash/memoize';

const formatDuration = (seconds: number, shortForm: boolean) => {
  const mins = ~~(seconds / 60);
  const secs = ~~seconds % 60;
  const ret = [];

  ret.push(`${String(mins).padStart(shortForm ? 1 : 2, '0')}:${secs < 10 ? '0' : ''}`);
  ret.push(`${secs}`);
  return ret.join('');
};

export const secondsAsDuration = memoize(formatDuration, (seconds: number, shortForm: boolean) =>
  [seconds, shortForm].join('-'),
);
