import { z } from 'zod';

import { playerBackendApiResponseSchema } from 'shared/types/player/playerBackendApiSchema';
import { playerFixturesSchema, playerMappingSchema, playerSchema } from 'shared/types/player/playerSchema';
import {
  playerSportMetadataApiResponseSchema,
  playerSportMetadataApiSchema,
} from 'shared/types/player/playerSportMetadataApiSchema';

export type PlayerSportMetadataApi = z.infer<typeof playerSportMetadataApiSchema>;

export type PlayerSportMetadataApiResponse = z.infer<typeof playerSportMetadataApiResponseSchema>;

export type PlayerBackendApiResponse = z.infer<typeof playerBackendApiResponseSchema>;

export type Player = z.infer<typeof playerSchema>;

export type PlayerMapping = z.infer<typeof playerMappingSchema>;

export type PlayerFixture = z.infer<typeof playerFixturesSchema>;

export type PlayerApiFilters = {
  name?: string;
  sort?: string;
  sortDirection?: string;
  isFavorite?: boolean;
};

export const PLAYER_STAT = {
  appearances: 'appearances',
  totalMinutes: 'totalMinutes',
  minutesStarting: 'minutesStarting',
  goals: 'goals',
  assists: 'assists',
  yellowCards: 'yellowCards',
  redCards: 'redCards',
} as const;

export type PlayerStats = {
  [PLAYER_STAT.appearances]: number | string;
  [PLAYER_STAT.totalMinutes]: number | string;
  [PLAYER_STAT.minutesStarting]: number | string;
  [PLAYER_STAT.goals]: number | string;
  [PLAYER_STAT.assists]: number | string;
  [PLAYER_STAT.yellowCards]: number | string;
  [PLAYER_STAT.redCards]: number | string;
};
