import { MatchSegmentTypes } from 'shared/types/segment/types';

export enum TacticActionType {
  OFFENSIVE = 'offensive',
  DEFENSIVE = 'defensive',
}

type MatchTime = {
  frame: number;
  'time-in-match': number;
  'time-in-segment': number;
};

export interface Video {
  'video-path': string;
  'video-download-path': string;
  players: Array<any>;
  segment: MatchSegmentTypes;
  start: MatchTime;
  end: MatchTime;
  type?: string;
}
