import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { generateTeamsUrl } from 'api/routes';
import { transformTeams } from 'api/teams/use-teams-infinity-query/utils/transformTeams';
import { SortDirection } from 'shared/types/filters/types';
import { Team, TeamApiBackendResponse, TeamsFilters } from 'shared/types/team/team';

const PAGE_SIZE = 15;

type Args = {
  initialFilters?: Pick<TeamsFilters, 'seasonId' | 'name'>;
};

const INITIAL_TEAMS_FILTERS: TeamsFilters = {
  seasonId: '',
  name: '',
};

export const useTeamsInfinityQuery = (args?: Args): InfinityQueryResult<Team, TeamsFilters> => {
  return useInfinityQuery<Team, TeamsFilters, TeamApiBackendResponse>({
    generateUrl: generateTeamsUrl,
    initialFilters: args?.initialFilters ?? INITIAL_TEAMS_FILTERS,
    key: 'season-teams',
    options: {
      size: PAGE_SIZE,
      sort: '',
      sortDirection: SortDirection.ASC,
    },
    transformer: transformTeams,
  });
};
