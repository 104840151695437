import { defensiveTactics, offensiveTactics, TacticId } from '@kognia/tactical-analysis-data';
import { useCallback, useMemo } from 'react';

import { useSaveUserPreset } from 'api/user-presets/use-save-user-preset';
import { invalidateUserPresetsQuery } from 'api/user-presets/use-user-presets';
import { Timeline } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/timeline';
import { useTimelineTableData } from 'pages/tactical-analysis/hooks/use-timeline-table-data';
import { useCurrentPlaylistItem } from 'shared/components/video-player';
import { TimelineSelectedTacticsPreset } from 'shared/constants/user-presets/userPresetsClasses';
import { TacticIdOrAll } from 'shared/types/playlist/types';

const extractTacticsFromTimelineData = (
  timelineData: Timeline,
): {
  offensive: Set<any>;
  defensive: Set<any>;
} => {
  return Array.from(timelineData.tacticsIdsList).reduce(
    (acc, tacticId) => {
      if (offensiveTactics.includes(tacticId)) {
        acc.offensive.add(tacticId);
      }
      if (defensiveTactics.includes(tacticId)) {
        acc.defensive.add(tacticId);
      }

      return acc;
    },
    {
      offensive: new Set(),
      defensive: new Set(),
    },
  );
};

export const useTimelineTactics = (recordingId: string) => {
  const timelineData = useTimelineTableData(recordingId);
  const { savePreset } = useSaveUserPreset();
  const currentPlaylistItem = useCurrentPlaylistItem();
  const extractedTacticsFromTimelineData = useMemo(() => extractTacticsFromTimelineData(timelineData), [timelineData]);
  const availableTactics: TacticId[] = useMemo(
    () => [...extractedTacticsFromTimelineData.offensive, ...extractedTacticsFromTimelineData.defensive],
    [extractedTacticsFromTimelineData.defensive, extractedTacticsFromTimelineData.offensive],
  );

  const selectedTactics: TacticId[] = useMemo(() => {
    if (currentPlaylistItem?.fundamentalsSelected.fundamentalsSelected[0] === 'all') {
      return availableTactics;
    }

    return currentPlaylistItem.fundamentalsSelected.fundamentalsSelected as TacticId[];
  }, [availableTactics, currentPlaylistItem.fundamentalsSelected.fundamentalsSelected]);

  const handleTacticSelection = useCallback(
    async (tacticsIds: TacticId[]) => {
      const tacticsIdsToSave: TacticIdOrAll[] = tacticsIds.length === availableTactics.length ? ['all'] : tacticsIds;
      const preset = new TimelineSelectedTacticsPreset(tacticsIdsToSave, recordingId);
      savePreset({ data: preset, onSuccess: invalidateUserPresetsQuery });
    },
    [availableTactics.length, recordingId, savePreset],
  );

  return {
    selectedTactics,
    setSelectedTactics: handleTacticSelection,
    tactics: availableTactics,
  };
};
