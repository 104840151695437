import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { PLAYBACK_RATES } from 'shared/constants/playback-rates/paybackRates';
import { PlaybackRate } from 'shared/types/payback-rates/payback-rates';

const videoPlayerSpeedAtom = atom<PlaybackRate>({
  key: 'video-player-speed',
  default: PLAYBACK_RATES.normal,
});

export const useVideoPlayerSpeed = () => {
  return useRecoilValue(videoPlayerSpeedAtom);
};

export const useSetVideoPlayerSpeed = () => {
  return useSetRecoilState(videoPlayerSpeedAtom);
};
