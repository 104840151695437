import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { ReprocessThirdPartyEventsApiParams } from 'pages/backoffice/api/types';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';

interface ReprocessThirdPartyEventsParams {
  data: ReprocessThirdPartyEventsApiParams;
}

export const useReprocessThirdPartyEvents = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Reprocess third party events success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Reprocess third party events error'),
  });

  const reprocessThirdPartyEvents = ({ data }: ReprocessThirdPartyEventsParams) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate({ url: `${annotationApiUrls.THIRD_PARTY_EVENTS}`, data });
  };

  return { ...mutationRequest, reprocessThirdPartyEvents };
};
