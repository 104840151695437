import { useCallback } from 'react';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { transformPlaylist } from 'api/playlist/transformers';
import { PlaylistApiResponse } from 'api/playlist/types';
import { playlistPreview } from 'api/routes';
import { BackendApiError, backendApiErrorParser } from 'api/utils/backend-api-error-parser';
import { Playlist } from 'shared/types';
import { DashboardPlaylistGenericPayload } from 'shared/types/embeddedDashboard';

export const useGenerateTemporaryPlaylist = ({ onSuccess }: { onSuccess: (response: Playlist) => void }) => {
  const mutationRequest = useMutationRequest<PlaylistApiResponse, Playlist, BackendApiError>({
    transformer: transformPlaylist,
    onSuccess,
    errorMessage: (response) => backendApiErrorParser(response, 'Error generating playlist'),
  });

  return useCallback(
    (payload: DashboardPlaylistGenericPayload) => {
      return mutationRequest.mutate({
        url: playlistPreview,
        data: {
          name: payload.name,
          items: payload.items.map((item) => ({
            startFrame: item.start_frame,
            endFrame: item.end_frame,
            recordingId: item.recording_id,
            name: item.name,
          })),
        },
      });
    },
    [mutationRequest],
  );
};
