import React, { PropsWithChildren, ReactNode } from 'react';

import { FormInputLabel, FormItem } from 'shared/components/form/styled';

interface Props {
  label: ReactNode;
  id: string;
  required?: boolean;
}

export const FormField = ({ children, label, required, id }: PropsWithChildren<Props>) => {
  return (
    <FormItem>
      <FormInputLabel id={id}>
        {label}
        {required ? '*' : null}
      </FormInputLabel>
      {children}
    </FormItem>
  );
};
