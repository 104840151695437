import { DefensiveTactics, OffensiveTactics } from '@kognia/tactical-analysis-data';
import { OverlayElementNames } from '../types';
import { ReferenceType } from '../utils/loaders';

export type OverlayElement = {
  overlayElementTypeId: OverlayElementNames;
  references: {
    referenceType: ReferenceType;
  }[];
};

export type TacticConfig = {
  name: OffensiveTactics | DefensiveTactics;
  overlayElements: OverlayElement[];
};

export type TacticsConfig = {
  [key in OffensiveTactics | DefensiveTactics]?: TacticConfig;
};

export const tacticsConfig: TacticsConfig = {
  [OffensiveTactics.WIDTH_OF_THE_TEAM]: {
    name: OffensiveTactics.WIDTH_OF_THE_TEAM,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.WIDE_PLAYER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTactics.PRESSURE_ON_THE_BALL_POSSESSOR]: {
    name: DefensiveTactics.PRESSURE_ON_THE_BALL_POSSESSOR,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.DEFENDER_PRESSING_POSSESSOR_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTactics.MOVING_FORWARD_DURING_ORGANIZED_PRESSURE]: {
    name: DefensiveTactics.MOVING_FORWARD_DURING_ORGANIZED_PRESSURE,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.MOVING_FORWARD_DURING_ORGANIZED_PRESSURE_ARROWS,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTactics.DEFENDING_MOVING_BEHIND_THE_DEFENSIVE_LINE]: {
    name: DefensiveTactics.DEFENDING_MOVING_BEHIND_THE_DEFENSIVE_LINE,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.DEFENDING_RUN_BEHIND_DEFENSIVE_LINE,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [OffensiveTactics.MOVING_BEHIND_THE_DEFENSIVE_LINE]: {
    name: OffensiveTactics.MOVING_BEHIND_THE_DEFENSIVE_LINE,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.RUN_BEHIND_DEFENSIVE_LINE,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTactics.CLEAR_THE_BOX]: {
    name: DefensiveTactics.CLEAR_THE_BOX,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.CLEAR_THE_BOX_DEFENDER_ARROW,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTactics.SECOND_BALL_DEFENSIVE_WINNING_AFTER_DIRECT_PLAY]: {
    name: DefensiveTactics.SECOND_BALL_DEFENSIVE_WINNING_AFTER_DIRECT_PLAY,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.SECOND_BALL_DEFENSIVE_CONTROLLER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTactics.SECOND_BALL_DEFENSIVE_WINNING_AFTER_FINISHING]: {
    name: DefensiveTactics.SECOND_BALL_DEFENSIVE_WINNING_AFTER_FINISHING,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.SECOND_BALL_DEFENSIVE_CONTROLLER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTactics.SECOND_BALL_DEFENSIVE_WINNING_AFTER_CROSS]: {
    name: DefensiveTactics.SECOND_BALL_DEFENSIVE_WINNING_AFTER_CROSS,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.SECOND_BALL_DEFENSIVE_CONTROLLER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTactics.SECOND_BALL_DEFENSIVE_WINNING_AFTER_SET_PIECE]: {
    name: DefensiveTactics.SECOND_BALL_DEFENSIVE_WINNING_AFTER_SET_PIECE,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.SECOND_BALL_DEFENSIVE_CONTROLLER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [OffensiveTactics.SECOND_BALL_OFFENSIVE_WINNING_AFTER_DIRECT_PLAY]: {
    name: OffensiveTactics.SECOND_BALL_OFFENSIVE_WINNING_AFTER_DIRECT_PLAY,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.SECOND_BALL_OFFENSIVE_CONTROLLER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [OffensiveTactics.SECOND_BALL_OFFENSIVE_WINNING_AFTER_FINISHING]: {
    name: OffensiveTactics.SECOND_BALL_OFFENSIVE_WINNING_AFTER_FINISHING,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.SECOND_BALL_OFFENSIVE_CONTROLLER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [OffensiveTactics.SECOND_BALL_OFFENSIVE_WINNING_AFTER_CROSS]: {
    name: OffensiveTactics.SECOND_BALL_OFFENSIVE_WINNING_AFTER_CROSS,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.SECOND_BALL_OFFENSIVE_CONTROLLER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [OffensiveTactics.SECOND_BALL_OFFENSIVE_WINNING_AFTER_SET_PIECE]: {
    name: OffensiveTactics.SECOND_BALL_OFFENSIVE_WINNING_AFTER_SET_PIECE,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.SECOND_BALL_OFFENSIVE_CONTROLLER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [DefensiveTactics.DEFENDING_RUNNING_INTO_THE_BOX]: {
    name: DefensiveTactics.DEFENDING_RUNNING_INTO_THE_BOX,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.DEFENDING_RUN_INTO_THE_BOX,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [OffensiveTactics.RUNNING_INTO_THE_BOX]: {
    name: OffensiveTactics.RUNNING_INTO_THE_BOX,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.RUN_INTO_THE_BOX,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
  [OffensiveTactics.PASS_BEHIND_DEFENSIVE_LINE]: {
    name: OffensiveTactics.PASS_BEHIND_DEFENSIVE_LINE,
    overlayElements: [
      {
        overlayElementTypeId: OverlayElementNames.PASS_BEHIND_DEFENSIVE_LINE_RECEIVER_HIGHLIGHT,
        references: [
          {
            referenceType: ReferenceType.StaticCoordinates,
          },
        ],
      },
      {
        overlayElementTypeId: OverlayElementNames.PASS_BEHIND_DEFENSIVE_LINE_ARROW,
        references: [
          {
            referenceType: ReferenceType.Players,
          },
        ],
      },
    ],
  },
};
