import { Checkbox } from '@mui/material';
import some from 'lodash/some';
import { useEffect, useState } from 'react';

import { FilterOption } from 'shared/components/filter-v2';
import FilterControls from 'shared/components/filter-v2/filter-controls';
import { FormControlLabel, CheckboxMenuWrapper } from 'shared/components/filter-v2-menu/checkbox/CheckboxMenu.styled';

type CheckBoxMenuProps = {
  initialOptions: FilterOption[];
  submitOptions: (options: FilterOption[], autoSubmit: boolean) => void;
  autoSubmit?: boolean;
};

const CheckBoxMenu = ({ initialOptions, submitOptions, autoSubmit = false }: CheckBoxMenuProps) => {
  const [menuOptions, setMenuOptions] = useState<FilterOption[]>(initialOptions);

  useEffect(() => {
    setMenuOptions(initialOptions);
  }, [initialOptions]);

  const onSelectOption = (id: string) => {
    const updatedOptions = menuOptions.map((option) => {
      return option.id === id ? { ...option, ...{ isSelected: !option.isSelected } } : option;
    }) as FilterOption[];
    autoSubmit ? submitOptions(updatedOptions, autoSubmit) : setMenuOptions(updatedOptions);
  };

  const resetOptions = () => {
    const updatedOptions = menuOptions.map((option) => ({ ...option, ...{ isSelected: false } })) as FilterOption[];
    autoSubmit ? submitOptions(updatedOptions, autoSubmit) : setMenuOptions(updatedOptions);
  };

  const handleSubmit = () => {
    submitOptions(menuOptions, autoSubmit);
  };

  const isResetDisabled = !some(menuOptions, ['isSelected', true]);

  return (
    <>
      <CheckboxMenuWrapper>
        {menuOptions.map((option: FilterOption) => (
          <div key={option.id}>
            <FormControlLabel
              label={option.title}
              control={
                <Checkbox
                  id={option.id}
                  key={option.id}
                  checked={option.isSelected}
                  onClick={() => onSelectOption(option.id)}
                />
              }
            />
          </div>
        ))}
      </CheckboxMenuWrapper>
      <FilterControls
        isResetDisabled={isResetDisabled}
        onClickReset={resetOptions}
        onClickApply={handleSubmit}
        showApplyButton={!autoSubmit}
      />
    </>
  );
};

export default CheckBoxMenu;
