import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { PlaylistDownloadRequestsParams } from 'api/playlist/use-download-playlist/types/playlistDownloadRequestsParams';
import { PlaylistDownloadType } from 'api/playlist/use-download-playlist/types/playlistDownloadType';
import { playlistDownloadRequestsPlaylistIdUrl, playlistDownloadRequestsUrl } from 'api/routes';
import { BackendApiError, backendApiErrorParser } from 'api/utils/backend-api-error-parser';
import { routes } from 'kognia/router/routes';
import { NotificationLink } from 'shared/components/NotificationLink';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';
import { FundamentalsSelection, Playlist } from 'shared/types/playlist/types';

interface DownloadPlaylistItemData {
  recordingId: string;
  name: string;
  startTime: number;
  endTime: number;
  fundamentalsSelected: FundamentalsSelection;
}

type PreviewPlaylistOptions = { name: string } & PlaylistDownloadRequestsParams;
type PlaylistOptions = { name?: string } & PlaylistDownloadRequestsParams;

interface DownloadPreviewPlaylistData {
  items?: DownloadPlaylistItemData[];
  options: PreviewPlaylistOptions;
}

interface DownloadPlaylistData {
  playlistItemsIds?: string[];
  options: PlaylistOptions;
}

interface PlaylistDownloadRequests {
  data: Playlist;
  params: PlaylistDownloadRequestsParams;
}

interface PlaylistDownloadRequestsPlaylistId {
  playlistId: string;
  params: PlaylistDownloadRequestsParams;
}

interface PlaylistDownloadRequestsPlaylistItems {
  playlistItemsIds: string[];
  playlistId: string;
  params: PlaylistDownloadRequestsParams;
}

const mapPreviewPlaylistToDownloadData = (request: PlaylistDownloadRequests): DownloadPreviewPlaylistData => {
  return {
    items: request.data.playlistItems.map((item) => ({
      recordingId: item.recordingId,
      name: item.name ?? '',
      startTime: item.startTime,
      endTime: item.endTime,
      fundamentalsSelected: item.fundamentalsSelected,
    })),
    options: {
      ...request.params,
    },
  };
};

export const useDownloadPlaylist = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const triggerNotification = useNotifications();

  const { mutate } = useMutationRequest<unknown, unknown, BackendApiError>({
    onSuccess: () =>
      triggerNotification({
        type: NotificationType.INFO,
        message: (
          <Trans
            i18nKey={'download-manager:download-ready-notification'}
            components={{
              downloadManagerLink: <NotificationLink onClick={() => navigate(routes.DOWNLOAD_MANAGER)} />,
            }}
          />
        ),
      }),
    errorMessage: (response) => backendApiErrorParser(response, t('api:use-download-playlist.error')),
  });

  const downloadTemporaryPlaylist = useCallback(
    (payload: PlaylistDownloadRequests) => {
      mutate({
        url: playlistDownloadRequestsUrl,
        data: mapPreviewPlaylistToDownloadData(payload),
      });
    },
    [mutate],
  );

  const downloadPlaylistItems = useCallback(
    (payload: PlaylistDownloadRequestsPlaylistItems) => {
      mutate({
        url: playlistDownloadRequestsPlaylistIdUrl(payload.playlistId),
        data: {
          playlistItemsIds: payload.playlistItemsIds,
          options: {
            ...payload.params,
          },
        } as DownloadPlaylistData,
      });
    },
    [mutate],
  );

  const downloadPlaylist = useCallback(
    (payload: PlaylistDownloadRequestsPlaylistId) => {
      mutate({
        url: playlistDownloadRequestsPlaylistIdUrl(payload.playlistId),
        data: {
          options: {
            ...payload.params,
          },
        } as DownloadPlaylistData,
      });
    },
    [mutate],
  );

  return useCallback(
    ({
      params,
      data,
      type,
    }: {
      params: PlaylistDownloadRequestsParams;
      data: Playlist;
      type: PlaylistDownloadType;
    }) => {
      if (type === 'temporaryPlaylist') {
        downloadTemporaryPlaylist({
          data,
          params,
        });
      }

      if (type === 'playlistItems') {
        downloadPlaylistItems({
          playlistItemsIds: data.playlistItems.map((item) => item.id),
          playlistId: data.id,
          params,
        });
      }

      if (type === 'playlist') {
        downloadPlaylist({
          playlistId: data.id,
          params,
        });
      }
    },
    [downloadPlaylist, downloadPlaylistItems, downloadTemporaryPlaylist],
  );
};
