import { List } from '@mui/material';

import { CompetitionItem } from 'pages/backoffice/pages/competitions/components/competition-item';
import { Competition } from 'pages/backoffice/types/competitions';

interface Props {
  competitions: Competition[];
}

export const CompetitionsList = ({ competitions }: Props) => {
  const competitionsItems = competitions.map((competition) => {
    return <CompetitionItem key={competition.id} competition={competition} />;
  });

  return <List>{competitionsItems}</List>;
};
