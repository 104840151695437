import { Box, Typography } from '@mui/material';
import React from 'react';

interface Props extends React.PropsWithChildren {
  label: string;
}

export const UserDetailsModalGridRow = ({ label, children }: Props) => {
  return (
    <Box
      key={label}
      display='grid'
      gridTemplateColumns='minmax(80px, 0.5fr) 1fr'
      gap={2}
      borderBottom='1px solid'
      borderColor='secondary.light'
      pb={1}
    >
      <Typography fontWeight='fontWeightMedium'>{label}</Typography>
      <Typography sx={{ wordBreak: 'break-all' }}>{children}</Typography>
    </Box>
  );
};
