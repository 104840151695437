import { IconButton, Stack, Typography } from '@mui/material';
import { IconChevronLeft } from 'kognia-ui/icons/IconChevronLeft';
import { IconChevronRight } from 'kognia-ui/icons/IconChevronRight';

export interface DownloadManagerPaginationProps {
  page: number;
  totalPages: number;
  onClickPrev: () => void;
  onClickNext: () => void;
}

export const DownloadManagerPagination = ({
  page,
  totalPages,
  onClickPrev,
  onClickNext,
}: DownloadManagerPaginationProps) => {
  return (
    <>
      {totalPages > 1 ? (
        <Stack direction='row' alignItems='center' gap={0.5}>
          <IconButton
            size='small'
            sx={{ padding: 0, color: 'text.primary' }}
            disabled={page === 0}
            onClick={onClickPrev}
            data-testid='download-manager-pagination-button-prev'
          >
            <IconChevronLeft size='small' color='inherit' />
          </IconButton>

          <Typography
            variant='caption'
            color='secondary'
            lineHeight={1}
            fontWeight='fontWeightMedium'
            sx={{ userSelect: 'none' }}
          >
            {page + 1} of {totalPages}
          </Typography>

          <IconButton
            size='small'
            sx={{ padding: 0, color: 'text.primary' }}
            disabled={page + 1 === totalPages}
            onClick={onClickNext}
            data-testid='download-manager-pagination-button-next'
          >
            <IconChevronRight size='small' color='inherit' />
          </IconButton>
        </Stack>
      ) : null}
    </>
  );
};
