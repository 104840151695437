import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { usePlayerStats } from 'entities/dashboard/api/usePlayerStats';
import { statsTranslationKeys } from 'features/dashboard/player-profile-stats/constants/translationKeys';
import { PlayerProfileStatsSkeleton } from 'features/dashboard/player-profile-stats/ui/PlayerProfileStatsSkeleton';
import { Stat } from 'features/dashboard/player-profile-stats/ui/Stat';
import { PLAYER_STAT } from 'shared/types/player/player';

type Props = {
  playerId: string;
  competitionId: string;
  seasonYear: number;
};

const EMPTY_STATS = {
  [PLAYER_STAT.appearances]: '-',
  [PLAYER_STAT.totalMinutes]: '-',
  [PLAYER_STAT.minutesStarting]: '-',
  [PLAYER_STAT.goals]: '-',
  [PLAYER_STAT.assists]: '-',
  [PLAYER_STAT.yellowCards]: '-',
  [PLAYER_STAT.redCards]: '-',
};

export const PlayerProfileStats = ({ playerId, competitionId, seasonYear }: Props) => {
  const { t } = useTranslation('player');
  const { data: playerStats, isLoading } = usePlayerStats({
    playerId,
    competitionId,
    seasonYear,
  });

  if (isLoading) {
    return <PlayerProfileStatsSkeleton />;
  }

  const stats = playerStats ? playerStats : EMPTY_STATS;

  return (
    <Stack gap={2}>
      <Stack direction={'row'} justifyContent={'center'}>
        <Stat
          isEmpty={!playerStats}
          value={stats[PLAYER_STAT.appearances]}
          name={t(statsTranslationKeys[PLAYER_STAT.appearances])}
        />
        <Stat
          isEmpty={!playerStats}
          value={stats[PLAYER_STAT.totalMinutes]}
          name={t(statsTranslationKeys[PLAYER_STAT.totalMinutes])}
        />
        <Stat
          isEmpty={!playerStats}
          value={stats[PLAYER_STAT.minutesStarting]}
          name={t(statsTranslationKeys[PLAYER_STAT.minutesStarting])}
        />
        <Stat
          isEmpty={!playerStats}
          value={stats[PLAYER_STAT.goals]}
          name={t(statsTranslationKeys[PLAYER_STAT.goals])}
        />
      </Stack>

      <Stack direction={'row'} justifyContent={'center'}>
        <Stat
          isEmpty={!playerStats}
          value={stats[PLAYER_STAT.assists]}
          name={t(statsTranslationKeys[PLAYER_STAT.assists])}
        />
        <Stat
          isEmpty={!playerStats}
          value={stats[PLAYER_STAT.yellowCards]}
          name={t(statsTranslationKeys[PLAYER_STAT.yellowCards])}
        />
        <Stat
          isEmpty={!playerStats}
          value={stats[PLAYER_STAT.redCards]}
          name={t(statsTranslationKeys[PLAYER_STAT.redCards])}
        />
      </Stack>
    </Stack>
  );
};
