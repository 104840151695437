import { useSetAtom } from 'jotai';
import { useEffect } from 'react';

import { useUpdateDownloadRequests } from 'api/download-manager/use-update-download-requests/useUpdateDownloadRequests';
import { previousAmountNotViewedRecordings } from 'entities/download-manager/store/previousAmountNotViewedRecordings';
import { NOTIFICATION_DOWNLOAD_REQUESTS_QUERY_KEY } from 'shared/constants/download-manager/downloadManagerQueryKeys';
import { DownloadRequestsData } from 'shared/types/download-manager/downloadRequests';

export const useMarkDownloadRequestsAsViewed = (data: DownloadRequestsData | undefined) => {
  const { updateDownloadRequests } = useUpdateDownloadRequests();
  const setPreviousAmountOfNotViewedRecordings = useSetAtom(previousAmountNotViewedRecordings);

  useEffect(() => {
    if (data) {
      const isNotViewedData = data.downloadRequests.content.some(({ viewed }) => !viewed);

      if (isNotViewedData) {
        updateDownloadRequests([NOTIFICATION_DOWNLOAD_REQUESTS_QUERY_KEY]).then(() => {
          setPreviousAmountOfNotViewedRecordings(null);
        });
      }
    }
  }, [updateDownloadRequests, data, setPreviousAmountOfNotViewedRecordings]);
};
