import { Box } from '@mui/material';
import { forwardRef, PropsWithChildren } from 'react';

import { useTimelineInnerWidth } from 'pages/tactical-analysis/components/timeline/timeline-table/hooks/use-generate-timeline-width';
import { useTacticalAnalysisEpisodes } from 'pages/tactical-analysis/hooks/use-tactical-analysis-episodes';

export const EpisodesRow = forwardRef(
  (
    {
      children,
      recordingId,
    }: PropsWithChildren<{
      recordingId: string;
    }>,
    ref: any,
  ) => {
    const episodes = useTacticalAnalysisEpisodes(recordingId);
    const width = useTimelineInnerWidth(recordingId);

    if (episodes.length === 0) return null;

    return (
      <Box
        ref={ref}
        display='inline-flex'
        height='34px'
        py={0}
        px={3}
        bgcolor='common.white'
        style={{ width: `${width}px` }}
      >
        {children}
      </Box>
    );
  },
);

EpisodesRow.displayName = 'EpisodesRow';
