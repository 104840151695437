export const makeRecordingDetailRoute = (params: { recordingId: string; showsKeypad?: boolean; isLive?: boolean }) =>
  `/tagging-tool/${encodeURIComponent(params.recordingId)}${params.isLive === true ? '?isLive=true' : '?isLive=false'}${
    params.showsKeypad === true ? '&showsKeypad=1' : ''
  }`;

export const makeRecordingAlignmentSourcesRoute = (params: { recordingId: string; isLive?: boolean }) =>
  `/tagging-tool/${encodeURIComponent(params.recordingId)}/align/sources${
    params.isLive === true ? '?isLive=true' : '?isLive=false'
  }`;

export const makeRecordingAlignmentRoute = (params: {
  recordingId: string;
  sourceRecordingId?: string;
  isLive?: boolean;
}) =>
  `/tagging-tool/${encodeURIComponent(params.recordingId)}/align${
    params.sourceRecordingId ? `/${params.sourceRecordingId}` : ''
  }${params.isLive === true ? '?isLive=true' : '?isLive=false'}`;

export const makeTimelineRoute = (params: { recordingId: string }) =>
  `/performance-report/${encodeURIComponent(params.recordingId)}/timeline`;

export const makePlaylistIndexRoute = (params: { recordingId: string }) =>
  `/recordings/${params.recordingId}/playlists`;
