import classNames from 'classnames';

import styles from 'shared/components/video-player/video-player-component/components/text-overlay/TextOverlay.module.scss';

interface Props {
  prefix: string;
  description: string;
  fullScreen: boolean;
}

export const TextOverlay = ({ prefix, description, fullScreen }: Props) => {
  return (
    <div className={classNames(styles.textOverlay, { [styles.textOverlayFullScreen]: fullScreen })}>
      <span className={styles.textOverlayPrefix}>{prefix}</span>
      <span className={styles.textOverlayDescription}>{description}</span>
    </div>
  );
};
