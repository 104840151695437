import { useEffect, useState } from 'react';

import { useClients } from 'api/backoffice/client/hooks/use-clients/useClients';
import { Client } from 'shared/types';

export const useAutocompleteEnvironmentsState = (environmentIds: string[]) => {
  const [values, setValues] = useState<Client[]>([]);
  const [isEnabled, setIsEnabled] = useState<boolean>(Boolean(environmentIds.length));

  const { items, isSuccess } = useClients({
    enabled: isEnabled,
    pageSize: environmentIds.length,
    initialFilters: {
      clientIds: environmentIds,
    },
  });

  useEffect(() => {
    if (!isEnabled) return;

    if (isSuccess) {
      setValues(items);
      setIsEnabled(false);
    }
  }, [isEnabled, isSuccess, items, environmentIds]);

  return [values, setValues] as const;
};
