import { ListItem, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

export const TeamListItem = styled(ListItem)(({ theme }) => ({
  display: 'grid',
  flexDirection: 'row',
  gridTemplateColumns: '1fr 1fr 1fr auto',
  borderBottom: `1px solid ${Colors.iron}`,
  padding: `${theme.spacing(1)} ${theme.spacing(2)} ${theme.spacing(1)} ${theme.spacing(2)} !important`,
}));
