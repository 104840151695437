import { Colors } from 'kognia-ui';
import React, { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import {
  ErrorContainerButton,
  ErrorContainerContent,
  ErrorContainerImage,
  ErrorContainerLink,
  ErrorContainerText,
  ErrorContainerTitle,
  ErrorContainerWrapper,
} from 'features/error-container/ErrorContainer.feature.styled';
import { routes } from 'kognia/router/routes';
import IconWhistle from 'shared/assets/icon-whistle.svg';
import PageBackground from 'shared/assets/page-background.svg';
import { ContainerWrapper } from 'shared/components/container/index.styled';

export const ErrorContainerFeature = () => {
  const { t } = useTranslation('error-container.feature');

  const handleRefreshPage = useCallback(() => {
    window.location.reload();
  }, []);

  return (
    <ErrorContainerWrapper>
      <ContainerWrapper backgroundImage={PageBackground} backgroundSize='cover' backgroundColor={Colors.white}>
        <ErrorContainerContent>
          <ErrorContainerImage>
            <img src={IconWhistle} alt='' />
          </ErrorContainerImage>
          <ErrorContainerTitle>{t('error.something-went-wrong')}</ErrorContainerTitle>
          <ErrorContainerText>{t('error.try-again')}</ErrorContainerText>
          <ErrorContainerText>
            <Trans
              i18nKey={'error-container.feature:error.submit-feedback'}
              components={{
                submitFeedbackLink: <ErrorContainerLink target='_blank' href={routes.SUBMIT_FEEDBACK} />,
              }}
            />
          </ErrorContainerText>
          <ErrorContainerButton size='large' variant='contained' onClick={handleRefreshPage}>
            {t('error.refresh-page')}
          </ErrorContainerButton>
        </ErrorContainerContent>
      </ContainerWrapper>
    </ErrorContainerWrapper>
  );
};
