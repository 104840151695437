import { GlobalStyles } from '@mui/material';
import { Colors } from 'kognia-ui';
import React, { useMemo } from 'react';

import { getTimeZoomLevelAdjustment } from 'pages/tactical-analysis/components/tactical-analysis/utils/adjust-time-by-zoom-level';
import { useTimelineInnerWidth } from 'pages/tactical-analysis/components/timeline/timeline-table/hooks/use-generate-timeline-width';
import { useTimelineTeamIdFocus } from 'pages/tactical-analysis/hooks/use-timeline-team-id-focus';
import { useTimelineZoomLevel } from 'pages/tactical-analysis/hooks/use-timeline-zoom-level';
import { useVideoPlayerPlayingMode } from 'shared/components/video-player';

export const SEPARATOR_WIDTH = 12;
export const SEPARATOR_DOUBLE_WIDTH = SEPARATOR_WIDTH * 5;

export const CSS_HOME_TEAM_COLOR = '--home-team-color';
export const CSS_HOME_TEAM_COLOR_HOVER = '--home-team-color-hover';
export const CSS_OPPONENT_TEAM_COLOR = '--opponent-team-color';
export const CSS_OPPONENT_TEAM_COLOR_HOVER = '--opponent-team-color-hover';
export const CSS_FILTER_COLOR = '--filter-color';
export const CSS_FILTER_COLOR_HOVER = '--filter-color-hover';
export const CSS_EPISODE_CLIP_COLOR = '--episode-clip-color';
export const CSS_EPISODE_CLIP_COLOR_HOVER = '--episode-clip-color-hover';
export const CSS_TAGS_OFFENSE_COLOR = '--tags-offense-color';
export const CSS_TAGS_OFFENSE_COLOR_HOVER = '--tags-offense-color-hover';
export const CSS_TAGS_DEFENSIVE_COLOR = '--tags-defensive-color';
export const CSS_TAGS_DEFENSIVE_COLOR_HOVER = '--tags-defensive-color-hover';
export const CSS_TAGS_TRANSITION_COLOR = '--tags-transition-color';
export const CSS_TAGS_TRANSITION_COLOR_HOVER = '--tags-transition-color-hover';
export const CSS_TAGS_IMPORTED_COLOR = '--tags-imported-color';
export const CSS_TAGS_IMPORTED_COLOR_HOVER = '--tags-imported-color-hover';
export const CSS_CLIP_BORDER_COLOR = '--clip-border-color';
export const CSS_CLIP_ZOOM_WIDTH = '--clip-width';
export const CSS_TIMELINE_INNER_WIDTH = '--timeline-inner-width';
export const CSS_EFFECTIVE_TIME_SEPARATOR = '--effective-time-separator';
export const CSS_EFFECTIVE_TIME_SEPARATOR_HALF_TIME = '--effective-time-separator-half-time';
export const CSS_EFFECTIVE_TIME_VISIBILITY = '--effective-time-visibility';
export const CSS_IS_HOME_TEAM_SELECTED_VISIBILITY = '--is-home-team-selected-visibility';
export const CSS_IS_OPPONENT_TEAM_SELECTED_VISIBILITY = '--is-opponent-team-selected-visibility';

type Props = {
  recordingId: string;
};

const timelineColors = {
  homeSelected: {
    homeTeam: Colors.bluePastel,
    homeTeamHover: '#2853A8',
    opponentTeam: Colors.cloud,
    opponentTeamHover: '#7F97C6',
  },
  opponentSelected: {
    homeTeam: Colors.fresco,
    homeTeamHover: '#96AEB1',
    opponentTeam: Colors.fountain,
    opponentTeamHover: '#399490',
  },
  anySelected: {
    filter: Colors.orange,
    filterHover: '#D18F12',
    episode: '#7A7E98',
    episodeHover: '#55586A',
    border: '#131332',
    tagsOffense: Colors.lavender,
    tagsOffenseHover: '#8E87C3',
    tagsDefensive: Colors.aqua,
    tagsDefensiveHover: '#48B7B3',
    tagsTransition: '#FACD74',
    tagsTransitionHover: '#CBA65E',
    tagsImported: '#C9CED6',
    tagsImportedHover: '#6F738C',
  },
};

export const TimelineCssVariables = ({ recordingId }: Props) => {
  const { zoomLevel } = useTimelineZoomLevel();
  const { isHomeTeamSelected, isOpponentTeamSelected } = useTimelineTeamIdFocus(recordingId);
  const { useEffectiveTime: effectiveTime } = useVideoPlayerPlayingMode();
  const timelineInnerWidth = useTimelineInnerWidth(recordingId);

  const stylesEffectiveTime = useMemo(() => {
    return {
      ['html']: {
        [CSS_EFFECTIVE_TIME_VISIBILITY]: effectiveTime ? 'none' : 'block',
        [CSS_EFFECTIVE_TIME_SEPARATOR]: effectiveTime ? `${SEPARATOR_WIDTH}px` : 'auto',
        [CSS_EFFECTIVE_TIME_SEPARATOR_HALF_TIME]: effectiveTime ? `${SEPARATOR_DOUBLE_WIDTH}px` : 'auto',
      },
    };
  }, [effectiveTime]);

  const stylesTeamColor = useMemo(() => {
    return {
      ['html']: {
        [CSS_IS_HOME_TEAM_SELECTED_VISIBILITY]: isHomeTeamSelected ? 'flex' : 'none',
        [CSS_IS_OPPONENT_TEAM_SELECTED_VISIBILITY]: isOpponentTeamSelected ? 'flex' : 'none',
        [CSS_HOME_TEAM_COLOR]: isHomeTeamSelected
          ? timelineColors.homeSelected.homeTeam
          : timelineColors.opponentSelected.homeTeam,
        [CSS_OPPONENT_TEAM_COLOR]: !isHomeTeamSelected
          ? timelineColors.opponentSelected.opponentTeam
          : timelineColors.homeSelected.opponentTeam,
        [CSS_HOME_TEAM_COLOR_HOVER]: isHomeTeamSelected
          ? timelineColors.homeSelected.homeTeamHover
          : timelineColors.opponentSelected.homeTeamHover,
        [CSS_OPPONENT_TEAM_COLOR_HOVER]: !isHomeTeamSelected
          ? timelineColors.opponentSelected.opponentTeamHover
          : timelineColors.homeSelected.opponentTeamHover,
        [CSS_FILTER_COLOR]: timelineColors.anySelected.filter,
        [CSS_FILTER_COLOR_HOVER]: timelineColors.anySelected.filterHover,
        [CSS_EPISODE_CLIP_COLOR]: timelineColors.anySelected.episode,
        [CSS_EPISODE_CLIP_COLOR_HOVER]: timelineColors.anySelected.episodeHover,
        [CSS_CLIP_BORDER_COLOR]: timelineColors.anySelected.border,
        [CSS_TAGS_OFFENSE_COLOR]: timelineColors.anySelected.tagsOffense,
        [CSS_TAGS_OFFENSE_COLOR_HOVER]: timelineColors.anySelected.tagsOffenseHover,
        [CSS_TAGS_DEFENSIVE_COLOR]: timelineColors.anySelected.tagsDefensive,
        [CSS_TAGS_DEFENSIVE_COLOR_HOVER]: timelineColors.anySelected.tagsDefensiveHover,
        [CSS_TAGS_TRANSITION_COLOR]: timelineColors.anySelected.tagsTransition,
        [CSS_TAGS_TRANSITION_COLOR_HOVER]: timelineColors.anySelected.tagsTransitionHover,
        [CSS_TAGS_IMPORTED_COLOR]: timelineColors.anySelected.tagsImported,
        [CSS_TAGS_IMPORTED_COLOR_HOVER]: timelineColors.anySelected.tagsImportedHover,
      },
    };
  }, [isHomeTeamSelected, isOpponentTeamSelected]);

  const stylesWidth = useMemo(() => {
    return {
      ['html']: {
        [CSS_TIMELINE_INNER_WIDTH]: `${timelineInnerWidth}px`,
        [CSS_CLIP_ZOOM_WIDTH]: `${getTimeZoomLevelAdjustment(zoomLevel)}px`,
      },
    };
  }, [timelineInnerWidth, zoomLevel]);

  return (
    <>
      <GlobalStyles styles={stylesTeamColor} />
      <GlobalStyles styles={stylesWidth} />
      <GlobalStyles styles={stylesEffectiveTime} />
    </>
  );
};
