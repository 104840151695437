import { Box, styled } from '@mui/material';
import React from 'react';

interface DialogContentWrapperProps {
  noHeader?: boolean;
}

interface Props extends DialogContentWrapperProps {
  children: React.ReactNode;
}

const DialogContentWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'noHeader',
})<DialogContentWrapperProps>(({ theme, noHeader }) => ({
  display: 'flex',
  flexGrow: 1,
  padding: `${noHeader ? theme.spacing(7) : '0'} ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
}));

export const DialogContent = ({ children, noHeader }: Props) => {
  return (
    <DialogContentWrapper noHeader={noHeader}>
      <Box flexGrow={1}>{children}</Box>
    </DialogContentWrapper>
  );
};
