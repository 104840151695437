import { useEffect, useState } from 'react';

export const useElementWidth = (ref: React.RefObject<HTMLElement>) => {
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    if (ref?.current !== null) {
      const observer = new ResizeObserver((entries) => {
        const { width } = entries[0].contentRect;
        setWidth(width);
      });

      const containerElement: HTMLElement = ref.current;
      observer.observe(containerElement);

      return () => {
        observer.unobserve(containerElement);
      };
    }
  }, [ref]);

  return width;
};
