import { Checkbox as MuiCheckbox, CheckboxProps, styled } from '@mui/material';

type CustomColors = 'white' | 'typography' | 'tertiaryLight' | 'quaternaryLight';

interface Props extends CheckboxProps {
  customColor: CustomColors;
}

export const CheckboxWithCustomColor = styled(MuiCheckbox, {
  shouldForwardProp: (prop) => prop !== 'customColor',
})<Props>(({ theme, customColor }) => ({
  ...(customColor === 'white' && {
    color: theme.palette.common.white,
    '&.Mui-checked': {
      color: theme.palette.common.white,
    },
    '&.MuiCheckbox-indeterminate': {
      color: theme.palette.common.white,
    },
  }),

  ...(customColor === 'typography' && {
    '&.Mui-checked': {
      color: theme.palette.text.primary,
    },
    '&.MuiCheckbox-indeterminate': {
      color: theme.palette.text.primary,
    },
  }),

  ...(customColor === 'tertiaryLight' && {
    color: theme.palette.tertiary.light,
    '&.Mui-checked': {
      color: theme.palette.tertiary.light,
    },
    '&.MuiCheckbox-indeterminate': {
      color: theme.palette.tertiary.light,
    },
  }),

  ...(customColor === 'quaternaryLight' && {
    color: theme.palette.quaternary.light,
    '&.Mui-checked': {
      color: theme.palette.quaternary.light,
    },
    '&.MuiCheckbox-indeterminate': {
      color: theme.palette.quaternary.light,
    },
  }),
}));
