import { Box, ClickAwayListener, Popper } from '@mui/material';
import { ChangeEvent, useCallback, useRef, useState } from 'react';

import { useDashboardRecentSearches } from 'entities/dashboard/api/use-dashboard-recent-searches/useDashboardRecentSearches';
import { useDashboardSearchResults } from 'entities/dashboard/api/useDashboardSearchResults';
import { MINIMAL_SEARCH_LENGTH } from 'entities/dashboard/constants/dashboardSearch';
import { useElementWidth } from 'shared/hooks/useElementWidth';
import { DashboardLatestSearchesWidget } from 'widgets/dashboard/dashboard-latest-searches/DashboardLatestSearches.widget';
import { DashboardSearchTextField } from 'widgets/dashboard/dashboard-search/ui/DashboardSearchTextField';
import { DashboardSearchResultsWidget } from 'widgets/dashboard/DashboardSearchResults.widget';

const DEFAULT_INPUT_WIDTH = 400;
const MIN_INPUT_WIDTH = 304;

interface Props {
  width?: number | `${number}${'px' | 'rem' | 'em' | '%' | 'vw'}` | 'auto';
  size?: 'medium' | 'small';
}

export const DashboardSearchWidget = ({ width, size }: Props) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [areResultsOpen, setAreResultsOpen] = useState<boolean>(false);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const searchInputContainerRef = useRef<HTMLDivElement>(null);
  const { hasRecentSearches } = useDashboardRecentSearches();
  const { setEnableSearch, options, setFilters, fetchNextPage, isPending, isLoading, totals } =
    useDashboardSearchResults();
  const searchInputWidth = useElementWidth(searchInputContainerRef);
  const isShowPastRequestsOptions = searchValue.length < MINIMAL_SEARCH_LENGTH;

  const onSearch = useCallback(
    (value: string) => {
      setFilters({ name: value });
      setEnableSearch(value.length >= MINIMAL_SEARCH_LENGTH);
    },
    [setEnableSearch, setFilters],
  );

  const handleCloseResults = useCallback(() => {
    setAreResultsOpen(false);
  }, []);

  const handleOpenResults = useCallback(() => {
    setAreResultsOpen(true);
  }, []);

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setAreResultsOpen(value.length >= MINIMAL_SEARCH_LENGTH ? true : hasRecentSearches);
    },
    [hasRecentSearches],
  );

  const handleSelect = useCallback(() => {
    setSearchValue('');
    handleCloseResults();
  }, [handleCloseResults]);

  return (
    <Box width={'100%'} maxWidth={width ?? DEFAULT_INPUT_WIDTH} minWidth={MIN_INPUT_WIDTH}>
      <DashboardSearchTextField
        containerRef={searchInputContainerRef}
        handleCloseResults={handleCloseResults}
        handleOpenResults={handleOpenResults}
        isLoading={isLoading}
        isOpen={areResultsOpen}
        onChange={onSearch}
        onInputChange={handleInputChange}
        ref={searchInputRef}
        searchResults={options}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        size={size}
      />

      <ClickAwayListener onClickAway={handleCloseResults}>
        <Popper open={areResultsOpen} anchorEl={searchInputRef.current} placement='bottom-start'>
          {isShowPastRequestsOptions ? (
            <DashboardLatestSearchesWidget
              onEscape={handleCloseResults}
              onSelect={handleSelect}
              width={searchInputWidth}
            />
          ) : (
            <DashboardSearchResultsWidget
              currentOptions={options}
              fetchNextPage={fetchNextPage}
              isLoading={isPending}
              onEscape={handleCloseResults}
              onSelect={handleSelect}
              totals={totals}
              width={searchInputWidth}
            />
          )}
        </Popper>
      </ClickAwayListener>
    </Box>
  );
};
