import { FormControl, Input, TextField, Typography } from '@mui/material';
import { Colors } from 'kognia-ui';
import React from 'react';
import { Path, UseFormRegister, UseFormTrigger, UseFormWatch, FieldErrors } from 'react-hook-form';

import { CoachFormFieldsNames } from 'pages/backoffice/pages/coaches/components/coach-form/types';
import { FormFormLabel } from 'shared/components/form/form-form-label';

interface Props<TFormValues extends Record<string, any>> {
  register: UseFormRegister<TFormValues>;
  errors: FieldErrors<TFormValues>;
  watch: UseFormWatch<TFormValues>;
  trigger: UseFormTrigger<TFormValues>;
}

export function CoachesForm<TFormValues extends Record<string, any>>({
  register,
  errors,
  trigger,
}: Props<TFormValues>) {
  return (
    <>
      <FormControl fullWidth margin='normal'>
        <FormFormLabel>Coach Name</FormFormLabel>
        <TextField
          size='small'
          {...register(CoachFormFieldsNames.name as Path<TFormValues>)}
          error={!!errors[CoachFormFieldsNames.name]}
          helperText={errors[CoachFormFieldsNames.name] && <>{errors[CoachFormFieldsNames.name].message}</>}
        />
      </FormControl>
      <FormControl
        fullWidth
        margin='normal'
        onChange={() => trigger(CoachFormFieldsNames.coachImage as Path<TFormValues>)}
      >
        <FormFormLabel>Image URL</FormFormLabel>
        <Input
          type='file'
          size='small'
          {...register(CoachFormFieldsNames.coachImage as Path<TFormValues>)}
          error={!!errors[CoachFormFieldsNames.coachImage]}
        />
        {errors[CoachFormFieldsNames.coachImage] && (
          <Typography color={Colors.red}>
            <>{errors[CoachFormFieldsNames.coachImage].message ?? ''}</>
          </Typography>
        )}
      </FormControl>
    </>
  );
}
