import { Box, styled, Typography } from '@mui/material';
import { Colors } from 'kognia-ui';

export enum KeypadEditKeypadActionsVariant {
  TOP = 'top',
  BOTTOM = 'bottom',
}

interface KeypadEditKeypadActionsProps {
  variant: KeypadEditKeypadActionsVariant;
}

export const KeypadEditSubTitle = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(3),
  textAlign: 'center',
}));

export const KeypadEditKeypadContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(4),
  gap: theme.spacing(2),
}));

export const KeypadEditKeypadFault = styled(Box)(({ theme }) => ({
  flex: '1',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export const KeypadEditOffenseBottom = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(4),
  gap: theme.spacing(2),
}));

export const KeypadEditKeypadActions = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'variant',
})<KeypadEditKeypadActionsProps>(({ theme, variant }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  ...(variant === KeypadEditKeypadActionsVariant.TOP && {
    marginTop: theme.spacing(2),
  }),
  ...(variant === KeypadEditKeypadActionsVariant.BOTTOM && {
    marginBottom: theme.spacing(2),
  }),
}));

export const KeypadEditKeypadMiddle = styled(Box)(({ theme }) => ({
  flex: '1',
  background: Colors.iron,
  border: `1px solid ${Colors.storm}`,
  position: 'relative',
  padding: theme.spacing(1, 0),
}));

export const KeypadEditKeypadHContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
}));

export const KeypadEditKeypadVContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));
