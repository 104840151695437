import isEqual from 'lodash/isEqual';
import { useCallback } from 'react';
import { NavigateOptions, useSearchParams } from 'react-router-dom';

import { FiltersFromUrl, FiltersList } from 'shared/types';
import { entriesFromObject, keysFromObject } from 'shared/utils/objectToArray';

type TransformFiltersArgs<T> = {
  filters: T;
  defaultFiltersData?: Partial<FiltersFromUrl<T>>;
  overwrittenKeys?: Partial<FiltersFromUrl<T>>;
  options?: NavigateOptions;
};

const sortSearchParams = <T extends FiltersList>(searchParams: Partial<FiltersFromUrl<T>>) => {
  return Object.entries(searchParams)
    .sort(([keyA], [keyB]) => {
      return keyA.localeCompare(keyB);
    })
    .reduce<Record<string, string>>((acc, [key, value]) => {
      if (value) {
        acc[key] = value;
      }

      return acc;
    }, {});
};

const convertUrlStringToObject = (url: string): Record<string, string> => {
  const urlParams = new URLSearchParams(url);
  return Object.fromEntries(urlParams.entries());
};

const createDataForRequest = <T extends FiltersList>({
  filters,
  defaultFiltersData = {},
  overwrittenKeys = {},
}: TransformFiltersArgs<T>) => {
  const searchParamsData: Partial<FiltersFromUrl<T>> = {};

  const filtersData = entriesFromObject(filters).reduce<Partial<FiltersFromUrl<T>>>((acc, [key, value]) => {
    const overwrittenKey = overwrittenKeys[key];

    const filter = Object.keys(value.options)
      .filter((optionKey) => value.options[optionKey].isApplied)
      .join(',');

    if (filter) {
      if (overwrittenKey) {
        acc[overwrittenKey] = filter;
      } else {
        acc[key] = filter;
      }

      searchParamsData[key] = filter;
    } else if (!filter && defaultFiltersData[key]) {
      if (overwrittenKey) {
        acc[overwrittenKey] = defaultFiltersData[key];
      } else {
        acc[key] = defaultFiltersData[key];
      }

      searchParamsData[key] = defaultFiltersData[key];
    }

    return acc;
  }, {});

  return { searchParamsData, filtersData };
};

export const useFiltersSearchParams = <T extends FiltersList>(filters: T) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getFilters = useCallback((): Partial<FiltersFromUrl<T>> => {
    return keysFromObject(filters).reduce<Partial<FiltersFromUrl<T>>>((acc, key) => {
      const filter = typeof key === 'string' ? searchParams.get(key) : null;
      if (filter !== null) {
        acc[key] = filter;
      }

      return acc;
    }, {});
  }, [filters, searchParams]);

  const getUrlString = useCallback((): string => {
    return decodeURIComponent(searchParams.toString());
  }, [searchParams]);

  const sortAndSetSearchParams = useCallback(
    <K extends T>(searchParams: Partial<FiltersFromUrl<K>>, options?: NavigateOptions): void => {
      const prevParams = getFilters();
      const newParams = sortSearchParams(searchParams);

      if (!isEqual(prevParams, newParams)) {
        setSearchParams(newParams, options);
      }
    },
    [getFilters, setSearchParams],
  );

  const transformFiltersForRequestAndSetSearchParams = useCallback(
    <K extends T>({
      filters,
      defaultFiltersData = {},
      overwrittenKeys = {},
      options,
    }: TransformFiltersArgs<K>): Partial<FiltersFromUrl<K>> => {
      const { filtersData, searchParamsData } = createDataForRequest({
        filters,
        defaultFiltersData,
        overwrittenKeys,
      });

      sortAndSetSearchParams(searchParamsData, options);

      return filtersData;
    },
    [sortAndSetSearchParams],
  );

  return {
    getFilters,
    getUrlString,
    convertUrlStringToObject,
    transformFiltersForRequestAndSetSearchParams,
  };
};
