import { useAtom } from 'jotai';
import Cookies from 'js-cookie';
import { useCallback } from 'react';

import { currentClientIdAtom } from 'shared/contexts/app-state/atoms';

export function useClientId() {
  const [clientId, updateClientId] = useAtom(currentClientIdAtom);

  const setClientId = useCallback(
    (newClientId: string) => {
      Cookies.set('current-client-id', newClientId, { expires: 365 });
      updateClientId(newClientId);
    },
    [updateClientId],
  );

  return {
    clientId,
    setClientId,
  };
}
