import { OverlayGeneratorChunkData, OverlayGeneratorStore } from '../index';

const CHUNKS_QUANTITY_TO_STORE = 10;

const getChunksToKeep = (data: OverlayGeneratorChunkData, currentChunkNumber: number): OverlayGeneratorChunkData => {
  const nextChunkNumber = currentChunkNumber + 1;
  const chunksToKeep = Object.entries(data).filter(
    ([key]) => Number(key) === currentChunkNumber || Number(key) === nextChunkNumber,
  );
  const chunksQuantityToRemove = CHUNKS_QUANTITY_TO_STORE - chunksToKeep.length;
  const chunksCleaned = Object.entries(data)
    .filter(([key]) => Number(key) !== currentChunkNumber && Number(key) !== nextChunkNumber)
    .slice(-chunksQuantityToRemove);

  const chunksToStore = [...chunksToKeep, ...chunksCleaned];

  return { ...chunksToStore.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}) };
};

export const validateChunkData = (state: OverlayGeneratorStore, currentChunkNumber: number) => {
  const chunksToKeep = getChunksToKeep(state.chunkData, currentChunkNumber);

  return { ...state, data: chunksToKeep };
};
