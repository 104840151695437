import { Box, styled } from '@mui/material';

interface CoachImageProps {
  fullSize?: boolean;
}

export const CoachImageContainer = styled(Box)(() => ({
  borderRadius: '50%',
  overflow: 'hidden',
  height: '100%',
  width: '100%',
}));

export const CoachImageElement = styled('img', { shouldForwardProp: (prop) => prop !== 'fullSize' })<CoachImageProps>(
  ({ fullSize }) => ({
    maxHeight: fullSize ? '100%' : '36px',
    maxWidth: fullSize ? '100%' : '36px',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }),
);
