import { useQuery } from '@tanstack/react-query';

import { generatePlaylistItemFromEpisodes } from 'pages/tactical-analysis/components/tactical-analysis/utils/generate-timeline-playlist';
import {
  DEFAULT_TACTICAL_CAMERA_PLAYING_MODE,
  EPISODES_PLAYING_MODE,
  TACTICAL_CAMERA_WITH_OVERLAYS_PLAYING_MODE,
} from 'shared/components/video-player/defaultPlayingModes';
import { PlayingMode, PlayingModes, PlaylistItemType } from 'shared/components/video-player/types';
import { MatchWithEpisodes } from 'shared/types/match';
import { TacticIdOrAll } from 'shared/types/playlist/types';

interface Params {
  recordingId: string;
  matchWithEpisodes: MatchWithEpisodes | undefined;
  selectedTactics: TacticIdOrAll[];
  selectedPlayingMode: PlayingMode | undefined;
  areTimelineOverlaysEnabled: boolean;
}

const getValidPlayingMode = (
  playingMode: PlayingMode | undefined,
  hasHomographies: boolean,
  hasEpisodesSources: boolean,
) => {
  // Validate playing mode preset. EPISODE mode is not valid for match with hasHomographies
  if (!playingMode) return;
  if (playingMode.mode === PlayingModes.EPISODES && hasHomographies) {
    return TACTICAL_CAMERA_WITH_OVERLAYS_PLAYING_MODE;
  }

  if (!hasHomographies && hasEpisodesSources && (playingMode.showOverlays || playingMode.useEffectiveTime)) {
    return EPISODES_PLAYING_MODE;
  }

  return playingMode;
};

export const generateTacticalAnalysisPlaylistItems = ({
  recordingId,
  matchWithEpisodes,
  selectedTactics,
  selectedPlayingMode,
  areTimelineOverlaysEnabled,
}: Params) => {
  return new Promise<PlaylistItemType[]>((resolve) => {
    const recordingEpisodes = matchWithEpisodes?.episodes;
    const recordingMatch = matchWithEpisodes?.match;
    const defaultVideoSource = matchWithEpisodes?.match.defaultVideoSource;
    const duration = matchWithEpisodes?.match.defaultVideoSource.duration;

    if (!recordingMatch || !defaultVideoSource || !recordingEpisodes) return resolve([]);

    const hasEpisodes = recordingEpisodes && recordingEpisodes.length > 0;
    const hasEpisodesSources = hasEpisodes && recordingEpisodes.some((item) => !!item.dashUrl);

    const shouldUseEpisodesMode = hasEpisodesSources && !recordingMatch.hasHomographies;
    const shouldUseTacticalCameraModeWithEpisodes = hasEpisodes && recordingMatch.hasHomographies;

    const playingMode: PlayingMode = areTimelineOverlaysEnabled
      ? {
          ...DEFAULT_TACTICAL_CAMERA_PLAYING_MODE,
          ...(shouldUseEpisodesMode && EPISODES_PLAYING_MODE),
          ...(shouldUseTacticalCameraModeWithEpisodes && TACTICAL_CAMERA_WITH_OVERLAYS_PLAYING_MODE),
        }
      : {
          ...DEFAULT_TACTICAL_CAMERA_PLAYING_MODE,
          ...(shouldUseEpisodesMode && EPISODES_PLAYING_MODE),
        };

    const hasHomographies =
      areTimelineOverlaysEnabled && recordingMatch.hasHomographies !== undefined
        ? recordingMatch?.hasHomographies
        : false;

    const validateSelectedPlayingMode = getValidPlayingMode(selectedPlayingMode, hasHomographies, hasEpisodesSources);

    resolve(
      generatePlaylistItemFromEpisodes({
        episodes: recordingEpisodes,
        fullVideoSourceDuration: duration,
        tacticalCameraVideo: defaultVideoSource,
        playingMode: validateSelectedPlayingMode || playingMode,
        recordingId: recordingId,
        fundamentalsSelected: selectedTactics,
        hasHomographies,
        showOverlays: recordingMatch.showOverlays,
      }),
    );
  });
};

export const useGenerateTacticalAnalysisPlaylistItems = (params: Params) => {
  return useQuery({
    queryKey: [`generate-playlist-tactical-analysis-${params.recordingId}`],
    queryFn: () => generateTacticalAnalysisPlaylistItems(params),
    enabled: params.matchWithEpisodes !== undefined,
  });
};
