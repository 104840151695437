import { ResponsiveLineCanvas } from '@nivo/line';
import { Colors } from 'kognia-ui';
import React, { useEffect, useMemo, useState } from 'react';

import { useRenderFrameRate } from 'shared/components/video-player/hooks/use-overlays-controller';

const timeRange = 60; // Time range in seconds
export const FrameRateChart = () => {
  const frameRate = useRenderFrameRate();
  const [data, setData] = useState<number[]>(Array(timeRange).fill(0));

  useEffect(() => {
    setData((prevData) => {
      const newData = [...prevData, frameRate];
      if (newData.length > timeRange) {
        newData.shift(); // Remove the oldest data point if there are more than 30 data points
      }
      return newData;
    });
  }, [frameRate]);

  const chartData = useMemo(
    () => ({
      id: 'Frame Rate',
      color: Colors.lavender,
      data: data.map((value, index) => ({
        x: index,
        y: value > 60 ? 60 : value,
      })),
    }),
    [data],
  );

  return (
    <div style={{ height: '200px', width: '100%', display: 'flex' }}>
      <ResponsiveLineCanvas
        data={[chartData]}
        enableArea={true}
        areaOpacity={1}
        colors={[Colors.lavender]}
        margin={{ top: 20, right: 10, bottom: 30, left: 40 }}
        xScale={{ type: 'linear', min: 0, max: timeRange }}
        yScale={{
          type: 'linear',
          min: 0,
          max: 60,
        }}
        yFormat=' >-.2f'
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
          tickValues: [0, 10, 20, 30, 40, 50, 60],
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          truncateTickAt: 0,
          tickValues: [0, 10, 20, 30, 40, 50, 60],
        }}
        pointSize={5}
        isInteractive={false}
        pointColor={['white']}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
      />
    </div>
  );
};
