import { UseQueryResult } from '@tanstack/react-query';

import { queryClient } from 'api/config';
import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { transformPlaylist } from 'api/playlist/transformers';
import { PlaylistApiResponse } from 'api/playlist/types';
import { playlistsUrl, recordingPlaylistsUrl } from 'api/routes';
import { Playlist } from 'shared/types';

const generateQueryRef = (includeEmpty: boolean, recordingId?: string) => [
  'fetchPlaylists',
  `fetchPlaylists-recordingId:${recordingId || 'all'}:includeEmpty:${includeEmpty ? 'true' : 'false'}`,
];

interface useFetchRecordingsPlaylistsInterface {
  (recordingId?: string, includeEmpty?: boolean): UseQueryResult<Playlist[]> & { invalidateQuery: () => Promise<void> };
}

export const useFetchRecordingPlaylists: useFetchRecordingsPlaylistsInterface = (recordingId, includeEmpty = true) => {
  const onSuccess = (data: PlaylistApiResponse[]) => data.map((playlist) => transformPlaylist(playlist));
  const fetchQueryRef = generateQueryRef(includeEmpty, recordingId);
  const invalidateQuery = () => queryClient.invalidateQueries({ queryKey: fetchQueryRef });
  const fetchRequest = useFetchRequest<Playlist[]>({
    queryRef: fetchQueryRef,
    url: recordingId ? recordingPlaylistsUrl(recordingId, includeEmpty) : playlistsUrl,
    options: { retry: false, refetchOnWindowFocus: true },
    transformer: onSuccess,
  });

  return { ...fetchRequest, invalidateQuery };
};
