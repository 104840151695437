import { atom, useAtom } from 'jotai';
import { useCallback } from 'react';

const sidebarOpenState = atom(true);

export const useBackofficeSidebar = () => {
  const [isBackofficeSidebarOpen, setIsBackofficeSidebarOpen] = useAtom(sidebarOpenState);

  const toggleBackofficeSidebar = useCallback(
    () => setIsBackofficeSidebarOpen(!isBackofficeSidebarOpen),
    [setIsBackofficeSidebarOpen, isBackofficeSidebarOpen],
  );

  return {
    isBackofficeSidebarOpen,
    toggleBackofficeSidebar,
  };
};
