import { ProcessFixturePreset } from 'pages/backoffice/entities/process-fixture/types/processFixture.types';
import {
  ProcessFixtureEventsProvider,
  ProcessFixtureFieldsNames,
  ProcessFixturePipelineType,
  ProcessFixtureTrackingProvider,
} from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/types/processFixtureForm.types';
import { SportlogiqTrackingType } from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/types/processFixtureFormSubforms.types';

const processFixtureDefaultValues = {
  [ProcessFixtureFieldsNames.bucket]: 'kognia-client-uploads',
  [ProcessFixtureFieldsNames.runProcessing]: true,
  [ProcessFixtureFieldsNames.restartProcessing]: false,
};

export const PRESETS_FORMS_DEFAULTS = {
  [ProcessFixturePreset.ADVANCED]: processFixtureDefaultValues,
  [ProcessFixturePreset.PERFORMANCE]: {
    ...processFixtureDefaultValues,
    [ProcessFixtureFieldsNames.showOverlays]: true,
    [ProcessFixtureFieldsNames.autoRunTacticalAnalysis]: false,
    [ProcessFixtureFieldsNames.sportlogiqTrackingType]: SportlogiqTrackingType.RAW_FIXTURE_TRACKING_DATA,
  },
  [ProcessFixturePreset.SCOUTING]: {
    ...processFixtureDefaultValues,
    [ProcessFixtureFieldsNames.pipelineType]: ProcessFixturePipelineType.THIRD_PARTY_TACTICAL_TRACKING,
    [ProcessFixtureFieldsNames.trackingProvider]: ProcessFixtureTrackingProvider.SPORTLOGIQ,
    [ProcessFixtureFieldsNames.eventsProvider]: ProcessFixtureEventsProvider.OPTA,
    [ProcessFixtureFieldsNames.showOverlays]: false,
    [ProcessFixtureFieldsNames.autoRunTacticalAnalysis]: true,
    [ProcessFixtureFieldsNames.sportlogiqTrackingType]: SportlogiqTrackingType.FIXTURE_TRACKING_DATA,
  },
};
