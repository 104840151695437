import { Stack } from '@mui/material';
import isArray from 'lodash/isArray';
import { useTranslation } from 'react-i18next';

import { KeyboardKey } from 'shared/components/keyboard-shortcuts/components/keyboard-key';
import { KeyboardKeyOptions } from 'shared/components/keyboard-shortcuts/types/keyboard-key-options';

interface Props {
  keys: KeyboardKeyOptions[][];
}

const SingleShortcut = ({ keys }: { keys: KeyboardKeyOptions[] }) => {
  const shortcut = keys.map((key, index) => {
    if (index === keys.length - 1) {
      return <KeyboardKey key={`${key}_${index}`} keyItem={key} />;
    }

    return (
      <Stack key={`${key}_${index}`} direction='row' alignItems='center' spacing={1} justifyContent={'center'}>
        <KeyboardKey keyItem={key} />
        <span>+</span>
      </Stack>
    );
  });

  return <>{shortcut}</>;
};

export const KeyboardShortcut = ({ keys }: Props) => {
  const { t } = useTranslation();
  if (!isArray(keys[0]) && keys.length === 1) {
    if (keys.length === 1) {
      return <KeyboardKey keyItem={keys[0]} />;
    }

    return <SingleShortcut keys={keys.flat()} />;
  }

  if (keys.length === 1) {
    return <SingleShortcut keys={keys[0]} />;
  }

  const keysGroups = keys.map((keyGroup, index) => {
    const isLast = index === keys.length - 1;

    return (
      <Stack key={`${keyGroup}_${index}`} direction='row' alignItems='center' spacing={1} justifyContent={'center'}>
        <SingleShortcut keys={keyGroup} />
        {!isLast ? <span>{t('common:keyboard-shortcuts.or')}</span> : null}
      </Stack>
    );
  });

  return <>{keysGroups}</>;
};
