import { useTheme } from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { queryClient } from 'api/config';
import {
  DEFAULT_DOWNLOAD_REQUESTS_QUERY_KEY,
  useFetchDownloadRequests,
} from 'api/download-manager/use-fetch-download-requests/useFetchDownloadRequests';
import { useUpdateDownloadRequests } from 'api/download-manager/use-update-download-requests/useUpdateDownloadRequests';
import { previousAmountNotViewedRecordings } from 'entities/download-manager/store/previousAmountNotViewedRecordings';
import { sidebarDrawerElement } from 'entities/download-manager/store/sidebarDrawerElement';
import { NotViewedRecordingsData } from 'features/download-manager/sidebar-menu-item-download-manager/types/notViewedRecordingsData';
import { DownloadManagerPopper } from 'features/download-manager/sidebar-menu-item-download-manager/ui/DownloadManagerPopper';
import { routes } from 'kognia/router/routes';
import { CustomSidebarItemLinkComponentProps } from 'shared/components/sidebar-layout/sidebar-menu';
import { SidebarMenuItem } from 'shared/components/sidebar-layout/sidebar-menu/sidebar-menu-item';
import { shiftSidebarWidth } from 'shared/components/sidebar-layout/SideBarLayout.styled';
import {
  NOTIFICATION_DOWNLOAD_REQUESTS_QUERY_KEY,
  PAGE_DOWNLOAD_REQUESTS_QUERY_KEY,
} from 'shared/constants/download-manager/downloadManagerQueryKeys';
import { useValueRef } from 'shared/hooks/use-value-ref/useValueRef';

const REFETCH_INTERVAL = 60_000;
const PAGE_SIZE = 1_000;

export const SidebarMenuItemDownloadManagerFeature = ({
  item,
  onClick,
  isSidebarOpen,
}: CustomSidebarItemLinkComponentProps) => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const popperRef = useRef<HTMLDivElement>(null);
  const menuButtonRef = useRef<HTMLDivElement>(null);
  const sidebarDrawer = useAtomValue(sidebarDrawerElement);
  const isFirstDownloadManagerPage =
    (pathname === routes.DOWNLOAD_MANAGER && searchParams.get('page') === '0') ||
    (pathname === routes.DOWNLOAD_MANAGER && searchParams.get('page') === null);
  const isSidebarOpenRef = useValueRef(isSidebarOpen);
  const [anchorEl, setAnchorEl] = useState<{ isOpen: boolean; element: HTMLDivElement | null } | null>(null);
  const open = Boolean(anchorEl?.element);
  const [previousAmountOfNotViewedRecordings, setPreviousAmountOfNotViewedRecordings] = useAtom(
    previousAmountNotViewedRecordings,
  );

  const { data } = useFetchDownloadRequests({
    queryParams: {
      size: PAGE_SIZE,
      viewed: false,
    },
    options: { gcTime: REFETCH_INTERVAL, refetchInterval: REFETCH_INTERVAL },
    queryRef: NOTIFICATION_DOWNLOAD_REQUESTS_QUERY_KEY,
    onSuccess: (data) => {
      if (
        (previousAmountOfNotViewedRecordings !== null &&
          data.totalNotViewedElements !== previousAmountOfNotViewedRecordings) ||
        (previousAmountOfNotViewedRecordings === null && data.totalNotViewedElements)
      ) {
        queryClient.invalidateQueries({ queryKey: [PAGE_DOWNLOAD_REQUESTS_QUERY_KEY] });
      }
    },
  });
  const { updateDownloadRequests } = useUpdateDownloadRequests();

  const amountOfNotViewedRecordings = useMemo(() => data?.totalNotViewedElements ?? 0, [data?.totalNotViewedElements]);
  const notViewedRecordingsData = useMemo(() => {
    const initialNotViewedCounts = { failed: 0, processed: 0 };

    data?.downloadRequests.content.reduce<NotViewedRecordingsData>((acc, { viewed, status }) => {
      if (!viewed) {
        if (status === 'processed') {
          acc.processed += 1;
        } else if (status === 'error') {
          acc.failed += 1;
        }
      }

      return acc;
    }, initialNotViewedCounts);

    return initialNotViewedCounts;
  }, [data]);

  useEffect(() => {
    if (
      amountOfNotViewedRecordings &&
      previousAmountOfNotViewedRecordings !== amountOfNotViewedRecordings &&
      !isFirstDownloadManagerPage
    ) {
      setAnchorEl({ isOpen: isSidebarOpenRef.current, element: menuButtonRef.current });
    } else {
      setAnchorEl(null);
    }
  }, [amountOfNotViewedRecordings, isFirstDownloadManagerPage, isSidebarOpenRef, previousAmountOfNotViewedRecordings]);

  useEffect(() => {
    const handleStartPopperTransition = (event: TransitionEvent) => {
      if (event.propertyName === 'width' && popperRef.current) {
        const translateValuesRegExp = popperRef.current.style.transform.match(/translate.*\((.+)\)/);

        if (translateValuesRegExp) {
          const [translateX, ...restTranslateValuesArray] = translateValuesRegExp[1].split(', ');
          const restTranslateValues = restTranslateValuesArray.join(', ');
          const translateXValue = parseFloat(translateX);

          popperRef.current.style.transition = theme.transitions.create('transform', {
            duration: isSidebarOpen
              ? theme.transitions.duration.enteringScreen
              : theme.transitions.duration.leavingScreen,
            easing: theme.transitions.easing.sharp,
          });

          popperRef.current.style.transform = isSidebarOpen
            ? `translate(${translateXValue + shiftSidebarWidth}px, ${restTranslateValues})`
            : `translate(${translateXValue - shiftSidebarWidth}px, ${restTranslateValues})`;
        }
      }
    };

    const handleEndPopperTransition = (event: TransitionEvent) => {
      if (event.propertyName === 'width' && popperRef.current) {
        popperRef.current.style.transition = 'none';

        setAnchorEl((prev) => {
          return prev === null ? null : { ...prev, isOpen: isSidebarOpen };
        });
      }
    };

    if (sidebarDrawer) {
      sidebarDrawer.addEventListener('transitionstart', handleStartPopperTransition);
      sidebarDrawer.addEventListener('transitionend', handleEndPopperTransition);

      return () => {
        sidebarDrawer.removeEventListener('transitionstart', handleStartPopperTransition);
        sidebarDrawer.removeEventListener('transitionend', handleEndPopperTransition);
      };
    }
  }, [isSidebarOpen, sidebarDrawer, theme]);

  const handleCloseWithSavePreviousNotViewedData = useCallback(() => {
    setAnchorEl(null);
    setPreviousAmountOfNotViewedRecordings(amountOfNotViewedRecordings);
  }, [amountOfNotViewedRecordings, setPreviousAmountOfNotViewedRecordings]);

  const handleOnClick = useCallback(() => {
    setAnchorEl(null);
    onClick();
    setPreviousAmountOfNotViewedRecordings(amountOfNotViewedRecordings);
  }, [amountOfNotViewedRecordings, onClick, setPreviousAmountOfNotViewedRecordings]);

  const handleCloseWithDownloadRequest = useCallback(() => {
    updateDownloadRequests([DEFAULT_DOWNLOAD_REQUESTS_QUERY_KEY]);
    setAnchorEl(null);
    setPreviousAmountOfNotViewedRecordings(null);
  }, [setPreviousAmountOfNotViewedRecordings, updateDownloadRequests]);

  return (
    <>
      <SidebarMenuItem
        item={item}
        isSidebarOpen={isSidebarOpen}
        onClick={handleOnClick}
        ref={menuButtonRef}
        badge={{
          badgeContent: amountOfNotViewedRecordings,
          invisibleWhenClosedSidebar: open || isFirstDownloadManagerPage,
          invisibleWhenOpenedSidebar: open || isFirstDownloadManagerPage,
          color: 'info',
        }}
      />

      <DownloadManagerPopper
        ref={popperRef}
        anchorEl={anchorEl?.element ?? null}
        open={open}
        notViewedRecordingsData={notViewedRecordingsData}
        data={amountOfNotViewedRecordings === 1 ? data?.downloadRequests.content.find((el) => !el.viewed) : null}
        amountOfNotViewedRecordings={amountOfNotViewedRecordings}
        onCloseWithDownloadRequest={handleCloseWithDownloadRequest}
        onCloseWithSavePreviousNotViewedData={handleCloseWithSavePreviousNotViewedData}
      />
    </>
  );
};
