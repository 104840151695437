import { useQueryClient } from '@tanstack/react-query';

import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { getVenuesUrl } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { transformVenues } from 'pages/backoffice/api/venues/use-venues/transformer';
import { Venue, VenuesApiResponse, VenuesFilters } from 'pages/backoffice/api/venues/use-venues/types';
import { SortDirection } from 'shared/types/filters/types';

const QUERY_KEY = 'venues';

export const useVenues = (): InfinityQueryResult<Venue, VenuesFilters> => {
  const { isAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();

  return useInfinityQuery<Venue, VenuesFilters, VenuesApiResponse>({
    generateUrl: getVenuesUrl,
    key: QUERY_KEY,
    options: {
      size: 10,
      sort: 'name',
      sortDirection: SortDirection.DESC,
    },
    transformer: transformVenues,
    queryOptions: {
      enabled: isAllowedBackofficeOrigin,
    },
  });
};

export const useInvalidateVenues = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
};
