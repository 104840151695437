import isEmpty from 'lodash/isEmpty';
import { useCallback } from 'react';

import { ProcessFixtureParamsData, useProcessFixture } from 'pages/backoffice/api/operations/use-process-fixture';
import {
  ProcessFixtureFieldsNames,
  ProcessFixtureForm,
} from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/types/processFixtureForm.types';
import { isEventsFile } from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/utils/is-event-file';
import { isTrackingFile } from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/utils/is-tracking-file';

type Options = {
  onSubmit: () => void;
};
export const useHandleProcessFixtureSubmit = ({ onSubmit }: Options) => {
  const { processFixture } = useProcessFixture();

  return useCallback(
    (formData: ProcessFixtureForm) => {
      const {
        [ProcessFixtureFieldsNames.recordingId]: recordingId,
        [ProcessFixtureFieldsNames.videoPath]: videoPath,
        [ProcessFixtureFieldsNames.bucket]: bucket,
        [ProcessFixtureFieldsNames.pipelineType]: pipelineType,
        [ProcessFixtureFieldsNames.trackingProvider]: trackingProvider,
        [ProcessFixtureFieldsNames.eventsProvider]: eventsProvider,
        [ProcessFixtureFieldsNames.runProcessing]: runProcessing,
        [ProcessFixtureFieldsNames.restartProcessing]: restartProcessing,
        [ProcessFixtureFieldsNames.showOverlays]: showOverlays,
        [ProcessFixtureFieldsNames.autoRunTacticalAnalysis]: autoRunTacticalAnalysis,
        [ProcessFixtureFieldsNames.sportlogiqTrackingType]: sportlogiqTrackingType,
        ...restData
      } = formData;

      onSubmit();
      const filesNonEmptyData = Object.entries(restData).reduce(
        (acc, [key, value]) => {
          if (!isEmpty(value)) {
            if (isTrackingFile(key)) {
              return { ...acc, tracking: { ...acc.tracking, [key]: value } };
            } else if (isEventsFile(key)) {
              return { ...acc, events: { ...acc.events, [key]: value } };
            }
          }

          return acc;
        },
        { tracking: {}, events: {} },
      );

      const data: ProcessFixtureParamsData = {
        recording_id: recordingId,
        video_path: videoPath,
        bucket,
        pipeline_type: pipelineType,
        settings: {
          providers: {
            video: 'third-party-tactical',
            tracking: trackingProvider,
            events: eventsProvider,
            ...(sportlogiqTrackingType && {
              options: {
                sportlogiq_tracking_type: sportlogiqTrackingType,
              },
            }),
          },
          run_processing: runProcessing,
          postprocess: {
            show_overlays: showOverlays,
            auto_run_tactical_analysis: autoRunTacticalAnalysis,
          },
        },
        restart_processing: restartProcessing,
        ...(!isEmpty(filesNonEmptyData.tracking) && {
          tracking_files: filesNonEmptyData.tracking,
        }),
        ...(!isEmpty(filesNonEmptyData.events) && {
          events_files: filesNonEmptyData.events,
        }),
      };

      processFixture({ data });
    },
    [processFixture, onSubmit],
  );
};
