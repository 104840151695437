import { List } from '@mui/material';

import { SeasonCompetitionItem } from 'pages/backoffice/pages/season-competitions/components/season-competition-item';
import { Season } from 'pages/backoffice/types/seasons';

interface Props {
  seasons: Season[];
}

export const SeasonCompetitionsList = ({ seasons }: Props) => {
  const seasonsItems = seasons.map((season) => {
    return <SeasonCompetitionItem key={season.id} season={season} />;
  });

  return <List>{seasonsItems}</List>;
};
