import { z } from 'zod';

import { Pagination } from 'shared/types/pagination/types';

export interface VenueApi {
  uuid: string;
  name: string;
  city: string;
  country_code: string;
}

export type VenuesFilters = {
  name?: string;
  sort?: string;
  sortDirection?: string;
};

export interface VenuesApiResponse {
  data: VenueApi[];
  page: Pagination;
}

export const VenueSchema = z.object({
  id: z.string(),
  name: z.string(),
  city: z.string(),
  countryCode: z.string(),
});

export type Venue = z.TypeOf<typeof VenueSchema>;
