import { FieldErrors, UseFormRegister } from 'react-hook-form';

import {
  ProcessFixtureFieldsNames,
  processFixtureFormSchema,
} from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/types/processFixtureForm.types';
import { SportlogiqTrackingType } from 'pages/backoffice/widgets/process-fixture/ProcessFixtureForm/types/processFixtureFormSubforms.types';
import { FormSelectField } from 'shared/components/form/form-select-field';
import { getTypeOptions } from 'shared/components/form/get-type-options';

interface Props {
  register: UseFormRegister<processFixtureFormSchema>;
  errors: FieldErrors<processFixtureFormSchema>;
  defaultValue?: SportlogiqTrackingType;
}

export function SportlogiqForm({ errors, register, defaultValue }: Props) {
  return (
    <FormSelectField
      id={ProcessFixtureFieldsNames.sportlogiqTrackingType}
      label='Sportlogiq tracking type'
      error={!!errors[ProcessFixtureFieldsNames.sportlogiqTrackingType]}
      disabled={Boolean(defaultValue)}
      defaultValue={defaultValue}
      fullWidth
      options={getTypeOptions({
        options: Object.values(SportlogiqTrackingType).map((value) => ({
          value,
        })),
      })}
      helperText={
        errors[ProcessFixtureFieldsNames.sportlogiqTrackingType] && (
          <>{errors[ProcessFixtureFieldsNames.sportlogiqTrackingType]?.message}</>
        )
      }
      {...register(ProcessFixtureFieldsNames.sportlogiqTrackingType)}
    />
  );
}
