import { Box, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const NoDownloadRequestsData = () => {
  const { t } = useTranslation();

  return (
    <Box flexGrow={1} display='flex' flexDirection='column' gap={1.5} pt={1}>
      <Divider sx={{ borderColor: 'secondary.light' }} />
      <Typography variant='body2' color='secondary' fontWeight='fontWeightMedium'>
        {t('download-manager:no-data')}
      </Typography>
    </Box>
  );
};
