import React from 'react';

import RecordingsList from 'pages/recordings-list/components/recordings-list';
import { Recording } from 'shared/types/recording/types';

interface Props {
  isLoading: boolean;
  recordings?: Array<Recording>;
}

export const RecordingListWithFilters = ({ recordings, isLoading }: Props) => {
  return <RecordingsList isLoading={isLoading} recordings={recordings ? recordings : []} />;
};
