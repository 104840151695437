import { DownloadRequest } from 'shared/types/download-manager/downloadRequests';

export const createGroupedDownloadRequestsData = (data: DownloadRequest[], locale: string) => {
  const intlDateFormatter = new Intl.DateTimeFormat(locale, { year: 'numeric', month: 'short', day: 'numeric' });

  const groupedData = data.reduce<Record<string, DownloadRequest[]>>((acc, item) => {
    const date = intlDateFormatter.format(new Date(item.startedAt));
    if (acc[date] === undefined) {
      acc[date] = [];
    }

    acc[date].push(item);

    return acc;
  }, {});

  return Object.entries(groupedData).map(([key, value]) => ({
    date: key,
    data: value,
  }));
};
