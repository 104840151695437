import { atomFamily } from 'recoil';

import { EPISODES_PLAYING_MODE } from 'shared/components/video-player/defaultPlayingModes';
import { PLAYER_STATES, READY_STATES } from 'shared/components/video-player/state/states';
import { PlayerStatePlaylist } from 'shared/components/video-player/types';

const currentTime = atomFamily<number, string>({
  key: 'video-player-current-time',
  default: 0,
});

type States = READY_STATES | PLAYER_STATES;
const playerStatus = atomFamily<States | { [key in States]: string }, string>({
  key: 'video-player-state',
  default: PLAYER_STATES.IDLE,
});

const isPlaying = atomFamily<boolean, string>({
  key: 'video-player-is-playing',
  default: false,
});

const playlistDefault: PlayerStatePlaylist = {
  preferredPlayingMode: EPISODES_PLAYING_MODE,
  currentSelectedPlayingMode: EPISODES_PLAYING_MODE,
  currentPlaylistItemId: '',
  playlistItems: [],
  playingItem: {
    currentSourceTime: 0,
    playlistItem: {
      videoTypes: [],
      duration: 0,
      name: '',
      id: '',
      index: 0,
      recordingMatchday: '',
      recordingName: '',
      recordingId: '',
      hasHomographies: false,
      fundamentalsSelected: {
        tacticalAnalysisId: undefined,
        fundamentalsSelected: [],
      },
      showOverlays: false,
    },
    videoSourceIndex: 0,
  },
};

const playlist = atomFamily<PlayerStatePlaylist, string>({
  key: 'video-player-playlist',
  default: playlistDefault,
});

const isInStandBy = atomFamily<boolean, string>({
  key: 'video-player-is-stand-by',
  default: false,
});

export const videoPlayerStateAtoms = {
  currentTime,
  isPlaying,
  isInStandBy,
  playlist,
  playerStatus,
};
