import { RecordingsFiltersList } from 'api/recording/useFetchRecordings';

export const POSSIBLE_FILTERS: RecordingsFiltersList = {
  annotationType: {
    title: 'Annotation Type',
    options: {},
  },
  competition: {
    title: 'Competition',
    options: {},
  },
  date: {
    title: 'Date',
    options: {},
  },
  matchday: {
    title: 'Match Day',
    options: {},
  },
  team: {
    title: 'Team',
    options: {},
  },
  type: {
    title: 'Type',
    options: {},
  },
};
