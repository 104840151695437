import { styled } from '@mui/material';
import { Colors } from 'kognia-ui';

interface Props {
  children: React.ReactNode;
}

const SectionContainer = styled('section')(({ theme }) => ({
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  background: Colors.shark,
  color: Colors.white,
}));

const FiltersSummary = ({ children }: Props) => {
  return <SectionContainer>{children}</SectionContainer>;
};

export default FiltersSummary;
