import { transformRecordingVideoSources } from 'api/match/transformers/match/matchWithEpisodes';
import {
  cropTaggingEventUrl,
  exportTaggingEventUrl,
  importTaggingEventUrl,
  matchOrLiveTaggingEventsUrl,
  matchTaggingEventUrl,
  taggingEventBaseUrl,
} from 'api/routes';
import { getDefaultVideoSource } from 'shared/utils/get-video-sources';
import { fetchRequest, FetchRequestOptions } from 'tagging-tool/service/base';
import {
  FetchCreateTaggingEventResponse,
  FetchDeleteTaggingEventsParams,
  FetchExportTaggingEventsParams,
  FetchRenameTaggingEventParams,
  FetchTaggingEventsParams,
  FetchTaggingEventsResponse,
  FetchTrimTaggingEventParams,
  FetchUploadImportedTaggingEventsParams,
  FetchUploadImportedTaggingEventsResponse,
} from 'tagging-tool/service/taggingEvent';
import { sanitizeNumber, sanitizeString } from 'tagging-tool/utility/sanitizer';

export const fetchTaggingEvents = (params: FetchTaggingEventsParams, options?: Partial<FetchRequestOptions>) => {
  return fetchRequest<FetchTaggingEventsResponse>({
    ...options,
    url: matchOrLiveTaggingEventsUrl(params.recordingId, params.isLive ?? false),
  }).then((res) => {
    if (res.error) {
      return res;
    }

    const { videoSources, name, date, startRecordingTime, data } = res.data;

    const mutatedVideoSources = transformRecordingVideoSources(videoSources) || [];

    const taggingEvents = data.map((event) => ({
      description: event.description,
      id: event.id,
      name: event.name,
      recordingId: event.recordingId,
      time: event.time,
      timeAfter: event.timeAfter,
      timeBefore: event.timeBefore,
      typeOfPlay: event.typeOfPlay,
      typeOfPlaySource: event.typeOfPlaySource,
      userId: event.userId,
    }));

    return {
      ...res,
      data: {
        ...res.data,
        data: taggingEvents.sort((r1, r2) => {
          if (r1.time === r2.time) {
            return 0;
          } else if (r1.time < r2.time) {
            return params.asc === true ? -1 : 1;
          }
          return params.asc === true ? 1 : -1;
        }),
        startRecordingTime: sanitizeNumber(startRecordingTime),
        name: sanitizeString(name) || 'No name',
        date: sanitizeString(date) || '',
        videoSources: mutatedVideoSources,
        matchVideoSource: getDefaultVideoSource(mutatedVideoSources),
      },
    };
  });
};

export const fetchDeleteTaggingEvent = (params: FetchDeleteTaggingEventsParams) => {
  return fetchRequest<FetchCreateTaggingEventResponse>({
    method: 'delete',
    url: taggingEventBaseUrl,
    body: params.taggingEventsIds,
  });
};

export const fetchRenameTaggingEvent = (params: FetchRenameTaggingEventParams) => {
  const { name, taggingEventId } = params;
  return fetchRequest<FetchCreateTaggingEventResponse>({
    method: 'put',
    url: matchTaggingEventUrl(taggingEventId),
    body: { name },
  });
};

export const fetchUploadImportedTaggingEvents = (params: FetchUploadImportedTaggingEventsParams) => {
  const { recordingId, data: body } = params;
  return fetchRequest<FetchUploadImportedTaggingEventsResponse>({
    method: 'post',
    url: importTaggingEventUrl(recordingId),
    body,
  });
};

export const fetchExportTaggingEvents = (params: FetchExportTaggingEventsParams) => {
  const { recordingId, isLive } = params;
  return fetchRequest<string>({
    url: exportTaggingEventUrl(recordingId, isLive),
  });
};

export const fetchTrimTaggingEvent = (params: FetchTrimTaggingEventParams) => {
  const { taggingEventId, ...body } = params;
  return fetchRequest<FetchCreateTaggingEventResponse>({
    method: 'put',
    url: cropTaggingEventUrl(taggingEventId),
    body,
  });
};
