import { useRecoilValue, useSetRecoilState } from 'recoil';

import { timelineAtoms } from 'pages/tactical-analysis/atoms';

export const useSetTimelineHeight = () => {
  return useSetRecoilState(timelineAtoms.height);
};

export const useTimelineHeight = () => {
  return useRecoilValue(timelineAtoms.height);
};
