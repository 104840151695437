import { Fade } from '@mui/material';

import styles from 'shared/components/loading-block/LoadingBlock.module.scss';
import Spinner from 'shared/components/spinner';
interface Props {
  isLoading: boolean;
  children: React.ReactNode;
}

export const LoadingBlock = ({ isLoading, children }: Props) => {
  return (
    <div className={styles.container}>
      <Fade in={isLoading}>
        <div className={styles.content}>
          <Spinner />
        </div>
      </Fade>
      {children}
    </div>
  );
};
