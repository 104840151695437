export const SECONDS_IN_DAY = 86400;
export const SECONDS_IN_HOUR = 3600;
export const SECONDS_IN_MINUTE = 60;

export const convertFromISO8601ToSeconds = (duration: string): number => {
  const regex = /P(?:([0-9]+)D)?T?(?:([0-9]+)H)?(?:([0-9]+)M)?(?:([0-9.]+)S)?/;
  const matches = duration.match(regex);

  if (!matches) {
    throw new Error('Invalid ISO 8601 duration format');
  }

  const days = parseInt(matches[1], 10) || 0;
  const hours = parseInt(matches[2], 10) || 0;
  const minutes = parseInt(matches[3], 10) || 0;
  const seconds = parseFloat(matches[4]) || 0;

  return days * SECONDS_IN_DAY + hours * SECONDS_IN_HOUR + minutes * SECONDS_IN_MINUTE + seconds;
};
