import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { SeasonClient, SeasonClientsApiResponse } from 'pages/backoffice/api/season-clients/types';
import { SeasonClients } from 'shared/types/user/types';
import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';

export const transformSeasonClients = (seasonClient: SeasonClient): SeasonClients => ({
  id: seasonClient.uuid,
  originalClient: seasonClient.original_client,
  seasonName: seasonClient.season_name,
  clients: seasonClient.clients,
  updatedAt: new Date(seasonClient.updated_at),
});

export const transformSeasonClientsList = (
  response: SeasonClientsApiResponse,
): InfinityQueryDataResult<SeasonClients> => {
  return {
    data: {
      items: response.data.map(transformSeasonClients),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
