import { embedDashboard, UiConfigType } from '@superset-ui/embedded-sdk';
import { useQuery } from '@tanstack/react-query';
import { RefObject, useMemo } from 'react';

import { dashboardGuestToken } from 'api/routes';
import { SUPERSET_URL } from 'kognia/env';

async function fetchGuestToken(dashboardId: string) {
  const response = await fetch(dashboardGuestToken(dashboardId), {
    method: 'GET',
  });
  const data = await response.json();
  return data.token;
}

const embedSupersetDashboard = async (
  dashboardId: string,
  container: HTMLElement,
  dashboardUrlParams?: UiConfigType['urlParams'],
) => {
  return embedDashboard({
    id: dashboardId,
    supersetDomain: SUPERSET_URL,
    mountPoint: container,
    fetchGuestToken: async () => fetchGuestToken(dashboardId),
    dashboardUiConfig: {
      hideTitle: true,
      hideTab: true,
      filters: {
        expanded: false,
      },
      urlParams: dashboardUrlParams,
    },
  });
};

const fetchDashboard = async (
  dashboardId: string,
  containerRef: RefObject<HTMLDivElement>,
  dashboardUrlParams?: UiConfigType['urlParams'],
) => {
  try {
    return await embedSupersetDashboard(dashboardId, containerRef.current!, dashboardUrlParams);
  } catch (error) {
    throw new Error('Error loading dashboard');
  }
};

type Options = {
  dashboardId: string;
  containerRef: RefObject<HTMLDivElement>;
  dashboardUrlParams?: UiConfigType['urlParams'];
};

export const useEmbeddedDashboard = ({ dashboardId, containerRef, dashboardUrlParams }: Options) => {
  const uuid = useMemo(() => Date.now().toString(), []);
  return useQuery({
    queryKey: ['dashboard', dashboardId, uuid, dashboardUrlParams],
    queryFn: () => fetchDashboard(dashboardId, containerRef, dashboardUrlParams),
    retry: false,
  });
};
