export class AsyncQueue {
  private queue: (() => Promise<any>)[] = [];
  private isProcessing = false;

  public enqueue(task: () => Promise<any>): void {
    this.queue.push(task);
    if (!this.isProcessing) this.processQueue();
  }

  private async processQueue() {
    if (this.isProcessing) return;
    this.isProcessing = true;

    while (this.queue.length > 0) {
      const currentTask = this.queue.shift();
      if (currentTask) {
        try {
          await currentTask();
        } catch (error) {
          console.error('Error processing task:', error);
        }
      }
    }

    this.isProcessing = false;
  }
}

export const playerPlayingControlQueue = new AsyncQueue();
