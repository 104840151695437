import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { CoachApi } from 'pages/backoffice/api/coaches/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';

type UpdateCoachParams = Pick<CoachApi, 'name'> & { image?: File };

interface RequestData {
  data: UpdateCoachParams;
  id: string;
  onSuccess?: () => void;
}

export const useUpdateCoach = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.PATCH,
    successMessage: 'Coach updated',
    errorMessage: (data) => metadataApiErrorParser(data, 'Coach update error'),
  });

  const update = ({ data, id, onSuccess }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    const formData = new FormData();
    formData.append('coach', JSON.stringify({ name: data.name }));
    data.image && formData.append('coach_image', data.image);

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.COACHES}/${id}`,
        data: formData,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, update };
};
