import { TaggingEvent } from 'api/tagging-tool/types';
import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { generateClipsWithOverlappingTime } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/generateClips';

export const getClipsFromTaggingEvent = (clips: TaggingEvent[], rowId: string): Clip[] => {
  const mappedClips = clips.map<Clip>((clip) => {
    return {
      endTime: clip.time + clip.timeAfter,
      id: clip.id,
      rowId: rowId,
      startTime: clip.time - clip.timeBefore > 0 ? clip.time - clip.timeBefore : 0,
      title: clip.name,
      type: 'manual-tag',
      elementId: clip.id,
    };
  });

  return generateClipsWithOverlappingTime({ clips: mappedClips });
};
