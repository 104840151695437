import { Box, Typography } from '@mui/material';
import classNames from 'classnames';
import { IconChevronDown } from 'kognia-ui/icons/IconChevronDown';
import map from 'lodash/map';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'pages/recordings-list/components/recordings-list-page/filters-bar/annotation-type-filter/annotation-type-filter.module.scss';
import { useRecordingsListFilters } from 'pages/recordings-list/hooks/useRecordingsListFilters';
import { RecordingAnnotationTypes, RecordingFilters } from 'pages/recordings-list/types/recording';
import { ListTitle } from 'shared/components/list/list-title';
import { Filter } from 'shared/types';

type AnnotationTypeFilterProps = {
  annotationFilter: Filter;
};

export const AnnotationTypeFilter = ({ annotationFilter }: AnnotationTypeFilterProps): JSX.Element => {
  const { t } = useTranslation();
  const [isDropdownVisible, setDropdownVisible] = useState(false);
  const { setFilter, getUrlFilters } = useRecordingsListFilters();
  const annotationTypeOptionApplied = getUrlFilters().annotationType || RecordingAnnotationTypes.all;

  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
      <ListTitle dataTestId='page-title'>{t('filters:recordings')}</ListTitle>

      <div
        className={styles.dropdown}
        tabIndex={0}
        onMouseEnter={() => setDropdownVisible(true)}
        onMouseLeave={() => setDropdownVisible(false)}
        onFocus={() => setDropdownVisible(true)}
        onBlur={() => setDropdownVisible(false)}
      >
        <Typography sx={{ fontSize: 24, fontWeight: 300, display: 'inline' }}>
          {t(`filters:annotation-type.${annotationTypeOptionApplied.toLowerCase()}`)}
        </Typography>
        <IconChevronDown
          size='small'
          className={classNames(styles.caret, {
            [styles['caret--down']]: isDropdownVisible,
          })}
        />
        <ul
          className={classNames(styles.list, {
            [styles['list--visible']]: isDropdownVisible,
          })}
        >
          {map(annotationFilter.options, (option, index) => (
            <li
              tabIndex={annotationTypeOptionApplied === index ? -1 : 0}
              className={classNames(styles.type, {
                [styles['type--disabled']]: annotationTypeOptionApplied === index,
              })}
              key={index}
              onClick={() => {
                setFilter(RecordingFilters.ANNOTATION_TYPE, [index]);
              }}
            >
              {t(`filters:annotation-type.${index.toLowerCase()}`)}
            </li>
          ))}
        </ul>
      </div>
    </Box>
  );
};
