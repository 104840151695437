import React from 'react';

import { KeypadTag } from 'api/tagging-tool/types';
import LiveStreamTagButton from 'pages/tagging-tool-tag-recording/components/tag-button/LiveStreamTagButton';
import RecordingTagButton from 'pages/tagging-tool-tag-recording/components/tag-button/RecordingTagButton';
import { TagButtonProps } from 'pages/tagging-tool-tag-recording/components/tag-button/TagButtonBase';

export const TagButton = (
  props: TagButtonProps & { videoStartRecordingTime?: number; onPress: (time: number) => (tag: KeypadTag) => void },
) => {
  if (props.videoStartRecordingTime) return <LiveStreamTagButton {...props} />;

  return <RecordingTagButton {...props} />;
};
