import find from 'lodash/find';
import map from 'lodash/map';
import reduce from 'lodash/reduce';

import {
  RowGroup,
  RowList,
} from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/row';
import { generateBlockContainerClip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/generateBlockContainerClip';
import { sortScenarios } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/utils/sortScenarios';
import { Episode, isValidScenario } from 'shared/types/index';

export const generateScenariosRows = (
  episodes: Episode[],
  recordingId: string,
): {
  scenariosRowGroup: RowGroup;
  scenariosIdsList: Set<string>;
} => {
  const rows: RowList = {};
  const scenariosIdsList: Set<string> = new Set();

  episodes.map((episode) => {
    episode.tacticalScenarios
      .filter((scenario) => isValidScenario(scenario.tacticalScenarioType))
      .map((scenario) => {
        const title = `fundamentals:tactical-scenario-types.${scenario.tacticalScenarioType}`;
        if (!rows[scenario.tacticalScenarioType]) {
          rows[scenario.tacticalScenarioType] = generateBlockContainerClip({
            id: `${recordingId}-${scenario.tacticalScenarioType}`,
            rowType: 'scenarios',
            timelineTableBlocks: episodes,
            title,
            rowId: `${recordingId}-${scenario.tacticalScenarioType}`,
            clipIdPrefix: scenario.tacticalScenarioType,
          });
        }

        const episodeClip = find(
          rows[scenario.tacticalScenarioType].clips,
          (row) => row.id === `${scenario.tacticalScenarioType}-${episode.id}`,
        );

        scenariosIdsList.add(scenario.tacticalScenarioType);
        episodeClip?.clips?.push({
          id: `${recordingId}-${scenario.tacticalScenarioType}-${scenario.teamId}-${scenario.startTime}-${scenario.endTime}`,
          startTime: scenario.startTime,
          endTime: scenario.endTime,
          type: 'scenario',
          elementId: scenario.tacticalScenarioType,
          rowId: `${recordingId}-${scenario.tacticalScenarioType}`,
          teamId: scenario.teamId,
          title,
        });
      });
  });

  const extractedRows = map(rows, (row) => row).sort(sortScenarios(`${recordingId}-`));
  const scenariosRowGroup: RowGroup = {
    id: `${recordingId}-scenarios`,
    isOpen: true,
    isSelected: false,
    title: 'timeline:scenarios',
    rows: extractedRows,
    totalClips: reduce(extractedRows, (acc, row) => acc + row.clips.length, 0),
    type: 'scenarios',
  };

  return { scenariosRowGroup, scenariosIdsList };
};
