import { useCallback } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';

import { useSaveUserPreset } from 'api/user-presets/use-save-user-preset';
import { TimelinePinScenariosPreset } from 'shared/constants/user-presets/userPresetsClasses';

const pinScenariosAtom = atom<boolean>({
  key: 'pin-scenarios',
  default: false,
});

export const usePinScenarios = () => {
  return useRecoilValue(pinScenariosAtom);
};

export const useSetPinScenarios = () => {
  const { savePreset } = useSaveUserPreset();
  const setPinScenario = useSetRecoilState(pinScenariosAtom);

  return useCallback(
    (value: boolean, updatePreset = true) => {
      const pinScenariosPreset = new TimelinePinScenariosPreset(value);
      updatePreset && savePreset({ data: pinScenariosPreset });
      setPinScenario(value);
    },
    [savePreset, setPinScenario],
  );
};
