import { Checkbox, ListItem, Typography } from '@mui/material';
import { IconEdit } from 'kognia-ui/icons/IconEdit';
import { IconTimeline } from 'kognia-ui/icons/IconTimeline';
import { useCallback } from 'react';

import { KogniaUser } from 'api/backoffice/user/use-kognia-users/types';
import { EditUserModal } from 'pages/backoffice/features/users/edit-user-modal/EditUserModal';
import KebabMenu, { MenuListOption } from 'shared/components/kebab-menu';
import { useDates } from 'shared/hooks/use-dates';
import { useModalState } from 'shared/hooks/use-modal-state/useModalState';

interface Props {
  user: KogniaUser;
  selectedUsers: string[];
  openDetailsModal: (user: KogniaUser) => void;
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
}

export const UsersListItem = ({ user, selectedUsers, openDetailsModal, setSelectedUsers }: Props) => {
  const { dateToString } = useDates();
  const { isOpenModal, closeModal, openModal } = useModalState();

  const handleOpenDetailsModal = () => {
    openDetailsModal(user);
  };

  const handleOnChangeCheckedUser = useCallback(
    (userId: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedUsers((prev) => {
        if (!event.target.checked) {
          return prev.filter((item) => item !== userId);
        }

        return [...prev, userId];
      });
    },
    [setSelectedUsers],
  );

  const options: MenuListOption[] = [
    {
      displayText: 'Details',
      icon: <IconTimeline size='small' color='secondary' />,
      onClick: handleOpenDetailsModal,
    },
    {
      displayText: 'Edit',
      icon: <IconEdit size='small' color='secondary' />,
      onClick: openModal,
    },
  ];

  return (
    <>
      <ListItem
        sx={(theme) => ({
          display: 'grid',
          gridTemplateColumns: '42px repeat(4, minmax(100px, 1fr)) 32px',
          borderBottom: `1px solid ${theme.palette.secondary.light}`,
          padding: theme.spacing(1, 2),
        })}
      >
        <Checkbox checked={selectedUsers.includes(user.id)} onChange={handleOnChangeCheckedUser(user.id)} />

        <Typography pr={1} fontWeight='fontWeightMedium' overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
          {user.firstName}
        </Typography>

        <Typography pr={1} overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
          {user.lastName}
        </Typography>

        <Typography pr={1} overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
          {dateToString(user.updatedAt)}
        </Typography>

        <Typography pr={1} overflow='hidden' textOverflow='ellipsis' whiteSpace='nowrap'>
          {user.email}
        </Typography>

        <KebabMenu options={options} />
      </ListItem>

      {isOpenModal ? <EditUserModal user={user} isOpen={isOpenModal} onClose={closeModal} /> : null}
    </>
  );
};
