import { Box, outlinedInputClasses, styled, TextField } from '@mui/material';
import { Colors } from 'kognia-ui';

import Spinner from 'shared/components/spinner';

interface TaggingEventClipContainerProps {
  multiselect: boolean;
}

export const TaggingEventClipContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'multiselect',
})<TaggingEventClipContainerProps>(({ theme, multiselect }) => ({
  background: Colors.white,
  borderRadius: theme.shape.borderRadius,
  position: 'relative',
  padding: theme.spacing(1, 0, 1, 2),
  display: 'flex',
  gap: theme.spacing(1),
  justifyContent: 'flex-start',
  alignItems: 'center',
  ...(multiselect && {
    paddingRight: theme.spacing(2),
  }),
}));

export const TaggingEventClipEnd = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export const TaggingEventEditableTextContainer = styled(Box)({
  flex: 1,
});

export const TaggingEventEditableText = styled(TextField)(({ theme }) => ({
  [`& .${outlinedInputClasses.input}`]: {
    padding: theme.spacing(0.5, 1),
  },
}));

export const TaggingEventSpinner = styled(Spinner)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(2),
}));
