import { Box, Checkbox, Grid, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

interface Props {
  name: string;
  isChecked: boolean;
  autocompleteWidth: number;
}

const Details = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(),
  lineHeight: 1,
}));

interface NameProps {
  maxWidth: number;
}

const Name = styled(Box, { shouldForwardProp: (prop) => prop !== 'maxWidth' })<NameProps>(({ maxWidth }) => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: `${maxWidth - 56}px`,
}));

const Result = styled(Grid)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(0.5),
  justifyContent: 'space-between',
  alignItems: 'center',
  '&:hover': {
    background: Colors.background,
  },
}));

export const AutocompleteResult = ({ name, isChecked, autocompleteWidth }: Props) => {
  return (
    <Result container>
      <Grid item>
        <Grid container spacing={1} alignItems='center'>
          <Grid item>
            <Checkbox checked={isChecked} />
          </Grid>
          <Grid item>
            <Details>
              <Name title={name} maxWidth={autocompleteWidth}>
                {name}
              </Name>
            </Details>
          </Grid>
        </Grid>
      </Grid>
    </Result>
  );
};
