import { UseSuspenseQueryResult } from '@tanstack/react-query';

import { queryClient } from 'api/config';
import { useFetchSuspenseRequest } from 'api/hooks/useFetchSuspenseRequest';
import { playerWithDefaults } from 'api/routes';
import { Player } from 'shared/types/player/player';
import { transformPlayerApiResponse } from 'shared/utils/transformPlayer';

const PLAYER_WITH_DEFAULTS_QUERY_KEY = 'player-with-defaults';

export const generatePlayerWithDefaultsQueryKey = (playerId: string) => [PLAYER_WITH_DEFAULTS_QUERY_KEY, playerId];

export const invalidatePlayerWithDefaults = (playerId: string) => {
  return queryClient.invalidateQueries({ queryKey: generatePlayerWithDefaultsQueryKey(playerId) });
};

export const optimisticFavoritesUpdatePlayerWithDefaults = async (playerId: string, isFavorite: boolean) => {
  const queryKey = generatePlayerWithDefaultsQueryKey(playerId);
  await queryClient.cancelQueries({ queryKey });

  queryClient.setQueryData(queryKey, (player: Player) => {
    return {
      ...player,
      isFavorite,
    };
  });
};

export const usePlayerWithDefaults = (playerId: string): UseSuspenseQueryResult<Player> => {
  return useFetchSuspenseRequest<Player>({
    url: playerWithDefaults(playerId),
    queryRef: generatePlayerWithDefaultsQueryKey(playerId),
    transformer: transformPlayerApiResponse,
  });
};
