import { Row } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/row';

export const sortByTitle = (row1: Row, row2: Row) => {
  if (row1.title < row2.title) {
    return -1;
  }
  if (row1.title > row2.title) {
    return 1;
  }
  return 0;
};
