import { Box, Button, CardContent, Stack, TextField, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { IconPlus } from 'kognia-ui/icons/IconPlus';
import { ChangeEvent } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import { useMetadataFixtures } from 'pages/backoffice/api/fixtures/use-metadata-fixtures';
import { BackofficeContentContainer } from 'pages/backoffice/components/backoffice-content-container';
import { ListSkeleton } from 'pages/backoffice/components/list-skeleton';
import { FixuresList } from 'pages/backoffice/pages/fixtures/components/metadata-fixture-list';
import { useResetFixtureFormData } from 'pages/backoffice/pages/fixtures/fixture-form/config';
import { FixtureListHeaderGrid } from 'pages/backoffice/pages/fixtures/styled';
import { ListHeader } from 'shared/components/list/list-header';
import NotFound from 'shared/components/not-found';
import Pagination from 'shared/components/pagination';
import { useDebounce } from 'shared/hooks/use-debounce/useDebounce';

export const Fixtures = () => {
  const navigate = useNavigate();
  const resetFormData = useResetFixtureFormData();
  const { items, page, fetchNextPage, isFetching, setFilters } = useMetadataFixtures();

  const handleSearchChange = useDebounce((event: ChangeEvent<HTMLInputElement>) => {
    setFilters({ name: event.target.value });
  });

  return (
    <BackofficeContentContainer>
      <Stack direction='row' alignItems='center' justifyContent='space-between' marginBottom={2}>
        <Typography variant='h4'>Fixtures</Typography>
        <Button
          variant='contained'
          size='large'
          startIcon={<IconPlus size='small' sx={{ color: 'common.white' }} />}
          onClick={() => {
            resetFormData();
            navigate(generatePath(routes.BACKOFFICE_FIXTURE_FORM_STEP_1, { id: null }));
          }}
        >
          New fixture
        </Button>
      </Stack>

      <CardContent>
        <>
          <Stack marginBottom={2} justifyContent='flex-end' direction='row'>
            <TextField size='small' placeholder='Search fixtures' onChange={handleSearchChange} />
          </Stack>

          <ListHeader>
            <FixtureListHeaderGrid>
              <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
                Score
              </Typography>
              <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
                Teams
              </Typography>
              <Typography variant='body1' fontSize={fontSizes.default} fontWeight={'fontWeightMedium'}>
                Event
              </Typography>
              <div>&nbsp;</div>
            </FixtureListHeaderGrid>
          </ListHeader>
          {!isFetching && items.length === 0 ? (
            <Box marginTop={4}>
              <NotFound header={'No results'} />
            </Box>
          ) : null}

          {isFetching && !Boolean(items.length > 0) ? <ListSkeleton /> : <FixuresList fixtures={items} />}

          {page ? (
            <Pagination
              total={page.totalElements}
              displayed={items.length}
              onShowMore={fetchNextPage}
              getStatsText={(displayed, total) => `${displayed} of ${total} fixtures`}
            />
          ) : null}
        </>
      </CardContent>
    </BackofficeContentContainer>
  );
};
