import { Box } from '@mui/material';
import { forwardRef, PropsWithChildren } from 'react';

export const RowHeadersContent = forwardRef(
  ({ children, ...rest }: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>, ref: any) => (
    <Box {...rest} ref={ref} sx={{ background: ({ palette }) => palette.common.white }}>
      {children}
    </Box>
  ),
);

RowHeadersContent.displayName = 'RowHeadersContent';
