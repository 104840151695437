import { TacticId } from '@kognia/tactical-analysis-data';
import { Box, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { CheckboxWithCustomColor } from 'shared/components/CheckboxWithCustomColor/CheckboxWithCustomColor';

type TacticCheckBoxProps = {
  tactic: TacticId;
  selected: boolean;
  onChange: (tactic: TacticId) => void;
  isAvailableInFrame: boolean;
};

export const TacticCheckBox = ({ tactic, selected, onChange, isAvailableInFrame }: TacticCheckBoxProps) => {
  const { t } = useTranslation();
  const handleToggleTactic = useCallback(() => {
    onChange(tactic);
  }, [tactic, onChange]);

  return (
    <Box
      display='flex'
      alignItems='center'
      flexDirection='row'
      gap={1}
      sx={{ color: isAvailableInFrame ? 'primary.main' : 'text.primary' }}
    >
      <CheckboxWithCustomColor customColor='typography' checked={selected} onChange={handleToggleTactic} />
      {/* TODO use font styles from theme */}
      <Typography component='span' fontSize={fontSizes.default} lineHeight={1.77}>
        {t(`fundamentals:fundamentals.${tactic}`)}
      </Typography>
    </Box>
  );
};
