import { SvgIcon, type SvgIconProps } from '../components/SvgIcon';

export const IconShield = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path
        d='M17.75 5.212h0v-.006-.118-.12-.25h-.25-.117-.033-.148-.029-.258H6.617 6.5h-.25v.25.12 7.402c0 2.869 1.531 4.809 2.8 5.924 1.318 1.158 2.619 1.692 2.775 1.754h.001l.04.016h0l.041.016.09.035.091-.035.042-.016.001-.001.043-.017h.001c.155-.061 1.456-.596 2.774-1.753 1.269-1.115 2.801-3.056 2.8-5.924h0V6.904h0V5.616v-.001-.311-.03-.044-.013-.004zM12.029 19.17h0l-.003.002-.027.012a9.71 9.71 0 0 1-2.436-1.612c-.699-.637-1.241-1.329-1.627-2.071l8.851-9.106v6.094c-.001 1.998-.823 3.739-2.469 5.187-.648.57-1.296.972-1.726 1.208l-.564.285zm-4.817-6.68V5.694h8.906l-8.59 8.838c-.21-.645-.316-1.326-.316-2.042z'
        strokeWidth='.5'
      />
    </SvgIcon>
  );
};
