import { IconClose } from 'kognia-ui/icons/IconClose';
import { IconDone } from 'kognia-ui/icons/IconDone';
import React from 'react';

import {
  TagEventActionButton,
  TagEventClipActionsContainer,
} from 'pages/tagging-tool-tag-recording/components/tag-event-clip-actions/styled';
import Spinner from 'shared/components/spinner';

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  loading?: boolean;
  disabled?: boolean;
};

export const TagEventClipActions = (props: Props) => {
  return (
    <TagEventClipActionsContainer>
      {props.loading ? (
        <Spinner />
      ) : (
        <TagEventActionButton
          disabled={props.disabled === true}
          onClick={props.onConfirm}
          variant='contained'
          size='small'
        >
          <IconDone size='small' sx={{ color: 'common.white' }} />
        </TagEventActionButton>
      )}
      <TagEventActionButton
        disabled={props.loading === true}
        onClick={props.onCancel}
        variant='contained'
        size='small'
        color='secondary'
      >
        <IconClose size='small' sx={{ color: 'common.white' }} />
      </TagEventActionButton>
    </TagEventClipActionsContainer>
  );
};
