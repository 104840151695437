import { useAtomValue, useSetAtom } from 'jotai';
import { atomFamily, atomWithReset, useResetAtom } from 'jotai/utils';

import { PlaylistsFilters, PlaylistSortOptions } from 'api/playlist/useFetchPlaylists/types';
import { SortDirection } from 'shared/types/filters/types';

export const INITIAL_PLAYLISTS_FILTERS: PlaylistsFilters = {
  competition: [],
  from: '',
  name: '',
  to: '',
  sort: PlaylistSortOptions.UPDATED_AT,
  sortDirection: SortDirection.DESC,
};

const playlistFilters = atomFamily(() => atomWithReset<PlaylistsFilters>(INITIAL_PLAYLISTS_FILTERS));

export const usePlaylistsFilters = (id: string) => {
  return useAtomValue(playlistFilters(id));
};

export const useSetPlaylistsFilters = (id: string) => {
  return useSetAtom(playlistFilters(id));
};

export const useResetPlaylistsFilters = (id: string) => {
  return useResetAtom(playlistFilters(id));
};
