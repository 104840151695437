export const ZOOM_LEVELS = {
  extraLarge: 0,
  large: 1,
  mediumLarge: 2,
  medium: 3,
  mediumSmall: 4,
  small: 5,
  extraSmall: 6,
} as const;

export const ZOOM_LEVELS_VALUES = Object.values(ZOOM_LEVELS);
