import { useCallback, useReducer } from 'react';

import { ActionType, reducer } from 'features/multi-analysis-filters/hooks/use-multiple-recordings-filters/reducer';
import {
  RecordingFiltersScenariosOrTacticsInside,
  RecordingsFilters,
  RecordingsFiltersEvents,
} from 'shared/types/recording/types';

export const useMultipleRecordingsFilters = (initialFilters: RecordingsFilters) => {
  const [filters, dispatch] = useReducer(reducer, initialFilters);

  const reset = useCallback(() => {
    dispatch({ type: ActionType.RESET });
  }, [dispatch]);

  const setEventsStartingFilter = useCallback(
    (payload: RecordingsFiltersEvents | undefined) => {
      dispatch({ type: ActionType.SET_EVENTS_STARTING_FILTER, payload });
    },
    [dispatch],
  );

  const setEventsEndingFilter = useCallback(
    (payload: RecordingsFiltersEvents | undefined) => {
      dispatch({ type: ActionType.SET_EVENTS_ENDING_FILTER, payload });
    },
    [dispatch],
  );

  const setScenariosInsideAnEpisodeFilter = useCallback(
    (payload: RecordingFiltersScenariosOrTacticsInside | undefined) => {
      dispatch({ type: ActionType.SET_SCENARIOS_OR_TACTICS_INSIDE, payload });
    },
    [dispatch],
  );

  return {
    filters,
    reset,
    setEventsEndingFilter,
    setScenariosInsideAnEpisodeFilter,
    setEventsStartingFilter,
  };
};
