import { UseSuspenseQueryResult } from '@tanstack/react-query';

import { transformCompetitions } from 'api/competitions/utils/transformCompetitions';
import { useFetchSuspenseRequest } from 'api/hooks/useFetchSuspenseRequest';
import { playerCompetitions } from 'api/routes';
import { Competition } from 'shared/types/competition/competition';

const PLAYER_COMPETITIONS_QUERY_KEY = 'player-competitions';

export const usePlayerCompetitions = (playerId: string): UseSuspenseQueryResult<Competition[]> => {
  return useFetchSuspenseRequest<Competition[]>({
    url: playerCompetitions(playerId),
    queryRef: [PLAYER_COMPETITIONS_QUERY_KEY, playerId],
    transformer: transformCompetitions,
  });
};
