export enum Variants {
  IN = 'in',
  OUT = 'out',
  REMOVE = 'remove',
}
export const recordingVariants = {
  [Variants.IN]: { opacity: 1 },
  [Variants.OUT]: { opacity: 0, transition: { delay: 0.2, duration: 0.2, type: 'tween' } },
  [Variants.REMOVE]: { opacity: 0, transition: { delay: 0, duration: 0.1, type: 'tween' } },
};

export const getAnimationStatus = (isPresent: boolean, isDeleted: boolean) => {
  if (isDeleted && !isPresent) return Variants.OUT;
  if (!isPresent) return Variants.REMOVE;
  return Variants.IN;
};
