import { TFunction } from 'i18next';

import { TaggingEvent, TaggingEventsFilters, TaggingEventUserInfo } from 'api/tagging-tool/types';
import { TypeOfPlay } from 'shared/types/type-of-play/types';

export type TaggingEventFilter = { title: string; value: string };

export interface TaggingEventsFilterOptions {
  offensive: TaggingEventFilter[];
  defensive: TaggingEventFilter[];
  transitions: TaggingEventFilter[];
  imported: TaggingEventFilter[];
  user: TaggingEventFilter[];
}

export const getUserNameFromUserId = (userId: string, usersInfo: TaggingEventUserInfo[]): string => {
  const user = usersInfo.find((userInfo) => userInfo.userId === userId);
  return user ? `${user.firstName} ${user.lastName}` : '';
};

export const generateTaggingEventsFilters = (
  taggingEvents: TaggingEvent[],
  usersInfo: TaggingEventUserInfo[],
  t: TFunction<'translation'>,
): TaggingEventsFilterOptions => {
  return {
    offensive: Array.from(
      new Set(taggingEvents.filter((tag) => tag.typeOfPlay === TypeOfPlay.Offense).map((tag) => tag.name)),
    ).map((name) => ({
      title: name,
      value: name,
    })),
    defensive: Array.from(
      new Set(taggingEvents.filter((tag) => tag.typeOfPlay === TypeOfPlay.Defense).map((tag) => tag.name)),
    ).map((name) => ({
      title: name,
      value: name,
    })),
    transitions: Array.from(
      new Set(taggingEvents.filter((tag) => tag.typeOfPlay === TypeOfPlay.Transition).map((tag) => tag.name)),
    ).map((name) => ({
      title: name,
      value: name,
    })),
    imported: taggingEvents.some((tag) => tag.typeOfPlay === TypeOfPlay.imported)
      ? [
          {
            title: t('tagging-tool:tagging-recording.filter-imported'),
            value: 'imported',
          },
        ]
      : [],
    user: Array.from(new Set(taggingEvents.filter((tag) => tag.userId).map((tag) => tag.userId))).map((userId) => ({
      title: getUserNameFromUserId(userId, usersInfo),
      value: userId,
    })),
  };
};

export const applyTypeOfPlayFilter = (filters: TaggingEventsFilters, typeOfPlay: keyof TaggingEventsFilters) => {
  return (taggingEvent: TaggingEvent): boolean => {
    const isCurrentTypeOfPlay = taggingEvent.typeOfPlay === typeOfPlay;
    const areAllSelected = filters[typeOfPlay].all;
    const areFilterOptionsEmpty = filters[typeOfPlay].options.length === 0;
    const isNameInFilterOptions = !areFilterOptionsEmpty && filters[typeOfPlay].options.includes(taggingEvent.name);

    return (
      !isCurrentTypeOfPlay ||
      (areFilterOptionsEmpty && areAllSelected) ||
      (isCurrentTypeOfPlay && (typeOfPlay === TypeOfPlay.imported || isNameInFilterOptions))
    );
  };
};

export const applyUserFilter = (filters: TaggingEventsFilters) => {
  return (taggingEvent: TaggingEvent): boolean => {
    return filters['user'].options.length === 0 || filters['user'].options.includes(taggingEvent.userId);
  };
};
