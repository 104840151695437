import { List, ListItem, Stack } from '@mui/material';

import { useFetchAccount } from 'api/user/use-fetch-account';
import { DateLineDivider } from 'features/download-manager/download-requests-list/ui/date-line-divider/DateLineDivider';
import { DownloadItem } from 'features/download-manager/download-requests-list/ui/download-item/DownloadItem';
import { createGroupedDownloadRequestsData } from 'features/download-manager/download-requests-list/utils/createGroupedDownloadRequestsData';
import { Locales } from 'kognia/i18n/types';
import { DownloadRequest } from 'shared/types/download-manager/downloadRequests';

interface Props {
  data: DownloadRequest[];
}

export const DownloadRequestsListFeature = ({ data }: Props) => {
  const { data: account } = useFetchAccount();
  const locale = account?.locale || Locales['en-US'];
  const groupedDownloadRequestsData = createGroupedDownloadRequestsData(data, locale);

  return (
    <List disablePadding sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
      {groupedDownloadRequestsData.map(({ data, date }) => (
        <ListItem key={date} disablePadding>
          <Stack direction='column' gap={1} width='100%'>
            <DateLineDivider textAlign='left' color='secondary'>
              {date}
            </DateLineDivider>

            <List disablePadding sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
              {data.map((item) => (
                <ListItem key={item.startedAt} disablePadding>
                  <DownloadItem data={item} />
                </ListItem>
              ))}
            </List>
          </Stack>
        </ListItem>
      ))}
    </List>
  );
};
