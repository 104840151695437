import { Button, Grid, Typography } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';
import { IconDefense } from 'kognia-ui/icons/IconDefense';
import { IconOffense } from 'kognia-ui/icons/IconOffense';
import { IconPlus } from 'kognia-ui/icons/IconPlus';
import { IconTransition } from 'kognia-ui/icons/IconTransition';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { KeypadTag } from 'api/tagging-tool/types';
import { CustomButtonsBox } from 'pages/tagging-tool-keypad-edit/components/custom-buttons/components/custom-buttons-box';
import { CustomButtonsData } from 'pages/tagging-tool-keypad-edit/components/keypad-edit/state';

type Props = {
  children?: React.ReactNode;
  data?: CustomButtonsData;
  handleAddNew: () => void;
  handleEdit: (payload: KeypadTag) => void;
  handleRemove: (payload: KeypadTag) => void;
  handleEnabledChange: (tag: KeypadTag, is_enabled: boolean) => void;
};

export const CustomButtonPanel = ({ data, handleEdit, handleAddNew, handleEnabledChange, handleRemove }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container direction={'column'} spacing={2}>
      <Grid item display='flex' justifyContent='space-between' marginTop={8} alignItems='center'>
        <Typography variant={'h6'}>{t('tagging-tool:keypad.custom-buttons-title')}</Typography>
        <Button
          variant='outlined'
          color='secondary'
          startIcon={<IconPlus size='small' color='secondary' />}
          onClick={handleAddNew}
        >
          {t('tagging-tool:keypad.add-custom-button')}
        </Button>
      </Grid>
      <Grid item>
        <Typography color={Colors.storm} fontSize={fontSizes.default}>
          {t('tagging-tool:keypad.custom-buttons')}
        </Typography>
      </Grid>
      <Grid item display='flex' gap={1} paddingTop={2}>
        <CustomButtonsBox
          data={data?.offense}
          icon={<IconOffense size='small' color='secondary' />}
          onEdit={handleEdit}
          onEnabledChange={handleEnabledChange}
          onRemove={handleRemove}
          title={t('tagging-tool:keypad-tag.type-of-play-offense')}
        />
        <CustomButtonsBox
          data={data?.transition}
          icon={<IconTransition size='small' color='secondary' />}
          onEdit={handleEdit}
          onEnabledChange={handleEnabledChange}
          onRemove={handleRemove}
          title={t('tagging-tool:keypad-tag.type-of-play-transition')}
        />
        <CustomButtonsBox
          data={data?.defense}
          icon={<IconDefense size='small' color='secondary' />}
          onEdit={handleEdit}
          onEnabledChange={handleEnabledChange}
          onRemove={handleRemove}
          title={t('tagging-tool:keypad-tag.type-of-play-defense')}
        />
      </Grid>
    </Grid>
  );
};
