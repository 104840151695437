export const sanitizeString = (s: any): string | undefined => {
  if (typeof s !== 'string') {
    return undefined;
  }
  return s.trim().length === 0 ? undefined : s;
};

export const sanitizeNumber = (n: any): number | undefined => {
  if (typeof n !== 'number') {
    return undefined;
  }
  return n;
};
