import { Box, ListItem, Stack, Typography } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCompetition } from 'pages/backoffice/api/competitions/use-competition';
import { useCompetitions } from 'pages/backoffice/api/competitions/use-competitions';
import { SearchResults } from 'pages/backoffice/pages/season-competitions/components/search-results';
import { Competition } from 'pages/backoffice/types/competitions';
import { Autocomplete } from 'shared/components/autocomplete';
import { AutocompleteDropdownButton } from 'shared/components/autocomplete-multi-select/components/autocomplete-dropdown-button';
import {
  AutocompletePopper,
  AutocompletePopperContent,
} from 'shared/components/autocomplete-multi-select/components/autocomplete-popper';
import { AutocompletePopperWrapper } from 'shared/components/autocomplete-multi-select/components/autocomplete-popper-wrapper';

interface Props {
  setCompetitionOnChange: (competition: Competition | undefined) => void;
  defaultSelectedCompetitionId?: string;
}

const AUTOCOMPLETE_WIDTH = 400;

export const SelectCompetition = ({ setCompetitionOnChange, defaultSelectedCompetitionId }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [autocompleteValue, setAutocompleteValue] = useState<Competition | undefined>();
  const { items: competitionResults, isLoading, setFilters, fetchNextPage } = useCompetitions();
  const { data: defaultSelectedCompetition } = useCompetition({ competitionId: defaultSelectedCompetitionId });
  const { t } = useTranslation();

  const isOpen = Boolean(anchorEl);

  const open = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAutocompleteValue(defaultSelectedCompetition);
      setAnchorEl(event.currentTarget);
    },
    [defaultSelectedCompetition],
  );

  const close = useCallback(() => {
    setFilters({});
    setAnchorEl(null);
  }, [setFilters]);

  const handleDropdownClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      isOpen ? close() : open(event);
    },
    [isOpen, close, open],
  );

  const handleSelectCompetition = useCallback(() => {
    setAutocompleteValue((autocompleteValue) => {
      setCompetitionOnChange(autocompleteValue);
      return autocompleteValue;
    });
    close();
  }, [close, setCompetitionOnChange]);

  const handleSetName = useCallback(
    (name: string) => {
      setFilters({ name: name });
    },
    [setFilters],
  );

  const renderOption = useCallback((props: React.ComponentProps<typeof ListItem>, option: Competition) => {
    return (
      <ListItem {...props} key={option.id}>
        <Box px={1} py={0.5}>
          {option.name}
        </Box>
      </ListItem>
    );
  }, []);

  const paperComponent = useCallback(
    (props: any) => (
      <SearchResults {...props} open={isOpen}>
        {props.children}
      </SearchResults>
    ),
    [isOpen],
  );

  const handleUpdateValue = useCallback(
    (item: Competition | null) => {
      if (item === null) return;
      setAutocompleteValue(item);
      setCompetitionOnChange(item);
      close();
    },
    [setAutocompleteValue, setCompetitionOnChange, close],
  );

  const isOptionEqual = useCallback((option: Competition, value: Competition) => option.id === value.id, []);
  const getOptionName = useCallback((option: Competition) => option.name, []);

  return (
    <Stack direction={'column'} spacing={1}>
      <Typography variant={'body1'}>Select competition</Typography>
      <Box>
        <AutocompleteDropdownButton
          isOpen={isOpen}
          onClick={handleDropdownClick}
          isSelected={Boolean(defaultSelectedCompetition)}
          label={defaultSelectedCompetition ? defaultSelectedCompetition.name : 'Select competition'}
        />
        {anchorEl && (
          <ClickAwayListener onClickAway={handleSelectCompetition}>
            <AutocompletePopper anchorEl={anchorEl} open={isOpen} placement='bottom-start'>
              <AutocompletePopperContent elevation={8}>
                <Autocomplete
                  autoFocus
                  PaperComponent={paperComponent}
                  PopperComponent={AutocompletePopperWrapper}
                  fetchNextPage={fetchNextPage}
                  getItemLabel={getOptionName}
                  inputWidth={AUTOCOMPLETE_WIDTH}
                  isLoading={isLoading}
                  isOptionEqualToValue={isOptionEqual}
                  listWidth={AUTOCOMPLETE_WIDTH}
                  multiple={false}
                  onChange={handleSetName}
                  open
                  options={competitionResults}
                  renderOption={renderOption}
                  placeholder={t('common:actions.search')}
                  resultsHeight={260}
                  updateValue={handleUpdateValue}
                  value={autocompleteValue}
                />
              </AutocompletePopperContent>
            </AutocompletePopper>
          </ClickAwayListener>
        )}
      </Box>
    </Stack>
  );
};
