import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { Team, TeamApiResponse } from 'shared/types/team/team';
import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';

export const transformTeams = (response: TeamApiResponse): InfinityQueryDataResult<Team> => {
  return {
    data: {
      items: response.content.map((team) => ({
        id: team.uuid,
        abbreviation: team.abbreviation ?? '',
        name: team.name,
        teamType: team.team_type,
        countryCode: team.country_code,
        logoUrl: team.logo_url,
        mappings: team.mappings,
        isFavorite: team.is_favorite ?? false,
        fixtures: team.fixtures.map((fixture) => ({
          id: fixture.uuid,
          name: fixture.name,
          duration: fixture.duration,
          homeTeamScore: fixture.home_team_score,
          awayTeamScore: fixture.away_team_score,
          date: new Date(fixture.date),
          pitchLength: fixture.pitch_length,
          pitchWidth: fixture.pitch_width,
        })),
      })),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
