import classNames from 'classnames';

import styles from 'pages/recording-playlists/components/empty-cell-grid/empty-cell/EmptyCell.module.scss';

interface Props {
  isLoading: boolean;
}
const EmptyCell = ({ isLoading }: Props) => {
  return <div className={classNames(styles.emptyCell, { [styles.isLoading]: isLoading })} />;
};

export default EmptyCell;
