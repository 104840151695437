import { QueryKey, useSuspenseQuery, UseSuspenseQueryResult } from '@tanstack/react-query';

import { callBackendApi, UseBackendApiParams } from 'api/hooks/callBackendApi';
import { HTTPMethod, UseApiFetchOptions } from 'api/types';
import { DEFAULT_QUERY_STALE_TIME } from 'shared/constants/queryDefaults';

interface UseApiFetchInterface<T> {
  queryRef: QueryKey;
  transformer: (response: any) => T;
  url: string;
  options?: UseApiFetchOptions;
  data?: UseBackendApiParams;
  method?: HTTPMethod.GET | HTTPMethod.POST;
}

export const useFetchSuspenseRequest = <T>({
  queryRef,
  url,
  options,
  transformer,
  data,
  method = HTTPMethod.GET,
}: UseApiFetchInterface<T>): UseSuspenseQueryResult<T> => {
  return useSuspenseQuery<T>({
    queryKey: queryRef,
    queryFn: () => callBackendApi(url, method, transformer, data),
    staleTime: DEFAULT_QUERY_STALE_TIME,
    ...options,
  });
};
