import { ErrorBoundary } from '@sentry/react';
import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';

import { ErrorContainerFeature } from 'features/error-container/ErrorContainer.feature';
import { appRouter } from 'kognia/router/appRouter';
import { AppTheme } from 'kognia/theme';
import AppContext from 'shared/contexts/app-context';
import { useSubscribeEvents } from 'shared/hooks/use-subscribe-events';
import './App.scss';
import 'kognia/i18n';

function App(): JSX.Element {
  useSubscribeEvents();

  return (
    <ErrorBoundary
      fallback={
        <Suspense>
          <ErrorContainerFeature />
        </Suspense>
      }
    >
      <AppTheme>
        <AppContext>
          <RouterProvider router={appRouter} />
        </AppContext>
      </AppTheme>
    </ErrorBoundary>
  );
}

export default App;
