export interface FiltersKeyValuesHash {
  [key: string]: string[];
}

export interface FilterOption {
  title: string;
  isApplied?: boolean;
  options?: FilterOptions;
}

export interface FilterOptions {
  [key: string]: FilterOption;
}

export interface Filter {
  title: string;
  options: FilterOptions;
}

export type FiltersList = Record<string, Filter>;

export enum SortDirection {
  DESC = 'desc',
  ASC = 'asc',
}

export type FiltersFromUrl<T> = { [K in keyof T]: string };
