import { Box } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { PropsWithChildren } from 'react';

import { BASIC_ROW_HEIGHT } from 'pages/tactical-analysis/components/timeline/timeline-table/components/TimelineTable.styled';

export const RowHeaderTotal = ({ children }: PropsWithChildren) => (
  <Box
    sx={({ palette, spacing }) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 22,
      minWidth: 22,
      marginLeft: spacing(1),
      color: palette.secondary.main,
      fontSize: fontSizes.small,
      lineHeight: BASIC_ROW_HEIGHT,
      userSelect: 'none',
    })}
  >
    {children}
  </Box>
);
