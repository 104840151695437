import { invalidateClients } from 'api/backoffice/client/hooks/use-clients/useClients';
import { BrandingApiResponse, BrandingRequestData } from 'api/backoffice/client/types/brandingRequestData';
import { transformClient } from 'api/backoffice/client/utils/transformClient';
import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { clientBrandingUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { Client } from 'shared/types';

export const useEditBranding = () => {
  const { mutate, isPending, isError, isSuccess } = useMutationRequest<BrandingApiResponse, Client>({
    type: HTTPMethod.PATCH,
    errorMessage: 'Error editing a branding',
    successMessage: 'Branding edited successfully',
    transformer: transformClient,
  });

  const edit = ({
    data,
    onSuccess,
    clientId,
  }: {
    data: Partial<BrandingRequestData>;
    onSuccess?: (res: Client) => void;
    clientId: string;
  }) => {
    const formData = new FormData();
    if (data.displayName) formData.append('displayName', new Blob([data.displayName], { type: 'application/json' }));
    if (data.favicon?.[0]) formData.append('favicon', data.favicon[0]);
    if (data.emailLogo?.[0]) formData.append('emailLogo', data.emailLogo[0]);
    if (data.navbarLogo?.[0]) formData.append('navbarLogo', data.navbarLogo[0]);
    if (data.navbarCollapsedLogo?.[0]) {
      formData.append('navbarCollapsedLogo', data.navbarCollapsedLogo[0]);
    }
    if (data.homePageLogo?.[0]) formData.append('homePageLogo', data.homePageLogo[0]);

    mutate(
      { url: clientBrandingUrl(clientId), data: formData },
      {
        onSuccess: async (res) => {
          await invalidateClients();
          if (onSuccess) onSuccess(res);
        },
      },
    );
  };

  return { edit, isPending, isError, isSuccess };
};
