import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { timelineMatchAtoms } from 'pages/tactical-analysis/atoms';
import { generatePlaylistItemFromEpisodes } from 'pages/tactical-analysis/components/tactical-analysis/utils/generate-timeline-playlist';
import { useTacticalAnalysisEpisodes } from 'pages/tactical-analysis/hooks/use-tactical-analysis-episodes';
import { useTacticalAnalysisMatch } from 'pages/tactical-analysis/hooks/use-tactical-analysis-match';
import { useTimelineTactics } from 'pages/tactical-analysis/hooks/use-timeline-tactics';
import { useCurrentPlaylistItem } from 'shared/components/video-player';
import { PlayingMode } from 'shared/components/video-player/types';

export const useGenerateTimelinePlaylist = (recordingId: string) => {
  const episodes = useTacticalAnalysisEpisodes(recordingId);
  const match = useTacticalAnalysisMatch(recordingId);
  const timelineVideoSource = useRecoilValue(timelineMatchAtoms.matchVideoSource(recordingId));
  const { selectedTactics: fundamentalsSelected } = useTimelineTactics(recordingId);
  const currentPlaylistItem = useCurrentPlaylistItem();

  return useCallback(
    (playingMode: PlayingMode) =>
      generatePlaylistItemFromEpisodes({
        episodes,
        fullVideoSourceDuration: match.defaultVideoSource.duration,
        tacticalCameraVideo: timelineVideoSource,
        playingMode,
        recordingId,
        fundamentalsSelected,
        hasHomographies: currentPlaylistItem.hasHomographies,
        showOverlays: currentPlaylistItem.showOverlays,
      }),
    [
      timelineVideoSource,
      currentPlaylistItem.hasHomographies,
      currentPlaylistItem.showOverlays,
      match,
      episodes,
      recordingId,
      fundamentalsSelected,
    ],
  );
};
