import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { useSaveUserPreset } from 'api/user-presets/use-save-user-preset';
import { useTacticalAnalysisData } from 'pages/tactical-analysis/api/use-tactical-analysis-data';
import { useGenerateTimelinePlaylist } from 'pages/tactical-analysis/hooks/use-generate-timeline-playlist';
import { useSetPresetPlayingMode } from 'pages/tactical-analysis/hooks/use-preset-playing-mode';
import { useVideoPlayerActions, useVideoPlayerPlayingMode, useVideoPlayerState } from 'shared/components/video-player';
import {
  EPISODES_PLAYING_MODE,
  TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE,
} from 'shared/components/video-player/defaultPlayingModes';
import { PlayingMode, PlayingModes } from 'shared/components/video-player/types';
import { TimelinePlayingModePreset } from 'shared/constants/user-presets/userPresetsClasses';
import { invariant } from 'shared/utils/invariant';

interface ValidatePlayingModeParams {
  playingMode: PlayingMode;
  hasEpisodesSources: boolean;
  hasHomographies: boolean;
}

const validatePlayingMode = ({
  playingMode,
  hasEpisodesSources,
  hasHomographies,
}: ValidatePlayingModeParams): PlayingMode => {
  if (!hasHomographies && hasEpisodesSources) {
    if (playingMode.mode === PlayingModes.EPISODES) {
      return TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE;
    }

    return EPISODES_PLAYING_MODE;
  }

  return playingMode;
};

export const useTimelinePlayingMode = (recordingId: string) => {
  const actions = useVideoPlayerActions();
  const videoPlayerState = useVideoPlayerState();
  const setPresetPlayingMode = useSetPresetPlayingMode(recordingId);
  const videoPlayerPlayingMode = useVideoPlayerPlayingMode();
  const generateTimelinePlaylist = useGenerateTimelinePlaylist(recordingId);

  const { id } = useParams();
  invariant(id);
  const { data } = useTacticalAnalysisData(id);
  const recordingEpisodes = data.matchWithEpisodes?.episodes;
  const recordingMatch = data.matchWithEpisodes?.match;
  const defaultVideoSource = data.matchWithEpisodes?.match.defaultVideoSource;
  const hasEpisodes = recordingEpisodes && recordingEpisodes.length > 0;

  const { savePreset } = useSaveUserPreset();

  const handleSetPlayingMode = useCallback(
    (playingMode: PlayingMode) => {
      if (!videoPlayerState.readyState) return;
      if (!recordingMatch || !hasEpisodes || !defaultVideoSource) return;

      const hasEpisodesSources = hasEpisodes && recordingEpisodes.some((item) => !!item.dashUrl);

      const validatedPlayingMode = validatePlayingMode({
        playingMode,
        hasEpisodesSources: hasEpisodesSources,
        hasHomographies: recordingMatch.hasHomographies,
      });

      const playingModePreset = new TimelinePlayingModePreset(validatedPlayingMode, recordingId);
      savePreset({ data: playingModePreset });

      const playlistItems = generateTimelinePlaylist(validatedPlayingMode);

      setPresetPlayingMode(validatedPlayingMode);
      actions.setPlaylist(playlistItems, validatedPlayingMode, true, true);
    },
    [
      videoPlayerState.readyState,
      recordingMatch,
      hasEpisodes,
      defaultVideoSource,
      recordingEpisodes,
      recordingId,
      savePreset,
      generateTimelinePlaylist,
      setPresetPlayingMode,
      actions,
    ],
  );

  return {
    playingMode: videoPlayerPlayingMode,
    setPlayingMode: handleSetPlayingMode,
  };
};
