import { Subject, bufferTime, filter, map, merge } from 'rxjs';

import { Action, ActionTypes, MetricsAction, OverlaysFrameRateAction } from 'shared/streams/actionTypes';
import { MetricsNames } from 'shared/types/metrics';

const eventEmitter = new Subject<Action>();
const frameRateEmitter = new Subject<OverlaysFrameRateAction>();

export function publishEvent(action: Action) {
  eventEmitter.next(action);
}

export function publishFrameRateEvent(action: OverlaysFrameRateAction) {
  frameRateEmitter.next(action);
}

const OVERLAYS_FRAME_RATE_BUFFER_TIME = 5000;

const metrics$ = eventEmitter.pipe(
  filter(
    (action) =>
      action.type === ActionTypes.OPEN_RECORDINGS_FILTER ||
      action.type === ActionTypes.VIEW_PAGE ||
      action.type === ActionTypes.APPLY_RECORDINGS_FILTER ||
      action.type === ActionTypes.OPEN_TACTICAL_ANALYSIS_FILTER ||
      action.type === ActionTypes.APPLY_TACTICAL_ANALYSIS_FILTER ||
      action.type === ActionTypes.PLAY_TACTICAL_ANALYSIS_ROW ||
      action.type === ActionTypes.PLAY_TACTICAL_ANALYSIS_SELECTED_CLIPS ||
      action.type === ActionTypes.OPEN_TACTICAL_ANALYSIS_EXPORT_XML ||
      action.type === ActionTypes.EXPORT_TACTICAL_ANALYSIS_XML ||
      action.type === ActionTypes.OPEN_TACTICAL_ANALYSIS_EXPORT_VIDEO ||
      action.type === ActionTypes.EXPORT_TACTICAL_ANALYSIS_VIDEO ||
      action.type === ActionTypes.CHANGE_TACTICAL_ANALYSIS_ZOOM ||
      action.type === ActionTypes.UPDATE_FAVORITES ||
      action.type === ActionTypes.NAVIGATE_TO_FAVORITE,
  ),
  map(
    (action) =>
      <MetricsAction>{
        type: ActionTypes.SEND_METRICS,
        payload: action.payload,
      },
  ),
);

const overlaysFrameRate$ = frameRateEmitter.pipe(
  filter((action) => action.type === ActionTypes.LOG_OVERLAYS_FRAME_RATE),
  bufferTime(OVERLAYS_FRAME_RATE_BUFFER_TIME),
  filter((actions) => actions.length > 0),
  map((actions) => {
    const actionsFrameRateSum = actions.reduce<number>((acc, action) => {
      return acc + action.payload;
    }, 0);

    const averageFrameRate = Math.round(actionsFrameRateSum / actions.length);

    return <MetricsAction>{
      type: ActionTypes.SEND_METRICS,
      payload: {
        name: MetricsNames.OVERLAYS_AVERAGE_FRAME_RATE,
        data: { value: String(averageFrameRate) },
      },
    };
  }),
);

export const applicationEvents$ = merge(overlaysFrameRate$, metrics$);
