import classnames from 'classnames';

import { RBChecked, RBDisabled, RBUnchecked } from 'shared/components/atomize-icons';
import styles from 'shared/components/radio-button/RadioButton.module.scss';

type RadioProps = {
  displayValue: string;
  id: string;
  checked?: boolean;
  disabled?: boolean;
  onSelect?: (id: string) => void;
  value?: any;
};

const getLabelClass = (checked?: boolean, disabled?: boolean) => {
  if (disabled) return classnames(styles.radioLabel, styles.radioLabelDisabled);
  if (checked) return classnames(styles.radioLabel, styles.radioLabelChecked);
  return styles.radioLabel;
};

export const RadioButton = ({ displayValue, disabled, id, checked, onSelect }: RadioProps) => {
  const labelClass = getLabelClass(checked, disabled);
  const RadioButtonIcon = () => {
    if (disabled) return <RBDisabled className={classnames(styles.radioIcon, styles.radioIconDisabled)} />;
    if (checked) return <RBChecked className={classnames(styles.radioIcon, styles.radioIconChecked)} />;
    return <RBUnchecked className={styles.radioIcon} />;
  };

  const handleSelect = () => {
    if (disabled) return;
    onSelect && onSelect(id);
  };

  return (
    <label className={labelClass} htmlFor={id} onClick={handleSelect}>
      <input id={id} type='radio' className={styles.radio} disabled={disabled} />
      <RadioButtonIcon />
      <span className={styles.radioContent}>{displayValue}</span>
    </label>
  );
};

export default RadioButton;
