import { styled, Typography } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';

export type TypeCounterBackgroundColorType = Colors | 'none';
export type TypeCounterColorType = Colors | 'initial';

export interface TypeCounterContainerProps {
  color: TypeCounterColorType;
  backgroundColor: TypeCounterBackgroundColorType;
  disabled: boolean;
}

export const TypeCounterContainer = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'color' && prop !== 'backgroundColor' && prop !== 'disabled',
})<TypeCounterContainerProps>(({ theme, color, backgroundColor, disabled }) => ({
  minWidth: '40px',
  textAlign: 'center',
  padding: theme.spacing(0, 1),
  background: backgroundColor,
  color: color,
  fontWeight: fontWeight['500'],
  fontSize: fontSizes.small,
  lineHeight: 2,
  ...(disabled && {
    color: Colors.storm,
    background: Colors.background,
  }),
}));
