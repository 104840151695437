import { useTranslation } from 'react-i18next';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { transformPlaylist } from 'api/playlist/transformers';
import { PlaylistApiResponse, UpdateParams } from 'api/playlist/types';
import { updatePlaylistItemUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { Playlist } from 'shared/types';

export const useUpdatePlaylistItem = (playlistId: string, onSuccess?: (playlist: Playlist) => void) => {
  const { t } = useTranslation();
  const { mutate, isPending, isError, isSuccess } = useMutationRequest<PlaylistApiResponse, Playlist>({
    transformer: transformPlaylist,
    type: HTTPMethod.PATCH,
    errorMessage: t('api:use-update-playlist-item.error'),
    onSuccess: async (playlist: Playlist) => {
      onSuccess && onSuccess(playlist);
    },
  });

  const updatePlaylistItem = (
    playlistItemId: string,
    values: UpdateParams,
    onSuccess?: (playlist: Playlist) => void,
  ) => {
    mutate({ url: updatePlaylistItemUrl(playlistId, playlistItemId), data: values }, { onSuccess });
  };

  return { updatePlaylistItem, isPending, isError, isSuccess };
};
