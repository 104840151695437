import classnames from 'classnames';
import React, { useLayoutEffect, useRef } from 'react';
import 'shared/components/input/index.scss';

export type Props = React.HTMLProps<HTMLInputElement> & {
  id: string;
  label?: string;
  type?: string;
  suffix?: JSX.Element;
  autoSelect?: boolean;
  fullWidth?: boolean;
};

const Input = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { type = 'text', id, label, suffix, autoSelect, ...rest } = props;

  useLayoutEffect(() => {
    if (autoSelect) {
      inputRef?.current?.select();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!label)
    return (
      <>
        <input ref={inputRef} className={classnames('input', `input--${type}`)} id={id} type={type} {...rest} />
        {suffix ? <span className='input__suffix'>{suffix}</span> : null}
      </>
    );

  return (
    <label
      htmlFor={id}
      className={classnames('input-label', `input-label--${type}`, { 'input-label--fullwidth': props.fullWidth })}
    >
      <span className='input-label__text'>{label}</span>
      <input ref={inputRef} className={classnames('input', `input--${type}`)} id={id} type={type} {...rest} />
      {suffix ? <span className='input__suffix'>{suffix}</span> : null}
    </label>
  );
};

export default Input;
