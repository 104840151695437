import { array, object, string } from 'zod';

import { localeSchema } from 'api/backoffice/user/use-kognia-users/types';
import { UserFormFieldsNames } from 'pages/backoffice/features/users/user-form/utils/userFormFieldNames';

export const userFormSchema = object({
  [UserFormFieldsNames.FIRST_NAME]: string().trim().min(2),
  [UserFormFieldsNames.LAST_NAME]: string().trim().min(2),
  [UserFormFieldsNames.CLIENT_IDS]: array(string()).min(1),
  [UserFormFieldsNames.EMAIL]: string().email(),
  [UserFormFieldsNames.LOCALE]: localeSchema,
});
