import { Grid } from '@mui/material';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const ListContainer = ({ children, className = '' }: Props) => {
  return (
    <Grid className={className} container spacing={1} direction={'column'} wrap={'nowrap'}>
      {children}
    </Grid>
  );
};
