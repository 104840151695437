import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { transformPlaylistItem } from 'api/playlist/transformers';
import { PlaylistItemApiResponse } from 'api/playlist/types';
import { TacticalAnalysesPlaylistItemsResponse } from 'api/recording/useEpisodesWithFilters/types';
import { transformFiltersForRequest } from 'api/recording/utils';
import { tacticalAnalysesAsItems } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { PlaylistItemWithoutVideoSources } from 'shared/types/playlist/types';
import {
  RecordingFiltersScenariosOrTacticsInside,
  RecordingFiltersTactic,
  RecordingFiltersTactics,
  RecordingsFilters,
} from 'shared/types/recording/types';
import { TacticsVariants } from 'shared/types/tactics/types';

export const TIMELINE_DEFAULT_FILTERS = {
  teams: [],
  eventsStarting: new Set([]),
  eventsEnding: new Set([]),
  scenarios: [],
  tactics: { offensive: [], defensive: [] },
};
export const defaultRecordingsFiltersEvents = { teams: [], event: '', zones: [], players: [] };
export const defaultRecordingsFiltersTactic = (category: TacticsVariants): RecordingFiltersTactic => ({
  tacticalFundamentalType: '',
  teamIds: [],
  playerIds: [],
  category,
});

export const defaultRecordingsFiltersTactics: RecordingFiltersTactics = {
  offensive: [defaultRecordingsFiltersTactic(TacticsVariants.OFFENSIVE)],
  defensive: [defaultRecordingsFiltersTactic(TacticsVariants.DEFENSIVE)],
};

export const defaultRecordingFiltersScenariosOrTacticsInside: RecordingFiltersScenariosOrTacticsInside = {
  teams: [],
  zones: [],
  scenario: '',
  tactics: defaultRecordingsFiltersTactics,
};

export const useTacticalAnalysesPlaylistItems = (): TacticalAnalysesPlaylistItemsResponse => {
  const { t } = useTranslation();

  const { mutate, isPending, isError, isSuccess, data } = useMutationRequest<
    PlaylistItemApiResponse[],
    PlaylistItemWithoutVideoSources[]
  >({
    type: HTTPMethod.POST,
    errorMessage: t('api:use-tactical-analysis-filter.error'),
    transformer: (data: PlaylistItemApiResponse[]): PlaylistItemWithoutVideoSources[] => {
      return data.map((playlistItem) => transformPlaylistItem(playlistItem));
    },
  });

  const getFilteredItems = useCallback(
    (params: RecordingsFilters) => {
      mutate({ url: tacticalAnalysesAsItems, data: transformFiltersForRequest(params) });
    },
    [mutate],
  );

  return { getFilteredItems, isPending, isError, isSuccess, data: !data ? [] : data };
};
