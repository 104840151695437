import { Box, styled } from '@mui/material';
import { fontSizes } from 'kognia-ui';

import { CSS_TIMELINE_INNER_WIDTH } from 'pages/tactical-analysis/components/timeline/timeline-table/components/timeline-css-variables';

export const RowsContentHeader = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'isShadow',
})<{ isShadow: boolean }>(({ theme, isShadow }) => ({
  position: 'sticky',
  top: 0,
  zIndex: 2,
  minWidth: `var(${CSS_TIMELINE_INNER_WIDTH})`,
  width: '100%',
  background: theme.palette.common.white,
  fontSize: fontSizes.small,
  // TODO use from theme
  lineHeight: 1.67,
  overflow: 'visible',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',

  ...(isShadow && {
    zIndex: 1,
    // TODO use from theme
    boxShadow: `0 3px 3px -1px rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06)`,
  }),
}));
