import { QueryClientProvider, QueryKey } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ReactNode, useEffect } from 'react';

import { queryClient } from 'api/config';
import { useClientId } from 'shared/contexts/app-state/hooks/useClientId';

const createCacheKey = (clientId: string | undefined) => (queryKey: QueryKey) => {
  return JSON.stringify(clientId ? [...queryKey, clientId] : queryKey);
};

export const ApiQueryProvider = ({ children }: { children?: ReactNode }) => {
  const { clientId } = useClientId();

  useEffect(() => {
    queryClient.setQueryDefaults([], {
      queryKeyHashFn: createCacheKey(clientId),
    });
    queryClient.invalidateQueries();
  }, [clientId]);

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      {children}
    </QueryClientProvider>
  );
};
