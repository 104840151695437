import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { DeleteEpisodeOverlaysApiParams } from 'pages/backoffice/api/types';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';
import { getQueryParams } from 'pages/backoffice/utils/get-query-params';

interface DeleteEpisodeOverlaysParams {
  data: DeleteEpisodeOverlaysApiParams;
}

export const useDeleteEpisodeOverlays = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Delete episode overlays success',
    errorMessage: (data) => metadataApiErrorParser(data, 'Delete episode overlays error'),
  });

  const deleteEpisodeOverlays = ({ data }: DeleteEpisodeOverlaysParams) => {
    const queryParams = getQueryParams<DeleteEpisodeOverlaysApiParams>(data);
    if (!queryParams) return;
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate({ url: `${annotationApiUrls.DELETE_EPISODE_OVERLAYS}?${queryParams}` });
  };

  return { ...mutationRequest, deleteEpisodeOverlays };
};
