import { useCallback, useMemo } from 'react';

import { useRedirectTo } from 'shared/components/sidebar-layout/sidebar-menu/hooks/use-redirect-to';
import { SidebarMenuItem } from 'shared/components/sidebar-layout/sidebar-menu/sidebar-menu-item';
import { SidebarMenuItemTitle } from 'shared/components/sidebar-layout/sidebar-menu/sidebar-menu-item-title';
import { SidebarMenuItemWithOptions } from 'shared/components/sidebar-layout/sidebar-menu/sidebar-menu-item-with-options';
import { Menu, MenuItem } from 'shared/components/sidebar-layout/sidebar-menu/styled';
import { useIsUserAdmin } from 'shared/contexts/app-state/hooks/useIsUserAdmin';

export enum MenuItemType {
  LINK = 'link',
  CUSTOM_CLICK = 'custom_click',
  PARENT = 'parent',
  TITLE = 'title',
}
interface MenuItemBase {
  label: string;
  isAdminAccess?: boolean;
  isDisabled?: boolean;
  isHidden?: boolean;
  icon?: JSX.Element;
  bottomDivider?: boolean;
}

export type MenuItemTitle = MenuItemBase & {
  type: MenuItemType.TITLE;
};

export interface CustomSidebarItemLinkComponentProps {
  isSidebarOpen: boolean;
  item: Omit<MenuItemLink, 'CustomComponent'>;
  onClick: () => void;
}

export interface MenuItemLink extends MenuItemBase {
  link: string;
  type: MenuItemType.LINK;
  CustomComponent?: React.FC<CustomSidebarItemLinkComponentProps>;
}

export interface MenuItemCustomClick extends MenuItemBase {
  onClick: () => void;
  type: MenuItemType.CUSTOM_CLICK;
}

export type MenuSubmenuItem = MenuItemLink | MenuItemCustomClick;

export interface MenuItemParent extends MenuItemBase {
  items: MenuSubmenuItem[];
  type: MenuItemType.PARENT;
}

export type MenuItem = MenuItemLink | MenuItemCustomClick | MenuItemParent | MenuItemTitle;

interface Props {
  menuItems: MenuItem[];
  isSidebarOpen: boolean;
}

export const SidebarMenu = ({ menuItems, isSidebarOpen }: Props) => {
  const isAdmin = useIsUserAdmin();
  const redirectTo = useRedirectTo();

  const allowedMenuItems = useMemo(
    () => menuItems.filter((item) => !item.isAdminAccess || (item.isAdminAccess && isAdmin)),
    [isAdmin, menuItems],
  );

  const handleItemClick = useCallback(
    (item: MenuItem) => {
      if (item.type === MenuItemType.LINK) {
        redirectTo(item.link);
      } else if (item.type === MenuItemType.CUSTOM_CLICK) {
        item.onClick();
      }
    },
    [redirectTo],
  );

  return (
    <Menu>
      {allowedMenuItems.map((item, index) => {
        if (item.isHidden) return null;
        if (item.type === MenuItemType.TITLE)
          return (
            <MenuItem key={`${item.label}_${index}`}>
              <SidebarMenuItemTitle item={item} isSidebarOpen={isSidebarOpen} />
            </MenuItem>
          );

        if (item.type === MenuItemType.PARENT) {
          return (
            <MenuItem key={`${item.label}_${index}`}>
              <SidebarMenuItemWithOptions isSidebarOpen={isSidebarOpen} item={item} />
            </MenuItem>
          );
        }

        if (item.type === MenuItemType.LINK) {
          const { CustomComponent, ...restItemProperties } = item;
          const Component = CustomComponent ?? SidebarMenuItem;

          return (
            <MenuItem key={`${item.label}_${index}`}>
              <Component
                item={restItemProperties}
                onClick={() => handleItemClick(item)}
                isSidebarOpen={isSidebarOpen}
              />
            </MenuItem>
          );
        }

        return (
          <MenuItem key={`${item.label}_${index}`}>
            <SidebarMenuItem item={item} onClick={() => handleItemClick(item)} isSidebarOpen={isSidebarOpen} />
          </MenuItem>
        );
      })}
    </Menu>
  );
};
