import { array, boolean, object, string, TypeOf, z } from 'zod';

import { Locales } from 'kognia/i18n/types';
import { LinkApiResponse } from 'shared/types/link/link';
import { Pagination } from 'shared/types/pagination/types';

export type KogniaUsersFilters = {
  firstName?: string;
  emails?: string[];
};

export const localeSchema = z.nativeEnum(Locales);

export const kogniaUserSchema = object({
  id: string(),
  clientIds: array(string()),
  firstName: string(),
  lastName: string(),
  email: string(),
  phoneAreaCode: string().nullable(),
  phoneNumber: string().nullable(),
  avatarUrl: string().nullable(),
  role: string().nullable(),
  locale: localeSchema,
  createdAt: string(),
  updatedAt: string(),
  isMigratedToAuth0: boolean(),
  links: array(
    object({
      rel: string(),
      href: string(),
      hreflang: string().nullable(),
      media: string().nullable(),
      title: string().nullable(),
      type: string().nullable(),
      deprecation: string().nullable(),
      profile: string().nullable(),
      name: string().nullable(),
    }),
  ),
});

export type KogniaUser = TypeOf<typeof kogniaUserSchema>;
export type KogniaUseLocale = TypeOf<typeof localeSchema>;

export interface UsersApiResponse {
  links: LinkApiResponse[];
  content: KogniaUser[];
  page: Pagination;
}

export const createKogniaUserSchema = object({
  clientIds: array(string()).min(1),
  firstName: string().min(2),
  lastName: string().min(2),
  email: string().email(),
  locale: localeSchema,
});

export type CreateKogniaUser = TypeOf<typeof createKogniaUserSchema>;
