import {
  PANORAMIC_PLAYING_MODE,
  TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE,
} from 'shared/components/video-player/defaultPlayingModes';
import { VideoSourceType } from 'shared/components/video-player/types';
import { VideoSource } from 'shared/types/recording/types';
import { getVideoSources } from 'shared/utils/get-video-sources';

interface Options {
  videoSources: VideoSource[];
  startTime?: number;
  endTime?: number;
}

export const generateTaggingVideoTypes = ({ videoSources, startTime = 0, endTime = 0 }: Options): VideoSourceType[] => {
  const sources = getVideoSources(videoSources);

  const hasTacticalCameraVideo = Boolean(sources?.tacticalCameraVideo?.src);
  const hasUploadedVideo = Boolean(sources?.uploadedVideo?.src);
  const hasPanoramaVideo = Boolean(sources?.panoramicVideo?.src);

  return [
    ...(hasUploadedVideo
      ? [
          {
            playingMode: TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE,
            videoSources: [
              {
                startTime,
                endTime,
                src: sources.uploadedVideo.src,
                id: sources.uploadedVideo.id,
              },
            ],
          },
        ]
      : []),
    ...(hasTacticalCameraVideo
      ? [
          {
            playingMode: TACTICAL_CAMERA_WITHOUT_OVERLAYS_PLAYING_MODE,
            videoSources: [
              {
                startTime,
                endTime,
                src: sources.tacticalCameraVideo.src,
                id: sources.tacticalCameraVideo.id,
              },
            ],
          },
        ]
      : []),
    ...(hasPanoramaVideo
      ? [
          {
            playingMode: PANORAMIC_PLAYING_MODE,
            videoSources: [
              {
                startTime,
                endTime,
                src: sources.panoramicVideo.src,
                id: sources.panoramicVideo.id,
              },
            ],
          },
        ]
      : []),
  ];
};
