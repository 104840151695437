import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { useCompetitionsState } from 'entities/competitions/store/steps';
import { useTeamWithDefaults } from 'entities/dashboard/api/useTeamWithDefaults';
import { getPlayerPositionFromValue } from 'entities/dashboard/types/dashboard';
import { BodyTableRow } from 'features/competitions/players-step/ui/BodyTableRow';
import { HeaderTableCell } from 'features/competitions/players-step/ui/HeaderTableCell';
import { NameRow } from 'features/competitions/players-step/ui/name-row/NameRow';
import { routes } from 'kognia/router/routes';
import { invariant } from 'shared/utils/invariant';

export const PlayersStepFeature = () => {
  const { team } = useCompetitionsState();
  invariant(team, 'team is undefined in PlayersStepFeature');
  const { data: teamData } = useTeamWithDefaults(team.id);
  const { t } = useTranslation(['explore', 'player']);
  const navigate = useNavigate();

  const rows = useMemo(() => {
    if (!teamData?.players?.length) {
      return [];
    }

    return teamData?.players.map(({ id, position, name, photoUrl }) => {
      return {
        id,
        name,
        photoUrl,
        position,
      };
    });
  }, [teamData?.players]);

  const goToPlayerProfile = (playerId: string) => {
    navigate(generatePath(routes.PLAYER_PROFILE, { playerId }));
  };

  if (!rows.length) {
    return null;
  }

  return (
    <TableContainer
      sx={(theme) => ({
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
      })}
    >
      <Table aria-label='table'>
        <TableHead>
          <TableRow>
            <HeaderTableCell>{t('explore:competitions.name')}</HeaderTableCell>
            <HeaderTableCell align='center'>{t('explore:competitions.position')}</HeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map(({ id, name, photoUrl, position }) => (
            <BodyTableRow
              key={id}
              tabIndex={0}
              onClick={() => goToPlayerProfile(id)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  goToPlayerProfile(id);
                }
              }}
            >
              <TableCell>
                <NameRow name={name} photoUrl={photoUrl} />
              </TableCell>
              <TableCell align='center'>
                <Typography variant='caption' color='secondary' component='span'>
                  {t(`player:position.${getPlayerPositionFromValue(position)}`)}
                </Typography>
              </TableCell>
            </BodyTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
