import { sort } from 'fast-sort';

import { KeypadTag } from 'api/tagging-tool/types';
import { KeypadIndex } from 'tagging-tool/model/Keypad';

export const HOTKEY_SPLIT_KEY_PRINTABLE = '+';
export const HOTKEY_SPLIT_KEY = '$';

export type HotKey = Array<string>;

export const hotKeyToString = (hotKey: HotKey): string => {
  return hotKey.join(HOTKEY_SPLIT_KEY);
};

export const hotKeyToPrintableString = (value: string): string => {
  return value
    .split(HOTKEY_SPLIT_KEY)
    .map((value) => {
      return `${value.charAt(0).toUpperCase()}${value.substring(1, value.length)}`;
    })
    .join(` ${HOTKEY_SPLIT_KEY_PRINTABLE} `);
};

export const keyboardEventToKey = (ev: KeyboardEvent): string | undefined => {
  const { code } = ev;
  let key: string | undefined;
  if (code.indexOf('Key') === 0) {
    // letters
    key = code.substring(3, code.length).toUpperCase();
  } else if (code.indexOf('Digit') === 0) {
    // numbers
    key = code.substring(5, code.length).toLowerCase();
  } else if (code.indexOf('Arrow') === 0) {
    // arrow keys
    key = code.substring(5, code.length).toLowerCase();
  } else if (code.indexOf('Shift') === 0) {
    // shift
    key = 'shift';
  } else if (code.indexOf('Meta') === 0) {
    // command
    key = 'cmd';
  } else if (code.indexOf('Control') === 0) {
    // control
    key = 'ctrl';
  } else if (code.indexOf('F') === 0 && code.length === 2) {
    // function keys
    key = code.toUpperCase();
  } else if (code.indexOf('Esc') === 0) {
    // esc
    key = 'esc';
  } else if (code.indexOf('Alt') === 0) {
    // alt
    key = 'alt';
  }

  return key;
};

export type KeypadTagHotKeyResolver = {
  resolve: (hotKey: string | null | undefined) => KeypadTag | undefined;
};

export const makeHotKeyResolver = (keypadData: KeypadIndex): KeypadTagHotKeyResolver => {
  type Index = { [indexKey: string]: KeypadTag };

  // we need to somehow normalize hotkeys, eg: shift+a = a+shift
  // we're going to do that sorting strings
  const hotKeyToIndexKey = (hotkey: string) => {
    return sort(hotkey.split(HOTKEY_SPLIT_KEY)).asc().join();
  };

  const index: Index = Object.values(keypadData)
    .filter((tag: KeypadTag) => {
      // remove tags w/out hotkey
      return tag.hotKey !== undefined && tag.hotKey !== null;
    })
    .reduce((acc: Index, tag: KeypadTag) => {
      const { hotKey } = tag;
      const indexKey = hotKeyToIndexKey(hotKey!);
      return { ...acc, [indexKey]: tag };
    }, {} as Index);

  return {
    resolve: (hotKey) => {
      if (hotKey === null || hotKey === undefined) {
        return undefined;
      }
      return index[hotKeyToIndexKey(hotKey)];
    },
  };
};
