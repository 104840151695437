import { ZOOM_LEVELS } from 'shared/constants/zoom-range/zoomLevelsValues';
import { ZoomLevelsType } from 'shared/types/zoom-range/zoomLevels';

export const getTimeZoomLevelAdjustment = (zoomLevel: ZoomLevelsType) => {
  const zoomLevelRatio = {
    [ZOOM_LEVELS.extraLarge]: 0.2,
    [ZOOM_LEVELS.large]: 0.6,
    [ZOOM_LEVELS.mediumLarge]: 1,
    [ZOOM_LEVELS.medium]: 4,
    [ZOOM_LEVELS.mediumSmall]: 8,
    [ZOOM_LEVELS.small]: 12,
    [ZOOM_LEVELS.extraSmall]: 16,
  };

  return zoomLevelRatio[zoomLevel];
};

export const getBlockSizeByZoomLevel = (zoomLevel: ZoomLevelsType) => {
  const zoomLevelRatio = {
    [ZOOM_LEVELS.extraLarge]: 40,
    [ZOOM_LEVELS.large]: 60,
    [ZOOM_LEVELS.mediumLarge]: 80,
    [ZOOM_LEVELS.medium]: 100,
    [ZOOM_LEVELS.mediumSmall]: 120,
    [ZOOM_LEVELS.small]: 140,
    [ZOOM_LEVELS.extraSmall]: 180,
  };

  return zoomLevelRatio[zoomLevel];
};

export const adjustTimeSpaceByZoomLevel = (time: number, zoomLevel: ZoomLevelsType) =>
  time * getTimeZoomLevelAdjustment(zoomLevel);
