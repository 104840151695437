import React, { RefObject, useCallback } from 'react';

import { useSaveUserPreset } from 'api/user-presets/use-save-user-preset';
import { HEADER_MIN_WIDTH } from 'pages/tactical-analysis/atoms';
import ResizeBar from 'pages/tactical-analysis/components/tactical-analysis/resize-bar';
import styles from 'pages/tactical-analysis/components/timeline/timeline-table/components/row-headers-resize-bar/RowHeadersResizeBar.module.scss';
import {
  useSetTimelineHeadersWidth,
  useTimelineHeadersWidth,
} from 'pages/tactical-analysis/hooks/use-timeline-headers-width';
import { TimelineHeadersWidthPreset } from 'shared/constants/user-presets/userPresetsClasses';

type Props = {
  headerContentDiv: RefObject<HTMLDivElement>;
  contentDiv: RefObject<HTMLDivElement>;
};

const TIMELINE_TABLE_MIN_WIDTH = 224;

export const RowHeadersResizeBar = ({ headerContentDiv, contentDiv }: Props) => {
  const { savePreset } = useSaveUserPreset();
  const headersWidth = useTimelineHeadersWidth();
  const setTimelineHeadersWidth = useSetTimelineHeadersWidth();

  const handleSaveHeaderWidth = useCallback(
    (width: number) => {
      if (headersWidth === width) {
        return;
      }
      const headerWidthPreset = new TimelineHeadersWidthPreset(width);
      setTimelineHeadersWidth(width);
      savePreset({ data: headerWidthPreset });
    },
    [savePreset, setTimelineHeadersWidth, headersWidth],
  );

  return (
    <div className={styles.container}>
      <ResizeBar
        direction={'horizontal'}
        mainPanelElement={headerContentDiv}
        secondaryPanelElement={contentDiv}
        mainPanelMinSize={HEADER_MIN_WIDTH}
        secondaryPanelMinSize={TIMELINE_TABLE_MIN_WIDTH}
        onChange={handleSaveHeaderWidth}
      />
    </div>
  );
};
