import { styled, TableCell, tableCellClasses } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';

export const HeaderTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Colors.background,
    color: theme.palette.secondary.main,
    padding: theme.spacing(0.8, 3),
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: fontSizes.default,
    fontWeight: theme.typography.fontWeightMedium,
  },
}));
