import { ZodSchema } from 'zod';

import { PRESET_SCHEMA } from 'shared/constants/user-presets/userPresetsSchema';
import { UserPreset, UserPresetKeysUnion, UserPresetValues } from 'shared/types/user-preset/types';

export function validateWithSchema(data: unknown, schema: ZodSchema<unknown>): boolean {
  const result = schema.safeParse(data);
  return result.success;
}

export const getPreset = <T extends UserPresetKeysUnion>(data: UserPreset<unknown>[], key: T) => {
  const value = data.find((preset) => preset.key === key)?.value;

  if (value === undefined || !validateWithSchema(value, PRESET_SCHEMA[key])) {
    return undefined;
  }

  return value as UserPresetValues[T];
};
