import { Box } from '@mui/material';

import { useIsTimelineReadyToRender } from 'pages/tactical-analysis/hooks/use-is-timeline-ready-to-render';

interface Props {
  recordingId: string;
  children: React.ReactNode;
}

export const TimelineIsReadyWrapper = ({ children, recordingId }: Props) => {
  const isTimelineReadyToRender = useIsTimelineReadyToRender(recordingId);

  return (
    <Box
      display={'flex'}
      flex={1}
      height={'100%'}
      overflow={'hidden'}
      position={'relative'}
      width={'100%'}
      sx={{
        opacity: isTimelineReadyToRender ? 1 : 0,
        transition: (theme) =>
          theme.transitions.create('all', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.short,
          }),
      }}
    >
      {children}
    </Box>
  );
};
