import { Box } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

export const DialogButtonContainer = ({ children }: Props) => {
  return <Box sx={{ marginLeft: 1, marginRight: 1, paddingTop: 1, paddingBottom: 1 }}>{children}</Box>;
};
