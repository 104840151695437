import styles from 'shared/components/empty-page/EmptyPage.module.scss';
import NotFound from 'shared/components/not-found';

interface Props {
  header: string;
  description?: string;
}

const EmptyPage = ({ header, description }: Props) => {
  return (
    <div className={styles.content}>
      <main>
        <NotFound header={header} description={description} />
      </main>
    </div>
  );
};

export default EmptyPage;
