import { TypeOf } from 'zod';

import { stageFormSchema } from 'pages/backoffice/pages/stages/components/stage-form/form';

export enum StageFormFieldsNames {
  id = 'id',
  name = 'name',
  orderIndex = 'orderIndex',
  seasonId = 'seasonId',
  parentStageId = 'parentStageId',
}

export type StageFormSchema = TypeOf<typeof stageFormSchema>;
