import { ChangeEvent, forwardRef } from 'react';

import { PlainText } from 'shared/components/editable-text/plain-text';
import Input from 'shared/components/input';

type InputTextFieldProps = {
  isEditing: boolean;
  originalText: string;
  handleNameChange: (event: ChangeEvent<HTMLInputElement>) => void;
};

type RefType = HTMLInputElement;

const InputTextField = forwardRef<RefType, InputTextFieldProps>(
  ({ isEditing, originalText, handleNameChange }, ref) => {
    if (!isEditing) return <PlainText originalText={originalText} />;

    return (
      <Input
        autoSelect
        autoFocus
        defaultValue={originalText}
        id='edit-recording-name'
        onChange={handleNameChange}
        ref={ref}
      />
    );
  },
);

InputTextField.displayName = 'InputTextField';

export default InputTextField;
