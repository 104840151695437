import { Box, Button, Fade, Stack } from '@mui/material';
import { IconEdit } from 'kognia-ui/icons/IconEdit';
import { useState } from 'react';

import { useInvalidateMetadataFixture } from 'pages/backoffice/api/fixtures/use-metadata-fixture';
import { useUpdateRecording } from 'pages/backoffice/api/fixtures/use-update-recording';
import { SelectClients } from 'pages/backoffice/components/select-clients';
import { GridListItemRecording } from 'pages/backoffice/pages/fixtures/components/grid-list-item-recording';
import { MetadataRecordingSummary } from 'pages/backoffice/types/recordings';

export const FixtureRecording = ({
  recording,
  fixtureId,
}: {
  fixtureId: string;
  recording: MetadataRecordingSummary;
}) => {
  const invalidateMetadataFixture = useInvalidateMetadataFixture();

  const { update: updateRecording } = useUpdateRecording();
  const [clientIds, setClientIds] = useState<string[]>(recording.clientIds);
  const [isEditing, setIsEditing] = useState(false);

  const handleCancel = () => {
    setIsEditing(false);
    setClientIds(recording.clientIds);
  };

  const handleSave = () => {
    updateRecording({
      data: { client_ids: clientIds, uuid: recording.id },
      id: recording.id,
      onSuccess: () => {
        setIsEditing(false);
        invalidateMetadataFixture(fixtureId);
      },
    });
  };

  if (isEditing) {
    return (
      <Fade in>
        <Stack gap={2} sx={{ padding: 2 }}>
          <Box>{recording.id}</Box>
          <SelectClients clientIds={clientIds} onChange={setClientIds} />
          <Stack direction={'row'} gap={1} justifyContent={'flex-end'}>
            <Button variant='outlined' color='secondary' onClick={handleCancel}>
              Cancel
            </Button>
            <Button variant='contained' onClick={handleSave}>
              Save
            </Button>
          </Stack>
        </Stack>
      </Fade>
    );
  }

  return (
    <GridListItemRecording key={recording.id}>
      <Box>{recording.id}</Box>
      <Stack>
        {recording.clientIds.map((clientId) => (
          <Box key={clientId}>{clientId}</Box>
        ))}
      </Stack>
      <Box>
        <IconEdit isButton onClick={() => setIsEditing(true)} />
      </Box>
    </GridListItemRecording>
  );
};
