import { TypeOf } from 'zod';

import { coachFormSchema } from 'pages/backoffice/pages/coaches/components/coach-form/form';

export enum CoachFormFieldsNames {
  id = 'id',
  name = 'name',
  coachImage = 'coach_image',
}

export interface CoachForm {
  [CoachFormFieldsNames.id]?: string;
  [CoachFormFieldsNames.name]: string;
  [CoachFormFieldsNames.coachImage]?: FileList;
}

export type CoachFormSchema = TypeOf<typeof coachFormSchema>;
