import { Box, Stack } from '@mui/material';
import { Breakpoint, Colors, generateContainerQueries } from 'kognia-ui';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import bgImage from 'shared/assets/bg-radar.webp';
import Container from 'shared/components/container';
import { KogniaLogoAnimated } from 'shared/components/kognia-logo-animated/KogniaLogoAnimated';
import { useFeatureFlag } from 'shared/contexts/app-state/hooks/useFeatureFlag';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import { useCurrentClient } from 'shared/hooks/use-current-client/useCurrentClient';
import { CompetitionsWidget } from 'widgets/competitions/Competitions.widget';
import { DashboardSearchWidget } from 'widgets/dashboard/dashboard-search/DashboardSearch.widget';
import { FavoritesCardWidget } from 'widgets/dashboard/favorites-card/FavoritesCard.widget';

const MIN_CARD_WIDTH = '320px';
const MAX_CARD_WIDTH = 488;
const LOGO_MAX_HEIGHT = '92px';
const LOGO_MAX_WIDTH = '488px';

export const ExplorePage = () => {
  const favoritesFeatureFlag = useFeatureFlag(FEATURE_FLAG.APP_FAVORITES);
  const branding = useBranding();
  const currentClient = useCurrentClient();

  const hasBranding = currentClient.branding !== null;

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        backgroundColor: Colors.background,
        backgroundImage: `url("${bgImage}")`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: `center top`,
        backgroundSize: 'cover',
        containerType: 'inline-size',
      }}
    >
      <Container>
        <Stack gap={3} alignItems='center' pt={1}>
          {hasBranding ? (
            <Box
              component={'img'}
              src={branding.homePageLogoUrl}
              alt={branding.displayName}
              maxWidth={LOGO_MAX_WIDTH}
              maxHeight={LOGO_MAX_HEIGHT}
              p={0.5}
            />
          ) : (
            <KogniaLogoAnimated />
          )}

          <DashboardSearchWidget width={MAX_CARD_WIDTH} />

          <Box
            display='grid'
            justifyContent='center'
            gap={3}
            pt={2}
            width='100%'
            sx={{
              ...generateContainerQueries({
                [Breakpoint.LG]: {
                  gridTemplateColumns: `minmax(${MIN_CARD_WIDTH}, 1.4fr) minmax(${MIN_CARD_WIDTH}, 1fr)`,
                },
                [Breakpoint.MD]: {
                  gridTemplateColumns: `minmax(${MIN_CARD_WIDTH}, 1fr) minmax(${MIN_CARD_WIDTH}, 1fr)`,
                },
                [Breakpoint.XS]: {
                  gridTemplateColumns: `minmax(${MIN_CARD_WIDTH}, ${MAX_CARD_WIDTH}px)`,
                },
              }),
            }}
          >
            {favoritesFeatureFlag && <FavoritesCardWidget />}

            <CompetitionsWidget />
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};
