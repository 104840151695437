import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';
import React from 'react';

interface VideoBarBlockContainerProps {
  hideSeparator?: boolean;
}

const VideoBarBlockContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'hideSeparator',
})<VideoBarBlockContainerProps>(({ theme, hideSeparator }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(),
  position: 'relative',
  color: Colors.white,
  flexGap: theme.spacing(2),
  ...(!hideSeparator && {
    position: 'relative',
    paddingLeft: theme.spacing(2),
    marginLeft: theme.spacing(2),
    '&::before': {
      content: '""',
      position: 'absolute',
      left: 0,
      width: '1px',
      height: '100%',
      backgroundColor: Colors.athens,
    },
  }),
}));

interface Props extends VideoBarBlockContainerProps {
  children: React.ReactNode;
}

export const VideoBarBlock = ({ children, hideSeparator = false }: Props) => {
  return <VideoBarBlockContainer hideSeparator={hideSeparator}>{children}</VideoBarBlockContainer>;
};

export const VideoBarActions = styled(Box)(() => ({
  display: 'flex',
  flex: 1,
  gap: 1,
  justifyContent: 'flex-end',
}));
