import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

type SeparatorProps = {
  height: string;
};

const Separator = styled(Box, {
  label: 'Separator',
  shouldForwardProp: (prop) => prop !== 'height',
})<SeparatorProps>(({ height }) => ({
  height,
  width: '1px',
  minWidth: '1px',
  background: Colors.iron,
}));

type Props = {
  height?: string;
};

export const VerticalSeparator = ({ height = '100%' }: Props) => {
  return <Separator height={height} />;
};
