import { Button, Tooltip } from '@mui/material';
import classNames from 'classnames';
import { IconForward } from 'kognia-ui/icons/IconForward';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useVideoPlayerIsInStandBy } from 'shared/components/video-player/hooks';
import { usePlaylistItems, useVideoPlayerActions, useVideoPlayerState } from 'shared/components/video-player/index';
import { PLAYER_STATES } from 'shared/components/video-player/state/states';
import styles from 'shared/components/video-player/video-player-component/components/Controls.module.scss';

const ForwardButton = () => {
  const { t } = useTranslation();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { state, readyState } = useVideoPlayerState();
  const isInStandBy = useVideoPlayerIsInStandBy();
  const actions = useVideoPlayerActions();
  const playlistItems = usePlaylistItems();

  const isDisabled = !readyState || state === PLAYER_STATES.ENDED || isInStandBy;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    buttonRef?.current?.blur();
    actions.nextPlaylistItem();
  };

  if (playlistItems.length <= 1) return null;

  return (
    <Tooltip enterDelay={2000} title={`${t('video-player:controls.next-video')}`}>
      <span>
        <Button
          ref={buttonRef}
          className={classNames({ [styles.disabled]: isDisabled })}
          disabled={isDisabled}
          size={'small'}
          onClick={handleClick}
        >
          <IconForward sx={{ color: 'common.white' }} />
        </Button>
      </span>
    </Tooltip>
  );
};

export default ForwardButton;
