import { Box, styled } from '@mui/material';

export const UnsavedChangesDialogIconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: 24,
  width: 24,
  border: `1px solid ${theme.palette.error.main}`,
  backgroundColor: theme.palette.error.light,
  borderRadius: '50%',
}));
