import { Typography } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';

export const PageSubTitle = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography fontSize={fontSizes.default} color={Colors.storm}>
      {children}
    </Typography>
  );
};
