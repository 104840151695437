import { Box } from '@mui/material';

import { LogoImg } from 'shared/components/sidebar-layout/sidebar-logo/styled/LogoImg';
import { useBranding } from 'shared/hooks/use-branding/useBranding';

interface Props {
  isSmall: boolean;
}

export const SidebarLogo = ({ isSmall }: Props) => {
  const branding = useBranding();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
      <LogoImg
        src={branding.navbarCollapsedLogoUrl}
        alt={branding.displayName}
        loading='eager'
        isHidden={!isSmall}
        sx={{ maxWidth: '40px' }}
      />

      <LogoImg src={branding.navbarLogoUrl} alt={branding.displayName} loading='eager' isHidden={isSmall} />
    </Box>
  );
};
