import { array, object, string, TypeOf } from 'zod';

import { KogniaUser, kogniaUserSchema } from 'api/backoffice/user/use-kognia-users/types';

export enum UsersToEnvironmentsFormFieldsNames {
  ENVIRONMENT_IDS = 'environmentIds',
  KOGNIA_USERS = 'kogniaUsers',
}

export interface UsersToEnvironmentsFormFields {
  [UsersToEnvironmentsFormFieldsNames.ENVIRONMENT_IDS]: string[];
  [UsersToEnvironmentsFormFieldsNames.KOGNIA_USERS]: KogniaUser[];
}

export interface UsersToEnvironmentsForm {
  // eslint-disable-next-line max-len
  [UsersToEnvironmentsFormFieldsNames.ENVIRONMENT_IDS]: UsersToEnvironmentsFormFields[UsersToEnvironmentsFormFieldsNames.ENVIRONMENT_IDS];
  // eslint-disable-next-line max-len
  [UsersToEnvironmentsFormFieldsNames.KOGNIA_USERS]: UsersToEnvironmentsFormFields[UsersToEnvironmentsFormFieldsNames.KOGNIA_USERS];
}

export const usersToEnvironmentsFormSchema = object({
  [UsersToEnvironmentsFormFieldsNames.ENVIRONMENT_IDS]: array(string()).min(1),
  [UsersToEnvironmentsFormFieldsNames.KOGNIA_USERS]: array(kogniaUserSchema).min(1),
});

export type UsersToEnvironmentsFormSchema = TypeOf<typeof usersToEnvironmentsFormSchema>;
