import { TacticalAnalysisStates } from 'shared/types/recording/types';

export interface TacticalAnalysisApiResponse {
  id: string;
  recordingId: string;
  tacticalAnalysisGitCommit: string;
  numberOfEpisodes: number;
  startTime: Date;
  state: TacticalAnalysisStates;
  showOverlays: boolean;
}

export enum HTTPMethod {
  CONNECT = 'CONNECT',
  DELETE = 'DELETE',
  GET = 'GET',
  HEAD = 'HEAD',
  OPTIONS = 'OPTIONS',
  PATCH = 'PATCH',
  POST = 'POST',
  PUT = 'PUT',
  TRACE = 'TRACE',
}

export interface fetchQueryResponse<T> {
  data: T;
  isFetching: boolean;
  isFetchingNextPage?: boolean;
  isError: boolean;
  isSuccess: boolean;
  isPending: boolean;
  isMissingData?: boolean;
  refetch?: () => void;
  setQueryData?: (...args: any[]) => void;
  invalidateQuery?: () => Promise<void>;
}

export type UseApiFetchOptions = {
  enabled?: boolean;
  refetchOnReconnect?: boolean;
  refetchOnWindowFocus?: boolean;
  retry?: number | boolean;
  staleTime?: number;
  refetchInterval?: number;
  gcTime?: number;
};
