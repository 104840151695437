import { Grid, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

import { PLAYLIST_TIMELINE_HEIGHT } from 'entities/playlist/config/Playlist.config';

export const PlaylistTimelineContainer = styled(Grid)(() => ({
  height: PLAYLIST_TIMELINE_HEIGHT,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  // TODO use from theme
  backgroundColor: Colors.background,
}));
