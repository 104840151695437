import { useInfiniteQuery } from '@tanstack/react-query';
import { useMemo, useState } from 'react';

import { callBackendApi } from 'api/hooks/callBackendApi';
import { InfinityQueryResult, Options } from 'api/hooks/useInfinityQuery/types';
import { getItems, getPage } from 'api/hooks/useInfinityQuery/utils';
import { HTTPMethod } from 'api/types';
import { DEFAULT_QUERY_STALE_TIME } from 'shared/constants/queryDefaults';
import { SortDirection } from 'shared/types';

const PAGE_SIZE = 10;

export const useInfinityQuery = <TData, TFilters, TApiResponse>({
  generateUrl,
  options = {
    sortDirection: SortDirection.DESC,
    sort: '',
    size: PAGE_SIZE,
  },
  initialFilters = {} as TFilters,
  transformer,
  key,
  queryOptions,
}: Options<TData, TFilters, TApiResponse>): InfinityQueryResult<TData, TFilters> => {
  const [filters, setFilters] = useState<TFilters>(initialFilters);
  const queryRef = useMemo(() => [key, options, filters], [key, options, filters]);
  const fetchRequest = useInfiniteQuery<TData[]>({
    queryKey: queryRef,
    queryFn: (infinityQueryOptions) => {
      return callBackendApi(
        generateUrl(
          {
            sortDirection: options.sortDirection,
            sort: options.sort,
            size: options.size,
            // TODO: don't cast to number, set it in the useInfinityQuery hook
            page: infinityQueryOptions.pageParam as number,
          },
          filters,
        ),
        HTTPMethod.GET,
        transformer,
      );
    },
    getNextPageParam: (lastPage: any) => {
      return lastPage.nextCursor;
    },
    staleTime: DEFAULT_QUERY_STALE_TIME,
    initialPageParam: 0,
    ...queryOptions,
  });

  const items = useMemo(() => getItems<TData>(fetchRequest.data?.pages), [fetchRequest.data]);
  const page = useMemo(() => getPage(fetchRequest.data?.pages), [fetchRequest.data]);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data, ...result } = fetchRequest;

  return { ...result, items, setFilters, page };
};
