import Cookies from 'js-cookie';

import { Client } from 'shared/types/user/types';

export const chooseInitialClientId = (userClients: Client[]) => {
  if (!userClients.length) {
    throw new Error('User has no environments');
  }

  const clientIdFromCookies = Cookies.get('current-client-id');
  if (clientIdFromCookies) {
    const client = userClients.find((client) => client.id === clientIdFromCookies);

    return client ? client.id : userClients[0].id;
  }

  return userClients[0].id;
};
