import { Avatar, styled } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';

import {
  collapsedSidebarWidth,
  openSidebarWidth,
  SidebarLayoutSidebar,
} from 'shared/components/sidebar-layout/SideBarLayout.styled';

interface BackofficeSidebarLayoutSidebarProps {
  sidebarOpen: boolean;
}

export const BackofficeSidebarLayoutSidebar = styled(SidebarLayoutSidebar, {
  shouldForwardProp: (prop) => prop !== 'sidebarOpen',
})<BackofficeSidebarLayoutSidebarProps>(({ theme, sidebarOpen }) => ({
  '& .MuiDrawer-paper': {
    left: sidebarOpen ? openSidebarWidth : collapsedSidebarWidth,
    transitionDuration: `${theme.transitions.duration.enteringScreen}ms`,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    transitionProperty: 'left, width',
    zIndex: theme.zIndex.drawer - 1,
  },
}));

export const BackofficeSidebarLetterIcon = styled(Avatar)(() => ({
  backgroundColor: Colors.athens,
  color: Colors.night,
  fontSize: fontSizes.xSmall,
  height: 24,
  width: 24,
}));
