import { Box, styled } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';

export const CounterBadge = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontWeight: theme.typography.fontWeightMedium,
  fontSize: fontSizes.xxSmall,
  // TODO use from theme
  backgroundColor: Colors.melrose,
  color: theme.palette.primary.main,
  padding: theme.spacing(0, 0.5),
  borderRadius: '2px',
  alignItems: 'center',
  height: '18px',
  lineHeight: 1,
}));
