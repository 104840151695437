import { PlayingMode } from 'shared/components/video-player/types';
import { PlaylistItemType } from 'shared/components/video-player/types';
import { getVideoByVideoType } from 'shared/components/video-player/util';
import { round } from 'shared/utils/round';

export const getCurrentPlaylistItemTime = (
  playingMode: PlayingMode,
  absoluteCurrentTime: number,
  videoSourceIndex: number,
  playlistItem?: PlaylistItemType,
) => {
  if (!playlistItem) return 0;

  const isFullMatchNotEffectiveTime = playingMode.mode === 'TACTICAL_CAMERA' && !playingMode.useEffectiveTime;

  const { videoSources } = getVideoByVideoType(playlistItem, playingMode);

  const adjustedStartTime = videoSources.reduce((startTime, source, idx) => {
    return videoSourceIndex > idx ? startTime + (source.endTime - source.startTime) : startTime;
  }, 0);

  const currentTime = isFullMatchNotEffectiveTime
    ? absoluteCurrentTime
    : absoluteCurrentTime - videoSources[videoSourceIndex].startTime + adjustedStartTime;

  return round(currentTime > 0 ? currentTime : 0);
};
