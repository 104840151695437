import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

export const AlignRecordingClipContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
}));

export const AlignRecordingClipCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: 1,
  gap: theme.spacing(1),
  padding: theme.spacing(1, 2),
  justifyContent: 'flex-start',
  alignItems: 'center',
  background: Colors.white,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[1],
}));
