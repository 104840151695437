import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

export const videoPlayerBarContainerHeight = 40;

export const VideoPlayerBarContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  zIndex: 10,
  backgroundColor: Colors.night,
  color: Colors.white,
  paddingLeft: theme.spacing(2),
  paddingTop: theme.spacing(0.25),
  paddingBottom: theme.spacing(0.25),
  paddingRight: theme.spacing(2),
  minHeight: videoPlayerBarContainerHeight,
}));

export const VideoBarContent = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flex: 1,
}));
