import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';
import { UpdateVenueApiParams } from 'pages/backoffice/api/venues/use-update-venue/types';
import { useInvalidateVenues } from 'pages/backoffice/api/venues/use-venues';

interface RequestData {
  data: UpdateVenueApiParams;
  id: string;
  onSuccess?: () => void;
}

export const useUpdateVenue = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateVenues = useInvalidateVenues();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.PATCH,
    successMessage: 'Venue updated',
    errorMessage: (data) => metadataApiErrorParser(data, 'Venue update error'),
    onSuccess: invalidateVenues,
  });

  const update = ({ data, id, onSuccess }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: `${annotationApiUrls.VENUES}/${id}`,
        data,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, update };
};
