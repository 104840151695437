import { TextField, Typography } from '@mui/material';
import { useCallback, useState } from 'react';

import { emailSchema } from 'pages/backoffice/widgets/users/users-list/ui/emails-input/utils/emailsSchema';
import { useDebounce } from 'shared/hooks/use-debounce/useDebounce';

interface Props {
  setEmails: (emails: string[]) => void;
}

const AVAILABLE_EMAILS_SEPARATORS_REGEXP = /[\s,\n]+/; // space, comma, line break

export const EmailsInput = ({ setEmails }: Props) => {
  const [errors, setErrors] = useState<string[]>([]);

  const onChange = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const inputValue = event.target.value;

      if (!inputValue.trim().length) {
        setEmails([]);
        setErrors([]);
      } else {
        const newErrors: string[] = [];
        const emailsList = inputValue
          .trim()
          .split(AVAILABLE_EMAILS_SEPARATORS_REGEXP)
          .filter((email) => email.trim() !== '');

        emailsList.forEach((email) => {
          try {
            emailSchema.parse(email);
          } catch {
            newErrors.push(email);
          }
        });

        setErrors(newErrors);

        if (!newErrors.length) {
          setEmails(emailsList);
        }
      }
    },
    [setEmails],
  );

  const handleChange = useDebounce(onChange);

  return (
    <TextField
      onChange={handleChange}
      size='small'
      fullWidth
      multiline
      maxRows={5}
      placeholder='Lists of emails'
      helperText={
        errors.length ? (
          <Typography
            component='span'
            variant='inherit'
            overflow='hidden'
            textOverflow='ellipsis'
            whiteSpace='pre-wrap'
          >{`Not valid email(s): ${errors.join(', ')}`}</Typography>
        ) : null
      }
      error={!!errors.length}
    />
  );
};
