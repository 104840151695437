import { Pagination } from 'shared/types/pagination/types';

export const getItems = <T>(pages: T[][] | undefined): T[] => {
  return pages
    ? pages.reduce((acc: T[], page: any) => {
        return acc.concat(page.data.items);
      }, [])
    : [];
};

export const getPage = (pages: any): Pagination | undefined => {
  return pages ? pages[0].data.page : undefined;
};
