import {
  CustomButtonsSkeletonContainer,
  CustomButtonsSkeletonGradient,
  CustomButtonsSkeletonItem,
} from 'pages/tagging-tool-keypad-edit/components/custom-buttons-skeleton/index.styled';

export const CustomButtonsSkeleton = () => {
  return (
    <CustomButtonsSkeletonContainer>
      <CustomButtonsSkeletonItem />
      <CustomButtonsSkeletonItem />
      <CustomButtonsSkeletonItem />
      <CustomButtonsSkeletonGradient />
    </CustomButtonsSkeletonContainer>
  );
};
