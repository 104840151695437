import { Stack, styled } from '@mui/material';
import { boxShadows, Colors } from 'kognia-ui';

import { PLAYLIST_ITEM_HEIGHT } from 'entities/playlist/config/Playlist.config';

interface PlaylistItemWrapperProps {
  isCurrent: boolean;
  isDeleting?: boolean;
  isEditing: boolean;
  isDisabled: boolean;
  isDraggable: boolean;
}

export const PlaylistItemWrapper = styled(Stack, {
  shouldForwardProp: (prop) =>
    prop !== 'isCurrent' &&
    prop !== 'isEditing' &&
    prop !== 'isDraggable' &&
    prop !== 'isDisabled' &&
    prop !== 'isDeleting',
})<PlaylistItemWrapperProps>(({ theme, isCurrent, isDeleting = false, isEditing, isDisabled, isDraggable }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `1px solid transparent`,
  backgroundColor: theme.palette.common.white,
  alignItems: 'center',
  height: PLAYLIST_ITEM_HEIGHT,
  position: 'relative',
  overflow: 'hidden',
  cursor: isDraggable ? 'pointer' : 'default',
  transition: theme.transitions.easing.easeOut,

  '&:hover': {
    borderColor: theme.palette.primary.main,
  },

  ...(isCurrent && {
    borderColor: 'transparent',
    backgroundColor: theme.palette.primary.light,
    boxShadow: boxShadows[2],

    '[data-element-name="name-text"], [data-element-name="duration"]': {
      color: theme.palette.common.white,
    },

    '[data-element-name="counter"]': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.primary.main,
    },

    svg: {
      fill: theme.palette.common.white,
    },
  }),

  ...(isDisabled && {
    backdropFilter: 'grayscale(100%)',
    pointerEvents: 'none',
    borderColor: theme.palette.secondary.main,
    // TODO use from theme
    backgroundColor: Colors.background,
    opacity: 0.5,

    '[data-element-name="name-text"], [data-element-name="duration"]': {
      color: theme.palette.secondary.main,
    },

    '[data-element-name="counter"]': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
    },

    svg: {
      fill: theme.palette.secondary.main,
    },
  }),

  ...(isEditing && {
    pointerEvents: 'none',
    borderColor: theme.palette.warning.main,
    backgroundColor: theme.palette.warning.light,
    opacity: 0.5,

    '[data-element-name="name-text"], [data-element-name="duration"]': {
      color: theme.palette.warning.main,
    },

    '[data-element-name="counter"]': {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.common.white,
    },

    svg: {
      fill: theme.palette.warning.main,
    },
  }),

  ...(isDeleting && {
    boxShadows: boxShadows[1],
    borderColor: theme.palette.error.main,
    opacity: 0.3,
    backgroundColor: theme.palette.error.main,

    '[data-element-name="name-text"], [data-element-name="duration"]': {
      color: theme.palette.common.white,
    },

    '[data-element-name="counter"]': {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.error.main,
    },

    svg: {
      fill: theme.palette.error.main,
    },
  }),
}));
