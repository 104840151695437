import reduce from 'lodash/reduce';

type QueryParamFilters = { [key in string]: string | number | boolean | undefined | Array<unknown> };
type QueryParamOptions = { [key in string]: string | number | boolean | undefined | Array<unknown> };

export const generateQueryParamsFromOptionsAndFilters = (options: QueryParamOptions, filters: QueryParamFilters) => {
  return reduce(
    { ...filters, ...options },
    (result, filter, key) =>
      filter || filter === 0
        ? {
            ...result,
            [key]: filter,
          }
        : { ...result },
    {},
  );
};
