import { atomFamily, selectorFamily } from 'recoil';

import { generatePlaylistMenuItemId } from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/add-to-playlist-menu-state/hooks';

export type PlaylistMenuItem = {
  id: string;
  endTime: number;
  startTime: number;
  playlistId: string;
  name: string;
};

const playlistMenuState = atomFamily<string[], string>({
  key: 'tactical-analysis-menu-state',
  default: [],
});

const playlistMenuItem = atomFamily<PlaylistMenuItem, string>({
  key: 'tactical-analysis-playlist-menu-item',
  default: {
    id: '',
    endTime: 0,
    startTime: 0,
    playlistId: '',
    name: '',
  },
});

const playlistItemsSelector = selectorFamily<PlaylistMenuItem[], string>({
  key: 'tactical-analysis-playlist-menu-items',
  get:
    (playlistMenuId: string) =>
    ({ get }) => {
      const items = get(playlistMenuState(playlistMenuId));

      return items.map((playlistItemId) =>
        get(playlistMenuItem(generatePlaylistMenuItemId(playlistMenuId, playlistItemId))),
      );
    },
  set:
    (playlistMenuId) =>
    ({ get, set }, newValues) => {
      const items = get(playlistMenuState(playlistMenuId));
      if (!Array.isArray(newValues)) return;

      const finalItems = newValues.reduce(
        (acc, clip) => {
          if (!items.includes(clip.id)) {
            set(playlistMenuItem(generatePlaylistMenuItemId(playlistMenuId, clip.id)), clip);
            acc.push(clip.id);
          }
          return acc;
        },
        [...items],
      );

      set(playlistMenuState(playlistMenuId), finalItems);
    },
});

const playlistItemsSelectorIds = selectorFamily<string[], string>({
  key: 'tactical-analysis-playlist-menu-ids-items',
  get:
    (playlistMenuId: string) =>
    ({ get }) => {
      return get(playlistMenuState(playlistMenuId));
    },
  set:
    (playlistMenuId) =>
    ({ get, set, reset }, idsToRemove) => {
      const items = get(playlistMenuState(playlistMenuId));
      if (!Array.isArray(idsToRemove)) return;

      const finalItems = items.reduce((acc, playlistItemId) => {
        if (idsToRemove.includes(playlistItemId)) {
          reset(playlistMenuItem(generatePlaylistMenuItemId(playlistMenuId, playlistItemId)));
        } else {
          acc.push(playlistItemId);
        }

        return acc;
      }, [] as string[]);

      set(playlistMenuState(playlistMenuId), finalItems);
    },
});

export const playlistMenuAtoms = {
  playlistItemsSelectorIds,
  playlistItemsSelector,
  playlistMenuItem,
  playlistMenuState,
};
