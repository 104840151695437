import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

interface KeypadPanelContainerProps {
  disabled?: boolean;
}

export const KeypadPanelContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<KeypadPanelContainerProps>(({ disabled }) => ({
  background: Colors.white,
  ...(disabled && {
    opacity: 0.3,
    pointerEvents: 'none',
    filter: 'grayscale(100%)',
  }),
}));

export const KeypadsPanelTopBar = styled(Box)(({ theme }) => ({
  background: Colors.iron,
  padding: theme.spacing(1),
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const KeypadsPanelKickOffTags = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: theme.spacing(2, 1, 0, 1),
  gap: theme.spacing(1),
}));

export const KeypadsPanelTitlesContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3, 0.5, 0, 0.5),
  gap: theme.spacing(1),
}));

export const KeypadsPanelTitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  justifyContent: 'center',
  alignItems: 'center',
  flex: '1',
  margin: '0',
}));

export const KeypadPanelSections = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.5),
  padding: theme.spacing(0, 2, 2),
}));

export const KeypadPanelCustomButtons = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

export const KeypadPanelCustomButtonsContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(0.5),
  gap: theme.spacing(1),
  alignItems: 'flex-start',
}));

export const KeypadPanelCustomButtonsColumn = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  gap: theme.spacing(0.5),
}));
