import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

export const ExportModalContent = styled(Box)(({ theme }) => ({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(6, 10),
  width: '60%',
  maxWidth: '500px',
  padding: theme.spacing(3, 4.5, 4.25, 4.5),
  backgroundColor: theme.palette.common.white,
  borderRadius: '8px',
}));

export const ExportModalIconContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '48px',
  height: '48px',
  padding: theme.spacing(2),
  borderRadius: '50%',
  // TODO add color to palette
  backgroundColor: Colors.background,
}));
