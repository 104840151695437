import { Box, Button, styled } from '@mui/material';
import { Colors } from 'kognia-ui';
import { IconChevronRight } from 'kognia-ui/icons/IconChevronRight';
import { IconUser } from 'kognia-ui/icons/IconUser';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { routes } from 'kognia/router/routes';
import SwitchEnvironmentBackground from 'shared/assets/page-background.svg';
import Container from 'shared/components/container';
import { Dialog } from 'shared/components/dialog';
import { DialogActions } from 'shared/components/dialog/dialog-actions';
import { DialogContent } from 'shared/components/dialog/dialog-content';
import { DialogContentText, DialogTextVariants } from 'shared/components/dialog/dialog-content-text';
import { DialogHeader } from 'shared/components/dialog/dialog-header';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import Spinner from 'shared/components/spinner';
import { useClientId } from 'shared/contexts/app-state/hooks/useClientId';
import { useDebounce } from 'shared/hooks/use-debounce/useDebounce';
import { useCommonResourceClients } from 'shared/hooks/use-source-client';
import { Client } from 'shared/types/user/types';

const ClientButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: Colors.background,
  height: '48px',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  textTransform: 'none',
}));

const ClientButtonsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  gap: theme.spacing(1),
}));

const DEBOUNCE_TIME = 500;

interface SwitchEnvironmentProps {
  resourceClientsIds: string[];
}

export const SwitchEnvironment = ({ resourceClientsIds }: SwitchEnvironmentProps) => {
  const { t } = useTranslation();
  const [newClientId, setNewClientId] = useState<string | null>(null);
  const navigate = useNavigate();
  const { setClientId } = useClientId();
  const getCommonClients = useCommonResourceClients();
  const commonClients = getCommonClients(resourceClientsIds);
  const isLoading = Boolean(newClientId);

  const handleEnvironmentSwitch = useCallback((client: Client) => {
    setNewClientId(client.id);
  }, []);

  const handleClientChange = useDebounce(
    (newClientId: string) => {
      setClientId(newClientId);
      setNewClientId(null);
    },
    { wait: DEBOUNCE_TIME },
  );

  useEffect(() => {
    if (newClientId) {
      handleClientChange(newClientId);
    }
  }, [handleClientChange, newClientId, setClientId]);

  const handleClose = useCallback(() => {
    navigate(routes.HOME_PAGE, { replace: true });
  }, [navigate]);

  const isSingleSelection = commonClients.length === 1;

  return (
    <SidebarLayout>
      <Container backgroundImage={SwitchEnvironmentBackground} backgroundSize='cover'>
        <Dialog
          open={true}
          fullWidth={true}
          maxWidth='sm'
          disableBackground={true}
          onClose={handleClose}
          disableEscapeKeyDown={true}
          disableBackdropOnClose={true}
        >
          <DialogHeader icon={<IconUser size='small' />}>{t('switch-environment:modal.title')}</DialogHeader>
          <DialogContent>
            <DialogContentText textVariant={DialogTextVariants.Primary} textAlign='center'>
              {t('switch-environment:modal.text')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {isSingleSelection ? (
              <Button
                variant='contained'
                onClick={() => handleEnvironmentSwitch(commonClients[0])}
                disabled={isLoading}
              >
                {t('switch-environment:modal.button', { clientName: commonClients[0].name })}
              </Button>
            ) : (
              <ClientButtonsWrapper>
                {commonClients.map((client) => (
                  <ClientButton
                    endIcon={
                      newClientId === client.id ? (
                        <Spinner size='small' />
                      ) : (
                        <IconChevronRight size='small' color={isLoading ? 'disabled' : 'primary'} />
                      )
                    }
                    key={client.id}
                    fullWidth={true}
                    size='large'
                    disabled={isLoading}
                    onClick={() => handleEnvironmentSwitch(client)}
                  >
                    {client.name}
                  </ClientButton>
                ))}
              </ClientButtonsWrapper>
            )}
          </DialogActions>
        </Dialog>
      </Container>
    </SidebarLayout>
  );
};
