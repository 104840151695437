import { Box } from '@mui/material';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Container from 'shared/components/container';
import { PageTitle } from 'shared/components/page-title';
import { DownloadManagerWidget } from 'widgets/download-manager/DownloadManager.widget';

const DEFAULT_TITLE_HEIGHT_WITH_PADDING = 60;

const DownloadManagerPage = () => {
  const { t } = useTranslation();
  const titleWrapperRef = useRef<HTMLDivElement>(null);

  return (
    <Container>
      <Box
        flexGrow={1}
        minHeight={({ spacing }) =>
          `calc(100vh - (${titleWrapperRef.current?.clientHeight || DEFAULT_TITLE_HEIGHT_WITH_PADDING}px + ${spacing(
            1,
          )}))`
        }
        display='flex'
        flexDirection='column'
      >
        <Box ref={titleWrapperRef} pb={3}>
          <PageTitle>{t('download-manager:title')}</PageTitle>
        </Box>
        <DownloadManagerWidget />
      </Box>
    </Container>
  );
};

export default DownloadManagerPage;
