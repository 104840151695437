import { SvgIcon, type SvgIconProps } from '../components/SvgIcon';

export const IconKeypadEmpty = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <g>
        <path d='M8.053 4.5H4.895c-.052 0-.103.01-.151.03s-.091.049-.128.086-.066.08-.086.128-.03.099-.03.151v3.158c0 .052.01.103.03.151s.049.091.086.128.08.066.128.086.099.03.151.03h3.158c.052 0 .103-.01.151-.03s.091-.049.128-.086.066-.08.086-.128.03-.099.03-.151V4.895c0-.052-.01-.103-.03-.151s-.049-.091-.086-.128-.08-.066-.128-.086-.099-.03-.151-.03zm-.395 3.158H5.289V5.289h2.368v2.368z' />
        <use xlinkHref='#B' />
        <use xlinkHref='#B' x='5.526' />
        <use xlinkHref='#C' />
        <use xlinkHref='#D' />
        <use xlinkHref='#D' x='5.526' />
        <use xlinkHref='#C' y='5.527' />
        <use xlinkHref='#D' y='5.527' />
        <use xlinkHref='#D' x='5.526' y='5.527' />
      </g>
      <defs>
        <path
          id='B'
          d='M13.579 4.5h-3.158c-.052 0-.103.01-.151.03s-.091.049-.128.086-.066.08-.086.128-.03.099-.03.151v3.158c0 .052.01.103.03.151s.049.091.086.128.08.066.128.086.099.03.151.03h3.158c.052 0 .103-.01.151-.03s.091-.049.128-.086.066-.08.086-.128.03-.099.03-.151V4.895c0-.052-.01-.103-.03-.151s-.049-.091-.086-.128-.08-.066-.128-.086-.099-.03-.151-.03zm-.395 3.158h-2.368V5.289h2.368v2.368z'
        />
        <path
          id='C'
          d='M8.053 10.026H4.895c-.052 0-.103.01-.151.03s-.091.049-.128.086-.066.08-.086.128-.03.099-.03.151v3.158c0 .052.01.103.03.151s.049.091.086.128.08.066.128.086.099.03.151.03h3.158c.052 0 .103-.01.151-.03s.091-.049.128-.086.066-.08.086-.128.03-.099.03-.151v-3.158c0-.052-.01-.103-.03-.151s-.049-.091-.086-.128-.08-.066-.128-.086-.099-.03-.151-.03zm-.395 3.158H5.289v-2.368h2.368v2.368z'
        />
        <path
          id='D'
          d='M13.579 10.026h-3.158c-.052 0-.103.01-.151.03s-.091.049-.128.086-.066.08-.086.128-.03.099-.03.151v3.158c0 .052.01.103.03.151s.049.091.086.128.08.066.128.086.099.03.151.03h3.158c.052 0 .103-.01.151-.03s.091-.049.128-.086.066-.08.086-.128.03-.099.03-.151v-3.158c0-.052-.01-.103-.03-.151s-.049-.091-.086-.128-.08-.066-.128-.086-.099-.03-.151-.03zm-.395 3.158h-2.368v-2.368h2.368v2.368z'
        />
      </defs>
    </SvgIcon>
  );
};
