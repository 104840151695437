import { StatsTitle } from 'features/dashboard/player-profile-stats/ui/StatTitle';
import { StatValue } from 'features/dashboard/player-profile-stats/ui/StatValue';
import { StatWrapper } from 'features/dashboard/player-profile-stats/ui/StatWrapper';

type Props = {
  value: number | string;
  name: string;
  isEmpty: boolean;
};

export const Stat = ({ value, name, isEmpty }: Props) => {
  return (
    <StatWrapper>
      <StatsTitle>{name}</StatsTitle>
      <StatValue isEmpty={isEmpty}>{value}</StatValue>
    </StatWrapper>
  );
};
