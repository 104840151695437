import { useCallback } from 'react';

import { useBulkSelectedItems, useSetBulkSelectedItems } from 'entities/playlist/hooks/useBulkSelectedItems';

export const useHandleSelect = (playlistId: string) => {
  const selectedItems = useBulkSelectedItems(playlistId);
  const setSelectedItems = useSetBulkSelectedItems(playlistId);

  return useCallback(
    (value: string) => {
      if (!selectedItems.includes(value)) {
        return setSelectedItems([...selectedItems, value]);
      }

      const filteredItems = selectedItems.filter((item) => item !== value);

      return setSelectedItems(filteredItems);
    },
    [setSelectedItems, selectedItems],
  );
};
