import { Box, styled } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

const DialogContentWrapper = styled(Box)(({ theme }) => ({
  padding: `0 ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
}));

export const DialogContent = ({ children }: Props) => {
  return <DialogContentWrapper>{children}</DialogContentWrapper>;
};
