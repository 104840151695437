import { DialogContentTextProps, styled } from '@mui/material';
import MuiDialogContentText from '@mui/material/DialogContentText';
import { Colors, fontSizes } from 'kognia-ui';

export enum DialogTextVariants {
  Primary = 'primary',
  Secondary = 'secondary',
}

interface DialogContentTextWrapperProps {
  textVariant: DialogTextVariants.Primary | DialogTextVariants.Secondary;
}

const DialogContentTextWrapper = styled(MuiDialogContentText, {
  shouldForwardProp: (prop) => prop !== 'textVariant',
})<DialogContentTextWrapperProps>(({ theme, textVariant }) => ({
  fontSize: fontSizes.default,
  color: textVariant === DialogTextVariants.Secondary ? Colors.storm : Colors.night,
  marginBottom: theme.spacing(1),
}));

interface Props extends DialogContentTextProps {
  textVariant?: DialogTextVariants.Primary | DialogTextVariants.Secondary;
}

export const DialogContentText = ({
  children,
  textVariant = DialogTextVariants.Secondary,
  textAlign = 'center',
  ...rest
}: Props) => {
  return (
    <DialogContentTextWrapper textVariant={textVariant} textAlign={textAlign} {...rest}>
      {children}
    </DialogContentTextWrapper>
  );
};
