import { ListItem, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

export const FixturesListItem = styled(ListItem)(({ theme }) => ({
  display: 'grid',
  flexDirection: 'row',
  gridTemplateColumns: '.5fr 1fr 1fr 32px',
  borderBottom: `1px solid ${Colors.iron}`,
  padding: `${theme.spacing(1, 2, 1, 3)} !important`,
  gridGap: theme.spacing(),
}));
