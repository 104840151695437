const isValidDate = (data: Date) => {
  return data instanceof Date && !isNaN(data.getTime());
};

export function generateRecordingId(dateStr: string, homeAbb: string, awayAbb: string) {
  const date = new Date(dateStr);

  if (!isValidDate(date)) return '';

  const year = date.getFullYear();
  let month = (date.getMonth() + 1).toString();
  let day = date.getDate().toString();

  month = month.padStart(2, '0');
  day = day.padStart(2, '0');

  return `${year}-${month}-${day}-${homeAbb}-${awayAbb}`;
}
