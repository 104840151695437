import { useLocation } from 'react-router-dom';

import { RecordingsFiltersList } from 'api/recording/useFetchRecordings';
import { routes } from 'kognia/router/routes';
import { FILTERS_RECORDINGS_LISTS_KEY } from 'pages/recordings-list/constants/filtersKey';
import { POSSIBLE_FILTERS } from 'pages/recordings-list/constants/possibleFilters';
import { useFiltersSearchParams } from 'shared/hooks/use-filters-search-params/useFiltersSearchParams';
import { useUrlFiltersState } from 'shared/hooks/use-url-filters/useUrlFiltersState';

export const useRecordingsListFilters = () => {
  const { pathname } = useLocation();

  if (pathname !== routes.RECORDING_LIST) {
    throw new Error('useRecordingsListFilters must be used on recordings page');
  }

  const { getFilters, getUrlString } = useFiltersSearchParams(POSSIBLE_FILTERS);
  const { filters, setFilter, removeFilters } = useUrlFiltersState<RecordingsFiltersList>(FILTERS_RECORDINGS_LISTS_KEY);

  return {
    getUrlFilters: getFilters,
    getUrlString,
    filters,
    setFilter,
    removeFilters,
  };
};
