import { Box, styled } from '@mui/material';

export const ItemContentWrapper = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  height: '100%',
  flexGrow: 1,
  justifyContent: 'space-between',
  maxWidth: '100%',
});
