import React from 'react';

import { FormFieldProps } from 'pages/backoffice/pages/annotation/components/form-fields/types';
import { AnnotationFormLabels } from 'pages/backoffice/pages/annotation/utils/form-fields-validation';
import { AnnotationFormFieldsNames } from 'pages/backoffice/pages/annotation/utils/form-fields-validation/types';
import { FormInputField } from 'shared/components/form/form-input-field';

export const EpisodeIndexField = ({ errors = {}, register }: FormFieldProps) => {
  return (
    <FormInputField
      id={AnnotationFormFieldsNames.EPISODE_INDEX}
      label={AnnotationFormLabels[AnnotationFormFieldsNames.EPISODE_INDEX]}
      error={!!errors[AnnotationFormFieldsNames.EPISODE_INDEX]}
      fullWidth
      helperText={
        errors[AnnotationFormFieldsNames.EPISODE_INDEX] && (
          <>{errors[AnnotationFormFieldsNames.EPISODE_INDEX]?.message}</>
        )
      }
      {...register(AnnotationFormFieldsNames.EPISODE_INDEX)}
    />
  );
};
