import { IconButton, Tooltip } from '@mui/material';
import { IconStar } from 'kognia-ui/icons/IconStar';
import { IconStarFilled } from 'kognia-ui/icons/IconStarFilled';

type Props = {
  isFavorite: boolean;
  onClick: () => void;
  title: string;
};

export const FavoriteStarButton = ({ isFavorite, onClick, title }: Props) => {
  return (
    <Tooltip title={title}>
      <IconButton
        size={'small'}
        sx={{ position: 'absolute', top: (theme) => theme.spacing(0.5), right: (theme) => theme.spacing(0.5) }}
        onClick={onClick}
      >
        {isFavorite ? <IconStarFilled size={'small'} color={'primary'} /> : <IconStar size={'small'} />}
      </IconButton>
    </Tooltip>
  );
};
