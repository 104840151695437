import { Box } from '@mui/material';

import { CountryCode } from 'shared/utils/countries';

export type FlagSizes = 'xxsmall' | 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge';

const FLAG_SIZES = {
  xxsmall: 8,
  xsmall: 16,
  small: 24,
  medium: 32,
  large: 64,
  xlarge: 88,
};

type Props = {
  countryCode: CountryCode | undefined;
  size?: FlagSizes;
};

export const CountryFlag = ({ countryCode, size = 'xsmall' }: Props) => {
  if (!countryCode) return null;

  return (
    <Box
      height={`${FLAG_SIZES[size]}px`}
      minHeight={`${FLAG_SIZES[size]}px`}
      width={`${FLAG_SIZES[size] * 1.5}px`}
      minWidth={`${FLAG_SIZES[size] * 1.5}px`}
      justifyContent={'center'}
      overflow={'hidden'}
      borderRadius={0.5}
      border={({ palette }) => `1px solid ${palette.divider}`}
      sx={{
        backgroundImage: `url(/flags/3x2/${countryCode.toUpperCase()}.svg)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    />
  );
};
