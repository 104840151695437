import { TimeRulerBlockContainer } from 'pages/tactical-analysis/components/timeline/time-ruler/time-ruler-block-container';
import { useTacticalAnalysisMatchSegments } from 'pages/tactical-analysis/hooks/use-tactical-analysis-match-segments';

type Props = {
  recordingId: string;
  endTime: number;
};

export const TimeRulerFullTime = ({ endTime, recordingId }: Props) => {
  const matchSegments = useTacticalAnalysisMatchSegments(recordingId);
  return (
    <TimeRulerBlockContainer
      recordingId={recordingId}
      matchSegments={matchSegments}
      fullWidth
      startTime={0}
      endTime={endTime}
    />
  );
};
