import { Box, styled } from '@mui/material';

export const FiltersBarContainer = styled(Box)(({ theme }) => ({
  zIndex: 13,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '4.5rem',
  padding: theme.spacing(0, 1),
  background: theme.palette.common.white,
  // TODO add to theme
  boxShadow: '0 3px 3px -1px rgba(10, 22, 70, 0.1), 0 0 1px 0 rgba(10, 22, 70, 0.06)',
  overflowX: 'auto',
}));
