import { enrichEpisodesWithName, generateMatchSegments } from 'api/match/transformers/match/matchWithEpisodes/utils';
import { TacticalAnalysesPlaylistItemsFiltersAPI } from 'api/recording/useEpisodesWithFilters/types';
import { transformFilters } from 'api/recording/utils';
import { RecordingVideoSourceAPI } from 'pages/recordings-list/api/types';
import { Episode, Match, MatchApi } from 'shared/types';
import { MatchWithEpisodes } from 'shared/types/match';
import { PlayBackTypes, VideoSource, ViewTypes } from 'shared/types/recording/types';
import { convertFromISO8601ToSeconds } from 'shared/utils/convert-from-iso-8601-to-seconds/convertFromISO8601ToSeconds';
import { getDefaultVideoSource } from 'shared/utils/get-video-sources';
import { round } from 'shared/utils/round';

export const transformRecordingVideoSources = (videoSources: RecordingVideoSourceAPI[]): VideoSource[] => {
  return videoSources.map((video) => ({
    id: video.id,
    recordingId: video.recordingId,
    src: video.src,
    srcDownload: video.srcDownload,
    poster: video.poster,
    duration: convertFromISO8601ToSeconds(video.duration),
    playBackType: video.playBackType as PlayBackTypes,
    videoView: {
      type: video.videoView.type as ViewTypes,
    },
  }));
};

export interface MatchWithEpisodesAPIResponse {
  match: MatchApi;
  episodes: Episode[];
  filters: TacticalAnalysesPlaylistItemsFiltersAPI;
}

export const transformMatchWithEpisodes = ({
  match,
  episodes,
  filters,
}: MatchWithEpisodesAPIResponse): MatchWithEpisodes => {
  const videoSources = transformRecordingVideoSources(match.videoSources);

  const defaultVideoSource = getDefaultVideoSource(videoSources);
  const recordingMatch: Match = {
    id: match.id,
    clientIds: match.clientIds,
    title: match.title,
    videoSources,
    hasHomographies: match.hasHomographies,
    segments: match.segments.map((segment) => ({
      length: round(segment.length),
      start: round(segment.start),
      segment: {
        length: round(segment.segmentType.length),
        name: segment.segmentType.name,
        start: round(segment.segmentType.start),
      },
    })),
    teams: match.teams,
    defaultVideoSource,
    showOverlays: match.showOverlays,
  };

  const recordingEpisodes = enrichEpisodesWithName(episodes);

  return {
    matchSegments: generateMatchSegments(recordingEpisodes),
    match: recordingMatch,
    episodes: recordingEpisodes,
    filters: Object.keys(filters).length === 0 ? undefined : transformFilters(filters),
  };
};
