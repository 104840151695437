import { useEffect, useState } from 'react';

import { usePlaylist } from 'api/playlist/usePlaylist';
import { useMultimatchAppliedFiltersPreset } from 'pages/playlist-detail/hooks/useMultimatchAppliedFiltersPreset';
import { useClientId } from 'shared/contexts/app-state/hooks/useClientId';

export const usePlaylistPage = ({ playlistId }: { playlistId: string }) => {
  const { isPending } = useMultimatchAppliedFiltersPreset({ playlistId });
  const { clientId } = useClientId();
  const playlist = usePlaylist({ playlistId });
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const isInvalidClient = clientId !== playlist?.data?.clientId;

  useEffect(() => {
    if (isFirstLoad && !playlist.isStale) {
      setIsFirstLoad(false);
    }
  }, [isFirstLoad, playlist.isStale]);

  return {
    ...playlist,
    isLoading: isPending || (playlist.isLoading && playlist.isPending) || isFirstLoad,
    isInvalidClient,
  };
};
