import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';
import { Player, PlayerSportMetadataApi } from 'shared/types/player/player';
import { transformPlayerApiResponse } from 'shared/utils/transformPlayer';

type CreatePlayerParams = Pick<PlayerSportMetadataApi, 'name'> & { image?: File };

interface CreatePlayerData {
  data: CreatePlayerParams;
  onSuccess?: (player: Player) => void;
}

export const useCreatePlayer = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const mutationRequest = useMutationRequest<PlayerSportMetadataApi, Player, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Player created',
    transformer: transformPlayerApiResponse,
    errorMessage: (data) => metadataApiErrorParser(data, 'Player creation error'),
  });

  const createPlayer = ({ data, onSuccess }: CreatePlayerData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    const formData = new FormData();
    formData.append('player', JSON.stringify({ name: data.name }));
    data.image && formData.append('player_image', data.image);

    mutationRequest.mutate(
      {
        url: annotationApiUrls.PLAYERS,
        data: formData,
      },
      { onSuccess },
    );
  };

  return { ...mutationRequest, createPlayer };
};
