import { styled } from '@mui/material';

import { SearchPlaylistAutocomplete } from 'shared/components/search-playlist-autocomplete';

export const SearchPlaylistAutocompleteInput = styled(SearchPlaylistAutocomplete)(({ theme }) => ({
  minWidth: theme.spacing(30),
  margin: 0,
  position: 'relative',
  padding: 0,
  top: '2px',
  '& .MuiOutlinedInput-root.MuiInputBase-sizeSmall': {
    padding: 0,
  },
  '& .MuiAutocomplete-inputRoot': {
    background: theme.palette.common.white,
    paddingTop: theme.spacing(0.25),
    paddingBottom: theme.spacing(0.25),
    '& input': {
      padding: `${theme.spacing(0.5)} 0 5px ${theme.spacing()} !important`,
    },
  },
}));
