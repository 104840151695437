import { BackofficeAnnotationToolType } from 'pages/backoffice/api/types';
import { FormFieldProps } from 'pages/backoffice/pages/annotation/components/form-fields/types';
import { AnnotationFormLabels } from 'pages/backoffice/pages/annotation/utils/form-fields-validation';
import { AnnotationFormFieldsNames } from 'pages/backoffice/pages/annotation/utils/form-fields-validation/types';
import { FormSelectField } from 'shared/components/form/form-select-field';
import { getTypeOptions } from 'shared/components/form/get-type-options';

export const AnnotationToolField = ({ errors = {}, register }: FormFieldProps) => {
  return (
    <FormSelectField
      id={AnnotationFormFieldsNames.ANNOTATION_TOOL}
      label={AnnotationFormLabels[AnnotationFormFieldsNames.ANNOTATION_TOOL]}
      options={getTypeOptions({
        options: Object.values(BackofficeAnnotationToolType).map((option) => ({ value: option })),
      })}
      defaultValue={BackofficeAnnotationToolType.CVAT}
      error={!!errors[AnnotationFormFieldsNames.ANNOTATION_TOOL]}
      helperText={
        errors[AnnotationFormFieldsNames.ANNOTATION_TOOL] && (
          <>{errors[AnnotationFormFieldsNames.ANNOTATION_TOOL]?.message}</>
        )
      }
      {...register(AnnotationFormFieldsNames.ANNOTATION_TOOL)}
    />
  );
};
