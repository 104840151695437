import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { HTTPMethod } from 'api/types';
import { CreateRecordingApiParams } from 'pages/backoffice/api/recordings/use-create-metadata-recording/types';
import { useInvalidateMetadataRecordings } from 'pages/backoffice/api/recordings/use-metadata-recordings';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { useAllowedBackofficeOrigin } from 'pages/backoffice/api/use-allowed-backoffice-origin';
import { metadataApiErrorParser, MetadataApiErrorResponse } from 'pages/backoffice/api/utils';

interface RequestData {
  data: CreateRecordingApiParams;
  onSuccess?: () => void;
}

export const useCreateMetadataRecording = () => {
  const { getIsAllowedBackofficeOrigin } = useAllowedBackofficeOrigin();
  const invalidateRecordings = useInvalidateMetadataRecordings();
  const mutationRequest = useMutationRequest<unknown, unknown, MetadataApiErrorResponse>({
    type: HTTPMethod.POST,
    successMessage: 'Recording created',
    errorMessage: (data) => metadataApiErrorParser(data, 'Recording creation error'),
    onSuccess: invalidateRecordings,
  });

  const create = ({ data, onSuccess }: RequestData) => {
    if (!getIsAllowedBackofficeOrigin()) return;

    mutationRequest.mutate(
      {
        url: annotationApiUrls.RECORDINGS,
        data,
      },
      {
        onSuccess,
      },
    );
  };

  return { ...mutationRequest, create };
};
