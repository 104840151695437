import React, { ReactElement } from 'react';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';

interface Props {
  id: string;
  index: number;
  renderChildren?: (provided: DraggableProvided) => ReactElement;
  children?: ReactElement;
  isDragDisabled?: boolean;
}

export const SortableItem = ({ id, index, renderChildren, children, isDragDisabled = false }: Props) => {
  return (
    <Draggable draggableId={id} index={index} isDragDisabled={isDragDisabled}>
      {renderChildren && !children
        ? (draggableProvided) => renderChildren(draggableProvided)
        : (draggableProvided) => (
            <div
              style={{ marginBottom: '8px' }}
              ref={draggableProvided.innerRef}
              {...draggableProvided.draggableProps}
              {...draggableProvided.dragHandleProps}
            >
              {children}
            </div>
          )}
    </Draggable>
  );
};
