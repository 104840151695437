import { Stack, Typography } from '@mui/material';
import { TeamAvatar } from 'kognia-ui/components/avatar/TeamAvatar';
import { useTranslation } from 'react-i18next';
import { generatePath, useNavigate } from 'react-router-dom';

import { useCompetitionsState } from 'entities/competitions/store/steps';
import { routes } from 'kognia/router/routes';
import { ButtonOutlined } from 'shared/components/button-outlined/ButtonOutlined';
import { invariant } from 'shared/utils/invariant';

export const TeamProfileButton = () => {
  const { team } = useCompetitionsState();
  invariant(team, 'team is undefined in TeamProfileButton');
  const { t } = useTranslation('explore');
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(generatePath(routes.TEAM_PROFILE, { teamId: team.id }));
  };

  return (
    <ButtonOutlined onClick={handleClick} sx={{ p: ({ spacing }) => spacing(0.4, 1), minWidth: 'fit-content' }}>
      <Stack direction='row' alignItems='center' gap={1}>
        <TeamAvatar src={team.logoUrl} />
        <Typography component='span' variant='caption' fontWeight='fontWeightMedium'>
          {t('explore:competitions.team-profile')}
        </Typography>
      </Stack>
    </ButtonOutlined>
  );
};
