import { Box, FormLabel, Stack, styled } from '@mui/material';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';
import { PropsWithChildren } from 'react';

export const Label = styled(FormLabel)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  fontWeight: fontWeight['500'],
  fontSize: fontSizes.medium,
  lineHeight: fontSizes.medium,
}));

const LabelOptional = styled(Box)(() => ({
  fontSize: fontSizes.xSmall,
  color: Colors.storm,
  lineHeight: fontSizes.medium,
}));
type Props = {
  optional?: boolean;
};

export const FormFormLabel = ({ children, optional = false }: PropsWithChildren<Props>) => {
  return (
    <Stack direction={'row'} gap={1} alignItems={'center'} sx={{ marginTop: 1, marginBottom: 0.5 }}>
      <Label>{children}</Label> {optional ? <LabelOptional>Optional</LabelOptional> : null}
    </Stack>
  );
};
