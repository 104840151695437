import { UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { TacticalAnalysesFilters } from 'api/recording/useEpisodesWithFilters/types';
import { transformFilters } from 'api/recording/utils';
import { tacticalAnalysesFilters } from 'api/routes';

const generateQueryRef = (recordingIds: string[]) => [`fetchTacticalAnalysesFilters:${recordingIds.join(',')}`];

export const useTacticalAnalysesFilters = (recordingIds: string[]): UseQueryResult<TacticalAnalysesFilters> => {
  const fetchQueryRef = generateQueryRef(recordingIds);

  return useFetchRequest<TacticalAnalysesFilters>({
    queryRef: fetchQueryRef,
    url: tacticalAnalysesFilters(recordingIds),
    transformer: transformFilters,
    options: {
      enabled: recordingIds.length > 0,
    },
  });
};
