import { Button } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import { useIsBulkModeActive, useSetIsBulkModeActive } from 'entities/playlist/hooks/useIsBulkModeActive';
import { usePlaylistItems, useVideoPlayerActions } from 'shared/components/video-player';

type Props = {
  playlistId: string;
};

export const EnableBulkModeButton = ({ playlistId }: Props) => {
  const { t } = useTranslation();
  const playingItems = usePlaylistItems();
  const actions = useVideoPlayerActions();
  const enabledBulkMode = useIsBulkModeActive(playlistId);
  const setEnabledBulkMode = useSetIsBulkModeActive(playlistId);

  const handleSelectClick = () => {
    setEnabledBulkMode(!enabledBulkMode);

    actions.pause();
  };

  if (isEmpty(playingItems)) return null;

  return (
    <Button size='small' variant='outlined' color='secondary' onClick={handleSelectClick}>
      {t('common:actions.select')}
    </Button>
  );
};
