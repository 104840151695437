import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, styled } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';
import { IconShare } from 'kognia-ui/icons/IconShare';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath } from 'react-router-dom';

import { playlistPageQueryParams, routes } from 'kognia/router/routes';
import { Dialog } from 'shared/components/dialog';
import { DialogActions } from 'shared/components/dialog/dialog-actions';
import { DialogContent } from 'shared/components/dialog/dialog-content';
import { DialogContentText, DialogTextVariants } from 'shared/components/dialog/dialog-content-text';
import { DialogHeader } from 'shared/components/dialog/dialog-header';
import { useCurrentClient } from 'shared/contexts/app-state/hooks/useCurrentClient';
import { Playlist } from 'shared/types';

type SharePlaylistModalProps = {
  playlist: Playlist;
  onCloseModal: () => void;
  open?: boolean;
};

enum SharePlaylistOptions {
  View = 'view',
  Edit = 'edit',
}

type ShareCheckboxValue = SharePlaylistOptions.View | SharePlaylistOptions.Edit;

const CopyBarContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4.5),
  minHeight: theme.spacing(6),
  border: `1px solid ${Colors.storm}`,
  padding: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  display: `flex`,
  flexDirection: 'row',
  alignItems: 'center',
}));

const CopyBarUrl = styled(Box)({
  color: Colors.storm,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const FormLabel = styled(Box)({
  fontSize: fontSizes.default,
});

const SharePlaylistModal = ({ playlist, onCloseModal, open = true }: SharePlaylistModalProps) => {
  const { t } = useTranslation();
  const [userCopiedUrl, setUserCopiedUrl] = useState(false);
  const [shareCheckboxValue, setShareCheckboxValue] = useState<ShareCheckboxValue>(SharePlaylistOptions.View);
  const currentClient = useCurrentClient();
  const { origin } = window.location;
  const { id, name } = playlist;
  const playlistDetailUrl = origin + generatePath(routes.RECORDING_PLAYLIST_DETAIL, { playlistId: id });
  const copyPlaylistUrl = `${origin}${routes.PLAYLISTS}?${playlistPageQueryParams.copyPlaylist}=${id}`;
  const urlToCopy = shareCheckboxValue === SharePlaylistOptions.View ? playlistDetailUrl : copyPlaylistUrl;

  const closeModal = useCallback(() => onCloseModal(), [onCloseModal]);

  const copyUrl = () => {
    navigator.clipboard.writeText(urlToCopy).then(() => {
      setUserCopiedUrl(true);
    });
  };

  const handleShareCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setShareCheckboxValue(event.target.value as ShareCheckboxValue);
    setUserCopiedUrl(false);
  }, []);

  return (
    <Dialog open={open} onClose={onCloseModal}>
      <DialogHeader icon={<IconShare size='small' />}>{t('share-playlist-modal:title')}</DialogHeader>
      <DialogContent>
        <DialogContentText textVariant={DialogTextVariants.Primary}>
          {t('share-playlist-modal:subtitle', { name })}
        </DialogContentText>
        <DialogContentText>
          {t('share-playlist-modal:description', {
            clientName: currentClient?.name,
          })}
        </DialogContentText>
        <Box>
          <FormControl>
            <RadioGroup
              aria-labelledby='share-option-type'
              name='share-option-type'
              onChange={handleShareCheckboxChange}
              value={shareCheckboxValue}
            >
              <FormControlLabel
                value={SharePlaylistOptions.View}
                control={<Radio color='primary' />}
                label={<FormLabel>{t('share-playlist-modal:share-original')}</FormLabel>}
              />
              <FormControlLabel
                value={SharePlaylistOptions.Edit}
                control={<Radio color='primary' />}
                label={<FormLabel>{t('share-playlist-modal:share-copy')}</FormLabel>}
              />
            </RadioGroup>
          </FormControl>
        </Box>
        <CopyBarContainer>
          <CopyBarUrl id='copy-url-contents'>{urlToCopy}</CopyBarUrl>
          <Button
            variant='contained'
            size='small'
            color={userCopiedUrl ? 'success' : 'primary'}
            onClick={copyUrl}
            sx={{ minWidth: 0, flex: '1 0 auto' }}
          >
            {userCopiedUrl ? t('common:actions.copied') : t('common:actions.copy')}
          </Button>
        </CopyBarContainer>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' color='secondary' onClick={closeModal}>
          {t('common:actions.done')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SharePlaylistModal;
