import { List, ListItemButton, Stack } from '@mui/material';
import { useCallback, useRef } from 'react';

import { useCompetitionsInfinityQuery } from 'api/competitions/use-competitions-infinity-query/useCompetitionsInfinityQuery';
import { useSetCompetitionsState } from 'entities/competitions/store/steps';
import { CompetitionRow } from 'features/competitions/competitions-step/ui/competition-row/CompetitionRow';
import Spinner from 'shared/components/spinner';
import { useIntersectionObserver } from 'shared/hooks/use-intersection-observer/useIntersectionObserver';
import { Competition } from 'shared/types/competition/competition';

export const CompetitionsStepFeature = () => {
  const { items, isFetching, fetchNextPage, hasNextPage } = useCompetitionsInfinityQuery();
  const lastCompetitionRef = useRef<HTMLDivElement | null>(null);
  const setCompetitionsState = useSetCompetitionsState();

  const handleObserver = useCallback(() => {
    if (!hasNextPage || isFetching) {
      return;
    }

    fetchNextPage();
  }, [fetchNextPage, hasNextPage, isFetching]);

  useIntersectionObserver(handleObserver, lastCompetitionRef);

  const handleGotToTeams = (competition: Competition) => {
    const defaultSeason = competition.seasons.toSorted((a, b) => b.year - a.year)[0];

    setCompetitionsState({
      step: 'teams',
      competition,
      season: defaultSeason,
    });
  };

  return (
    <Stack>
      {items.length > 0 && (
        <List disablePadding>
          {items.map((competition, index) => (
            <ListItemButton
              key={competition.id}
              ref={index === items.length - 1 ? lastCompetitionRef : null}
              sx={{ p: 0, borderBottom: ({ palette }) => `1px solid ${palette.divider}` }}
              onClick={() => handleGotToTeams(competition)}
            >
              <CompetitionRow country={competition.country} name={competition.name} logoUrl={competition.logoUrl} />
            </ListItemButton>
          ))}
        </List>
      )}

      {isFetching && (
        <Stack alignItems={'center'}>
          <Spinner />
        </Stack>
      )}
    </Stack>
  );
};
