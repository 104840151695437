import React from 'react';

import { KeypadTag } from 'api/tagging-tool/types';
import { TagButtonBase, TagButtonProps } from 'pages/tagging-tool-tag-recording/components/tag-button/TagButtonBase';

const LiveStreamTagButton = ({
  videoStartRecordingTime,
  ...rest
}: TagButtonProps & { videoStartRecordingTime?: number; onPress?: (time: number) => (tag: KeypadTag) => void }) => {
  const onPress = !rest.onPress ? undefined : rest.onPress((new Date().getTime() - videoStartRecordingTime!) / 1000);

  const buttonProps = {
    ...rest,
    onPress,
  };

  return <TagButtonBase {...buttonProps} />;
};

export default LiveStreamTagButton;
