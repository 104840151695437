import { Box, Button, Link, styled, Typography } from '@mui/material';
import { animationDurations, animations, Colors, fontSizes, fontWeight } from 'kognia-ui';

import IconCircleBackground from 'shared/assets/icon-circle-background.svg';

export const ErrorContainerWrapper = styled(Box)(() => ({
  minHeight: '100%',
  display: 'flex',
  width: '100%',
  animation: `${animations.fadeIn} ${animationDurations.medium} ease-out`,
}));

export const ErrorContainerContent = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignContent: 'center',
  alignItems: 'center',
  height: '100%',
  paddingBottom: '15%',
}));

export const ErrorContainerTitle = styled(Typography)(({ theme }) => ({
  fontSize: fontSizes.xLarge,
  color: Colors.primary,
  fontWeight: fontWeight['700'],
  textAlign: 'center',
  marginBottom: theme.spacing(1),
}));

export const ErrorContainerText = styled(Typography)(() => ({
  fontSize: fontSizes.medium,
  textAlign: 'center',
}));

export const ErrorContainerButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(6),
  minWidth: '288px',
}));

export const ErrorContainerLink = styled(Link)(({ theme }) => ({
  '&.MuiLink-root': {
    color: Colors.shark,
    fontWeight: theme.typography.fontWeightMedium,
    textDecoration: 'none',
    borderBottom: `1px solid ${Colors.shark}`,
    '&::after': {
      display: 'none !important',
    },
    '&:hover': {
      color: Colors.linkHover,
      borderBottomColor: Colors.linkHover,
    },
  },
}));

export const ErrorContainerImage = styled(Box)(({ theme }) => ({
  width: '236px',
  height: '236px',
  background: `url("${IconCircleBackground}")`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginBottom: theme.spacing(3.5),
}));
