import { useAtom } from 'jotai';
import { useCallback } from 'react';

import { getUrlFiltersState } from 'shared/hooks/use-url-filters/store/filtersStore';
import { State } from 'shared/hooks/use-url-filters/types/state';
import { FiltersList } from 'shared/types';

export const useUrlFiltersState = <T extends FiltersList>(stateId: string) => {
  const [filters, setFilters] = useAtom<State<T>>(getUrlFiltersState(stateId));

  const setFilter = useCallback(
    <K extends keyof T>(filterName: K, filterValue: string[]) => {
      setFilters((prev) => {
        return { ...prev, [filterName]: filterValue };
      });
    },
    [setFilters],
  );

  const removeFilters = useCallback(
    <K extends keyof T>(filters: K[]) => {
      setFilters((prev) => {
        const removedFilters = filters.reduce<Partial<State<T>>>((acc, filterName) => {
          acc[filterName] = [];
          return acc;
        }, {});

        return { ...prev, ...removedFilters };
      });
    },
    [setFilters],
  );

  return { filters, setFilter, removeFilters };
};
