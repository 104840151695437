import { validateBackofficeOrigin } from 'pages/backoffice/api/routes';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';

export const useAllowedBackofficeOrigin = () => {
  const triggerNotification = useNotifications();
  const origin = window.location.origin;

  const validateOrigin = () => {
    if (!validateBackofficeOrigin(origin)) {
      triggerNotification({
        type: NotificationType.ERROR,
        message: 'This origin is not allowed to perform backoffice requests',
      });
      return false;
    }
    return true;
  };

  return {
    getIsAllowedBackofficeOrigin: validateOrigin,
    isAllowedBackofficeOrigin: validateBackofficeOrigin(origin),
  };
};
