import { useMemo } from 'react';

import { useBulkSelectedItems } from 'entities/playlist/hooks/useBulkSelectedItems';
import { useIsBulkModeActive } from 'entities/playlist/hooks/useIsBulkModeActive';
import { SelectedPlaylistItems } from 'features/playlist/playlist-overlay-selector-panel/types/PlaylistOverlaySelector.types';
import { useCurrentPlaylistItem, usePlaylistItems } from 'shared/components/video-player';

export const useActivePlaylistItemIds = (playlistId: string): SelectedPlaylistItems => {
  const playlistItems = usePlaylistItems();
  const playlistItem = useCurrentPlaylistItem();
  const isBulkModeActive = useIsBulkModeActive(playlistId);
  const bulkSelectedPlaylistItems = useBulkSelectedItems(playlistId);

  return useMemo(() => {
    const playlistItemsIds = isBulkModeActive ? bulkSelectedPlaylistItems : [playlistItem.id];

    return playlistItemsIds.reduce<SelectedPlaylistItems>(
      (selectedPlaylistItems, id) => {
        const playlistItem = playlistItems.find((playlistItem) => playlistItem.id === id);

        if (!playlistItem || !playlistItem.hasHomographies || !playlistItem.showOverlays) return selectedPlaylistItems;

        return {
          ids: [...selectedPlaylistItems.ids, playlistItem.id],
          playlistItems: [...selectedPlaylistItems.playlistItems, playlistItem],
        };
      },
      { ids: [], playlistItems: [] },
    );
  }, [playlistItems, isBulkModeActive, bulkSelectedPlaylistItems, playlistItem]);
};
