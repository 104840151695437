import { Alert } from '@mui/material';
import React from 'react';
import { TypeOf, z } from 'zod';

import { useMetadataFixture } from 'pages/backoffice/api/fixtures/use-metadata-fixture';
import { AddRecordingIdDialogForm } from 'pages/backoffice/pages/fixtures/components/metadata-fixture-item/add-recording-id-dialog/add-recording-id-dialog-form';
import { Dialog } from 'shared/components/dialog';

type Props = {
  setIsOpen: (isOpen: boolean) => void;
  isOpen: boolean;
  fixtureId: string;
};

export enum NewRecordingFormFieldsNames {
  clientIds = 'clientIds',
  id = 'id',
}

export const newRecordingSchema = z.object({
  [NewRecordingFormFieldsNames.clientIds]: z.array(z.string()),
  [NewRecordingFormFieldsNames.id]: z.string().trim().min(2),
});

export type NewRecordingForm = TypeOf<typeof newRecordingSchema>;

export const AddRecordingIdDialog = ({ setIsOpen, fixtureId, isOpen }: Props) => {
  const { data: fixture, isLoading } = useMetadataFixture({ fixtureId });
  const handleCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} maxWidth='sm' fullWidth onClose={handleCancel}>
      {!fixture && !isLoading ? <Alert severity='error'>Fixture not found</Alert> : null}
      {fixture ? (
        <AddRecordingIdDialogForm fixture={fixture} onSuccess={() => setIsOpen(false)} onCancel={handleCancel} />
      ) : null}
    </Dialog>
  );
};
