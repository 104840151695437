import { getPlaylistItemFirstVideoSource } from 'entities/playlist/util/getPlaylistItemFirstVideoSource';
import { PlaylistItemType } from 'shared/components/video-player/types';

export const generateCopyToPlaylistItem = (playlistItem: PlaylistItemType) => {
  const playlistItemVideoSource = getPlaylistItemFirstVideoSource(playlistItem);

  return {
    startTime: playlistItemVideoSource?.startTime ?? 0,
    endTime: playlistItemVideoSource?.endTime ?? 0,
    name: playlistItem.name ?? '',
    recordingId: playlistItem.recordingId ?? '',
    fundamentalsSelected: playlistItem.fundamentalsSelected,
  };
};
