export const applyResizeLimits = (
  mainPanelSize: number,
  secondaryPanelSize: number,
  mainPanelMinSize: number,
  secondaryPanelMinSize: number,
) => {
  const isMainPanelSizeValid = mainPanelSize > mainPanelMinSize;
  const isSecondaryPanelSizeValid = secondaryPanelSize >= secondaryPanelMinSize;

  if (!isSecondaryPanelSizeValid && isMainPanelSizeValid) {
    return mainPanelSize - (secondaryPanelMinSize - secondaryPanelSize);
  }

  if (isMainPanelSizeValid) {
    return mainPanelSize;
  }

  return mainPanelMinSize;
};
