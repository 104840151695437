import i18next from 'kognia/i18n';
import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';

interface Params {
  clips: Clip[];
}

interface GetOverlappingClipsParams {
  clips: Clip[];
  clip: Clip;
  rowId: string;
  initialUsedClips?: string[];
}

interface CreateParentClipParams {
  startTime: number;
  endTime: number;
  rowId: string;
  childClips: Clip[];
  elementId?: string;
  title?: string;
  action?: string;
  teamId?: string;
}

const createParentClip = ({
  startTime,
  endTime,
  rowId,
  childClips,
  elementId,
  title,
  action,
  teamId,
}: CreateParentClipParams) => {
  const parentClipId = `${rowId}-${startTime}-${endTime}-parent-clip`;

  const clips = childClips.map((clip) => {
    return {
      ...clip,
      parentClipId,
    };
  });

  const defaultTitle = i18next.t('timeline:parent-clip-title', {
    clipsCount: clips.length,
    firstClipTitle: clips[0].title,
  });

  return <Clip>{
    id: parentClipId,
    title: title,
    titleForPlaylist: defaultTitle,
    startTime: startTime,
    endTime: endTime,
    clips,
    rowId,
    elementId,
    ...(action && { action }),
    ...(teamId && { teamId }),
    type: 'parent-clip',
  };
};

type CheckForOverlappingClipsResult = {
  parentClip: Clip | null;
  usedClips: string[];
};

const checkForOverlappingClips = ({
  clips,
  clip,
  rowId,
  initialUsedClips = [],
}: GetOverlappingClipsParams): CheckForOverlappingClipsResult => {
  const usedClips: string[] = initialUsedClips;
  const overlappingClips = clips.filter(
    (c) =>
      clip.rowId === c.rowId &&
      usedClips.includes(c.id) === false &&
      ((clip.startTime > c.startTime && clip.startTime < c.endTime) ||
        (clip.endTime > c.startTime && clip.endTime <= c.endTime) ||
        (c.startTime > clip.startTime && c.startTime < clip.endTime) ||
        (c.endTime > clip.startTime && c.endTime < clip.endTime)),
  );
  const hasOverlappingClips = overlappingClips.length > 0;

  if (hasOverlappingClips) {
    const clipsInScope = clip?.clips?.length ? [...clip.clips, ...overlappingClips] : overlappingClips;
    usedClips.push(...clipsInScope.map((c) => c.id));
    usedClips.push(clip.id);

    const elementId = clipsInScope.every((item) => item.elementId === clipsInScope[0].elementId)
      ? clipsInScope[0].elementId
      : '';

    const title = clipsInScope.every((item) => item.title === clipsInScope[0].title) ? clipsInScope[0].title : '';
    const action = clipsInScope.every((item) => item.action === clipsInScope[0].action) ? clipsInScope[0].action : '';
    const teamId = clipsInScope.every((item) => item.teamId === clipsInScope[0].teamId) ? clipsInScope[0].teamId : '';

    const parentClip = createParentClip({
      startTime: Math.min(...clipsInScope.map((c) => c.startTime)),
      endTime: Math.max(...clipsInScope.map((c) => c.endTime)),
      rowId,
      action,
      teamId,
      elementId,
      title,
      childClips: clip?.clips?.length ? [...clip.clips, ...overlappingClips] : overlappingClips,
    });

    const filteredClips = clips.filter((c) => !usedClips.includes(c.id));
    return checkForOverlappingClips({
      clips: filteredClips,
      clip: parentClip,
      rowId,
      initialUsedClips: usedClips,
    });
  }

  return {
    parentClip: clip.clips && clip.clips?.length > 1 ? clip : null,
    usedClips: Array.from(new Set(usedClips)),
  };
};

export const generateClipsWithOverlappingTime = ({ clips }: Params): Clip[] => {
  const resultClips: Clip[] = [];
  const usedClips: string[] = [];

  clips
    .sort((a, b) => a.startTime - b.startTime)
    .forEach((clip) => {
      if (usedClips.includes(clip.id)) {
        return;
      }

      const overlappingClips = checkForOverlappingClips({ clips, clip, rowId: clip.rowId });

      if (overlappingClips.parentClip) {
        resultClips.push(overlappingClips.parentClip);
        usedClips.push(...overlappingClips.usedClips);
      } else {
        resultClips.push(clip);
      }
    });

  return resultClips;
};
