import { Box, styled } from '@mui/material';

export const TagEventsSkeletonContainer = styled(Box)(() => ({
  position: 'relative',
}));

export const TagEventsSkeletonContent = styled(Box)(() => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}));
