import { IconPlaylist } from 'kognia-ui/icons/IconPlaylist';
import { useTranslation } from 'react-i18next';

import styles from 'pages/playlists/components/playlist-number-of-clips/PlaylistsNumberOfClips.module.scss';

interface Props {
  number: number;
}

const PlaylistNumberOfClips = ({ number }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.numberOfClips}>
      <IconPlaylist />
      {t('playlists:card.clips', { count: number })}
    </div>
  );
};

export default PlaylistNumberOfClips;
