import { styled, FormLabel, FormLabelProps } from '@mui/material';
import classnames from 'classnames';
import React from 'react';

// NOTE: It's used in FilterZone.module.scss file
const LABEL_REQUIRED_CLASS_NAME = 'checkbox-label--checked';
const CONTENT_REQUIRED_CLASS_NAME = 'checkbox__content';

type Props = React.HTMLProps<HTMLInputElement> & {
  zone: number;
  id: string | number;
  labelProps?: FormLabelProps;
};

export const Label = styled(FormLabel)({
  display: 'flex',
  flexWrap: 'wrap',
  overflow: 'hidden',
});

export const CheckboxZone = ({ zone, id, checked, labelProps, ...restProps }: Props) => {
  const idString = id.toString();

  return (
    <Label className={classnames(checked && LABEL_REQUIRED_CLASS_NAME)} htmlFor={idString} {...labelProps}>
      <input id={idString} type='checkbox' hidden checked={checked} {...restProps} />
      <span className={CONTENT_REQUIRED_CLASS_NAME}>{zone}</span>
    </Label>
  );
};
