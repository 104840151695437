import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

export const SectionContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

export const SectionContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
}));

export const SectionGroup = styled(Box)(({ theme }) => ({
  display: 'flex',
  flex: '1',
  gap: theme.spacing(1),
}));

export const SectionChild = styled(Box)(() => ({
  flex: 1,
  border: `1px solid ${Colors.white}`,
}));
