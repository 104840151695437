import { MenuList, Stack, Tab, Tabs, Typography } from '@mui/material';
import { SyntheticEvent, UIEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EXPLORE_SEARCH_ID, MENU_ID, tabs } from 'entities/dashboard/constants/dashboardSearch';
import { useSelectResultHandler } from 'entities/dashboard/hooks/useSelectResultHandler';
import { SearchResult, SearchType } from 'entities/dashboard/types/dashboardSearch';
import { searchResultsTabsKeys } from 'features/dashboard/dashboard-search-results-tabs/constants/dashboardSearchResultsTabs';
import { DashboardSearchOption } from 'features/dashboard/dashboard-search-results-tabs/DashboardSearchOption';
import { TabPanel } from 'shared/components/tabs/components/tab-panel';

type Props = {
  searchResults: readonly SearchResult[];
  onSelect?: (data: SearchResult) => void;
  noResultsMessage: string;
  onEscape?: () => void;
  onScroll?: (event: UIEvent<HTMLElement>) => void;
  title: string;
  totals: {
    [SearchType.ALL]: number;
    [SearchType.PLAYER]: number;
    [SearchType.TEAM]: number;
  };
};

const scrollToItem = (index: number, menuListRef: React.RefObject<HTMLUListElement>) => {
  if (!menuListRef.current) return;

  const menuItems = menuListRef.current.querySelectorAll('li');
  if (menuItems && menuItems[index]) {
    (menuItems[index] as HTMLElement).scrollIntoView({ block: 'center', inline: 'nearest' });
  }
};

export const DashboardSearchResultsTabsFeature = ({
  noResultsMessage,
  onEscape,
  onSelect,
  searchResults,
  onScroll,
  title,
  totals,
}: Props) => {
  const menuListRef = useRef<HTMLUListElement | null>(null);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const selectResultHandler = useSelectResultHandler();
  const { t } = useTranslation('explore');

  const handleChangeTab = useCallback((newValue: number) => {
    setSelectedIndex(0);
    setSelectedTab(newValue);
  }, []);

  const handleSelectPreviousTab = useCallback(() => {
    handleChangeTab(selectedTab > 0 ? selectedTab - 1 : tabs.length - 1);
  }, [selectedTab, handleChangeTab]);

  const handleSelectNextTab = useCallback(() => {
    handleChangeTab(selectedTab < tabs.length - 1 ? selectedTab + 1 : 0);
  }, [selectedTab, handleChangeTab]);

  const tabsSearchResults = useMemo(() => {
    return tabs.map((tab) => searchResults.filter((result) => tab === SearchType.ALL || result.type === tab));
  }, [searchResults]);

  const handleChangeTabs = (_: SyntheticEvent, newValue: number) => {
    handleChangeTab(newValue);
  };

  useEffect(() => {
    if (!menuListRef.current) return;
    scrollToItem(selectedIndex, menuListRef);
  }, [selectedIndex, selectedTab]);

  const handleSelect = useCallback(
    (result: SearchResult) => {
      selectResultHandler(result);
      onSelect && onSelect(result);
    },
    [onSelect, selectResultHandler],
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      const tabSearchResults = tabsSearchResults[selectedTab];

      switch (event.key) {
        case 'ArrowLeft':
          if ((event.target as HTMLElement).getAttribute('role') === 'tab') return;

          event.preventDefault();
          event.stopPropagation();
          handleSelectPreviousTab();
          break;
        case 'ArrowRight':
          if ((event.target as HTMLElement).getAttribute('role') === 'tab') return;

          event.preventDefault();
          event.stopPropagation();
          handleSelectNextTab();
          break;
        case 'ArrowDown':
          if (!tabSearchResults.length) return;
          if ((event.target as HTMLElement).getAttribute('role') === 'tab') {
            (event.target as HTMLElement)?.blur();
          }

          event.preventDefault();
          event.stopPropagation();
          if (selectedIndex < tabSearchResults.length - 1) {
            setSelectedIndex((prevIndex: number) => {
              const nextIndex = (prevIndex + 1) % tabSearchResults.length;
              scrollToItem(nextIndex, menuListRef);
              return nextIndex;
            });
          }
          break;
        case 'ArrowUp':
          if (!tabSearchResults.length) return;
          if ((event.target as HTMLElement).getAttribute('role') === 'tab') {
            document.getElementById(EXPLORE_SEARCH_ID)?.focus();
            return;
          }
          if (selectedIndex === 0) {
            document.getElementById(EXPLORE_SEARCH_ID)?.focus();
            return;
          }
          event.preventDefault();
          event.stopPropagation();

          setSelectedIndex((prevIndex) => {
            const previousIndex = prevIndex <= 0 ? tabSearchResults.length - 1 : prevIndex - 1;
            scrollToItem(previousIndex, menuListRef);
            return previousIndex;
          });
          break;
        case 'Escape':
          event.preventDefault();

          document.getElementById(EXPLORE_SEARCH_ID)?.focus();
          onEscape && onEscape();
          break;
        case 'Enter':
          event.preventDefault();
          if (!tabSearchResults.length) return;
          if (!tabSearchResults[selectedIndex]) return;

          handleSelect(tabSearchResults[selectedIndex]);
          break;
        default:
          break;
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [
    handleSelectNextTab,
    handleSelectPreviousTab,
    handleSelect,
    searchResults,
    selectedIndex,
    handleChangeTab,
    selectedTab,
    tabsSearchResults,
    onEscape,
  ]);

  const tabSearchResults = useMemo(() => tabsSearchResults[selectedTab], [tabsSearchResults, selectedTab]);

  if (searchResults.length === 0)
    return (
      <Stack height={'56px'} justifyContent={'center'} pl={2}>
        <Typography variant={'caption'} fontWeight={'fontWeightMedium'}>
          {noResultsMessage}
        </Typography>
      </Stack>
    );

  return (
    <>
      <Tabs value={selectedTab} onChange={handleChangeTabs}>
        {tabs.map((tab, index) => (
          <Tab
            key={tab}
            onFocus={() => handleChangeTab(index)}
            data-index={index}
            label={
              <Stack direction={'row'} alignItems={'center'} gap={1}>
                {t(searchResultsTabsKeys[tab])}
                <Typography component={'span'} variant={'caption'}>
                  ({totals[tab]})
                </Typography>
              </Stack>
            }
          />
        ))}
      </Tabs>
      <Stack direction={'row'} alignItems={'center'} minHeight={'48px'}>
        {tabSearchResults.length > 0 ? (
          <Typography variant={'caption'} pl={2} fontWeight={'fontWeightMedium'}>
            {title}
          </Typography>
        ) : (
          <Typography variant={'caption'} pl={2} fontWeight={'fontWeightMedium'}>
            {noResultsMessage}
          </Typography>
        )}
      </Stack>
      {tabs.map((tab, index) => {
        const tabSearchResults = tabsSearchResults[index];
        const filteredSearchResults = tabSearchResults.filter(
          (result) => tab === SearchType.ALL || result.type === tab,
        );
        return (
          <TabPanel
            key={tab}
            value={selectedTab}
            index={index}
            sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}
            panelContainerSx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              maxHeight: 168,
              px: 0,
            }}
          >
            <MenuList
              ref={menuListRef}
              id={MENU_ID}
              aria-labelledby={EXPLORE_SEARCH_ID}
              onScroll={onScroll}
              component={'ul'}
              sx={{ py: 0, overflowY: 'auto', scrollBehavior: 'smooth', pr: 1 }}
            >
              {filteredSearchResults.map((option) => (
                <DashboardSearchOption
                  key={option.value}
                  option={option}
                  onSelect={onSelect}
                  selected={selectedIndex === filteredSearchResults.indexOf(option)}
                />
              ))}
            </MenuList>
          </TabPanel>
        );
      })}
    </>
  );
};
