import { Box, styled } from '@mui/material';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { Colors, fontSizes, fontWeight } from 'kognia-ui';
import React from 'react';

interface Props {
  children: React.ReactNode;
  icon?: React.ReactNode;
}

const BackofficeSectionWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'uppercase',
  flexDirection: 'column',
  padding: theme.spacing(3),
}));

const BackofficeSectionTitle = styled(MuiDialogTitle)({
  fontSize: fontSizes.default,
  padding: 0,
  letterSpacing: 2,
  fontWeight: fontWeight['500'],
  color: Colors.night,
});

const BackofficeSectionIcon = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2.5),
  backgroundColor: Colors.background,
  borderRadius: '50%',
  marginBottom: theme.spacing(2),
}));

export const BackofficeSection = ({ children, icon }: Props) => {
  return (
    <BackofficeSectionWrapper>
      {icon && <BackofficeSectionIcon>{icon}</BackofficeSectionIcon>}
      <BackofficeSectionTitle>{children}</BackofficeSectionTitle>
    </BackofficeSectionWrapper>
  );
};
