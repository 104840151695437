import { useMemo } from 'react';

import { useMapVideos } from 'entities/playlist/hooks/use-map-videos/useMapVideos';
import { VideoPlayerStateProvider } from 'shared/components/video-player';
import { PLAYLIST_WITH_OVERLAYS_PLAYING_MODE } from 'shared/components/video-player/defaultPlayingModes';
import { Playlist } from 'shared/types';
import { UserPlaylist } from 'widgets/playlist/user-playlist/ui/UserPlaylist';

type Props = {
  playlist: Playlist;
};

export const UserPlaylistWidget = ({ playlist }: Props) => {
  const mapVideos = useMapVideos();
  const playlistItems = useMemo(() => (playlist ? mapVideos(playlist) : []), [mapVideos, playlist]);

  return (
    <VideoPlayerStateProvider
      playerId={`playlist-${playlist.id}`}
      playlistItems={playlistItems}
      playingMode={PLAYLIST_WITH_OVERLAYS_PLAYING_MODE}
    >
      <UserPlaylist playlist={playlist} />
    </VideoPlayerStateProvider>
  );
};
