import { IconButton, Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { IconPlay } from 'kognia-ui/icons/IconPlay';
import React, { ChangeEventHandler, useCallback, useState } from 'react';
import { useResetRecoilState, useSetRecoilState } from 'recoil';

import { TaggingEvent } from 'api/tagging-tool/types';
// eslint-disable-next-line import/no-restricted-paths
import { usePlaylistMenuId } from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/add-to-playlist-menu-state';
import {
  playlistMenuAtoms,
  PlaylistMenuItem,
  // eslint-disable-next-line import/no-restricted-paths
} from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/add-to-playlist-menu-state/atoms';
// eslint-disable-next-line import/no-restricted-paths
import { generatePlaylistMenuItemId } from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/add-to-playlist-menu-state/hooks';
import {
  TaggingEventClipContainer,
  TaggingEventClipEnd,
  TaggingEventEditableText,
  TaggingEventEditableTextContainer,
} from 'pages/tagging-tool-tag-recording/components/tag-event-clip/styled';
import { TagEventClipActions } from 'pages/tagging-tool-tag-recording/components/tag-event-clip-actions';
import { TagEventClipMenu } from 'pages/tagging-tool-tag-recording/components/tag-event-clip-menu';
import { CheckboxWithCustomColor } from 'shared/components/CheckboxWithCustomColor/CheckboxWithCustomColor';
import { TypeCounter } from 'shared/components/type-counter';
import { getIconPlayColor } from 'shared/utils/get-icon-play-color';
import { TaggingEventClipTypeBackgroundColor, TaggingEventClipTypeColor } from 'shared/utils/tagging-event-clip-type';
import { fetchRenameTaggingEvent } from 'tagging-tool/service/taggingEvent.service';
import { secondsToTimeString } from 'tagging-tool/utility/date';

const usePlaylistMenuItemId = (clipId: string) => {
  const playlistMenuId = usePlaylistMenuId();

  return generatePlaylistMenuItemId(playlistMenuId, clipId);
};

export const useAddPlaylistMenuItem = (clipId: string) => {
  const playlistMenuId = usePlaylistMenuId();
  const playlistItemId = usePlaylistMenuItemId(clipId);
  const setPlaylistMenuItems = useSetRecoilState(playlistMenuAtoms.playlistMenuState(playlistMenuId));
  const setPlaylistItemValue = useSetRecoilState(playlistMenuAtoms.playlistMenuItem(playlistItemId));

  return useCallback(
    (clip: PlaylistMenuItem) => {
      setPlaylistItemValue(clip);
      setPlaylistMenuItems((items: string[]) => {
        return [...items, clipId];
      });
    },
    [clipId, setPlaylistItemValue, setPlaylistMenuItems],
  );
};

export const useRemovePlaylistMenuItem = (clipId: string) => {
  const playlistMenuId = usePlaylistMenuId();
  const playlistItemId = usePlaylistMenuItemId(clipId);
  const setPlaylistMenuItems = useSetRecoilState(playlistMenuAtoms.playlistMenuState(playlistMenuId));
  const reset = useResetRecoilState(playlistMenuAtoms.playlistMenuItem(playlistItemId));

  return useCallback(() => {
    reset();
    setPlaylistMenuItems((items: string[]) => {
      const index = items.indexOf(clipId);

      if (index === -1) return items;
      return [...items.slice(0, index), ...items.slice(index + 1)];
    });
  }, [clipId, reset, setPlaylistMenuItems]);
};

type ClipProps = {
  taggingEvent: TaggingEvent;
  counter: number;
  hasVideo: boolean;
  disabled: boolean;
  selected: boolean;
  isMultiselectModeActive?: boolean;
  onPlay: (taggingEvent: TaggingEvent) => void;
  onDelete: (id: string) => void;
  onRename: (taggingEvent: TaggingEvent) => void;
  onTrim: (taggingEvent: TaggingEvent) => void;
};

export const TaggingEventClip = ({
  taggingEvent,
  counter,
  hasVideo,
  disabled,
  selected,
  isMultiselectModeActive = false,
  onPlay,
  onDelete,
  onRename,
  onTrim,
}: ClipProps) => {
  const { id, time, timeAfter, timeBefore, typeOfPlay } = taggingEvent;

  const [name, setName] = useState<string>(taggingEvent.name);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [renaming, setRenaming] = useState<boolean>(false);
  const [renamingLoading, setRenamingLoading] = useState<boolean>(false);
  const addPlaylistItem = useAddPlaylistMenuItem(id);
  const removePlaylistItem = useRemovePlaylistMenuItem(id);

  const resetUI = useCallback(() => {
    setMenuOpen(false);
    setRenamingLoading(false);
    setRenaming(false);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
    resetUI();
  }, [resetUI]);

  const handleRename = useCallback((_event: TaggingEvent) => {
    setMenuOpen(false);
    setRenaming(true);
  }, []);

  const handleRenameCancel = useCallback(() => {
    resetUI();
  }, [resetUI]);

  const handlePlay = () => {
    onPlay(taggingEvent);
  };

  const handleRenameConfirm = useCallback(() => {
    setRenamingLoading(true);

    const { recordingId, id: taggingEventId } = taggingEvent;
    fetchRenameTaggingEvent({ recordingId, taggingEventId, name }).then((res) => {
      resetUI();
      if (!res.error) {
        onRename({ ...taggingEvent, name });
      }
    });
  }, [resetUI, taggingEvent, name, onRename]);

  const startTime = time - timeBefore > 0 ? time - timeBefore : 0;
  const endTime = time + timeAfter;

  const handleOnChange = (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    checked
      ? addPlaylistItem({
          id: id,
          endTime: time + timeAfter,
          startTime: time - timeBefore > 0 ? time - timeBefore : 0,
          playlistId: '',
          name: name,
        })
      : removePlaylistItem();
  };

  const handleEditNameInputChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
    event.preventDefault();
    setName(event.target.value);
  }, []);

  return (
    <TaggingEventClipContainer multiselect={isMultiselectModeActive} key={id}>
      {isMultiselectModeActive && (
        <CheckboxWithCustomColor
          customColor='typography'
          checked={selected}
          onClick={(event?: React.MouseEvent<HTMLButtonElement>) => {
            event && event.stopPropagation();
          }}
          onChange={handleOnChange}
        />
      )}

      <TypeCounter
        color={TaggingEventClipTypeColor[typeOfPlay]}
        backgroundColor={TaggingEventClipTypeBackgroundColor[typeOfPlay]}
        disabled={disabled}
      >
        {`${counter}`.padStart(3, '0')}
      </TypeCounter>

      {hasVideo && (
        <IconButton size='small' disabled={disabled} onClick={handlePlay}>
          <IconPlay size='small' sx={{ color: getIconPlayColor({ disabled, typeOfPlay }) }} />
        </IconButton>
      )}

      <TaggingEventEditableTextContainer>
        {renaming ? (
          <TaggingEventEditableText value={name} onChange={handleEditNameInputChange} size='small' fullWidth />
        ) : (
          name
        )}
      </TaggingEventEditableTextContainer>
      <TaggingEventClipEnd>
        {renaming && (
          <TagEventClipActions
            loading={renamingLoading}
            onConfirm={handleRenameConfirm}
            onCancel={handleRenameCancel}
          />
        )}
        {!disabled && !renaming && (
          <Typography fontWeight='fontWeightMedium' fontSize={fontSizes.default}>
            {secondsToTimeString(startTime)} - {secondsToTimeString(endTime)}
          </Typography>
        )}
        {!renaming && !isMultiselectModeActive && (
          <TagEventClipMenu
            hasVideo={hasVideo}
            initialShow={menuOpen}
            onClose={handleMenuClose}
            event={taggingEvent}
            onDelete={() => {
              setMenuOpen(false);
              onDelete(taggingEvent.id);
            }}
            onRename={handleRename}
            onTrim={onTrim}
          />
        )}
      </TaggingEventClipEnd>
    </TaggingEventClipContainer>
  );
};
