import isEmpty from 'lodash/isEmpty';

import { isFullMatchVideo } from 'shared/components/video-player/is-full-match-video';
import {
  PlayerStatePlaylist,
  PlayerType,
  PlayingMode,
  PlayingModes,
  VideoSourceType,
} from 'shared/components/video-player/types';
import {
  PlayerStateMachineContext,
  PlaylistItemType,
  VideoSourceWithTimes,
} from 'shared/components/video-player/types';
import { PlaylistItemWithoutVideoSources } from 'shared/types/playlist/types';

export const getVideoByVideoType = (playlistItem: PlaylistItemType, playingMode: PlayingMode): VideoSourceType => {
  // Search for preferred video type
  const preferredVideoType = playlistItem.videoTypes.find((videoType) => videoType.playingMode.isPreferred);
  if (preferredVideoType) return preferredVideoType;

  // Find video with overlays
  const videoTypeWithOverlays = playlistItem.videoTypes.find(
    (videoType) =>
      videoType.playingMode.showOverlays &&
      videoType.playingMode.showOverlays === playingMode.showOverlays &&
      !isEmpty(videoType.videoSources),
  );
  if (videoTypeWithOverlays) return videoTypeWithOverlays;

  // Find video type more similar to current PlayingMode
  const videoType = playlistItem.videoTypes.find(
    (videoType) =>
      videoType.playingMode.mode === playingMode.mode ||
      (playingMode.mode === PlayingModes.PLAYLIST &&
        isFullMatchVideo(videoType.playingMode.mode) &&
        !isEmpty(videoType.videoSources)),
  );
  if (videoType) return videoType;

  //Return first videoType that has no empty videoSources
  const isAnyVideoTypeWithSources = playlistItem.videoTypes.find((item) => !isEmpty(item.videoSources));
  if (isAnyVideoTypeWithSources) return isAnyVideoTypeWithSources;

  // Return an empty value
  return {
    playingMode,
    videoSources: [{ startTime: 0, endTime: 0, src: '', id: '' }],
  };
};

export const getVideoSourceByIndex = (
  playlistItem: PlaylistItemType,
  playingMode: PlayingMode,
  videoSourceIndex: number,
): VideoSourceWithTimes => {
  const { videoSources } = getVideoByVideoType(playlistItem, playingMode);

  return videoSources[videoSourceIndex];
};

export const getCurrentVideoSource = (context: PlayerStateMachineContext): VideoSourceWithTimes => {
  const { currentSelectedPlayingMode } = context.playlist;
  const { playlistItem, videoSourceIndex } = context.playlist.playingItem;
  return getVideoSourceByIndex(playlistItem, currentSelectedPlayingMode, videoSourceIndex);
};

const getClosestMatchingVideoSourceIndex = (
  matchTime: number,
  startTime: number,
  endTime: number,
  currentVideoIndex: number,
  newVideoIndex: number,
): number => {
  if (matchTime <= endTime && matchTime >= startTime) {
    return newVideoIndex;
  }

  if (startTime >= matchTime && currentVideoIndex === -1) {
    return newVideoIndex;
  }

  return currentVideoIndex;
};

export const getCurrentTimeAndVideoSourceIndex = (
  matchTime: number,
  videoSources: VideoSourceWithTimes[],
  playingMode: PlayingMode,
): { currentTime: number; videoIndex: number } => {
  const result = videoSources.reduce(
    (result, videoSource, currentVideoIndex) => {
      const endTime = videoSource.endTimeInMatch ? videoSource.endTimeInMatch : videoSource.endTime;
      const startTime = videoSource.startTimeInMatch ? videoSource.startTimeInMatch : videoSource.startTime;
      const isFullMatch = isFullMatchVideo(playingMode.mode);

      const foundVideoIndex = getClosestMatchingVideoSourceIndex(
        matchTime,
        startTime,
        endTime,
        result.videoIndex,
        currentVideoIndex,
      );

      if (foundVideoIndex === result.videoIndex) return result;

      const time = isFullMatch ? matchTime : matchTime - startTime;

      return {
        videoIndex: foundVideoIndex,
        currentTime: foundVideoIndex !== result.videoIndex ? time : result.currentTime,
      };
    },
    {
      videoIndex: -1,
      currentTime: isFullMatchVideo(playingMode.mode) || !playingMode.useEffectiveTime ? matchTime : 0,
    },
  );

  const videoSourceIndex = result.videoIndex >= 0 ? result.videoIndex : 0;
  const currentTime =
    result.currentTime > 0 &&
    result.currentTime > videoSources[videoSourceIndex].startTime &&
    result.currentTime < videoSources[videoSourceIndex].endTime
      ? result.currentTime
      : videoSources[videoSourceIndex].startTime;

  return {
    videoIndex: videoSourceIndex,
    currentTime: currentTime > 0 ? currentTime : videoSources[videoSourceIndex].startTime,
  };
};

export const areAllOverlayTacticsSelected = (playlistItems: PlaylistItemType | PlaylistItemWithoutVideoSources) => {
  return playlistItems.fundamentalsSelected.fundamentalsSelected[0] === 'all';
};

export const checkIsFinishedPlayingCurrentPlaylistItem = (
  playlist: PlayerStatePlaylist,
  videoRef: PlayerType | undefined,
) => {
  const videoSource = getVideoSourceByIndex(
    playlist.playingItem.playlistItem,
    playlist.currentSelectedPlayingMode,
    playlist.playingItem.videoSourceIndex,
  );

  if (typeof videoSource?.endTime !== 'number' || !videoRef?.current?.currentTime) {
    return false;
  }

  return videoRef.current.currentTime >= videoSource.endTime;
};
