import { useEffect, useRef } from 'react';

import {
  usePlayHeadShadowTime,
  usePlayHeadShadowVisibility,
} from 'pages/tactical-analysis/components/timeline/play-head/play-head-shadow/atoms';
import {
  PlayHeadArrowDown,
  PlayHeadContent,
  PlayHeadTime,
  PlayHeadHeader,
} from 'pages/tactical-analysis/components/timeline/play-head/PlayHead.styled';
import { useTacticalAnalysisEpisodes } from 'pages/tactical-analysis/hooks/use-tactical-analysis-episodes';
import { useTacticalAnalysisMatchSegments } from 'pages/tactical-analysis/hooks/use-tactical-analysis-match-segments';
import { useTimelineZoomLevel } from 'pages/tactical-analysis/hooks/use-timeline-zoom-level';
import { formattedTime } from 'pages/tactical-analysis/utils/formatted-time';
import { getPlayHeadPositionByTime } from 'pages/tactical-analysis/utils/get-play-head-position-by-time';
import { useVideoPlayerPlayingMode } from 'shared/components/video-player';

type Props = {
  recordingId: string;
};

export const PlayHeadShadow = ({ recordingId }: Props) => {
  const matchSegments = useTacticalAnalysisMatchSegments(recordingId);
  const playHeadShadowContentDiv = useRef<HTMLDivElement>(null);
  const playHeadShadowVisibility = usePlayHeadShadowVisibility(recordingId);
  const shadowTime = usePlayHeadShadowTime(recordingId);
  const episodes = useTacticalAnalysisEpisodes(recordingId);
  const { zoomLevel } = useTimelineZoomLevel();
  const { useEffectiveTime: effectiveTime } = useVideoPlayerPlayingMode();

  useEffect(() => {
    const leftPosition = getPlayHeadPositionByTime(shadowTime, effectiveTime, episodes, zoomLevel);

    requestAnimationFrame(() => {
      if (!playHeadShadowContentDiv.current) return;

      playHeadShadowContentDiv.current.style.transform = `translateX(${leftPosition}px)`;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shadowTime]);

  return (
    <PlayHeadContent style={{ opacity: playHeadShadowVisibility ? 1 : 0 }} isShadow ref={playHeadShadowContentDiv}>
      <PlayHeadHeader>
        <PlayHeadArrowDown isShadow />
        <PlayHeadTime isShadow>{formattedTime(shadowTime, matchSegments)}</PlayHeadTime>
      </PlayHeadHeader>
    </PlayHeadContent>
  );
};
