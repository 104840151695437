import { Stack } from '@mui/material';
import { IconDownload } from 'kognia-ui/icons/IconDownload';
import { IconShare } from 'kognia-ui/icons/IconShare';
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { invalidatePlaylistQuery } from 'api/playlist/usePlaylist';
import { useUpdatePlaylist } from 'api/playlist/useUpdatePlaylist';
import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { useIsBulkModeActive, useSetIsBulkModeActive } from 'entities/playlist/hooks/useIsBulkModeActive';
import { PlaylistActions } from 'entities/playlist/ui/PlaylistActions';
import { PlaylistContainerGrid } from 'entities/playlist/ui/PlaylistContainerGrid';
import { PlaylistDetails } from 'entities/playlist/ui/PlaylistDetails';
import { PlaylistHeaderGrid } from 'entities/playlist/ui/PlaylistHeaderGrid';
import { PlaylistTimelineContainer } from 'entities/playlist/ui/PlaylistTimelineContainer';
import { PlaylistTimelineHeader } from 'entities/playlist/ui/PlaylistTimelineHeader';
import { PlaylistVideoPlayerContainer } from 'entities/playlist/ui/PlaylistVideoPlayerContainer';
import { DownloadPlaylistItemsModal } from 'features/playlist/download-playlist-items-modal/DownloadPlaylistItemsModal';
import { DownloadCurrentPlaylistItemModal } from 'features/playlist/DownloadCurrentPlaylistItemModal';
import { EnableBulkModeButton } from 'features/playlist/EnableBulkModeButton';
import { PlaylistOverlaysSelectorPanelFeature } from 'features/playlist/playlist-overlay-selector-panel/PlaylistOverlaySelectorPanel.feature';
import { UserPlaylistItemsList } from 'features/playlist/user-playlist-items-list/UserPlaylistItemsList';
import { AddMultipleClips } from 'pages/playlist-detail/components/add-multiple-clips/AddMultipleClips';
import { EditableText } from 'shared/components/editable-text/EditableText';
import SharePlaylistModal from 'shared/components/share-playlist-modal';
import { VerticalIconButton } from 'shared/components/vertical-icon-button';
import { useCurrentPlaylistItem, usePlaylistItems, useVideoPlayerActions } from 'shared/components/video-player';
import { PlaylistItemType } from 'shared/components/video-player/types';
import VideoPlayerComponent from 'shared/components/video-player/video-player-component';
import { useFeatureFlag } from 'shared/contexts/app-state/hooks/useFeatureFlag';
import { useUser } from 'shared/contexts/app-state/hooks/useUser';
import { Playlist } from 'shared/types';
import { PlaylistItemTrimModal } from 'widgets/playlist/user-playlist/ui/PlaylistItemTrim';

const findVideoById = (videos: PlaylistItemType[], id: string) => {
  return videos.find((video) => video.id === id);
};

type Props = {
  playlist: Playlist;
};

export const UserPlaylist = ({ playlist }: Props) => {
  const { t } = useTranslation();
  const enabledBulkMode = useIsBulkModeActive(playlist.id);
  const setEnabledBulkMode = useSetIsBulkModeActive(playlist.id);

  const actions = useVideoPlayerActions();
  const playingItems = usePlaylistItems();
  const playlistItem = useCurrentPlaylistItem();
  const { updatePlaylistName, isPending: isPlaylistUpdating } = useUpdatePlaylist(playlist.id);
  const downloadPlaylistFeatureFlag = useFeatureFlag(FEATURE_FLAG.DOWNLOAD_PLAYLIST);
  const customOverlaysFeatureFlag = useFeatureFlag(FEATURE_FLAG.CUSTOM_OVERLAYS);

  const [trimmingPlaylistItem, setTrimmingPlaylistItem] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
  const [isCurrentItemDownloadModalOpen, setIsCurrentItemDownloadModalOpen] = useState(false);
  const user = useUser();

  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const openModal = useCallback(() => setIsModalOpen(true), []);

  const onUpdateName = useCallback(
    (newText: string) => {
      if (newText === playlist.name || trim(newText).length === 0) return;

      updatePlaylistName({ name: newText, onSuccess: invalidatePlaylistQuery });
    },
    [playlist.name, updatePlaylistName],
  );

  const canEditName = user.id === playlist.user.userId;

  const trimmingPlaylistItemItem =
    trimmingPlaylistItem !== '' ? findVideoById(playingItems, trimmingPlaylistItem) : null;

  const handleTrimmingPlaylistItem = useCallback(
    (trimmingPlaylistItem: string) => {
      setTrimmingPlaylistItem(trimmingPlaylistItem);

      trimmingPlaylistItem ? actions.handleStandBy() : actions.resumeStandBy();
    },
    [actions],
  );

  const handleDownloadModalOpen = useCallback(() => setIsDownloadModalOpen(true), []);
  const handleDownloadModalClose = useCallback(() => setIsDownloadModalOpen(false), []);

  const handleOpenDownloadCurrentItemModal = useCallback(() => {
    actions.handleStandBy();
    setIsCurrentItemDownloadModalOpen(true);
  }, [actions]);

  const handleCloseDownloadCurrentItemModal = useCallback(() => {
    actions.resumeStandBy();
    setIsCurrentItemDownloadModalOpen(false);
  }, [actions]);

  return (
    <PlaylistContainerGrid container>
      <PlaylistHeaderGrid xs={12} item>
        <EditableText
          canEdit={canEditName}
          originalText={playlist.name}
          onSubmit={onUpdateName}
          isSubmitting={isPlaylistUpdating}
        />
        {!enabledBulkMode ? (
          <Stack direction='row' spacing={2}>
            <VerticalIconButton Icon={IconShare} onClick={openModal}>
              {t('common:actions.share')}
            </VerticalIconButton>

            {downloadPlaylistFeatureFlag && !isEmpty(playingItems) ? (
              <VerticalIconButton Icon={IconDownload} onClick={handleDownloadModalOpen}>
                {t('common:actions.download')}
              </VerticalIconButton>
            ) : null}
          </Stack>
        ) : null}
      </PlaylistHeaderGrid>
      <PlaylistVideoPlayerContainer display='flex' item xs={12}>
        <VideoPlayerComponent
          onDownloadSource={handleOpenDownloadCurrentItemModal}
          showVideoPlayerBar
          disabledTacticDrawings={!playlistItem.showOverlays}
        />
        {customOverlaysFeatureFlag && playlistItem.hasHomographies && playlistItem.showOverlays ? (
          <PlaylistOverlaysSelectorPanelFeature key={playlist.id} playlistId={playlist.id} />
        ) : null}

        {trimmingPlaylistItemItem && (
          <PlaylistItemTrimModal
            open={Boolean(trimmingPlaylistItemItem)}
            playlistId={playlist.id}
            playlistItem={trimmingPlaylistItemItem}
            setTrimmingPlaylistItem={handleTrimmingPlaylistItem}
          />
        )}
      </PlaylistVideoPlayerContainer>
      <PlaylistTimelineContainer item xs={12}>
        <PlaylistTimelineHeader>
          <PlaylistDetails playlist={playlist} />
          <PlaylistActions>
            <EnableBulkModeButton playlistId={playlist.id} />
            <AddMultipleClips playlistId={playlist.id} />
          </PlaylistActions>
        </PlaylistTimelineHeader>

        <UserPlaylistItemsList
          playlist={playlist}
          setTrimmingPlaylistItem={handleTrimmingPlaylistItem}
          trimmingPlaylistItemId={trimmingPlaylistItem}
          enabledBulkMode={enabledBulkMode}
          setEnabledBulkMode={setEnabledBulkMode}
        />
      </PlaylistTimelineContainer>

      {isModalOpen && <SharePlaylistModal onCloseModal={closeModal} playlist={playlist} />}
      {isDownloadModalOpen ? (
        <DownloadPlaylistItemsModal
          downloadType={'playlist'}
          onClose={handleDownloadModalClose}
          playlistData={playlist}
        />
      ) : null}
      <DownloadCurrentPlaylistItemModal
        playlist={playlist}
        isOpen={isCurrentItemDownloadModalOpen}
        onClose={handleCloseDownloadCurrentItemModal}
      />
    </PlaylistContainerGrid>
  );
};
