import { UseQueryResult } from '@tanstack/react-query';
import { useCallback, useMemo } from 'react';

import { queryClient } from 'api/config';
import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { transformPlaylist } from 'api/playlist/transformers';
import { playlistUrl } from 'api/routes';
import { Playlist } from 'shared/types';

type UseGetPlaylistResult = UseQueryResult<Playlist> & {
  setQueryData: (...args: any[]) => void;
};

interface Parameters {
  playlistId: string;
  onSuccess?: (data: Playlist) => void;
}

const fetchPlaylistQueryRef = 'fetchPlaylist';
export const generateFetchPlaylistQueryRef = (playlistId: string) => [
  fetchPlaylistQueryRef,
  `fetchPlaylist-playlistId:${playlistId}`,
];

export const invalidatePlaylistQuery = () => queryClient.invalidateQueries({ queryKey: [fetchPlaylistQueryRef] });
export const invalidatePlaylistIdQuery = (playlistId: string) =>
  queryClient.invalidateQueries({ queryKey: generateFetchPlaylistQueryRef(playlistId) });

export const usePlaylist = ({ playlistId, onSuccess }: Parameters): UseGetPlaylistResult => {
  const queryRef = useMemo(() => generateFetchPlaylistQueryRef(playlistId), [playlistId]);
  const fetchRequest = useFetchRequest<Playlist>({
    queryRef,
    url: playlistUrl(playlistId),
    transformer: transformPlaylist,
    options: {
      enabled: !!playlistId,
    },
    onSuccess,
  });

  const setQueryData = useCallback((data: Playlist) => queryClient.setQueryData(queryRef, data), [queryRef]);
  return { ...fetchRequest, setQueryData };
};
