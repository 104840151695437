import { BrandingFormField } from 'pages/backoffice/features/branding-form/types/brandingFormField';
import { BrandingFormFieldsNames } from 'pages/backoffice/features/branding-form/utils/brandingFormFieldsNames';
import { ClientBranding } from 'shared/types/user/types';

interface BrandingFormFieldsParams {
  previewUrls?: Partial<ClientBranding> | null;
}

export const generateBrandingFormFields = ({ previewUrls }: BrandingFormFieldsParams): BrandingFormField[] => [
  {
    id: BrandingFormFieldsNames.FAVICON,
    component: 'input',
    type: 'file',
    fullWidth: true,
    showClearButton: true,
    previewUrl: previewUrls?.faviconUrl,
  },
  {
    id: BrandingFormFieldsNames.EMAIL_LOGO,
    component: 'input',
    type: 'file',
    fullWidth: true,
    showClearButton: true,
    previewUrl: previewUrls?.emailLogoUrl,
  },
  {
    id: BrandingFormFieldsNames.NAVBAR_LOGO,
    component: 'input',
    type: 'file',
    fullWidth: true,
    showClearButton: true,
    previewUrl: previewUrls?.navbarLogoUrl,
  },
  {
    id: BrandingFormFieldsNames.NAVBAR_COLLAPSED_LOGO,
    component: 'input',
    type: 'file',
    fullWidth: true,
    showClearButton: true,
    previewUrl: previewUrls?.navbarCollapsedLogoUrl,
  },
  {
    id: BrandingFormFieldsNames.HOME_PAGE_LOGO,
    component: 'input',
    type: 'file',
    fullWidth: true,
    showClearButton: true,
    previewUrl: previewUrls?.homePageLogoUrl,
  },
  {
    id: BrandingFormFieldsNames.DISPLAY_NAME,
    placeholder: previewUrls?.displayName,
    component: 'input',
    fullWidth: true,
  },
];
