import React, { useMemo } from 'react';

import { DownloadPlaylistItemsModal } from 'features/playlist/download-playlist-items-modal/DownloadPlaylistItemsModal';
import { useCurrentPlaylistItemId } from 'shared/components/video-player/hooks';
import { Playlist } from 'shared/types';

interface Props {
  playlist: Playlist;
  isOpen: boolean;
  onClose: () => void;
}

export const DownloadCurrentPlaylistItemModal = ({ playlist, isOpen, onClose }: Props) => {
  const playlistItemId = useCurrentPlaylistItemId();

  const playlistData = useMemo(
    () => ({
      ...playlist,
      playlistItems: playlist.playlistItems.filter((item) => item.id === playlistItemId),
    }),
    [playlist, playlistItemId],
  );

  return isOpen ? (
    <DownloadPlaylistItemsModal downloadType={'playlistItems'} playlistData={playlistData} onClose={onClose} />
  ) : null;
};
