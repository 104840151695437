import { TacticId } from '@kognia/tactical-analysis-data';
import { Animation } from 'konva/lib/Animation';
import { IFrame } from 'konva/lib/types';
import { FrameInfo, useOverlayGenerator } from 'overlay-generator';
import { useCallback, useEffect, useRef, useState } from 'react';
import { atomFamily, useRecoilValue, useSetRecoilState } from 'recoil';

import {
  useCurrentMatchTime,
  useCurrentPlaylistItem,
  useIsBuffering,
  useIsSeeking,
  useVideoPlayerActions,
  useVideoPlayerId,
  useVideoPlayerPlayingMode,
  useVideoPlayerRef,
} from 'shared/components/video-player/hooks';

const areOverlaysReady = atomFamily<boolean, string>({
  key: 'overlays-ready',
  default: false,
});

const frameRate = atomFamily<number, string>({
  key: 'overlays-render-frame-rate',
  default: 0,
});

const frameInfo = atomFamily<FrameInfo, string>({
  key: 'overlays-frame-info',
  default: {
    frameNumber: 0,
    frameTactics: [],
    overlayElementsDebugInfo: [],
  },
});

export const useSetAreOverlaysReady = () => {
  const playerId = useVideoPlayerId();
  return useSetRecoilState(areOverlaysReady(playerId));
};

export const useAreOverlaysReady = () => {
  const playerId = useVideoPlayerId();
  return useRecoilValue(areOverlaysReady(playerId));
};

export const useSetOverlaysFrameInfo = () => {
  const playerId = useVideoPlayerId();
  return useSetRecoilState(frameInfo(playerId));
};

export const useOverlaysFrameInfo = () => {
  const playerId = useVideoPlayerId();
  return useRecoilValue(frameInfo(playerId));
};

export const useSetRenderFrameRate = () => {
  const playerId = useVideoPlayerId();
  return useSetRecoilState(frameRate(playerId));
};

export const useRenderFrameRate = () => {
  const playerId = useVideoPlayerId();
  return useRecoilValue(frameRate(playerId));
};

export const useOverlaysController = () => {
  const videoPlayerRef = useVideoPlayerRef();
  const playlistItem = useCurrentPlaylistItem();
  const container = useRef<HTMLDivElement>(null);
  const { createAnimation, overlayGenerator, frameRate, frameInfo, isReady } = useOverlayGenerator({
    id: playlistItem.recordingId,
  });
  const lastRenderInfo = useRef<{
    width: number;
    height: number;
    tactics: TacticId[] | undefined;
    frame: number;
    showOverlays: boolean | undefined;
  }>({
    frame: 0,
    width: 0,
    height: 0,
    tactics: [],
    showOverlays: undefined,
  });
  const anim = useRef<{ controller: Animation | undefined }>({ controller: undefined });
  const matchTime = useCurrentMatchTime();
  const currentMatchTimeRef = useRef(0);
  const setAreOverlaysReady = useSetAreOverlaysReady();
  const isBuffering = useIsBuffering();
  const isSeeking = useIsSeeking();
  const setOverlaysFrameInfo = useSetOverlaysFrameInfo();
  const setRenderFrameRate = useSetRenderFrameRate();
  const actions = useVideoPlayerActions();
  const playingMode = useVideoPlayerPlayingMode();
  const [matrix3dTransformation, setMatrix3dTransformation] = useState<number[] | undefined>([]);

  useEffect(() => {
    if (!anim.current.controller) return;

    isSeeking || isBuffering ? anim.current.controller.stop() : anim.current.controller.start();
  }, [isSeeking, isBuffering]);

  useEffect(() => {
    isReady ? actions.resumeStandBy() : actions.handleStandBy();
    setAreOverlaysReady(isReady);
  }, [actions, setAreOverlaysReady, isReady]);

  useEffect(() => {
    currentMatchTimeRef.current = matchTime;
  }, [currentMatchTimeRef, matchTime]);

  useEffect(() => {
    setOverlaysFrameInfo(frameInfo);
  }, [setOverlaysFrameInfo, frameInfo]);

  const handleUpdateFrame = useCallback(
    async ({
      frame,
      tactics,
      videoPlayerWidth,
      videoPlayerHeight,
      overlayContainer,
    }: {
      frame: number;
      tactics: TacticId[] | undefined;
      videoPlayerWidth: number;
      videoPlayerHeight: number;
      overlayContainer: HTMLDivElement;
    }) => {
      await overlayGenerator.drawFrameInCanvas(overlayContainer, frame, {
        tactics,
      });

      const matrix3d = overlayGenerator.getTransformationMatrix(frame, {
        width: videoPlayerWidth,
        height: videoPlayerHeight,
      });

      setMatrix3dTransformation(matrix3d);
      return Boolean(matrix3d);
    },
    [overlayGenerator],
  );

  const handleVideoTimeUpdate = useCallback(
    (frame?: IFrame) => {
      const videoPlayerWidth = videoPlayerRef.current?.offsetWidth;
      const videoPlayerHeight = videoPlayerRef.current?.offsetHeight;
      if (!container.current || !videoPlayerRef.current || !videoPlayerWidth || !videoPlayerHeight) return;

      setRenderFrameRate(Math.round(frame?.frameRate ?? 0));
      const videoFrame = Math.round(currentMatchTimeRef.current * frameRate);

      const tactics =
        playlistItem.fundamentalsSelected && playlistItem.fundamentalsSelected.fundamentalsSelected[0] === 'all'
          ? undefined
          : (playlistItem.fundamentalsSelected.fundamentalsSelected as TacticId[]);

      const currentFrameInfo = {
        width: videoPlayerWidth,
        height: videoPlayerHeight,
        frame: videoFrame,
        tactics,
        showOverlays: playingMode.showOverlays,
      };

      if (JSON.stringify(currentFrameInfo) === JSON.stringify(lastRenderInfo.current) || !playingMode.showOverlays) {
        return false;
      }

      lastRenderInfo.current = currentFrameInfo;
      return handleUpdateFrame({
        frame: videoFrame,
        tactics,
        videoPlayerWidth,
        videoPlayerHeight,
        overlayContainer: container.current,
      });
    },
    [
      playingMode,
      container,
      frameRate,
      handleUpdateFrame,
      playlistItem.fundamentalsSelected,
      setRenderFrameRate,
      videoPlayerRef,
    ],
  );

  useEffect(() => {
    anim.current.controller = createAnimation(handleVideoTimeUpdate);
    const animationControls = anim.current.controller;

    animationControls.start();

    return () => {
      animationControls.stop();
    };
  }, [createAnimation, handleVideoTimeUpdate]);

  return { container, overlayGenerator, matrix3dTransformation };
};
