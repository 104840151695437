import { Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CountryFlag } from 'shared/components/CountryFlag';
import { CountryCode } from 'shared/utils/countries';

type Props = {
  countryCode: CountryCode | undefined;
  showCountryName?: boolean;
  showTooltip?: boolean;
};

export const Country = ({ countryCode, showCountryName = true, showTooltip = false }: Props) => {
  const { t } = useTranslation('countries');
  if (!countryCode) return null;

  return (
    <Stack direction={'row'} gap={1} alignItems={'center'}>
      <Tooltip title={showTooltip ? t(countryCode.toUpperCase()) : false}>
        <div>
          <CountryFlag countryCode={countryCode} />
        </div>
      </Tooltip>
      {showCountryName && <Typography variant={'body2'}>{t(countryCode.toUpperCase())}</Typography>}
    </Stack>
  );
};
