import { IconButton, MenuItem, Popover } from '@mui/material';
import { IconClose } from 'kognia-ui/icons/IconClose';
import { IconPlay } from 'kognia-ui/icons/IconPlay';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import styles from 'pages/tactical-analysis/components/timeline/timeline-table/components/row-actions/RowActions.module.scss';

type Props = {
  rowId: string;
  menuAnchorEl: HTMLDivElement | null;
  onClose: () => void;
  playRow: (rowId: string) => void;
  playHomeTeamRow: (rowId: string) => void;
  playOpponentTeamRow: (rowId: string) => void;
};

export const RowActions = ({ menuAnchorEl, rowId, onClose, playRow, playOpponentTeamRow, playHomeTeamRow }: Props) => {
  const { t } = useTranslation();

  const closeAllMenus = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleClickAway = useCallback(
    (event: MouseEvent | TouchEvent) => {
      event.stopPropagation();
      closeAllMenus();
    },
    [closeAllMenus],
  );

  const handleClose = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      closeAllMenus();
    },
    [closeAllMenus],
  );

  const handlePlay = useCallback(() => {
    if (menuAnchorEl?.dataset.rowType === 'home-team') {
      playHomeTeamRow(rowId);
    } else if (menuAnchorEl?.dataset.rowType === 'opponent-team') {
      playOpponentTeamRow(rowId);
    } else {
      playRow(rowId);
    }
    closeAllMenus();
  }, [closeAllMenus, menuAnchorEl?.dataset.rowType, playHomeTeamRow, playOpponentTeamRow, playRow, rowId]);

  return (
    <>
      <Popover
        open
        className={styles.popOver}
        anchorEl={menuAnchorEl}
        onClose={handleClickAway}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <div className={styles.modalMenu} onClick={(event) => event.stopPropagation()}>
          <IconButton
            size='small'
            sx={{
              position: 'absolute',
              top: ({ spacing }) => spacing(1),
              right: ({ spacing }) => spacing(1),
              padding: 0,
            }}
            onClick={handleClose}
          >
            <IconClose size='small' color='secondary' />
          </IconButton>
          <MenuItem className={styles.menuItem} onClick={() => handlePlay()}>
            <IconPlay color='secondary' size='small' /> {t('timeline:actions.play-clip')}
          </MenuItem>
        </div>
      </Popover>
    </>
  );
};
