import React from 'react';

import { RowGroup } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/row';
import { RowsHeadersGroup } from 'pages/tactical-analysis/components/timeline/timeline-table/components/row-headers-group';

export const RowGroupsList = ({ rowGroups, recordingId }: { recordingId: string; rowGroups: RowGroup[] }) => {
  return (
    <div>
      {rowGroups
        .filter((rowGroup) => rowGroup.totalClips > 0)
        .map((rowGroup) => (
          <RowsHeadersGroup key={rowGroup.type} recordingId={recordingId} level={0} rowsGroup={rowGroup} />
        ))}
    </div>
  );
};
