import reduce from 'lodash/reduce';
import { useEffect } from 'react';

import { usePlaylists } from 'api/playlist/useFetchPlaylists';
import { INITIAL_PLAYLISTS_FILTERS, usePlaylistsFilters } from 'api/playlist/useFetchPlaylists/atoms';
import { PlaylistFiltersNames, PlaylistsFilters } from 'api/playlist/useFetchPlaylists/types';
import { playlistPageQueryParams, routes } from 'kognia/router/routes';
import { QueryStringState, useQueryStringState } from 'shared/hooks/use-query-string-state';

const transformQueryStringValuesToFilters = (
  queryStringValues: QueryStringState,
  allowedFields: string[],
): PlaylistsFilters => {
  return reduce(
    queryStringValues,
    (acc, value, key) => {
      if (allowedFields.includes(key)) {
        if (['competition', 'userId'].includes(key)) {
          return {
            ...acc,
            [key]: Array.isArray(value) ? value : value?.split(','),
          };
        }
        return { ...acc, [key]: value };
      }

      return acc;
    },
    INITIAL_PLAYLISTS_FILTERS,
  );
};
const ALLOWED_QUERY_PARAMS = [...Object.keys(PlaylistFiltersNames), ...Object.keys(playlistPageQueryParams)];

export const usePlaylistsWithQueryStringState = () => {
  const filters = usePlaylistsFilters(routes.PLAYLISTS);
  const { value, updateValues } = useQueryStringState(ALLOWED_QUERY_PARAMS);

  useEffect(() => {
    updateValues({ ...filters });
  }, [updateValues, filters]);

  return usePlaylists({
    playlistId: routes.PLAYLISTS,
    initialFilters: transformQueryStringValuesToFilters(value, ALLOWED_QUERY_PARAMS),
  });
};
