export const secondsToTimeString = (seconds: number) => {
  const date = new Date(0);
  date.setSeconds(seconds);

  // 1970-01-01T00:00:00.000Z
  let str = date.toISOString();
  str = str.substring(11, 11 + 8);
  if (str.indexOf('00:') === 0) {
    str = str.substr(3, str.length);
  }

  return str;
};

const dateFormatter = new Intl.DateTimeFormat('en', {
  day: 'numeric',
  year: 'numeric',
  month: 'short',
});

const timeFormatter = new Intl.DateTimeFormat('en', {
  hour12: false,
  hour: 'numeric',
  minute: 'numeric',
});

export const dateToPrintableDateTimeString = (date: Date) => {
  return `${dateFormatter.format(new Date(date))} - ${timeFormatter.format(new Date(date))}`;
};

export const dateToPrintableDateString = (date: Date): string => {
  return dateFormatter.format(new Date(date));
};

export const toISODateString = (date: Date): string => {
  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2);
};
