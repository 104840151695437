import { Box } from '@mui/material';

interface Props {
  open: boolean;
  children: React.ReactNode;
}

export const SearchResults = ({ open, children }: Props) => {
  if (!open) return null;

  return <Box>{children}</Box>;
};
