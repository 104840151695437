import React, { useCallback } from 'react';

import { useVideoPlayerActions, VideoPlayerStateProvider } from 'shared/components/video-player';
import { DEFAULT_TACTICAL_CAMERA_PLAYING_MODE } from 'shared/components/video-player/defaultPlayingModes';
import { PlaylistItemType } from 'shared/components/video-player/types';
import PlaylistItemTrimPlayer from 'widgets/playlist/user-playlist/ui/playlist-item-trim-player/PlaylistItemTrimPlayer';
import { PlaylistItemTrimDialog } from 'widgets/playlist/user-playlist/ui/PlaylistItemTrimDialog';

interface Props {
  open: boolean;
  playlistId: string;
  playlistItem: PlaylistItemType;
  setTrimmingPlaylistItem: (videoId: string) => void;
}

export const PlaylistItemTrimModal = ({ open, playlistId, playlistItem, setTrimmingPlaylistItem }: Props) => {
  const actions = useVideoPlayerActions();

  const handleOnConfirm = useCallback(
    (playlistItem: PlaylistItemType) => {
      actions.updatePlaylistItem(playlistItem);
      actions.resumeStandBy();
    },
    [actions],
  );

  const handleCancel = useCallback(() => {
    actions.resumeStandBy();
    setTrimmingPlaylistItem('');
  }, [actions, setTrimmingPlaylistItem]);

  return (
    <PlaylistItemTrimDialog hiddenActions open={open} maxWidth='xl' fullWidth onClose={handleCancel}>
      <VideoPlayerStateProvider
        playerId={`${playlistId}-item-trim`}
        playlistItems={[playlistItem]}
        playingMode={DEFAULT_TACTICAL_CAMERA_PLAYING_MODE}
      >
        <PlaylistItemTrimPlayer onConfirm={handleOnConfirm} playlistId={playlistId} onCancel={handleCancel} />
      </VideoPlayerStateProvider>
    </PlaylistItemTrimDialog>
  );
};
