import { getDurationFromEpisodes } from 'pages/tactical-analysis/components/tactical-analysis/utils/get-duration-from-episodes';
import { EPISODES_PLAYING_MODE } from 'shared/components/video-player/defaultPlayingModes';
import {
  PlayingMode,
  PlayingModes,
  PlaylistItemType,
  VideoSource,
  VideoSourceType,
} from 'shared/components/video-player/types';
import { Episode, TacticIdOrAll } from 'shared/types';
import { round } from 'shared/utils/round';

const generateEffectiveTimeSources = (episodes: Episode[]) => {
  return episodes.map((episode) => ({
    startTime: 0,
    endTime: round(episode.endTime - episode.startTime),
    startTimeInMatch: round(episode.startTime),
    endTimeInMatch: round(episode.endTime),
    src: episode.dashUrl,
    srcDownload: episode.s3Url,
    id: episode.videoSourceId,
  }));
};

const generateVideoSourceTypeEffectiveTime = (episodes: Episode[]): VideoSourceType => {
  return {
    playingMode: EPISODES_PLAYING_MODE,
    videoSources: generateEffectiveTimeSources(episodes),
  };
};

const generateEffectiveTimeFullGameSources = ({
  episodes,
  src,
  srcDownload,
}: {
  episodes: Episode[];
  src: string;
  srcDownload?: string;
  effectiveTime?: boolean;
}) => {
  return episodes.map((episode) => ({
    startTime: round(episode.startTime),
    endTime: round(episode.endTime),
    startTimeInMatch: round(episode.startTime),
    endTimeInMatch: round(episode.endTime),
    src: src,
    srcDownload: srcDownload,
    id: episode.videoSourceId,
  }));
};

const generateVideoSourceTypeFullGame = ({
  duration,
  episodes,
  hasHomographies,
  showOverlays,
  tacticalCameraVideo,
  useEffectiveTime,
}: {
  tacticalCameraVideo: VideoSource;
  showOverlays: boolean;
  useEffectiveTime: boolean;
  duration?: number;
  hasHomographies: boolean;
  episodes: Episode[];
}): VideoSourceType => {
  if (episodes.length > 0 && useEffectiveTime) {
    return {
      playingMode: { mode: PlayingModes.TACTICAL_CAMERA, useEffectiveTime, showOverlays },
      videoSources: generateEffectiveTimeFullGameSources({
        episodes,
        src: tacticalCameraVideo.src,
        srcDownload: tacticalCameraVideo.srcDownload,
      }),
    };
  }

  return {
    playingMode: {
      mode: PlayingModes.TACTICAL_CAMERA,
      useEffectiveTime: false,
      showOverlays: hasHomographies ? showOverlays : false,
    },
    videoSources: [
      {
        type: tacticalCameraVideo.type,
        poster: tacticalCameraVideo.poster,
        src: tacticalCameraVideo.src,
        srcDownload: tacticalCameraVideo.srcDownload,
        startTimeInMatch: 0,
        startTime: 0,
        endTime: duration ?? 0,
        endTimeInMatch: duration ?? 0,
        id: tacticalCameraVideo.id,
      },
    ],
  };
};

interface GeneratePlaylistItemFromEpisodes {
  episodes: Episode[];
  fullVideoSourceDuration?: number;
  tacticalCameraVideo: VideoSource;
  playingMode: PlayingMode;
  recordingId: string;
  fundamentalsSelected: TacticIdOrAll[];
  hasHomographies: boolean;
  showOverlays: boolean;
}

export const generatePlaylistItemFromEpisodes = ({
  episodes,
  fullVideoSourceDuration,
  tacticalCameraVideo,
  playingMode,
  recordingId,
  fundamentalsSelected,
  hasHomographies,
  showOverlays,
}: GeneratePlaylistItemFromEpisodes): PlaylistItemType[] => {
  if (!episodes.length && playingMode.mode === PlayingModes.EPISODES) return [];
  const tacticalAnalysisId = episodes.length > 0 ? episodes[0].tacticalAnalysisId : undefined;

  const duration =
    (playingMode.mode === PlayingModes.TACTICAL_CAMERA && playingMode.useEffectiveTime) ||
    playingMode.mode === PlayingModes.EPISODES
      ? getDurationFromEpisodes(episodes)
      : fullVideoSourceDuration ?? 0;

  const videoTypes =
    playingMode.mode === PlayingModes.EPISODES
      ? [generateVideoSourceTypeEffectiveTime(episodes)]
      : [
          generateVideoSourceTypeFullGame({
            tacticalCameraVideo,
            duration: duration,
            hasHomographies,
            showOverlays: playingMode.showOverlays,
            useEffectiveTime: playingMode.useEffectiveTime,
            episodes,
          }),
        ];

  return [
    {
      id: 'timeline-playlist-item',
      duration,

      fundamentalsSelected: {
        tacticalAnalysisId: tacticalAnalysisId,
        fundamentalsSelected: fundamentalsSelected,
      },
      hasHomographies,
      videoTypes,
      recordingId,
      showOverlays,
    },
  ];
};
