import { State } from 'shared/hooks/use-url-filters/types/state';
import { FiltersList } from 'shared/types';
import { entriesFromObject } from 'shared/utils/objectToArray';

export const createInitialState = <T extends FiltersList>(filters: T) => {
  return entriesFromObject(filters).reduce<State<T>>((acc, [key, value]) => {
    acc[key] = Object.keys(value.options).filter((optionKey) => value.options[optionKey].isApplied);

    return acc;
  }, {} as State<T>);
};
