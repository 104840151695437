import { PLAYER_STAT } from 'shared/types/player/player';

type StatsTranslations = {
  [key in keyof typeof PLAYER_STAT]: string;
};

export const statsTranslationKeys: StatsTranslations = {
  [PLAYER_STAT.appearances]: 'stats.appearances',
  [PLAYER_STAT.goals]: 'stats.goals',
  [PLAYER_STAT.minutesStarting]: 'stats.minutesStarting',
  [PLAYER_STAT.redCards]: 'stats.redCards',
  [PLAYER_STAT.totalMinutes]: 'stats.totalMinutes',
  [PLAYER_STAT.yellowCards]: 'stats.yellowCards',
  [PLAYER_STAT.assists]: 'stats.assists',
};
