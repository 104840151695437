import { Box } from '@mui/material';
import { CSSProperties, useMemo } from 'react';

import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import {
  ColumnEmptyContainer,
  FilterHighlightsWrapper,
} from 'pages/tactical-analysis/components/timeline/filter-highlights/FilterHighlights.styled';
import { CSS_CLIP_ZOOM_WIDTH } from 'pages/tactical-analysis/components/timeline/timeline-table/components/timeline-css-variables';
import { useTimelineTableData } from 'pages/tactical-analysis/hooks/use-timeline-table-data';
import { hasFiltersResults } from 'pages/tactical-analysis/utils/has-filters-results';
import { useVideoPlayerPlayingMode } from 'shared/components/video-player';

type Props = {
  recordingId: string;
};

// TODO calculation of top (104px) should be dynamic
const ColumnHighlightContent = ({ clip, parentClip }: { clip: Clip; parentClip: Clip }) => {
  const contentStyle: CSSProperties = useMemo(
    () => ({
      top: '104px',
      height: 'calc(100% - 104px)',
      left: `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.startTime - parentClip.startTime})`,
      width: `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.endTime - clip.startTime})`,
    }),
    [clip, parentClip],
  );

  return <Box position='absolute' bgcolor='secondary.light' sx={{ opacity: '0.3' }} style={contentStyle} />;
};

const ColumnHighlight = ({ clip }: { clip: Clip }) => {
  const clipStyle: CSSProperties = useMemo(
    () => ({
      width: `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.endTime - clip.startTime})`,
    }),
    [clip],
  );

  return (
    <Box
      id={'filter-highlight-' + clip.id}
      key={clip.id}
      sx={{ position: 'relative', height: '100%' }}
      style={clipStyle}
    >
      {clip.clips?.map((filterClip) => (
        <ColumnHighlightContent clip={filterClip} parentClip={clip} key={filterClip.id} />
      ))}
    </Box>
  );
};

const ColumnEmpty = ({ clip, isEffectiveTime }: { clip: Clip; isEffectiveTime: boolean }) => {
  const clipStyle: CSSProperties = useMemo(
    () => ({
      width: !isEffectiveTime ? `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.endTime - clip.startTime})` : 'auto',
      background: 'transparent',
    }),
    [clip, isEffectiveTime],
  );

  return <ColumnEmptyContainer key={clip.id} style={clipStyle} isHalfTime={Boolean(clip.title)} />;
};

export const FilterHighlights = ({ recordingId }: Props) => {
  const { useEffectiveTime } = useVideoPlayerPlayingMode();
  const timelineTableData = useTimelineTableData(recordingId);

  if (!timelineTableData.filtersRow || !hasFiltersResults(timelineTableData.filtersRow)) return null;

  return (
    <FilterHighlightsWrapper>
      {timelineTableData.filtersRow.clips.map((clip) =>
        clip.type === 'not-effective-time' ? (
          <ColumnEmpty key={clip.id} clip={clip} isEffectiveTime={useEffectiveTime} />
        ) : (
          <ColumnHighlight key={clip.id} clip={clip} />
        ),
      )}
    </FilterHighlightsWrapper>
  );
};
