import { array, boolean, number, object, string } from 'zod';

export const competitionSchema = object({
  id: string(),
  name: string(),
  country: string(),
  logoUrl: string().nullable().optional(),
  seasons: array(
    object({
      id: string(),
      year: number(),
      crossesYear: boolean(),
      name: string(),
      crossYearValue: string().optional(),
    }),
  ),
});
