import { useRecoilValue, useSetRecoilState } from 'recoil';

import { timelineMatchAtoms } from 'pages/tactical-analysis/atoms';

export const useTacticalAnalysisMode = (recordingId: string) => {
  return useRecoilValue(timelineMatchAtoms.mode(recordingId));
};

export const useSetTacticalAnalysisMode = (recordingId: string) => {
  return useSetRecoilState(timelineMatchAtoms.mode(recordingId));
};
