import map from 'lodash/map';

import { RowGroup } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/row';
import { RowHeader } from 'pages/tactical-analysis/components/timeline/timeline-table/components/row-header';
import { RowsHeadersGroup } from 'pages/tactical-analysis/components/timeline/timeline-table/components/row-headers-group';

type Props = { recordingId: string; rowsGroup: RowGroup };

export const RowGroupList = ({ recordingId, rowsGroup }: Props) => {
  return (
    <>
      {rowsGroup.rowGroups
        ? rowsGroup.rowGroups.map((rowGroup) => (
            <RowsHeadersGroup level={1} key={rowGroup.id} rowsGroup={rowGroup} recordingId={recordingId} />
          ))
        : map(rowsGroup.rows, (row, idx) => <RowHeader key={idx} row={row} recordingId={recordingId} />)}
    </>
  );
};
