import { useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';

import { unregisterTrackedUser } from 'kognia/error-tracking/register-user';
import { appState, featureFlagsState, isAuthorizedAtom, userAtom } from 'shared/contexts/app-state/atoms';
import { PAGE_STATES } from 'shared/hooks/use-page-state-machine';

export function useUnauthorize() {
  const setAppState = useSetAtom(appState);
  const resetUserState = useResetAtom(userAtom);
  const setIsAuthorized = useSetAtom(isAuthorizedAtom);
  const resetFeatureFlagsState = useResetAtom(featureFlagsState);

  return () => {
    unregisterTrackedUser();
    setIsAuthorized(false);
    resetFeatureFlagsState();
    resetUserState();
    setAppState(PAGE_STATES.ERROR);
  };
}
