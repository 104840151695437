import { styled, Box } from '@mui/material';
import { Colors, animations, fontSizes } from 'kognia-ui';

export const Block = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: 'auto',
  height: '100%',
  paddingTop: theme.spacing(1.25),
  background: Colors.athens,
  fontSize: fontSizes.xxSmall,
  animation: `${animations.fadeIn} ${theme.transitions.duration.shorter} ${theme.transitions.easing.easeOut}`,
  userSelect: 'none',

  '&:after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '1px',
    height: '10px',
    background: theme.palette.secondary.main,
  },

  '&:before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: '50%',
    width: '1px',
    height: '6px',
    background: theme.palette.secondary.main,
  },
}));
