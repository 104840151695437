import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { statsTranslationKeys } from 'features/dashboard/player-profile-stats/constants/translationKeys';
import { StatSkeleton } from 'features/dashboard/player-profile-stats/ui/StatSkeleton';
import { PLAYER_STAT } from 'shared/types/player/player';

export const PlayerProfileStatsSkeleton = () => {
  const { t } = useTranslation('player');

  return (
    <Stack gap={2}>
      <Stack direction={'row'} justifyContent={'center'}>
        <StatSkeleton name={t(statsTranslationKeys[PLAYER_STAT.appearances])} />
        <StatSkeleton name={t(statsTranslationKeys[PLAYER_STAT.totalMinutes])} />
        <StatSkeleton name={t(statsTranslationKeys[PLAYER_STAT.minutesStarting])} />
        <StatSkeleton name={t(statsTranslationKeys[PLAYER_STAT.goals])} />
      </Stack>

      <Stack direction={'row'} justifyContent={'center'}>
        <StatSkeleton name={t(statsTranslationKeys[PLAYER_STAT.assists])} />
        <StatSkeleton name={t(statsTranslationKeys[PLAYER_STAT.yellowCards])} />
        <StatSkeleton name={t(statsTranslationKeys[PLAYER_STAT.redCards])} />
      </Stack>
    </Stack>
  );
};
