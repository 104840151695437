import { Grid } from '@mui/material';
import React from 'react';

import RecordingCardLoading from 'pages/recordings-list/components/vod-recording-card/loading';

const RecordingsListLoading = () => (
  <Grid container marginTop={2}>
    <RecordingCardLoading />
    <RecordingCardLoading />
    <RecordingCardLoading />
    <RecordingCardLoading />
  </Grid>
);

export default RecordingsListLoading;
