import i18n, { Resource } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

import deDE from 'kognia/i18n/locales/de-DE';
import enUS from 'kognia/i18n/locales/en-US';
import esES from 'kognia/i18n/locales/es-ES';
import { Locales, Namespaces } from 'kognia/i18n/types';

export const namespaces = Object.values(Namespaces);

export const resources: Resource = {
  [Locales['en-US']]: enUS,
  [Locales['es-ES']]: esES,
  [Locales['de-DE']]: deDE,
};

export const initConfig = {
  resources,
  ns: namespaces,
  fallbackLng: Locales['en-US'],
  supportedLngs: [Locales['en-US'], Locales['es-ES'], Locales['de-DE']],
  defaultNS: 'common',
  interpolation: {
    escapeValue: false,
  },
  partialBundledLanguages: true,
  react: {
    useSuspense: true,
  },
};

i18n
  .use(resourcesToBackend((language: string, namespace: string) => import(`./locales/${language}/${namespace}.json`)))
  .use(initReactI18next)
  .use(LanguageDetector)
  .init(initConfig);

export default i18n;
