import { invalidateClients } from 'api/backoffice/client/hooks/use-clients/useClients';
import { transformClientWithLinks } from 'api/backoffice/client/transformer';
import { ClientApiResponse } from 'api/backoffice/client/types';
import { EditClientRequestData } from 'api/backoffice/client/types/editClientRequestData';
import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { clientEditUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { Client } from 'shared/types';

export const useEditClient = () => {
  const { mutate, isPending, isError, isSuccess } = useMutationRequest<ClientApiResponse, Client>({
    type: HTTPMethod.PATCH,
    errorMessage: 'Error editing a client',
    successMessage: 'New client edited',
    transformer: transformClientWithLinks,
  });

  const editClient = ({
    id,
    data,
    onSuccess,
  }: {
    id: string;
    data: EditClientRequestData;
    onSuccess?: (res: Client) => void;
  }) => {
    mutate(
      { url: clientEditUrl(id), data },
      {
        onSuccess: async (res) => {
          await invalidateClients();
          onSuccess && onSuccess(res);
        },
      },
    );
  };

  return { editClient, isPending, isError, isSuccess };
};
