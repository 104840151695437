import { useAtomValue } from 'jotai';

import { currentClientIdAtom } from 'shared/contexts/app-state/atoms';
import { useUser } from 'shared/contexts/app-state/hooks/useUser';

export function useCurrentClient() {
  const currentClientId = useAtomValue(currentClientIdAtom);
  const user = useUser();

  return user.clients.find((client) => client.id === currentClientId);
}
