import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

export const TacticalAnalysisContainer = styled(Box)({
  backgroundColor: Colors.background,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflow: 'hidden',
  width: '100%',
  position: 'relative',
  height: '100%',
});

export const TacticalAnalysisVideoContainer = styled(Box)({
  alignItems: 'flex-start',
  backgroundColor: Colors.shark,
  display: 'flex',
  flex: 1,
  justifyContent: 'center',
  overflow: 'hidden',
});

type TacticalAnalysisTimelineContainerProps = {
  height: number;
};

export const TacticalAnalysisTimelineContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'height',
})<TacticalAnalysisTimelineContainerProps>(({ height }) => ({
  height: `${height}px`,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  position: 'relative',
}));
