import { Box, styled } from '@mui/material';

export const TaggingEventsListContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
  padding: theme.spacing(0, 0, 1),
}));

export const TaggingEventsListNotFoundImg = styled('img')(({ theme }) => ({
  marginTop: theme.spacing(3),
  width: '160px',
}));
