import { UserFormFieldsNames } from 'pages/backoffice/features/users/user-form/utils/userFormFieldNames';

interface GetUserFormFieldsProps {
  isEdit?: boolean;
}

export const getUserFormFields = ({ isEdit }: GetUserFormFieldsProps) => [
  {
    id: UserFormFieldsNames.FIRST_NAME,
  },
  {
    id: UserFormFieldsNames.LAST_NAME,
  },
  {
    id: UserFormFieldsNames.EMAIL,
    disabled: isEdit,
  },
];
