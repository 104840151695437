import { Typography } from '@mui/material';
import { fontSizes } from 'kognia-ui';
import { IconClose } from 'kognia-ui/icons/IconClose';
import isEqual from 'lodash/isEqual';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useRecoilCallback } from 'recoil';

import { useDeletePlaylistItem } from 'api/playlist/useDeletePlaylistItem';
import { usePlaylists } from 'api/playlist/useFetchPlaylists';
import {
  selectionActionsCreator,
  useGetClipsList,
} from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/atoms';
import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { usePlaylistMenuId } from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/add-to-playlist-menu-state';
import { playlistMenuAtoms } from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/add-to-playlist-menu-state/atoms';
import {
  generatePlaylistMenuItemId,
  usePlaylistMenuItems,
  useRemoveMultiplePlaylistMenuItem,
} from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/add-to-playlist-menu-state/hooks';
import styles from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/AddToPlaylistMenu.module.scss';
import {
  AddToPlaylistMenuContainer,
  AddToPlaylistMenuContent,
} from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/AddToPlaylistMenu.styled';
import { CurrentPlaylistMenu } from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/current-playlist-menu';
import { PlaylistItemsSelectorMenu } from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/playlist-items-selector-menu';
import { PlaylistSelectorMenu } from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/playlist-selector-menu';
import { UnsavedChangesDialog } from 'pages/tactical-analysis/components/tactical-analysis/add-to-playlist-menu/unsaved-changes-dialog';
import { useGeneratePlaylistItemName } from 'pages/tactical-analysis/components/timeline/timeline-table/hooks/use-generate-playlist-item-name';
import { useGetSelectedClips } from 'pages/tactical-analysis/hooks/use-get-selected-clips/useGetSelectedClips';
import { PlaylistMenuVerticalPosition } from 'shared/components/select-playlist-dialog/SelectPlaylistDialog';
import { useCurrentPlaylistItem } from 'shared/components/video-player';
import { FundamentalsSelection, Playlist } from 'shared/types';

interface Props {
  isOpen: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  recordingId: string;
  verticalPosition?: PlaylistMenuVerticalPosition;
  onSave: ({
    items,
    playlistId,
    fundamentalsSelected,
  }: {
    items: Clip[];
    playlistId: string;
    fundamentalsSelected: FundamentalsSelection;
  }) => void;
}

export const useUpdatePlaylistMenuClips = (recordingId: string) => {
  const generatePlaylistItemName = useGeneratePlaylistItemName(recordingId);
  const playlistMenuId = usePlaylistMenuId();
  return useRecoilCallback(
    ({ set, snapshot }) =>
      (clipIds: string[]) => {
        const actions = selectionActionsCreator(set, snapshot);

        clipIds.forEach((clipId) => {
          const clip = actions.getClip(clipId);
          if (!clip) return;

          set(playlistMenuAtoms.playlistMenuItem(generatePlaylistMenuItemId(playlistMenuId, clipId)), {
            id: clip.id,
            startTime: clip.startTime,
            endTime: clip.endTime,
            playlistId: '',
            name: generatePlaylistItemName(clip),
          });
        });
        set(playlistMenuAtoms.playlistMenuState(playlistMenuId), clipIds);
      },
    [generatePlaylistItemName],
  );
};

const useSynchronizeTimelineSelectionWithPlaylistMenu = (recordingId: string) => {
  const updatePlaylistMenuClips = useUpdatePlaylistMenuClips(recordingId);

  const getSelectedClips = useGetSelectedClips();

  useEffect(() => {
    const clips = getSelectedClips();

    updatePlaylistMenuClips(clips.map((c) => c.id));
  }, [getSelectedClips, updatePlaylistMenuClips]);
};

const AddToPlaylistMenu = ({
  isOpen,
  onClose = () => {},
  recordingId,
  verticalPosition = PlaylistMenuVerticalPosition.Center,
  onSave,
}: Props) => {
  const [isMenuOpen, setMenuOpen] = useState(isOpen);

  const { t } = useTranslation();
  const {
    state,
  }: {
    state:
      | {
          playlist: Playlist;
        }
      | undefined;
  } = useLocation();
  const [isUnsavedDialogOpen, setIsUnsavedDialogOpen] = useState(false);
  const [selectedPlaylist, setSelectedPlaylist] = useState<Playlist | undefined>(
    state?.playlist ? state.playlist : undefined,
  );
  const reset = useRemoveMultiplePlaylistMenuItem();
  const { data } = usePlaylists({ enabled: isMenuOpen, refetchInterval: false, isAutocomplete: true });
  const selectedPlaylistItems = usePlaylistMenuItems();
  const { deletePlaylistItem } = useDeletePlaylistItem(selectedPlaylist?.id || '');
  const currentPlaylistItem = useCurrentPlaylistItem();
  const getClips = useGetClipsList();

  useSynchronizeTimelineSelectionWithPlaylistMenu(recordingId);

  useEffect(() => {
    if (
      data?.playlists &&
      selectedPlaylist &&
      data.playlists.find((item) => item.id === selectedPlaylist?.id) &&
      !isEqual(
        selectedPlaylist,
        data.playlists.find((item) => item.id === selectedPlaylist?.id),
      )
    ) {
      setSelectedPlaylist(data.playlists.find((item) => item.id === selectedPlaylist?.id));
    }
  }, [data.playlists, selectedPlaylist]);

  const handleSave = useCallback(() => {
    if (!selectedPlaylist) return;
    const fundamentalsSelected: FundamentalsSelection = currentPlaylistItem.hasHomographies
      ? currentPlaylistItem.fundamentalsSelected
      : {
          tacticalAnalysisId: currentPlaylistItem.fundamentalsSelected.tacticalAnalysisId,
          fundamentalsSelected: ['all'],
        };

    const playlistItemsIds = selectedPlaylistItems.map<string>((clip) => clip.id);
    const clips = getClips(playlistItemsIds);

    onSave({ items: clips, playlistId: selectedPlaylist.id, fundamentalsSelected });
  }, [
    selectedPlaylist,
    currentPlaylistItem.hasHomographies,
    currentPlaylistItem.fundamentalsSelected,
    selectedPlaylistItems,
    getClips,
    onSave,
  ]);

  useEffect(() => {
    setMenuOpen(isOpen);
  }, [isOpen]);

  const handleMenuClose = useCallback(() => {
    setMenuOpen(false);
    setSelectedPlaylist(undefined);
    onClose();
    reset(selectedPlaylistItems.map((clip) => clip.id));
  }, [onClose, reset, selectedPlaylistItems]);

  const handleClose = useCallback(() => {
    if (selectedPlaylistItems.length > 0) {
      setIsUnsavedDialogOpen(true);
    } else {
      handleMenuClose();
    }
  }, [handleMenuClose, selectedPlaylistItems.length]);

  const handleSelectedPlaylistChange = useCallback((playlist: Playlist) => setSelectedPlaylist(playlist), []);
  const handleDeletePlaylistItem = useCallback(
    (playlistItemId: string) => {
      deletePlaylistItem(playlistItemId);
    },
    [deletePlaylistItem],
  );

  if (!data) return null;

  return (
    <>
      <AddToPlaylistMenuContainer isClosed={!isMenuOpen}>
        <AddToPlaylistMenuContent>
          <div className={styles.titleBar}>
            <Typography
              component='span'
              py={0.5}
              px={1}
              color='common.white'
              fontSize={fontSizes.default}
              overflow='hidden'
              whiteSpace='nowrap'
            >
              {t('timeline:add-to-playlist-menu.title')}
            </Typography>
            <span className={styles.closeButton} onClick={handleClose}>
              <IconClose size='small' sx={{ color: 'common.white' }} />
            </span>
          </div>
          <PlaylistSelectorMenu
            verticalPosition={verticalPosition}
            selectedPlaylist={selectedPlaylist}
            onSelect={handleSelectedPlaylistChange}
          />
          <div className={styles.container}>
            <PlaylistItemsSelectorMenu onSave={handleSave} playlistId={selectedPlaylist?.id} />
            {selectedPlaylist ? (
              <CurrentPlaylistMenu
                selectedPlaylist={selectedPlaylist}
                onDeletePlaylistItem={handleDeletePlaylistItem}
              />
            ) : null}
          </div>
        </AddToPlaylistMenuContent>
      </AddToPlaylistMenuContainer>
      <UnsavedChangesDialog
        isOpen={isUnsavedDialogOpen}
        onCancel={() => {
          setIsUnsavedDialogOpen(false);
          handleMenuClose();
        }}
        onSave={handleSave}
        onClose={() => setIsUnsavedDialogOpen(false)}
      />
    </>
  );
};

export default AddToPlaylistMenu;
