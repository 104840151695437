export enum OffensiveTactics {
  ACCOMPANY_PLAY_TEAM_TOGETHER = 'accompany-play-team-together',
  BALANCE_OF_THE_TEAM_AFTER_RECOVERY = 'balance-of-the-team-after-recovery',
  COMPLETED_BACKWARD_PASS = 'completed-backward-pass',
  COMPLETED_FORWARD_PASS = 'completed-forward-pass',
  COMPLETED_HORIZONTAL_PASS = 'completed-horizontal-pass',
  CROSS_INTO_THE_BOX = 'cross-into-the-box',
  FINISHING = 'finishing',
  FINISHING_AFTER_CROSS = 'finishing-after-cross',
  FINISHING_PASS = 'finishing-pass',
  GOAL = 'goal',
  GOAL_ASSIST = 'goal-assist',
  GOAL_CHANCE = 'goal-chance',
  GOAL_KICK_START_LONG_INSIDE_CHANNELS = 'goal-kick-start-long-inside-channels',
  GOAL_KICK_START_LONG_OUTSIDE_CHANNELS = 'goal-kick-start-long-outside-channels',
  GOAL_KICK_START_SHORT_INSIDE_CHANNELS = 'goal-kick-start-short-inside-channels',
  GOAL_KICK_START_SHORT_OUTSIDE_CHANNELS = 'goal-kick-start-short-outside-channels',
  IDENTIFYING_PASSING_LINES_UNDER_PRESSURE = 'identifying-passing-lines-under-pressure',
  LONG_BALL = 'long-ball',
  LOST_BALL = 'lost-ball',
  MOVING_BEHIND_THE_DEFENSIVE_LINE = 'moving-behind-the-defensive-line',
  OCCUPYING_SPACE_IN_THE_BOX = 'occupying-space-in-the-box',
  OPEN_PASSING_LINES_AFTER_LONG_BALL = 'open-passing-lines-after-long-ball',
  OVERCOMING_OPPONENTS_WITH_VERTICAL_PASSES = 'overcoming-opponents-with-vertical-passes',
  PASSING_BETWEEN_LINES = 'passing-between-lines',
  PASS_BEHIND_DEFENSIVE_LINE = 'pass-behind-defensive-line',
  POSITIONING_BEHIND_CENTER_BACKS_WHEN_LATERAL_BALLS = 'positioning-behind-center-backs-when-lateral-balls',
  POSSESSION_AFTER_RECOVERY = 'possession-after-recovery',
  PROGRESSION_AFTER_RECOVERY = 'progression-after-recovery',
  REALIZED_EMERGENCY_SUPPORT = 'realized-emergency-support',
  REALIZED_FINISHING_SUPPORT = 'realized-finishing-support',
  REALIZED_HORIZONTAL_OVERCOMING_SUPPORT = 'realized-horizontal-overcoming-support',
  REALIZED_STRIKER_SUPPORT = 'realized-striker-support',
  REALIZED_VERTICAL_OVERCOMING_SUPPORT = 'realized-vertical-overcoming-support',
  RECEIVE_FOUL_AFTER_RECOVERY = 'receive-foul-after-recovery',
  RECEIVING_BETWEEN_LINES = 'receiving-between-lines',
  RECEIVING_POSITIONING_BETWEEN_LINES = 'receiving-positioning-between-lines',
  RUNNING_INTO_THE_BOX = 'running-into-the-box',
  SECOND_BALL_OFFENSIVE_WINNING_AFTER_CROSS = 'second-ball-offensive-winning-after-cross',
  SECOND_BALL_OFFENSIVE_WINNING_AFTER_DIRECT_PLAY = 'second-ball-offensive-winning-after-direct-play',
  SECOND_BALL_OFFENSIVE_WINNING_AFTER_FINISHING = 'second-ball-offensive-winning-after-finishing',
  SECOND_BALL_OFFENSIVE_WINNING_AFTER_SET_PIECE = 'second-ball-offensive-winning-after-set-piece',
  SPACE_BETWEEN_DEFENSIVE_LINE_AND_HALFWAY_LINE = 'space-between-defensive-line-and-halfway-line',
  SUPPORTS = 'supports',
  SWITCH_OF_PLAY = 'switch-of-play',
  TAKING_ADVANTAGE_OF_DEFENSIVE_LINE_IMBALANCES = 'taking-advantage-of-defensive-line-imbalances',
  UNCOMPLETED_FORWARD_PASS = 'uncompleted-forward-pass',
  UNCOMPLETED_BACKWARD_PASS = 'uncompleted-backward-pass',
  UNCOMPLETED_HORIZONTAL_PASS = 'uncompleted-horizontal-pass',
  WIDTH_OF_THE_TEAM = 'width-of-the-team',
  WIDTH_OF_THE_TEAM_OPPOSITE_CHANNEL = 'width-of-the-team-opposite-channel',
}

export const offensiveTactics: string[] = Object.values(OffensiveTactics);

export enum DefensiveTactics {
  BALANCE_OF_THE_TEAM = 'balance-of-the-team',
  BALANCE_OF_THE_TEAM_AFTER_LOSS = 'balance-of-the-team-after-loss',
  CLEAR_THE_BOX = 'clear-the-box',
  COMMIT_FOUL_AFTER_LOSS = 'commit-foul-after-loss',
  COMPACTNESS_OF_TEAM = 'compactness-of-team',
  DEFENDING_AGAINST_THE_POSSESSOR = 'defending-against-the-possessor',
  DEFENDING_MOVING_BEHIND_THE_DEFENSIVE_LINE = 'defending-moving-behind-the-defensive-line',
  DEFENDING_RUNNING_INTO_THE_BOX = 'defending-running-into-the-box',
  DEFENSIVE_LINE_IMBALANCE_IN_DEPTH = 'defensive-line-imbalance-in-depth',
  DEFENSIVE_LINE_IMBALANCE_IN_WIDTH = 'defensive-line-imbalance-in-width',
  HOLD_AFTER_LOSS = 'hold-after-loss',
  MARKING_OPPONENTS_INSIDE_THE_BOX = 'marking-opponents-inside-the-box',
  MARKING_SUPPORTS = 'marking-supports',
  MOVING_FORWARD_DURING_ORGANIZED_PRESSURE = 'moving-forward-during-organized-pressure',
  NEUTRALIZING_OPPONENT_ADVANTAGE_OF_DEFENSIVE_LINE_IMBALANCE = 'neutralizing-opponent-advantage-of-defensive-line-imbalance',
  PRESS_AFTER_LOSS = 'press-after-loss',
  PRESSURE_ON_THE_BALL_POSSESSOR = 'pressure-on-the-ball-possessor',
  RECOVERED_BALL = 'recovered-ball',
  SECOND_BALL_DEFENSIVE_WINNING_AFTER_CROSS = 'second-ball-defensive-winning-after-cross',
  SECOND_BALL_DEFENSIVE_WINNING_AFTER_DIRECT_PLAY = 'second-ball-defensive-winning-after-direct-play',
  SECOND_BALL_DEFENSIVE_WINNING_AFTER_FINISHING = 'second-ball-defensive-winning-after-finishing',
  SECOND_BALL_DEFENSIVE_WINNING_AFTER_SET_PIECE = 'second-ball-defensive-winning-after-set-piece',
  TACKLE = 'tackle',
}

export const defensiveTactics: string[] = Object.values(DefensiveTactics);
