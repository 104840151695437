import { PlayerStateMachineContext, PlaylistItemType } from 'shared/components/video-player/types';
import { getVideoByVideoType } from 'shared/components/video-player/util';

export const jumpToPlaylistItem = (context: PlayerStateMachineContext, playlistItem: PlaylistItemType) => {
  const selectedVideoType = getVideoByVideoType(playlistItem, context.playlist.preferredPlayingMode);
  const videoSource = selectedVideoType.videoSources[0];

  return {
    currentTime: videoSource.startTime,
    playlist: {
      ...context.playlist,
      currentPlaylistItemId: playlistItem.id,
      currentSelectedPlayingMode: selectedVideoType.playingMode,
      playingItem: {
        currentSourceTime: 0,
        playlistItem,
        videoSourceIndex: 0,
      },
    },
  };
};
