import { useQueryClient } from '@tanstack/react-query';

import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { getSeasonClientsUrl } from 'pages/backoffice/api/routes';
import { transformSeasonClientsList } from 'pages/backoffice/api/season-clients/transformer';
import { SeasonClientsApiResponse } from 'pages/backoffice/api/season-clients/types';
import { SortDirection } from 'shared/types/filters/types';
import { SeasonClients } from 'shared/types/user/types';

const PAGE_SIZE = 10;
const QUERY_KEY = 'competition-clients';

export const useSeasonClients = (): InfinityQueryResult<SeasonClients, never> => {
  return useInfinityQuery<SeasonClients, never, SeasonClientsApiResponse>({
    generateUrl: getSeasonClientsUrl,
    key: QUERY_KEY,
    options: {
      size: PAGE_SIZE,
      sort: '',
      sortDirection: SortDirection.ASC,
    },
    transformer: transformSeasonClientsList,
  });
};

export const useInvalidateSeasonClients = () => {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
};
