import { OffensiveTactics, DefensiveTactics } from '@kognia/tactical-analysis-data';
import { z } from 'zod';

import { PlayingModes } from 'shared/components/video-player/types';
import { PLAYBACK_RATES } from 'shared/constants/playback-rates/paybackRates';
import { USER_PRESET_KEYS } from 'shared/constants/user-presets/userPresetsKeys';
import { ZOOM_LEVELS } from 'shared/constants/zoom-range/zoomLevelsValues';
import { RecordingsFiltersSchema } from 'shared/types/recording/schemas';

enum All {
  ALL = 'all',
}

const combined = {
  ...OffensiveTactics,
  ...DefensiveTactics,
  ...All,
} as const;

// NOTE: schema based on USER_PRESET_KEYS object
export const PRESET_SCHEMA = {
  [USER_PRESET_KEYS.multiMatchAppliedFilters]: RecordingsFiltersSchema,
  [USER_PRESET_KEYS.playingMode]: z.object({
    mode: z.nativeEnum(PlayingModes),
    showOverlays: z.boolean(),
    useEffectiveTime: z.boolean(),
    isPreferred: z.boolean().optional(),
  }),
  [USER_PRESET_KEYS.selectedTactics]: z.array(z.nativeEnum(combined)),
  [USER_PRESET_KEYS.zoomLevel]: z.union([
    z.literal(ZOOM_LEVELS.extraLarge),
    z.literal(ZOOM_LEVELS.large),
    z.literal(ZOOM_LEVELS.mediumLarge),
    z.literal(ZOOM_LEVELS.medium),
    z.literal(ZOOM_LEVELS.mediumSmall),
    z.literal(ZOOM_LEVELS.small),
    z.literal(ZOOM_LEVELS.extraSmall),
  ]),
  [USER_PRESET_KEYS.height]: z.number(),
  [USER_PRESET_KEYS.pinScenarios]: z.boolean(),
  [USER_PRESET_KEYS.headersWidth]: z.number(),
  [USER_PRESET_KEYS.timeLineAppliedFilters]: RecordingsFiltersSchema,
  [USER_PRESET_KEYS.filters]: z.string(),
  [USER_PRESET_KEYS.time]: z.number(),
  [USER_PRESET_KEYS.teamIdFocus]: z.string(),
  [USER_PRESET_KEYS.showBallPossession]: z.boolean(),
  [USER_PRESET_KEYS.showNoBallPossession]: z.boolean(),
  [USER_PRESET_KEYS.speed]: z.union([
    z.literal(PLAYBACK_RATES.extraSlow),
    z.literal(PLAYBACK_RATES.slow),
    z.literal(PLAYBACK_RATES.normal),
    z.literal(PLAYBACK_RATES.fast),
    z.literal(PLAYBACK_RATES.extraFast),
  ]),
} as const;
