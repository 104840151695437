import { styled } from '@mui/material';
import { css } from '@mui/system';
import { Colors, fontSizes } from 'kognia-ui';

export const InputAdornment = styled('div')(
  ({ theme }) => css`
    padding: ${theme.spacing(0, 0.5)};
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: ${Colors.storm};
    background-color: ${Colors.athens};
    border-radius: ${theme.shape.borderRadius}px;
    font-size: ${fontSizes.xxSmall};
    font-weight: ${theme.typography.fontWeightMedium};
  `,
);
