import { useRecoilValue, useSetRecoilState } from 'recoil';

import { timelineMatchAtoms } from 'pages/tactical-analysis/atoms';

export const useSetTacticalAnalysisTeams = (recordingId: string) => {
  return useSetRecoilState(timelineMatchAtoms.teams(recordingId));
};

export const useTacticalAnalysisTeams = (recordingId: string) => {
  return useRecoilValue(timelineMatchAtoms.teams(recordingId));
};
