import { Box, styled } from '@mui/material';

type OverlaysHomographyContainerProps = {
  aspectRatio: string;
};

export const VideoOverlaysHomographyContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'aspectRatio',
})<OverlaysHomographyContainerProps>(({ aspectRatio }) => ({
  pointerEvents: 'none',
  aspectRatio: aspectRatio,
  position: 'absolute',
  top: 0,
  left: 0,
  transformOrigin: '0 0',
  ['& *::selection']: {
    background: 'transparent',
  },
}));
