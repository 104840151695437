import { Box, Tab, Tabs } from '@mui/material';
import { isEmpty } from 'lodash';
import pickBy from 'lodash/pickBy';
import { useCallback } from 'react';

import { RecordingsFiltersList } from 'api/recording/useFetchRecordings';
import { Filters } from 'pages/recordings-list/components/recordings-list-page/filters-bar/filters';
import { useRecordingsListFilters } from 'pages/recordings-list/hooks/useRecordingsListFilters';
import { TabType } from 'pages/recordings-list/RecordingsListPageContainer';
import { RecordingFilters } from 'pages/recordings-list/types/recording';

interface Props {
  appliedFilters: RecordingsFiltersList;
  competitionsTabs: TabType[];
  filters: RecordingsFiltersList;
}

export const selectInitialCompetitionIndex = (
  urlFilterCompetition: string | undefined,
  competitionsTabs: TabType[],
) => {
  if (competitionsTabs.length === 1) {
    return 0;
  }

  if (urlFilterCompetition) {
    const competitionTabIndex = competitionsTabs.findIndex(
      (competitionsTab) => competitionsTab.value[0] === urlFilterCompetition,
    );

    if (competitionTabIndex >= 0) {
      return competitionTabIndex;
    }
  }

  return 0;
};

export const RecordingTypesTabsAndFilters = ({ appliedFilters, competitionsTabs, filters }: Props) => {
  const { getUrlFilters, removeFilters, setFilter } = useRecordingsListFilters();
  const tab = selectInitialCompetitionIndex(getUrlFilters().competition, competitionsTabs);
  const selectedCompetition = competitionsTabs[tab];

  const generateFiltersList = useCallback(
    () =>
      pickBy(isEmpty(appliedFilters) ? filters : appliedFilters, (_filter, key) => {
        if (selectedCompetition.type === RecordingFilters.TYPE) {
          return key === RecordingFilters.DATE;
        }

        return (
          key !== RecordingFilters.ANNOTATION_TYPE &&
          key !== RecordingFilters.COMPETITION &&
          key !== RecordingFilters.TYPE
        );
      }),
    [filters, selectedCompetition, appliedFilters],
  );

  const handleTabChange = useCallback(
    (_: React.SyntheticEvent, newValue: number) => {
      const selectedTab = competitionsTabs[newValue];

      const filtersToRemove = [
        RecordingFilters.DATE,
        RecordingFilters.MATCHDAY,
        RecordingFilters.COMPETITION,
        RecordingFilters.TEAM,
      ];

      if (selectedTab.type === RecordingFilters.TYPE) {
        filtersToRemove.push(RecordingFilters.COMPETITION);
      }

      if (selectedTab.type === RecordingFilters.COMPETITION) {
        filtersToRemove.push(RecordingFilters.TYPE);
      }

      removeFilters(filtersToRemove);
      setFilter(selectedTab.type, selectedTab.value);
    },
    [setFilter, competitionsTabs, removeFilters],
  );

  return (
    <>
      <Box>
        <Tabs
          value={tab}
          variant='scrollable'
          onChange={handleTabChange}
          sx={{
            marginTop: 4,
            marginBottom: 4,
            borderBottom: ({ palette }) => `1px solid ${palette.secondary.light}`,
          }}
          scrollButtons
          aria-label='scrollable tabs'
        >
          {competitionsTabs.map((type, idx) => (
            <Tab sx={{ textTransform: 'none' }} key={type.label} label={type.label} value={idx} />
          ))}
        </Tabs>
      </Box>
      <Filters filtersList={generateFiltersList()} />
    </>
  );
};
