import { Box, styled } from '@mui/material';
import { Colors, fontSizes } from 'kognia-ui';

export const ZoomContainer = styled(Box)(({ theme }) => {
  const trackColor = theme.palette.secondary.light;
  const thumbHeight = '12px';
  const thumbColor = theme.palette.text.primary;
  const thumbWidth = '12px';
  const trackWidth = '100%';
  const trackHeight = '4px';
  const trackRadius = '4px';

  const track = {
    cursor: 'default',
    height: trackHeight,
  };

  const thumb = {
    backgroundColor: thumbColor,
    border: '0',
    borderRadius: '50%',
    cursor: 'default',
    height: thumbHeight,
    width: thumbWidth,
  };

  return {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    height: '40px',
    marginLeft: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    // TODO add color to theme
    borderLeft: `1px solid ${Colors.athens}`,

    '& label': {
      fontSize: fontSizes.small,
      lineHeight: 'initial',
      fontWeight: theme.typography.fontWeightRegular,
      marginBottom: 0,
      marginRight: theme.spacing(1),
      textTransform: 'uppercase',
    },

    '& input[type="range"]': {
      WebkitAppearance: 'none',
      background: 'transparent',
      width: trackWidth,

      '&::-moz-focus-outer': {
        border: 0,
      },

      '&:focus': {
        outline: 0,
        '&::-webkit-slider-runnable-track': {
          background: trackColor,
        },
        '&::-ms-fill-lower': {
          background: thumbColor,
        },
      },

      '&::-webkit-slider-runnable-track': {
        ...track,
        background: trackColor,
        borderRadius: trackRadius,
      },

      '&::-webkit-slider-thumb': {
        ...thumb,
        WebkitAppearance: 'none',
        marginTop: `calc(${trackHeight} * 0.5 - ${thumbHeight} * 0.5)`,
      },

      '&::-moz-range-track': {
        ...track,
        background: trackColor,
        borderRadius: trackRadius,
        height: `calc(${trackHeight} * 0.5)`,
      },

      '&::-moz-range-thumb': {
        ...thumb,
      },

      '&::-ms-track': {
        ...track,
        background: 'transparent',
        borderColor: 'transparent',
        borderWidth: `calc(${thumbHeight} * 0.5) 0`,
        color: 'transparent',
      },

      '&::-ms-fill-lower': {
        background: trackColor,
        borderRadius: `calc(${trackRadius} * 2)`,
      },

      '&::-ms-fill-upper': {
        background: trackColor,
        borderRadius: `calc(${trackRadius} * 2)`,
      },

      '&::-ms-thumb': {
        ...thumb,
        marginTop: `calc(${trackHeight} * 0.25)`,
      },

      '&:disabled': {
        '&::-webkit-slider-thumb, &::-moz-range-thumb, &::-ms-thumb, &::-webkit-slider-runnable-track, &::-ms-fill-lower, &::-ms-fill-upper':
          {
            cursor: 'not-allowed',
          },
      },
    },
  };
});

export const Separator = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '3px',
  zIndex: -1,
  width: '2px',
  height: '10px',
  background: theme.palette.secondary.light,
  pointerEvents: 'none',
}));
