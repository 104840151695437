import { Box, FormControlLabel as MuiFormControlLabel, styled } from '@mui/material';
import { fontSizes } from 'kognia-ui';

export const CheckboxMenuWrapper = styled(Box)({
  maxHeight: '400px',
  overflow: 'auto',
});

export const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  columnGap: theme.spacing(1),
  margin: 0,
  padding: theme.spacing(1, 0),
  overflow: 'hidden',
  '& .MuiFormControlLabel-label': {
    fontSize: fontSizes.default,
    color: theme.palette.text.primary,
  },
  '&:hover': {
    '& .MuiCheckbox-root': {
      color: theme.palette.primary.main,
      boxShadow: 'none',
    },
  },
}));
