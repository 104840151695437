import { UseQueryResult } from '@tanstack/react-query';

import { useFetchRequest } from 'api/hooks/useFetchRequest';
import { transformCompetitions } from 'pages/backoffice/api/competitions/use-competitions/transformer';
import { annotationApiUrls } from 'pages/backoffice/api/routes';
import { transformSeason } from 'pages/backoffice/api/seasons/transformers';
import { Competition } from 'pages/backoffice/types/competitions';

type Params = {
  competitionId: string | undefined;
  onSuccess?: (competition: Competition) => void;
};

export const useCompetition = ({ competitionId, onSuccess }: Params): UseQueryResult<Competition> => {
  return useFetchRequest<Competition>({
    queryRef: ['metadata-competition', competitionId ?? ''],
    url: `${annotationApiUrls.COMPETITIONS}/${competitionId ?? ''}`,
    transformer: (competition) => transformCompetitions([competition])[0],
    options: {
      enabled: competitionId !== undefined,
    },
    onSuccess,
  });
};
