import { SegmentConfig } from 'shared/types/segment/types';
import { round } from 'shared/utils/round';
import { secondsAsDuration } from 'shared/utils/seconds-as-duration';

const MINUTE_TIME = 60;

const findSegmentForTime = (time: number, matchSegments: SegmentConfig[]): SegmentConfig | undefined => {
  for (const segment in matchSegments) {
    const matchSegment = matchSegments[segment];

    if (time >= round(matchSegment.start) && time <= round(matchSegment.start + matchSegment.length)) {
      return matchSegment;
    }
  }
};

export const formattedTime = (time: number, matchSegments: SegmentConfig[], short = true) => {
  if (matchSegments.length === 0) return secondsAsDuration(round(Math.abs(time)), false);
  const matchSegment = findSegmentForTime(time, matchSegments);

  if (!matchSegment) {
    return '--:--';
  }

  const adjustedTime = time - matchSegment.start + matchSegment.segment.start;

  const exceededTime = adjustedTime - matchSegment.segment.start - matchSegment.segment.length;

  if (exceededTime > 0 && short) {
    return `${(matchSegment.segment.length + matchSegment.segment.start) / MINUTE_TIME}+${secondsAsDuration(
      exceededTime,
      true,
    )}`;
  }

  return secondsAsDuration(round(Math.abs(adjustedTime)), false);
};
