import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { useCallback } from 'react';

const overlayPanelOpenState = atomFamily(() => atom<boolean>(false));

export const useIsOverlayPanelOpen = (id: string) => {
  return useAtomValue(overlayPanelOpenState(id));
};

const overlayPanelSelectedTab = atomFamily(() => atom<number>(0));

export const useOverlaySelectedTab = (id: string) => {
  return useAtomValue(overlayPanelSelectedTab(id));
};

export const useSetOverlaySelectedTab = (id: string) => {
  return useSetAtom(overlayPanelSelectedTab(id));
};

export const useSetIsOverlayPanelOpen = (id: string, callback?: (isOpen: boolean) => void) => {
  const [isOverlayPanelOpen, setIsOverlayPanelOpen] = useAtom(overlayPanelOpenState(id));

  return useCallback(
    (open: boolean) => {
      if (isOverlayPanelOpen !== open) {
        callback?.(open);
        setIsOverlayPanelOpen(open);
      }
    },
    [callback, setIsOverlayPanelOpen, isOverlayPanelOpen],
  );
};
