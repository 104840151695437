import { useTranslation } from 'react-i18next';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { playlistUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';

export const useDeletePlaylist = (playlistId: string) => {
  const { t } = useTranslation();
  const { mutate, isPending, isError, isSuccess } = useMutationRequest({
    type: HTTPMethod.DELETE,
    errorMessage: t('api:use-delete-playlist.error'),
    successMessage: t('api:use-delete-playlist.success'),
  });

  const deletePlaylist = () => {
    mutate({ url: playlistUrl(playlistId) });
  };

  return { deletePlaylist, isPending, isError, isSuccess };
};
