import { motion, useMotionTemplate, useMotionValue } from 'framer-motion';
import { Colors } from 'kognia-ui';
import { useEffect } from 'react';

import { useCurrentTime, useDuration, useVideoPlayerPlayingMode } from 'shared/components/video-player';
import { useCurrentVideoSource } from 'shared/components/video-player/hooks';

type ProgressBarProps = {
  isEditing: boolean;
};

const ProgressBar = ({ isEditing }: ProgressBarProps) => {
  const duration = useDuration();
  const currentTime = useCurrentTime();
  const videoSource = useCurrentVideoSource();
  const playingMode = useVideoPlayerPlayingMode();
  const width = useMotionValue(0);
  const widthPercent = useMotionTemplate`${width}%`;

  useEffect(() => {
    const currentSourceTime = playingMode.useEffectiveTime ? currentTime : currentTime - videoSource.startTime;
    const currentPercent = (100 * currentSourceTime) / duration;

    width.set(currentSourceTime > 0.1 ? currentPercent : 0);
  }, [duration, videoSource.startTime, width, currentTime, playingMode]);

  return (
    <motion.div
      style={{
        background: isEditing ? Colors.peach : Colors.brandPastel,
        position: 'absolute',
        top: 0,
        height: '100%',
        width: widthPercent,
        opacity: isEditing ? 0.5 : 1,
      }}
    />
  );
};

type Props = {
  isEditing: boolean;
  isVisible: boolean;
};

export const PlaylistItemProgressBar = ({ isEditing, isVisible }: Props) => {
  if (!isVisible) return null;

  return <ProgressBar isEditing={isEditing} />;
};
