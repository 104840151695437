import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

export const ImportUploaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  height: '40px',
}));

export const ImportUploaderContent = styled(Box)(() => ({
  border: `1px solid ${Colors.storm}`,
  color: Colors.storm,
}));

export const ImportUploaderLabel = styled('label')(({ theme }) => ({
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(0, 2),
  borderRadius: '8px',
  height: '32px',
}));
