import filter from 'lodash/filter';

import { TaggingEvent } from 'api/tagging-tool/types';
import { GroupedTags } from 'shared/types/tagging-events/types';
import { TypeOfPlay } from 'shared/types/type-of-play/types';

export const groupTagsByType = (tags: TaggingEvent[]): GroupedTags => {
  const groupedTagData = {} as GroupedTags;
  Object.values(TypeOfPlay).forEach((tagType) => {
    const filteredTags = filter(tags, (t) => t.typeOfPlay === tagType);

    if (filteredTags.length > 0) {
      groupedTagData[tagType as TypeOfPlay] = filteredTags;
    }
  });
  return groupedTagData;
};
