import { SvgIcon, type SvgIconProps } from '../components/SvgIcon';

export const IconOverlays = (props: Omit<SvgIconProps, 'children'>): JSX.Element => {
  return (
    <SvgIcon {...props}>
      <path d='M22 7.664a2.77 2.77 0 0 0-.764-1.855c-.453-.466-.693-1.101-.664-1.754-.001-.25-.13-.482-.341-.613a.71.71 0 0 0-.695-.029c-.128.065-3.25 1.667-3.25 4.251.005.904.428 1.753 1.143 2.295A18.79 18.79 0 0 0 17 14.16c0 1.083.1 6.496 2.143 6.496s2.143-5.414 2.143-6.496a18.79 18.79 0 0 0-.429-4.201c.715-.542 1.138-1.391 1.143-2.295zm-2.7-2.404a3.91 3.91 0 0 0 .828 1.444c.27.246.43.593.443.96 0 .516-.272.992-.714 1.25s-.987.258-1.429 0-.714-.735-.714-1.25c0-.96.836-1.833 1.586-2.404zm.557 8.9c.079 1.651-.164 3.302-.714 4.858-.551-1.556-.793-3.207-.714-4.858a18.05 18.05 0 0 1 .343-3.609 3.11 3.11 0 0 0 .372 0c.124-.005.248-.017.371-.036a18.04 18.04 0 0 1 .343 3.645zm-3.571.722c0 .191-.075.375-.209.51a.71.71 0 0 1-.505.211h-1.429c-.589-.078-1.178.15-1.564.606-.191.394-.169.86.057 1.234a2.18 2.18 0 0 1 .35 1.111c.014.396-.08.788-.271 1.133a2.39 2.39 0 0 1-2.143.967c-2.273 0-4.454-.913-6.061-2.537A8.71 8.71 0 0 1 2 11.995c0-2.297.903-4.5 2.511-6.125s3.788-2.537 6.061-2.537a8.42 8.42 0 0 1 5.164 1.761c.167.168.241.408.198.643a.72.72 0 0 1-.412.528.71.71 0 0 1-.665-.045c-1.234-.942-2.739-1.449-4.286-1.444-1.894 0-3.711.761-5.051 2.114s-2.092 3.189-2.092 5.104.753 3.75 2.092 5.104 3.156 2.114 5.051 2.114c.564 0 .85-.144.936-.282s0-.469-.143-.837c-.466-.818-.466-1.824 0-2.642a3.06 3.06 0 0 1 2.779-1.292h1.429a.71.71 0 0 1 .505.211c.134.135.209.319.209.51z' />
      <g strokeWidth='.7'>
        <circle cx='8.542' cy='16.272' r='1.15' />
        <circle cx='5.874' cy='12.502' r='1.15' />
        <circle cx='7.823' cy='8.424' r='1.15' />
        <circle cx='12.286' cy='7.526' r='1.15' />
      </g>
    </SvgIcon>
  );
};
