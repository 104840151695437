import { Box, styled } from '@mui/material';

export const BASIC_ROW_HEIGHT = '24px';

export const RowGroupHeaderContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'level' && prop !== 'isClickable',
})<{ level: number; isClickable?: boolean }>(({ theme, level, isClickable }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: BASIC_ROW_HEIGHT,
  paddingLeft: 0,
  background: theme.palette.secondary.light,
  borderBottom: `1px solid ${theme.palette.secondary.main}`,
  userSelect: 'none',

  ...(level === 1 && {
    paddingLeft: theme.spacing(1),
    background: theme.palette.grey[200],
  }),

  ...(isClickable && {
    cursor: 'pointer',
  }),
}));
