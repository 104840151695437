import { WritableAtom } from 'jotai';
import { atomFamily, atomWithReset } from 'jotai/utils';

import { State } from 'shared/hooks/use-url-filters/types/state';
import { FiltersList } from 'shared/types';

const urlFiltersState = atomFamily(() => atomWithReset<State<any>>({}));

type SetStateAction<Value> = Value | ((prev: Value) => Value);

export const getUrlFiltersState = <T extends FiltersList>(
  id: string,
): WritableAtom<State<T>, [SetStateAction<State<T>>], void> => {
  return urlFiltersState(id);
};
