import { transformRecordingCompetitions } from 'api/competitions/use-competitions-infinity-query/utils/transformRecordingCompetitions';
import { useInfinityQuery } from 'api/hooks/useInfinityQuery';
import { InfinityQueryResult } from 'api/hooks/useInfinityQuery/types';
import { recordingCompetitionsWithFiltersUrl } from 'api/routes';
import { Competition, CompetitionsApiResponse } from 'shared/types/competition/competition';
import { SortDirection } from 'shared/types/filters/types';

const PAGE_SIZE = 15;

type Args = {
  initialFilters?: CompetitionsFilters;
};

export type CompetitionsFilters = {
  name: string;
};

const INITIAL_COMPETITIONS_FILTERS: CompetitionsFilters = {
  name: '',
};

export const useCompetitionsInfinityQuery = (args?: Args): InfinityQueryResult<Competition, CompetitionsFilters> => {
  return useInfinityQuery<Competition, CompetitionsFilters, CompetitionsApiResponse>({
    generateUrl: recordingCompetitionsWithFiltersUrl,
    initialFilters: args?.initialFilters ?? INITIAL_COMPETITIONS_FILTERS,
    key: 'recording-competitions',
    options: {
      size: PAGE_SIZE,
      sort: '',
      sortDirection: SortDirection.ASC,
    },
    transformer: transformRecordingCompetitions,
  });
};
