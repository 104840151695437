import React, { useCallback, useEffect, useState } from 'react';

import { VideoPlayerDebugContent } from 'shared/components/video-player/video-player-component/components/video-player-debug/video-player-debug-content';

export const VideoPlayerDebug = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.altKey && (event.code === 'KeyD' || event.code === 'keyd')) {
      event.preventDefault();
      event.stopPropagation();
      setIsVisible((isVisible) => !isVisible);
    }
    return false;
  }, []);

  const handleOnClose = useCallback(() => {
    setIsVisible(false);
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  if (!isVisible) return null;

  return <VideoPlayerDebugContent onClose={handleOnClose} />;
};
