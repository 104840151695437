const PROXY_HEADER_BASEURL = 'x-ktt-baseurl';
const PROXY_HEADER_COOKIE = 'x-ktt-cookie';

const KEY_BASEURL = '_KTT_BASEURL';
const KEY_PROXY_COOKIE = 'ktt::proxy_cookie';

type Headers = { [header: string]: string };

export const getStorageExtraRequestHeaders = (): Headers => {
  const ret: Headers = {};

  const baseURL = localStorage.getItem(KEY_BASEURL);
  if (baseURL !== null) {
    ret[PROXY_HEADER_BASEURL] = baseURL;
  }

  const cookie = localStorage.getItem(KEY_PROXY_COOKIE);
  if (cookie !== null) {
    ret[PROXY_HEADER_COOKIE] = cookie;
  }

  return ret;
};
