import { Skeleton, Stack } from '@mui/material';

export const TeamsListSkeleton = () => {
  return (
    <Stack spacing={1} marginTop={2} marginBottom={2}>
      <Skeleton variant='rectangular' width='100%' height={50} />
      <Skeleton variant='rectangular' width='100%' height={50} />
      <Skeleton variant='rectangular' width='100%' height={50} />
      <Skeleton variant='rectangular' width='100%' height={50} />
      <Skeleton variant='rectangular' width='100%' height={50} />
      <Skeleton variant='rectangular' width='100%' height={50} />
      <Skeleton variant='rectangular' width='100%' height={50} />
      <Skeleton variant='rectangular' width='100%' height={50} />
    </Stack>
  );
};
