import { Paper } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

export const ProfileSearchBarWrapper: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Paper elevation={0} sx={({ spacing }) => ({ padding: spacing(2), margin: spacing(2, 4, 0, 4) })}>
      {children}
    </Paper>
  );
};
