import { useLocation, matchPath } from 'react-router-dom';

import { routes } from 'kognia/router/routes';

export const useMatchedRoutePattern = (): string | null => {
  const location = useLocation();
  const currentPath = location.pathname;
  const routesValues = Object.values(routes);

  for (const path of routesValues) {
    const match = matchPath({ path }, currentPath);
    if (match) {
      return path;
    }
  }

  return null;
};
