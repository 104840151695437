import { Typography, styled } from '@mui/material';
import { fontSizes } from 'kognia-ui';

export const PlaylistItemsSelectorMenuTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: fontSizes.small,
  // TODO pass variant prop on component after creating typography in theme
  lineHeight: 1.67,
  fontWeight: theme.typography.fontWeightMedium,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
})) as typeof Typography;

export const PlaylistItemsSelectorMenuNoItemsText = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(0.5, 2),
  color: theme.palette.common.white,
  fontSize: fontSizes.xxSmall,
  // TODO get from typography after creating typography in theme
  lineHeight: 1.67,
  fontWeight: theme.typography.fontWeightMedium,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
}));
