import { Box, styled } from '@mui/material';
import { Colors } from 'kognia-ui';

export const CustomButtonsSkeletonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  position: 'relative',
}));
export const CustomButtonsSkeletonItem = styled(Box)(({ theme }) => ({
  background: Colors.athens,
  height: theme.spacing(8),
}));
export const CustomButtonsSkeletonGradient = styled(Box)({
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  background: `linear-gradient(to bottom, transparent 0%, ${Colors.white} 100%)`,
});
