import { RowGroup } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/row';
import { RowGroupRows } from 'pages/tactical-analysis/components/timeline/timeline-table/components/row-group-rows';
import { RowGroupHeaderContainer } from 'pages/tactical-analysis/components/timeline/timeline-table/components/TimelineTable.styled';
import { useRowGroupIsCollapsed } from 'pages/tactical-analysis/components/timeline/timeline-table/store/atoms';

type Props = {
  rowGroup: RowGroup;
  recordingId: string;
  level: number;
};

export const RowsContentGroup = ({ rowGroup, recordingId, level = 0 }: Props) => {
  const isCollapsed = useRowGroupIsCollapsed(rowGroup.id);

  return (
    <>
      <RowGroupHeaderContainer level={level} />
      <div style={{ display: isCollapsed ? 'none' : 'block' }}>
        {rowGroup.rowGroups ? (
          rowGroup.rowGroups.map((rowGroup) => (
            <RowsContentGroup level={1} key={rowGroup.id} rowGroup={rowGroup} recordingId={recordingId} />
          ))
        ) : (
          <RowGroupRows rowsGroup={rowGroup} recordingId={recordingId} />
        )}
      </div>
    </>
  );
};
