import { Typography } from '@mui/material';

export const StatValue = ({ children, isEmpty = false }: { children: React.ReactNode; isEmpty?: boolean }) => {
  return (
    <Typography
      color={(theme) => (isEmpty ? theme.palette.secondary.main : 'inherit')}
      fontWeight={(theme) => theme.typography.fontWeightMedium}
    >
      {children}
    </Typography>
  );
};
