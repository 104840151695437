import { Box, styled } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

const DialogActionsWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: `0 ${theme.spacing(4)} ${theme.spacing(4)} ${theme.spacing(4)}`,
}));

export const DialogActions = ({ children }: Props) => {
  return <DialogActionsWrapper>{children}</DialogActionsWrapper>;
};
