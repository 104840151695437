import { ClientApiResponse, ClientsApiResponse } from 'api/backoffice/client/types';
import { InfinityQueryDataResult } from 'api/hooks/useInfinityQuery/types';
import { Client } from 'shared/types';
import { chooseNextCursorValue } from 'shared/utils/choose-next-cursor-value';

export const transformClientWithLinks = (client: ClientApiResponse): Client => ({
  id: client.id,
  name: client.name,
  city: client.city,
  country: client.country,
  branding: client.branding,
});

export const transformClients = (response: ClientsApiResponse): InfinityQueryDataResult<Client> => {
  return {
    data: {
      items: response.content.map(transformClientWithLinks),
      page: response.page,
    },
    nextCursor: chooseNextCursorValue(response.page.totalPages, response.page.number),
  };
};
