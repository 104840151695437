import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';

import { bulkSelectedItems } from 'entities/playlist/store/UserPlaylist.state';

export const useBulkSelectedItems = (id: string) => {
  return useAtomValue(bulkSelectedItems(id));
};

export const useSetBulkSelectedItems = (id: string) => {
  return useSetAtom(bulkSelectedItems(id));
};

export const useResetBulkSelectedItems = (id: string) => {
  return useResetAtom(bulkSelectedItems(id));
};
