import debounce from 'lodash/debounce';
import { useEffect, useMemo } from 'react';

import { useValueRef } from 'shared/hooks/use-value-ref/useValueRef';

interface Options {
  leading: true;
  maxWait?: number;
  trailing?: boolean;
}

interface Settings {
  wait?: number;
  options?: Options;
}

const DEFAULT_DEBOUNCE_TIME = 300;

export const useDebounce = <T extends (...args: any[]) => ReturnType<T>>(callback: T, settings?: Settings) => {
  const callbackRef = useValueRef<T>(callback);

  const debouncedCallback = useMemo(
    () =>
      debounce(
        (...args: Parameters<T>) => callbackRef.current(...args),
        settings?.wait ?? DEFAULT_DEBOUNCE_TIME,
        settings?.options,
      ),
    [callbackRef, settings?.wait, settings?.options],
  );

  useEffect(() => {
    return () => debouncedCallback.cancel();
  }, [debouncedCallback]);

  return debouncedCallback;
};
