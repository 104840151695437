import { Paper } from '@mui/material';

import { PlayerProfileStats } from 'features/dashboard/player-profile-stats/ui/PlayerProfileStats';

type Props = {
  playerId: string;
  competitionId?: string;
  seasonYear?: number;
};

export const PlayerProfileStatsFeature = ({ playerId, competitionId, seasonYear }: Props) => {
  if (!competitionId || !seasonYear) return null;

  return (
    <Paper elevation={0} sx={{ padding: 2, minWidth: 0 }}>
      <PlayerProfileStats playerId={playerId} competitionId={competitionId} seasonYear={seasonYear} />
    </Paper>
  );
};
