import React, { PropsWithChildren } from 'react';

import { PLAYLIST_ITEM_FULL_WIDTH, PLAYLIST_ITEM_GAP } from 'entities/playlist/config/Playlist.config';

type Props = PropsWithChildren<{
  index: number;
}>;

export const PlaylistItemPositionWrapper = ({ children, index }: Props) => {
  return (
    <div
      style={{
        position: 'absolute',
        paddingLeft: PLAYLIST_ITEM_GAP,
        paddingRight: PLAYLIST_ITEM_GAP,
        left: index * PLAYLIST_ITEM_FULL_WIDTH,
        width: PLAYLIST_ITEM_FULL_WIDTH,
      }}
    >
      {children}
    </div>
  );
};
