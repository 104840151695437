import { PropsWithChildren } from 'react';

import styles from 'pages/tactical-analysis/components/timeline/timeline-table/components/Rows.module.scss';

export const RowHeaderContent = ({
  children,
  title,
  ...rest
}: PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> & { title: string }) => (
  <div {...rest} title={title} className={styles.rowHeaderContent}>
    {children}
  </div>
);
