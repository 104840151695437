import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { PostNewPlaylistItem } from 'api/playlist/types';
import { useAddManyToPlaylist } from 'api/playlist/useAddManyToPlaylist';
import { TacticalAnalysesFilters } from 'api/recording/useEpisodesWithFilters/types';
import { useTacticalAnalysesPlaylistItems } from 'api/recording/useEpisodesWithFilters/useTacticalAnalysesPlaylistItems';
import { useMapVideos } from 'entities/playlist/hooks/use-map-videos/useMapVideos';
import { MultiAnalysisFilters } from 'features/multi-analysis-filters/MultiAnalysisFilters';
import { useVideoPlayerActions } from 'shared/components/video-player';
import { Playlist } from 'shared/types';
import { RecordingsFilters } from 'shared/types/recording/types';

interface Props {
  appliedFilters: RecordingsFilters | undefined;
  filtersData: TacticalAnalysesFilters;
  isLoadingRecordings: boolean;
  onClose: () => void;
  onUpdate: (filters: RecordingsFilters) => void;
  playlistId: string;
}

export const MultiAnalysisFiltersPlaylist = ({
  appliedFilters,
  filtersData,
  isLoadingRecordings,
  onClose,
  onUpdate,
  playlistId,
}: Props) => {
  const { t } = useTranslation();
  const mapVideos = useMapVideos();
  const actions = useVideoPlayerActions();

  const { addManyToPlaylist, isPending: isAddManyToPlaylistLoading } = useAddManyToPlaylist();

  const { isPending: isLoadingFilteredData, getFilteredItems, data } = useTacticalAnalysesPlaylistItems();

  const addPlaylistItemsToPlaylist = useCallback(() => {
    if (!(data.length > 0)) return;
    const playlistItems: PostNewPlaylistItem[] = data
      .filter((playlistItem) => playlistItem.origin)
      .map((playlistItem) => {
        return {
          endTime: playlistItem.endTime,
          name: playlistItem.name || '',
          playlistId,
          startTime: playlistItem.startTime,
          recordingId: playlistItem.recordingId,
          fundamentalsSelected: playlistItem.fundamentalsSelected,
        };
      });
    addManyToPlaylist({
      items: playlistItems,
      options: {
        onSuccess: (response: Playlist) => {
          const playlistItems = mapVideos(response);
          actions.replacePlaylistItems(playlistItems);
          onClose();
        },
      },
    });
  }, [mapVideos, onClose, actions, data, addManyToPlaylist, playlistId]);

  return (
    <MultiAnalysisFilters
      appliedFilters={appliedFilters}
      applyButtonLabel={t('recordings:filters.add-playlist-clips-count', { count: data.length ?? 0 })}
      applyButtonDefaultLabel={t('recordings:filters.add-playlist-clips')}
      resetButtonLabel={t('recordings:filters.reset-filters')}
      filtersData={filtersData}
      getFilteredItems={getFilteredItems}
      isDisabled={data.length === 0}
      isLoading={isLoadingFilteredData || isAddManyToPlaylistLoading}
      isLoadingFilters={isLoadingRecordings}
      onApply={addPlaylistItemsToPlaylist}
      onUpdate={onUpdate}
      recordingIds={appliedFilters?.recordingIds ?? []}
    />
  );
};
