import { Clip } from 'pages/tactical-analysis/api/use-tactical-analysis-data/generate-timeline-rows/types/clip';
import { SelectableClip } from 'pages/tactical-analysis/components/timeline/timeline-table/components/clip';
import styles from 'pages/tactical-analysis/components/timeline/timeline-table/components/no-effective-time-clips-list/NotEffectiveTimeClipsList.module.scss';

type Props = {
  clip: Clip;
  recordingId: string;
};
export const NotEffectiveTimeClipsList = ({ clip, recordingId }: Props) => {
  return (
    <div className={styles.content}>
      {clip.clips?.map((innerClip) => (
        <SelectableClip recordingId={recordingId} clip={innerClip} parentClip={clip} key={innerClip.id} />
      ))}
    </div>
  );
};
