import React from 'react';

import { formattedTime } from 'pages/tactical-analysis/utils/formatted-time';
import {
  useCurrentVideoSourceDuration,
  useCurrentVideoSourceTime,
  usePlayerCurrentSource,
} from 'shared/components/video-player/hooks';
import styles from 'shared/components/video-player/video-player-component/components/progress-time-video-source/ProgressTime.module.scss';
import { SegmentConfig } from 'shared/types/segment/types';
import { secondsAsDuration } from 'shared/utils/seconds-as-duration';

const format = (time: number, matchSegments?: SegmentConfig[]) => {
  if (matchSegments) return formattedTime(time, matchSegments, false);

  return secondsAsDuration(time, false);
};

const ProgressTimeVideoSource = () => {
  const currentTime = useCurrentVideoSourceTime();
  const duration = useCurrentVideoSourceDuration();
  const videoSource = usePlayerCurrentSource();

  return (
    <div className={styles.container}>
      {format(currentTime - videoSource.startTime)} / {secondsAsDuration(duration, false)}
    </div>
  );
};

export default ProgressTimeVideoSource;
