import { ImageSizes } from '../types/images';
import { IconSizes } from '../../components/SvgIcon';

export const getIconSizeFromImageSize = (size: ImageSizes): IconSizes => {
  switch (size) {
    case 'xxsmall':
      return 'xsmall';
    case 'xsmall':
      return 'xsmall';
    case 'small':
      return 'small';
    case 'medium':
      return 'medium';
    case 'large':
      return 'xlarge';
    case 'xlarge':
      return 'xxlarge';
  }
};
