import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

import { PAGE_STATES } from 'shared/hooks/use-page-state-machine';
import { FeatureFlags, User } from 'shared/types/user/types';

export const userAtom = atomWithReset<User | null>(null);
export const isAuthorizedAtom = atomWithReset<boolean | null>(null);
export const currentClientIdAtom = atom<string>();
export const featureFlagsState = atomWithReset<FeatureFlags | null>(null);
export const appState = atom<PAGE_STATES>(PAGE_STATES.IDLE);
