import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';

import { FEATURE_FLAG } from 'api/user/use-fetch-feature-flags';
import { routes } from 'kognia/router/routes';
import { useFeatureFlag } from 'shared/contexts/app-state/hooks/useFeatureFlag';
import { useIsUserAdmin } from 'shared/contexts/app-state/hooks/useIsUserAdmin';

type ProtectedRouteProps = {
  onlyAdminAccess?: boolean;
  featureFlag?: FEATURE_FLAG;
  children: ReactNode;
};

export const AccessProtected = ({
  featureFlag: featureFlag,
  onlyAdminAccess = false,
  children,
}: ProtectedRouteProps) => {
  const isAdmin = useIsUserAdmin();
  const hasFeatureEnabled = useFeatureFlag(featureFlag);

  if (!isAdmin && onlyAdminAccess) return <Navigate to={routes.HOME_PAGE} replace />;
  if (featureFlag && !hasFeatureEnabled) return <Navigate to={routes.HOME_PAGE} replace />;

  return <>{children}</>;
};
