import { ListItemText, Tooltip, BadgeProps } from '@mui/material';
import { Colors } from 'kognia-ui';
import React, { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';

import { MenuItemBadge } from 'shared/components/sidebar-layout/sidebar-menu/sidebar-menu-item/ui/MenuItemBadge';
import { MenuButton, MenuIcon } from 'shared/components/sidebar-layout/sidebar-menu/styled';
import { getMenuItemSelected, ListItemTextStyles } from 'shared/components/sidebar-layout/sidebar-menu/utils';

import { MenuItem } from '..';

interface Props {
  item: MenuItem;
  isSidebarOpen: boolean;
  onClick: (ref: React.RefObject<HTMLDivElement>) => void;
  isSelected?: boolean;
  badge?: {
    badgeContent?: number;
    invisibleWhenOpenedSidebar?: boolean;
    invisibleWhenClosedSidebar?: boolean;
    color?: BadgeProps['color'];
  };
}

const MAX_BADGE_VALUE = 999;

export const SidebarMenuItem = forwardRef(
  ({ item, isSelected, onClick, isSidebarOpen, badge }: Props, ref: React.Ref<HTMLDivElement | null>) => {
    const buttonRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => buttonRef.current);

    const isMenuItemSelected =
      isSelected || ('link' in item ? getMenuItemSelected(location.pathname, [], item.link) : false);

    const handleOnClick = useCallback(() => {
      onClick(buttonRef);
    }, [onClick]);

    return (
      <Tooltip placement={'right'} title={isSidebarOpen ? '' : item.label}>
        <MenuButton
          disabled={item.isDisabled}
          onClick={handleOnClick}
          ref={buttonRef}
          selected={isMenuItemSelected}
          sx={{
            overflow: 'hidden',
            ...(item.bottomDivider && {
              // TODO use color from theme
              borderBottom: `1px solid ${Colors.iron}`,
              '&:last-child': {
                borderBottom: 0,
              },
            }),
          }}
        >
          <MenuIcon>
            {badge ? (
              <MenuItemBadge
                badgeContent={badge.badgeContent}
                max={MAX_BADGE_VALUE}
                color={badge.color}
                invisible={isSidebarOpen || badge.invisibleWhenClosedSidebar}
              >
                {item.icon}
              </MenuItemBadge>
            ) : (
              item.icon
            )}
          </MenuIcon>
          {isSidebarOpen && (
            <>
              <ListItemText
                primary={item.label}
                sx={{ margin: 0 }}
                primaryTypographyProps={{ sx: ListItemTextStyles }}
              />

              {badge && (
                <MenuItemBadge
                  badgeContent={badge.badgeContent}
                  color={badge.color}
                  max={MAX_BADGE_VALUE}
                  invisible={!isSidebarOpen || badge.invisibleWhenOpenedSidebar}
                  sx={{
                    '& .MuiBadge-badge': {
                      right: 16,
                      top: 0,
                    },
                  }}
                />
              )}
            </>
          )}
        </MenuButton>
      </Tooltip>
    );
  },
);

SidebarMenuItem.displayName = 'SidebarMenuItem';
