import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useMutationRequest } from 'api/hooks/useMutationRequest';
import { downloadEffectiveTimeVideosUrl } from 'api/routes';
import { HTTPMethod } from 'api/types';
import { BackendApiError, backendApiErrorParser } from 'api/utils/backend-api-error-parser';
import { routes } from 'kognia/router/routes';
import { NotificationLink } from 'shared/components/NotificationLink';
import { NotificationType, useNotifications } from 'shared/hooks/notifications';

type Params = {
  onSettled?: () => void;
};

export const useDownloadEffectiveTimeVideos = ({ onSettled }: Params) => {
  const { t } = useTranslation();
  const triggerNotification = useNotifications();
  const navigate = useNavigate();

  const { mutate } = useMutationRequest<unknown, unknown, BackendApiError>({
    onSuccess: () =>
      triggerNotification({
        type: NotificationType.INFO,
        message: (
          <Trans
            i18nKey={'download-manager:download-ready-notification'}
            components={{
              downloadManagerLink: <NotificationLink onClick={() => navigate(routes.DOWNLOAD_MANAGER)} />,
            }}
          />
        ),
      }),
    errorMessage: (response) => backendApiErrorParser(response, t('api:use-download-effective-time-videos.error')),
    onSettled: () => onSettled?.(),
    type: HTTPMethod.POST,
  });

  return useCallback(
    (tacticalAnalysisId: string, name: string) => {
      mutate({
        url: downloadEffectiveTimeVideosUrl(),
        data: { tacticalAnalysisId, name },
      });
    },
    [mutate],
  );
};
