import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';

import PlaylistCard from 'pages/recording-playlists/components/playlists-grid/playlist-card';
import { Playlist } from 'shared/types';

interface Props {
  playlists: Playlist[];
  recordingId: string;
}
const PlaylistsGrid = ({ playlists, recordingId }: Props) => {
  const [playlistsList, setPlaylistsList] = useState<Playlist[]>([]);

  useEffect(() => {
    setPlaylistsList(playlists);
  }, [playlists]);

  return (
    <Grid container spacing={4}>
      {playlistsList.map((playlist) => (
        <Grid key={playlist.id} item xs={4}>
          <PlaylistCard key={playlist.id} recordingId={recordingId} playlist={playlist} />
        </Grid>
      ))}
    </Grid>
  );
};

export default PlaylistsGrid;
