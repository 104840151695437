import { object, string, z } from 'zod';

import { searchResultSchema } from 'entities/dashboard/types/dashboardSearch';

export const dashboardRecentSearchesSchema = object({
  data: searchResultSchema,
  date: string(),
});

export type RecentSearch = z.infer<typeof dashboardRecentSearchesSchema>;
