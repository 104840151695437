import { Box, styled, SxProps, Theme } from '@mui/material';
import { forwardRef } from 'react';

interface Props {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
  panelContainerSx?: SxProps<Theme>;
  sx?: SxProps<Theme>;
}

export const TabPanelContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 1, 1),
}));

export const TabPanel = forwardRef((props: Props, ref: any) => {
  const { children, value, index, panelContainerSx = {}, sx = {}, ...other } = props;

  return (
    <Box ref={ref} role='tabpanel' hidden={value !== index} sx={sx} {...other}>
      {value === index && <TabPanelContainer sx={panelContainerSx}>{children}</TabPanelContainer>}
    </Box>
  );
});

TabPanel.displayName = 'TabPanel';

export const TabsPanel = styled(Box)(({ theme }) => ({ overflowY: 'scroll', marginTop: theme.spacing(2) }));
