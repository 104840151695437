import { TacticalAnalysisApiResponse } from 'api/types';
import { TacticalAnalysis } from 'shared/types/recording/types';

export const transformTacticalAnalysis = (data: TacticalAnalysisApiResponse): TacticalAnalysis => {
  return {
    id: data.id,
    recordingId: data.recordingId,
    tacticalAnalysisGitCommit: data.tacticalAnalysisGitCommit,
    numberOfEpisodes: data.numberOfEpisodes,
    startTime: data.startTime,
    state: data.state,
    showOverlays: data.showOverlays,
  };
};
